export const clinicalAreasReducer = (state, action) => {
  const { type, payload = {} } = action

  const { initialClinicalAreas, initialConditions, initialTreatments } = state
  switch (type) {
    case 'update':
      if ('clinicalAreasSearch' in payload) {
        const filtered =
          payload.clinicalAreasSearch.length > 0
            ? [...initialClinicalAreas].filter(
              item =>
                item.display &&
                item.display
                  .toLowerCase()
                  .includes(payload.clinicalAreasSearch.toLowerCase()),
            )
            : initialClinicalAreas
        return {
          ...state,
          ...payload,
          filteredClinicalAreas: filtered,
        }
      }
      if ('conditionsSearch' in payload) {
        const filtered =
          payload.conditionsSearch.length > 0
            ? [...initialConditions].filter(
              item =>
                item.display &&
                item.display
                  .toLowerCase()
                  .includes(payload.conditionsSearch.toLowerCase()),
            )
            : initialConditions
        return {
          ...state,
          ...payload,
          filteredConditions: filtered,
        }
      }
      if ('treatmentsSearch' in payload) {
        const filtered =
          payload.treatmentsSearch.length > 0
            ? [...initialTreatments].filter(
              item =>
                item.display &&
                item.display
                  .toLowerCase()
                  .includes(payload.treatmentsSearch.toLowerCase()),
            )
            : initialTreatments
        return {
          ...state,
          ...payload,
          filteredTreatments: filtered,
        }
      }
      return {
        ...state,
        ...payload,
      }
    case 'clear':
      if (payload.key === 'clinicalAreasSearch') {
        return {
          ...state,
          clinicalAreasSearch: '',
          filteredClinicalAreas: initialClinicalAreas,
        }
      }
      if (payload.key === 'conditionsSearch') {
        return {
          ...state,
          conditionsSearch: '',
          filteredConditions: initialConditions,
        }
      }
      if (payload.key === 'treatmentsSearch') {
        return {
          ...state,
          treatmentsSearch: '',
          filteredTreatments: initialTreatments,
        }
      }
      return state
    default:
      return state
  }
}

export const getInitialClinicalAreaState = (clinicalAreas = []) => {
  const filteredConditions = clinicalAreas.reduce((result, curr) => {
    if (curr?.conditions?.length > 0) {
      result.push(
        ...curr.conditions.map(item => ({
          ...item,
          clinicalArea: curr.display,
        })),
      )
    }
    return result
  }, [])

  const filteredTreatments = clinicalAreas.reduce((result, curr) => {
    if (curr.treatments && curr.treatments.length > 0) {
      result.push(
        ...curr.treatments.map(item => ({
          ...item,
          clinicalArea: curr.display,
        })),
      )
    }
    return result
  }, [])

  return {
    clinicalAreasSearch: '',
    conditionsSearch: '',
    treatmentsSearch: '',
    filteredClinicalAreas: [...clinicalAreas],
    filteredConditions,
    filteredTreatments,
    initialClinicalAreas: clinicalAreas,
    initialConditions: filteredConditions,
    initialTreatments: filteredTreatments,
  }
}
