import { useContext } from 'react'
import { useMutation } from 'react-query'
import { services } from 'services'
import { AuthStateContext } from 'context/auth-state-context'

export const useUpdateDetailsMutations = ({
  provider,
  facility,
  onMutate,
  onSuccess,
  onError,
}) => {
  const [authState] = useContext(AuthStateContext)

  const {
    mutate: updateProviderInfo,
    isLoading: updatingProviderInfo,
  } = useMutation(
    ({ options }) =>
      services.ribbon.updateProviderInfo(
        authState.token,
        provider.npi,
        options,
      ),
    {
      mutationKey: 'updateProviderInfo',
      onMutate: onMutate,
      onSuccess: onSuccess,
      onError: onError,
    },
  )
  const {
    mutate: updateFacilityInfo,
    isLoading: updatingFacilityInfo,
  } = useMutation(
    ({ options }) => services.ribbon.updateLocationData(facility.uuid, options),
    {
      mutationKey: 'updateLocationData',
      onMutate: onMutate,
      onSuccess: onSuccess,
      onError: onError,
    },
  )

  return {
    updateProviderInfo,
    updatingProviderInfo,
    updateFacilityInfo,
    updatingFacilityInfo,
  }
}
