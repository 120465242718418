import React from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components"
import { Flex } from 'design-system/flex'
import { Label, LABEL_TYPE } from 'design-system/label'
import {PARTNERS} from 'utils/constants/partners'
import {CopyableBookingLink} from 'components/scheduling-panel/copyable-booking-link'


const BookingLinkContainer = styled.div`
  max-width: 55%;
`

export const PartnerBookingLink = ({
  partnerId,
  partnerName,
  url,
}) => {
  const {logo} = PARTNERS[partnerId]

  return (
    <>
      <Label
        type={LABEL_TYPE.BODY1_BOLD}
        s={p => ({marginBottom: p.theme.sizes.two})}
      >
        Booking Link
      </Label>
      <Flex
        s={() => ({
          alignItems: 'center',
        })}
      >
        <Label
          type={LABEL_TYPE.BODY1}
          s={() => ({maxWidth: '50%',})}
        >
          Start booking appointments for this provider by copying and pasting the link to your browser.
        </Label>
        <BookingLinkContainer>
          {partnerName && (
            <Label
              type={LABEL_TYPE.BODY3_LIGHT}
              s={p => ({marginBottom: p.theme.sizes.one})}
            >
              {'Powered by'}
              {logo && logo({marginLeft: '0.28em', width: '11px', height: 'auto'})}
              {` ${partnerName}`}
            </Label>
          )}
          <CopyableBookingLink
            url={url}
          />
        </BookingLinkContainer>
      </Flex>
    </>
  )
}

PartnerBookingLink.propTypes = {
  partnerId: PropTypes.number,
  partnerName: PropTypes.string,
  url: PropTypes.string,
}
