import React, { useContext, useReducer } from 'react'
import { Modal } from 'design-system/modal'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Spinner } from 'design-system/spinner/spinner.component'
import { Flex } from 'design-system/flex'
import { DisplayFieldStep } from '../add-new/display-field-step'
import { NewFieldStep } from '../add-new/new-field-step'
import {
  editCustomFieldReducer,
  reducerTypes,
} from './edit-custom-field-reducer'
import { FIELD_TYPES_FLAT_LOWER } from 'utils/constants'
import { services } from 'services'
import { NotyfContext } from 'context/notyf-context'
import { AuthStateContext } from 'context/auth-state-context'
import { useMutation } from 'react-query'
import { useActionBtnsValidation } from '../add-new/required-fields-validations'

const formatFieldsForEditState = field => {
  const editState = {
    ...field,
    list_type: field.list_display_config
      ? field.list_display_config.list_type
      : '',

    boolean_display_config:
      field.field_type === FIELD_TYPES_FLAT_LOWER.list
        ? field.list_display_config.boolean_display_config
        : field.boolean_display_config,

    object_display_config:
      field.field_type === FIELD_TYPES_FLAT_LOWER.list
        ? field.list_display_config.object_display_config
        : field.object_display_config,

    configureDisplay: false,

    ...(field.field_type === FIELD_TYPES_FLAT_LOWER.boolean ||
    field.field_type === FIELD_TYPES_FLAT_LOWER.object ||
    (field.field_type === FIELD_TYPES_FLAT_LOWER.list &&
      field.list_display_config &&
      (field.list_display_config.list_type === FIELD_TYPES_FLAT_LOWER.object ||
        field.list_display_config.list_type === FIELD_TYPES_FLAT_LOWER.boolean))
      ? {
          showConfigureDisplayButton: true,
        }
      : {
          showConfigureDisplayButton: false,
        }),
  }

  return editState
}

export const EditCustomFieldModal = ({
  instance = {},
  fcaCustomFields = {},
  field = {},
  handleClose = () => {},
  handleSuccess = () => {},
}) => {
  const [state, dispatch] = useReducer(
    editCustomFieldReducer,
    formatFieldsForEditState(field),
  )
  const {
    configureDisplayBtnIsDisabled,
    createFieldBtnIsDisabled,
  } = useActionBtnsValidation(state)

  const [authState] = useContext(AuthStateContext)
  const notyf = useContext(NotyfContext)
  const { mutateAsync: manageInstance, isLoading } = useMutation(
    ({ uuid, options }) =>
      services.ribbon.manageInstance(authState.token, uuid, options),
    {
      onSuccess: res => {
        console.log(`🚀 => manageInstance => res`, res)
        notyf.success('Custom field updated successfully')

        handleSuccess()
      },
      onError: error => {
        console.log(
          `🚀 => manageInstance:updating Custom Fields => error`,
          error,
        )
        notyf.error(error.message)
      },
    },
  )

  const onChangeText = e => {
    const { name, value } = e.target
    dispatch({
      type: reducerTypes.update,
      payload: {
        [name]: value,
      },
    })
  }

  const onClickCancel = () => {
    handleClose()
  }

  const toggleConfigureDisplay = () => {
    dispatch({
      type: reducerTypes.toggle_display_config,
    })
  }

  const onClickSave = () => {
    const foundFieldIndex = fcaCustomFields[field.type].findIndex(
      item => item.uuid === field.uuid,
    )

    if (foundFieldIndex > -1) {
      const edittedField = {
        ...fcaCustomFields[field.type][foundFieldIndex],
        // only overriding fields that can be editted
        display_name: state.display_name,
        default_value: state.default_value,
        boolean_display_config:
          state.field_type === FIELD_TYPES_FLAT_LOWER.boolean
            ? state.boolean_display_config
            : null,
        object_display_config:
          state.field_type === FIELD_TYPES_FLAT_LOWER.object
            ? state.object_display_config
            : null,
        list_display_config:
          state.field_type === FIELD_TYPES_FLAT_LOWER.list
            ? {
                list_type: state.list_type,
                boolean_display_config:
                  state.list_type === FIELD_TYPES_FLAT_LOWER.boolean
                    ? state.boolean_display_config
                    : null,
                object_display_config:
                  state.list_type === FIELD_TYPES_FLAT_LOWER.object
                    ? state.object_display_config
                    : null,
              }
            : null,
      }

      const newCustomFields = JSON.parse(JSON.stringify(fcaCustomFields))
      newCustomFields[field.type].splice(foundFieldIndex, 1, edittedField)

      console.log('Saving New Custom Fields State', newCustomFields)

      // Save Custom Fields
      manageInstance({
        uuid: instance.uuid,
        options: {
          fca_custom_fields: newCustomFields,
        },
      })
    } else {
      notyf.error('Custom Field not found')
    }
  }

  const { showConfigureDisplayButton, configureDisplay } = state

  const actionBtnIsDisabled =
    isLoading ||
    (!showConfigureDisplayButton && createFieldBtnIsDisabled) ||
    (showConfigureDisplayButton && configureDisplayBtnIsDisabled) ||
    (configureDisplay && createFieldBtnIsDisabled)

  return (
    <Modal
      onClose={handleClose}
      title={
        configureDisplay ? 'Edit custom field display' : `Edit custom field`
      }
      gridContainerStyle={{ maxWidth: '600px' }}
      footer={
        <Flex
          s={p => ({
            marginRight: p.theme.sizes.six,
            justifyContent: 'flex-end',
          })}
        >
          <Button
            s={() => ({
              marginRight: '1rem',
            })}
            type={BUTTON_TYPE.LINK}
            onClick={configureDisplay ? toggleConfigureDisplay : onClickCancel}
          >
            {configureDisplay ? 'Back' : 'Cancel'}
          </Button>
          <Button
            onClick={
              showConfigureDisplayButton && !configureDisplay
                ? toggleConfigureDisplay
                : onClickSave
            }
            type={
              actionBtnIsDisabled ? BUTTON_TYPE.DISABLED : BUTTON_TYPE.PRIMARY
            }
            size={BUTTON_SIZE.LARGE}
            disabled={actionBtnIsDisabled}
            s={() => ({
              minWidth: '110px',
            })}
          >
            {showConfigureDisplayButton && !configureDisplay
              ? 'Next: Configure Display'
              : 'Save'}
          </Button>
        </Flex>
      }
    >
      {isLoading ? (
        <Spinner label={`Updating field..`} />
      ) : (
        <Flex
          s={p => ({
            flexDirection: 'column',
            paddingBottom: p.theme.sizes.ten,
            paddingTop: p.theme.sizes.four,
            minHeight: '240px',
          })}
        >
          {configureDisplay ? (
            <DisplayFieldStep
              dispatch={dispatch}
              state={state}
              onChangeText={() => {}}
              isEditMode={true}
            />
          ) : (
            <NewFieldStep
              state={state}
              onChangeCheckbox={() => {}}
              onChangeText={onChangeText}
              onChangeType={() => {}}
              isEditMode={true}
            />
          )}
        </Flex>
      )}
    </Modal>
  )
}
