import dayjs from 'utils/dayjs'

export const EDIT_INSURANCE_VIEWS = {
  default: 'DEFAULT',
  add: 'ADD',
  copyInsurances: 'COPY_INSURANCES',
  review: 'REVIEW',
}

export const VERIFICATION_STATUS_OPTIONS = {
  all: 'All',
  verified: 'Verified',
  reported: 'Reported',
  noSelection: 'No Selection',
}

export const editInsurancesInitialState = {
  type: '', // provider || facility
  fcaUserId: null,
  verifications: {},
  editVerifications: {},
  selectedInsurancesMap: {},
  selectedVerificationFilter: null,
  insurances: [],
  localSearch: '',
  planFilters: {},
  addInsurancesMap: {},
  copyFromLocation: null,
  view: EDIT_INSURANCE_VIEWS.default,
  previousView: EDIT_INSURANCE_VIEWS.default,
  selectedCarrier: null,
  confirmClose: false,
  storedNextAction: null,
}

export const EDIT_INSURANCE_ACTION_TYPES = {
  updateFilters: 'UPDATE_FILTERS',

  changeView: 'CHANGE_VIEW',
  toggleConfirmClose: 'TOGGLE_CONFIRM_CLOSE',
  updateSelectedCarrier: 'UPDATE_SELECTED_CARRIER',

  copyFromLocation: 'COPY_FROM_LOCATION',

  resetUpdates: 'RESET_UPDATES',
  verifyList: 'VERIFY_LIST',
  reportList: 'REPORT_LIST',
  noSelectionList: 'NO_SELECTION_LIST',

  updateAddListSingle: 'UPDATE_ADD_LIST_SINGLE',
  updateAddListAllAdd: 'UPDATE_ADD_LIST_ALL_ADD',
  updateAddListAllUndo: 'UPDATE_ADD_LIST_ALL_UNDO',

  selectSingle: 'SELECT_SINGLE',
  selectList: 'SELECT_LIST',
  unselectList: 'UNSELECT_LIST',
  unselectAll: 'UNSELECT_ALL',
  selectByFilter: 'SELECT_BY_FILTER',
  selectVerificationFilter: 'SELECT_VERIFICATION_FILTER',
}

export const editInsurancesReducer = (state, action) => {
  const {
    fcaUserId,
    addInsurancesMap,
    insurances: stateInsurances,
    view,
    verifications,
    editVerifications,
    selectedInsurancesMap: stateSelectedInsurancesMap,
  } = state

  const { type, payload } = action
  switch (type) {
    case EDIT_INSURANCE_ACTION_TYPES.resetUpdates: {
      return {
        ...state,
        editVerifications: JSON.parse(JSON.stringify(verifications || {})),
      }
    }
    case EDIT_INSURANCE_ACTION_TYPES.updateFilters: {
      const { filters, localSearch } = payload

      return {
        ...state,
        ...(filters && {
          planFilters: filters,
        }),
        ...(typeof localSearch !== 'undefined' && {
          localSearch,
        }),
        selectedInsurancesMap: {},
      }
    }

    case EDIT_INSURANCE_ACTION_TYPES.copyFromLocation: {
      const { location } = payload
      return {
        ...state,
        copyFromLocation: location,
      }
    }
    case EDIT_INSURANCE_ACTION_TYPES.changeView: {
      const { view: nextView } = payload
      console.log(`previousView: ${view} -> nextView: ${nextView}`)

      return {
        ...state,
        view: nextView,
        // Clear any stored actions on any direct view changes
        storedNextAction: null,
        confirmClose: false, // confirm close should never be true on any view changes
        previousView:
          // review view should never be previous view
          view === EDIT_INSURANCE_VIEWS.review
            ? EDIT_INSURANCE_VIEWS.default
            : view,
        // clear any added or copied from location insurances when going back to default list
        ...(nextView === EDIT_INSURANCE_VIEWS.default &&
          (view === EDIT_INSURANCE_VIEWS.add ||
            view === EDIT_INSURANCE_VIEWS.copyInsurances) && {
            addInsurancesMap: {},
            copyFromLocation: null,
            localSearch: '',
            planFilters: {},
          }),
        // clear any verification edits if user goes into adding.
        ...(view === EDIT_INSURANCE_VIEWS.default &&
          nextView === EDIT_INSURANCE_VIEWS.add && {
            editVerifications: JSON.parse(JSON.stringify(verifications)),
            selectedInsurancesMap: {},
            planFilters: {},
            localSearch: '',
          }),
      }
    }

    case EDIT_INSURANCE_ACTION_TYPES.updateSelectedCarrier: {
      const { selectedCarrier } = payload
      return {
        ...state,
        selectedCarrier,
        // clear any filtering on carrier changes
        localSearch: '',
        planFilters: {},
        // clear addInsurancesMap when user returns back to carrier select
        ...(selectedCarrier === null && {
          addInsurancesMap: {},
        }),
      }
    }

    case EDIT_INSURANCE_ACTION_TYPES.selectSingle: {
      const { insurances } = payload
      const insurance = insurances[0]
      const prevCopy = { ...stateSelectedInsurancesMap }

      if (prevCopy[insurance.uuid]) {
        delete prevCopy[insurance.uuid]
      } else {
        prevCopy[insurance.uuid] = insurance
      }
      return {
        ...state,
        selectedInsurancesMap: prevCopy,
      }
    }

    case EDIT_INSURANCE_ACTION_TYPES.selectList: {
      const { insurances } = payload
      if (!insurances) return state
      const prevCopy = { ...stateSelectedInsurancesMap }
      insurances.forEach(insuranceListItem => {
        // Filter out all Header Items - states / nationwide items
        if (Boolean(insuranceListItem.isHeader) === false) {
          // only add if not existing
          if (!prevCopy[insuranceListItem.uuid]) {
            prevCopy[insuranceListItem.uuid] = insuranceListItem
          }
        }
      })

      return {
        ...state,
        selectedInsurancesMap: prevCopy,
        selectedVerificationFilter: null,
      }
    }

    case EDIT_INSURANCE_ACTION_TYPES.unselectList: {
      const { insurances } = payload
      if (!insurances) return state

      const prevCopy = { ...stateSelectedInsurancesMap }
      insurances.forEach(insuranceListItem => {
        // Filter out all Header Items - states / nationwide items
        if (Boolean(insuranceListItem.isHeader) === false) {
          // only add if existing
          if (prevCopy[insuranceListItem.uuid]) {
            delete prevCopy[insuranceListItem.uuid]
          }
        }
      })

      return {
        ...state,
        selectedInsurancesMap: prevCopy,
      }
    }
    case EDIT_INSURANCE_ACTION_TYPES.unselectAll: {
      return {
        ...state,
        selectedInsurancesMap: {},
        selectedVerificationFilter: null,
      }
    }

    case EDIT_INSURANCE_ACTION_TYPES.selectByFilter: {
      const { insurances, filter } = payload
      if (!insurances) return state
      const newList = {}
      insurances.forEach(insuranceListItem => {
        // Filter out all Header Items - states / nationwide items
        if (Boolean(insuranceListItem.isHeader) === false) {
          newList[insuranceListItem.uuid] = insuranceListItem
        }
      })

      return {
        ...state,
        selectedInsurancesMap: newList,
        selectedVerificationFilter: filter,
      }
    }

    case EDIT_INSURANCE_ACTION_TYPES.toggleConfirmClose: {
      const { storedNextAction } = payload || {}
      return {
        ...state,
        confirmClose: !state.confirmClose,
        storedNextAction,
      }
    }

    case EDIT_INSURANCE_ACTION_TYPES.reportList:
    case EDIT_INSURANCE_ACTION_TYPES.verifyList: {
      const { insurances } = payload
      if (!insurances) return state

      const editVerificationsCopy = { ...editVerifications }
      insurances.forEach(i => {
        if (!i.isHeader) {
          const updatedConfidence =
            type === EDIT_INSURANCE_ACTION_TYPES.verifyList ? 5 : 1
          if (
            verifications[i.uuid] &&
            verifications[i.uuid].confidence === updatedConfidence
          ) {
            // Insurance already has same confidence user wants to update to.
            // Reverting to original verification obj so no changes are detected.
            editVerificationsCopy[i.uuid] = { ...verifications[i.uuid] }
          } else {
            editVerificationsCopy[i.uuid] = {
              confidence:
                type === EDIT_INSURANCE_ACTION_TYPES.verifyList ? 5 : 1,
              fca_user_id: fcaUserId,
              updated_at: dayjs().utc().toISOString(),
            }
          }
        }
      })

      return {
        ...state,
        editVerifications: editVerificationsCopy,
        selectedInsurancesMap: {},
      }
    }

    case EDIT_INSURANCE_ACTION_TYPES.noSelectionList: {
      const { insurances } = payload
      if (!insurances) return state

      const editVerificationsCopy = { ...editVerifications }
      insurances.forEach(i => {
        if (!i.isHeader) {
          if (editVerificationsCopy[i.uuid]) {
            delete editVerificationsCopy[i.uuid]
          }
        }
      })

      return {
        ...state,
        editVerifications: editVerificationsCopy,
        selectedInsurancesMap: {},
      }
    }

    case EDIT_INSURANCE_ACTION_TYPES.updateAddListSingle: {
      const { insurances } = payload
      const insurance = insurances[0]

      const prevCopy = { ...addInsurancesMap }

      if (prevCopy[insurance.uuid]) {
        delete prevCopy[insurance.uuid]
      } else {
        prevCopy[insurance.uuid] = insurance
      }

      return {
        ...state,
        addInsurancesMap: prevCopy,
      }
    }

    case EDIT_INSURANCE_ACTION_TYPES.updateAddListAllAdd: {
      const { insurances } = payload
      if (!insurances) return state

      const prevCopy = { ...addInsurancesMap }
      insurances.forEach(insuranceListItem => {
        // Filter out all Header Items - states / nationwide items
        if (
          Boolean(insuranceListItem.isHeader) === false &&
          // Filter out all insurances this location has already
          Boolean(
            stateInsurances.find(i => i.uuid === insuranceListItem.uuid),
          ) === false
        ) {
          // only add if not existing
          if (!prevCopy[insuranceListItem.uuid]) {
            prevCopy[insuranceListItem.uuid] = insuranceListItem
          }
        }
      })

      return {
        ...state,
        addInsurancesMap: prevCopy,
      }
    }

    case EDIT_INSURANCE_ACTION_TYPES.updateAddListAllUndo: {
      const { insurances } = payload
      if (!insurances) return state

      const prevCopy = { ...addInsurancesMap }
      insurances.forEach(insuranceListItem => {
        // remove if existing
        if (prevCopy[insuranceListItem.uuid]) {
          delete prevCopy[insuranceListItem.uuid]
        }
      })

      return {
        ...state,
        addInsurancesMap: prevCopy,
      }
    }

    default:
      return state
  }
}
