import React, { useMemo } from 'react'
import { Flex } from 'design-system/flex'
import { PageTitle } from '../components/PageTitle'
import { PageContainer } from '../components/PageContainer'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { useBlockLayout, usePagination, useSortBy, useTable } from 'react-table'
import {
  tableDefaultColumn,
  tokensTableColumns,
  tokensTestData,
} from './TokensTableOptions'
import { TokensTable } from './TokensTable'

export const Tokens = () => {
  const defaultColumn = useMemo(() => tableDefaultColumn, [])
  const dataMemo = useMemo(() => tokensTestData, [])
  const columnsMemo = useMemo(() => tokensTableColumns, [])

  const tableInstance = useTable(
    {
      columns: columnsMemo,
      data: dataMemo,
      initialState: { pageIndex: 0, pageSize: 20 },
      defaultColumn,
    },
    useBlockLayout,
    useSortBy,
    usePagination,
  )

  const onClickGenerateToken = () => {
    //
  }

  const onClickRemoveToken = token => {
    console.log(`🚀 => onClickRemoveToken => token`, token)
  }

  return (
    <PageContainer>
      <Flex
        s={() => ({
          justifyContent: 'space-between',
        })}
      >
        <PageTitle>Tokens</PageTitle>
        <Button onClick={onClickGenerateToken} type={BUTTON_TYPE.PRIMARY}>
          Generate API Token
        </Button>
      </Flex>
      <Flex
        s={p => ({
          marginTop: p.theme.sizes.six,
          flex: 1,
          flexDirection: 'column',
        })}
      >
        <TokensTable
          tableInstance={tableInstance}
          onClickRemoveToken={onClickRemoveToken}
        />
      </Flex>
    </PageContainer>
  )
}
