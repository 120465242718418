/* eslint-disable indent */
import { searchTypes } from 'utils/constants'
import {
  Insurance,
  Location,
  PartnerData,
  Provider,
  VirtualLocation,
} from 'services/ribbon/ribbon.types'
import {
  loadFromLocalStorage,
  LOCAL_STORAGE_KEYS,
  saveToLocalStorage,
} from 'utils/localstorage'
import dayjs from 'utils/dayjs'
import { getAddressFromPlaceResult } from 'utils/formatters/format-addresses'
import STATES from '../../../static/constants/states.json'

const STATES_DICT: { [state: string]: string } = STATES

export const isGetLocationAvailable = (): boolean =>
  !navigator || !!navigator.geolocation

interface GeolocationPosition {
  coords: {
    latitude: number
    longitude: number
  }
  timestamp: number
}
export const getCurrentPosition = (): Promise<GeolocationPosition> =>
  new Promise((resolve, reject) => {
    if (!isGetLocationAvailable) reject('Navigator not available')
    // Check localstorage for user's last used coordinates
    const localStorageValue = loadFromLocalStorage(
      LOCAL_STORAGE_KEYS.currentPosition,
    )
    if (localStorageValue) {
      const { timestamp } = localStorageValue
      if (timestamp && dayjs(timestamp).isBefore(dayjs().add(7, 'days'))) {
        return resolve(localStorageValue)
      }
    }
    // Request Permission - Get - Save user coordinates
    return navigator.geolocation.getCurrentPosition(
      result => {
        saveToLocalStorage(LOCAL_STORAGE_KEYS.currentPosition, {
          coords: {
            latitude: result.coords.latitude,
            longitude: result.coords.longitude,
          },
          timestamp: result.timestamp,
        })
        resolve(result)
      },
      reject,
      {
        enableHighAccuracy: false,
        timeout: 7000,
        maximumAge: 15 * 60 * 1000,
      },
    )
  })

type SearchStateInsurance = {
  id: string
  label: string
}

export const locationHasInsurance = (
  insurance: SearchStateInsurance,
  location: { insurances: Insurance[] },
): boolean => {
  if (
    location &&
    (location.insurances || []).find(i => i.uuid === insurance.id)
  ) {
    return true
  }
  return false
}

export const virtualLocationHasInsurance = (
  insurance: SearchStateInsurance,
  partnerData: { virtualLocations: Record<string, any>[] },
): boolean => {
  return (
    (partnerData?.virtualLocations ?? []).find(i =>
      i.insurances?.includes(insurance.id),
    ) !== undefined
  )
}

export const currentLocationOption = {
  id: 'current_location',
  label: 'Use my current location',
  value: 'Current Location',
  data: {
    current_location: true,
  },
}

interface CurrentPageResults {
  data: {
    locations: { distance: number }[]
    distance: number
    latitude: number
    longitude: number
  }[]
}
export const getLocationsFromSearchResults = (
  isLoading: boolean,
  currentPageResults: CurrentPageResults,
  searchState: Record<string, any>,
): [Record<string, unknown>[], Record<string, unknown>] => {
  if (isLoading) return [[], {}]
  const {
    searchType,
    providersSearchQuery,
    facilitiesSearchQuery,
  } = searchState

  let location = null
  if (searchType === searchTypes.providers) {
    if (providersSearchQuery) {
      location = providersSearchQuery.location
    }
  }
  if (searchType === searchTypes.facilities) {
    if (facilitiesSearchQuery) {
      location = facilitiesSearchQuery.location
    }
  }

  if (!location) return [[], {}]
  const furthestDistanceOnPage =
    currentPageResults &&
      currentPageResults.data &&
      currentPageResults.data.length
      ? currentPageResults.data[currentPageResults.data.length - 1].distance
      : 0
  const locations =
    searchType === searchTypes.providers
      ? ((currentPageResults && currentPageResults.data) || []).reduce(
        (acc, next) => {
          // Get All Provider Locations within distance
          const filteredLocations = next.locations.filter(
            l => l.distance <= furthestDistanceOnPage,
          )
          /**
           * Only returning the first filtered Provider Location here
           * as only one appears on the result list view. Although there could
           * be more here for this distance.
           */
          if (filteredLocations.length > 0) {
            // types need to be sorted for this to work
            // eslint-disable-next-line
            // @ts-ignore
            acc.push({ ...filteredLocations[0], provider: next })
          }

          return acc
        },
        [],
      )
      : // Facility
      (currentPageResults &&
        currentPageResults.data && [...currentPageResults.data]) ||
      []

  /**
   * Reversing locations so the pins on the map, have the top correspond with
   * the first on the list of results
   */
  const reversedLocations = locations.reverse()
  const ranges = reversedLocations.reduce(
    (acc: { minLat: number, maxLat: number, minLng: number, maxLng: number }, next) => {
      if (acc.maxLat === -1 || next.latitude > acc.maxLat) {
        acc.maxLat = next.latitude
      }
      if (acc.minLat === -1 || next.latitude < acc.minLat) {
        acc.minLat = next.latitude
      }
      if (acc.maxLng === -1 || next.longitude > acc.maxLng) {
        acc.maxLng = next.longitude
      }
      if (acc.minLng === -1 || next.longitude < acc.minLng) {
        acc.minLng = next.longitude
      }
      return acc
    },
    { minLat: -1, maxLat: -1, minLng: -1, maxLng: -1 },
  )

  return [reversedLocations, ranges]
}

export const formatLocationTypes = (locationTypes: Location): string => {
  if (!locationTypes) {
    return ''
  }

  const facilityTypesLabels = locationTypes.reduce(
    (acc: string[], curr: string) => {
      if (acc.length === 1) {
        acc.push(`+${locationTypes.length - 1} more`)
      } else if (acc.length === 0) {
        acc.push(curr)
      }
      return acc
    },
    [],
  )

  return facilityTypesLabels.join('\n')
}

export const removeSelectedLocationFromLocationsList = (
  locations: Location[],
  selectedLocation: Location,
): Location[] => {
  if (!selectedLocation) return locations
  const locationsCopy = [...locations]
  const foundLocationIndex = locationsCopy.findIndex(
    l => l.uuid === selectedLocation.uuid,
  )

  if (foundLocationIndex >= 0) {
    locationsCopy.splice(foundLocationIndex, 1)[0]
  }

  return locationsCopy
}

type LocationsMappedByConfidence = {
  highConfidence: Location[]
  lowConfidence: Location[]
}

export const reorderLocationsByDistanceAndHighConfidenceFirst = (
  locations: Location[],
): LocationsMappedByConfidence => {
  const highConfidenceLocationsSortedByDistance = locations
    .filter(l => l.confidence >= 3)
    .sort((a, b) => {
      return a.distance > b.distance ? 1 : -1
    })
  const lowConfidenceLocationsSortedByDistance = locations
    .filter(l => l.confidence < 3)
    .sort((a, b) => {
      return a.distance > b.distance ? 1 : -1
    })

  return {
    highConfidence: highConfidenceLocationsSortedByDistance,
    lowConfidence: lowConfidenceLocationsSortedByDistance,
  }
}

export const sortVirtualLocations = (
  locations: VirtualLocation[],
): VirtualLocation[] => {
  return [...locations].sort(
    (a, b) =>
      a.partnerName.localeCompare(b.partnerName) ||
      a.state.localeCompare(b.state),
  )
}

type ZipcodeAndState = {
  zipcode?: string
  state?: string
}
export const getZipcodeAndStateFromReverseGeocode = (results = []): ZipcodeAndState => {
  let closestZipcode, state
  if (results.length > 0) {
    results.some(result => {
      const formattedAddress = getAddressFromPlaceResult(result)
      if (formattedAddress.zipcode && formattedAddress.zipcode !== '-') {
        closestZipcode = formattedAddress.zipcode
      }
      if (formattedAddress.state) {
        state = formattedAddress.state
      }
      return true
    })
  }
  return {
    zipcode: closestZipcode,
    state: state,
  }
}

type OtherLocationsResult = { label: string; value: string; uuid: string }[]

export const listOtherLocationsWithInsurances = (
  locations: Location[],
): OtherLocationsResult => {
  return locations.reduce((result: OtherLocationsResult, curr: Location) => {
    if (curr.insurances && curr.insurances.length > 0) {
      result.push({
        label: curr.address,
        value: curr.address,
        uuid: curr.uuid,
      })
    }
    return result
  }, [])
}

export const getProviderPhysicalLocations = (
  provider: Provider,
): Location[] => {
  const physical_locations = provider?.locations ?? []
  return [...physical_locations]
}

export const getProviderVirtualLocations = (
  provider: Provider,
): VirtualLocation[] => {
  const virtualLocations = []
  for (const partner of provider?.partnerData ?? []) {
    for (const location of partner?.virtualLocations ?? []) {
      const location_with_uuid: VirtualLocation = {
        uuid: getVirtualLocationId(partner, location),
        partnerId: partner.partnerId,
        partnerName: partner.partnerName,
        state: location.state,
        insurances: location.insurances ?? [],
        ...location,
      }
      virtualLocations.push(location_with_uuid)
    }
  }
  return virtualLocations
}

export const getVirtualLocationId = (
  partner: PartnerData,
  location: VirtualLocation | Record<string, any>,
): string => {
  return `${partner.partnerId}-${location.state}`
}

export const getProviderLocationsCount = (
  provider: Provider,
  virtualLocationsEnabled: boolean,
): number => {
  const physicalLocations = getProviderPhysicalLocations(provider)
  const virtualLocations = getProviderVirtualLocations(provider)
  return (
    physicalLocations.length +
    (virtualLocationsEnabled ? virtualLocations.length : 0)
  )
}

export const isLocationVirtual = (location: { address?: string } | undefined): boolean => {
  if (!location) {
    return false
  }
  return location['address'] === undefined || location['address'] === null
}

export const isPartnerLocation = (location: { partnerId?: string } | undefined): boolean => {
  if (!location) {
    return false
  }
  return location.partnerId !== undefined && location.partnerId !== null
}

export const getPartnerLocationName = (location: VirtualLocation): string => {
  if (!location) {
    return ''
  }
  return `${location.partnerName}${location.state ? ` - ${STATES_DICT[location.state.toLowerCase()]}` : ''
    }`
}
