import React, { useState } from 'react'
import { Flex } from 'design-system/flex'
import { PageContainer } from '../components/PageContainer'
import { PageTitle } from '../components/PageTitle'
import { SummaryCard } from './SummaryCard'
import { PageDivider } from '../components/PageDivider'
import styled from 'styled-components'
import { DetailsModal } from './DetailsModal'

export const Syncing = () => {
  const [activeDetails, setActiveDetails] = useState(null)
  return (
    <PageContainer>
      <Flex
        s={() => ({
          justifyContent: 'space-between',
        })}
      >
        <PageTitle>Syncing</PageTitle>
      </Flex>
      <Flex
        s={p => ({
          marginTop: p.theme.sizes.six,
          flex: 1,
          flexDirection: 'column',
        })}
      >
        <SummaryCard
          type="Overall Summary"
          onClickViewDetails={() => setActiveDetails('Overall')}
        />
        <PageDivider />
        <SummaryCard type="Providers" onClickViewDetails={setActiveDetails} />
        <EmptyDivider />
        <SummaryCard type="Locations" onClickViewDetails={setActiveDetails} />
        <EmptyDivider />
        <SummaryCard type="Insurances" onClickViewDetails={setActiveDetails} />
        <EmptyDivider />
        <SummaryCard type="Specialties" onClickViewDetails={setActiveDetails} />
      </Flex>
      {activeDetails && (
        <DetailsModal
          handleClose={() => setActiveDetails(null)}
          type={activeDetails}
        />
      )}
    </PageContainer>
  )
}

const EmptyDivider = styled(PageDivider)`
  background-color: transparent;
  margin: ${p => p.theme.sizes.five} 0;
`
