import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Label } from 'design-system/label'
import { SelectInput } from 'design-system/input/select-input'
import { Button } from 'design-system/button'
import { Flex } from 'design-system/flex'
import PlusSVG from 'assets/plus.svg'
import CopySVG from 'assets/copy.svg'
import { searchTypes } from 'utils/constants'
import { NoInsuranceSearchResultsContainer } from './no-insurance-search-results'

const Container = styled(Flex)`
  flex: 1;
  flex-direction: column;
  padding: ${p => p.theme.sizes.four} ${p => p.theme.sizes.six};
`

export const EmptyInsurancesContainer = ({
  otherLocations,
  onSelectLocation,
  onClickAddInsurances,
  showCopyInsurances,
  onClickCopyInsurances,
  selectedLocation,
  type,
}) => {
  const onSelectLocationCallback = e => {
    const { uuid } = e.target
    onSelectLocation(uuid)
  }

  const locationOptions = otherLocations

  return (
    <Container>
      {showCopyInsurances ? (
        <Flex
          s={() => ({
            flexDirection: 'column',
          })}
        >
          <Label
            s={p => ({
              fontSize: p.theme.headingSizes.H3,
              fontWeight: 'bold',
            })}
          >
            Copy Insurances from Location
          </Label>

          <SelectInput
            onChange={onSelectLocationCallback}
            name="location"
            value={selectedLocation ? selectedLocation.address : ''}
            options={locationOptions}
            containerStyle={{ marginTop: '0.5rem' }}
            placeholder="Select Location"
          />
        </Flex>
      ) : (
        <Flex
          s={() => ({
            alignSelf: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            maxWidth: 450,
            ['> p']: {
              textAlign: 'center',
            },
          })}
        >
          <NoInsuranceSearchResultsContainer />
          <Label>
            {locationOptions.length > 0
              ? `Start by adding an insurance or copy insurances from another location from this provider.`
              : `Start by adding an insurance`}
          </Label>
          <Flex
            s={p => ({
              marginTop: p.theme.sizes.four,
              ['> div']: {
                margin: `0 ${p.theme.sizes.two}`,
              },
            })}
          >
            {type === searchTypes.providers && locationOptions.length > 0 && (
              <Button onClick={onClickCopyInsurances}>
                <CopySVG
                  height={16}
                  width={16}
                  style={{ marginRight: '12px' }}
                />
                <Label>Copy Insurances</Label>
              </Button>
            )}
            <Button onClick={onClickAddInsurances}>
              <PlusSVG height={16} width={16} style={{ marginRight: '12px' }} />
              <Label>Add Insurances</Label>
            </Button>
          </Flex>
        </Flex>
      )}
    </Container>
  )
}

EmptyInsurancesContainer.propTypes = {
  otherLocations: PropTypes.array,
  onSelectLocation: PropTypes.func,
  onClickAddInsurances: PropTypes.func,
  showCopyInsurances: PropTypes.bool,
  onClickCopyInsurances: PropTypes.func,
  selectedLocation: PropTypes.object,
  type: PropTypes.string,
}
