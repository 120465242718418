import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { VirtualSearchResultPanel } from 'components/search-result-panel/virtual-search-result-panel.component'
import { Block } from 'design-system/block'
import { GlobalStateContext } from 'context/global-context-provider'
import { formatInsuranceLabelForVirtual } from 'utils/formatters/format-insurance-labels'
import { SearchNoResults } from './search-no-results'
import { formatProviderSubtitle } from 'utils/formatters/format-provider-subtitle'

export const VirtualProviderSearchResultsPanel = ({
  results,
  resultsMetadata,
}) => {
  const [searchState] = useContext(GlobalStateContext)

  if (results.length === 0) {
    return <SearchNoResults />
  }

  return (
    <>
      {results.map(result => {
        const {
          npi,
          firstName,
          lastName,
          ratingsCount = 0,
          ratingsAvg,
          partnerData = [],
        } = result
        return (
          <Block
            key={`provider-result-${npi}`}
            s={p => ({
              padding: `${p.theme.sizes.two} 0`,
            })}
          >
            <VirtualSearchResultPanel
              id={npi}
              type={'providers'}
              title={`${firstName} ${lastName}`}
              subtitle={formatProviderSubtitle(resultsMetadata, result)}
              score={ratingsCount > 0 ? ratingsAvg / 2 : undefined}
              showInsuranceLabel={formatInsuranceLabelForVirtual(
                searchState.providersSearchQuery,
                partnerData,
              )}
              matchingLocation={searchState.location}
              result={result}
            />
          </Block>
        )
      })}
    </>
  )
}

VirtualProviderSearchResultsPanel.propTypes = {
  onHover: PropTypes.func,
  results: PropTypes.arrayOf(PropTypes.object),
  resultsMetadata: PropTypes.object,
}
