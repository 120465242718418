import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex } from 'design-system/flex'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Modal } from 'design-system/modal'
import CloseSVG from 'assets/close-x.svg'
import { PermissionRestricted } from 'components/permission-restricted/permission-restricted'
import { Button, BUTTON_TYPE } from 'design-system/button'
import {
  getPartnerLocationName,
  getProviderLocationsCount,
  getProviderPhysicalLocations,
  getProviderVirtualLocations, isLocationVirtual,
  removeSelectedLocationFromLocationsList,
  reorderLocationsByDistanceAndHighConfidenceFirst,
  sortVirtualLocations,
} from 'utils/location'
import PlusSVG from 'assets/plus.svg'
import {
  ReportIcon,
  VerifiedIcon,
  LowConfidenceIcon,
  MediumConfidenceIcon,
} from 'components/verification-toggle/verification-toggle'
import { useAuthState } from 'context/auth-state-context'
import { searchTypes } from 'utils/constants'
import { getModulesFromAuthState } from 'utils/modules'

const CloseBtnContainer = styled(Button)`
  > button {
    padding: ${p => p.theme.sizes.two};
    max-height: 32px;
  }
  border-radius: 4px;
  border-color: transparent;
  margin-right: ${p => p.theme.sizes.four};
`

const LocationsListContainer = styled(Flex)`
  flex-direction: column;
  padding: 0 ${p => p.theme.sizes.four};
`

const LocationListItemContainer = styled(Flex)`
  flex: 1;
  padding: ${p => p.theme.sizes.four};

  margin-bottom: ${p => p.theme.sizes.four};
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${p => p.theme.colors.borderDefault};
  border-radius: 5px;
  background: ${p => p.theme.colors.white};

  #switch-location-label {
    visibility: hidden;
  }

  ${p => ({
    ...(p.isSelected && {
      borderColor: p.theme.colors.gray0,
    }),
  })}

  :hover {
    background: ${p => p.theme.colors.gray7};
    cursor: pointer;
    ${p => ({
      ...(p.isSelected && { cursor: 'default', background: 'white' }),
    })}
    #switch-location-label {
      visibility: visible;
    }
  }
`

const ListItemRightColumn = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  margin-left: ${p => p.theme.sizes.four};
  min-width: 130px;
  text-align: right;
  p:nth-of-type(2) {
    margin-top: 8px;
  }
`

const ListItemLeftColumn = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  p:nth-of-type(2) {
    margin-top: 8px;
    min-height: 20px;
  }
`

const ListHeaderLabel = styled(Heading)`
  margin-top: 36px;
  margin-bottom: ${p => p.theme.sizes.four};
`

const ConfidenceIconContainer = styled.div`
  margin-left: 4px;
`

export const LocationsListModal = ({
  closeModal = () => {},
  selectedLocation,
  onLocationSelect = () => {},
  onClickAddLocation = () => {},
  provider,
}) => {
  const [authState] = useAuthState()
  const providerModules = getModulesFromAuthState(authState, searchTypes.providers)
  const isVirtualSearchEnabled = providerModules.virtual_search?.enabled
  const [localLocations, setLocalLocations] = useState(getProviderPhysicalLocations(provider))
  const [virtualLocations, setVirtualLocations] = useState(getProviderVirtualLocations(provider))

  useEffect(() => {
    setLocalLocations(getProviderPhysicalLocations(provider))
    setVirtualLocations(getProviderVirtualLocations(provider))
  }, [provider])

  const onClickLocation = location => {
    onLocationSelect(location)
    closeModal()
  }

  const renderLocationsList = locations => {
    return (
      <>
        {locations.map(location => {
          const { uuid, address, name, distance, confidence } = location
          const isSelected = selectedLocation && selectedLocation.uuid === uuid
          const isReported = confidence === 0
          const isVerified = confidence === 5
          const isLowConfidence = confidence > 0 && confidence < 3
          const isMediumConfidence = confidence > 2 && confidence < 5
          return (
            <LocationListItemContainer
              key={uuid}
              isSelected={isSelected}
              onClick={() => (!isSelected ? onClickLocation(location) : {})}
            >
              <ListItemLeftColumn>
                {name && (
                  <Flex
                    s={p => ({
                      alignItems: 'center',
                      marginBottom: p.theme.sizes.one,
                    })}
                  >
                    <Label type={LABEL_TYPE.BODY1_BOLD}>{name}</Label>
                    <ConfidenceIconContainer>
                      {isReported && <ReportIcon />}
                      {isLowConfidence && <LowConfidenceIcon />}
                      {isMediumConfidence && <MediumConfidenceIcon />}
                      {isVerified && <VerifiedIcon />}
                    </ConfidenceIconContainer>
                  </Flex>
                )}
                <Flex
                  s={() => ({
                    alignItems: 'center',
                  })}
                >
                  <Label type={LABEL_TYPE.BODY1}>{address}</Label>
                  {!name && (
                    <ConfidenceIconContainer>
                      {isReported && <ReportIcon />}
                      {isLowConfidence && <LowConfidenceIcon />}
                      {isMediumConfidence && <MediumConfidenceIcon />}
                      {isVerified && <VerifiedIcon />}
                    </ConfidenceIconContainer>
                  )}
                </Flex>
              </ListItemLeftColumn>

              {isSelected ? (
                <ListItemRightColumn>
                  <Label>Current Location</Label>
                  {distance && <Label>{`${distance} miles`}</Label>}
                </ListItemRightColumn>
              ) : (
                <ListItemRightColumn>
                  <Label
                    id="switch-location-label"
                    s={p => ({
                      color: p.theme.colors.gray3,
                      [':hover']: {
                        color: p.theme.colors.gray2,
                      },
                    })}
                    onClick={() =>
                      !isSelected ? onClickLocation(location) : {}
                    }
                  >
                    Switch Location
                  </Label>
                  {distance && <Label>{`${distance} miles`}</Label>}
                </ListItemRightColumn>
              )}
            </LocationListItemContainer>
          )
        })}
      </>
    )
  }

  const renderVirtualLocationsList = locations => {
    return (
      <>
        {locations.map(location => {
          const { uuid, partnerName } = location
          const isSelected = selectedLocation && selectedLocation.uuid === uuid

          return (
            <LocationListItemContainer
              key={uuid}
              isSelected={isSelected}
              onClick={() => (!isSelected ? onClickLocation(location) : {})}
            >
              <ListItemLeftColumn>
                {partnerName && (
                  <Flex
                    s={p => ({
                      alignItems: 'center',
                      marginBottom: p.theme.sizes.one,
                    })}
                  >
                    <Label type={LABEL_TYPE.BODY1_BOLD}>{getPartnerLocationName(location)}</Label>
                  </Flex>
                )}
                <Flex
                  s={() => ({
                    alignItems: 'center',
                  })}
                >
                  <Label type={LABEL_TYPE.BODY1}>View virtual platform to see booking details.</Label>
                </Flex>
              </ListItemLeftColumn>

              {isSelected ? (
                <ListItemRightColumn>
                  <Label>Current Location</Label>
                  <Label>Virtual</Label>
                </ListItemRightColumn>
              ) : (
                <ListItemRightColumn>
                  <Label
                    id="switch-location-label"
                    s={p => ({
                      color: p.theme.colors.gray3,
                      [':hover']: {
                        color: p.theme.colors.gray2,
                      },
                    })}
                    onClick={() =>
                      !isSelected ? onClickLocation(location) : {}
                    }
                  >
                    Switch Location
                  </Label>
                  <Label>Virtual</Label>
                </ListItemRightColumn>
              )}
            </LocationListItemContainer>
          )
        })}
      </>
    )
  }

  const locationsWithoutSelected = removeSelectedLocationFromLocationsList(
    localLocations,
    selectedLocation,
  )
  const virtualLocationsWithoutSelected = removeSelectedLocationFromLocationsList(
    virtualLocations,
    selectedLocation
  )

  const { highConfidence, lowConfidence } = reorderLocationsByDistanceAndHighConfidenceFirst(
    locationsWithoutSelected,
  )
  const sortedVirtualLocations = sortVirtualLocations(virtualLocationsWithoutSelected)
  return (
    <Modal
      onClose={closeModal}
      nested
      header={
        <Flex
          s={p => ({
            justifyContent: 'space-between',
            padding: p.theme.sizes.six,
            alignItems: 'flex-start',
          })}
        >
          <Flex
            s={() => ({
              flexDirection: 'column',
              justifyContent: 'space-between',
            })}
          >
            <Heading type={HEADING_TYPE.H2}>All Locations</Heading>
            <Flex
              s={p => ({
                alignItems: 'center',
                marginTop: p.theme.sizes.four,
              })}
            >
              <Label
                s={p => ({
                  color: p.theme.colors.textLightest,
                  fontWeight: '400',
                })}
              >
                {`Showing ${getProviderLocationsCount(provider, isVirtualSearchEnabled)} locations`}
              </Label>
              <PermissionRestricted level={2}>
                <Button
                  s={p => ({
                    ['> button']: {
                      padding: 0,
                      height: '24px',
                      width: '24px',
                    },
                    borderRadius: '50%',
                    marginLeft: p.theme.sizes.four,
                  })}
                  type={BUTTON_TYPE.DEFAULT}
                  onClick={onClickAddLocation}
                  data-testid="add-locaton-btn"
                >
                  <PlusSVG height={12} width={12} />
                </Button>
              </PermissionRestricted>
            </Flex>
          </Flex>

          <CloseBtnContainer type={BUTTON_TYPE.DEFAULT} onClick={closeModal}>
            <CloseSVG />
          </CloseBtnContainer>
        </Flex>
      }
    >
      <LocationsListContainer>
        {selectedLocation && (isLocationVirtual(selectedLocation) ?
          renderVirtualLocationsList([selectedLocation]) :
          renderLocationsList([selectedLocation]))
        }
        {isVirtualSearchEnabled && sortedVirtualLocations?.length > 0 && (
          <>
            <ListHeaderLabel
              type={HEADING_TYPE.H3}
              s={() => ({
                fontWeight: 700,
              })}
            >
              Virtual Platforms
            </ListHeaderLabel>
            {renderVirtualLocationsList(sortedVirtualLocations)}
          </>
        )}
        {highConfidence?.length > 0 && (
          <>
            <ListHeaderLabel
              type={HEADING_TYPE.H3}
              s={() => ({
                fontWeight: 700,
              })}
            >
              High Confidence Locations
            </ListHeaderLabel>
            {renderLocationsList(highConfidence)}
          </>
        )}
        {lowConfidence?.length > 0 && (
          <>
            <ListHeaderLabel type={HEADING_TYPE.H3}>
              Low Confidence Locations
            </ListHeaderLabel>
            {renderLocationsList(lowConfidence)}
          </>
        )}
      </LocationsListContainer>
    </Modal>
  )
}

LocationsListModal.propTypes = {
  closeModal: PropTypes.func,
  locations: PropTypes.array,
  selectedLocation: PropTypes.object,
  provider: PropTypes.object,
  onLocationSelect: PropTypes.func,
  onClickAddLocation: PropTypes.func,
  refreshProvider: PropTypes.func,
  onClickEditLocation: PropTypes.func,
}
