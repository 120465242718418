import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Label } from 'design-system/label'
import styled from 'styled-components'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { useToggle } from 'hooks/useToggle'
import { DropdownMenu, Overlay } from 'components/dropdown/dropdown'
import dayjs from 'utils/dayjs'
import { Flex } from 'design-system/flex'
import Clipboard from 'assets/clipboard.svg'
import { NotyfContext } from 'context/notyf-context'

export const InstanceInfo = ({
  instance = {},
  onClickDeactivateInstance = () => {},
}) => {
  if (!instance) return null
  return (
    <Flex
      s={p => ({
        flexDirection: 'column',
        minWidth: '310px',
        maxWidth: '310px',
        backgroundColor: p.theme.colors.white,
        padding: `${p.theme.sizes.six} ${p.theme.sizes.four}`,
        borderRight: `1px solid ${p.theme.colors.gray4}`,
        borderBottom: `1px solid ${p.theme.colors.gray4}`,
        height: '100%',
      })}
    >
      <Label
        s={p => ({
          fontWeight: '700',
          fontSize: p.theme.sizes.five,
          alignSelf: 'center',
        })}
      >
        {instance.name}
      </Label>
      <InfoBlock>
        <InfoRow
          label="UUID"
          value={instance.uuid}
          renderValue={() => (
            <ToggleView viewLabel="View UUID" viewValue={instance.uuid} />
          )}
        />
        <InfoRow
          label="Account ID"
          value={instance.account_id}
          renderValue={() => (
            <ToggleView viewLabel="View ID" viewValue={instance.account_id} />
          )}
        />
        <InfoRow
          label="Created on"
          value={dayjs(
            instance.fca_config ? instance.fca_config.created_at : '',
          ).format('LL')}
        />
        <InfoRow
          label="Last activity"
          value={dayjs(instance.updated_at).format('LL')}
        />
        <InfoRow
          label="Users"
          value={instance.backend_data ? instance.backend_data.num_users : ''}
        />
        <InfoRow
          label="Algolia App ID"
          value={
            instance.location_directory
              ? instance.location_directory.algolia_app_id
              : ''
          }
          renderValue={() => (
            <ToggleView
              viewLabel="View ID"
              viewValue={
                instance.location_directory
                  ? instance.location_directory.algolia_app_id
                  : ''
              }
            />
          )}
        />
        <InfoRow
          label="Location Index"
          value={
            instance.location_directory ? instance.location_directory.index : ''
          }
          renderValue={() => (
            <ToggleView
              viewLabel="View Index"
              viewValue={
                instance.location_directory
                  ? instance.location_directory.index
                  : ''
              }
            />
          )}
        />
        <InfoRow label="API Version" value={instance.api_version} />
        <InfoRow
          label="Status"
          value={instance.account_is_expired ? 'Expired' : 'Active'}
        />
      </InfoBlock>

      <ManageInstanceButtonContainer
        onClickDeactivateInstance={onClickDeactivateInstance}
      />
    </Flex>
  )
}

const ToggleView = ({ viewLabel = 'Label', viewValue = 'Value' }) => {
  const [show, setShow] = useState(false)
  const notyf = useContext(NotyfContext)
  const onClickCopy = () => {
    // copy to clipboard
    if (window !== 'undefined' && navigator && navigator.clipboard) {
      navigator.clipboard.writeText(viewValue)
      notyf.open({ type: 'info', message: `Copied ${viewValue} to clipboard.` })
    }
  }

  return (
    <Flex
      s={() => ({
        flexWrap: 'none',
      })}
    >
      <Label
        s={() => ({
          fontWeight: '500',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          flex: 1,
          textAlign: 'right',
          maxWidth: '100px',
          [':hover']: {
            textDecoration: 'underline',
          },
        })}
        onClick={() => setShow(prev => !prev)}
        data-tip={show ? viewValue : ''}
      >
        {show ? viewValue : viewLabel}
      </Label>
      {show && (
        <Flex
          onClick={onClickCopy}
          s={() => ({
            [':hover']: {
              cursor: 'pointer',
            },
          })}
        >
          <Clipboard />
        </Flex>
      )}
    </Flex>
  )
}

const ManageInstanceButtonContainer = ({
  onClickDeactivateInstance = () => {},
}) => {
  const [show, toggle] = useToggle(false)
  return (
    <div>
      {show && <Overlay onClick={() => toggle(false)} />}
      <ButtonContainer>
        <Button
          onClick={() => toggle(true)}
          type={BUTTON_TYPE.DEFAULT}
          size={BUTTON_SIZE.LARGE}
        >
          Manage Instance
        </Button>
        {show && (
          <DropdownMenu
            options={[
              {
                title: 'Deactivate instance',
                onClick: () => {
                  onClickDeactivateInstance()
                  toggle(false)
                },
              },
            ]}
          />
        )}
      </ButtonContainer>
    </div>
  )
}

const ButtonContainer = styled.div`
  position: relative;
  & > div {
    top: -85px;
  }
`

const InfoBlock = styled.div`
  flex: 1;
  margin-top: ${p => p.theme.sizes.six};
  margin-bottom: ${p => p.theme.sizes.six};
`
const InfoRow = ({ label = 'Label', value = 'Value', renderValue = null }) => {
  return (
    <Flex
      s={p => ({
        justifyContent: 'space-between',
        marginBottom: p.theme.sizes.two,
        wordWrap: '',
      })}
    >
      <Label
        s={p => ({
          padding: 0,
          color: p.theme.colors.gray2,
          fontWeight: '400',
          lineHeight: p.theme.sizes.five,
          flex: 1,
          marginRight: p.theme.sizes.six,
        })}
      >
        {label}
      </Label>
      {renderValue ? (
        renderValue()
      ) : (
        <Label
          s={p => ({
            padding: 0,
            flex: 1,
            color: p.theme.colors.gray1,
            fontWeight: '400',
            wordBreak: 'break-all',
            textAlign: 'right',
          })}
        >
          {value}
        </Label>
      )}
    </Flex>
  )
}

InstanceInfo.propTypes = {
  instance: PropTypes.object,
  onClickDeactivateInstance: PropTypes.func,
}
