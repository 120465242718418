import React from 'react'
import { Router } from '@reach/router'
import { Layout } from './layout.component'
import { AuthenticatedRoutes } from 'config/route-config'

export const DefaultLayout = () => {
  return (
    <Layout>
      <Router>
        {AuthenticatedRoutes.map(route => {
          const { path, component: RouteComponent } = route
          return <RouteComponent key={path} path={path} />
        })}
      </Router>
    </Layout>
  )
}
