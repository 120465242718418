import React from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

const UnstyledList = props => {
  return (
    <ul className={props.className}>
      {('length' in props.children ? props.children : [props.children]).map(
        (c, i) => (
          <li key={`list-item-${i}`}>{c}</li>
        ),
      )}
    </ul>
  )
}

UnstyledList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  sItemContainer: PropTypes.func,
}

export const List = styled(UnstyledList)`
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: white;
  > li {
    margin: 0;
    :hover {
      background: ${props =>
        !props.disableItemHoverStyle ? props.theme.colors.gray6 : 'none'};
    }
    ${props => (props.sItemContainer ? css(props.sItemContainer(props)) : '')}
  }
  ${props => (props.s ? css(props.s(props)) : '')}
`

List.propTypes = {
  s: PropTypes.func,
  sItemContainer: PropTypes.func,
  onItemSelect: PropTypes.func,
  disableItemHoverStyle: PropTypes.bool,
}

List.defaultProps = {
  s: () => ({}),
  disableItemHoverStyle: false,
}
