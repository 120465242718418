import React, { useCallback, useState } from 'react'
import { Block } from 'design-system/block'
import { Label, LABEL_TYPE } from 'design-system/label'
import { colors } from 'theme/colors'
import { Chevron } from 'design-system/icon/icons/chervon-left'
import styled from 'styled-components'

const ListItemLabel = styled(Label)`
  ${p => ({
    marginTop: p.theme.sizes.four,
    marginBottom: p.theme.sizes.four,
  })}
`

export const ClinicalAreaListItem = ({
  clinicalArea = {
    display: 'Clinical Area',
    conditions: [],
    treatments: [],
    uuid: '',
  },
  isLast = false,
  isFacilityPage = false
}) => {
  const [expanded, setExpanded] = useState(false)
  const onClick = useCallback(() => {
    setExpanded(prev => !prev)
  }, [])

  const { display, conditions, treatments } = clinicalArea
  return (
    <Block>
      <Block
        type="FLEX"
        s={p => ({
          padding: 0,
          justifyContent: 'space-between',
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: colors.gray4,
          paddingBottom: p.theme.sizes.two,
          marginBottom: p.theme.sizes.two,
          cursor: 'pointer',
          alignItems: 'center',
          ...(isLast &&
            !expanded && {
            borderBottomWidth: 0,
            paddingBottom: 0,
            marginBottom: 0,
          }),
        })}
        onClick={onClick}
      >
        <Label
          s={() => ({
            flex: 0.4,
          })}
          type={LABEL_TYPE.BODY1_BOLD}
        >
          {display}
        </Label>
        {!isFacilityPage &&
          <Label
            s={p => ({
              flex: 0.2,
              color: p.theme.colors.gray1,
            })}
          >
            {`${conditions.length} conditions`}
          </Label>}
        <Label
          s={p => ({
            flex: 0.2,
            color: p.theme.colors.gray1,
          })}
        >
          {`${treatments.length} treatments`}
        </Label>
        <Chevron direction={expanded ? 'up' : 'down'} />
      </Block>
      {expanded && (
        <Block
          s={() => ({
            padding: 0,
            paddingBottom: '2rem',
          })}
        >
          {!isFacilityPage &&
            <>
              <ListItemLabel type={LABEL_TYPE.BODY1_BOLD}>Conditions</ListItemLabel>
              {conditions.map(item => {
                return (
                  <Label
                    key={item.uuid}
                    s={p => ({
                      color: p.theme.colors.gray0Alpha70,
                      marginBottom: p.theme.sizes.two,
                    })}
                  >
                    {item.display}
                  </Label>
                )
              })}
            </>
          }
          <ListItemLabel type={LABEL_TYPE.BODY1_BOLD}>Treatments</ListItemLabel>
          {treatments.map(item => {
            return (
              <Label
                key={item.uuid}
                s={p => ({
                  color: p.theme.colors.gray0Alpha70,
                  marginBottom: p.theme.sizes.two,
                })}
              >
                {item.display}
              </Label>
            )
          })}
        </Block>
      )}
    </Block>
  )
}
