import React from 'react'
import HeadwayLogo from 'assets/logos/headway.svg'


/**
 * The PARTNERS constant stores static information about Ribbon's data partners. Example fields that a partner can have
 * include: description, specialty, logo.
 * In the future, if we have more partners, we may pull some or all of this information directly from the Core API.
 * @type object
 */
export const PARTNERS = {
  1: {
    // eslint-disable-next-line react/display-name
    'logo': (style) => <HeadwayLogo style={style} />,
    'name': 'Headway',
    'description': 'Headway is building a new mental healthcare system that everyone can access. ' +
      '1 in 4 people in the US have a treatable mental health condition, but the vast majority do not get ' +
      'the care they need. The primary reason is cost: the majority of therapists do not accept insurance, ' +
      'meaning paying for therapy is prohibitively expensive for prospective patients. That’s why Headway ' +
      'is building the first software-enabled network of therapists who accept health insurance, making it ' +
      'easy for therapists to grow their practices, and possible for patients to find quality care they can afford.',
    'specialty': 'Behavioral Health'
  }
}
