import React, { useContext, useRef, useState } from 'react'
import { CSVReader } from 'react-papaparse'
import styled from 'styled-components'
import { Modal } from 'design-system/modal'
import { Block } from 'design-system/block'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Spinner } from 'design-system/spinner/spinner.component'
import { colors } from 'theme/colors'
import { Flex } from 'design-system/flex'
import { validEmail } from 'utils/validations'
import { NotyfContext } from 'context/notyf-context'

export const UploadUsersModal = ({
  handleClose = () => {},
  handleAddUsers = async () => {},
}) => {
  const [state, setState] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const csvUploadRef = useRef()
  const notyf = useContext(NotyfContext)

  const onClickAdd = async () => {
    console.log(`🚀 => onClickAdd => state`, state)

    const users = state.map(i => {
      const { data } = i
      return {
        firstName: data[0],
        lastName: data[1],
        email: data[2],
        permissionLevel: data[3] ? Number(data[3]) : 1,
        isSso: data[4] ? data[4].toLowerCase() === 'true' : false,
      }
    })
    // API call to add users
    setIsLoading(true)
    try {
      await handleAddUsers(users)
      setIsLoading(false)
      handleClose()
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        const { data, status } = error.response
        if (status === 409) {
          if (data && data.message) {
            notyf.error(error.response.data.message)
            return
          }
        }
      }
      notyf.error(error.message)
    }
  }

  const onClickUploadCSV = e => {
    if (csvUploadRef.current) {
      csvUploadRef.current.open(e)
    }
  }

  const handleOnFileLoad = data => {
    console.log(`🚀 => UploadUsersModal => data`, data)
    data.forEach((row, index) => {
      let errors = []
      if (!row.data) {
        errors.push({
          type: 'No Data',
          code: 'NoData',
          message: 'Fields not found',
          row: index,
        })

        row.errors = errors
      } else {
        if (row.data.length < 4) {
          errors.push({
            type: 'Field Mismatch',
            code: 'TooFewFields',
            message: 'Missing columns',
            row: index,
          })
        }
        if (row.data.length > 5) {
          errors.push({
            type: 'Field Mismatch',
            code: 'TooManyFields',
            message: 'Too many columns',
            row: index,
          })
        }
        if (row.data[2] && !validEmail(row.data[2])) {
          errors.push({
            type: 'Field Mismatch',
            code: 'InvalidField',
            message: 'Invalid Email',
            row: index,
          })
        }
        row.errors = errors
      }
    })
    setState(data)
  }

  const handleOnError = err => {
    console.log(`🚀 => handleOnError => err`, err)
  }

  const handleOnRemoveFile = data => {
    console.log(`🚀 => handleOnRemoveFile => data`, data)
    setState(null)
  }

  const handleRemoveFile = e => {
    // Note that the ref is set async, so it might be null at some point
    if (csvUploadRef.current) {
      csvUploadRef.current.removeFile(e)
    }
  }

  let hasErrors = false
  if (state) {
    hasErrors = state.some(i => i.errors.length > 0)
  }
  const addDisabled = !state || hasErrors

  return (
    <Modal
      onClose={handleClose}
      title={'Add New Users'}
      gridContainerStyle={{ maxWidth: '600px' }}
      footer={
        <Block
          type="FLEX"
          s={p => ({
            marginRight: p.theme.sizes.six,
            justifyContent: 'flex-end',
          })}
        >
          <Button
            s={() => ({
              marginRight: '1rem',
            })}
            type={BUTTON_TYPE.LINK}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            onClick={onClickAdd}
            type={addDisabled ? BUTTON_TYPE.DISABLED : BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.LARGE}
            disabled={addDisabled}
            s={() => ({
              minWidth: '110px',
            })}
          >
            Add Users
          </Button>
        </Block>
      }
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <FormContainer>
          <Label type={LABEL_TYPE.BODY1}>
            Upload a formatted CSV of users with columns in this order: First
            Name, Last Name, E-mail Address, Permission Level (1-3), Is SSO User (true/false).
            No column headers required.
          </Label>
          <CSVReader
            ref={csvUploadRef}
            onFileLoad={handleOnFileLoad}
            onError={handleOnError}
            config={{
              delimiter: ',',
            }}
            style={{}}
            onRemoveFile={handleOnRemoveFile}
            progressBarColor={colors.aubergine}
          >
            {({ file }) => (
              <>
                {!file ? (
                  <Button
                    onClick={onClickUploadCSV}
                    type={BUTTON_TYPE.DEFAULT}
                    s={p => ({
                      marginTop: p.theme.sizes.four,
                      maxWidth: '200px',
                    })}
                  >
                    <Label>Upload CSV</Label>
                  </Button>
                ) : (
                  <div>
                    <Block
                      type="FLEX"
                      s={p => ({
                        padding: 0,
                        marginTop: p.theme.sizes.four,
                        alignItems: 'center',
                      })}
                    >
                      <Label>{file.name}</Label>
                      <Button
                        onClick={handleRemoveFile}
                        type={BUTTON_TYPE.LINK}
                        s={p => ({
                          marginLeft: p.theme.sizes.four,
                          maxWidth: '200px',
                        })}
                      >
                        <Label
                          s={() => ({
                            fontWeight: '700',
                          })}
                        >
                          Remove
                        </Label>
                      </Button>
                    </Block>
                    <Block
                      type="FLEX"
                      s={p => ({
                        marginTop: p.theme.sizes.five,
                        flexDirection: 'column',
                        padding: 0,
                      })}
                    >
                      <Label
                        s={p => ({
                          fontWeight: '500',
                          marginBottom: p.theme.sizes.two,
                        })}
                      >{`${state ? state.length : 0} users found:`}</Label>
                      {state &&
                        state.map((item, index) => {
                          const { data, errors } = item
                          return (
                            <Flex
                              key={index}
                              s={p => ({
                                justifyContent: 'space-between',
                                marginBottom: p.theme.sizes.one,
                                padding: `${p.theme.sizes.one} 0`,
                                borderBottom: `1px solid ${p.theme.colors.gray5}`,
                              })}
                            >
                              <Label
                                s={p => ({
                                  color:
                                    errors.length > 0
                                      ? p.theme.colors.errorRed
                                      : p.theme.colors.gray1,
                                  marginRight: p.theme.sizes.four,
                                })}
                              >
                                {data.join(', ')}
                              </Label>
                              <Label
                                s={p => ({
                                  color:
                                    errors.length > 0
                                      ? p.theme.colors.errorRed
                                      : p.theme.colors.gray1,
                                  fontWeight: '500',
                                  textAlign: 'right',
                                })}
                              >
                                {errors.map(err => err.message).join(', ')}
                              </Label>
                            </Flex>
                          )
                        })}
                    </Block>
                  </div>
                )}
              </>
            )}
          </CSVReader>
        </FormContainer>
      )}
    </Modal>
  )
}

const FormContainer = styled(Block)`
  padding: ${p => p.theme.sizes.four};
  min-height: 250px;
`
