import { useContext, useMemo } from 'react'
import { NotyfContext } from 'context/notyf-context'
import { useQuery } from 'react-query'
import { AuthStateContext } from 'context/auth-state-context'
import * as AlgoliaService from 'services/algolia'

const searchQueryKeys = {
  insuranceCarriers: planSearch => ['insuranceCarriers', planSearch],
}

const formatResults = carriers => {
  if (!carriers) {
    return []
  }

  const sortedResults = []
  const resultsMap = {}

  // TODO: Optimize for less loops
  // Could insert keys sorted & plans sorted after if needed

  // Separating carriers into groups of First Character of carrier_name A, B, C etc
  carriers.forEach(carrier => {
    const formattedCarrier = {
      displayName: carrier.carrier_name,
      uuid: carrier.carrier_name,
      isCarrier: true,
    }
    if (formattedCarrier.displayName) {
      const firstLetter = formattedCarrier.displayName[0]
      const resultValueByKey = resultsMap[firstLetter]
      if (resultValueByKey) {
        resultValueByKey.push(formattedCarrier)
      } else {
        resultsMap[firstLetter] = [formattedCarrier]
      }
    }
  })

  // sort results & character keys alphabetically
  const sortedKeys = Object.keys(resultsMap).sort()
  sortedKeys.forEach(key => {
    sortedResults.push(
      {
        displayName: key.toUpperCase(),
        uuid: key,
        isHeader: true,
      },
      ...resultsMap[key].sort((a, b) => {
        return a.displayName < b.displayName
          ? -1
          : a.displayName > b.displayName
          ? 1
          : 0
      }),
    )
  })

  return sortedResults
}

export const useInsuranceCarriersQuery = (searchInput = '') => {
  const [authState] = useContext(AuthStateContext)
  const appId = authState.refTypeaheadId
  const apiKey = authState.refTypeaheadKey
  const appName = authState.refTypeaheadName
  const notyfContext = useContext(NotyfContext)
  const { isLoading, data } = useQuery(
    searchQueryKeys.insuranceCarriers(searchInput),
    () =>
      AlgoliaService.searchAlgoliaInsurances({
        appId: appId,
        apiKey: apiKey,
        appName: appName,
        inputValue: searchInput,
        options: {
          hitsPerPage: 1000,
          facetFilters: ['type: insurance'],
          distinct: 1,
        },
      }),
    {
      onError: error => {
        notyfContext.error(
          error.message || 'An error occurred fetching insurance carriers.',
        )
      },
    },
  )

  const results = useMemo(() => formatResults(data), [data])

  // console.log(`🚀 => useInsuranceCarriersQuery => results`, results)

  return {
    results,
    isLoading,
  }
}
