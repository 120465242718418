import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { navigate } from 'gatsby'
import ReactTooltip from 'react-tooltip'
import {
  GlobalStateContext,
  GLOBAL_CONTEXT_ACTIONS,
} from 'context/global-context-provider'
import { FacetFilterContextProvider } from 'context/facet-filter-context';
import { SearchFilters } from 'components/search-filters/search-filters.component'
import { useBreakpoint } from 'context/breakpoint-context'
import { MapSection } from './components/search-map-section'
import { VirtualSearchRightSection } from './components/virtual-search-right-section'
import { ResultsSection } from './components/search-result-section'
import { DetailEditLocationForm } from 'components/edit-location/edit-location-form'
import { EDIT_TYPES, NEW_LOCATION, searchTypes } from 'utils/constants'
import { colors } from 'theme/colors'
import { SearchHeader } from './components/search-header'
import { Flex } from 'design-system/flex'
import { FixedSearchHeader } from './components/fixed-search-header'
import {
  getCurrentPosition,
  getLocationsFromSearchResults,
} from 'utils/location'
import { useSearchQueries } from './useSearchQueries'
import { EmptySearchContainer } from './components/empty-search-container'
import { useScrollContext } from 'context/scroll-context'

const searchObjectHash = {
  objectHash: obj => JSON.stringify(obj),
  arrays: {
    detectMove: false,
  },
}
const jsonDiff = require('jsondiffpatch')

export const Search = () => {
  const resultSectionRef = useRef()
  const breakpoints = useBreakpoint()
  const showMap = breakpoints['md'] || breakpoints['lg']
  const [selectedMarker, setSelectedMarker] = useState()
  const [editType, setEditType] = useState()
  const [searchState = {}, searchDispatch] = useContext(GlobalStateContext)
  const [scrollPos, setScrollPos] = useScrollContext()

  const {
    providerCurrentPageResults,
    facilityCurrentPageResults,
    isLoading,
  } = useSearchQueries()

  const currentPageResults =
    searchState.searchType === searchTypes.providers
      ? providerCurrentPageResults
      : facilityCurrentPageResults

  // console.log('currentPageResults', currentPageResults)

  const [markerLocations, markerLocationBounds] = getLocationsFromSearchResults(
    isLoading,
    currentPageResults,
    searchState,
  )
  // console.log('------- SearchContainer rerender', searchState)

  // Check User location permission
  useEffect(() => {
    getCurrentPosition().catch(err => console.log(err))
  }, [])

  const performProviderSearch = useCallback(
    (page = -1, search) => {
      // Up to date search bar params (location, name, insurance, specialties) values
      const {
        location,
        providerName,
        specialties,
        insurance,
        filters,
        providersSearchQuery,
      } = search
      if (page > 0 && location) {
        const activeFilters = filters[searchTypes.providers]
        if (providerName && !activeFilters.maxDistance) {
          activeFilters.maxDistance = '100'
        }
        // Previously searched for search bar params
        const {
          hasTelehealth: queryHasTelehealth,
        } = providersSearchQuery || {}

        const payload = {
          page,
          ...activeFilters,
          name: providerName,
          location,
          specialties,
          insurance,
          hasTelehealth: queryHasTelehealth,
        }
        searchDispatch({
          type: GLOBAL_CONTEXT_ACTIONS.UPDATE_SEARCH_QUERY,
          payload,
        })
      }
    },
    [],
  )

  const performFacilitySearch = useCallback(
    (page = -1, search) => {
      const {
        location,
        facilityName,
        facilityType,
        insurance,
        filters,
      } = search
      const activeFilters = filters[searchTypes.facilities]
      if (facilityName && !activeFilters.maxDistance) {
        activeFilters.maxDistance = '100'
      }
      const payload = {
        page,
        ...activeFilters,
        name: facilityName,
        location,
        locationType: facilityType,
        insurance,
      }
      if (page > 0 && location) {
        searchDispatch({
          type: GLOBAL_CONTEXT_ACTIONS.UPDATE_SEARCH_QUERY,
          payload,
        })
      }
    },
    [],
  )

  const performSearch = useCallback(
    (page = 1) => {
      console.log(`🚀 => Search => performSearch`, searchState)
      setSelectedMarker()
      window && window.scrollTo(0, 0)
      resetSearchScroll()
      if (searchState.searchType === searchTypes.providers) {
        performProviderSearch(page, searchState)
      } else {
        performFacilitySearch(page, searchState)
      }
    },
    [searchState],
  )

  // Auto searches on any filter changes
  useEffect(() => {
    const {
      filters,
      prevFilters,
      location,
      prevLocation,
      lastUpdatedKey,
    } = searchState
    console.log(`🚀 => useEffect => lastUpdatedKey`, lastUpdatedKey)
    // avoid difference checks on keys that shouldn't ever auto search
    if (lastUpdatedKey !== 'filters' && lastUpdatedKey !== 'location') {
      return
    }

    const filtersDiff = jsonDiff
      .create(searchObjectHash)
      .diff(filters, prevFilters)

    console.log(`🚀 => filtersDiff`, filtersDiff)
    if (filtersDiff) {
      performSearch()
      return
    }

    // Should auto search on any Map Location changes
    const locationsDiff = jsonDiff
      .create(searchObjectHash)
      .diff(location, prevLocation)
    console.log(`🚀 => locationsDiff`, locationsDiff)

    if (locationsDiff) {
      const { label } = locationsDiff
      // from searched for location to map location changes
      if (label && label[0] && label[0] === 'Map Location') {
        performSearch()
        return
      }
      // is still map location, but position changes (lat/lng)
      if (location && location.label === 'Map Location') {
        performSearch()
        return
      }
    }
  }, [searchState, performSearch])

  /**
   * Auto scroll back to result clicked on when leaving page.
   */
  useEffect(() => {
    if (scrollPos > 0) {
      if (
        currentPageResults &&
        currentPageResults.data &&
        currentPageResults.data.length > 0
      ) {
        if (resultSectionRef.current) {
          resultSectionRef.current.scrollTo({
            top: scrollPos,
            behavior: 'auto',
          })
        }
      } else {
        setScrollPos(0)
      }
    }
  }, [])

  /**
   * Handle search container scrolling up to nav bar & hiding icons on inputs
   */
  const handleResultsScroll = useCallback(
    e => {
      /**
       * Limit Handling if results are not greater than 5,
       * was causing issues with bouncy scroll if search scroll never makes it to navbar
       * */
      if (!currentPageResults) {
        return
      }
      if (currentPageResults) {
        const { data } = currentPageResults
        if (!data || (data && data.length <= 5)) {
          return
        }
      }
      const { scrollTop = 0 } = e.target
      setScrollPos(scrollTop)
      // Adjust header and searchInput sizing
      const headerElement = document.getElementById('search-header')
      const offset = Math.min(160, scrollTop)
      headerElement.style.marginTop = `-${offset}px`
      headerElement.style.marginBottom = `${offset <= 148 ? 0 : 14 - (160 - offset)
        }px`
      headerElement.style.paddingBottom = `${32 - 32 * (offset / 160)}px`
    },
    [currentPageResults],
  )

  const resetSearchScroll = useCallback(() => {
    // console.log('Resetting ScrollState', resultSectionRef.current)
    if (resultSectionRef && resultSectionRef.current) {
      resultSectionRef.current.scrollTop = 0
    }
    setScrollPos(0)
    const headerElement = document.getElementById('search-header')
    headerElement.style.marginTop = `0px`
    headerElement.style.marginBottom = `0px`
    headerElement.style.paddingBottom = `32px`
  }, [])

  const onHoverResult = useCallback(
    id => {
      if (!id) {
        setSelectedMarker()
        return
      }

      // if marker is already selected -> clear marker
      if (selectedMarker) {
        const { provider, uuid } = selectedMarker

        if (provider && provider.npi === id) {
          setSelectedMarker()
          return
        } else if (uuid && uuid === id) {
          setSelectedMarker()
        }
      } else {
        // Find location by provider npi -> set as active marker
        const markerLocation = markerLocations.find(item => {
          if (searchState.searchType === searchTypes.providers) {
            if (item.provider && item.provider.npi === id) return item
          } else {
            if (item.uuid && item.uuid === id) {
              return item
            }
          }
          return undefined
        })
        if (markerLocation) {
          setSelectedMarker(markerLocation)
        }
      }
    },
    [markerLocations, selectedMarker, searchState.searchType],
  )

  const closeModal = useCallback(
    result => {
      const { newFacility } = result || {}
      setEditType()
      if (newFacility && newFacility.uuid) {
        navigate(`/facilities/${newFacility.uuid}`, {
          state: { facility: newFacility },
        })
      }
    },
    [navigate],
  )

  const onClickAddNewFacility = useCallback(() => {
    setEditType(EDIT_TYPES.addLocation)
  }, [])

  return (
    <Flex
      className="search-container"
      s={() => ({
        flexDirection: 'column',
        height: '100%',
      })}
    >
      {scrollPos > 125 && <FixedSearchHeader performSearch={performSearch} />}
      <SearchHeader performSearch={performSearch} />
      {!isLoading && !currentPageResults ? (
        <EmptySearchContainer />
      ) : (
        <Flex s={() => ({ flexGrow: 1, position: 'relative' })}>
          {/* Absolute positioning here so filters dont block bottom options
            from scroll position of result section
        */}
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
            }}
          >
            <Flex
              s={() => ({
                height: '100%',
                flex: 1,
              })}
            >
              <SearchFilters />
              <Flex
                s={() => ({
                  flexDirection: 'column',
                  ...(showMap
                    ? { minWidth: '38.5rem' }
                    : {
                      width: 'auto',
                    }),

                  flex: 1,
                  overflow: 'auto',
                })}
                onScroll={handleResultsScroll}
                ref={resultSectionRef}
              >
                <ResultsSection
                  performSearch={performSearch}
                  onHoverResult={onHoverResult}
                  onClickAddNewFacility={onClickAddNewFacility}
                />
              </Flex>
              {showMap && searchState.providersSearchMode === 'inPerson' && (
                <MapSection
                  isLoading={isLoading}
                  markerLocations={markerLocations}
                  markerLocationBounds={markerLocationBounds}
                  selectedMarker={selectedMarker}
                  setSelectedMarker={setSelectedMarker}
                />
              )}
              {searchState.providersSearchMode === 'virtualCare' && (
                <VirtualSearchRightSection />
              )}
            </Flex>
          </div>
        </Flex>
      )}
      {editType === EDIT_TYPES.addLocation && (
        <DetailEditLocationForm
          selectedLocation={NEW_LOCATION}
          closeModal={closeModal}
          addNew
          title="Add a New Facility"
        />
      )}
      <ReactTooltip
        place="bottom"
        effect="solid"
        backgroundColor={colors.gray1}
      />
    </Flex>
  )
}

export function SearchWithContexts() {
  return (
    <FacetFilterContextProvider >
      <Search />
    </FacetFilterContextProvider>
  );
}
