import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import dayjs from 'utils/dayjs'
import ArrowDown from 'assets/arrow-down.svg'
import styled from 'styled-components'

const Container = styled(Flex)`
  padding: ${p => p.theme.sizes.two} ${p => p.theme.sizes.four};
  align-items: center;
  background-color: ${p => p.theme.colors.white};
  cursor: pointer;
  border: 1px solid ${p => p.theme.colors.borderMid};
  border-radius: 2rem;
  :focus-within {
    border: 1px solid ${p => p.theme.colors.aubergine};
  }
  :hover {
    border: 1px solid ${p => p.theme.colors.aubergine};
  }

  > p {
    flex: 1;
    ${p => ({
      ...(!p.value && {
        opacity: 0.5,
      }),
    })}
  }
  ${p => ({
    ...(p.disabled && {
      opacity: '50%',
      [': hover']: {
        cursor: 'default',
      },
    }),
  })}
`

export const DateSelect = ({
  value,
  onChange,
  dateFormat = 'L',
  disabled = false,
}) => {
  return (
    <DatePicker
      selected={value ? dayjs(value).toDate() : undefined}
      onChange={date => onChange(date)}
      customInput={
        <Container value={value} disabled={disabled}>
          <Label>
            {value ? dayjs(value).format(dateFormat) : 'Select Date'}
          </Label>
          <ArrowDown />
        </Container>
      }
      todayButton="Today"
      maxDate={dayjs().toDate()}
      disabledKeyboardNavigation
      disabled={disabled}
    />
  )
}

DateSelect.propTypes = {
  value: PropTypes.string,
  dateFormat: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}
