import React from 'react'
import styled from 'styled-components'
import { Flex } from 'design-system/flex'
import { CostEstimatesSearch } from './cost-estimates-search'
import { CostEstimatesResults } from './cost-estimates-results'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import EmptySearchSVG from 'assets/empty-search.svg'
import { Label } from 'design-system/label'
import { useProceduresCostEstimates } from './useProceduresCostEstimates'
import { useCostEstimatesContext } from 'context/cost-estimates-context'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { focusNextInput } from 'utils/document'

export const CostEstimates = () => {
  const [state, dispatch] = useCostEstimatesContext()

  const { procedureId, location } = state

  const {
    result,
    fetchCostEstimates,
    fetchingCostEstimates,
    error,
    fetchingProcedure,
  } = useProceduresCostEstimates({ procedureId, location, autoSearch: false })

  const handleStartSearch = () => {
    focusNextInput('location-input')
  }

  let hasValues = false
  if (location || procedureId) {
    hasValues = true
  }

  return (
    <Container>
      <SearchContainer>
        <CostEstimatesSearch
          searchState={state}
          dispatch={dispatch}
          search={fetchCostEstimates}
          fetchingCostEstimates={fetchingCostEstimates}
          result={result}
          fetchingProcedure={fetchingProcedure}
        />
      </SearchContainer>
      <ContentContainer>
        <Content>
          {!fetchingCostEstimates && !result && !error ? (
            <>
              <NoSearchStateContainer>
                <EmptySearchSVG />
                <Label>
                  To see cost estimates, enter a procedure and a location to
                  find its cost estimate in that area
                </Label>
                {!hasValues && (
                  <Button
                    type={BUTTON_TYPE.PRIMARY}
                    size={BUTTON_SIZE.EXTRA_LARGE}
                    s={p => ({
                      marginTop: p.theme.sizes.five,
                      minWidth: '240px',
                    })}
                    onClick={handleStartSearch}
                  >
                    Start search
                  </Button>
                )}
              </NoSearchStateContainer>
            </>
          ) : (
            <ResultsContainer>
              {!fetchingCostEstimates && !error && result && (
                <Heading type={HEADING_TYPE.H2}>
                  {`Estimated cost for ${result.procedure.display} near ${result.location.label}`}
                </Heading>
              )}
              <CostEstimatesResults
                result={result}
                isLoading={fetchingCostEstimates}
                error={error}
              />
            </ResultsContainer>
          )}
        </Content>
      </ContentContainer>
    </Container>
  )
}

const Container = styled(Flex)`
  height: 100%;
  flex-direction: column;
`
const SearchContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid ${p => p.theme.colors.borderLight};
  padding: ${p => p.theme.sizes.six} ${p => p.theme.sizes.four};
`

const ContentContainer = styled(Flex)`
  flex: 1;
  justify-content: center;
`

const Content = styled(Flex)`
  flex-direction: column;
  background-color: ${p => p.theme.colors.gray7};
  flex: 1;
  padding: ${p => p.theme.sizes.six} ${p => p.theme.sizes.twelve};
`
const ResultsContainer = styled(Flex)`
  flex-direction: column;
  align-self: center;
  max-width: 830px;
  width: 100%;
  flex: 1;
`

const NoSearchStateContainer = styled(Flex)`
  flex: 1;
  margin-top: ${p => p.theme.sizes.twelve};
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 375px;
  align-self: center;
  & > p {
    margin-top: ${p => p.theme.sizes.four};
    color: ${p => p.theme.colors.gray2};
    font-weight: 400;
  }
`
