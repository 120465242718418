import React, { useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Block } from 'design-system/block'
import { Chevron } from 'design-system/icon/icons/chervon-left'
import { Label, LABEL_TYPE } from 'design-system/label'
import { DetailSearchInput } from 'components/detail-search-input/detail-search-input'
import { Flex } from 'design-system/flex'
import { VerificationToggle } from 'components/verification-toggle/verification-toggle'
import {
  formatDisplayNameByState,
  formatInsurancePlansByState,
  mapInsurancesByCarrier,
} from 'utils/insurances'
import dayjs from 'utils/dayjs'
import { useAuthState } from 'context/auth-state-context'
import { useMutation } from 'react-query'
import { services } from 'services'
import { useNotyf } from 'context/notyf-context'

export const InsurancesList = ({
  selectedLocation,
  provider,
  facility,
  handleUpdateSuccess,
  moduleEnabled,
}) => {
  const [authState] = useAuthState()
  const notyf = useNotyf()
  const { insurances, insuranceVerifications } = selectedLocation
  const [state, dispatch] = useReducer(insurancesReducer, {
    search: '',
    fcaUserId: authState.fca_user_id,
    filteredInsurances: [...insurances],
    initialInsurances: [...insurances],
    localInsuranceVerifications: insuranceVerifications
      ? { ...insuranceVerifications }
      : {},
  })

  const { search, filteredInsurances, localInsuranceVerifications } = state
  const insurancesMap = mapInsurancesByCarrier(filteredInsurances)

  const onMutate = ({ options }) => {
    console.log(`🚀 => VerificationToggle => onMutate:`, options)
    // Optimistic update
    const { insurance_verifications } = options
    dispatch({
      type: ACTION_KEYS.update,
      payload: {
        localInsuranceVerifications: insurance_verifications,
      },
    })

    notyf.dismissAll()
    const updatingNotification = notyf.open({
      type: 'updating',
      duration: 10000, // setting a max here as backup but should always take less time
      message: `Insurance updating..`,
    })

    return {
      oldVerifications: { ...insuranceVerifications },
      updatedVerifications: insurance_verifications,
      updatingNotification,
    }
  }

  const onSuccess = async (res, vars, context) => {
    console.log(`🚀 => VerificationToggle => onSuccess:`, res)
    const { updatingNotification } = context
    await handleUpdateSuccess({ refresh: true })
    if (updatingNotification) {
      notyf.dismiss(updatingNotification)
    }
    notyf.success(`Insurance updated!`)
  }

  const onError = (error, newOptions, context) => {
    console.log(`🚀 => VerificationToggle => onError:`, error)
    const { updatingNotification, oldVerifications } = context
    if (updatingNotification) {
      notyf.dismiss(updatingNotification)
    }
    dispatch({
      type: ACTION_KEYS.update,
      payload: {
        localInsuranceVerifications: oldVerifications,
      },
    })
    notyf.error(error.message)
  }

  const {
    mutate: mutateProviderLocationData,
    isLoading: isUpdatingProviderLocationData,
  } = useMutation(
    ({ providerId, locationId, options }) =>
      services.ribbon.updateProviderLocationData(
        providerId,
        locationId,
        options,
      ),
    {
      mutationKey: 'updateProviderLocationData',
      onMutate,
      onSuccess,
      onError,
    },
  )

  const {
    mutate: mutateLocationData,
    isLoading: isUpdatingLocationData,
  } = useMutation(
    ({ locationId, options }) =>
      services.ribbon.updateLocationData(locationId, options),
    {
      mutationKey: 'updateLocationData',
      onMutate,
      onSuccess,
      onError,
    },
  )

  /**
   * Adjust local insurances/verifications on any changes from insurances server state;
   */
  useEffect(() => {
    const { insurances, insuranceVerifications } = selectedLocation
    dispatch({
      type: ACTION_KEYS.update,
      payload: {
        search: '',
        initialInsurances: [...insurances],
        filteredInsurances: [...insurances],
        localInsuranceVerifications: insuranceVerifications
          ? { ...insuranceVerifications }
          : {},
      },
    })
  }, [selectedLocation])

  const handleVerification = (uuid, confidence) => {
    console.log(`🚀 => InsurancesList => uuid:confidence`, uuid, confidence)

    const verificationsCopy = { ...localInsuranceVerifications }
    // No Selection
    if (confidence === 3) {
      if (verificationsCopy[uuid]) {
        delete verificationsCopy[uuid]
      }
    } else {
      verificationsCopy[uuid] = {
        confidence,
        fca_user_id: authState.fcaUserId,
        updated_at: dayjs().utc().toISOString(),
      }
    }

    if (provider) {
      // Provider mutation
      mutateProviderLocationData({
        providerId: provider.npi,
        locationId: selectedLocation.uuid,
        options: {
          insurance_verifications: verificationsCopy,
        },
      })
    }
    if (facility) {
      // Facility mutation
      mutateLocationData({
        locationId: selectedLocation.uuid,
        options: {
          insurance_verifications: verificationsCopy,
        },
      })
    }
  }

  const isUpdating = isUpdatingLocationData || isUpdatingProviderLocationData

  return (
    <>
      <DetailSearchInput
        placeholder="Search insurances"
        value={search}
        onChange={() => dispatch({ type: ACTION_KEYS.clear })}
        onInputChange={e =>
          dispatch({
            type: ACTION_KEYS.search,
            payload: { search: e.target.value },
          })
        }
      />
      <Block
        type={'CARD'}
        s={p => ({
          padding: p.theme.sizes.four,
          fontSize: p.theme.sizes.four,
          maxHeight: '24rem',
          overflowY: 'auto',
        })}
      >
        {Object.keys(insurancesMap).map((carrierName, index) => {
          const insurances = insurancesMap[carrierName]
          return (
            <InsuranceListItem
              key={carrierName}
              carrier={carrierName}
              carrierInsurances={insurances}
              isLast={index === Object.keys(insurancesMap).length - 1}
              verifications={localInsuranceVerifications}
              handleVerification={handleVerification}
              isUpdating={isUpdating}
              moduleEnabled={moduleEnabled}
            />
          )
        })}
        {filteredInsurances.length === 0 && search.length > 0 && (
          <Label type={LABEL_TYPE.BODY1_BOLD}>No matching results</Label>
        )}
      </Block>
    </>
  )
}

InsurancesList.propTypes = {
  selectedLocation: PropTypes.object,
  provider: PropTypes.object,
  facility: PropTypes.object,
  handleUpdateSuccess: PropTypes.func,
  moduleEnabled: PropTypes.bool
}

const ListItemRow = styled(Flex)`
  flex-direction: column;
  padding: ${p => p.theme.sizes.two};
`

const ListItemHeader = styled(Flex)`
  flex: 1;
  justify-content: space-between;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${p => p.theme.colors.gray4};
  padding-bottom: ${p => p.theme.sizes.two};
  margin-bottom: ${p => p.theme.sizes.two};
  cursor: pointer;
  ${p => ({
    ...(p.isLast &&
      !p.expanded && {
      borderBottomWidth: 0,
      paddingBottom: 0,
      marginBottom: 0,
    }),
  })}
`

const PlanListHeaderLabel = styled(Label)`
  ${p => ({
    marginTop: p.theme.sizes.two,
    marginBottom: p.theme.sizes.three,
    color: p.theme.colors.gray1,
  })}
  font-weight: 600;
`

const InsuranceListItem = ({
  carrier = '',
  carrierInsurances = [],
  isLast = false,
  verifications = {},
  handleVerification = () => { },
  isUpdating = false,
  moduleEnabled = false,
}) => {
  const [expanded, setExpanded] = useState(false)

  const onClickCarrier = () => {
    setExpanded(prev => !prev)
  }

  const formattedPlansByState = formatInsurancePlansByState(carrierInsurances)

  return (
    <ListItemRow>
      <ListItemHeader
        isLast={isLast}
        expanded={expanded}
        onClick={onClickCarrier}
      >
        <Label type={LABEL_TYPE.BODY1_BOLD}>
          {carrier}
          <span
            style={{ fontWeight: 'normal' }}
          >{` (${carrierInsurances.length})`}</span>
        </Label>
        <Chevron direction={expanded ? 'up' : 'down'} />
      </ListItemHeader>
      {expanded && (
        <Flex
          s={p => ({
            flexDirection: 'column',
            paddingBottom: p.theme.sizes.six,
          })}
        >
          {formattedPlansByState.map(item => {
            const { displayName, uuid } = item
            if (item.isHeader) {
              return (
                <PlanListHeaderLabel key={uuid}>
                  {displayName}
                </PlanListHeaderLabel>
              )
            }

            const label = formatDisplayNameByState(item)
            const itemVerification =
              verifications && verifications[uuid]
                ? verifications[uuid].confidence
                : 3
            return (
              <Flex
                key={uuid}
                s={p => ({
                  justifyContent: 'space-between',
                  flex: 1,
                  marginBottom: p.theme.sizes.two,
                  alignItems: 'center',
                })}
              >
                <Label
                  s={p => ({
                    color: p.theme.colors.gray1,
                    marginRight: p.theme.sizes.six,
                  })}
                >
                  {label}
                </Label>
                {moduleEnabled && (
                  <VerificationToggle
                    uuid={uuid}
                    handleVerification={handleVerification}
                    isUpdating={isUpdating}
                    activeIconMap={{
                      low: false,
                      medium: false,
                      report: itemVerification === 1,
                      verify: itemVerification === 5,
                    }}
                    menuOptions={{
                      none: { confidence: 3, label: 'No Selection' },
                      report: { confidence: 1, label: 'Report' },
                      verify: { confidence: 5, label: 'Verify' },
                    }}
                  />
                )}
              </Flex>
            )
          })}
        </Flex>
      )}
    </ListItemRow>
  )
}

const ACTION_KEYS = {
  update: 'UPDATE',
  search: 'SEARCH',
  clear: 'CLEAR',
}

const insurancesReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case ACTION_KEYS.search: {
      const { search } = payload
      const filtered =
        search && search.length > 0
          ? [...state.initialInsurances].filter(
            item =>
              item.displayName &&
              item.displayName.toLowerCase().includes(search.toLowerCase()),
          )
          : state.initialInsurances

      return {
        ...state,
        search,
        filteredInsurances: filtered,
      }
    }
    case ACTION_KEYS.update:
      return {
        ...state,
        ...payload,
      }
    case ACTION_KEYS.clear:
      return {
        ...state,
        search: '',
        filteredInsurances: state.initialInsurances,
      }

    default:
      return state
  }
}
