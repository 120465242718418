import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { AuthStateContext } from 'context/auth-state-context'

export const PermissionRestricted = ({ children, level = 2 }) => {
  const [authState] = useContext(AuthStateContext)
  const { permissionLevel } = authState || {}
  if (!permissionLevel || permissionLevel < level) {
    return null
  }
  return <>{children}</>
}

PermissionRestricted.propTypes = {
  children: PropTypes.node,
  level: PropTypes.number,
}
