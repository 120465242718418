import algoliasearch from 'algoliasearch'


type Options = {
  appId: string,
  apiKey: string,
  appName: string,
  inputValue: string
  options: SearchOptions
}

export type SearchOptions = {
  hitsPerPage: number
  facetFilters: Array<string>
  page: number
}

export type AlgoliaClinicalAreaResponse = {
  display_name: string
  objectId: string
  type: string
  user_id: number | string
  uuid: string
  _highlighResult: Record<string, string>
  _metadata: {
    conditions: string
    specialties: string[]
    treatments: string
  }
}

export type AlgoliaConditionResponse = {
  display_name: string
  objectId: string
  type: string
  user_id: number | string
  uuid: string
  _highlighResult: Record<string, string>
  _metadata: {
    modules: string[]
    specialties: string[]
  }
}

export type AlgoliaTreatmentResponse = {
  display_name: string
  objectId: string
  type: string
  user_id: number | string
  uuid: string
  _highlighResult: Record<string, string>
  _metadata: {
    specialties: string[]
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const searchAlgoliaClinicalAreas = async (options: Options) => {
  const searchClient = algoliasearch(options.appId, options.apiKey)
  const index = searchClient.initIndex(options.appName)
  const results = await index.search<AlgoliaClinicalAreaResponse[]>(
    options.inputValue,
    {
      ...options.options,
    })
  return results.hits
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const searchAlgoliaConditions = async (options: Options) => {
  const searchClient = algoliasearch(options.appId, options.apiKey)
  const index = searchClient.initIndex(options.appName)
  const results = await index.search<AlgoliaConditionResponse[]>(
    options.inputValue,
    {
      ...options.options,
    })
  return results.hits
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const searchAlgoliaTreatments = async (options: Options) => {
  const searchClient = algoliasearch(options.appId, options.apiKey)
  const index = searchClient.initIndex(options.appName)
  const results = await index.search<AlgoliaTreatmentResponse[]>(
    options.inputValue,
    {
      ...options.options,
    })
  return results.hits
}
