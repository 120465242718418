import { useMutation } from 'react-query'
import { services } from 'services'
import { useNotyf } from 'context/notyf-context'

export const useManageLocationDetails = ({
  location,
  refreshLocation,
  handleMutation,
}) => {
  const notyf = useNotyf()

  const onMutate = ({ options }) => {
    handleMutation(options)
    notyf.dismissAll()
    const updatingNotification = notyf.open({
      type: 'updating',
      duration: 10000, // setting a max here as backup but should always take less time
      message: `Location updating..`,
    })

    return {
      previous: {
        confidence: location.confidence,
      },
      next: options,
      updatingNotification,
    }
  }

  const onSuccess = async (res, vars, context) => {
    console.log(`🚀 => updateProviderLocationData => res`, res)
    const { updatingNotification } = context
    await refreshLocation(location)
    if (updatingNotification) {
      notyf.dismiss(updatingNotification)
    }
    notyf.success('Location Updated!')
  }

  const onError = (error, newOptions, context) => {
    console.log(`🚀 => updateProviderLocationData => error`, error)
    const { previous } = context
    handleMutation(previous)

    notyf.error(error.message)
  }

  const {
    mutate: updateProviderLocationData,
    isLoading: updatingProviderLocationData,
  } = useMutation(
    ({ action, options }) => services.ribbon.manageConfidence(action, options),
    {
      mutationKey: 'updateProviderLocationData',
      onMutate,
      onSuccess,
      onError,
    },
  )
  const {
    mutate: updateLocationData,
    isLoading: updatingLocationData,
  } = useMutation(
    ({ action, options }) => services.ribbon.manageConfidence(action, options),
    {
      mutationKey: 'updateLocationData',
      onMutate,
      onSuccess,
      onError,
    },
  )

  return {
    updateProviderLocationData,
    updatingProviderLocationData,
    updateLocationData,
    updatingLocationData,
  }
}
