import { useNotyf } from 'context/notyf-context'
import { useMutation } from 'react-query'
import { services } from 'services'

export const useUpdateInsuranceMutations = ({ handleClose }) => {
  const notyf = useNotyf()
  const updateProviderLocationInsurancesMutation = useMutation(
    ({ providerId, locationId, options }) =>
      services.ribbon.updateProviderLocationInsurances(
        providerId,
        locationId,
        options,
      ),
    {
      mutationKey: 'updateProviderLocationInsurances',
      onSuccess: async res => {
        console.log(`🚀 => updateProviderLocationInsurances => res`, res)
        await handleClose({ refresh: true })
        notyf.success(`Insurances updated!`)
      },
      onError: error => {
        console.log(`🚀 => updateProviderLocationInsurances => error`, error)
        notyf.error(error.message)
      },
    },
  )
  const updateProviderLocationDataMutation = useMutation(
    ({ providerId, locationId, options }) =>
      services.ribbon.updateProviderLocationData(
        providerId,
        locationId,
        options,
      ),
    {
      mutationKey: 'updateProviderLocationData',
      onSuccess: async res => {
        console.log(`🚀 => updateProviderLocationData => res`, res)
        await handleClose({ refresh: true })
        notyf.success(`Insurances updated!`)
      },
      onError: error => {
        console.log(`🚀 => updateProviderLocationData => error`, error)
        notyf.error(error.message)
      },
    },
  )

  const updateLocationInsurancesMutation = useMutation(
    ({ locationId, options }) =>
      services.ribbon.updateLocationInsurances(locationId, options),
    {
      mutationKey: 'updateLocationInsurances',
      onSuccess: async res => {
        console.log(`🚀 => updateLocationInsurances => res`, res)
        await handleClose({ refresh: true, newFacility: res })
        notyf.success(`Insurances updated!`)
      },
      onError: error => {
        console.log(`🚀 => updateLocationInsurances => error`, error)
        notyf.error(error.message)
      },
    },
  )

  const updateLocationDataMutation = useMutation(
    ({ locationId, options }) =>
      services.ribbon.updateLocationData(locationId, options),
    {
      mutationKey: 'updateLocationData',
      onSuccess: async res => {
        console.log(`🚀 => updateLocationData => res`, res)
        await handleClose({ refresh: true })
        notyf.success(`Insurances updated!`)
      },
      onError: error => {
        console.log(`🚀 => updateLocationData => error`, error)
        notyf.error(error.message)
      },
    },
  )

  return {
    updateProviderLocationInsurancesMutation,
    updateProviderLocationDataMutation,
    updateLocationInsurancesMutation,
    updateLocationDataMutation,
  }
}
