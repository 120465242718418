import React, { useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useIsMutating, useQuery, useQueryClient } from 'react-query'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { useGlobalContext } from 'context/global-context-provider'
import { useAuthState } from 'context/auth-state-context'
import { useNotyf } from 'context/notyf-context'
import { services } from 'services'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Procedures } from 'components/procedures/procedures'
import { LeftDetailColumn } from 'components/left-detail-column/left-detail-column.component'
import { RightDetailColumn } from 'components/right-detail-column/right-detail-column.component'
import { BackToSearch } from 'components/back-to-search/back-to-search.component'
import { Label, LABEL_TYPE } from 'design-system/label'
import { RatingLabel } from 'components/rating-label/rating-label.component'
import { formatLanguages, formatAgeRange } from './provider-detail-formatter'
import { LocationsPanel } from 'components/locations-panel/locations-panel'
import { Spinner } from 'design-system/spinner/spinner.component'
import ProviderDetailEditInfoForm from './provider-detail-edit-info-form'
import {
  DetailEditLocationForm,
  LOCATION_SCROLL_OPTIONS,
} from 'components/edit-location/edit-location-form'
import { EDIT_TYPES, NEW_LOCATION, searchTypes } from 'utils/constants'
import { colors } from 'theme/colors'
import { PermissionRestricted } from 'components/permission-restricted/permission-restricted'
import { ClinicalAreas } from './components/clinical-areas/clinical-areas'
import ProviderDemographics from './components/provider-demographics/provider-demographics'
import { CareJourney } from './components/care-journey/care-journey'
import { Flex } from 'design-system/flex'
import { CustomFieldsSection } from 'components/custom-fields/custom-fields-section'
import { searchQueryKeys } from 'containers/search/useSearchQueries'
import { LocationsListModal } from './components/locations-list/locations-list-modal'
import { useToggle } from 'hooks/useToggle'
import { EditDetailButton } from 'components/edit-detail-btn/edit-detail-btn'
import { NotesContainer } from 'components/notes/notes-container'
import { NoteLabel } from 'components/notes/note-label'
import { PreferenceToggle } from 'components/preference-toggle/preference-toggle'
import { ScrollToTopBtn } from 'components/scroll-to-top/scroll-to-top-btn'
import { useIsMounted } from 'hooks/useIsMounted'
import { InsurancesContainer } from 'components/insurances-module/insurances-container'
import Copy from 'design-system/copy'
import { BinaryToggle } from 'design-system/binary-toggle'
import { List } from 'design-system/list'
import InPersonOutlineSVG from 'assets/in-person-outline.svg'
import InPersonColorSVG from 'assets/in-person-color.svg'
import VirtualCareOutlineSVG from 'assets/virtual-care-outline.svg'
import VirtualCareColorSVG from 'assets/virtual-care-color.svg'
import {
  getProviderPhysicalLocations,
  getProviderVirtualLocations,
  isLocationVirtual,
} from 'utils/location'
import { getModulesFromAuthState } from 'utils/modules'
import { SchedulingPanel } from 'components/scheduling-panel/scheduling-panel'

const TOGGLE_STATES = [
  {
    id: 'inPerson',
    title: 'In-Person',
    icon: {
      active: <InPersonColorSVG width={16} height={16} />,
      inactive: <InPersonOutlineSVG width={16} height={16} />,
    },
    enabled: false,
  },
  {
    id: 'virtualCare',
    title: 'Virtual',
    icon: {
      active: <VirtualCareColorSVG width={16} height={16} />,
      inactive: <VirtualCareOutlineSVG width={16} height={16} />,
    },
    enabled: false,
  },
]

export const ProviderDetail = props => {
  const { location, providerId, locationId } = props
  const { state: routeState } = location || {}
  const { provider: routeStateProvider } = routeState || {}
  // using Ref since locations with distance would never change with any state changes
  // and are only available if/on new page loads.
  const routeStateProviderLocations = useRef(
    routeStateProvider ? routeStateProvider.locations : null,
  )
  const queryClient = useQueryClient()
  const isMutating = useIsMutating(['updatingProviderInfo']) //  used for when updating preference
  const [selectedLocation, setSelectedLocation] = useState()

  const [searchState] = useGlobalContext()
  const [authState] = useAuthState()
  const notyf = useNotyf()
  const [editType, setEditType] = useState()
  const [scrollTo, setScrollTo] = useState()
  const [showAllLocations, toggleShowAllLocations] = useToggle()
  const wasViewingOtherLocations = useRef(false)
  const notesContainerRef = useRef()
  const rightDetailContainerRef = useRef(false)
  const isMounted = useIsMounted()

  const { providersSearchQuery } = searchState

  const providerModules = getModulesFromAuthState(
    authState,
    searchTypes.providers,
  )
  const isVirtualSearchEnabled = providerModules.virtual_search?.enabled

  const [isLeft, setLeftToggle] = useState(true)

  const {
    data: provider,
    refetch: refetchProvider,
    isFetching: fetchingProvider,
    isLoading,
    error: fetchProviderError,
    isRefetchError,
  } = useQuery(
    ['providerById', providerId],
    () => services.ribbon.getProvider({ id: providerId }),
    {
      enabled: true,
      staleTime: 0, // required to call on every pageload for analytics
      cacheTime: 0,
      onSuccess: res => {
        console.log('useQuery getProvider result:', res)

        const providerPhysicalLocations = getProviderPhysicalLocations(res)
        const providerVirtualLocations = getProviderVirtualLocations(res)
        TOGGLE_STATES[0].enabled = providerPhysicalLocations?.length > 0
        TOGGLE_STATES[1].enabled = providerVirtualLocations?.length > 0

        // should only not exist on first entry
        if (!selectedLocation) {
          let foundLocation = providerPhysicalLocations.find(
            l => l.uuid === locationId,
          )
          if (!foundLocation) {
            // if no matching physical location, search for a matching virtual location
            foundLocation = providerVirtualLocations.find(
              l => l.uuid === locationId,
            )
          }
          if (foundLocation) {
            setSelectedLocation(foundLocation)
            setLeftToggle(!isLocationVirtual(foundLocation))
          } else {
            // default to first physical location of provider
            const defaultLocation =
              providerPhysicalLocations.length > 0
                ? providerPhysicalLocations[0]
                : {}
            setSelectedLocation(defaultLocation)
            setLeftToggle(!isLocationVirtual(defaultLocation))
          }
        }
      },
      // useCallback so mutation doesnt run on every render instead only on new res
      select: React.useCallback(res => {
        // Mutating locations for distance field to be added in
        // which is only available if coming from search results.
        console.log('getProvider select:', routeStateProviderLocations.current)
        if (routeStateProviderLocations.current) {
          const { locations: resLocations } = res
          resLocations.forEach(resLocation => {
            const foundLocationInState = routeStateProviderLocations.current.find(
              i => i.uuid === resLocation.uuid,
            )
            if (foundLocationInState) {
              resLocation.distance = foundLocationInState.distance
            }
          })
        }
        return res
      }, []),

      onError: error => {
        console.log('useQuery getProvider:', error)
      },
    },
  )

  const providerPhysicalLocations = getProviderPhysicalLocations(provider)
  const providerVirtualLocations = getProviderVirtualLocations(provider)

  const onClickToggleTab = () => {
    if (TOGGLE_STATES.every(t => t.enabled)) {
      if (isLeft) {
        onLocationSelect(
          providerVirtualLocations.length > 0
            ? providerVirtualLocations[0]
            : {},
        )
      } else {
        onLocationSelect(
          providerPhysicalLocations.length > 0
            ? providerPhysicalLocations[0]
            : {},
        )
      }
      setLeftToggle(!isLeft)
    }
  }

  useEffect(() => {
    setLeftToggle(!isLocationVirtual(selectedLocation))
  }, [selectedLocation])

  const fetchProvider = useCallback(async nextLocationId => {
    console.log(`Fetching Provider: location:${nextLocationId}`)

    // Not throwing an error here as update is already successful
    const response = await refetchProvider()

    console.log(`🚀 => fetchProvider:query`, response)
    const { data: latestProvider, error: fetchProviderError } = response
    if (fetchProviderError) {
      notyf.open({
        type: 'error',
        duration: 6000,
        message: `An error occurred fetching this provider's latest data.`,
      })
    }
    if (latestProvider && isMounted()) {
      navigate(`/providers/${latestProvider.npi}/${nextLocationId}`, {
        replace: true,
      })

      const providerLocation = latestProvider.locations.find(
        l => l.uuid === nextLocationId,
      )

      if (providerLocation) {
        setSelectedLocation(providerLocation)
      } else {
        setSelectedLocation(
          latestProvider.locations && latestProvider.locations.length > 0
            ? latestProvider.locations[0]
            : {},
        )
      }
    }
  }, [])

  const refreshProviderData = useCallback(
    async location => {
      console.log('Refresh with location: ', location)
      // adding fake latency so getProvider & searchProviders have time to return updated data
      // calling instantly would sometimes return old data.
      await new Promise(resolve => setTimeout(resolve, 1800))
      await fetchProvider(location.uuid)

      queryClient.invalidateQueries({
        predicate: query => {
          // invalidating all other queries besides the current query in search
          // invalidated queries will be refetched when searched for again and not cached with old data
          const invalidateQuery =
            query.queryKey[0] &&
            query.queryKey[0] === searchQueryKeys.providersSearch[0] &&
            (!query.queryKey[1] || query.queryKey[1] !== providersSearchQuery)

          return invalidateQuery ? query : false
        },
      })

      // Do not need to wait for it to finish - worst case search results will have a loading indicator
      // Resetting data for any update/refresh, so data loads again on search container
      queryClient.refetchQueries(
        searchQueryKeys.providersSearchWithQuery(providersSearchQuery),
      )
    },
    [fetchProvider, providersSearchQuery],
  )

  const closeModal = useCallback(
    async result => {
      console.log(`🚀 => closeModal:result`, result)
      const { refresh, newProviderLocation } = result || {}
      if (refresh) {
        if (newProviderLocation) {
          await refreshProviderData(newProviderLocation)
        } else {
          await refreshProviderData(selectedLocation)
        }
      }
      if (isMounted()) {
        setEditType()
        if (scrollTo) setScrollTo()
        if (wasViewingOtherLocations.current) {
          wasViewingOtherLocations.current = false
          toggleShowAllLocations(true)
        }
      }
    },
    [refreshProviderData, selectedLocation, scrollTo],
  )

  const handleCustomFieldUpdateSuccess = useCallback(async () => {
    return refreshProviderData(selectedLocation)
  }, [refreshProviderData, selectedLocation])

  const onClickEditLocation = useCallback(locationScroll => {
    if (Object.values(LOCATION_SCROLL_OPTIONS).includes(locationScroll)) {
      // May need adjusting to be one state update with editType if not reliable enough
      setScrollTo(locationScroll)
    }
    typeof window !== 'undefined' &&
      window.gtag('event', 'click', { event_label: 'Edit Provider Location' })
    setEditType(EDIT_TYPES.location)
  }, [])

  const onClickAddLocation = useCallback(() => {
    wasViewingOtherLocations.current = true
    toggleShowAllLocations(false)
    typeof window !== 'undefined' &&
      window.gtag('event', 'click', { event_label: 'Add Provider Location' })
    setEditType(EDIT_TYPES.addLocation)
  }, [])

  // Possibly combine all edit types into one object
  const onClickEditProviderInfo = useCallback(() => {
    typeof window !== 'undefined' &&
      window.gtag('event', 'click', { event_label: 'Edit Provider Info' })
    setEditType(EDIT_TYPES.info)
  }, [])

  /**
   * Sets Location to State and updates URL with selected ID
   * for up to date refresh handling
   */
  const onLocationSelect = useCallback(
    location => {
      if (location) {
        setSelectedLocation(location)
        navigate(`/providers/${provider.npi}/${location.uuid}`, {
          replace: true,
        })
      }
    },
    [provider],
  )

  const onClickNotesLabel = () => {
    if (notesContainerRef && notesContainerRef.current) {
      notesContainerRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  const onClickAddNote = () => {
    setEditType(EDIT_TYPES.addNotes)
  }

  return (
    <>
      <Flex s={() => ({ height: '100%' })}>
        <Flex
          s={() => ({
            height: '100%',
            flexDirection: 'column',
            flex: 0.3,
            maxWidth: '27.5rem',
          })}
        >
          <BackToSearch
            onBackClick={() => navigate('/')}
            disabled={isMutating > 0 || (fetchingProvider && !isLoading)}
          />
          {provider && (
            <LeftDetailColumn>
              <Heading
                type={HEADING_TYPE.H1}
                s={p => ({
                  color: p.theme.colors.gray8,
                  lineHeight: '120%',
                  fontWeight: p.theme.fontWeights.semiUltraBold,
                })}
              >
                {provider.firstName} {provider.lastName}
              </Heading>
              <List
                disableItemHoverStyle={true}
                s={p => ({
                  marginTop: p.theme.spacing.three,
                })}
              >
                <Flex>
                  <Label
                    type={LABEL_TYPE.BODY1}
                    s={p => ({
                      color: p.theme.colors.gray,
                      display: 'flex',
                    })}
                  >
                    NPI:&nbsp;
                  </Label>
                  <Copy value={provider.npi} />
                </Flex>
              </List>
              {isVirtualSearchEnabled && (
                <BinaryToggle
                  toggleTitle="Visit Type"
                  tabs={TOGGLE_STATES}
                  onClickTab={onClickToggleTab}
                  activeTab={isLeft ? 'inPerson' : 'virtualCare'}
                />
              )}
              <Flex
                s={p => ({
                  flexDirection: 'column',
                  margin: `${p.theme.sizes.five} 0`,
                })}
              >
                {provider.specialties.map((specialty, specialtyIndex) => (
                  <Label
                    s={p => ({
                      color: p.theme.colors.gray1,
                    })}
                    type={LABEL_TYPE.BODY1}
                    key={`specialty-row-${specialtyIndex}`}
                  >
                    {specialty.display}
                    {specialty.isPrimary ? ' (Primary Specialty)' : ''}
                  </Label>
                ))}
              </Flex>
              {providerModules.care_journey.enabled && (
                <CareJourney performance={provider.performance} />
              )}
              <Flex
                s={p => ({
                  ['> div:first-of-type']: {
                    marginRight: p.theme.sizes.six,
                  },
                })}
              >
                {provider.ratingsCount > 0 && (
                  <RatingLabel
                    rating={
                      provider.ratingsCount > 0
                        ? provider.ratingsAvg / 2
                        : undefined
                    }
                    label={`(${provider.ratingsCount})`}
                  />
                )}
                {providerModules.notes && providerModules.notes.enabled && (
                  <NoteLabel
                    notesLength={provider.notes ? provider.notes.length : 0}
                    onClickLabel={onClickNotesLabel}
                    onClickAddNote={onClickAddNote}
                  />
                )}
              </Flex>

              {providerModules.preference &&
                providerModules.preference.enabled && (
                  <PreferenceToggle
                    provider={provider}
                    handleUpdateSuccess={handleCustomFieldUpdateSuccess}
                  />
                )}
              {providerModules.focus_areas.enabled && (
                <ProviderDemographics
                  panelDemographics={provider.panelDemographics}
                />
              )}
              <Divider />

              <Label
                type={LABEL_TYPE.BODY1}
                s={p => ({
                  color: p.theme.colors.gray1,
                  fontWeight: '700',
                  fontSize: p.theme.headingSizes.H3,
                })}
              >
                Provider Details
              </Label>

              <Label
                type={LABEL_TYPE.BODY1}
                s={p => ({
                  color: p.theme.colors.gray,
                  fontWeight: 500,
                  marginTop: p.theme.sizes.four,
                })}
              >
                Gender
              </Label>
              <Label
                type={LABEL_TYPE.BODY1}
                s={p => ({ color: p.theme.colors.gray1 })}
              >
                {(provider.gender === 'm' && 'Male') ||
                  (provider.gender === 'f' && 'Female')}
              </Label>

              <Label
                type={LABEL_TYPE.BODY1}
                s={p => ({
                  marginTop: p.theme.sizes.four,
                  color: p.theme.colors.gray,
                  fontWeight: 500,
                })}
              >
                Age
              </Label>
              <Label
                type={LABEL_TYPE.BODY1}
                s={p => ({ color: p.theme.colors.gray1 })}
              >
                {formatAgeRange(provider.age)}
              </Label>
              {provider.educations.length > 0 && (
                <>
                  <Label
                    key={'education-heading'}
                    type={LABEL_TYPE.BODY1}
                    s={p => ({
                      color: p.theme.colors.gray,
                      fontWeight: 500,
                      marginTop: p.theme.sizes.four,
                    })}
                  >
                    Education
                  </Label>
                  {provider.educations.map((education, educationIndex) => (
                    <Label
                      key={`education-row-${educationIndex}`}
                      type={LABEL_TYPE.BODY1}
                      s={p => ({ color: p.theme.colors.gray1 })}
                    >
                      {`
                      ${education.type ? education.type + ', ' : ''}
                      ${education.education.name}${
                        education.year ? ', ' + education.year : ''
                      }
                      `}
                    </Label>
                  ))}
                </>
              )}

              <Label
                type={LABEL_TYPE.BODY1}
                s={p => ({
                  color: p.theme.colors.gray,
                  fontWeight: 500,
                  marginTop: p.theme.sizes.four,
                })}
              >
                Languages
              </Label>
              <Label
                type={LABEL_TYPE.BODY1}
                s={p => ({ color: p.theme.colors.gray1 })}
              >
                {formatLanguages(provider.languages)}
              </Label>
              <PermissionRestricted level={2}>
                <Flex
                  s={p => ({
                    marginTop: p.theme.sizes.six,
                  })}
                >
                  <EditDetailButton
                    label="Edit Provider Info"
                    onClick={onClickEditProviderInfo}
                    size="lg"
                  />
                </Flex>
              </PermissionRestricted>

              <CustomFieldsSection
                type="providers"
                section="sidebar"
                item={provider}
                handleUpdateSuccess={handleCustomFieldUpdateSuccess}
              />
            </LeftDetailColumn>
          )}
        </Flex>
        <Flex
          s={() => ({
            flexDirection: 'column',
            flex: 0.7,
            height: '100%',
            background: colors.gray7,
          })}
        >
          <RightDetailColumn ref={rightDetailContainerRef}>
            {isLoading ? (
              <Spinner label="Loading provider" />
            ) : (
              <>
                {Boolean(fetchProviderError) && !isRefetchError ? (
                  <Label
                    s={p => ({
                      fontSize: p.theme.sizes.five,
                      fontWeight: 'bold',
                    })}
                  >
                    Load error - please try again.
                  </Label>
                ) : (
                  provider &&
                  selectedLocation && (
                    <>
                      {Object.keys(selectedLocation).length > 0 && (
                        <LocationsPanel
                          location={selectedLocation}
                          setSelectedLocation={setSelectedLocation}
                          onSeeOtherLocationsClick={toggleShowAllLocations}
                          onClickEditLocation={onClickEditLocation}
                          refreshLocation={refreshProviderData}
                          provider={provider}
                        />
                      )}
                      <SchedulingPanel
                        provider={provider}
                        selectedLocation={selectedLocation}
                      />
                      {/* START INSURANCES */}
                      {providerModules.networks.enabled && (
                        <>
                          <Divider />
                          <InsurancesContainer
                            handleUpdateSuccess={closeModal}
                            provider={provider}
                            selectedLocation={selectedLocation}
                            modalType={editType}
                            setModalType={setEditType}
                          />
                        </>
                      )}
                      {/* END INSURANCES */}

                      {/* START CLINICAL AREAS */}
                      {providerModules.focus_areas.enabled && (
                        <>
                          <Divider />
                          <ClinicalAreas
                            clinicalAreas={provider.clinicalAreas}
                          />
                        </>
                      )}
                      {/* END CLINICAL AREAS */}
                      {providerModules.procedures.enabled && (
                        <>
                          <Divider />
                          <Procedures procedures={provider.procedures} />
                        </>
                      )}

                      {/* START NOTES SECTION */}
                      {providerModules.notes && providerModules.notes.enabled && (
                        <>
                          <Divider />
                          <NotesContainer
                            notes={provider.notes}
                            provider={provider}
                            handleUpdateSuccess={handleCustomFieldUpdateSuccess}
                            ref={notesContainerRef}
                            // Edit & Add Notes Modals
                            modalType={editType}
                            setModalType={setEditType}
                          />
                        </>
                      )}

                      {/* END NOTES SECTION */}

                      <CustomFieldsSection
                        type="providers"
                        section="main"
                        item={provider}
                        handleUpdateSuccess={handleCustomFieldUpdateSuccess}
                      />
                    </>
                  )
                )}
              </>
            )}
          </RightDetailColumn>
        </Flex>
      </Flex>
      {/* Edit Modals */}
      {editType === EDIT_TYPES.info && (
        <ProviderDetailEditInfoForm
          provider={provider}
          closeModal={closeModal}
        />
      )}
      {(editType === EDIT_TYPES.location ||
        editType === EDIT_TYPES.addLocation) && (
        <DetailEditLocationForm
          selectedLocation={
            editType === EDIT_TYPES.addLocation
              ? NEW_LOCATION
              : selectedLocation || {}
          }
          closeModal={closeModal}
          addNew={editType === EDIT_TYPES.addLocation}
          title={
            editType === EDIT_TYPES.addLocation
              ? 'Add New Location'
              : 'Edit Location Details'
          }
          provider={provider}
          scrollTo={scrollTo}
        />
      )}
      {showAllLocations && (
        <LocationsListModal
          closeModal={() => toggleShowAllLocations(false)}
          selectedLocation={selectedLocation}
          onLocationSelect={onLocationSelect}
          onClickAddLocation={onClickAddLocation}
          provider={provider}
          refreshProvider={refreshProviderData}
          onClickEditLocation={onClickEditLocation}
        />
      )}
      <ScrollToTopBtn containerRef={rightDetailContainerRef} minScroll={500} />
    </>
  )
}

export const Divider = styled.div`
  ${p => ({
    height: '1px',
    width: '100%',
    backgroundColor: p.theme.colors.gray4,
    margin: `${p.theme.sizes.six} 0`,
  })}
`
ProviderDetail.propTypes = {
  providerId: PropTypes.string,
  location: PropTypes.object,
  locationId: PropTypes.string,
  theme: PropTypes.object,
}
