import { useContext, useEffect } from 'react'
import { HasChangesContext } from './has-changes-context'

const stateObjectHash = {
  objectHash: obj => JSON.stringify(obj),
  arrays: {
    detectMove: false,
  },
}
const jsonDiff = require('jsondiffpatch')

export const useHasChanges = (state, editState) => {
  const [hasChanges, setHasChanges] = useContext(HasChangesContext)

  useEffect(() => {
    // Compare state & editState
    const stateDiff = jsonDiff.create(stateObjectHash).diff(state, editState)
    if (stateDiff) {
      // console.log(`🚀 => useEffect => stateDiff`, stateDiff)

      if (!hasChanges) {
        setHasChanges(true)
      }
    } else {
      if (hasChanges) {
        setHasChanges(false)
      }
    }
  }, [state, editState, hasChanges])

  return
}
