import React from 'react'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import { DataCard } from '../components/DataCard'
import styled from 'styled-components'
import { DataCardContent } from '../components/DataCardContent'

export const MetricsCards = () => {
  return (
    <Flex
      s={p => ({
        marginTop: p.theme.sizes.six,
        justifyContent: 'space-between',
        ['> div']: {
          flex: `0 0 31%`,
        },
      })}
    >
      <DataCard>
        <DataCardContent title="API Requests" description="Last 30 days">
          <DataMainLabel>1,000</DataMainLabel>
        </DataCardContent>
      </DataCard>
      <DataCard>
        <DataCardContent title="Uptime" description="Last 90 days">
          <DataMainLabel>99.5%</DataMainLabel>
        </DataCardContent>
      </DataCard>
      <DataCard>
        <DataCardContent title="API Status" description="No current issues">
          <StatusLabel status="success">Connected</StatusLabel>
        </DataCardContent>
      </DataCard>
    </Flex>
  )
}

const DataMainLabel = styled(Label)`
  margin: ${p => p.theme.sizes.four} 0;
  font-weight: 500;
  font-size: ${p => p.theme.headingSizes.H1};
  color: ${p => p.theme.colors.gray1};
`

const StatusLabel = styled(DataMainLabel)`
  display: flex;
  align-items: center;

  ::before {
    content: '';
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: ${p =>
      p.status === 'success'
        ? p.theme.colors.successGreen
        : p.theme.colors.orange};
    margin-right: 8px;
  }
`
