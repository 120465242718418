/* eslint-disable react/prop-types */
import CaretDown from 'assets/caret-down.svg'
import React from 'react'
import styled from 'styled-components'

const TableHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex: 1;
  align-items: center;
`
const TableHeaderTitle = styled.p`
  font-weight: 500;
  line-height: 24px;
  ${p => ({
    ...(p.active && {
      fontWeight: 'bold',
    }),
  })};
`

export const Caret = styled(CaretDown)`
  ${props => props.direction === 'up' && `transform: rotate(180deg)`}
  ${props => props.direction === 'left' && `transform: rotate(90deg)`}
  ${props => props.direction === 'right' && `transform: rotate(-90deg)`}
`

export const SortingHeader = props => {
  const { column } = props
  // console.log('SortingHeader props', props);
  return (
    <TableHeader title={column.headerFullTitle || column.headerTitle}>
      <TableHeaderTitle active={column.isSorted}>
        {column.headerTitle}
      </TableHeaderTitle>

      {column.isSorted ? (
        <Caret direction={column.isSortedDesc ? 'down' : 'up'} />
      ) : (
        <div
          style={{ background: 'transparent', width: '16px', height: '16px' }}
        />
      )}
    </TableHeader>
  )
}
