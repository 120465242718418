import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { Block } from 'design-system/block'
import { ProceduresListItem } from './procedures-list-item'
import styled from 'styled-components'
import { DetailSearchInput } from 'components/detail-search-input/detail-search-input'
import { proceduresReducer } from './procedures-reducer'
import { Label, LABEL_TYPE } from 'design-system/label'

export const ProceduresList = ({ procedures }) => {
  const [state, dispatch] = useReducer(proceduresReducer, {
    search: '',
    filteredData: [...procedures],
    initialData: procedures,
  })

  const { search, filteredData } = state
  return (
    <>
      <DetailSearchInput
        placeholder="Search procedures"
        value={search}
        onChange={() => dispatch({ type: 'clear' })}
        onInputChange={e =>
          dispatch({
            type: 'update',
            payload: { search: e.target.value },
          })
        }
      />

      <HeaderRow />

      <ListCard type={'CARD'}>
        {filteredData.map((item, index) => {
          return (
            <ProceduresListItem
              key={item.uuid}
              item={item}
              isLast={index === filteredData.length - 1}
            />
          )
        })}
        {filteredData.length === 0 && search.length > 0 && (
          <Label
            s={p => ({
              padding: `${p.theme.sizes.four} 0`,
            })}
            type={LABEL_TYPE.BODY1_BOLD}
          >
            No matching results
          </Label>
        )}
      </ListCard>
    </>
  )
}

const ListCard = styled(Block)`
  ${p => ({
    padding: `0 ${p.theme.sizes.four} 0 ${p.theme.sizes.four}`,
    fontSize: p.theme.sizes.four,
    maxHeight: '24rem',
    overflowY: 'scroll',
  })}
`

const HeaderRow = () => {
  return (
    <Block
      type="FLEX"
      s={p => ({
        padding: `${p.theme.sizes.two} ${p.theme.sizes.four} ${p.theme.sizes.two} ${p.theme.sizes.four}`,
        justifyContent: 'space-between',
        marginBottom: p.theme.sizes.two,
        alignItems: 'center',
      })}
    >
      <Label
        s={() => ({
          flex: 0.45,
        })}
        type={LABEL_TYPE.BODY1_BOLD}
      >
        Procedure Name
      </Label>
      <Label
        s={p => ({
          flex: 0.3,
          color: p.theme.colors.gray1,
          textAlign: 'center',
        })}
        type={LABEL_TYPE.BODY1_BOLD}
      >
        Experience Level
      </Label>
      <Label
        s={p => ({
          flex: 0.25,
          color: p.theme.colors.gray1,
          textAlign: 'center',
          marginRight: p.theme.sizes.four, // offset for scrollbar on list items
        })}
        type={LABEL_TYPE.BODY1_BOLD}
      >
        Cost
      </Label>
    </Block>
  )
}

ProceduresList.propTypes = {
  procedures: PropTypes.arrayOf(PropTypes.object),
}
