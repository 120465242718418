import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal } from 'design-system/modal'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Chevron } from 'design-system/icon/icons/chervon-left'
import { Flex } from 'design-system/flex'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { FIELD_TYPES_FLAT_LOWER } from 'utils/constants'
import { customFieldsEditsAreValid } from 'utils/validations'
import { InputByType } from './input-by-type'
import { useCustomFieldMutations } from './useCustomFieldMutations'
import { Spinner } from 'design-system/spinner'

const objectHash = {
  objectHash: obj => JSON.stringify(obj),
  arrays: {
    detectMove: false,
  },
}
const jsonDiff = require('jsondiffpatch')

const createOriginalField = (field, values) => {
  if (!field) return {}
  switch (field.field_type) {
    case FIELD_TYPES_FLAT_LOWER.object:
      return {
        [field.display_name]: {
          ...values,
        },
      }
    case FIELD_TYPES_FLAT_LOWER.string:
    case FIELD_TYPES_FLAT_LOWER.boolean:
    case FIELD_TYPES_FLAT_LOWER.integer:
    case FIELD_TYPES_FLAT_LOWER.date:
    case FIELD_TYPES_FLAT_LOWER.float:
      return {
        [field.display_name]: {
          [field.display_name]: values[0],
        },
      }
    default:
      return {}
  }
}

export const EditCustomFieldModal = ({
  field = {},
  values = [],
  handleClose = async () => {},
  type,
  updateId,
}) => {
  const { object_display_config } = field
  const ogField = createOriginalField(field, values)
  const [editField, setEditField] = useState(
    JSON.parse(JSON.stringify(ogField)),
  )

  const fieldDiff = jsonDiff.create(objectHash).diff(ogField, editField)
  // console.log(`🚀 => field`, field)
  // console.log(`🚀 => ogField`, ogField)
  // console.log(`🚀 => editField`, editField)
  // console.log(`🚀 => fieldDiff`, fieldDiff)

  const [showReviewChanges, setShowReviewChanges] = useState(false)

  const {
    updateProviderInfo,
    updatingProviderField,
    updateFacilityInfo,
    updatingFacilityField,
  } = useCustomFieldMutations({ field, updateId, handleClose })

  const submitChanges = () => {
    console.log(`🚀 => submitChanges:editField`, editField[field.display_name])

    const updateBody = {}

    if (field.field_type === FIELD_TYPES_FLAT_LOWER.object) {
      updateBody[field.parameter_name] = editField[field.display_name]
    } else {
      // string & integer & float & boolean & date
      // integer & floats converted to number format on input change
      updateBody[field.parameter_name] =
        editField[field.display_name][field.display_name]
    }

    console.log(`🚀 => submitChanges:updateBody`, updateBody)

    if (type === 'providers') {
      updateProviderInfo({ options: updateBody })
    }
    if (type === 'facilities') {
      updateFacilityInfo({ options: updateBody })
    }
  }
  const toggleReviewChanges = () => {
    console.log(`🚀 => toggleReviewChanges`)
    setShowReviewChanges(prev => !prev)
  }

  const onChangeField = e => {
    let { value, name, type } = e.target
    // console.log(`🚀 => onChangeField => value, name, type`, value, name, type)
    if (type === 'number') {
      value = Number(value)
      if (value > Number.MAX_SAFE_INTEGER) {
        value = Number.MAX_SAFE_INTEGER
      }
    }
    if (field.field_type === FIELD_TYPES_FLAT_LOWER.object) {
      setEditField(prev => {
        const newEditField = {
          [field.display_name]: {
            ...prev[field.display_name],
            [name]: value,
          },
        }
        return newEditField
      })
    } else {
      // handles String, Boolean, Float, Integer, Date cases
      setEditField(prev => {
        return {
          ...prev,
          [field.display_name]: {
            [field.display_name]: value,
          },
        }
      })
    }
  }

  const onKeyDown = e => {
    if (e.key === 'Tab') {
      // handles String, Float, Integer cases
      if (
        field.field_type === FIELD_TYPES_FLAT_LOWER.string ||
        field.field_type === FIELD_TYPES_FLAT_LOWER.integer ||
        field.field_type === FIELD_TYPES_FLAT_LOWER.float
      ) {
        if (!editField[field.display_name][field.display_name]) {
          // set default value as input value
          let defaultValue = field.default_value
          if (
            field.field_type === FIELD_TYPES_FLAT_LOWER.integer ||
            field.field_type === FIELD_TYPES_FLAT_LOWER.float
          ) {
            defaultValue = Number(defaultValue)
          }
          setEditField(prev => {
            return {
              ...prev,
              [field.display_name]: {
                [field.display_name]: defaultValue,
              },
            }
          })
        }
      }
    }
  }

  let disabledReviewChangesBtn = true
  if (fieldDiff !== undefined) {
    if (customFieldsEditsAreValid(field, editField)) {
      disabledReviewChangesBtn = false
    }
  }

  const isUpdating = updatingProviderField || updatingFacilityField

  return (
    <Modal
      onClose={() => handleClose(null)}
      title={`Edit ${field.display_name || 'Field Name'}`}
      gridContainerStyle={{ maxWidth: '600px' }}
      headerLeftAction={
        showReviewChanges ? (
          <Label
            type={LABEL_TYPE.LINK}
            onClick={() => setShowReviewChanges(false)}
          >
            <Chevron direction="left" style={{ marginRight: '0.5rem' }} />
            Back to edit
          </Label>
        ) : null
      }
      footer={
        <Flex
          s={p => ({
            paddingRight: p.theme.sizes.four,
            justifyContent: 'flex-end',
          })}
        >
          <Button
            s={p => ({
              marginRight: p.theme.sizes.two,
            })}
            type={BUTTON_TYPE.LINK}
            onClick={() => handleClose(null)}
          >
            Cancel
          </Button>
          <Button
            onClick={showReviewChanges ? submitChanges : toggleReviewChanges}
            type={
              disabledReviewChangesBtn || isUpdating
                ? BUTTON_TYPE.DISABLED
                : BUTTON_TYPE.PRIMARY
            }
            disabled={disabledReviewChangesBtn || isUpdating}
          >
            {showReviewChanges ? 'Submit changes' : 'Next: Review Changes'}
          </Button>
        </Flex>
      }
    >
      {isUpdating ? (
        <Flex
          s={() => ({
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <Spinner label={`Updating ${field.display_name || 'Field Name'}`} />
        </Flex>
      ) : (
        <FormContainer>
          {field.field_type === FIELD_TYPES_FLAT_LOWER.object ? (
            // Object
            <>
              {Object.keys(object_display_config.keys).map(key => {
                const displayConfigKey = object_display_config.keys[key]
                const keyValue =
                  editField[field.display_name][displayConfigKey.name]

                return (
                  <Flex
                    key={key}
                    s={p => ({
                      justifyContent: 'space-between',
                      marginTop: p.theme.sizes.four,
                    })}
                  >
                    <InputContainer>
                      <Label type={LABEL_TYPE.BODY1_BOLD}>
                        {displayConfigKey.display_name}
                      </Label>
                      <InputByType
                        fieldType={displayConfigKey.type}
                        value={keyValue}
                        name={displayConfigKey.name}
                        onChangeField={onChangeField}
                        disabled={showReviewChanges}
                      />
                    </InputContainer>
                  </Flex>
                )
              })}
            </>
          ) : (
            // String, Float, Boolean, Integer, Date
            <InputContainer>
              <Label type={LABEL_TYPE.BODY1_BOLD}>{field.display_name}</Label>
              <InputByType
                fieldType={field.field_type}
                value={
                  editField[field.display_name]
                    ? editField[field.display_name][field.display_name]
                    : ''
                }
                onChangeField={onChangeField}
                defaultValue={field.default_value}
                onKeyDown={onKeyDown}
                disabled={showReviewChanges}
              />
            </InputContainer>
          )}
        </FormContainer>
      )}
    </Modal>
  )
}

const InputContainer = styled.div`
  flex: 1;
`

const FormContainer = styled.div`
  padding: ${p => p.theme.sizes.four};
  min-height: 120px;
`

EditCustomFieldModal.propTypes = {
  field: PropTypes.object,
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  handleClose: PropTypes.func,
  type: PropTypes.string,
  updateId: PropTypes.string,
}
