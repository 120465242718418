/* eslint-disable react/prop-types */
import React from 'react'
import { SortingHeader } from 'components/table/sorting-header'
import { Checkbox } from 'design-system/checkbox'
import { Label } from 'design-system/label'
import { Block } from 'design-system/block'
import { DropdownMenu, Overlay } from 'components/dropdown/dropdown'
import { formatPermissionLevel } from 'utils/formatters/format-permission-levels'
import dayjs from 'utils/dayjs'
import { Flex } from 'design-system/flex'

const CheckboxHeader = ({
  toggleAllPageRowsSelected,
  isAllPageRowsSelected,
  selectedFlatRows,
}) => {
  const isIndeterminate = !isAllPageRowsSelected && selectedFlatRows.length > 0
  return (
    <Flex
      s={() => ({
        height: '100%',
        alignItems: 'center',
      })}
    >
      <Checkbox
        indeterminate={isIndeterminate}
        value={isAllPageRowsSelected}
        onClick={() => toggleAllPageRowsSelected()}
      />
    </Flex>
  )
}

const CheckBoxCell = ({ row, index }) => {
  return (
    <Checkbox
      data-testid={`checkboxcell-${index}`}
      value={row.isSelected}
      onClick={() => row.toggleRowSelected()}
    />
  )
}

const MoreMenuCell = ({ custom, cell, page, state: tableState }) => {
  const { actions, state } = custom
  const {
    onClickMore,
    onClickResendInvite,
    onClickUpdatePermissions,
    onClickRemoveUsers,
  } = actions
  const { activeMenu } = state

  const { original: user, index } = cell.row

  let inverseDropdown = false
  if (page && page.length >= 3) {
    const { pageIndex, pageSize } = tableState
    const pageMultiple = pageIndex * pageSize
    // page mutiple because index = index of all table results which doesnt account current pages
    // eg. page 2 - index(22) - pagesize(20) -> should not inverse
    let pageLengthMinusItemIndex = page.length - (index - pageMultiple)
    // 3 results is minimum to start pointing dropdown upwards(inverse)
    let differenceForInverse = page.length <= 3 ? 1 : 2

    if (pageLengthMinusItemIndex <= differenceForInverse) {
      inverseDropdown = true
    }
  }

  const showMenu = activeMenu === user.id

  return (
    <div>
      {showMenu && (
        <Overlay
          data-testid="more-menu-overlay"
          onClick={() => onClickMore(null)}
        />
      )}
      <Block
        s={() => ({
          position: 'relative',
        })}
      >
        <Label
          s={() => ({
            fontWeight: 'bold',
            fontSize: '1.25em',
            textAlign: 'center',
          })}
          onClick={() => onClickMore(user)}
          data-testid="more-menu-label"
        >
          ...
        </Label>
        {showMenu && (
          <DropdownMenu
            inverse={inverseDropdown}
            options={[
              ...(user && !user.lastLogin && !user.isSso
                ? [
                    {
                      title: 'Resend Invite',
                      onClick: () => {
                        onClickResendInvite(user)
                        onClickMore(null)
                      },
                    },
                  ]
                : []),
              {
                title: 'Update Permissions',
                onClick: () => {
                  onClickUpdatePermissions(user)
                  onClickMore(null)
                },
              },
              {
                title: 'Remove User',
                onClick: () => {
                  onClickRemoveUsers(user)
                  onClickMore(null)
                },
              },
            ]}
          />
        )}
      </Block>
    </div>
  )
}

export const usersColumns = [
  {
    Header: CheckboxHeader,
    headerTitle: '',
    Cell: CheckBoxCell,
    accessor: 'selection', // accessor is the "key" in the data
    minWidth: 40, // minWidth is only used as a limit for resizing
    width: 40, // width is used for both the flex-basis and flex-grow
    disableSortBy: true,
  },
  {
    Header: SortingHeader,
    headerTitle: 'Name',
    accessor: 'name', // accessor is the "key" in the data
    width: 225,
    defaultCanSort: true,
    Cell: cell =>
      `${cell.row.original.firstName} ${cell.row.original.lastName}`,
  },
  {
    Header: SortingHeader,
    headerTitle: 'Email Address',
    accessor: 'email', // accessor is the "key" in the data
    width: 225,
    defaultCanSort: false,
  },
  {
    Header: SortingHeader,
    headerTitle: 'Permissions',
    accessor: 'permissionLevel', // accessor is the "key" in the data
    width: 150,
    defaultCanSort: true,
    Cell: cell => formatPermissionLevel(cell.row.original.permissionLevel),
  },
  {
    Header: SortingHeader,
    headerTitle: 'Last Login',
    accessor: 'lastLogin', // accessor is the "key" in the data
    width: 225,
    defaultCanSort: true,
    Cell: cell =>
      cell.row.original.lastLogin
        ? dayjs(cell.row.original.lastLogin).format('lll')
        : '-',
  },
  {
    Header: () => {
      return null
    },
    headerTitle: '',
    Cell: MoreMenuCell,
    accessor: 'more_menu', // accessor is the "key" in the data
    minWidth: 40, // minWidth is only used as a limit for resizing
    width: 40, // width is used for both the flex-basis and flex-grow
    disableSortBy: true,
  },
]

export const customFilterTypes = {
  // custom filter
  usersSearch: (rows, id, filterValue) => {
    // console.log('search', rows, id, filterValue)
    return rows.filter(row => {
      const {
        original: { firstName, lastName },
      } = row
      const fullNameLower = `${firstName.toLowerCase()} ${lastName.toLowerCase()}`

      return row !== undefined
        ? fullNameLower.includes(filterValue.toLowerCase())
        : false
    })
  },
}

export const tableDefaultColumn = {
  // When using the useFlexLayout:
  minWidth: 80, // minWidth is only used as a limit for resizing
  width: 180, // width is used for both the flex-basis and flex-grow
  maxWidth: 300,
}
