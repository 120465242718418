export const getAddressFromPlaceResult = result => {
  const { address_components } = result
  const { long_name: streetNumber } =
    address_components.find(item => item.types.includes('street_number')) || {}
  const { short_name: street } =
    address_components.find(item => item.types.includes('route')) || {}

  // Try neighborhood first, moving up localities until City is filled
  let { long_name: city } =
    address_components.find(item => item.types.includes('neighborhood')) || {}
  // Backup to neighborhood
  if (!city) {
    const { long_name: sublocality } =
      address_components.find(item => item.types.includes('sublocality')) || {}
    city = sublocality
  }
  // backup to sublocality
  if (!city) {
    const { long_name: locality } =
      address_components.find(item => item.types.includes('locality')) || {}
    city = locality
  }

  const { short_name: state } =
    address_components.find(item =>
      item.types.includes('administrative_area_level_1'),
    ) || {}

  const { long_name: zipcode } =
    address_components.find(item => item.types.includes('postal_code')) || {}

  const address = {
    address: `${streetNumber ? `${streetNumber} ` : ''}${street}`,
    city,
    state,
    zipcode: zipcode || '-',
  }

  return address
}

/**
 * Formats address as one line given address properties, returns empty string if no address given
 * @param {string} address
 * @param {string} unit
 * @param {string} city
 * @param {string} state
 * @param {string} zip
 */
export const formatAddress = (
  address = '',
  unit,
  city = '',
  state = '',
  zip = '',
) => {
  if (!address) {
    return ''
  }

  return `${address}${unit ? `, ${unit}` : ''}, ${city}, ${state}, ${zip}`
}
