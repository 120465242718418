import { useContext, useMemo } from 'react'
import { useQuery } from 'react-query'
import { NotyfContext } from 'context/notyf-context'
import * as AlgoliaService from 'services/algolia'
import { AuthStateContext } from 'context/auth-state-context'

const searchQueryKeys = {
  insuranceSearchCarriers: inputValue => [
    'insuranceSearchCarriers',
    inputValue,
  ],
  insuranceSearchPlans: inputValue => ['insuranceSearchPlans', inputValue],
}

const formatManualSearchResults = (carriers, plans, loading) => {
  if (loading) {
    return []
  }
  if (!carriers || !plans) {
    return []
  }
  let filteredCarriers = []
  carriers.forEach((carrier) => {
    if (carrier.carrier_name) {
      filteredCarriers.push({
        displayName: carrier.carrier_name,
        uuid: carrier.carrier_name,
        isCarrier: true,
      })
    }
  })
  const sortedResults = [
    { displayName: 'Carriers', uuid: 'carriers', isHeader: true },
    ...filteredCarriers,
    { displayName: 'Plans', uuid: 'plans', isHeader: true },
    ...plans.map(plan => ({
      uuid: plan.uuid,
      displayName: plan.display_name,
      carrierName: plan.carrier_name,
      planName: plan._metadata.plan_name,
      state: plan._metadata.state,
    })),
  ]
  return sortedResults
}

export const useInsuranceManualSearchQuery = (inputValue, enabled) => {
  const notyfContext = useContext(NotyfContext)
  const [authState] = useContext(AuthStateContext)
  const appId = authState.refTypeaheadId
  const apiKey = authState.refTypeaheadKey
  const appName = authState.refTypeaheadName

  const { isLoading: plansLoading, data: plansResults } = useQuery(
    searchQueryKeys.insuranceSearchPlans(inputValue),
    () =>
      AlgoliaService.searchAlgoliaInsurances({
        appId: appId,
        apiKey: apiKey,
        appName: appName,
        inputValue: inputValue,
        options: {
          hitsPerPage: 100,
          facetFilters: ['type: insurance'],
          distinct: false,
        },
      }),
    {
      enabled,
      onError: error => {
        notyfContext.error(
          error.message || 'An error occurred searching insurances.',
        )
      },
    },
  )

  const { isLoading: carriersLoading, data: carrierResults } = useQuery(
    searchQueryKeys.insuranceSearchCarriers(inputValue),
    () =>
      AlgoliaService.searchAlgoliaInsurances({
        appId: appId,
        apiKey: apiKey,
        appName: appName,
        inputValue: inputValue,
        options: {
          hitsPerPage: 100,
          facetFilters: ['type: insurance'],
          distinct: true,
        },
      }),
    {
      enabled,
      onError: error => {
        notyfContext.error(
          error.message || 'An error occurred searching insurances.',
        )
      },
    },
  )

  const results = useMemo(
    () =>
      formatManualSearchResults(
        carrierResults,
        plansResults,
        plansLoading || carriersLoading,
      ),
    [carrierResults, plansResults],
  )

  return {
    results,
    isLoading: plansLoading || carriersLoading,
  }
}
