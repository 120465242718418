import React from 'react'
import styled from 'styled-components'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import { DataCard } from '../components/DataCard'

const CardContainer = styled(Flex)`
  flex-direction: column;
`

const ContentContainer = styled(DataCard)`
  padding: 0;
  > div:last-of-type {
    border: none;
  }
`

const CardRow = styled(Flex)`
  padding: ${p => p.theme.sizes.four} ${p => p.theme.sizes.four};
  border-bottom: 1px solid ${p => p.theme.colors.gray4};
`

const CardLabel = styled(Label)`
  min-width: 180px;
  margin-right: ${p => p.theme.sizes.six};
`

const ChangeLabel = styled(Label)`
  font-weight: 600;
  color: ${p => p.theme.colors.aubergine3};
  margin-left: ${p => p.theme.sizes.six};
`
export const SetupCard = ({ onClickChange = () => {} }) => {
  return (
    <CardContainer>
      <Label
        s={p => ({
          fontWeight: '500',
          fontSize: '1.125em',
          marginBottom: p.theme.sizes.four,
        })}
      >
        Setup
      </Label>
      <ContentContainer>
        <CardRow>
          <CardLabel>API Version</CardLabel>
          <Label>2021-05-12</Label>
          <ChangeLabel onClick={() => onClickChange('api_version')}>
            Change
          </ChangeLabel>
        </CardRow>
        <CardRow>
          <CardLabel>Typo Tolerance</CardLabel>
          <Label>5</Label>
          <ChangeLabel onClick={() => onClickChange('typo_tolerance')}>
            Change
          </ChangeLabel>
        </CardRow>
        <CardRow>
          <CardLabel>Setup Item</CardLabel>
          <Label>Value</Label>
          <ChangeLabel onClick={() => onClickChange('other')}>
            Change
          </ChangeLabel>
        </CardRow>
      </ContentContainer>
    </CardContainer>
  )
}
