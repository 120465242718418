import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import ArrowDown from 'assets/arrow-down.svg'
import { Label } from 'design-system/label'
import { MenuLabel } from 'components/dropdown/dropdown'
import { Button, BUTTON_TYPE } from 'design-system/button'
import dayjs from 'utils/dayjs'
import { Flex } from 'design-system/flex'

const InputLabel = styled(Label)`
  margin-bottom: ${p => p.theme.sizes.two};
  color: ${p => p.theme.colors.gray1};
`
const InputContainer = styled.div`
  margin-bottom: ${p => p.theme.sizes.four};
`

export const DateFilterDropdown = ({
  onClickApplyFilter = () => {},
  onClickClearFilter = () => {},
  dateCreatedFilter = null,
}) => {
  const [showCustomMenu, setShowCustomMenu] = useState(
    dateCreatedFilter && dateCreatedFilter.value.display === 'Custom',
  )
  const [customFilter, setCustomFilter] = useState({
    from: dateCreatedFilter ? dateCreatedFilter.value.from : null,
    to: dateCreatedFilter ? dateCreatedFilter.value.to : null,
    display: 'Custom',
  })

  if (showCustomMenu) {
    return (
      <Flex
        s={p => ({
          flexDirection: 'column',
          padding: `0 ${p.theme.sizes.five}`,
        })}
      >
        <Label
          s={p => ({
            marginBottom: p.theme.sizes.four,
            color: p.theme.colors.gray0,
          })}
        >
          Between
        </Label>
        <InputContainer>
          <InputLabel>Start Date</InputLabel>
          <DatePicker
            selected={customFilter.from}
            onChange={date =>
              setCustomFilter(prev => ({ ...prev, from: date }))
            }
            customInput={<CustomDatePickerInput />}
            todayButton="Today"
            maxDate={dayjs().toDate()}
            disabledKeyboardNavigation
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>End Date</InputLabel>
          <DatePicker
            selected={customFilter.to}
            onChange={date => setCustomFilter(prev => ({ ...prev, to: date }))}
            customInput={<CustomDatePickerInput />}
            todayButton="Today"
            maxDate={dayjs().toDate()}
            disabledKeyboardNavigation
          />
        </InputContainer>

        <Flex
          s={p => ({
            justifyContent: 'space-between',
            marginTop: p.theme.sizes.four,
          })}
        >
          <Button
            onClick={() => onClickClearFilter('created_at')}
            type={BUTTON_TYPE.LINK}
          >
            Clear
          </Button>
          <Button
            onClick={() => onClickApplyFilter('created_at', customFilter)}
            type={
              !customFilter.to || !customFilter.from
                ? BUTTON_TYPE.DISABLED
                : BUTTON_TYPE.PRIMARY
            }
            disabled={!customFilter.to || !customFilter.from}
          >
            Apply
          </Button>
        </Flex>
      </Flex>
    )
  }

  let selectedFilter = dateCreatedFilter
    ? dateCreatedFilter.value.display
    : null

  return (
    <>
      <MenuLabel
        onClick={() =>
          onClickApplyFilter('created_at', {
            from: dayjs().subtract(3, 'months').toDate(),
            to: dayjs().toDate(),
            display: 'Past 3 months',
          })
        }
        selected={selectedFilter === 'Past 3 months'}
      >
        Past 3 months
      </MenuLabel>
      <MenuLabel
        onClick={() =>
          onClickApplyFilter('created_at', {
            from: dayjs().subtract(6, 'months').toDate(),
            to: dayjs().toDate(),
            display: 'Past 6 months',
          })
        }
        selected={selectedFilter === 'Past 6 months'}
      >
        Past 6 months
      </MenuLabel>
      <MenuLabel
        onClick={() =>
          onClickApplyFilter('created_at', {
            from: dayjs().subtract(1, 'year').toDate(),
            to: dayjs().toDate(),
            display: 'Past year',
          })
        }
        selected={selectedFilter === 'Past year'}
      >
        Past year
      </MenuLabel>
      <MenuLabel
        selected={selectedFilter === 'Custom'}
        onClick={() => setShowCustomMenu(true)}
      >
        Custom
      </MenuLabel>
    </>
  )
}

const CustomDatePickerInput = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value = null, onClick = () => {} }, ref) => (
    <Flex
      ref={ref}
      onClick={onClick}
      s={p => ({
        padding: `${p.theme.sizes.two} ${p.theme.sizes.four}`,
        alignItems: 'center',
        justifyContent: 'flex-start',
        border: `1px solid ${p.theme.colors.borderMid}`,
        marginRight: p.theme.sizes.four,
        borderRadius: '100px',
        position: 'relative',
        backgroundColor: p.theme.colors.white,
        cursor: 'pointer',
        [':hover']: {
          borderColor: p.theme.colors.aubergine,
        },
      })}
    >
      <Label
        s={() => ({
          flex: 1,
        })}
      >
        {value ? dayjs(value).format('LL') : 'Select Date'}
      </Label>
      <ArrowDown />
    </Flex>
  ),
)
CustomDatePickerInput.displayName = 'CustomDatePickerInput'
