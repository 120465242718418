import React from 'react'
import PropTypes from 'prop-types'
import { locationHasInsurance } from 'utils/location'
import { Label, LABEL_TYPE } from 'design-system/label'
import UmbrellaIcon from 'assets/umbrella.svg'
import { Flex } from 'design-system/flex'

export const ContainsInsuranceIndicator = ({
  searchQuery,
  selectedLocation,
}) => {
  if (
    !searchQuery ||
    !searchQuery.insurance ||
    !locationHasInsurance(searchQuery.insurance, selectedLocation)
  ) {
    return null
  }
  return (
    <Flex
      s={p => ({
        alignItems: 'flex-start',
        paddingBottom: p.theme.sizes.two,
        color: p.theme.colors.grayRandom,
        marginBottom: p.theme.sizes.four,
      })}
    >
      <UmbrellaIcon height={16} width={16} style={{ marginTop: '0.1rem' }} />
      <Label
        type={LABEL_TYPE.BODY1}
        s={p => ({
          paddingLeft: p.theme.sizes.two,
          color: p.theme.colors.gray2,
        })}
      >
        {`${searchQuery.insurance.label} accepted at ${selectedLocation.address}`}
      </Label>
    </Flex>
  )
}

ContainsInsuranceIndicator.propTypes = {
  searchQuery: PropTypes.shape({
    insurance: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  }),
  selectedLocation: PropTypes.shape({
    address: PropTypes.string,
    insurances: PropTypes.array,
  }),
}
