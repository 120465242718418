import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'design-system/flex'
import styled from 'styled-components'
import dayjs from 'utils/dayjs'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { Modal } from 'design-system/modal'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Input } from 'design-system/input'
import { TextArea } from 'design-system/input/text-area'
import { Spinner } from 'design-system/spinner'
import { useToggle } from 'hooks/useToggle'
import { AuthStateContext } from 'context/auth-state-context'
import { DeleteModal } from 'components/delete-modal/delete-modal'
import { useNoteMutations } from './useNoteMutations'

const FooterContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 0 ${p => p.theme.sizes.four};
`

const InputContainer = styled(Flex)`
  flex-direction: column;
  flex: 1;
  margin-top: ${p => p.theme.sizes.four};
`
const FormInput = styled(Input)`
  margin-top: ${p => p.theme.sizes.two};
  border-radius: 10px;
`

const FormTextarea = styled(TextArea)`
  margin-top: ${p => p.theme.sizes.two};
  border-radius: 10px;
  height: 150px;
  resize: vertical;
`

const initialState = {
  title: '',
  body: '',
}
export const NotesModal = ({
  addNew,
  note = { ...initialState },
  notes = [],
  handleClose,
  provider,
  facility,
}) => {
  const [authState] = useContext(AuthStateContext)
  const [editState, setEditState] = useState(addNew ? initialState : note)
  const { title, body } = editState
  const [showDelete, toggleDelete] = useToggle(false)

  const {
    createNote,
    creatingNote,
    updateProviderInfo,
    updateFacilityInfo,
    updatingProviderInfo,
    updatingFacilityInfo,
  } = useNoteMutations({
    notes,
    provider,
    facility,
    handleUpdateSuccess: handleClose,
    addNew,
  })

  const onChangeText = e => {
    // console.log('onChangeText', e)
    const { value, name } = e.target
    setEditState(prev => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }

  const onClickPublish = async () => {
    if (addNew) {
      console.log(`🚀 => Add New Note => noteToPublish`, editState)
      // Add new Notes API call

      createNote({
        user_name: authState.name,
        fca_user_id: authState.fcaUserId,
        title: editState.title,
        body: editState.body,
        created_at: dayjs().utc().toISOString(),
      })
      // updating provider info with result happens onSuccess of mutation
    } else {
      console.log(`🚀 => Update Note => noteToPublish`, editState)
      // Update Provider Info with updated Note
      const foundNoteIndex = notes.findIndex(n => n.uuid === note.uuid)
      if (foundNoteIndex > -1) {
        const updatedNote = {
          ...notes[foundNoteIndex],
          title: editState.title,
          body: editState.body,
          updated_at: dayjs().utc().toISOString(),
        }
        const notesCopy = notes.slice()
        notesCopy.splice(foundNoteIndex, 1, updatedNote)
        if (provider) {
          updateProviderInfo({
            options: { notes: notesCopy },
          })
        }
        if (facility) {
          updateFacilityInfo({
            options: { notes: notesCopy },
          })
        }
      }
    }
  }

  const onClickDeleteNote = () => {
    console.log('Delete note: ', editState)
    // API call to delete note.
    const foundNoteIndex = notes.findIndex(n => n.uuid === note.uuid)
    if (foundNoteIndex > -1) {
      const notesCopy = notes.slice()
      notesCopy.splice(foundNoteIndex, 1)

      if (provider) {
        updateProviderInfo({
          options: { notes: notesCopy },
        })
      }
      if (facility) {
        updateFacilityInfo({
          options: { notes: notesCopy },
        })
      }
    }
  }

  let publishIsDisabled = true
  if (addNew) {
    if (title.trim().length > 0 && body.trim().length > 0) {
      publishIsDisabled = false
    }
  } else {
    if (
      (editState.title !== note.title && editState.title.trim() !== '') ||
      (editState.body !== note.body && editState.body.trim() !== '')
    ) {
      publishIsDisabled = false
    }
  }

  const showLoading =
    updatingProviderInfo || updatingFacilityInfo || creatingNote

  if (showDelete) {
    return (
      <DeleteModal
        title="Delete Note"
        fieldName="note"
        handleClose={() => handleClose()}
        handleCancel={toggleDelete}
        handleDelete={onClickDeleteNote}
        loading={updatingProviderInfo || updatingFacilityInfo}
      />
    )
  }

  return (
    <Modal
      onClose={() => handleClose()}
      title={addNew ? `Add a Note` : `Edit Note`}
      gridContainerStyle={{ maxWidth: '600px' }}
      footer={
        <FooterContainer>
          <Flex>
            {!addNew && !showLoading && (
              <Button
                s={p => ({
                  ['> button']: {
                    color: p.theme.colors.errorRed,
                    fontWeight: '500',
                  },
                })}
                type={BUTTON_TYPE.LINK}
                onClick={toggleDelete}
              >
                Delete Note
              </Button>
            )}
          </Flex>
          <Flex>
            <Button
              s={p => ({
                marginRight: p.theme.sizes.four,
              })}
              type={BUTTON_TYPE.LINK}
              onClick={() => handleClose()}
              disabled={showLoading}
            >
              Cancel
            </Button>
            <Button
              onClick={onClickPublish}
              type={
                publishIsDisabled || showLoading
                  ? BUTTON_TYPE.DISABLED
                  : BUTTON_TYPE.PRIMARY
              }
              disabled={publishIsDisabled || showLoading}
            >
              {addNew ? `Publish` : `Publish Changes`}
            </Button>
          </Flex>
        </FooterContainer>
      }
    >
      {showLoading ? (
        <Flex
          s={() => ({
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <Spinner label={addNew ? `Adding note..` : `Updating note..`} />
        </Flex>
      ) : (
        <Flex
          s={p => ({
            padding: p.theme.sizes.five,
            flexDirection: 'column',
          })}
        >
          <InputContainer>
            <Label type={LABEL_TYPE.BODY1_BOLD}>Title</Label>
            <FormInput
              onInputChange={onChangeText}
              name="title"
              value={[{ label: title }]}
              showClearIcon={false}
              placeholder="Enter Title"
            />
          </InputContainer>
          <InputContainer>
            <Label type={LABEL_TYPE.BODY1_BOLD}>Note</Label>
            <FormTextarea
              onChange={onChangeText}
              name="body"
              value={body}
              showClearIcon={false}
              placeholder="Enter Note"
              type="default"
            />
          </InputContainer>
        </Flex>
      )}
    </Modal>
  )
}

NotesModal.propTypes = {
  addNew: PropTypes.bool,
  handleClose: PropTypes.func,
  note: PropTypes.object,
  provider: PropTypes.object,
  facility: PropTypes.object,
  notes: PropTypes.array,
}
