import React, { useContext } from 'react'
import { Modal } from 'design-system/modal'
import { Block } from 'design-system/block'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label, LABEL_TYPE } from 'design-system/label'
import styled from 'styled-components'
import { Spinner } from 'design-system/spinner/spinner.component'
import { services } from 'services'
import { NotyfContext } from 'context/notyf-context'
import { AuthStateContext } from 'context/auth-state-context'
import { useMutation, useQueryClient } from 'react-query'

export const DeactivateInstanceModal = ({
  handleClose = () => {},
  handleSuccess = () => {},
  instance = {},
}) => {
  const queryClient = useQueryClient()
  const [authState] = useContext(AuthStateContext)
  const notyf = useContext(NotyfContext)
  const { mutate: manageInstance, isLoading } = useMutation(
    ({ token, uuid, options }) =>
      services.ribbon.manageInstance(token, uuid, options),
    {
      onSuccess: res => {
        console.log(`🚀 => manageInstance => res`, res)
        queryClient.refetchQueries('applications')
        handleSuccess()
      },
      onError: error => {
        console.log(`🚀 => manageInstance => error`, error)
        notyf.error(error.message)
      },
    },
  )

  const onClickDeactivateInstance = () => {
    console.log(`🚀 => onClickDeactivateInstance => instance`, instance)
    manageInstance({
      token: authState.token,
      uuid: instance.uuid,
      options: {
        has_fca_instance: false,
      },
    })
  }

  return (
    <Modal
      onClose={handleClose}
      title={'Deactivate Instance'}
      gridContainerStyle={{ maxWidth: '600px' }}
      footer={
        <Block
          type="FLEX"
          s={p => ({
            marginRight: p.theme.sizes.six,
            justifyContent: 'flex-end',
          })}
        >
          <Button
            s={() => ({
              marginRight: '1rem',
            })}
            type={BUTTON_TYPE.LINK}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            onClick={onClickDeactivateInstance}
            type={BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.LARGE}
            disabled={isLoading}
            s={() => ({
              minWidth: '110px',
            })}
          >
            Deactivate Instance
          </Button>
        </Block>
      }
    >
      {isLoading ? (
        <Spinner label="Deactivating instance.." />
      ) : (
        <FormContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>
            Are you sure you want to deactivate this instance? This action
            cannot be undone.
          </Label>
        </FormContainer>
      )}
    </Modal>
  )
}

const FormContainer = styled(Block)`
  padding: ${p => p.theme.sizes.four};
`
