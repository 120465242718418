import DashboardIcon from 'assets/developer-dashboard/dashboard.svg'
import LogsIcon from 'assets/developer-dashboard/list.svg'
import SyncingIcon from 'assets/developer-dashboard/cloud.svg'
import TokensIcon from 'assets/developer-dashboard/chip.svg'
import ConfigIcon from 'assets/developer-dashboard/api.svg'
import DataIcon from 'assets/developer-dashboard/data-storage.svg'
import ToolsIcon from 'assets/developer-dashboard/developer-tools.svg'

export const SidebarRoutes = [
  {
    path: '/',
    name: 'Dashboard',
    icon: DashboardIcon,
  },
  {
    name: 'Developer Tools',
    icon: ToolsIcon,
    subRoutes: [
      {
        path: '/tokens',
        name: 'Tokens',
        icon: TokensIcon,
      },
      {
        path: '/logs',
        name: 'Logs',
        icon: LogsIcon,
      },
      {
        path: '/api-configuration',
        name: 'API Configuration',
        icon: ConfigIcon,
      },
    ],
  },
  {
    path: '/data',
    name: 'Data',
    icon: DataIcon,
  },

  {
    path: '/syncing',
    name: 'Syncing',
    icon: SyncingIcon,
  },
]
