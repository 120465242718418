import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'design-system/input'

export const ParameterValue = ({ index, param, onChangeParamValue }) => {
  return (
    <div>
      <Input
        placeholder="Enter value"
        s={p => ({
          backgroundColor: p.theme.colors.white,
          flex: 1,
          borderLeft: 0,
          ...(index > 0 && {
            borderTop: 'none',
          }),
        })}
        onChange={() => onChangeParamValue(index, { target: { value: '' } })}
        onInputChange={e => onChangeParamValue(index, e)}
        value={[{ label: param.value }]}
      />
    </div>
  )
}
ParameterValue.propTypes = {
  index: PropTypes.number,
  param: PropTypes.object,
  onChangeParamValue: PropTypes.func,
}
