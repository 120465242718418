import React from 'react'
import PropTypes from 'prop-types'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Block } from 'design-system/block'
import { List } from 'design-system/list'
import { Checkbox } from 'design-system/checkbox'
import { Flex } from 'design-system/flex'

export const MultiSelectFilter = ({
  label = 'Label',
  labelComponent,
  onChange = () => {},
  options = [],
  selectedOptions = [],
  renderOptionLabel,
}) => {
  return (
    <Block
      s={p => ({
        paddingLeft: 0,
        paddingBottom: p.theme.sizes.four,
      })}
    >
      {labelComponent ? (
        labelComponent
      ) : (
        <Label type={LABEL_TYPE.BODY1_BOLD}>{label}</Label>
      )}

      <List disableItemHoverStyle={true}>
        {options.map(option => {
          const checked = selectedOptions.includes(option)
          if (!option) {
            return null
          }
          return (
            <Flex
              s={p => ({
                cursor: 'pointer',
                alignItems: 'flex-start',
                marginTop: p.theme.sizes.two,
                [':hover']: {
                  fontWeight: '600',
                },
              })}
              key={option}
              onClick={() => onChange(option)}
            >
              <Checkbox
                s={() => ({ marginRight: '8px', marginTop: '4px' })}
                value={checked}
              />
              <Label
                s={p => ({
                  color: p.theme.colors.gray1,
                })}
              >
                {renderOptionLabel ? renderOptionLabel(option) : option}
              </Label>
            </Flex>
          )
        })}
      </List>
    </Block>
  )
}

MultiSelectFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  labelComponent: PropTypes.element,
  label: PropTypes.string,
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  renderOptionLabel: PropTypes.func,
}
