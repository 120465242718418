import { FIELD_TYPES_FLAT_LOWER } from 'utils/constants'
import { isInteger } from 'utils/validations'

const onlyAlphaNumericRegex = /^[a-z0-9_]+$/i

const isValidBooleanDisplayConfig = boolean_display_config => {
  if (
    boolean_display_config.True.display.length > 0 &&
    boolean_display_config.False.display.length > 0
  ) {
    return true
  }
}
const isValidObjectDisplayConfig = object_display_config => {
  if (
    object_display_config.display_name.length > 0 &&
    object_display_config.keys.every(option => {
      if (
        option.name.length > 0 &&
        option.type.length > 0 &&
        option.display_name.length > 0
      ) {
        return true
      }

      return false
    })
  ) {
    return true
  }
}

export const useActionBtnsValidation = state => {
  let configureDisplayBtnIsDisabled = true
  let createFieldBtnIsDisabled = true
  const {
    showConfigureDisplayButton,
    configureDisplay,
    display_name,
    field_type,
    parameter_name,
    boolean_display_config,
    object_display_config,
    list_type,
    default_value,
  } = state
  if (
    display_name.length > 0 &&
    field_type.length > 0 &&
    parameter_name.length > 0
  ) {
    if (onlyAlphaNumericRegex.test(parameter_name)) {
      if (showConfigureDisplayButton) {
        configureDisplayBtnIsDisabled = false

        if (configureDisplay) {
          if (field_type.toLowerCase() === FIELD_TYPES_FLAT_LOWER.boolean) {
            if (isValidBooleanDisplayConfig(boolean_display_config)) {
              createFieldBtnIsDisabled = false
            }
          }

          if (field_type.toLowerCase() === FIELD_TYPES_FLAT_LOWER.object) {
            if (isValidObjectDisplayConfig(object_display_config)) {
              createFieldBtnIsDisabled = false
            }
          }

          if (field_type.toLowerCase() === FIELD_TYPES_FLAT_LOWER.list) {
            if (list_type.toLowerCase() === FIELD_TYPES_FLAT_LOWER.boolean) {
              if (isValidBooleanDisplayConfig(boolean_display_config)) {
                createFieldBtnIsDisabled = false
              }
            }

            if (list_type.toLowerCase() === FIELD_TYPES_FLAT_LOWER.object) {
              if (isValidObjectDisplayConfig(object_display_config)) {
                createFieldBtnIsDisabled = false
              }
            }
          }
        }
      } else {
        if (field_type.toLowerCase() === FIELD_TYPES_FLAT_LOWER.list) {
          if (list_type.length > 0) {
            createFieldBtnIsDisabled = false
          }
        } else {
          createFieldBtnIsDisabled = false
          if (default_value) {
            if (field_type.toLowerCase() === FIELD_TYPES_FLAT_LOWER.integer) {
              if (!isInteger(default_value)) {
                createFieldBtnIsDisabled = true
              }
            }
            if (field_type.toLowerCase() === FIELD_TYPES_FLAT_LOWER.float) {
              if (Number.isNaN(default_value)) {
                createFieldBtnIsDisabled = true
              }
            }
          }
        }
      }
    }
  }
  return { configureDisplayBtnIsDisabled, createFieldBtnIsDisabled }
}
