import React from 'react'
import { Router } from '@reach/router'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { SEO } from 'components/seo/seo.component'
import PrivateRoute from 'components/private-route/private-route'
import { AuthenticatedRoutes, AuthRoutes } from 'config/route-config'
import { UnAuthedLayout } from 'components/layout/unauthed-layout'
import { DefaultLayout } from 'components/layout/default-layout'
import PageNotFound from 'components/page-not-found/page-not-found'
import { DevelopersLayout } from 'containers/developers/DevelopersLayout'

const isProd = process.env.NODE_ENV === 'production'

const IndexPage = () => {
  return (
    <>
      <SEO title="Ribbon Health" />
      {isProd && (
        <Helmet>
          <script
            type="text/javascript"
            src="//script.crazyegg.com/pages/scripts/0108/7645.js"
            async="async"
          ></script>
        </Helmet>
      )}
      <Router>
        {AuthRoutes.map(route => {
          const { path, component: RouteComponent } = route
          return (
            <UnAuthedLayout key={path} path={path}>
              <RouteComponent path="*" />
            </UnAuthedLayout>
          )
        })}

        <DevelopersLayout path="developers/*" />

        {AuthenticatedRoutes.map(route => {
          const { path, component: RouteComponent } = route
          return (
            <PrivateRoute
              key={path}
              path="/*"
              name="Default"
              component={DefaultLayout}
              routeComponent={RouteComponent}
            />
          )
        })}
        <PageNotFound default />
      </Router>
    </>
  )
}

IndexPage.propTypes = {
  className: PropTypes.string,
}

export default IndexPage
