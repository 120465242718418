import React from 'react'
import { Modal } from 'design-system/modal'
import { Block } from 'design-system/block'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label, LABEL_TYPE } from 'design-system/label'
import styled from 'styled-components'

export const UnsavedChangesModal = ({
  handleClose = () => {},
  handleContinue = () => {},
}) => {
  return (
    <Modal
      onClose={handleClose}
      title="Unsaved Changes"
      gridContainerStyle={{ maxWidth: '600px' }}
      footer={
        <Block
          type="FLEX"
          s={p => ({
            marginRight: p.theme.sizes.six,
            justifyContent: 'flex-end',
          })}
        >
          <Button
            s={() => ({
              marginRight: '1rem',
            })}
            type={BUTTON_TYPE.LINK}
            onClick={handleClose}
          >
            Stay on this page
          </Button>
          <Button
            onClick={() => handleContinue()}
            type={BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.LARGE}
            s={() => ({
              minWidth: '110px',
            })}
          >
            Leave this page
          </Button>
        </Block>
      }
    >
      <FormContainer>
        <Label type={LABEL_TYPE.BODY1_BOLD}>
          You have unsaved changes. Leaving this page will discard any changes
          that you’ve made
        </Label>
      </FormContainer>
    </Modal>
  )
}

const FormContainer = styled(Block)`
  padding: ${p => p.theme.sizes.four};
`
