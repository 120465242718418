import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import React from 'react'
import styled from 'styled-components'
import { DataCard } from '../components/DataCard'
import dayjs from 'utils/dayjs'

const CardContainer = styled(Flex)`
  flex-direction: column;
`
const CardRow = styled(Flex)`
  padding: ${p => p.theme.sizes.two} 0;
`

const CardLabel = styled(Label)`
  min-width: 180px;
  margin-right: ${p => p.theme.sizes.six};
`

export const SummaryCard = ({
  type = 'Type',
  onClickViewDetails = () => {},
}) => {
  return (
    <CardContainer>
      <Label
        s={p => ({
          fontWeight: '500',
          fontSize: '1.125em',
          marginBottom: p.theme.sizes.four,
        })}
      >
        {type}
      </Label>
      <DataCard>
        <CardRow>
          <CardLabel>Last Sync</CardLabel>
          <Label>{dayjs().format('LLLL')}</Label>
        </CardRow>
        <CardRow>
          <CardLabel>Records Updated</CardLabel>
          <Label>1,234 out of 2,500</Label>
        </CardRow>
        <CardRow>
          <CardLabel>% Change</CardLabel>
          <Label>67% records updated</Label>
        </CardRow>
        <CardRow>
          <Label
            s={p => ({
              color: p.theme.colors.aubergine3,
              fontWeight: '600',
            })}
            onClick={() => onClickViewDetails(type)}
          >
            View Details
          </Label>
        </CardRow>
      </DataCard>
    </CardContainer>
  )
}
