import React from 'react'
import PropTypes from 'prop-types'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Block } from 'design-system/block'
import { isPartnerLocation } from 'utils/location'
import { Divider } from 'containers/provider-detail/provider-detail.container'
import { PartnerBookingLink } from 'components/scheduling-panel/booking-links'

export const getPartnerBookingLink = (provider, partnerId) => {
  const partner = (provider.partnerData ?? []).find(
    p => p.partnerId === partnerId,
  )
  const bookingLink = (partner?.onlineProfiles ?? []).find(
    link => link.type === 'booking',
  )
  return bookingLink?.url
}

export const SchedulingPanel = ({ provider, selectedLocation }) => {
  let bookingLink
  const isPartnerDataLocation = isPartnerLocation(selectedLocation)

  // If selected location is a partner-provided location, get that partner's booking link, if any
  if (isPartnerDataLocation) {
    bookingLink = getPartnerBookingLink(provider, selectedLocation.partnerId)
  }

  return bookingLink ? (
    <>
      <Divider />
      <Heading
        type={HEADING_TYPE.H2}
        s={p => ({
          color: p.theme.colors.aubergine2,
          marginBottom: p.theme.sizes.four,
        })}
      >
        Appointment Booking
      </Heading>
      <Block
        type={'CARD'}
        s={p => ({
          paddingLeft: p.theme.sizes.four,
          paddingRight: p.theme.sizes.four,
          paddingTop: p.theme.sizes.five,
          paddingBottom: p.theme.sizes.five,
          lineHeight: p.theme.sizes.five,
          maxHeight: '32em',
          overflowY: 'auto',
        })}
      >
        {isPartnerDataLocation && (
          <PartnerBookingLink
            partnerId={selectedLocation.partnerId}
            partnerName={selectedLocation.partnerName}
            url={bookingLink}
          />
        )}
      </Block>
    </>
  ) : null
}

SchedulingPanel.propTypes = {
  provider: PropTypes.object,
  selectedLocation: PropTypes.object,
}
