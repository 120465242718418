import React, { useContext, useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMutation, useQueryClient } from 'react-query'
import { Block } from 'design-system/block'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label } from 'design-system/label'
import { FieldsSectionList } from './fields-section-list'
import { useToggle } from 'hooks/useToggle'
import { AddNewCustomFieldModal } from './add-new/add-new-field-modal'
import { NotyfContext } from 'context/notyf-context'
import { useHasChanges } from '../useHasChanges'
import { AnimatedStickyHeader } from 'containers/admin/components/animated-sticky-header'
import { AddNewFilterModal } from '../filter-config/add-new-filter-modal'
import { Flex } from 'design-system/flex'
import { EditCustomFieldModal } from './edit-custom-field/edit-custom-field-modal'
import { AuthStateContext } from 'context/auth-state-context'
import { services } from 'services'
import { colors } from 'theme/colors'

const editFieldsReducer = (state, action) => {
  console.log('state :>> ', state)
  console.log('action :>> ', action)
  const { type, payload } = action
  switch (type) {
    case 'toggle': {
      const { configKey, item } = payload
      // Copy as JSON parse/stringify as object will only be JSON appropriate key/values.
      const fieldsCopy = JSON.parse(JSON.stringify(state[configKey]))

      const foundFieldIndex = fieldsCopy.findIndex(i => i.uuid === item.uuid)
      if (foundFieldIndex > -1) {
        const foundField = fieldsCopy[foundFieldIndex]
        foundField.enabled = !foundField.enabled
      }

      const newState = {
        ...state,
        [configKey]: fieldsCopy,
      }
      console.log(`🚀 => editFieldsReducer:toggle => newState`, newState)
      return newState
    }
    case 'reset':
      return {
        ...payload,
      }
    default:
      break
  }
}

const initialEmptyState = {
  providers: [],
  facilities: [],
}
export const ManageInstanceCustomFields = ({ instance }) => {
  const queryClient = useQueryClient()
  const [authState] = useContext(AuthStateContext)
  const [editMode, setEditMode] = useState(false)
  const { fca_config } = instance || {}
  const { fca_custom_fields: state = initialEmptyState } = fca_config || {}

  const [editState, dispatch] = useReducer(editFieldsReducer, state)

  const [showAddNewField, setShowAddNewField] = useToggle(false)
  const [editCustomField, setEditCustomField] = useState()
  const [showCreateFilter, setShowCreateFilter] = useState(null)
  const notyf = useContext(NotyfContext)
  const [showStickyHeader, setShowStickyHeader] = useState(false)
  useHasChanges(state, editState)

  const { mutateAsync: manageInstance, isLoading: isSaving } = useMutation(
    ({ uuid, options }) =>
      services.ribbon.manageInstance(authState.token, uuid, options),
    {
      onSuccess: async res => {
        console.log(`🚀 => manageInstance => res`, res)
        await queryClient.refetchQueries('applications')
        notyf.success('Custom fields updated!')

        // State & editState should update from new application instance change

        if (editMode) {
          setEditMode(false)
        }
        if (editCustomField) {
          setEditCustomField(null)
        }
      },
      onError: error => {
        console.log(
          `🚀 => manageInstance:updating Custom Fields => error`,
          error,
        )
        notyf.error(error.message)
      },
    },
  )

  useEffect(() => {
    // handle instance changes here
    console.log(`instance changes`, instance)
    const { fca_config } = instance
    const { fca_custom_fields } = fca_config || {}
    if (fca_custom_fields) {
      dispatch({
        type: 'reset',
        payload: fca_custom_fields,
      })
    }

    // Update location state instance with instance from response
    // Fixes reloading the page and old instance loading from state
    if (typeof window !== 'undefined' && window.history) {
      window.history.replaceState({ instance }, '')
    }
  }, [instance])

  const onClickEditConfig = () => {
    setEditMode(prev => !prev)
  }

  const onClickSaveConfig = async () => {
    // Save Custom Fields
    manageInstance({
      uuid: instance.uuid,
      options: {
        fca_custom_fields: editState,
      },
    })
  }

  const onClickDiscardChanges = () => {
    // Discard Changes
    dispatch({
      type: 'reset',
      payload: state,
    })
    setEditMode(prev => !prev)
  }

  const onChangeEnabled = (configKey, item) => {
    console.log('onChangeEnabled :>> ', configKey, item)
    dispatch({
      type: 'toggle',
      payload: {
        configKey,
        item,
      },
    })
  }

  const onClickEditField = (configKey, item) => {
    console.log(`onClickEditField`, configKey, item)
    setEditCustomField(item)
  }

  const handleContainerScroll = e => {
    const { scrollTop = 0 } = e.target
    if (scrollTop >= 48) {
      if (!showStickyHeader) {
        setShowStickyHeader(true)
      }
    } else {
      if (showStickyHeader) {
        setShowStickyHeader(false)
      }
    }
  }

  const handleCreateFieldSuccess = (createdField, createFilter) => {
    queryClient.refetchQueries('applications')
    setShowAddNewField(false)
    // state & editState should update from useEffect
    if (createFilter) {
      console.log(
        `🚀 => handleCreateFieldSuccess => createdField`,
        createdField,
      )
      // Open Create filter modal with newly created field options prepopulated
      setShowCreateFilter(createdField)
    }
  }

  const handleSaveCustomFieldSuccess = () => {
    // Fields update from refetch of appications
    queryClient.refetchQueries('applications')
    setEditCustomField()
  }

  const handleCreateFilterSuccess = () => {
    queryClient.refetchQueries('applications')
    setShowCreateFilter(null)
  }

  return (
    <Flex
      onScroll={handleContainerScroll}
      s={p => ({
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        maxWidth: `${p.theme.flexboxgrid.container.lg}em`,
        height: 'max-content',
        overflow: 'auto',
        paddingBottom: p.theme.sizes.twelve,
        position: 'relative',
      })}
    >
      {editMode && showStickyHeader && (
        <AnimatedStickyHeader
          secondaryButton={{
            action: onClickDiscardChanges,
            label: 'Discard Changes',
          }}
          primaryButton={{
            action: onClickSaveConfig,
            label: 'Save Fields',
            loading: isSaving,
          }}
        />
      )}

      <Flex
        s={p => ({
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: `${p.theme.sizes.six} ${p.theme.sizes.six} 0`,
          marginBottom: p.theme.sizes.five,
        })}
      >
        <Label
          s={p => ({
            color: p.theme.colors.gray0,
            fontSize: p.theme.sizes.six,
            fontWeight: '700',
          })}
        >
          Custom Fields
        </Label>
        <Flex>
          {editMode ? (
            <SecondaryButton onClick={onClickDiscardChanges}>
              Discard Changes
            </SecondaryButton>
          ) : (
            <SecondaryButton onClick={() => setShowAddNewField(true)}>
              Add a new field
            </SecondaryButton>
          )}
          {editMode ? (
            <PrimaryButton
              loading={isSaving}
              outerLoadingColor={colors.aubergine}
              onClick={onClickSaveConfig}
            >
              Save Fields
            </PrimaryButton>
          ) : (
            <PrimaryButton onClick={onClickEditConfig}>
              Edit Fields
            </PrimaryButton>
          )}
        </Flex>
      </Flex>
      <Block
        s={p => ({
          padding: `0 ${p.theme.sizes.six}`,
        })}
      >
        <FieldsSectionList
          sectionTitle="Provider Fields"
          list={editMode ? editState.providers : state.providers}
          configKey="providers"
          onClickEditField={onClickEditField}
          editMode={editMode}
          onChangeEnabled={onChangeEnabled}
          dispatch={dispatch}
          onClickAddNew={() => setShowAddNewField(true)}
        />

        <Border />
        {/* Facilities Section */}

        <FieldsSectionList
          sectionTitle="Facilities Fields"
          list={editMode ? editState.facilities : state.facilities}
          configKey="facilities"
          onClickEditField={onClickEditField}
          editMode={editMode}
          onChangeEnabled={onChangeEnabled}
          dispatch={dispatch}
          onClickAddNew={() => setShowAddNewField(true)}
        />
      </Block>
      {showAddNewField && (
        <AddNewCustomFieldModal
          instance={instance}
          handleClose={() => setShowAddNewField(false)}
          handleSuccess={handleCreateFieldSuccess}
        />
      )}
      {editCustomField && (
        <EditCustomFieldModal
          instance={instance}
          fcaCustomFields={state}
          field={editCustomField}
          handleClose={() => setEditCustomField(null)}
          handleSuccess={handleSaveCustomFieldSuccess}
        />
      )}
      {showCreateFilter && (
        <AddNewFilterModal
          instance={instance}
          customField={showCreateFilter}
          handleClose={() => setShowCreateFilter(null)}
          handleSuccess={handleCreateFilterSuccess}
        />
      )}
    </Flex>
  )
}

const Border = styled.div`
  height: 1px;
  min-height: 1px;
  width: 100%;
  background-color: ${p => p.theme.colors.gray4};
  margin: ${p => p.theme.sizes.six} 0;
`

const PrimaryButton = styled(Button)`
  min-width: 160px;
`
PrimaryButton.defaultProps = {
  type: BUTTON_TYPE.DEFAULT,
  size: BUTTON_SIZE.LARGE,
}

const SecondaryButton = styled(Button)`
  margin-right: ${p => p.theme.sizes.two};
  :hover {
    background-color: ${p => p.theme.colors.gray6};
  }
`
SecondaryButton.defaultProps = {
  type: BUTTON_TYPE.LINK,
  size: BUTTON_SIZE.LARGE,
}

ManageInstanceCustomFields.propTypes = {
  instance: PropTypes.object,
}
