import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'design-system/label'
import { Flex } from 'design-system/flex'
import { Button } from 'design-system/button'
import { Input, INPUT_TYPE } from 'design-system/input'
import { SelectInput } from 'design-system/input/select-input'
import { FIELD_TYPES_FLAT_LOWER } from 'utils/constants'
import { DateSelect } from 'design-system/input/date-select'
import dayjs from 'utils/dayjs'
import MinusSVG from 'assets/minus.svg'
import { isInteger } from 'utils/validations'

export const InputByType = ({
  fieldType,
  value,
  name,
  defaultValue,
  onChangeField,
  onKeyDown,
  disabled = false,
}) => {
  let error = null
  if (fieldType === FIELD_TYPES_FLAT_LOWER.integer) {
    // empty string check on value
    if (value && !isInteger(value)) {
      error = 'Please enter a valid integer'
    }
  }
  if (fieldType === FIELD_TYPES_FLAT_LOWER.float) {
    // empty string check on value
    if (value && isNaN(value)) {
      error = 'Please enter a valid float value'
    }
  }

  switch (fieldType) {
    case FIELD_TYPES_FLAT_LOWER.string:
    case FIELD_TYPES_FLAT_LOWER.integer:
    case FIELD_TYPES_FLAT_LOWER.float:
      return (
        <>
          <Flex
            s={() => ({
              flex: 1,
              alignItems: 'center',
            })}
          >
            <Input
              s={p => ({
                marginTop: p.theme.sizes.two,
                minWidth: '240px',
                ...(error && {
                  borderColor: p.theme.colors.errorRed,
                }),
              })}
              onInputChange={e => {
                return onChangeField(e)
              }}
              name={name || 'value'}
              inputType={
                fieldType === FIELD_TYPES_FLAT_LOWER.integer ||
                fieldType === FIELD_TYPES_FLAT_LOWER.float
                  ? 'number'
                  : 'text'
              }
              step={fieldType === FIELD_TYPES_FLAT_LOWER.float ? '0.1' : '1'}
              value={[
                {
                  label: value,
                },
              ]}
              showClearIcon={false}
              type={INPUT_TYPE.ROUND}
              placeholder={defaultValue ? `${defaultValue}` : 'Enter value'}
              onKeyDown={onKeyDown}
              disabled={disabled}
            />
            {!value && defaultValue && (
              <Label
                s={p => ({
                  marginLeft: p.theme.sizes.four,
                  color: p.theme.colors.gray1,
                  fontWeight: '400',
                })}
              >
                Press tab to fill
              </Label>
            )}
          </Flex>
          {error && (
            <Label
              s={p => ({
                marginTop: p.theme.sizes.two,
                marginLeft: p.theme.sizes.four,
                color: p.theme.colors.errorRed,
                fontWeight: '400',
              })}
            >
              {error}
            </Label>
          )}
        </>
      )
    case FIELD_TYPES_FLAT_LOWER.boolean:
      return (
        <SelectInput
          onChange={e => onChangeField(e)}
          name={name || 'value'}
          value={
            value !== undefined && value !== ''
              ? value === true
                ? 'True'
                : 'False'
              : ''
          }
          options={[
            { label: 'True', value: true },
            { label: 'False', value: false },
          ]}
          disabled={disabled}
          containerStyle={{
            marginTop: '0.5em',
            minWidth: '240px',
            maxWidth: '240px',
          }}
          placeholder="Select value"
        />
      )
    case FIELD_TYPES_FLAT_LOWER.date:
      return (
        <Flex
          s={p => ({
            marginTop: p.theme.sizes.two,
            alignItems: 'center',
          })}
        >
          <Flex
            s={() => ({
              maxWidth: '240px',
              minWidth: '240px',
            })}
          >
            <DateSelect
              value={value}
              onChange={date =>
                onChangeField({
                  target: {
                    name: name || 'value',
                    // formatted and stored in YYYY-MM-DD format
                    value: dayjs(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabled={disabled}
            />
          </Flex>
          {!disabled ? (
            value ? (
              <Button
                onClick={() =>
                  onChangeField({
                    target: {
                      name: name || 'value',
                      value: null,
                    },
                  })
                }
                s={p => ({
                  marginLeft: p.theme.sizes.four,
                  ['> button']: {
                    padding: 0,
                    height: '36px',
                    width: '36px',
                  },
                  borderRadius: '50%',
                })}
              >
                <MinusSVG height={16} width={16} />
              </Button>
            ) : (
              <Button
                s={p => ({
                  marginLeft: p.theme.sizes.four,
                  ['> button']: {
                    height: 'auto',
                    lineHeight: 'normal',
                  },
                })}
                onClick={() =>
                  onChangeField({
                    target: {
                      name: name || 'value',
                      // formatted and stored in YYYY-MM-DD format
                      value: dayjs().format('YYYY-MM-DD'),
                    },
                  })
                }
              >
                Select Today
              </Button>
            )
          ) : (
            <></>
          )}
        </Flex>
      )
    default:
      return <div></div>
  }
}

InputByType.propTypes = {
  fieldType: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  defaultValue: PropTypes.any,
  onChangeField: PropTypes.func,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
}
