import React, { useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { useScrollContext } from 'context/scroll-context'
import RibbonLogoImage from 'images/ribbon-logo.png'
import { AccountIcon } from 'components/account-dropdown/account-dropdown.component'
import { AuthStateContext } from 'context/auth-state-context'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Chevron } from 'design-system/icon/icons/chervon-left'
import { Flex } from 'design-system/flex'
import { useToggle } from 'hooks/useToggle'
import CaretDown from 'assets/caret-down.svg'
import { FindCareDropdown } from './find-care-dropdown'
import { useOnClickOutside } from 'hooks/useOutsideClick'

const DisabledMessage = 'Disabled by your administrator'

const Caret = styled(CaretDown)`
  margin-left: 4px;
  ${props => props.direction === 'up' && `transform: rotate(180deg)`};
`

const RibbonLogo = styled.img`
  height: ${props => props.theme.sizes.five};
  padding: 0 ${props => props.theme.sizes.five};
`

const HeaderComponent = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  height: ${p => p.theme.sizes.twelve};
  z-index: ${p => p.theme.zIndexes.routeNav};
  ${p => ({
    ...(p.isAuth
      ? {
          borderBottom: 0,
        }
      : {
          borderBottom: `1px solid  ${p.theme.colors.gray4}`,
        }),
  })}
`

const HeaderBackLink = styled(Link)`
  display: flex;
  padding: ${p => p.theme.sizes.two} ${p => p.theme.sizes.four};
  margin-left: ${p => p.theme.sizes.five};
  border-radius: ${p => p.theme.sizes.twelve};
  align-items: center;
  text-decoration: none;
  :hover {
    background-color: ${p => p.theme.colors.gray6};
  }
  & > svg {
    margin-right: ${p => p.theme.sizes.two};
  }
  & > p {
    font-weight: 500;
    color: ${p => p.theme.colors.aubergine2};
  }
`

const AccountIconContainer = styled(Flex)`
  flex: 1;
  padding-right: ${p => p.theme.sizes.five};
  justify-content: flex-end;
`

const HeaderNavButtonsContainer = styled(Flex)`
  align-items: center;
  flex: 1;
  min-height: 45px;
  > a {
    text-decoration: none;
    align-self: flex-end;
  }
`

const HeaderNavLink = styled(Flex)`
  min-width: 125px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: ${p => p.theme.colors.gray2};
  margin: 0 12px;
  position: relative;
  line-height: 24px;
  & > div > p,
  & > p {
    :hover {
      ${p => ({
        ...(!p.isDisabled
          ? {
              color: p.theme.colors.gray0,
              fontWeight: 'bold',
            }
          : {
              cursor: 'not-allowed',
            }),
      })}
    }
  }
  ${p => ({
    ...(p.isActive && {
      color: p.theme.colors.gray0,
      fontWeight: 'bold',
    }),
  })}
  > #find-care-dropdown {
    width: 240px;
    left: 0;
    right: initial;
  }
`

export const Header = ({ pageType, setShowMenu }) => {
  const [authState] = useContext(AuthStateContext)
  const location = useLocation()
  const [showFindCareMenu, toggleFindCareMenu] = useToggle(false)
  const { pathname } = location
  const isAdminPath = pathname.includes('/admin/instances')
  const isCostEstimatesPath = pathname.includes('/cost-estimates')
  const isHomePath = pathname === '/'
  const isLoggedIn = authState.token
  const [scrollPos] = useScrollContext()
  const findCareRef = useRef()
  useOnClickOutside(findCareRef, () => {
    if (showFindCareMenu) {
      toggleFindCareMenu()
    }
  })

  const { modules } = authState

  const costEstimatesEnabled =
    modules && modules.cost_estimate && modules.cost_estimate.enabled

  return (
    <HeaderComponent isAuth={pageType && pageType === 'auth'}>
      {isAdminPath ? (
        <HeaderBackLink to={'/admin'}>
          <Chevron direction="left" />
          <Label>Back to all instances</Label>
        </HeaderBackLink>
      ) : (
        <>
          {isLoggedIn ? (
            <Link to={'/'} style={{ maxHeight: '40px' }}>
              <RibbonLogo src={RibbonLogoImage} alt="Ribbon Health" />
            </Link>
          ) : (
            <OutboundLink
              style={{ textDecoration: 'none' }}
              href="https://www.ribbonhealth.com"
              target="_blank"
              rel="noreferrer"
            >
              <RibbonLogo src={RibbonLogoImage} alt="Ribbon Health" />
            </OutboundLink>
          )}
        </>
      )}
      {scrollPos < 125 && (isCostEstimatesPath || isHomePath) && (
        <HeaderNavButtonsContainer>
          <HeaderNavLink
            isActive={isHomePath || showFindCareMenu}
            ref={findCareRef}
          >
            <Flex onClick={() => toggleFindCareMenu()}>
              <Label>Find Care</Label>
              <Caret direction={showFindCareMenu ? 'up' : 'down'} />
            </Flex>
            {showFindCareMenu && (
              <FindCareDropdown
                toggleDropdown={toggleFindCareMenu}
                isHomePath={isHomePath}
              />
            )}
          </HeaderNavLink>

          <Link
            to={costEstimatesEnabled ? '/cost-estimates' : '/'}
            aria-disabled={!costEstimatesEnabled}
          >
            <HeaderNavLink
              isActive={isCostEstimatesPath}
              isDisabled={!costEstimatesEnabled}
              data-tip={!costEstimatesEnabled ? DisabledMessage : ''}
            >
              <Label>Estimate Cost</Label>
            </HeaderNavLink>
          </Link>
        </HeaderNavButtonsContainer>
      )}
      {isLoggedIn ? (
        <AccountIconContainer>
          <AccountIcon setShowMenu={setShowMenu} />
        </AccountIconContainer>
      ) : (
        <OutboundLink
          style={{ textDecoration: 'none' }}
          href="https://www.ribbonhealth.com"
          target="_blank"
          rel="noreferrer"
        >
          <Label
            type={LABEL_TYPE.BODY1_BOLD}
            s={p => ({
              color: p.theme.colors.aubergine2,
              padding: `0 ${p.theme.sizes.five}`,
              [':hover']: {
                color: p.theme.colors.aubergine4,
              },
            })}
          >
            Learn more about Ribbon Health
          </Label>
        </OutboundLink>
      )}
    </HeaderComponent>
  )
}

Header.propTypes = {
  pageType: PropTypes.string,
  setShowMenu: PropTypes.func,
}
