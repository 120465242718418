import React, { useContext, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { Block } from 'design-system/block'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label } from 'design-system/label'
import ReactSwitch from 'react-switch'
import { colors } from 'theme/colors'
import styled from 'styled-components'
import { NotyfContext } from 'context/notyf-context'
import { useHasChanges } from '../useHasChanges'
import { AnimatedStickyHeader } from 'containers/admin/components/animated-sticky-header'
import { Flex } from 'design-system/flex'
import { useMutation, useQueryClient } from 'react-query'
import { services } from 'services'
import { AuthStateContext } from 'context/auth-state-context'

const editModulesReducer = (state, action) => {
  const { type, payload } = action
  console.log('state:action :>> ', state, action)
  switch (type) {
    case 'toggle':
      return {
        ...state,
        ...payload,
      }
    case 'reset':
      return {
        ...payload,
      }
    default:
      break
  }
}

export const ManageInstanceModules = ({ instance }) => {
  const queryClient = useQueryClient()
  const [authState] = useContext(AuthStateContext)
  const [editMode, setEditMode] = useState(false)
  const [state, setState] = useState(
    instance && instance.fca_config ? instance.fca_config.modules : null,
  )
  const [editState, dispatch] = useReducer(editModulesReducer, state)
  const notyf = useContext(NotyfContext)
  const [showStickyHeader, setShowStickyHeader] = useState(false)
  useHasChanges(state, editState)

  const { mutate: manageInstance, isLoading: isSaving } = useMutation(
    ({ uuid, options }) =>
      services.ribbon.manageInstance(authState.token, uuid, options),
    {
      onSuccess: res => {
        console.log(`🚀 => manageInstance => res`, res)
        setState(editState)
        setEditMode(prev => !prev)
        queryClient.invalidateQueries('applications')
        notyf.success('Modules updated!')
        // Update location state instance with instance from response
        // Fixes reloading the page and old instance loading from state
        if (typeof window !== 'undefined' && window.history) {
          window.history.replaceState({ instance: res.data }, '')
        }
      },
      onError: error => {
        console.log(`🚀 => manageInstance => error`, error)
        notyf.error(error.message)
      },
    },
  )

  const onClickEditModules = () => {
    setEditMode(prev => !prev)
  }

  const onClickSaveModules = () => {
    const options = {
      fca_modules: editState,
    }
    console.log('onClickSaveModules:', options)
    manageInstance({
      uuid: instance.uuid,
      options,
    })
  }

  const onClickDiscard = () => {
    console.log('onClickActionBtn')
    setEditMode(prev => !prev)
    dispatch({ type: 'reset', payload: state })
  }

  const onChangeMainModuleType = moduleType => {
    // fields will always be valid json -> json stringify valid copy method
    const updatedModuleType = JSON.parse(JSON.stringify(editState[moduleType]))
    updatedModuleType.enabled = !editState[moduleType].enabled

    dispatch({
      type: 'toggle',
      payload: {
        [moduleType]: updatedModuleType,
      },
    })
  }

  const onChangeSubModulesType = (moduleType, moduleKey) => {
    // fields will always be valid json -> json stringify valid copy method
    const updatedModuleType = JSON.parse(JSON.stringify(editState[moduleType]))
    if (
      updatedModuleType.modules[moduleKey] &&
      editState[moduleType].modules[moduleKey]
    ) {
      updatedModuleType.modules[moduleKey].enabled = !editState[moduleType]
        .modules[moduleKey].enabled
      dispatch({ type: 'toggle', payload: { [moduleType]: updatedModuleType } })
    } else {
      console.log('Module not found in config')
    }
  }

  const handleContainerScroll = e => {
    const { scrollTop = 0 } = e.target
    if (scrollTop >= 48) {
      if (!showStickyHeader) {
        setShowStickyHeader(true)
      }
    } else {
      if (showStickyHeader) {
        setShowStickyHeader(false)
      }
    }
  }

  if (!instance || !editState) {
    return null
  }

  const showRequiredError =
    !editState.providers.enabled && !editState.facilities.enabled

  return (
    <Flex
      onScroll={handleContainerScroll}
      s={p => ({
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        maxWidth: `${p.theme.flexboxgrid.container.lg}em`,
        height: 'max-content',
        overflow: 'auto',
        position: 'relative',
        paddingBottom: p.theme.sizes.six,
      })}
    >
      {editMode && showStickyHeader && (
        <AnimatedStickyHeader
          secondaryButton={{
            action: onClickDiscard,
            label: 'Discard Changes',
          }}
          primaryButton={{
            action: onClickSaveModules,
            label: 'Save Modules',
            loading: isSaving,
          }}
        />
      )}
      <Flex
        s={p => ({
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: `${p.theme.sizes.six} ${p.theme.sizes.six} 0`,
        })}
      >
        <Label
          s={p => ({
            color: p.theme.colors.gray0,
            fontSize: p.theme.sizes.six,
            fontWeight: '700',
          })}
        >
          Modules
        </Label>
        <Block
          type="FLEX"
          s={() => ({
            padding: 0,
          })}
        >
          {editMode && (
            <SecondaryButton onClick={onClickDiscard} disabled={isSaving}>
              Discard Changes
            </SecondaryButton>
          )}
          {editMode ? (
            <PrimaryButton
              onClick={onClickSaveModules}
              disabled={showRequiredError}
              loading={isSaving}
              outerLoadingColor={colors.aubergine}
            >
              Save Changes
            </PrimaryButton>
          ) : (
            <PrimaryButton onClick={onClickEditModules}>
              Edit Modules
            </PrimaryButton>
          )}
        </Block>
      </Flex>
      <Block
        s={p => ({
          padding: `0 ${p.theme.sizes.six}`,
          marginTop: p.theme.sizes.six,
        })}
      >
        {showRequiredError && (
          <Label
            s={p => ({
              color: colors.errorRed,
              fontWeight: '500',
              marginBottom: p.theme.sizes.four,
            })}
          >
            You must enable at least one of Providers or Facilities
          </Label>
        )}

        <Block
          type="CARD"
          s={() => ({
            padding: 0,
          })}
        >
          <MainModuleRow
            label="Providers"
            moduleType="providers"
            editState={editState}
            state={state}
            editMode={editMode}
            onChange={onChangeMainModuleType}
          />
          <MainModuleRow
            label="Facilities"
            moduleType="facilities"
            editState={editState}
            state={state}
            editMode={editMode}
            onChange={onChangeMainModuleType}
          />
          {state.cost_estimate && (
            <MainModuleRow
              label="Cost Estimate"
              moduleType="cost_estimate"
              editState={editState}
              state={state}
              editMode={editMode}
              onChange={onChangeMainModuleType}
            />
          )}
        </Block>
      </Block>
      <Block
        s={p => ({
          padding: `0 ${p.theme.sizes.six}`,
        })}
      >
        <Block
          type="CARD"
          s={p => ({
            padding: 0,
            marginTop: p.theme.sizes.eight,
          })}
        >
          <Flex
            s={p => ({
              padding: p.theme.sizes.four,
              alignItems: 'center',
              justifyContent: 'space-between',
              boxShadow: `inset 0px -1px 0px #E2E8F0`,
            })}
          >
            <ModuleHeaderLabel
              s={() => ({
                flex: 1,
              })}
            >
              Module Name
            </ModuleHeaderLabel>
            <Flex
              s={() => ({
                flex: 0.3,
                justifyContent: 'space-around',
              })}
            >
              <ModuleHeaderLabel>Providers</ModuleHeaderLabel>
              <ModuleHeaderLabel>Facilities</ModuleHeaderLabel>
            </Flex>
          </Flex>
          <ModuleRow
            label="Networks"
            moduleKey="networks"
            editState={editState}
            state={state}
            editMode={editMode}
            onChange={onChangeSubModulesType}
          />
          <ModuleRow
            label="Procedures"
            moduleKey="procedures"
            editState={editState}
            state={state}
            editMode={editMode}
            onChange={onChangeSubModulesType}
          />
          <ModuleRow
            label="Focus Areas"
            moduleKey="focus_areas"
            editState={editState}
            state={state}
            editMode={editMode}
            onChange={onChangeSubModulesType}
          />
          <ModuleRow
            label="Care Journey"
            moduleKey="care_journey"
            editState={editState}
            state={state}
            editMode={editMode}
            onChange={onChangeSubModulesType}
          />
          <ModuleRow
            label="Custom Fields"
            editState={editState}
            state={state}
            editMode={editMode}
            moduleKey="custom_fields"
            onChange={onChangeSubModulesType}
          />
          <ModuleRow
            label="Preference"
            editState={editState}
            state={state}
            editMode={editMode}
            moduleKey="preference"
            onChange={onChangeSubModulesType}
          />
          <ModuleRow
            label="Notes"
            editState={editState}
            state={state}
            editMode={editMode}
            moduleKey="notes"
            onChange={onChangeSubModulesType}
          />
          <ModuleRow
            label="Insurance Edits"
            editState={editState}
            state={state}
            editMode={editMode}
            moduleKey="insurance_edits"
            onChange={onChangeSubModulesType}
          />
          <ModuleRow
            label="Virtual Search"
            editState={editState}
            state={state}
            editMode={editMode}
            moduleKey="virtual_search"
            onChange={onChangeSubModulesType}
          />
        </Block>
      </Block>
    </Flex>
  )
}

const ModuleHeaderLabel = styled(Label)`
  color: ${p => p.theme.colors.textLight};
  font-weight: 500;
`

const MainModuleRow = ({
  label = 'Label',
  moduleType = '',
  editState = {},
  state = {},
  editMode = false,
  onChange = () => {},
}) => {
  return (
    <Flex
      s={p => ({
        padding: p.theme.sizes.four,
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: `inset 0px -1px 0px #E2E8F0`,
      })}
    >
      <ModuleHeaderLabel
        s={() => ({
          flex: 1,
        })}
      >
        {label}
      </ModuleHeaderLabel>
      <Flex
        s={() => ({
          flex: 0.3,
          justifyContent: 'space-around',
        })}
      >
        <div />
        <ReactSwitch
          onChange={() => onChange(moduleType)}
          checked={
            editMode ? editState[moduleType].enabled : state[moduleType].enabled
          }
          onColor={colors.aubergine}
          checkedIcon={false}
          uncheckedIcon={false}
          height={16}
          width={32}
          disabled={!editMode}
        />
      </Flex>
    </Flex>
  )
}

// Modules are currently unavailable to Faciilites search
const unavailableFacilitiesModuleKeys = ['focus_areas', 'care_journey']

const ModuleRow = ({
  label = 'Label',
  moduleKey = '',
  editState = {},
  state = {},
  editMode = false,
  onChange = () => {},
}) => {
  const providerModuleChecked =
    (editMode ? editState : state).providers.modules[moduleKey] &&
    (editMode ? editState : state).providers.modules[moduleKey].enabled
  const facilitiesModuleChecked =
    (editMode ? editState : state).facilities.modules[moduleKey] &&
    (editMode ? editState : state).facilities.modules[moduleKey].enabled

  const providersEnabled = (editMode ? editState : state).providers.enabled
  const facilitiesEnabled = (editMode ? editState : state).facilities.enabled

  return (
    <Flex
      s={p => ({
        padding: p.theme.sizes.four,
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: `inset 0px -1px 0px #E2E8F0`,
      })}
    >
      <Label
        s={p => ({
          flex: 1,
          fontWeight: '500',
          color: p.theme.colors.textLightest,
        })}
      >
        {label}
      </Label>
      <Flex
        s={() => ({
          flex: 0.3,
          justifyContent: 'space-around',
        })}
      >
        {/* Provider switches */}
        <ReactSwitch
          onChange={() => onChange('providers', moduleKey)}
          checked={providerModuleChecked}
          onColor={colors.aubergine}
          checkedIcon={false}
          uncheckedIcon={false}
          height={16}
          width={32}
          disabled={!providersEnabled || !editMode}
        />
        {/* Facilities switches */}
        <ReactSwitch
          onChange={() => onChange('facilities', moduleKey)}
          checked={facilitiesModuleChecked}
          onColor={colors.aubergine}
          checkedIcon={false}
          uncheckedIcon={false}
          height={16}
          width={32}
          disabled={
            !facilitiesEnabled ||
            !editMode ||
            unavailableFacilitiesModuleKeys.includes(moduleKey)
          }
        />
      </Flex>
    </Flex>
  )
}

const PrimaryButton = styled(Button)`
  min-width: 160px;
`

PrimaryButton.defaultProps = {
  type: BUTTON_TYPE.DEFAULT,
  size: BUTTON_SIZE.LARGE,
}

const SecondaryButton = styled(Button)`
  margin-right: ${p => p.theme.sizes.two};
  :hover {
    background-color: ${p => p.theme.colors.gray6};
  }
`
SecondaryButton.defaultProps = {
  type: BUTTON_TYPE.LINK,
  size: BUTTON_SIZE.LARGE,
}

ManageInstanceModules.propTypes = {
  instance: PropTypes.object,
}
