import React, { useState, useCallback, useContext } from 'react'
import { Block } from 'design-system/block'
import { Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Modal } from 'design-system/modal'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Chevron } from 'design-system/icon/icons/chervon-left'
import { ProviderDetails } from 'components/edit-provider-info/edit-provider-details'
import { ProviderEducation } from 'components/edit-provider-info/edit-provider-education'
import { ProviderLanguages } from 'components/edit-provider-info/edit-provider-languages'
import { ProviderSpecialties } from 'components/edit-provider-info/edit-provider-specialties'
import { formatEducation, formatLanguage } from './provider-detail-formatter'
import { ReviewChanges } from 'components/edit-review-changes/review-changes'
import { NotyfContext } from 'context/notyf-context'
import { ConfirmCloseModal } from 'components/confirm-close/confirm-close-modal'
import { convertGenderInitial } from 'utils/constants'
import { services } from 'services'
import { AuthStateContext } from 'context/auth-state-context'

const providerObjectHash = {
  objectHash: obj => JSON.stringify(obj),
  arrays: {
    detectMove: false,
  },
}
const jsonDiff = require('jsondiffpatch')

const PROVIDER_KEYS = {
  age: 'Age',
  first_name: ' First Name',
  gender: 'Gender',
  last_name: 'Last Name',
  middle_name: 'Middle Name',
}

/**
 * Return educations that contain a name only
 * @param {[]} educations
 */
const educationReducer = (educations = []) => {
  return educations.reduce((result, curr) => {
    if (curr.education && curr.education.name) {
      result.push(curr)
    }
    return result
  }, [])
}

export const ProviderDetailEditInfoForm = ({
  provider = {},
  closeModal = async () => {},
}) => {
  const [authState] = useContext(AuthStateContext)
  const [editProvider, setEditProvider] = useState(
    JSON.parse(JSON.stringify(provider)),
  )
  const [showReviewChanges, setShowReviewChanges] = useState(false)
  const [submittingChanges, setSubmittingChanges] = useState(false)
  const notyf = useContext(NotyfContext)
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false)

  const ogProvider = {
    'Provider Details': {
      first_name: provider.firstName,
      middle_name: provider.middleName,
      last_name: provider.lastName,
      gender: provider.gender,
      age: provider.age,
    },
    Education: educationReducer(provider.educations),
    Languages: provider.languages,
    Specialties: provider.specialties,
  }

  const providerWithChanges = {
    'Provider Details': {
      first_name: editProvider.firstName,
      middle_name: editProvider.middleName,
      last_name: editProvider.lastName,
      gender: convertGenderInitial(editProvider.gender),
      age: editProvider.age,
    },
    Education: educationReducer(editProvider.educations),
    Languages: editProvider.languages,
    Specialties: editProvider.specialties,
  }

  let providerDiff = undefined
  providerDiff = jsonDiff
    .create(providerObjectHash)
    .diff(ogProvider, providerWithChanges)

  const toggleReviewChanges = useCallback(() => {
    setShowReviewChanges(prev => !prev)
  }, [])

  const submitChanges = useCallback(async () => {
    console.log('Previous:', provider)
    console.log('Changes:', editProvider)
    console.log('Diff:', providerDiff)
    try {
      setSubmittingChanges(true)
      const updateBody = {}
      const addedSpecialties = []
      const removedSpecialties = []
      Object.keys(providerDiff).forEach(key => {
        if (key === 'Provider Details') {
          // Looping through Diff Changes Obj, getting 2nd item in array
          // which is the changed field according to the diff array
          Object.keys(providerDiff[key]).forEach(detailsKey => {
            updateBody[detailsKey] = providerDiff[key][detailsKey][1]
          })
        }
        if (key === 'Education') {
          updateBody.educations = editProvider.educations
        }
        if (key === 'Languages') {
          updateBody.languages = editProvider.languages
        }
        if (key === 'Specialties') {
          // Separate Added & Removed from array diff
          // Add to specialtiesUpdateBody
          Object.keys(providerDiff[key]).forEach(detailsKey => {
            if (detailsKey === '_t') return
            const item = providerDiff[key][detailsKey]
            console.log(item)
            if (!detailsKey.includes('_')) {
              addedSpecialties.push(item[0].uuid)
            }
            if (detailsKey.includes('_')) {
              removedSpecialties.push(item[0].uuid)
            }
          })
        }
      })

      console.log('updateBody', updateBody)
      console.log('addedSpecialties', addedSpecialties)
      console.log('removedSpecialties', removedSpecialties)

      if (Object.keys(updateBody).length > 0) {
        await services.ribbon.updateProviderInfo(
          authState.token,
          provider.npi,
          updateBody,
        )
      }

      if (addedSpecialties.length > 0 || removedSpecialties.length > 0) {
        await services.ribbon.updateProviderSpecialties(
          authState.token,
          provider.npi,
          {
            add: addedSpecialties,
            remove: removedSpecialties,
          },
        )
      }

      await closeModal({ refresh: true })
      setSubmittingChanges(false)
      // Show Success Toast Notification
      notyf.success('Provider Info updated!')
    } catch (error) {
      setSubmittingChanges(false)
      // Show Success Toast Notification
      closeModal()
      notyf.error(error.message)
    }
  }, [provider, editProvider, closeModal, providerDiff, notyf, authState.token])

  const handleClose = useCallback(() => {
    if (providerDiff) {
      setShowCloseConfirmation(true)
      return
    }
    closeModal()
  }, [providerDiff, closeModal])

  let disabledReviewChangesBtn = false
  if (providerDiff === undefined) {
    disabledReviewChangesBtn = true
  }
  if (providerDiff !== undefined) {
    if (!editProvider.firstName || !editProvider.lastName) {
      disabledReviewChangesBtn = true
    }
  }
  if (submittingChanges) {
    disabledReviewChangesBtn = true
  }

  if (showCloseConfirmation) {
    return (
      <ConfirmCloseModal
        closeModal={closeModal}
        onClickCancel={() => setShowCloseConfirmation(false)}
      />
    )
  }
  return (
    <Modal
      onClose={handleClose}
      title="Edit Provider Information"
      headerLeftAction={
        showReviewChanges ? (
          <Label
            type={LABEL_TYPE.LINK}
            onClick={() => setShowReviewChanges(false)}
          >
            <Chevron direction="left" style={{ marginRight: '0.5rem' }} />
            Back to edit
          </Label>
        ) : null
      }
      footer={
        <Row end="xs" style={{ paddingRight: '2rem' }}>
          <Button
            s={() => ({
              marginRight: '1rem',
            })}
            type={BUTTON_TYPE.LINK}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            onClick={showReviewChanges ? submitChanges : toggleReviewChanges}
            type={
              disabledReviewChangesBtn
                ? BUTTON_TYPE.DISABLED
                : BUTTON_TYPE.PRIMARY
            }
            size={BUTTON_SIZE.LARGE}
            disabled={disabledReviewChangesBtn}
          >
            {showReviewChanges ? 'Submit changes' : 'Next: Review Changes'}
          </Button>
        </Row>
      }
    >
      {showReviewChanges ? (
        <ReviewChanges
          diff={providerDiff}
          renderArrayChanges={renderArrayChanges}
          renderPropertyChanges={renderPropertyChanges}
          title="Provider Information"
          loading={submittingChanges}
          loadingMessage="Updating Provider Information"
        />
      ) : (
        <Block
          s={p => ({
            padding: p.theme.sizes.five,
            paddingBottom: '14rem',
          })}
        >
          <ProviderDetails
            editProvider={editProvider}
            setEditProvider={setEditProvider}
          />
          <Divider />
          <ProviderEducation
            editProvider={editProvider}
            setEditProvider={setEditProvider}
          />
          <Divider />
          <ProviderLanguages
            editProvider={editProvider}
            setEditProvider={setEditProvider}
          />
          <Divider />
          <ProviderSpecialties
            editProvider={editProvider}
            setEditProvider={setEditProvider}
          />
        </Block>
      )}
    </Modal>
  )
}

const renderArrayChanges = (key, item) => {
  return Object.keys(item).map(itemKey => {
    if (itemKey === '_t') return // Ignore _t as its only an indicator this is an array
    let formattedLabel = ''
    let action = ''
    if (key === 'Education') {
      formattedLabel = formatEducation(item[itemKey][0])
    }
    if (key === 'Languages') {
      formattedLabel = formatLanguage(item[itemKey][0])
    }
    if (key === 'Specialties') {
      formattedLabel = item[itemKey][0].display
    }

    if (!itemKey.includes('_')) {
      action = 'Added'
    }
    if (itemKey.includes('_')) {
      action = 'Removed'
    }

    return (
      <Row between="xs" key={itemKey} style={{ marginBottom: '0.5rem' }}>
        <Label type={LABEL_TYPE.BODY1}>{formattedLabel}</Label>
        <Label type={LABEL_TYPE.BODY1}>{action}</Label>
      </Row>
    )
  })
}

const renderPropertyChanges = (item = [], itemKey = '') =>
  `${PROVIDER_KEYS[itemKey]}: ${item[itemKey][1]}`

const Divider = styled.div`
  background-color: ${props => props.theme.colors.gray3};
  width: 100%;
  height: 1px;
  margin-top: ${p => p.theme.sizes.six};
  margin-bottom: ${p => p.theme.sizes.six};
`

export default ProviderDetailEditInfoForm
