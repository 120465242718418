import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme/colors'
import { Flex } from 'design-system/flex'

const Dot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${colors.gray4};
  ${p => ({
    ...(p.filled && {
      backgroundColor: colors.aubergine,
    }),
    marginRight: p.theme.sizes.one,
  })};
`

export const DotIndicators = ({ total = 0, showEmpty = true }) => {
  return (
    <Flex data-testid={`dot-indicators-${total}`}>
      {[1, 2, 3, 4, 5].map(n => {
        const filled = n <= total
        if (!showEmpty && !filled) return null
        return <Dot key={n} filled={n <= total} />
      })}
    </Flex>
  )
}

DotIndicators.propTypes = {
  total: PropTypes.number,
  showEmpty: PropTypes.bool,
}
