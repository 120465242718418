import React, { useState } from 'react'
import { Modal } from 'design-system/modal'
import { Label } from 'design-system/label'
import { Flex } from 'design-system/flex'
import { Input } from 'design-system/input'
import SearchSVG from 'assets/developer-dashboard/search.svg'

export const ViewAllOfTypeModal = ({
  filteringBy = [],
  closeModal = () => {},
  type = '',
}) => {
  const [searchValue, setSearchValue] = useState('')
  const onChangeSearch = e => {
    setSearchValue(e.target.value)
  }
  return (
    <Modal onClose={closeModal} title={`Providers By ${type}`}>
      <Flex
        s={p => ({
          flexDirection: 'column',
          padding: p.theme.sizes.six,
          minHeight: '468px', // 500 max minus padding - so modal doesnt jump on search
        })}
      >
        {filteringBy.length > 0 && (
          <Label
            s={p => ({
              marginBottom: p.theme.sizes.four,
              color: p.theme.colors.gray2,
            })}
          >
            {`Filtering by ${filteringBy.length} provider ${
              filteringBy.length === '1' ? 'type' : 'types'
            }`}
          </Label>
        )}
        <Input
          placeholder={`Search ${type.toLowerCase()}`}
          value={[{ label: searchValue }]}
          leftIcon={SearchSVG}
          onChange={() => setSearchValue('')}
          onInputChange={onChangeSearch}
          s={p => ({
            borderRadius: p.theme.sizes.thirteen,
            backgroundColor: p.theme.colors.white,
          })}
        />
        <Flex
          s={p => ({
            marginTop: p.theme.sizes.four,
            border: `1px solid ${p.theme.colors.gray5}`,
            borderBottom: 'none',
            flexDirection: 'column',
          })}
        >
          {Array.from(Array(100).keys()).map(i => {
            return <DataRow key={i} label="Specialty name" value="1,000,000" />
          })}
        </Flex>
      </Flex>
    </Modal>
  )
}

const DataRow = ({ label = 'Label', value = 'Value', renderValue = null }) => {
  return (
    <Flex
      s={p => ({
        flex: 1,
        borderBottom: `1px solid ${p.theme.colors.gray5}`,
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${p.theme.sizes.two} ${p.theme.sizes.four}`,
      })}
    >
      <Label
        s={p => ({
          fontWeight: '400',
          color: p.theme.colors.gray0,
        })}
      >
        {label}
      </Label>
      {renderValue ? (
        renderValue()
      ) : (
        <Label
          s={p => ({
            color: p.theme.colors.gray2,
          })}
        >
          {value}
        </Label>
      )}
    </Flex>
  )
}
