import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Label } from 'design-system/label'
import styled from 'styled-components'

export const DropdownMenu = forwardRef(
  (
    { id = 'dropdown-menu', options = [], inverse = false, renderOption },
    ref,
  ) => {
    return (
      <MoreMenu
        ref={ref}
        id={id}
        inverse={inverse}
        totalOptions={options.length}
      >
        {options.map(option => {
          if (renderOption) {
            return renderOption(option)
          }
          return (
            <MenuLabel key={option.title} onClick={option.onClick}>
              {option.title}
            </MenuLabel>
          )
        })}
      </MoreMenu>
    )
  },
)
DropdownMenu.displayName = 'DropdownMenu'

DropdownMenu.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array,
  inverse: PropTypes.bool,
  renderOption: PropTypes.func,
}

export const MoreMenu = styled.div`
  position: absolute;
  padding: ${p => p.theme.sizes.four} 0;
  /* each menu Label option is about 44px */
  /* inverse places the dropdown above button inside of below */
  top: ${p => (p.inverse ? `-${p.totalOptions * 44 + 24}px` : '40px')};
  right: 0;
  width: 200px;
  border: 1px solid ${p => p.theme.colors.gray6};
  box-shadow: 0px 4px 4px rgba(211, 210, 210, 0.25);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.colors.white};
  z-index: 999;
`

export const MenuLabel = styled(Label)`
  padding: ${p => p.theme.sizes.three} ${p => p.theme.sizes.four};
  :hover {
    background-color: ${p =>
      p.onClick ? p.theme.colors.gray7 : p.theme.colors.white};
    font-weight: ${p => (p.onClick ? 'bold' : 'normal')};
  }
  font-weight: ${p => (p.selected ? 'bold' : 'normal')};
  ${p => ({
    ...(p.disabled && {
      color: p.theme.colors.textDisabled,
    }),
  })}
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  /* background-color: rgba(0, 0, 0, 0.1); */
  cursor: default;
`
