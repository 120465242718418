import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useToggle } from 'hooks/useToggle'
import { EditCustomFieldModal } from './edit-custom-field-modal'
import { FIELD_TYPES_FLAT_LOWER } from 'utils/constants'
import { MainSectionField } from './main-section-field'
import { SidebarField } from './sidebar-section-field'
import { CustomListModal } from './custom-list-modal'

const Divider = styled.div`
  ${p => ({
    height: '1px',
    width: '100%',
    backgroundColor: p.theme.colors.gray4,
    margin: `${p.theme.sizes.six} 0`,
  })}
`

export const CustomField = ({
  field = {},
  values,
  type,
  updateId,
  handleUpdateSuccess,
}) => {
  const [showEdit, toggleEdit] = useToggle(false)
  const [activeListItem, setActiveListItem] = useState()

  const onClickEditField = (item, index) => {
    console.log(`onClickEditField`, item, index)
    if (item && index !== undefined) {
      setActiveListItem({ item, index })
    } else {
      toggleEdit()
    }
  }

  const onClickAddField = () => {
    const { list_display_config } = field
    const { list_type } = list_display_config
    if (list_type === FIELD_TYPES_FLAT_LOWER.object) {
      const { object_display_config } = list_display_config
      const newObject = {}
      object_display_config.keys.forEach(displayConfigKey => {
        // Get all keys for new object to add to list
        newObject[displayConfigKey.name] = ''
      })
      // index: values.length indicates a new item will be added to list
      setActiveListItem({ item: newObject, index: values.length })
    } else {
      // index: values.length indicates a new item will be added to list
      setActiveListItem({ item: '', index: values.length })
    }
  }

  const handleClose = async refresh => {
    if (refresh) {
      await handleUpdateSuccess()
    }
    toggleEdit()
  }

  const handleCustomListClose = async refresh => {
    if (refresh) {
      await handleUpdateSuccess()
    }
    if (activeListItem) setActiveListItem()
  }

  return (
    <>
      <Divider />
      {field &&
      (field.field_type === FIELD_TYPES_FLAT_LOWER.object ||
        field.field_type === FIELD_TYPES_FLAT_LOWER.list) ? (
        <MainSectionField
          values={values}
          field={field}
          onClickEditField={onClickEditField}
          onClickAddField={onClickAddField}
        />
      ) : (
        <SidebarField
          field={field}
          values={values}
          onClickEditField={onClickEditField}
        />
      )}
      {showEdit && (
        <EditCustomFieldModal
          field={field}
          values={values}
          handleClose={handleClose}
          type={type}
          updateId={updateId}
        />
      )}
      {activeListItem && (
        <CustomListModal
          field={field}
          activeItem={activeListItem}
          values={values}
          handleClose={handleCustomListClose}
          type={type}
          updateId={updateId}
        />
      )}
    </>
  )
}

CustomField.propTypes = {
  field: PropTypes.object,
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  type: PropTypes.string,
  updateId: PropTypes.string,
  handleUpdateSuccess: PropTypes.func,
}
