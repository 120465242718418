import React, { useContext, useState } from 'react'
import ReactSwitch from 'react-switch'
import { Modal } from 'design-system/modal'
import { Block } from 'design-system/block'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Input, INPUT_TYPE } from 'design-system/input'
import styled from 'styled-components'
import { Spinner } from 'design-system/spinner/spinner.component'
import { SelectInput } from 'design-system/input/select-input'
import { formatPermissionLevel } from 'utils/formatters/format-permission-levels'
import { theme } from 'theme'
import { colors } from 'theme/colors'
import { validEmail } from 'utils/validations'
import { NotyfContext } from 'context/notyf-context'

const newUser = {
  firstName: '',
  lastName: '',
  email: '',
  permissionLevel: 1,
  isSso: false,
}

export const AddNewUsersModal = ({
  handleClose = () => {},
  handleAddUsers = async () => {},
}) => {
  const [state, setState] = useState([{ ...newUser }])
  const [isLoading, setIsLoading] = useState(false)
  const notyf = useContext(NotyfContext)

  const onChangeText = (index, { name, value }) => {
    setState(prev => {
      const prevCopy = prev.slice()
      prevCopy[index][name] = value
      return prevCopy
    })
  }

  const onClickAdd = async () => {
    console.log(`🚀 => onClickAdd => state`, state)

    // API call to add users
    setIsLoading(true)
    try {
      await handleAddUsers(state)
      setIsLoading(false)
      handleClose()
    } catch (error) {
      setIsLoading(false)
      // API returns error message
      if (error.response) {
        const { data, status } = error.response
        if (status === 409) {
          if (data && data.message) {
            notyf.error(error.response.data.message)
            return
          }
        }
      }
      notyf.error(error.message)
    }
  }

  const onClickRemoveUser = index => {
    setState(prev => {
      const prevCopy = prev.slice()
      prevCopy.splice(index, 1)

      return prevCopy
    })
  }

  const onClickAddAnotherUser = () => {
    setState(prev => {
      const prevCopy = prev.slice()
      prevCopy.push({ ...newUser })

      return prevCopy
    })
  }

  const addDisabled = state.some(
    user =>
      user.firstName.length === 0 ||
      user.lastName.length === 0 ||
      user.email.length === 0 ||
      (user.email.length > 0 && !validEmail(user.email)),
  )

  return (
    <Modal
      onClose={handleClose}
      title={'Add New Users'}
      gridContainerStyle={{ maxWidth: '600px' }}
      footer={
        <Block
          type="FLEX"
          s={p => ({
            marginRight: p.theme.sizes.six,
            justifyContent: 'flex-end',
          })}
        >
          <Button
            s={() => ({
              marginRight: '1rem',
            })}
            type={BUTTON_TYPE.LINK}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            onClick={onClickAdd}
            type={addDisabled ? BUTTON_TYPE.DISABLED : BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.LARGE}
            disabled={addDisabled}
            s={() => ({
              minWidth: '110px',
            })}
          >
            Add
          </Button>
        </Block>
      }
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {state.map((item, index) => {
            return (
              <NewUserForm
                key={index}
                item={item}
                onClickRemoveUser={() => onClickRemoveUser(index)}
                onChangeText={onChangeText}
                index={index}
              />
            )
          })}

          <Label
            type={BUTTON_TYPE.LINK}
            s={p => ({
              paddingLeft: p.theme.sizes.four,
              fontWeight: '500',
              marginBottom: p.theme.sizes.four,
              color: p.theme.colors.aubergine,
              width: 'auto',
            })}
            onClick={() => onClickAddAnotherUser()}
          >
            Add another user
          </Label>
        </>
      )}
    </Modal>
  )
}

const NewUserForm = ({
  item = {},
  onChangeText = () => {},
  index = -1,
  onClickRemoveUser = () => {},
}) => {
  const onChangeTextCallback = event => {
    const { name, value } = event.target

    onChangeText(index, { name, value })
  }

  const { firstName, lastName, email, permissionLevel, isSso } = item

  return (
    <FormContainer key={index}>
      <Block
        type="FLEX"
        s={() => ({
          padding: 0,
          justifyContent: 'space-between',
        })}
      >
        <InputContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>First Name</Label>
          <Input
            s={p => ({ marginTop: p.theme.sizes.two })}
            onInputChange={onChangeTextCallback}
            name="firstName"
            value={[{ label: firstName }]}
            showClearIcon={false}
            type={INPUT_TYPE.ROUND}
            placeholder="Enter first name"
          />
        </InputContainer>
        <InputContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Last Name</Label>
          <Input
            s={p => ({ marginTop: p.theme.sizes.two })}
            onInputChange={onChangeTextCallback}
            name="lastName"
            value={[{ label: lastName }]}
            showClearIcon={false}
            type={INPUT_TYPE.ROUND}
            placeholder="Enter last name"
          />
        </InputContainer>
      </Block>
      <Block
        type="FLEX"
        s={() => ({
          padding: 0,
          justifyContent: 'space-between',
        })}
      >
        <InputContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Email Address</Label>
          <Input
            s={p => ({ marginTop: p.theme.sizes.two })}
            onInputChange={onChangeTextCallback}
            name="email"
            value={[{ label: email }]}
            showClearIcon={false}
            type={INPUT_TYPE.ROUND}
            placeholder="Enter email address"
          />
        </InputContainer>
        <InputContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Permission Level</Label>
          <SelectInput
            onChange={onChangeTextCallback}
            name="permissionLevel"
            value={
              permissionLevel ? formatPermissionLevel(permissionLevel) : ''
            }
            options={[
              {
                label: formatPermissionLevel(1),
                value: 1,
              },
              {
                label: formatPermissionLevel(2),
                value: 2,
              },
              {
                label: formatPermissionLevel(3),
                value: 3,
              },
            ]}
            disabled={false}
            containerStyle={{ marginTop: theme.sizes.two }}
          />
        </InputContainer>
      </Block>
      <InputContainer>
        <Label
          type={LABEL_TYPE.BODY1_BOLD}
          s={p => ({ marginBottom: p.theme.sizes.two })}
        >
          Enable single sign-on
        </Label>
        <ReactSwitch
          checked={isSso}
          onChange={() => onChangeText(index, { name: 'isSso', value: !isSso })}
          onColor={colors.aubergine}
          checkedIcon={false}
          uncheckedIcon={false}
          height={20}
          width={40}
        />
      </InputContainer>
      {index !== 0 && (
        <Label
          type={LABEL_TYPE.LINK}
          onClick={onClickRemoveUser}
          s={p => ({
            color: p.theme.colors.aubergine,
            marginBottom: '1rem',
          })}
        >
          Remove User
        </Label>
      )}
    </FormContainer>
  )
}
const FormContainer = styled(Block)`
  padding: ${p => p.theme.sizes.four};
`

const InputContainer = styled.div`
  flex: 0.49;
  margin-bottom: ${p => p.theme.sizes.four};
`
