import React from 'react'
import { PropTypes } from 'prop-types'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { Block } from 'design-system/block'
import styled from 'styled-components'
import { convertRemToPixels } from 'utils/theme.util'
import { ZoomControl } from './zoom-control.component'
import { RedoSearchHereControl } from './redo-search-here-control.component'

const InfoWindowContainer = styled(Block)`
  position: absolute;
  padding: 0;
  top: 0;
  overflow: visible;
  height: 0;
  width: 100%;
`

const ZoomControlContainer = styled(Block)`
  position: absolute;
  padding: 0;
  width: 2.5rem;
  height: 6.25rem;
  right: 1rem;
  top: 1rem;
`

const RedoSearchHereContainer = styled(Block)`
  position: absolute;
  margin: 0 auto;
  padding: 0;
  height: 2.5rem;
  width: 15rem;
  top: 1rem;
  left: 1rem;
`

const INFO_WINDOW_WIDTH = '16.5rem'
const INFO_WINDOW_HEIGHT = '18.75rem'
const MARKER_HEIGHT = 60
const MARKER_GAP_OFFSET = 10

const InfoWindowContentContainer = styled(Block)`
  padding: 0;
  height: ${INFO_WINDOW_HEIGHT};
  max-height: ${INFO_WINDOW_HEIGHT};
  overflow: hidden;
  width: ${INFO_WINDOW_WIDTH};
  background: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.gray5};
  box-sizing: border-box;
  border-radius: 1.125rem;
  ${p => ({
    boxShadow: `0 ${p.theme.sizes.one} ${p.theme.sizes.three}
    ${p.theme.colors.borderInfoWindow}`,
  })}
`
const InfoWindow = styled(Block)`
  padding: 0;
  height: ${INFO_WINDOW_HEIGHT};
  max-height: ${INFO_WINDOW_HEIGHT};
  overflow: auto;
`

export const MapPanel = React.forwardRef((props, ref) => {
  const [map, setMap] = React.useState()
  const [bounds, setBounds] = React.useState()

  const pixelOffsetForBounds = (pixelRange, start, end, point) => {
    const [low, high] = [Math.min(start, end), Math.max(start, end)]
    const range = high - low
    const isInBounds = point >= low && point <= high
    if (isInBounds) {
      const percentageLengthFromLow = (point - low) / range
      const pixelOffsetFromLow =
        (end < start ? percentageLengthFromLow : 1 - percentageLengthFromLow) *
        pixelRange
      return pixelOffsetFromLow
    }
  }

  const mapWidth = map && map.getDiv().offsetWidth
  const mapHeight = map && map.getDiv().offsetHeight
  return (
    <Block
      ref={ref}
      className={props.className}
      s={() => ({
        position: 'relative',
        height: '100%',
        width: '100%',
        padding: 0,
      })}
    >
      <GoogleMap
        mapContainerStyle={{
          position: 'relative',
          height: '100%',
          width: '100%',
        }}
        onLoad={map => {
          setMap(map)
          props.onLoad && props.onLoad(map)
        }}
        zoom={props.zoom || 10}
        onBoundsChanged={() => {
          const bounds = map.getBounds()
          const ne = bounds.getNorthEast()
          const sw = bounds.getSouthWest()
          setBounds({ n: ne.lat(), e: ne.lng(), s: sw.lat(), w: sw.lng() })
        }}
        onCenterChanged={() => {
          const center = map && map.getCenter()
          if (
            map &&
            center &&
            (!props.center ||
              center.lat() !== props.center.lat ||
              center.lng() !== props.center.lng)
          ) {
            map && props.onCenterChanged && props.onCenterChanged(map && center)
          }
        }}
        options={{
          disableDefaultUI: true,
        }}
        center={props.center}
      >
        {props.markers &&
          props.markers.map((marker, index) => (
            <React.Fragment key={`marker-${index}`}>
              <Marker
                key={index}
                onMouseOver={() => {
                  marker.onMarkerMouseOver
                    ? marker.onMarkerMouseOver(marker, index)
                    : null
                }}
                position={marker.position}
                icon={marker.icon}
              />
            </React.Fragment>
          ))}
      </GoogleMap>
      {props.showZoomControls && (
        <ZoomControlContainer>
          <ZoomControl onZoomIn={props.onZoomIn} onZoomOut={props.onZoomOut} />
        </ZoomControlContainer>
      )}
      {props.shouldShowRedoSearchHereControl && (
        <RedoSearchHereContainer>
          <RedoSearchHereControl onClick={props.onRedoSearchHereClick} />
        </RedoSearchHereContainer>
      )}
      <InfoWindowContainer>
        {bounds &&
          props.infoWindows &&
          props.infoWindows.map((infoWindow, i) => {
            const offsets = [
              pixelOffsetForBounds(
                mapHeight,
                bounds.s,
                bounds.n,
                infoWindow.position.lat(),
              ),
              pixelOffsetForBounds(
                mapWidth,
                bounds.e,
                bounds.w,
                infoWindow.position.lng(),
              ),
            ]
            const sitsAbove = offsets[0] < 0.35 * mapHeight
            return (
              <Block
                key={`infoWindow-${i}`}
                onMouseEnter={() => {
                  infoWindow.onInfoWindowMouseEnter
                    ? infoWindow.onInfoWindowMouseEnter(infoWindow, i)
                    : null
                }}
                onMouseLeave={() => {
                  infoWindow.onInfoWindowMouseLeave
                    ? infoWindow.onInfoWindowMouseLeave(infoWindow, i)
                    : null
                }}
                s={p => ({
                  position: 'absolute',
                  zIndex: p.theme.zIndexes.modal,
                  padding: `${
                    sitsAbove ? MARKER_HEIGHT + MARKER_GAP_OFFSET : 0
                  }px 0 ${
                    !sitsAbove ? MARKER_HEIGHT + MARKER_GAP_OFFSET : 0
                  }px 0`,
                  margin: `-${
                    sitsAbove ? MARKER_HEIGHT + MARKER_GAP_OFFSET : 0
                  }px 0 -${
                    !sitsAbove ? MARKER_HEIGHT + MARKER_GAP_OFFSET : 0
                  }px 0`,
                  left: `${
                    offsets[1] - 0.85 * convertRemToPixels(INFO_WINDOW_WIDTH)
                  }px`,
                  top: `${
                    sitsAbove
                      ? offsets[0]
                      : offsets[0] -
                        convertRemToPixels(INFO_WINDOW_HEIGHT) -
                        (MARKER_HEIGHT + MARKER_GAP_OFFSET)
                  }px`,
                })}
              >
                <InfoWindowContentContainer>
                  <InfoWindow>{infoWindow.children}</InfoWindow>
                </InfoWindowContentContainer>
              </Block>
            )
          })}
      </InfoWindowContainer>
    </Block>
  )
})

MapPanel.displayName = 'map-panel'

MapPanel.propTypes = {
  onLoad: PropTypes.func,
  markers: PropTypes.arrayOf(PropTypes.object),
  infoWindows: PropTypes.arrayOf(PropTypes.object),
  center: PropTypes.object,
  className: PropTypes.string,
  onCenterChanged: PropTypes.func,
  zoom: PropTypes.number,
  onZoomIn: PropTypes.func,
  onZoomOut: PropTypes.func,
  onRedoSearchHereClick: PropTypes.func,
  style: PropTypes.object,
  shouldShowRedoSearchHereControl: PropTypes.bool,
  showZoomControls: PropTypes.bool,
}

MapPanel.defaultProps = {
  showZoomControls: true,
}
