import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useManageLocationDetails } from './useManageLocationDetails'
import { VerificationToggle } from 'components/verification-toggle/verification-toggle'
import { ConfirmReportModal } from './confirm-report-modal'
import { LOCATION_SCROLL_OPTIONS } from 'components/edit-location/edit-location-form'

const Container = styled.div`
  .verifyToggleContainer {
    margin-left: ${p => p.theme.sizes.three};
  }

  .moreMenuContainer {
    max-width: 225px;
  }
`

export const LocationVerification = ({
  location = {},
  handleMutation,
  provider,
  facility,
  onClickEditLocation = () => { },
  refreshLocation = async () => { },
}) => {
  const [showConfirmReport, setShowConfirmReport] = useState(false)

  const {
    updateProviderLocationData,
    updatingProviderLocationData,
    updateLocationData,
    updatingLocationData,
  } = useManageLocationDetails({
    location,
    refreshLocation,
    handleMutation,
  })

  const closeModal = () => {
    setShowConfirmReport(false)
  }

  const updateLocation = async (requestType, updateBody) => {
    if (provider) {
      // Update Provider Call
      updateProviderLocationData({
        action: requestType,
        options: {
          ...updateBody,
          npi: provider.npi,
          location_uuid: location.uuid,
        },
      })
    }
    if (facility) {
      // Update Facility Call
      updateLocationData({
        action: requestType,
        options: {
          ...updateBody,
          npi: null,
          location_uuid: location.uuid,
        },
      })
    }
  }

  const handleVerification = (uuid, confidence) => {
    console.log(`🚀 => handleVerification => uuid:confidence`, uuid, confidence)
    if (confidence === 5) {
      // confirm location
      updateLocation('update', {
        confidence: location.confidence,
        new_confidence: 5.0,
      })
    }
    if (confidence === 0) {
      // show confirm report first
      setShowConfirmReport(true)
    }
    // No Selection option translates to reversion. Call function only if actual confidence score change.
    if (confidence === 3 && confidence !== location.confidence) {
      // revert
      updateLocation('revert', {})
    }
  }

  const handleConfirmAction = actionIndex => {
    console.log(`🚀 => handleConfirmAction => actionIndex`, actionIndex)
    setShowConfirmReport(false)
    if (actionIndex === 0) {
      // Report Location inaccurate option
      updateLocation('update', {
        confidence: location.confidence,
        new_confidence: 0.0,
      })
    } else {
      // Edit location option
      onClickEditLocation(LOCATION_SCROLL_OPTIONS.contact)
    }
  }

  const isUpdating = updatingProviderLocationData || updatingLocationData

  return (
    <Container>
      <VerificationToggle
        uuid={location.uuid}
        confidence={location.confidence}
        handleVerification={handleVerification}
        isUpdating={isUpdating}
        activeIconMap={{
          low: location.confidence > 0 && location.confidence < 3,
          medium: location.confidence > 2 && location.confidence < 5,
          report: location.confidence === 0,
          verify: location.confidence === 5,
        }}
        menuOptions={{
          none: {
            confidence: 3,
            label: 'No Selection',
            tooltip: '',
          },
          report: {
            confidence: 0,
            label: 'Report Location',
            tooltip: 'Reported Location',
          },
          verify: {
            confidence: 5,
            label: 'Confirm Location',
            tooltip: 'Confirmed Location',
          },
        }}
      />
      {showConfirmReport && (
        <ConfirmReportModal
          closeModal={closeModal}
          onClickAction={handleConfirmAction}
          type={provider ? 'provider' : 'facility'}
        />
      )}
    </Container>
  )
}

LocationVerification.propTypes = {
  location: PropTypes.shape({}),
  provider: PropTypes.shape({
    npi: PropTypes.string,
  }),
  facility: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  onClickEditLocation: PropTypes.func,
  handleMutation: PropTypes.func,
  refreshLocation: PropTypes.func,
}
