import React from 'react'
import PropTypes from 'prop-types'

export const JsonView = ({ forceOpen = false, index = 0, data }) => {
  if (typeof window === 'undefined') {
    return null
  }
  const ReactJson = require('react-json-view').default

  return (
    <ReactJson
      name={null}
      displayDataTypes={false}
      displayObjectSize={false}
      enableClipboard={false}
      quotesOnKeys={false}
      src={data}
      collapsed={forceOpen ? 1 : index > 0 ? 0 : 1}
      style={{ fontFamily: 'Inter' }}
      iconStyle="circle"
      indentWidth={8}
    />
  )
}

JsonView.propTypes = {
  forceOpen: PropTypes.bool,
  index: PropTypes.number,
  data: PropTypes.object,
}
