import React, { useContext, useReducer } from 'react'
import { Modal } from 'design-system/modal'
import { Block } from 'design-system/block'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Spinner } from 'design-system/spinner/spinner.component'
import { Flex } from 'design-system/flex'
import {
  addNewFieldReducer,
  reducerTypes,
  initialState,
} from './add-new-field-reducer'
import { DisplayFieldStep } from './display-field-step'
import { NewFieldStep } from './new-field-step'
import { FIELD_TYPES_FLAT_LOWER } from 'utils/constants'
import { useMutation } from 'react-query'
import { AuthStateContext } from 'context/auth-state-context'
import { services } from 'services'
import { NotyfContext } from 'context/notyf-context'
import { useActionBtnsValidation } from './required-fields-validations'

export const AddNewCustomFieldModal = ({
  handleClose = () => {},
  handleSuccess = () => {},
  instance = {},
}) => {
  const [authState] = useContext(AuthStateContext)
  const notyf = useContext(NotyfContext)
  const [state, dispatch] = useReducer(addNewFieldReducer, initialState)
  const {
    configureDisplayBtnIsDisabled,
    createFieldBtnIsDisabled,
  } = useActionBtnsValidation(state)
  const { showConfigureDisplayButton, configureDisplay } = state

  const { mutate: createCustomField, isLoading: isCreating } = useMutation(
    ({ uuid, options }) =>
      services.ribbon.createCustomField(authState.token, uuid, options),
    {
      onSettled: () => {},
      onSuccess: async res => {
        console.log(`🚀 => createCustomField => res`, res)
        if (!res.uuid) {
          notyf.error('Error updating application with response.')
          return
        }
        let newConfig = {
          providers: [],
          facilities: [],
        }
        // Add to existing config
        if (instance.fca_config.fca_custom_fields) {
          newConfig = {
            ...instance.fca_config.fca_custom_fields,
            [state.type]: [
              ...instance.fca_config.fca_custom_fields[state.type],
              res,
            ],
          }
        } else {
          // New config set up
          newConfig[state.type].push(res)
        }
        await manageInstance({
          uuid: instance.uuid,
          options: {
            fca_custom_fields: newConfig,
          },
        })
        handleSuccess(res, state.createFilter)
      },
      onError: error => {
        console.log(`🚀 => createCustomField => error`, error)
        notyf.error(error.message)
      },
    },
  )

  const {
    mutateAsync: manageInstance,
    isLoading: isUpdatingInstance,
  } = useMutation(
    ({ uuid, options }) =>
      services.ribbon.manageInstance(authState.token, uuid, options),
    {
      onSettled: () => {},
      onSuccess: res => {
        console.log(`🚀 => createCustomField:manageInstance => res`, res)
        notyf.success('Custom field created!')
      },
      onError: error => {
        console.log(`🚀 => createCustomField => error`, error)
        notyf.error(error.message)
      },
    },
  )

  const onChangeText = e => {
    let { name, value, type } = e.target
    if (type === 'number') {
      value = Number(value)
      if (value > Number.MAX_SAFE_INTEGER) {
        value = Number.MAX_SAFE_INTEGER
      }
    }
    dispatch({
      type: reducerTypes.update,
      payload: {
        [name]: value,
        ...(name === 'field_type' && {
          showConfigureDisplayButton:
            value.toLowerCase() === FIELD_TYPES_FLAT_LOWER.boolean ||
            value.toLowerCase() === FIELD_TYPES_FLAT_LOWER.object
              ? true
              : false,
          // always reset list type on field type changes
          list_type: '',
          default_value: '',
        }),
        ...(name === 'list_type' && {
          showConfigureDisplayButton:
            value.toLowerCase() === FIELD_TYPES_FLAT_LOWER.object ||
            value.toLowerCase() === FIELD_TYPES_FLAT_LOWER.boolean
              ? true
              : false,
        }),
      },
    })
  }

  const onChangeCheckbox = (field, value) => {
    dispatch({ type: reducerTypes.update, payload: { [field]: value } })
  }

  const onChangeType = value => {
    dispatch({
      type: reducerTypes.update,
      payload: {
        type: value,
      },
    })
  }

  const onClickCreateField = () => {
    console.log(`🚀 => createField:state=>`, state)

    const newCustomField = {
      type: state.type,
      display_name: state.display_name,
      parameter_name: state.parameter_name,
      field_type: state.field_type.toLowerCase(),
      default_value: state.default_value,
      boolean_display_config:
        state.field_type.toLowerCase() === FIELD_TYPES_FLAT_LOWER.boolean
          ? state.boolean_display_config
          : null,
      object_display_config:
        state.field_type.toLowerCase() === FIELD_TYPES_FLAT_LOWER.object
          ? state.object_display_config
          : null,
      list_display_config:
        state.field_type.toLowerCase() === FIELD_TYPES_FLAT_LOWER.list
          ? {
              list_type: state.list_type.toLowerCase(),
              boolean_display_config: state.boolean_display_config,
              object_display_config: state.object_display_config,
            }
          : null,
    }

    console.log(`🚀 => createField:newCustomField =>`, newCustomField)

    createCustomField({ uuid: instance.uuid, options: newCustomField })
  }

  const onClickCancel = () => {
    handleClose()
  }

  const toggleConfigureDisplay = () => {
    dispatch({
      type: reducerTypes.toggle_display_config,
    })
  }

  const actionBtnIsDisabled =
    isCreating ||
    isUpdatingInstance ||
    (!showConfigureDisplayButton && createFieldBtnIsDisabled) ||
    (showConfigureDisplayButton && configureDisplayBtnIsDisabled) ||
    (configureDisplay && createFieldBtnIsDisabled)

  return (
    <Modal
      onClose={handleClose}
      title={
        configureDisplay ? 'Configure field display' : 'Add a new custom field'
      }
      gridContainerStyle={{ maxWidth: '600px' }}
      footer={
        <Flex
          s={p => ({
            marginRight: p.theme.sizes.six,
            justifyContent: 'flex-end',
          })}
        >
          <Button
            s={() => ({
              marginRight: '1rem',
            })}
            type={BUTTON_TYPE.LINK}
            onClick={configureDisplay ? toggleConfigureDisplay : onClickCancel}
          >
            {configureDisplay ? 'Back' : 'Cancel'}
          </Button>
          <Button
            onClick={
              showConfigureDisplayButton && !configureDisplay
                ? toggleConfigureDisplay
                : onClickCreateField
            }
            type={
              actionBtnIsDisabled ? BUTTON_TYPE.DISABLED : BUTTON_TYPE.PRIMARY
            }
            size={BUTTON_SIZE.LARGE}
            disabled={actionBtnIsDisabled}
            s={() => ({
              minWidth: '110px',
            })}
          >
            {showConfigureDisplayButton && !configureDisplay
              ? 'Next: Configure Display'
              : 'Create Field'}
          </Button>
        </Flex>
      }
    >
      {isCreating || isUpdatingInstance ? (
        <Spinner
          label={isCreating ? `Creating field..` : 'Updating application..'}
        />
      ) : (
        <Block
          s={p => ({
            padding: 0,
            paddingBottom: p.theme.sizes.ten,
            paddingTop: p.theme.sizes.four,
            minHeight: '240px',
          })}
        >
          {configureDisplay ? (
            <DisplayFieldStep dispatch={dispatch} state={state} />
          ) : (
            <NewFieldStep
              state={state}
              onChangeCheckbox={onChangeCheckbox}
              onChangeText={onChangeText}
              onChangeType={onChangeType}
            />
          )}
        </Block>
      )}
    </Modal>
  )
}
