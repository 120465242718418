import React, { forwardRef, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dayjs from 'utils/dayjs'
import { Flex } from 'design-system/flex'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Label } from 'design-system/label'
import { Button } from 'design-system/button'
import PlusSVG from 'assets/plus.svg'
import { NotesModal } from './notes-modal'
import { EDIT_TYPES } from 'utils/constants'
import { NoteItem } from './note-item'
import { PermissionRestricted } from 'components/permission-restricted/permission-restricted'
import { AuthStateContext } from 'context/auth-state-context'

const Divider = styled.div`
  height: 1px;
  min-height: 1px;
  width: 100%;
  background-color: ${p => p.theme.colors.gray4};
  margin: ${p => p.theme.sizes.six} 0;
`

const ListContainer = styled(Flex)`
  flex-direction: column;
  background-color: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.borderLight};
  padding: ${p => p.theme.sizes.six} ${p => p.theme.sizes.four};
  border-radius: 5px;
  max-height: 600px;
  overflow-y: auto;
`

const NotesHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin: ${p => `${p.theme.sizes.four} 0 ${p.theme.sizes.four} 0`};
`

export const NotesContainer = forwardRef(
  (
    {
      notes = [],
      handleUpdateSuccess,
      provider,
      facility,
      modalType,
      setModalType,
    },
    ref,
  ) => {
    const [activeEditNote, setActiveEditNote] = useState()
    const [authState] = useContext(AuthStateContext)

    const closeModal = async refresh => {
      if (refresh) {
        await handleUpdateSuccess()
      }

      setModalType()
      if (activeEditNote) {
        setActiveEditNote()
      }
    }

    const onClickAddNote = () => {
      setModalType(EDIT_TYPES.addNotes)
    }

    const onClickEditNote = note => {
      setActiveEditNote(note)
      setModalType(EDIT_TYPES.notes)
    }

    // sorted by last updated first
    const sortedNotes = notes.sort((a, b) =>
      dayjs(a.updated_at).isAfter(dayjs(b.updated_at)) ? -1 : 1,
    )

    return (
      <div ref={ref}>
        <NotesHeader>
          <Heading
            type={HEADING_TYPE.H2}
            s={p => ({
              color: p.theme.colors.aubergine2,
            })}
          >
            Notes
          </Heading>

          <PermissionRestricted level={2}>
            <Button onClick={onClickAddNote}>
              <PlusSVG height={16} width={16} style={{ marginRight: '12px' }} />
              <Label>Add Note</Label>
            </Button>
          </PermissionRestricted>
        </NotesHeader>

        {sortedNotes && sortedNotes.length > 0 ? (
          <ListContainer>
            {sortedNotes.map((note, index) => {
              const isLast = index === sortedNotes.length - 1
              const { fca_user_id, uuid } = note
              const isEditable = fca_user_id === authState.fcaUserId

              return (
                <div key={uuid}>
                  <NoteItem
                    note={note}
                    onClickEditNote={onClickEditNote}
                    isEditable={isEditable}
                  />
                  {!isLast && <Divider />}
                </div>
              )
            })}
          </ListContainer>
        ) : (
          <Label>There are no existing notes for this provider.</Label>
        )}

        {(modalType === EDIT_TYPES.addNotes ||
          modalType === EDIT_TYPES.notes) && (
          <NotesModal
            addNew={modalType === EDIT_TYPES.addNotes}
            notes={sortedNotes}
            note={activeEditNote}
            handleClose={closeModal}
            facility={facility}
            provider={provider}
          />
        )}
      </div>
    )
  },
)

NotesContainer.displayName = 'NotesContainer'

NotesContainer.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.object),
  onClickAddNote: PropTypes.func,
  handleUpdateSuccess: PropTypes.func,
  provider: PropTypes.object,
  facility: PropTypes.object,
  modalType: PropTypes.string,
  setModalType: PropTypes.func,
}
