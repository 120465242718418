import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, BUTTON_TYPE } from 'design-system/button'
import ChevronSVG from 'assets/chevron-down.svg'

const StyledButton = styled(Button)`
  position: absolute;
  right: 30px;
  bottom: 20px;
  z-index: 999;
  > button {
    padding: 0;
    width: 50px;
    height: 50px;
    > svg {
      transform: rotate(180deg); // up direction
      path {
        fill: white;
      }
    }
  }
  border-radius: 50%;
`

export const ScrollToTopBtn = ({ containerRef, minScroll = 500 }) => {
  const [show, setShow] = useState(false)

  // Show / Hide button based on Container scroll position and given minimum
  useEffect(() => {
    const handleScroll = event => {
      const { scrollTop } = event.target
      if (scrollTop >= minScroll && !show) {
        return setShow(true)
      }
      if (scrollTop < minScroll && show) {
        return setShow(false)
      }
    }
    if (containerRef && containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (containerRef && containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [show])

  const onClick = () => {
    if (containerRef && containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  if (!show) {
    return null
  }
  return (
    <StyledButton type={BUTTON_TYPE.PRIMARY} onClick={onClick}>
      <ChevronSVG />
    </StyledButton>
  )
}

ScrollToTopBtn.propTypes = {
  containerRef: PropTypes.any,
  minScroll: PropTypes.number,
}
