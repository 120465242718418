import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FacilitySearchInputs } from './facility-search-inputs'
import { ProviderSearchInputs } from './provider-search-inputs'
import {
  useGlobalContext,
  GLOBAL_CONTEXT_ACTIONS,
} from 'context/global-context-provider'
import { searchTypes } from 'utils/constants'
import { Flex } from 'design-system/flex'
import { focusNextInput } from 'utils/document'
import { Label } from 'design-system/label'
import { TabNavigation } from 'design-system/tab-navigation'
import InPersonFilledSVG from 'assets/in-person-filled.svg'
import InPersonOutlineSVG from 'assets/in-person-outline.svg'
import VirtualCareFilledSVG from 'assets/virtual-care-filled.svg'
import VirtualCareOutlineSVG from 'assets/virtual-care-outline.svg'
import { useAuthState } from 'context/auth-state-context'

const searchParamsObjectHash = {
  objectHash: obj => JSON.stringify(obj),
  arrays: {
    detectMove: false,
  },
}
const jsonDiff = require('jsondiffpatch')

const SearchHeaderContainer = styled(Flex)`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: ${p => p.theme.sizes.six};
`

const TABS = [
  {
    id: 'inPerson',
    title: 'In-Person',
    icon: {
      active: <InPersonFilledSVG width={16} height={16} />,
      inactive: <InPersonOutlineSVG width={16} height={16} />,
    },
  },
  {
    id: 'virtualCare',
    title: 'Virtual Care',
    icon: {
      active: <VirtualCareFilledSVG width={16} height={16} />,
      inactive: <VirtualCareOutlineSVG width={16} height={16} />,
    },
  },
]

export const SearchHeader = ({ performSearch }) => {
  const [authState] = useAuthState()
  const [searchState = {}, searchDispatch] = useGlobalContext()
  const [activeTab, setActiveTab] = useState({
    providers: searchState.providersSearchMode
      ? searchState.providersSearchMode
      : TABS[0].id,
    facilities: searchState.facilitiesSearchMode
      ? searchState.facilitiesSearchMode
      : TABS[0].id,
  })
  const onClickTab = selectedTab => {
    if (activeTab[searchState.searchType] !== selectedTab.id) {
      if (searchState.searchType == 'providers') {
        setActiveTab({ ...activeTab, providers: selectedTab.id })
        searchDispatch({
          key: `${searchState.searchType}SearchMode`,
          value: selectedTab.id,
        })
        if (searchState.location) {
          searchDispatch({
            type: GLOBAL_CONTEXT_ACTIONS.UPDATE_SEARCH_QUERY,
            payload: {
              ...searchState.providersSearchQuery,
              page: 1,
              hasTelehealth: selectedTab.id === 'virtualCare',
            },
          })
        }
      }
    }
  }
  const { modules } = authState
  const { modules: providerModules } = modules[searchTypes.providers] || {}
  const shouldDisplayTab =
    searchState.searchType == 'providers' &&
    searchState.providersSearchQuery &&
    providerModules.virtual_search?.enabled

  return (
    <>
      <SearchHeaderContainer id={'search-header'}>
        <Flex
          s={() => ({
            width: '100%',
            maxWidth: '73.75rem',
          })}
        >
          <Label
            s={p => ({
              fontWeight: '900',
              fontSize: p.theme.sizes.five,
            })}
          >
            {searchState.searchType === searchTypes.providers
              ? 'Search for Providers'
              : 'Search for Facilities'}
          </Label>
        </Flex>
        <Flex
          s={p => ({
            marginTop: p.theme.sizes.five,
            width: '100%',
            maxWidth: '73.75rem',
          })}
        >
          <SearchInputs performSearch={performSearch} small={false} />
        </Flex>
      </SearchHeaderContainer>
      {shouldDisplayTab && (
        <TabNavigation
          tabs={TABS}
          activeTab={activeTab[searchState.searchType]}
          onClickTab={onClickTab}
        />
      )}
    </>
  )
}

export const SearchInputs = ({ performSearch, small = false }) => {
  const [searchState = {}, dispatch = () => { }] = useGlobalContext()

  const onLocationChange = location => {
    dispatch({ location })
    if (location) {
      focusNextInput('insurances-input')
    }
  }

  const onInsuranceChange = insurance => {
    dispatch({ key: 'insurance', value: insurance })
    if (searchState.location && insurance) {
      if (searchState.searchType === searchTypes.providers) {
        focusNextInput('specialties-input')
      }
      if (searchState.searchType === searchTypes.facilities) {
        focusNextInput('type-input')
      }
    }
  }

  const onSpecialtiesChange = specialties => {
    dispatch({ specialties })
    if (searchState.location) {
      focusNextInput('search-btn')
    }
  }

  const onFacilityTypeChange = facilityType => {
    dispatch({ facilityType })
    if (searchState.location) {
      focusNextInput('search-btn')
    }
  }

  const onSearchClick = () => {
    if (!searchState.location) {
      focusNextInput('location-input')
      return
    }

    performSearch(1, true)
  }

  const searchHasChanges = checkSearchForChanges(searchState)

  return (
    <Flex
      s={p => ({
        flex: 1,
        width: '100%',
        maxWidth: '73.75rem',
        borderRadius: p.theme.sizes.thirteen,
      })}
    >
      {searchState.searchType === searchTypes.providers ? (
        <ProviderSearchInputs
          small={small}
          isSearchDisabled={false}
          onSearchClick={onSearchClick}
          onNameChange={name => dispatch({ key: 'providerName', value: name })}
          onInsuranceChange={onInsuranceChange}
          onLocationChange={onLocationChange}
          onSpecialtiesChange={onSpecialtiesChange}
          hasSearchChanges={searchHasChanges}
        />
      ) : (
        <FacilitySearchInputs
          small={small}
          isSearchDisabled={false}
          onSearchClick={onSearchClick}
          onNameChange={name => dispatch({ key: 'facilityName', value: name })}
          onInsuranceChange={onInsuranceChange}
          onLocationChange={onLocationChange}
          onFacilityTypeChange={onFacilityTypeChange}
          hasSearchChanges={searchHasChanges}
        />
      )}
    </Flex>
  )
}

const checkSearchForChanges = searchState => {
  const {
    providersSearchQuery,
    facilitiesSearchQuery,
    location,
    insurance,
    providerName,
    facilityName,
    facilityType,
    specialties,
    searchType,
  } = searchState

  if (searchType === searchTypes.providers) {
    if (providersSearchQuery) {
      if (!location) {
        return false
      }
      const {
        location: queryLocation,
        name: queryName,
        insurance: queryInsurance,
        specialties: querySpecialties,
      } = providersSearchQuery

      const newSearchQuery = {
        location,
        name: providerName,
        specialties,
        insurance,
      }

      const oldSearchQuery = {
        location: queryLocation,
        name: queryName,
        specialties: querySpecialties,
        insurance: queryInsurance,
      }

      const paramsDiff = jsonDiff
        .create(searchParamsObjectHash)
        .diff(newSearchQuery, oldSearchQuery)
      if (paramsDiff && location) {
        return true
      }
    } else {
      if (location) {
        return true
      }
    }
  }
  if (searchType === searchTypes.facilities) {
    if (facilitiesSearchQuery) {
      if (!location) {
        return false
      }
      const {
        location: queryLocation,
        name: queryName,
        insurance: queryInsurance,
        locationType: queryLocationType,
      } = facilitiesSearchQuery

      const newSearchQuery = {
        location,
        insurance,
        name: facilityName,
        locationType: facilityType,
      }

      const oldSearchQuery = {
        location: queryLocation,
        insurance: queryInsurance,
        name: queryName,
        locationType: queryLocationType,
      }

      const paramsDiff = jsonDiff
        .create(searchParamsObjectHash)
        .diff(newSearchQuery, oldSearchQuery)
      if (paramsDiff && location) {
        return true
      }
    } else {
      if (location) {
        return true
      }
    }
  }

  return false
}

SearchHeader.propTypes = {
  performSearch: PropTypes.func,
}
SearchInputs.propTypes = {
  performSearch: PropTypes.func,
  small: PropTypes.bool,
}
