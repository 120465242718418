import React from 'react'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Input, INPUT_TYPE } from 'design-system/input'
import { SelectInput } from 'design-system/input/select-input'
import {
  FIELD_TYPES_FLAT,
  FIELD_TYPES_FLAT_LOWER,
  OBJECT_FIELD_TYPES,
} from 'utils/constants'
import { Flex } from 'design-system/flex'
import { reducerTypes } from './add-new-field-reducer'
import styled from 'styled-components'
import { Block } from 'design-system/block'

export const DisplayFieldStep = ({
  dispatch = () => {},
  state = {},
  isEditMode = false,
}) => {
  const {
    list_type,
    field_type,
    boolean_display_config,
    object_display_config,
  } = state

  const onChangeBooleanConfig = e => {
    const { name, value } = e.target

    const newBooleanDisplayConfiguration = {
      ...boolean_display_config,
      [name]: {
        display: value,
      },
    }
    dispatch({
      type: reducerTypes.update,
      payload: {
        boolean_display_config: newBooleanDisplayConfiguration,
      },
    })
  }

  const onChangeObjectConfig = (index, e) => {
    const { name, value } = e.target

    if (index === undefined) {
      const newObjectConfig = {
        ...state.object_display_config,
        [name]: value,
      }
      dispatch({
        type: reducerTypes.update,
        payload: {
          object_display_config: newObjectConfig,
        },
      })
    } else {
      if (isEditMode && name !== 'display_name') {
        return
      }
      const newObjectConfig = {
        ...state.object_display_config,
      }
      newObjectConfig.keys[index][name] = value

      dispatch({
        type: reducerTypes.update,
        payload: {
          object_display_config: newObjectConfig,
        },
      })
    }
  }

  const onClickAddNewKeyPair = () => {
    dispatch({
      type: reducerTypes.add_object_key,
    })
  }

  const onClickRemoveKeyPair = index => {
    dispatch({
      type: reducerTypes.remove_object_key,
      payload: {
        index,
      },
    })
  }

  const renderBooleanConfig = () => {
    if (!boolean_display_config) {
      return <></>
    }
    const { True, False } = boolean_display_config
    return (
      <>
        <Flex
          s={p => ({
            justifyContent: 'space-between',
            marginTop: p.theme.sizes.four,
          })}
        >
          <InputContainer>
            <Label type={LABEL_TYPE.BODY1_BOLD}>Value</Label>
            <Input
              s={p => ({ marginTop: p.theme.sizes.two })}
              name="display_value"
              value={[{ label: 'True' }]}
              showClearIcon={false}
              type={INPUT_TYPE.ROUND}
              placeholder="Value"
            />
          </InputContainer>
          <InputContainer>
            <Label type={LABEL_TYPE.BODY1_BOLD}>Display Text</Label>
            <Input
              s={p => ({ marginTop: p.theme.sizes.two })}
              onInputChange={onChangeBooleanConfig}
              name="True"
              value={[{ label: True.display || '' }]}
              showClearIcon={false}
              type={INPUT_TYPE.ROUND}
              placeholder="Enter display text"
            />
          </InputContainer>
        </Flex>
        <Flex
          s={p => ({
            justifyContent: 'space-between',
            marginTop: p.theme.sizes.four,
          })}
        >
          <InputContainer>
            <Label type={LABEL_TYPE.BODY1_BOLD}>Value</Label>
            <Input
              s={p => ({ marginTop: p.theme.sizes.two })}
              name="displayValue"
              value={[{ label: 'False' }]}
              showClearIcon={false}
              type={INPUT_TYPE.ROUND}
              placeholder="Value"
            />
          </InputContainer>
          <InputContainer>
            <Label type={LABEL_TYPE.BODY1_BOLD}>Display Text</Label>
            <Input
              s={p => ({ marginTop: p.theme.sizes.two })}
              onInputChange={onChangeBooleanConfig}
              name="False"
              value={[{ label: False.display || '' }]}
              showClearIcon={false}
              type={INPUT_TYPE.ROUND}
              placeholder="Enter display text"
            />
          </InputContainer>
        </Flex>
      </>
    )
  }

  const renderObjectConfig = () => {
    if (!object_display_config) {
      return <></>
    }
    const { display_name, keys } = object_display_config
    return (
      <>
        <Flex
          s={p => ({
            justifyContent: 'space-between',
            marginTop: p.theme.sizes.four,
          })}
        >
          <InputContainer>
            <Label type={LABEL_TYPE.BODY1_BOLD}>Object Display Name</Label>
            <Input
              s={p => ({ marginTop: p.theme.sizes.two })}
              onInputChange={e => onChangeObjectConfig(undefined, e)}
              name="display_name"
              value={[{ label: display_name }]}
              showClearIcon={false}
              type={INPUT_TYPE.ROUND}
              placeholder="Enter display name"
            />
          </InputContainer>
          <InputContainer></InputContainer>
        </Flex>
        <Divider />
        {keys.map((key, index) => {
          const { name, type, display_name } = key
          return (
            <Flex
              s={() => ({
                flexDirection: 'column',
              })}
              key={index}
            >
              <Flex
                s={p => ({
                  justifyContent: 'space-between',
                  marginTop: p.theme.sizes.four,
                })}
              >
                <InputContainer>
                  <Label type={LABEL_TYPE.BODY1_BOLD}>Key Name</Label>
                  <Input
                    s={p => ({ marginTop: p.theme.sizes.two })}
                    onInputChange={e => onChangeObjectConfig(index, e)}
                    name="name"
                    value={[{ label: name }]}
                    showClearIcon={false}
                    type={INPUT_TYPE.ROUND}
                    placeholder="Key Name"
                    disabled={isEditMode}
                  />
                </InputContainer>
                <InputContainer>
                  <Label type={LABEL_TYPE.BODY1_BOLD}>Value Type</Label>
                  <SelectInput
                    onChange={e => onChangeObjectConfig(index, e)}
                    name="type"
                    value={type ? FIELD_TYPES_FLAT[type] : ''}
                    options={OBJECT_FIELD_TYPES}
                    disabled={isEditMode}
                    containerStyle={{ marginTop: '0.5em' }}
                    placeholder="Select value type"
                  />
                </InputContainer>
              </Flex>
              <Flex
                s={p => ({
                  justifyContent: 'space-between',
                  marginTop: p.theme.sizes.four,
                })}
              >
                <InputContainer>
                  <Label type={LABEL_TYPE.BODY1_BOLD}>Display Name</Label>
                  <Input
                    s={p => ({ marginTop: p.theme.sizes.two })}
                    onInputChange={e => onChangeObjectConfig(index, e)}
                    name="display_name"
                    value={[{ label: display_name }]}
                    showClearIcon={false}
                    type={INPUT_TYPE.ROUND}
                    placeholder="Display Name"
                  />
                </InputContainer>
                <InputContainer></InputContainer>
              </Flex>
              <Flex
                s={p => ({
                  marginTop: p.theme.sizes.four,
                })}
              >
                {!isEditMode && keys.length !== 1 && (
                  <Button
                    s={p => ({
                      width: 'max-content',
                      ['> button']: {
                        paddingLeft: p.theme.sizes.three,
                        paddingRight: p.theme.sizes.three,
                        fontWeight: '500',
                      },
                    })}
                    onClick={() => onClickRemoveKeyPair(index)}
                    type={BUTTON_TYPE.LINK}
                  >
                    - Remove key
                  </Button>
                )}
              </Flex>

              <Divider />
            </Flex>
          )
        })}
        {!isEditMode && (
          <Button
            s={p => ({
              width: 'max-content',
              ['> button']: {
                paddingLeft: p.theme.sizes.three,
                paddingRight: p.theme.sizes.three,
                fontWeight: '500',
              },
            })}
            onClick={onClickAddNewKeyPair}
            type={BUTTON_TYPE.LINK}
          >
            + Add another key
          </Button>
        )}
      </>
    )
  }

  const renderConfig = () => {
    switch (field_type.toLowerCase()) {
      case FIELD_TYPES_FLAT_LOWER.boolean:
        return renderBooleanConfig()
      case FIELD_TYPES_FLAT_LOWER.object:
        return renderObjectConfig()
      case FIELD_TYPES_FLAT_LOWER.list: {
        if (list_type.toLowerCase() === FIELD_TYPES_FLAT_LOWER.object) {
          return renderObjectConfig()
        }
        if (list_type.toLowerCase() === FIELD_TYPES_FLAT_LOWER.boolean) {
          return renderBooleanConfig()
        }

        return <></>
      }

      default:
        return <></>
    }
  }

  return <FormContainer>{renderConfig()}</FormContainer>
}

const InputContainer = styled.div`
  flex: 0.48;
`

const Divider = styled.div`
  height: 1px;
  min-height: 1px;
  width: 100%;
  background-color: ${p => p.theme.colors.gray4};
  margin: ${p => p.theme.sizes.six} 0;
`

const FormContainer = styled(Block)`
  padding: 0 ${p => p.theme.sizes.four};
`
