import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { SearchResultPanel } from 'components/search-result-panel/search-result-panel.component'
import { Block } from 'design-system/block'
import {
  GlobalStateContext,
  GLOBAL_CONTEXT_ACTIONS,
} from 'context/global-context-provider'
import { formatInsuranceLabel } from 'utils/formatters/format-insurance-labels'
import { SearchNoResults } from './search-no-results'
import { Flex } from 'design-system/flex'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { searchTypes } from 'utils/constants'
import { formatProviderSubtitle } from 'utils/formatters/format-provider-subtitle'

export const ProviderSearchResultsPanel = ({
  results,
  onHover,
  resultsMetadata,
}) => {
  const [searchState, updateFilters] = useContext(GlobalStateContext)
  const { filters } = searchState
  const providerFilters = filters ? filters[searchTypes.providers] : {}
  const { showInaccurate } = providerFilters
  const [expandResultsDismissed, setExpandResultsDismissed] = useState(false)

  useEffect(() => {
    // Should check to reset on all results changes
    // in case a user triggers expands results box multiple times in a row
    if (results.length === 0) {
      setExpandResultsDismissed(false)
    }
  }, [results])

  const onClickExpandSearch = () => {
    updateFilters({
      type: GLOBAL_CONTEXT_ACTIONS.UPDATE_FILTERS,
      payload: { key: 'showInaccurate', value: true },
    })
  }

  if (
    searchState.providerName &&
    results.length === 0 &&
    !showInaccurate &&
    !expandResultsDismissed
  ) {
    return (
      <ExpandSearchResults
        onClickDismiss={() => setExpandResultsDismissed(true)}
        onClickExpandSearch={onClickExpandSearch}
      />
    )
  }

  if (results.length === 0) {
    return <SearchNoResults />
  }

  return (
    <>
      {results.map(result => {
        const {
          npi,
          firstName,
          lastName,
          distance,
          ratingsCount = 0,
          ratingsAvg,
          locations = [],
          preference,
          notes,
        } = result
        const locationRes = locations[0] || {}
        return (
          <Block
            key={`provider-result-${npi}`}
            s={p => ({
              padding: `${p.theme.sizes.two} 0`,
            })}
          >
            <SearchResultPanel
              id={npi}
              type={'providers'}
              title={`${firstName} ${lastName}`}
              subtitle={formatProviderSubtitle(resultsMetadata, result)}
              address={locationRes.addressDetails}
              locationName={locationRes.name}
              distance={distance}
              phone={
                (locationRes.phoneNumbers &&
                  locationRes.phoneNumbers.length > 0 &&
                  locationRes.phoneNumbers[0].phone) ||
                undefined
              }
              score={ratingsCount > 0 ? ratingsAvg / 2 : undefined}
              showInsuranceLabel={formatInsuranceLabel(
                searchState.providersSearchQuery,
                locationRes,
              )}
              onHover={onHover}
              confidence={locationRes.confidence}
              result={result}
              preference={preference}
              hasNotes={notes && notes.length > 0}
              showHighConfidence={Boolean(showInaccurate) === false}
            />
          </Block>
        )
      })}
    </>
  )
}

ProviderSearchResultsPanel.propTypes = {
  onHover: PropTypes.func,
  results: PropTypes.arrayOf(PropTypes.object),
  resultsMetadata: PropTypes.object,
}

const ExpandSearchResults = ({ onClickDismiss, onClickExpandSearch }) => {
  return (
    <Flex
      s={p => ({
        flexDirection: 'column',
        padding: p.theme.sizes.four,
        marginTop: p.theme.sizes.four,
        alignItems: 'flex-start',
        border: `1px solid ${p.theme.colors.borderLight}`,
        backgroundColor: `rgba(67, 45, 78, 0.06)`,
        borderRadius: '4px',
      })}
    >
      <Label type={LABEL_TYPE.BODY1_BOLD}>Expand Search Results</Label>
      <Label
        s={p => ({
          marginTop: p.theme.sizes.three,
        })}
      >
        Enable Lower Confidence Locations to include information on providers
        and locations that Ribbon is less confident in.
      </Label>

      <Flex
        s={p => ({
          marginTop: p.theme.sizes.five,
          justifyContent: 'flex-end',
          alignItems: 'center',
          alignSelf: 'flex-end',
        })}
      >
        <Button
          type={BUTTON_TYPE.LINK}
          onClick={onClickDismiss}
          s={p => ({
            marginRight: p.theme.sizes.two,
            ['> button']: {
              color: p.theme.colors.textLightest,
            },
          })}
        >
          Dismiss
        </Button>
        <Button
          type={BUTTON_TYPE.PRIMARY}
          onClick={onClickExpandSearch}
          data-testid="expand-search-btn"
        >
          Expand Search
        </Button>
      </Flex>
    </Flex>
  )
}

ExpandSearchResults.propTypes = {
  onClickDismiss: PropTypes.func,
  onClickExpandSearch: PropTypes.func,
}
