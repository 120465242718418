import React from 'react'
import styled from 'styled-components'

import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'

const StyledContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  flex: 1;
  background-color: white;
  align-items: center;
`

export const PageError = () => {
  return (
    <StyledContainer>
      <Flex
        s={p => ({
          flexDirection: 'column',
          padding: p.theme.sizes.eight,
          border: `1px solid ${p.theme.colors.gray1}`,
          borderRadius: p.theme.sizes.four,
          maxWidth: '500px',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '200px',
          textAlign: 'center',
        })}
      >
        <Label s={p => ({
          fontSize: p.theme.sizes.nine,
          fontWeight: 800,
          color: p.theme.colors.aubergine,
        })}>
          Oops!
        </Label>
        <Label s={p => ({
          fontSize: p.theme.sizes.six,
          fontWeight: 800,
          color: p.theme.colors.aubergine,
          marginTop: p.theme.sizes.three,
        })}>
          Something went wrong.
        </Label>
        <Label s={p => ({
          marginTop: p.theme.sizes.six,
          color: p.theme.colors.gray2,
        })}>
          If this error persists, please contact our support team at support@ribbonhealth.com.
        </Label>
      </Flex>
    </StyledContainer>
  )
}
