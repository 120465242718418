import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import NotesSVG from 'assets/notes.svg'
import PlusSVG from 'assets/plus.svg'
import { PermissionRestricted } from 'components/permission-restricted/permission-restricted'
import { Button, BUTTON_TYPE } from 'design-system/button'

const Container = styled(Flex)`
  align-items: center;
  > p {
    margin-right: ${p => p.theme.sizes.two};
    margin-left: ${p => p.theme.sizes.one};
    font-weight: 500;
  }
`

export const NoteLabel = ({
  notesLength = 0,
  onClickAddNote,
  onClickLabel,
}) => {
  return (
    <Container>
      <NotesSVG />
      <Label onClick={onClickLabel}>{`Notes (${notesLength})`}</Label>
      <PermissionRestricted level={2}>
        <Button
          s={() => ({
            ['> button']: {
              padding: 0,
              height: '18px',
              width: '18px',
            },
            borderRadius: '50%',
          })}
          type={BUTTON_TYPE.DEFAULT}
          onClick={onClickAddNote}
          data-testid="add-note-btn"
        >
          <PlusSVG height={12} width={12} />
        </Button>
      </PermissionRestricted>
    </Container>
  )
}

NoteLabel.propTypes = {
  notesLength: PropTypes.number,
  onClickAddNote: PropTypes.func,
  onClickLabel: PropTypes.func,
}
