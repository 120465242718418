import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'design-system/flex'
import styled from 'styled-components'
import { Label } from 'design-system/label'
import CloseSVG from 'assets/close-x.svg'
import CheckCircleSVG from 'assets/check-circle.svg'
import { Button } from 'design-system/button'
import { Spinner } from 'design-system/spinner'
import { SearchResultsWindow } from './insurance-search-results'
import { useInsuranceCarriersQuery } from './useInsuranceCarriersQuery'
import { useInsurancePlansByCarriersQuery } from './useInsurancePlansByCarrierQuery'
import { Input, INPUT_SIZE, INPUT_TYPE } from 'design-system/input'
import { focusNextInput } from 'utils/document'
import {formatDisplayNameByState} from 'utils/insurances'

const Header = styled(Flex)`
  flex: 1;
  height: 50px;
  max-height: 50px;
  border: 1px solid ${p => p.theme.colors.borderLight};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: none;
  overflow: hidden;
  background-color: ${p => p.theme.colors.white};
`

const HeaderTab = styled(Flex)`
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;

  > p {
    font-weight: 500;
    color: ${p => !p.onClick && p.theme.colors.gray3};
  }

  ${p => ({
    ...(p.isActive
      ? {
          flex: 1,
          backgroundColor: p.theme.colors.aubergine,
          color: 'white',
        }
      : {
          [':hover']: {
            backgroundColor: p.onClick
              ? p.theme.colors.gray7
              : p.theme.colors.white,
            ['> #right-arrow']: {
              backgroundColor: p.theme.colors.gray7,
            },
          },
        }),
    ...(p.isSelected && {
      backgroundColor: p.theme.colors.gray6,
    }),
  })}
`

const ResultsContainer = styled(Flex)`
  flex: 1;
  flex-direction: column;
  border-top: 1px solid ${p => p.theme.colors.borderLight};
  overflow-y: hidden;
`

const ClearBtnContainer = styled(Button)`
  > button {
    padding: ${p => p.theme.sizes.two};
    max-height: 32px;
  }
  border-radius: 4px;
  border-color: transparent;
  margin-right: ${p => p.theme.sizes.four};
`

const NumberContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: ${p => p.theme.colors.aubergine3};
  margin-right: 8px;
`

const ResultLabel = styled(Label)`
  padding: ${p => p.theme.sizes.three} ${p => p.theme.sizes.four};
  ${p => ({
    ...(p.isSelected
      ? {
          fontWeight: 'bold',
          backgroundColor: p.theme.colors.gray6,
        }
      : {
          [':hover']: {
            backgroundColor: p.onClick
              ? p.theme.colors.gray6
              : p.theme.colors.white,
          },
        }),
    ...(p.isActive && {
      backgroundColor: p.theme.colors.gray6,
    }),
  })}
`

const ArrowPolygon = styled.div`
  position: absolute;
  right: -25px;
  top: 0;
  background-color: ${p =>
    p.isActive ? p.theme.colors.aubergine : p.theme.colors.white};
  width: 50px;
  height: 100%;
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  z-index: 99;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  ${p => ({
    ...(p.isActive
      ? {
          flex: 1,
          backgroundColor: p.theme.colors.aubergine,
          color: 'white',
        }
      : {
          [':hover']: {
            backgroundColor: p.onClick
              ? p.theme.colors.gray7
              : p.theme.colors.white,
          },
        }),
  })}
`

const CircleCheck = styled(CheckCircleSVG)`
  margin-right: 8px;
  path {
    stroke: ${p => p.theme.colors.aubergine};
  }
`

const PlanSearchInput = styled(Input)`
  margin-top: ${p => p.theme.sizes.three};
  margin-left: ${p => p.theme.sizes.three};
  margin-right: ${p => p.theme.sizes.three};
`

export const InsuranceDropdown = ({
  onSelectOption,
  isManualSearch,
  searchResults,
  selectedCarrier,
  setSelectedCarrier,
}) => {
  const [activeTab, setActiveTab] = useState(0)
  const showManualSearch = isManualSearch && !selectedCarrier
  const [planSearch, setPlanSearch] = useState('')

  const { results: carriers } = useInsuranceCarriersQuery()
  const {
    results: planResults,
    isLoading: plansLoading,
  } = useInsurancePlansByCarriersQuery(
    selectedCarrier ? selectedCarrier.displayName : '',
    planSearch,
  )

  // any carrier selection changes should show choose plan tab option
  // and reset any plan searching
  useEffect(() => {
    if (selectedCarrier) {
      setActiveTab(1)
      setPlanSearch('')
    } else {
      setActiveTab(0)
    }
  }, [selectedCarrier])

  // after tab switches to choose plan
  // focus search input and clear
  useEffect(() => {
    // Choose Plan tab
    if (activeTab === 1) {
      focusNextInput('plan-search-input')
    }
  }, [activeTab])

  const onChangePlanSearchInput = e => {
    const { value } = e.target
    setPlanSearch(value)
  }

  const onClickCarrier = carrier => {
    if (carrier && selectedCarrier && carrier.uuid === selectedCarrier.uuid) {
      setActiveTab(1)
      return
    }
    setSelectedCarrier(carrier)
    // Fetch Plans by Carrier selected
  }

  const onClickPlan = plan => {
    onSelectOption(plan)
  }

  const renderCarriers = () => {
    return (
      <ResultsContainer>
        <SearchResultsWindow
          results={carriers}
          onClick={onClickCarrier}
          selectedResult={selectedCarrier}
        />
      </ResultsContainer>
    )
  }
  const renderCarrierPlans = () => {
    return (
      <>
        <ResultsContainer>
          <PlanSearchInput
            id="plan-search-input"
            placeholder="Search for plan"
            value={[{ label: planSearch }]}
            onInputChange={onChangePlanSearchInput}
            onChange={() => setPlanSearch('')}
            showClearIcon
            type={INPUT_TYPE.ROUND}
            size={INPUT_SIZE.EXTRA_LARGE}
          />
          {selectedCarrier && (
            <Flex
              s={() => ({
                justifyContent: 'space-between',
                alignItems: 'center',
              })}
            >
              <ResultLabel
                s={() => ({
                  fontWeight: 'bold',
                })}
              >
                {selectedCarrier.displayName}
              </ResultLabel>
              <ClearBtnContainer
                onClick={() => setSelectedCarrier()}
                data-testid="remove-carrier"
              >
                <CloseSVG height={16} width={16} />
              </ClearBtnContainer>
            </Flex>
          )}
          {plansLoading && planSearch.length === 0 ? (
            <Flex
              s={() => ({
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              })}
            >
              <Spinner />
            </Flex>
          ) : (
            <SearchResultsWindow
              results={planResults}
              onClick={onClickPlan}
              selectedResult={null}
              renderLabel={result => formatDisplayNameByState(result)}
            />
          )}
        </ResultsContainer>
      </>
    )
  }

  const onClickManualSearchOption = option => {
    if (!option) {
      return
    }

    if (option.planName) {
      onClickPlan(option)
    }
    if (option.isCarrier) {
      onClickCarrier(option)
    }
  }

  return (
    <>
      {!showManualSearch && (
        <>
          <Header>
            <HeaderTab
              isActive={activeTab === 0}
              onClick={() => setActiveTab(0)}
            >
              {activeTab === 0 && (
                <NumberContainer>
                  <Label>1</Label>
                </NumberContainer>
              )}
              {activeTab === 1 && <CircleCheck />}
              <Label>{`${activeTab === 0 ? 'Choose ' : ''}Carrier`}</Label>
              <ArrowPolygon
                isActive={activeTab === 0}
                onClick={() => setActiveTab(0)}
                id="right-arrow"
              />
            </HeaderTab>

            <HeaderTab
              isActive={activeTab === 1}
              onClick={selectedCarrier ? () => setActiveTab(1) : null}
            >
              {activeTab === 1 && (
                <NumberContainer>
                  <Label>2</Label>
                </NumberContainer>
              )}
              <Label>{`${activeTab === 1 ? 'Choose ' : ''}Plan`}</Label>
            </HeaderTab>
          </Header>
          {activeTab === 0 ? renderCarriers() : renderCarrierPlans()}
        </>
      )}
      {showManualSearch && (
        <>
          <SearchResultsWindow
            results={searchResults}
            onClick={onClickManualSearchOption}
            selectedResult={null}
          />
        </>
      )}
    </>
  )
}

InsuranceDropdown.propTypes = {
  onSelectOption: PropTypes.func,
  isManualSearch: PropTypes.bool,
  searchResults: PropTypes.array,
  selectedCarrier: PropTypes.object,
  setSelectedCarrier: PropTypes.func,
}
