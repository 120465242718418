import React, { useState } from 'react'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { Checkbox } from 'design-system/checkbox'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import styled from 'styled-components'

const CheckboxContainer = styled(Flex)`
  cursor: pointer;
  align-items: center;
  margin-bottom: ${p => p.theme.sizes.four};
`

const ALL_STATUSES = ['200', '400', '500']

export const StatusFilterDropdown = ({
  onClickApplyFilter = () => {},
  onClickClearFilter = () => {},
  statusFilter = null,
}) => {
  const [selectedStatuses, setSelectedStatuses] = useState(
    statusFilter ? statusFilter.value : [],
  )

  const handleCheck = label => {
    setSelectedStatuses(prev => {
      const prevCopy = prev.slice()
      const foundLabelIndex = prevCopy.findIndex(i => i === label)
      if (foundLabelIndex > -1) {
        prevCopy.splice(foundLabelIndex, 1)
      } else {
        prevCopy.push(label)
      }
      return prevCopy
    })
  }
  return (
    <Flex
      s={p => ({
        flexDirection: 'column',
        padding: `0 ${p.theme.sizes.five}`,
      })}
    >
      {ALL_STATUSES.map(status => {
        return (
          <CheckboxContainer key={status} onClick={() => handleCheck(status)}>
            <Checkbox
              s={() => ({ marginRight: '8px' })}
              value={selectedStatuses.includes(status)}
            />
            <Label s={p => ({ color: p.theme.colors.gray1 })}>{status}</Label>
          </CheckboxContainer>
        )
      })}

      <Flex>
        <Button
          onClick={() => onClickApplyFilter('status', selectedStatuses)}
          type={
            !selectedStatuses || selectedStatuses.length === 0
              ? BUTTON_TYPE.DISABLED
              : BUTTON_TYPE.PRIMARY
          }
          disabled={!selectedStatuses || selectedStatuses.length === 0}
        >
          Apply
        </Button>
        <Button
          onClick={() => onClickClearFilter('status')}
          type={BUTTON_TYPE.LINK}
        >
          Clear
        </Button>
      </Flex>
    </Flex>
  )
}
