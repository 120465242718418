import React from 'react'
import styled from 'styled-components'
import { Block } from 'design-system/block'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Input, INPUT_TYPE } from 'design-system/input'
import { SelectInput } from 'design-system/input/select-input'
import { Checkbox } from 'design-system/checkbox'
import {
  FIELD_TYPES,
  FIELD_TYPES_FLAT,
  FIELD_TYPES_FLAT_LOWER,
  LIST_FIELD_TYPES,
} from 'utils/constants'
import { RadioOption } from 'design-system/radio-option/radio-option'
import { Flex } from 'design-system/flex'
import { isInteger } from 'utils/validations'

export const NewFieldStep = ({
  state = {},
  onChangeText = () => {},
  onChangeType = () => {},
  onChangeCheckbox = () => {},
  isEditMode = false,
}) => {
  const {
    type,
    parameter_name,
    display_name,
    field_type,
    list_type,
    default_value,
    createFilter,
  } = state

  return (
    <FormContainer>
      {(isEditMode
        ? ['Providers', 'Facilities'].filter(v => v.toLowerCase() === type)
        : ['Providers', 'Facilities']
      ).map((item, index) => {
        return (
          <RadioOption
            s={p => ({
              marginBottom: index === 0 ? p.theme.sizes.five : 0,
            })}
            key={item}
            checked={type === item.toLowerCase()}
            label={item}
            onClick={() => onChangeType(item.toLowerCase())}
          />
        )
      })}

      <Divider />

      <Flex
        s={() => ({
          justifyContent: 'space-between',
        })}
      >
        <InputContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Field display name</Label>
          <Input
            s={p => ({ marginTop: p.theme.sizes.two })}
            onInputChange={onChangeText}
            name="display_name"
            value={[{ label: display_name }]}
            showClearIcon={false}
            type={INPUT_TYPE.ROUND}
            placeholder="Enter display name"
          />
        </InputContainer>
        <InputContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Parameter name</Label>
          <Input
            s={p => ({ marginTop: p.theme.sizes.two })}
            onInputChange={onChangeText}
            name="parameter_name"
            value={[{ label: parameter_name }]}
            showClearIcon={false}
            type={INPUT_TYPE.ROUND}
            placeholder="Enter parameter name"
            disabled={isEditMode}
          />
        </InputContainer>
      </Flex>

      <Divider />

      <Flex
        s={() => ({
          justifyContent: 'space-between',
        })}
      >
        <InputContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Field Type</Label>
          <SelectInput
            onChange={onChangeText}
            name="field_type"
            value={field_type ? FIELD_TYPES_FLAT[field_type] : ''}
            options={FIELD_TYPES}
            disabled={isEditMode}
            containerStyle={{ marginTop: '0.5em' }}
            placeholder="Select field type"
          />
        </InputContainer>
        {field_type ? (
          <DefaultValueByFieldType
            fieldType={field_type ? field_type : ''}
            defaultValue={default_value}
            onChangeText={onChangeText}
          />
        ) : (
          <></>
        )}
        {field_type &&
          field_type.toLowerCase() === FIELD_TYPES_FLAT_LOWER.list && (
            <InputContainer>
              <Label type={LABEL_TYPE.BODY1_BOLD}>List Type</Label>
              <SelectInput
                onChange={e => onChangeText(e)}
                name="list_type"
                value={list_type ? FIELD_TYPES_FLAT[list_type] : ''}
                options={LIST_FIELD_TYPES}
                disabled={isEditMode}
                containerStyle={{ marginTop: '0.5em' }}
                placeholder="Select type"
              />
            </InputContainer>
          )}
      </Flex>
      {!isEditMode && (
        <>
          <Divider />

          <Flex
            s={p => ({
              justifyContent: 'space-between',
              marginTop: p.theme.sizes.four,
            })}
          >
            <CheckboxContainer
              onClick={() => onChangeCheckbox('createFilter', !createFilter)}
            >
              <Checkbox
                s={() => ({ marginRight: '8px' })}
                value={createFilter}
              />
              <Label s={p => ({ color: p.theme.colors.gray1 })}>
                Create a filter for this field
              </Label>
            </CheckboxContainer>
          </Flex>
        </>
      )}
    </FormContainer>
  )
}

const DefaultValueByFieldType = ({
  fieldType = '',
  onChangeText = () => {},
  defaultValue = '',
}) => {
  let error = null
  if (fieldType === FIELD_TYPES_FLAT_LOWER.integer) {
    // empty string check on value
    if (defaultValue && !isInteger(defaultValue)) {
      error = 'Please enter a valid integer'
    }
  }
  if (fieldType === FIELD_TYPES_FLAT_LOWER.float) {
    // empty string check on value
    if (defaultValue && Number.isNaN(defaultValue)) {
      error = 'Please enter a valid float value'
    }
  }

  const renderInputByFieldType = () => {
    if (!fieldType) {
      return <></>
    }
    switch (fieldType.toLowerCase()) {
      case FIELD_TYPES_FLAT_LOWER.string:
      case FIELD_TYPES_FLAT_LOWER.float:
      case FIELD_TYPES_FLAT_LOWER.integer:
        return (
          <Input
            s={p => ({ marginTop: p.theme.sizes.two })}
            onInputChange={onChangeText}
            name="default_value"
            value={[{ label: defaultValue || '' }]}
            showClearIcon={false}
            type={INPUT_TYPE.ROUND}
            placeholder="Enter suggested value"
            inputType={
              fieldType === FIELD_TYPES_FLAT_LOWER.integer ||
              fieldType === FIELD_TYPES_FLAT_LOWER.float
                ? 'number'
                : 'text'
            }
            step={fieldType === FIELD_TYPES_FLAT_LOWER.float ? '0.1' : '1'}
          />
        )
      default:
        return <></>
    }
  }

  if (
    fieldType.toLowerCase() === FIELD_TYPES_FLAT_LOWER.object ||
    fieldType.toLowerCase() === FIELD_TYPES_FLAT_LOWER.boolean ||
    fieldType.toLowerCase() === FIELD_TYPES_FLAT_LOWER.date
  ) {
    return <InputContainer />
  }

  if (fieldType.toLowerCase() === FIELD_TYPES_FLAT_LOWER.list) {
    return <></>
  }

  return (
    <InputContainer>
      <Label type={LABEL_TYPE.BODY1_BOLD}>Suggested Value(Optional)</Label>
      {renderInputByFieldType()}
      {error && (
        <Label
          s={p => ({
            marginTop: p.theme.sizes.two,
            marginLeft: p.theme.sizes.four,
            color: p.theme.colors.errorRed,
            fontWeight: '400',
          })}
        >
          {error}
        </Label>
      )}
    </InputContainer>
  )
}

const CheckboxContainer = styled(Flex)`
  cursor: pointer;
  align-items: center;
  margin-bottom: ${p => p.theme.sizes.four};
`
const InputContainer = styled.div`
  flex: 0.48;
`

const Divider = styled.div`
  height: 1px;
  min-height: 1px;
  width: 100%;
  background-color: ${p => p.theme.colors.gray4};
  margin: ${p => p.theme.sizes.six} 0;
`

const FormContainer = styled(Block)`
  padding: 0 ${p => p.theme.sizes.four};
`
