import React from 'react'
import PropTypes from 'prop-types'
import { Block } from 'design-system/block'
import styled from 'styled-components'
import { Label } from 'design-system/label'
import { colors } from 'theme/colors'

const Cost = styled(Label)`
  font-weight: 600;
  color: ${colors.gray4};
  ${p => ({
    ...(p.filled && {
      color: p.theme.colors.gray1,
    }),
  })};
`

export const CostIndicators = ({ total = 0, showEmpty = true }) => {
  return (
    <Block
      type="FLEX"
      s={() => ({
        padding: 0,
        justifyContent: 'center',
      })}
      data-testid={`cost-indicators-${total}`}
    >
      {[1, 2, 3, 4, 5].map(n => {
        const filled = n <= total
        if (!showEmpty && !filled) return null
        return (
          <Cost key={n} filled={n <= total}>
            $
          </Cost>
        )
      })}
    </Block>
  )
}

CostIndicators.propTypes = {
  total: PropTypes.number,
  showEmpty: PropTypes.bool,
}
