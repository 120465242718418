import { searchAlgoliaSpecialties } from 'services/algolia'
import { useNotyf } from 'context/notyf-context'
import { useAuthState } from 'context/auth-state-context'
import { useQuery } from 'react-query'

const searchQueryKeys = {
  specialtiesSearch: inputValue => ['specialtiesSearch', inputValue],
}

export const useSpecialtiesSearchQuery = ({ inputValue }) => {
  const [authState] = useAuthState()
  const {
    refTypeaheadId: appId,
    refTypeaheadKey: apiKey,
    refTypeaheadName: appName,
    userId,
  } = authState

  const notyf = useNotyf()
  return useQuery(
    searchQueryKeys.specialtiesSearch(inputValue),
    () =>
      searchAlgoliaSpecialties({
        appId: appId,
        apiKey: apiKey,
        appName: appName,
        inputValue: inputValue,
        userId,
      }),
    {
      enabled: inputValue.length > 0,
      onError: error => {
        notyf.error(error.message || 'An error occurred searching specialties.')
      },
    },
  )
}
