import React from 'react'
import { MultiSelectFilter } from 'components/filters/multi-select-filter/multi-select-filter'
import { SelectFilter } from 'components/filters/select-filter/select-filter'
import { RadioOption } from 'design-system/radio-option/radio-option'
import { filterDisplayTypeByFieldSelected } from 'utils/filters'

export const CustomFilter = ({
  filter = {
    api_parameter: '',
    filter_config: { title: '', field_type: '', filter_type: '', options: [] },
  },
  onChange = () => {},
  activeFilters = {},
}) => {
  const { filter_config, api_parameter } = filter
  const { field_type } = filter_config
  const activeFilter = activeFilters[api_parameter]
  const displayType = filterDisplayTypeByFieldSelected(field_type)

  if (displayType === 'single-select') {
    return (
      <SelectFilter
        label={filter_config.title || '{{Field Title}}'}
        onChange={option =>
          onChange({
            key: api_parameter,
            value: {
              value: option.value,
              display: option.label,
              is_custom: true,
              field_type,
            },
          })
        }
        selected={null}
        options={
          filter_config.options
            ? filter_config.options.map(item => ({
                label: item.display,
                value: item.value,
              }))
            : []
        }
        renderOption={option => (
          <RadioOption
            checked={activeFilter && activeFilter.value === option.value}
            label={option.label || '{{Option Display}}'}
          />
        )}
      />
    )
  }
  if (displayType === 'single' || displayType === 'multi-select') {
    return (
      <MultiSelectFilter
        label={filter_config.title || '{{Field Title}}'}
        onChange={value => {
          console.log(`🚀 => value`, value)
          let filtersCopy = activeFilter ? [...activeFilter.values] : []

          console.log(`🚀 => filtersCopy`, filtersCopy)
          if (filtersCopy) {
            const foundOption = filtersCopy.find(option => option === value)
            if (foundOption) {
              filtersCopy = filtersCopy.filter(option => option !== value)
            } else {
              filtersCopy.push(value)
            }
          }

          onChange({
            key: api_parameter,
            value:
              filtersCopy.length > 0
                ? { values: filtersCopy, is_custom: true, field_type }
                : undefined,
          })
        }}
        selectedOptions={activeFilter ? activeFilter.values : []}
        options={
          filter_config.options
            ? filter_config.options.map(
                item => item.display || '{{Option Display}}',
              )
            : []
        }
      />
    )
  }

  return <></>
}
