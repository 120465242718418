/* eslint-disable react/prop-types */
import React from 'react'
import { Label } from 'design-system/label'
import { useState } from 'react'
import dayjs from 'utils/dayjs'
import { SortingHeader } from '../../../components/table/sorting-header'

const UuidCell = ({ cell }) => {
  const [show, setShow] = useState(false)
  const handleClick = e => {
    e.stopPropagation()
    setShow(prev => !prev)
  }
  return (
    <Label
      onClick={handleClick}
      s={p => ({
        fontWeight: '500',
        color: p.theme.colors.aubergine2,
        [':hover']: {
          textDecoration: 'underline',
        },
      })}
    >
      {show ? cell.row.original.uuid : `View UUID`}
    </Label>
  )
}

export const instancesColumns = [
  {
    Header: SortingHeader,
    headerTitle: 'Instance Name',
    accessor: 'name', // accessor is the "key" in the data
    width: 225,
    canSort: true,
  },
  {
    Header: SortingHeader,
    headerTitle: 'UUID',
    accessor: 'uuid', // accessor is the "key" in the data
    width: 225,
    canSort: false,
    Cell: UuidCell,
  },
  {
    id: 'created_at',
    Header: SortingHeader,
    headerTitle: 'Date Created',
    accessor: 'fca_config.created_at', // accessor is the "key" in the data
    minWidth: 200,
    canSort: true,
    filter: 'instanceDateCreated',
    Cell: cell => dayjs(cell.row.original.fca_config.created_at).format('LL'),
  },
  {
    Header: SortingHeader,
    headerTitle: 'Users',
    accessor: 'backend_data.num_users', // accessor is the "key" in the data
    width: 100,
    canSort: true,
  },
  {
    id: 'status',
    Header: SortingHeader,
    headerTitle: 'Token Status',
    accessor: 'account_is_expired', // accessor is the "key" in the data
    width: 150,
    canSort: true,
    filter: 'instanceStatus',
    Cell: cell => (cell.row.original.account_is_expired ? 'Expired' : 'Active'),
  },
]

export const customFilterTypes = {
  // custom filter
  instancesSearch: (rows, id, filterValue) => {
    // console.log('search', rows, id, filterValue)
    return rows.filter(row => {
      const {
        original: { name },
      } = row
      const nameLower = name ? name.toLowerCase() : ''

      return row !== undefined
        ? nameLower.includes(filterValue.toLowerCase())
        : false
    })
  },
  instanceStatus: (rows, id, filterValue) => {
    return rows.filter(row => {
      const {
        original: { account_is_expired },
      } = row
      const status = account_is_expired ? 'Expired' : 'Active'
      return status === filterValue
    })
  },
  instanceDateCreated: (rows, id, filterValue) => {
    const { from, to } = filterValue || {}

    if (!from || !to) {
      return rows
    }

    return rows.filter(row => {
      const {
        original: {
          fca_config: { created_at },
        },
      } = row

      return (
        dayjs(created_at).isSameOrBefore(to) &&
        dayjs(created_at).isSameOrAfter(from)
      )
    })
  },
}

export const tableDefaultColumn = {
  // When using the useFlexLayout:
  minWidth: 80, // minWidth is only used as a limit for resizing
  width: 180, // width is used for both the flex-basis and flex-grow
  maxWidth: 300,
}
