import React, { useContext, useRef } from 'react'
import { useLocation } from '@reach/router'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import styled from 'styled-components'
import { AuthStateContext } from 'context/auth-state-context'
import ArrowDown from 'assets/arrow-down.svg'
import Checkmark from 'assets/developer-dashboard/checkmark.svg'
import { SidebarRoutes } from './sidebar-routes'
import { navigate } from 'gatsby'
import { InstanceContext } from '../context/InstanceContext'
import { useToggle } from 'hooks/useToggle'
import { useOnClickOutside } from 'hooks/useOutsideClick'
import { mockDeveloperInstanceOptions } from '__mocks__/mockDeveloperInstances'

const SidebarContainer = styled(Flex)`
  flex-direction: column;
  max-width: 265px;
  background-color: ${p => p.theme.colors.white};
`

export const DeveloperSidebar = () => {
  const [instance] = useContext(InstanceContext)
  const location = useLocation()

  const onClickRoute = path => {
    navigate(`/developers/${instance.uuid}${path}`)
  }

  return (
    <SidebarContainer>
      <InstanceSelectDropdown />
      {SidebarRoutes.map(route => {
        return (
          <SideNavLink
            key={route.name}
            route={route}
            onClick={onClickRoute}
            location={location.pathname}
          />
        )
      })}
    </SidebarContainer>
  )
}

const LinkContainer = styled(Flex)`
  padding: ${p => p.theme.sizes.four} ${p => p.theme.sizes.five};
  align-items: center;
  display: flex;
  text-decoration: none;
  color: ${p => p.theme.colors.gray1};
  > svg {
    margin-right: ${p => p.theme.sizes.four};
    > path {
      fill: ${p => p.theme.colors.gray1};
    }
  }

  :hover {
    background-color: ${p =>
      p.onClick ? p.theme.colors.gray7 : 'transparent'};
    cursor: ${p => (p.onClick ? 'pointer' : 'text')};
  }
  ${p => ({
    ...(p.isActive && {
      backgroundColor: p.theme.colors.gray7,
      color: p.theme.colors.aubergine3,
      fontWeight: '500',
      ['> svg > path']: {
        fill: p.theme.colors.aubergine3,
      },
    }),
    ...(p.isSubRoute && {
      paddingLeft: p.theme.sizes.ten,
    }),
  })}
`

const SideNavLink = ({ route = {}, onClick = () => {}, location = '' }) => {
  const [instance] = useContext(InstanceContext)
  const { icon: Icon, name, path, subRoutes } = route
  let match = location && path && path !== '/' ? location.includes(path) : false

  // Handle special case for default dashboard route
  if (path === '/') {
    const replacedLocation = location.replace(
      `/developers/${instance.uuid}`,
      '',
    )
    if (replacedLocation === path || replacedLocation === '') {
      match = true
    }
  }

  return (
    <Flex
      s={() => ({
        flexDirection: 'column',
      })}
    >
      <LinkContainer
        isActive={match}
        onClick={path ? () => onClick(path) : undefined}
      >
        {Icon ? <Icon height={24} width={24} /> : null}
        {name}
      </LinkContainer>
      <Flex
        s={() => ({
          flexDirection: 'column',
        })}
      >
        {subRoutes &&
          subRoutes.map(subRoute => {
            const { icon: Icon, name, path } = subRoute
            const subMatch =
              location && path && path !== '/' ? location.includes(path) : false
            return (
              <LinkContainer
                key={path}
                isActive={subMatch}
                isSubRoute
                onClick={path ? () => onClick(path) : undefined}
              >
                {Icon ? <Icon height={24} width={24} /> : null}
                {name}
              </LinkContainer>
            )
          })}
      </Flex>
    </Flex>
  )
}

const InstanceDropdownContainer = styled(Flex)`
  justify-content: space-around;
  align-items: center;
  padding: ${p => p.theme.sizes.six} ${p => p.theme.sizes.four};
  position: relative;
  :hover {
    background-color: ${p => p.theme.colors.gray7};
    cursor: pointer;
  }
`

const InstanceSelectDropdown = () => {
  const location = useLocation()
  const dropdownRef = useRef()
  const [authState] = useContext(AuthStateContext)
  const [instance] = useContext(InstanceContext)
  const { name = '' } = authState
  const [dropdownOpen, toggleDropdown] = useToggle(false)

  useOnClickOutside(dropdownRef, () => {
    if (dropdownOpen) {
      toggleDropdown(false)
    }
  })

  const onClickInstance = newInstanceUUID => {
    // dropdown toggles from dropdown container toggle onClick
    if (newInstanceUUID !== instance.uuid) {
      // Developer Route routing handles updating instance state from url param
      navigate(location.pathname.replace(instance.uuid, newInstanceUUID))
    }
  }

  return (
    <InstanceDropdownContainer
      ref={dropdownRef}
      onClick={() => toggleDropdown()}
    >
      {dropdownOpen && (
        <InstanceDropdown>
          {mockDeveloperInstanceOptions.map(option => {
            const isSelected = option.uuid === instance.uuid
            return (
              <DropdownOption
                selected={isSelected}
                key={option.uuid}
                onClick={() => onClickInstance(option.uuid)}
              >
                <div>
                  <Label>{option.name}</Label>
                  <Label>{`UUID: ${option.uuid}`}</Label>
                </div>
                {isSelected ? (
                  <Checkmark />
                ) : (
                  <div
                    style={{
                      width: '24px',
                      height: '24px',
                    }}
                  />
                )}
              </DropdownOption>
            )
          })}
        </InstanceDropdown>
      )}
      <Flex
        s={p => ({
          height: p.theme.sizes.six,
          width: p.theme.sizes.six,
          background: p.theme.colors.aubergine2,
          borderRadius: p.theme.sizes.five,
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        })}
      >
        <Label
          s={p => ({
            color: p.theme.colors.white,
            fontWeight: 800,
            fontSize: '1.125rem',
          })}
        >
          {name[0] || '?'}
        </Label>
      </Flex>
      <Flex
        s={() => ({
          flexDirection: 'column',
        })}
      >
        <Label
          s={() => ({
            fontWeight: '500',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '160px',
          })}
        >
          {instance.name}
        </Label>
        <Label
          s={p => ({
            overflow: 'hidden',
            maxWidth: '160px',
            color: p.theme.colors.gray2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          })}
        >{`UUID: ${instance.uuid}`}</Label>
      </Flex>
      <ArrowDown />
    </InstanceDropdownContainer>
  )
}

const InstanceDropdown = styled.div`
  position: absolute;
  padding: ${p => p.theme.sizes.four} 0;
  top: 80px;
  left: 16px;
  width: 315px;
  border: 1px solid ${p => p.theme.colors.gray6};
  box-shadow: 0px 4px 4px rgba(211, 210, 210, 0.25);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.colors.white};
  z-index: 999;
`

const DropdownOption = styled(Flex)`
  flex-direction: row;
  align-items: center;
  padding: ${p => p.theme.sizes.three} ${p => p.theme.sizes.four};
  ${p => ({
    ...(p.selected && {
      backgroundColor: p.theme.colors.gray7,
    }),
  })}
  :hover {
    background-color: ${p =>
      p.onClick ? p.theme.colors.gray7 : p.theme.colors.white};
  }
  > div:first-of-type {
    display: flex;
    flex-direction: column;
    flex: 1;
    > p {
      max-width: 225px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > p:nth-of-type(2) {
      color: ${p => p.theme.colors.gray2};
    }
  }
`
