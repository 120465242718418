import React from 'react'
import PropTypes from 'prop-types'

import { Block } from 'design-system/block'
import styled from 'styled-components'

const Container = styled(Block)`
  padding: ${p => p.theme.sizes.ten} ${p => p.theme.sizes.six}
    ${p => p.theme.sizes.twelve} ${p => p.theme.sizes.six};
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  line-height: ${p => p.theme.sizes.five};
`

export const LeftDetailColumn = props => {
  return (
    <Container>
      <Block
        s={p => ({
          padding: 0,
          minWidth: '20rem',
          [`@media (max-width: ${p.theme.flexboxgrid.breakpoints.md}em)`]: {
            width: '100%',
            minWidth: 'auto',
          },
        })}
      >
        {props.children}
      </Block>
    </Container>
  )
}

LeftDetailColumn.propTypes = {
  children: PropTypes.node,
}
