import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Block } from 'design-system/block'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Label } from 'design-system/label'
import { RatingLabel } from 'components/rating-label/rating-label.component'
import { Flex } from 'design-system/flex'
import LinkSVG from 'assets/link-out.svg'
import { PARTNERS } from 'utils/constants/partners'
import { getProviderVirtualLocations } from 'utils/location'
import { getPartnerBookingLink } from 'components/scheduling-panel/scheduling-panel'

const LinkContainer = styled(Link)`
  text-decoration: none;
`

const ResultLabel = styled(Label)`
  font-size: ${p => p.theme.fontSize.p};
  font-weight: 400;
  color: ${p => p.theme.colors.textLightest};
`

const BookingLinkLabel = styled(Label)`
  font-size: ${p => p.theme.fontSize.p};
  font-weight: 450;
  color: ${p => p.theme.colors.aubergine};
`

export const VirtualSearchResultPanel = ({
  id,
  onHover,
  result,
  title,
  subtitle,
  score,
  showInsuranceLabel,
  matchingLocation,
  type = 'providers',
}) => {
  const onMouseEnterCallback = useCallback(() => {
    if (onHover) {
      onHover(id)
    }
  }, [id, onHover])

  const onMouseExitCallback = useCallback(() => {
    if (onHover) {
      onHover(null)
    }
  }, [onHover])

  const onSelectCallback = useCallback(() => {
    typeof window !== 'undefined' &&
      window.gtag &&
      window.gtag('event', 'click', { event_label: 'List Result Card' })
  }, [])

  const providerVirtualLocations = getProviderVirtualLocations(result)
  const virtualLocationId = `1-${matchingLocation.state}`
  const { logo } = PARTNERS[1]
  const bookingLink = getPartnerBookingLink(result, 1)
  const handleOpenInNewTabClick = e => {
    e.preventDefault()
    window.open(bookingLink, '_blank')
  }

  return (
    <LinkContainer
      to={
        type === 'providers'
          ? `/providers/${id}/${providerVirtualLocations.length > 0 ? virtualLocationId : ''
          }`
          : `/facilities/${id}`
      }
      state={{
        ...(type === 'providers' && { provider: result }),
        ...(type === 'facilities' && { facility: result }),
      }}
      onClick={onSelectCallback}
    >
      <Block
        type={'CARD'}
        onMouseEnter={onMouseEnterCallback}
        onMouseLeave={onMouseExitCallback}
        s={p => ({
          background: 'none',
          minHeight: '10.25rem',
          padding: p.theme.sizes.four,
          display: 'flex',
          cursor: 'pointer',
          [`:hover`]: {
            background: `${p.theme.colors.gray7}`,
          },
        })}
      >
        <Flex
          s={() => ({
            flexDirection: 'column',
            flex: 1.2,
          })}
        >
          <Flex
            s={() => ({
              flexDirection: 'row',
              alignItems: 'center',
            })}
          >
            <Heading
              type={HEADING_TYPE.H3}
              s={p => ({
                ['> svg']: {
                  marginLeft: p.theme.sizes.one,
                  marginTop: '5px',
                },
              })}
            >
              {title}{' '}
            </Heading>
          </Flex>
          {subtitle && (
            <Label
              s={p => ({
                fontSize: p.theme.sizes.four,
                color: p.theme.colors.textLightest,
                whiteSpace: 'pre-wrap',
              })}
            >
              {subtitle}
            </Label>
          )}

          <RatingLabel rating={score} label={showInsuranceLabel} />
        </Flex>
        <Flex
          s={() => ({
            padding: 0,
            lineHeight: '22.4px',
            flex: 1,
            flexDirection: 'column',
            textAlign: 'right',
          })}
        >
          <Flex
            s={() => ({
              marginTop: 0,
              justifyContent: 'flex-end',
            })}
          >
            {logo && logo({ width: '16px', height: 'auto' })}
            <ResultLabel
              s={() => ({
                paddingLeft: '.25rem',
              })}
            >
              Headway
            </ResultLabel>
          </Flex>
          {bookingLink && (
            <Flex
              s={() => ({
                marginTop: 'auto',
                alignItems: 'center',
                justifyContent: 'flex-end',
              })}
            >
              <BookingLinkLabel
                onClick={handleOpenInNewTabClick}
                s={() => ({
                  paddingRight: '.25rem',
                })}
              >
                Find Bookings
              </BookingLinkLabel>
              <LinkSVG />
            </Flex>
          )}
        </Flex>
      </Block>
    </LinkContainer>
  )
}

VirtualSearchResultPanel.propTypes = {
  id: PropTypes.string,
  distance: PropTypes.number,
  score: PropTypes.number,
  phone: PropTypes.string,
  address: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  theme: PropTypes.object,
  showInsuranceLabel: PropTypes.string,
  onHover: PropTypes.func,
  confidence: PropTypes.number,
  result: PropTypes.object,
  type: PropTypes.string,
  locationName: PropTypes.string,
  preference: PropTypes.object,
  hasNotes: PropTypes.bool,
  showHighConfidence: PropTypes.bool,
  matchingLocation: PropTypes.shape({
    state: PropTypes.string
  })
}

VirtualSearchResultPanel.defaultProps = {
  address: {},
  showInNetwork: false,
}
