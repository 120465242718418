import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Layout } from './layout.component'
import { AuthStateContext } from 'context/auth-state-context'
import { navigate } from 'gatsby'

export const UnAuthedLayout = ({ children }) => {
  const [authState] = useContext(AuthStateContext)
  const { token, modules, permissionLevel } = authState

  useEffect(() => {
    if (token && modules) {
      if (permissionLevel >= 4) {
        console.log('Navigating to /admin')
        navigate('/admin')
      } else {
        console.log('Navigating to /')
        navigate('/')
      }
    }
  }, [token, modules])

  return <Layout pageType="auth">{children}</Layout>
}

UnAuthedLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
