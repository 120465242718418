import React, { useState } from 'react'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { Checkbox } from 'design-system/checkbox'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import styled from 'styled-components'

const CheckboxContainer = styled(Flex)`
  cursor: pointer;
  align-items: center;
  margin-bottom: ${p => p.theme.sizes.four};
`

const ALL_ENDPOINTS = [
  '/v1/custom/providers',
  '/v1/custom/locations',
  '/v1/custom/insurances',
  '/v1/custom/specialties',
  '/v1/custom/deductible',
]
export const EndpointFilterDropdown = ({
  onClickApplyFilter = () => {},
  pathsFilter = { value: { methods: [], endpoints: [] } },
}) => {
  const [selectedPaths, setSelectedPaths] = useState(
    pathsFilter ? pathsFilter.value : { methods: [], endpoints: [] },
  )

  const handleCheck = label => {
    setSelectedPaths(prev => {
      const prevCopy = { ...prev }
      const endpointsCopy = prevCopy.endpoints.slice()
      const foundLabelIndex = endpointsCopy.findIndex(i => i === label)
      if (foundLabelIndex > -1) {
        endpointsCopy.splice(foundLabelIndex, 1)
      } else {
        endpointsCopy.push(label)
      }
      prevCopy.endpoints = endpointsCopy
      return prevCopy
    })
  }

  const onClickClear = () => {
    // Clearing only specific type from paths
    selectedPaths.endpoints = []
    onClickApplyFilter('url', selectedPaths)
  }

  return (
    <Flex
      s={p => ({
        flexDirection: 'column',
        padding: `0 ${p.theme.sizes.five}`,
      })}
    >
      {ALL_ENDPOINTS.map(endpoint => {
        return (
          <CheckboxContainer
            key={endpoint}
            onClick={() => handleCheck(endpoint)}
          >
            <Checkbox
              s={() => ({ marginRight: '8px' })}
              value={selectedPaths.endpoints.includes(endpoint)}
            />
            <Label s={p => ({ color: p.theme.colors.gray1 })}>{endpoint}</Label>
          </CheckboxContainer>
        )
      })}

      <Flex>
        <Button
          onClick={() => onClickApplyFilter('url', selectedPaths)}
          type={
            selectedPaths.endpoints.length === 0
              ? BUTTON_TYPE.DISABLED
              : BUTTON_TYPE.PRIMARY
          }
          disabled={selectedPaths.endpoints.length === 0}
        >
          Apply
        </Button>
        <Button onClick={onClickClear} type={BUTTON_TYPE.LINK}>
          Clear
        </Button>
      </Flex>
    </Flex>
  )
}
