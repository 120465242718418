/* eslint-disable react/prop-types */
import React, { createContext, useReducer } from 'react'

const defaultState = {
  name: undefined,
  uuid: undefined,
}

const reducer = (state, nextInstance) => {
  console.log(`🚀 => InstanceContext:reducer => nextInstance`, nextInstance)

  return {
    ...state,
    ...nextInstance,
  }
}

export const InstanceContext = createContext(defaultState)

export const InstanceContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState)

  return (
    <InstanceContext.Provider value={[state, dispatch]}>
      {children}
    </InstanceContext.Provider>
  )
}

export const InstanceContextConsumer = InstanceContext.Consumer
