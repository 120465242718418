/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { Pagination } from './pagination'

export const Table = ({ tableInstance, onClickRow, custom }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex: statePageIndex, pageSize },
    loading = false,
  } = tableInstance

  if (loading) {
    return <p>Loading..</p>
  }
  return (
    <>
      <TableContainer>
        <TableWrapper>
          <StyledTable {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup, index) => (
                <TableHeadRow
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, headerIndex) => (
                    <TableHeader
                      key={headerIndex}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header', {
                        custom,
                      })}
                    </TableHeader>
                  ))}
                </TableHeadRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()} hasItems={page.length > 0}>
              {page.map((row, rowIndex) => {
                prepareRow(row)
                return (
                  <TableRow
                    isLast={rowIndex === page.length - 1}
                    key={rowIndex}
                    {...row.getRowProps()}
                    onClick={onClickRow ? () => onClickRow(row) : null}
                  >
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <TableData key={cellIndex} {...cell.getCellProps()}>
                          {cell.render('Cell', {
                            custom,
                          })}
                        </TableData>
                      )
                    })}
                  </TableRow>
                )
              })}
              {page.length === 0 && (
                <TableRow empty style={{ borderBottom: 'none' }}>
                  <NoMatchingResults>
                    No matching results found
                  </NoMatchingResults>
                </TableRow>
              )}
            </TableBody>
          </StyledTable>
        </TableWrapper>
      </TableContainer>
      <Pagination
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        previousPage={previousPage}
        statePageIndex={statePageIndex}
        pageCount={pageCount}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  )
}

const TableContainer = styled.div`
  border: 1px solid ${p => p.theme.colors.gray4};
  border-radius: 5px;
  background-color: ${p => p.theme.colors.white};
  display: block;
  max-width: 100%;
`
const TableWrapper = styled.div`
  display: block;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`

const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  padding-top: ${p => p.theme.sizes.five};
`

const TableHead = styled.thead``
const TableHeadRow = styled.tr`
  width: 100% !important;
  justify-content: space-between;
  border-bottom: 1px solid ${p => p.theme.colors.borderLight};
  ${p => ({
    padding: `0 ${p.theme.sizes.six}`,
  })}
`

const TableHeader = styled.th`
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  ${p => ({
    padding: `${p.theme.sizes.two} 0`,
  })};
`
const TableBody = styled.tbody`
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  ${p => ({
    padding: `0 ${p.theme.sizes.four}`,
  })}
  min-height: ${p => (p.hasItems ? '240px' : 0)};
`

const TableRow = styled.tr`
  display: flex;
  align-items: center;
  min-height: 60px;
  width: 100% !important;
  justify-content: space-between;
  flex: 0 !important;
  &:hover {
    background-color: ${p => (!p.empty ? p.theme.colors.gray7 : 'transparent')};
    cursor: ${p => (p.onClick ? 'pointer' : 'default')};
  }
  ${p => ({
    padding: `${p.theme.sizes.two} ${p.theme.sizes.four}`,
    ...(!p.isLast && {
      borderBottom: `1px solid ${p.theme.colors.borderLight}`,
    }),
  })}
`

const TableData = styled.td`
  text-align: left;
  word-wrap: break-word;
  color: ${p => p.theme.colors.grey8};
`

const NoMatchingResults = styled.td`
  color: ${p => p.theme.colors.gray0Alpha30};
  padding: ${p => p.theme.sizes.four} 0;
  align-self: flex-start;
`
