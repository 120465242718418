import React from 'react';
import PropTypes from 'prop-types';
import { Block } from "design-system/block";
import styled from "styled-components";
import { Icon } from 'design-system/icon';

const WrapperBlock = styled(Block)`
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 100px;
  padding: 0;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const ZoomControl = props => {
  return (
    <WrapperBlock>
      <Block onClick={props.onZoomIn} s={p => ({ borderBottom: `1px solid ${p.theme.colors.gray4}`, padding: 0, flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' })}>
        <Icon size={16}>
          <g clipPath="url(#clip0)" >
            <path d="M15.6667 7.66666H8.33334V0.333344C8.33334 0.14925 8.18409 0 8 0C7.81591 0 7.66666 0.14925 7.66666 0.333344V7.66669H0.333344C0.14925 7.66666 0 7.81591 0 8C0 8.18409 0.14925 8.33334 0.333344 8.33334H7.66669V15.6667C7.66669 15.8508 7.81594 16 8.00003 16C8.18412 16 8.33337 15.8508 8.33337 15.6667V8.33334H15.6667C15.8508 8.33334 16.0001 8.18409 16.0001 8C16 7.81591 15.8507 7.66666 15.6667 7.66666Z" fill="#231F20" />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </Icon>
      </Block>
      <Block onClick={props.onZoomOut} s={() => ({ padding: 0, flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' })}>
        <Icon size={16}>
          <g clipPath="url(#clip0)">
            <path d="M15.6667 7.33301H0.333344C0.14925 7.33301 0 7.48226 0 7.66635C0 7.85045 0.14925 7.9997 0.333344 7.9997H15.6667C15.8508 7.9997 16 7.85045 16 7.66635C16 7.48226 15.8507 7.33301 15.6667 7.33301Z" fill="#231F20" />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </Icon>
      </Block>
    </WrapperBlock>
  );
}

ZoomControl.propTypes = {
  onZoomOut: PropTypes.func,
  onZoomIn: PropTypes.func,
}