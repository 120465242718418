import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal } from 'design-system/modal'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Flex } from 'design-system/flex'
import { Spinner } from 'design-system/spinner'

const ContentContainer = styled(Flex)`
  padding: ${p => p.theme.sizes.four};
  justify-content: center;
  align-items: center;
  min-height: 100px;
`

export const DeleteModal = ({
  handleClose = () => { },
  handleDelete = () => { },
  handleCancel,
  title = 'Delete',
  fieldName = 'field',
  loading = false,
}) => {
  return (
    <Modal
      onClose={() => handleClose()}
      title={title}
      gridContainerStyle={{ maxWidth: '600px' }}
      footer={
        <Flex
          s={p => ({
            marginRight: p.theme.sizes.six,
            justifyContent: 'flex-end',
          })}
        >
          <Button
            s={p => ({
              marginRight: p.theme.sizes.four,
            })}
            type={BUTTON_TYPE.LINK}
            disabled={loading}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={handleDelete}
            type={loading ? BUTTON_TYPE.DISABLED : BUTTON_TYPE.DESTRUCTIVE}
          >
            Confirm Delete
          </Button>
        </Flex>
      }
    >
      <ContentContainer>
        {loading ? (
          <Spinner label={`Deleting ${fieldName || 'Field'}`} />
        ) : (
          <Label type={LABEL_TYPE.BODY1_BOLD}>
            {`Are you sure you'd like to delete this ${fieldName}?`}
          </Label>
        )}
      </ContentContainer>
    </Modal>
  )
}

DeleteModal.propTypes = {
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  fieldName: PropTypes.string,
  loading: PropTypes.bool,
}
