import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components';

const UnstyledIcon = props => {
  const size = props.size || 16
  return <svg className={props.className} onClick={props.onClick} width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    {props.children}
  </svg>;
}

UnstyledIcon.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
}

const DEFAULT = css(() => `
`);

const styles = {
  DEFAULT,
}

export const Icon = styled(UnstyledIcon)`
  ${props => styles[props.type]}
  ${props => props.s ? css(props.s(props)) : ''}
`

Icon.propTypes = {
  s: PropTypes.func,
  type: PropTypes.oneOf(['DEFAULT']).isRequired,
};


Icon.defaultProps = {
  type: 'DEFAULT',
};
