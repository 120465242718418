import React from 'react'
import PropTypes from 'prop-types'
import { LocationInput } from 'components/location-input/location-input.component'
import { useGlobalContext } from 'context/global-context-provider'
import styled from 'styled-components'
import { useAuthState } from 'context/auth-state-context'
import { searchTypes } from 'utils/constants'
import { Flex } from 'design-system/flex'
import { currentLocationOption } from 'utils/location'
import { NameInputAutocomplete } from 'components/name-input-autocomplete/name-input-autocomplete'
import { SearchBtn } from 'components/search-btn/search-btn'
import { InsuranceSearchContainer } from 'components/insurance-search-dropdown/insurance-search-container'
import { SpecialtiesSearchContainer } from 'components/specialties-search-dropdown/specialties-search-container'

const InputContainer = styled.div`
  flex: 1;
`

export const ProviderSearchInputs = props => {
  const [searchState] = useGlobalContext()
  const [authState] = useAuthState()

  const onClickSearch = () => {
    if (!props.isSearchDisabled) {
      props.onSearchClick()
    }
  }

  const { modules } = authState
  const { modules: providerModules } = modules[searchTypes.providers] || {}

  return (
    <Flex
      s={() => ({
        flex: 1,
      })}
    >
      <InputContainer>
        <LocationInput
          small={props.small}
          value={
            searchState.location && {
              id: `${searchState.location.position.latitude},${searchState.location.position.longitude}`,
              label: searchState.location.label,
              value: searchState.location,
            }
          }
          onSelection={value => props.onLocationChange(value)}
          showCurrentLocationOption
          defaultOptions={[currentLocationOption]}
          position="left"
        />
      </InputContainer>
      {providerModules.networks.enabled && (
        <InputContainer>
          <InsuranceSearchContainer
            small={props.small}
            onSelection={v => {
              props.onInsuranceChange(v)
            }}
          />
        </InputContainer>
      )}

      <InputContainer>
        <SpecialtiesSearchContainer
          small={props.small}
          onSelection={v => props.onSpecialtiesChange(v)}
        />
      </InputContainer>

      <InputContainer>
        <NameInputAutocomplete
          small={props.small}
          value={searchState.providerName}
          onSelection={v => props.onNameChange(v)}
        />
      </InputContainer>
      <SearchBtn
        hasSearchChanges={props.hasSearchChanges}
        isSearchDisabled={props.isSearchDisabled}
        onClickSearch={onClickSearch}
        id="search-btn"
      />
    </Flex>
  )
}

ProviderSearchInputs.propTypes = {
  small: PropTypes.bool,
  isSearchDisabled: PropTypes.bool,
  hasSearchChanges: PropTypes.bool,
  onNameChange: PropTypes.func,
  onLocationChange: PropTypes.func,
  onSpecialtiesChange: PropTypes.func,
  onInsuranceChange: PropTypes.func,
  onSearchClick: PropTypes.func,
}
