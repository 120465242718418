import React, { useRef, useState } from 'react'
import { theme } from 'theme'
import styled from 'styled-components'
import CaretDown from 'assets/caret-down.svg'
import { Label } from 'design-system/label'
import { useOnClickOutside } from 'hooks/useOutsideClick'
import { Flex } from 'design-system/flex'
import { MoreMenu } from 'components/dropdown/dropdown'

const LabelContainer = styled(Flex)`
  padding: ${p => p.theme.sizes.two} ${p => p.theme.sizes.two};
  align-items: center;
  justify-content: flex-start;
  margin-right: ${p => p.theme.sizes.four};
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  :hover {
    background-color: ${p => p.theme.colors.gray7};
  }
  > svg {
    path {
      fill: ${p => p.theme.colors.gray2};
    }
  }
`

export const LabelDropdown = ({ label = 'Label', renderMenu = () => null }) => {
  const [showMenu, setShowMenu] = useState(false)
  const menuRef = useRef()

  useOnClickOutside(menuRef, () => {
    if (showMenu) {
      setShowMenu(false)
    }
  })

  const onClickLabel = () => {
    if (!showMenu) {
      setShowMenu(true)
    }
  }

  return (
    <Flex
      s={() => ({
        position: 'relative',
      })}
      ref={menuRef}
    >
      <LabelContainer onClick={onClickLabel}>
        <Label
          s={p => ({
            marginRight: p.theme.sizes.one,
            color: p.theme.colors.gray2,
            fontWeight: '500',
          })}
        >
          {label}
        </Label>
        <CaretDown />
      </LabelContainer>
      {showMenu && (
        <MoreMenu
          style={{
            top: '44px',
            left: 0,
            minHeight: '100px',
            minWidth: '280px',
            padding: `${theme.sizes.five} 0`,
          }}
        >
          {renderMenu()}
        </MoreMenu>
      )}
    </Flex>
  )
}

export const MenuOption = styled(Flex)`
  align-items: center;
  padding: ${p => p.theme.sizes.three} ${p => p.theme.sizes.four};
  :hover {
    background-color: ${p =>
      p.onClick ? p.theme.colors.gray7 : p.theme.colors.white};
    cursor: pointer;
  }
  color: ${p =>
    p.isDisabled ? p.theme.colors.gray3 : p.theme.colors.aubergine2};
`
