import React, { useContext, useReducer, useState } from 'react'
import { Block } from 'design-system/block'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import { splitFiltersIntoSections } from 'utils/filters'
import { FilterSectionList } from './filter-section-list'
import { NotyfContext } from 'context/notyf-context'
import { AuthStateContext } from 'context/auth-state-context'
import { useToggle } from 'hooks/useToggle'
import { AddNewFilterModal } from './add-new-filter-modal'
import { useHasChanges } from '../useHasChanges'
import { AnimatedStickyHeader } from 'containers/admin/components/animated-sticky-header'
import { services } from 'services'
import { colors } from 'theme/colors'

const editFiltersReducer = (state, action) => {
  console.log('state :>> ', state)
  console.log('action :>> ', action)
  const { type, payload } = action
  switch (type) {
    case 'toggle': {
      const { configKey, item } = payload
      // Copy as JSON parse/stringify as object will only be JSON appropriate key/values.
      const filtersCopy = JSON.parse(JSON.stringify(state[configKey]))
      const { is_more } = item
      const itemListCopy = is_more ? filtersCopy.more : filtersCopy.sidebar

      const foundFilterIndex = itemListCopy.findIndex(i => i.uuid === item.uuid)
      if (foundFilterIndex > -1) {
        const foundFilterCopy = { ...itemListCopy[foundFilterIndex] }
        foundFilterCopy.enabled = !foundFilterCopy.enabled
        itemListCopy[foundFilterIndex] = foundFilterCopy
      }

      const newState = {
        ...state,
        [configKey]: filtersCopy,
      }
      console.log(`🚀 => editFiltersReducer:toggle => newState`, newState)
      return newState
    }
    case 'reorder': {
      const { configKey, result } = payload

      const newState = {
        ...state,
        [configKey]: {
          ...state[configKey],
          ...result,
        },
      }
      console.log(`🚀 => editFiltersReducer:reorder => newState`, newState)
      return newState
    }
    case 'reset':
      return {
        ...payload,
      }
    default:
      break
  }
}

const updateDisplayOrderOfItems = items => {
  return items.map((i, index) => {
    return { ...i, display_order: index }
  })
}

const initialEmptyState = {
  providers: {
    sidebar: [],
    more: [],
  },
  facilities: {
    sidebar: [],
    more: [],
  },
}

export const ManageInstanceFilterConfig = ({ instance = {} }) => {
  const queryClient = useQueryClient()
  const [authState] = useContext(AuthStateContext)
  const [editMode, setEditMode] = useState(false)
  const [showAddNew, setShowAddNew] = useToggle(false)

  const [state, setState] = useState(
    instance && instance.fca_config && instance.fca_config.fca_filters
      ? splitFiltersIntoSections(instance.fca_config.fca_filters)
      : initialEmptyState,
  )

  const [editState, dispatch] = useReducer(editFiltersReducer, state)
  const notyf = useContext(NotyfContext)
  const [showStickyHeader, setShowStickyHeader] = useState(false)
  useHasChanges(state, editState)

  const { mutateAsync: manageInstance, isLoading: isSaving } = useMutation(
    ({ uuid, options }) =>
      services.ribbon.manageInstance(authState.token, uuid, options),
    {
      onSuccess: res => {
        console.log(`🚀 => manageInstance => res`, res)
        queryClient.invalidateQueries('applications')
        notyf.success('Filter Configuration updated!')
        // Update location state instance with instance from response
        // Fixes reloading the page and old instance loading from state
        if (typeof window !== 'undefined' && window.history) {
          window.history.replaceState({ instance: res.data }, '')
        }
      },
      onError: error => {
        console.log(`🚀 => manageInstance => error`, error)
        notyf.error(error.message)
      },
    },
  )

  const onClickEditConfig = () => {
    setEditMode(prev => !prev)
  }

  const onClickSaveConfig = async () => {
    const filtersFlatWithIndex = {
      providers: updateDisplayOrderOfItems([
        ...editState.providers.sidebar,
        ...editState.providers.more,
      ]),
      facilities: updateDisplayOrderOfItems([
        ...editState.facilities.sidebar,
        ...editState.facilities.more,
      ]),
    }

    try {
      console.log(`onClickSaveConfig`, filtersFlatWithIndex)

      // Save Config
      await manageInstance({
        uuid: instance.uuid,
        options: {
          fca_filters: filtersFlatWithIndex,
        },
      })
      const newFiltersSplitIntoSections = splitFiltersIntoSections(
        filtersFlatWithIndex,
      )
      setState(newFiltersSplitIntoSections)
      dispatch({
        type: 'reset',
        payload: newFiltersSplitIntoSections,
      })
      setEditMode(prev => !prev)
    } catch (error) {
      // error is handled on manageInstance mutation
    }
  }

  const handleSuccess = res => {
    setShowAddNew(false)
    const newFiltersSplitIntoSections = splitFiltersIntoSections(
      res.data.fca_config.fca_filters,
    )
    // TODO: Combine state and editState into one reducer for easy reset and setting
    setState(newFiltersSplitIntoSections)
    dispatch({
      type: 'reset',
      payload: newFiltersSplitIntoSections,
    })
    queryClient.invalidateQueries('applications')
    // Update location state instance with instance from response
    // Fixes reloading the page and old instance loading from state
    if (typeof window !== 'undefined' && window.history) {
      window.history.replaceState({ instance: res.data }, '')
    }
  }

  const onClickDiscardChanges = () => {
    // Discard Changes
    dispatch({
      type: 'reset',
      payload: state,
    })
    setEditMode(prev => !prev)
  }

  const onChangeEnabled = (configKey, item) => {
    console.log('onChangeEnabled :>> ', configKey, item)
    dispatch({
      type: 'toggle',
      payload: {
        configKey,
        item,
      },
    })
  }

  const handleContainerScroll = e => {
    const { scrollTop = 0 } = e.target
    if (scrollTop >= 48) {
      if (!showStickyHeader) {
        setShowStickyHeader(true)
      }
    } else {
      if (showStickyHeader) {
        setShowStickyHeader(false)
      }
    }
  }

  return (
    <Flex
      onScroll={handleContainerScroll}
      s={p => ({
        padding: 0,
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        maxWidth: `${p.theme.flexboxgrid.container.lg}em`,
        height: 'max-content',
        overflow: 'auto',
        paddingBottom: p.theme.sizes.twelve,
        position: 'relative',
      })}
    >
      {editMode && showStickyHeader && (
        <AnimatedStickyHeader
          secondaryButton={{
            action: onClickDiscardChanges,
            label: 'Discard Changes',
          }}
          primaryButton={{
            action: onClickSaveConfig,
            label: 'Save Configuration',
            loading: isSaving,
          }}
        />
      )}
      <Flex
        s={p => ({
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: `${p.theme.sizes.six} ${p.theme.sizes.six} 0`,
          marginBottom: p.theme.sizes.five,
        })}
      >
        <Label
          s={p => ({
            color: p.theme.colors.gray0,
            fontSize: p.theme.sizes.six,
            fontWeight: '700',
          })}
        >
          Filter Configuration
        </Label>

        <Flex>
          {editMode ? (
            <SecondaryButton onClick={onClickDiscardChanges}>
              Discard Changes
            </SecondaryButton>
          ) : (
            <SecondaryButton onClick={() => setShowAddNew(true)}>
              Add a new filter
            </SecondaryButton>
          )}

          {editMode ? (
            <PrimaryButton loading={isSaving} onClick={onClickSaveConfig}>
              Save Configuration
            </PrimaryButton>
          ) : (
            <PrimaryButton onClick={onClickEditConfig}>
              Edit Configuration
            </PrimaryButton>
          )}
        </Flex>
      </Flex>
      {/* Providers Section */}
      <Block
        s={p => ({
          padding: `0 ${p.theme.sizes.six}`,
        })}
      >
        <FilterSectionList
          sectionTitle="Providers"
          list={editMode ? editState.providers : state.providers}
          configKey="providers"
          editMode={editMode}
          onChangeEnabled={onChangeEnabled}
          dispatch={dispatch}
        />

        <Border />
        {/* Facilities Section */}

        <FilterSectionList
          sectionTitle="Facilities"
          list={editMode ? editState.facilities : state.facilities}
          configKey="facilities"
          editMode={editMode}
          onChangeEnabled={onChangeEnabled}
          dispatch={dispatch}
        />
      </Block>

      {showAddNew && (
        <AddNewFilterModal
          handleSuccess={handleSuccess}
          instance={instance}
          handleClose={() => setShowAddNew(false)}
        />
      )}
    </Flex>
  )
}

const Border = styled.div`
  height: 1px;
  min-height: 1px;
  width: 100%;
  background-color: ${p => p.theme.colors.gray4};
  margin: ${p => p.theme.sizes.six} 0;
`

const PrimaryButton = styled(Button)`
  min-width: 200px;
`

PrimaryButton.defaultProps = {
  type: BUTTON_TYPE.DEFAULT,
  size: BUTTON_SIZE.LARGE,
  outerLoadingColor: colors.aubergine,
}

const SecondaryButton = styled(Button)`
  margin-right: ${p => p.theme.sizes.two};
  :hover {
    background-color: ${p => p.theme.colors.gray6};
  }
`
SecondaryButton.defaultProps = {
  type: BUTTON_TYPE.LINK,
  size: BUTTON_SIZE.LARGE,
}
