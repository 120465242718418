export const gmapsBoundsToArray: (
  bounds: google.maps.LatLngBounds,
) => [number, number][] = bounds => {
  const ne = {
    lat: bounds.getNorthEast().lat(),
    lng: bounds.getNorthEast().lng(),
  }
  const sw = {
    lat: bounds.getSouthWest().lat(),
    lng: bounds.getSouthWest().lng(),
  }
  const se = { lat: sw.lat, lng: ne.lng }
  const nw = { lat: ne.lat, lng: sw.lng }
  return [ne, se, sw, nw, ne].map(p => [p.lng, p.lat])
}

export const boundsToZoomLevel = (
  minLat: number,
  maxLat: number,
  minLng: number,
  maxLng: number,
  centerLat: number,
  centerLng: number,
): number => {
  const GLOBE_WIDTH = 256 // a constant in Google's map projection
  const latMaxDiff = Math.max(
    Math.abs(maxLat - centerLat),
    Math.abs(minLat - centerLat),
  )
  const lngMaxDiff = Math.max(
    Math.abs(maxLng - centerLng),
    Math.abs(minLng - centerLng),
  )

  const west = centerLng - lngMaxDiff
  const east = centerLng + lngMaxDiff
  const south = centerLat - latMaxDiff
  const north = centerLat + latMaxDiff

  let angle = east - west
  if (angle < 0) {
    angle += 360
  }
  const angle2 = north - south
  if (angle2 > angle) angle = angle2
  const zoomfactor = Math.round(
    Math.log((960 * 360) / angle / GLOBE_WIDTH) / Math.LN2,
  )
  return zoomfactor - 2
}
