import React from 'react'
import PropTypes from 'prop-types'
import { Block } from 'design-system/block'
import styled from 'styled-components'
import { Label } from 'design-system/label'
import { TabIcon } from 'components/tab-icon/tab-icon.component'

const TabsContainer = styled(Block)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 0;
  border-bottom: 1px solid ${p => p.theme.colors.gray4};
  background-color: ${p => p.theme.colors.white};
`

const Tab = styled(Block)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${p => ({
    padding: `${p.theme.sizes.four} ${p.theme.sizes.five}`,
    ...(p.isActive
      ? {
          borderBottom: `4px solid ${p.theme.colors.aubergine}`,
          p: {
            fontWeight: '500',
          },
        }
      : {
          borderBottom: `4px solid ${p.theme.colors.clear}`,
          p: {
            fontWeight: '400',
          },
        }),
  })};
`

const TabLabel = styled(Label)``

export const TabNavigation = ({
  tabs = [],
  onClickTab = () => {},
  activeTab,
}) => {
  return (
    <TabsContainer>
      {tabs.map(tab => {
        const isActive = tab.id === activeTab

        return (
          <>
            <Tab
              data-testid={tab.id}
              key={tab.id}
              isActive={isActive}
              onClick={() => onClickTab(tab)}
            >
              {isActive && tab.icon?.active && (
                <TabIcon icon={tab.icon.active} />
              )}
              {!isActive && tab.icon?.inactive && (
                <TabIcon icon={tab.icon.inactive} />
              )}
              <TabLabel>{tab.title}</TabLabel>
            </Tab>
          </>
        )
      })}
    </TabsContainer>
  )
}

TabNavigation.propTypes = {
  tabs: PropTypes.array,
  onClickTab: PropTypes.func,
  activeTab: PropTypes.string,
}
