import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { Block } from 'design-system/block'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import { Dropdown } from 'design-system/input/dropdown.component'
import { Link } from 'components/account-dropdown/link.component'
import { DropdownLabel } from 'components/account-dropdown/dropdown-label.component'
import { HelpLink } from 'components/account-dropdown/help-link.component'
import { AuthStateContext, AUTH_ACTION_TYPES } from 'context/auth-state-context'
import { PermissionRestricted } from 'components/permission-restricted/permission-restricted'
import {
  GlobalStateContext,
  GLOBAL_CONTEXT_ACTIONS,
} from 'context/global-context-provider'
import { CopyAPIRequestLink } from 'components/account-dropdown/copy-api-request-link.component'

const Icon = styled(Block)`
  padding: 0;
  height: ${p => p.theme.sizes.eight};
  width: ${p => p.theme.sizes.eight};
  background: ${p => p.theme.colors.aubergine};
  border-radius: ${p => p.theme.sizes.five};
  text-align: center;
  line-height: ${p => p.theme.sizes.eight};
  cursor: pointer;
`

export const AccountIcon = ({ setShowMenu }) => {
  const [authState] = useContext(AuthStateContext)
  const { name = '' } = authState

  return (
    <Icon
      s={() => ({
        position: 'relative',
        alignSelf: 'flex-end',
      })}
      onClick={() => setShowMenu(prev => !prev)}
    >
      <Label
        s={p => ({
          color: p.theme.colors.white,
          fontWeight: 800,
          fontSize: '1.125rem',
        })}
      >
        {name[0] || '?'}
      </Label>
    </Icon>
  )
}

export const AccountDropdown = ({ setShowMenu }) => {
  const [authState, authDispatch] = useContext(AuthStateContext)
  const [, globalDispatch] = useContext(GlobalStateContext)
  const { isSsoUser } = authState

  const handleOnClickLink = link => {
    setShowMenu(false)
    if (link === 'sign-out') {
      authDispatch({
        type: AUTH_ACTION_TYPES.LOGGED_OUT,
      })
      globalDispatch({
        type: GLOBAL_CONTEXT_ACTIONS.DATA_RESET,
        payload: {},
      })
    } else {
      navigate(link)
    }
  }

  return (
    <Dropdown
      sContainer={p => ({
        background: 'white',
        right: p.theme.sizes.five,
        top: p.theme.sizes.twelve,
        overflow: 'hidden',
        padding: `${p.theme.sizes.three} 0`,
        textAlign: 'left',
        zIndex: p.theme.zIndexes.modal + 1,
      })}
    >
      <Flex
        s={p => ({
          flexDirection: 'column',
          padding: p.theme.spacing.two,
        })}
      >
        <PermissionRestricted level={4}>
          <Link>
            <DropdownLabel onClick={() => handleOnClickLink('/admin')}>
              Admin console
            </DropdownLabel>
          </Link>
        </PermissionRestricted>
        {!isSsoUser &&
          <Link>
            <DropdownLabel onClick={() => handleOnClickLink('/change-password')}>
              Change password
            </DropdownLabel>
          </Link>
        }
        <HelpLink />
        <CopyAPIRequestLink />
        <Link>
          <OutboundLink
            style={{ textDecoration: 'none' }}
            href="https://www.ribbonhealth.com/terms-of-service/"
            target="_blank"
            rel="noreferrer"
          >
            <DropdownLabel>Terms of service</DropdownLabel>
          </OutboundLink>
        </Link>
        <Link>
          <OutboundLink
            style={{ textDecoration: 'none' }}
            href="https://www.ribbonhealth.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            <DropdownLabel>Privacy policy</DropdownLabel>
          </OutboundLink>
        </Link>
        <Link>
          <OutboundLink
            style={{ textDecoration: 'none' }}
            href="https://www.ribbonhealth.com"
            target="_blank"
            rel="noreferrer"
          >
            <DropdownLabel>RibbonHealth.com</DropdownLabel>
          </OutboundLink>
        </Link>
        <Link>
          <DropdownLabel onClick={() => handleOnClickLink('sign-out')}>
            Sign out
          </DropdownLabel>
        </Link>
      </Flex>
    </Dropdown>
  )
}

AccountDropdown.propTypes = {
  setShowMenu: PropTypes.func,
}

AccountIcon.propTypes = {
  setShowMenu: PropTypes.func,
}
