import React from 'react'
import PropTypes from 'prop-types'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Block } from 'design-system/block'
import { List } from 'design-system/list'
import { Flex } from 'design-system/flex'
import { InfoIndicator } from 'components/info-indicator/info-indicator'

export const SelectFilter = ({
  counts = {},
  hasCounts = false,
  label = 'Label',
  labelComponent,
  onChange = () => { },
  selected = null,
  options = [],
  renderOption,
  tooltip,
}) => {
  return (
    <Block
      s={p => ({
        paddingLeft: 0,
        lineHeight: p.theme.sizes.six,
        paddingBottom: p.theme.sizes.four,
      })}
    >
      {labelComponent ? (
        labelComponent
      ) : (
        <Flex
          s={() => ({
            alignItems: 'center',
          })}
        >
          <Label type={LABEL_TYPE.BODY1_BOLD}>{label}</Label>
          {tooltip && <InfoIndicator id="label" message={tooltip} />}
        </Flex>
      )}

      <List disableItemHoverStyle={true}>
        {options.map(option => {
          const isDisabled = hasCounts && !counts[option.value];
          return (
            <Block
              s={() => ({ padding: 0, cursor: 'pointer' })}
              key={option}
              onClick={() => !isDisabled && onChange(option)}
            >
              {renderOption ? (
                renderOption(option)
              ) : (
                <Label
                  type={
                    (selected === option.value && LABEL_TYPE.BODY1_BOLD) ||
                    LABEL_TYPE.BODY1
                  }
                  s={p => ({
                    color: p.theme.colors.gray1,
                    [':hover']: {
                      fontWeight: (isDisabled) ? 'initial' : 600,
                      cursor: (isDisabled) ? 'default' : 'pointer',
                    },
                    ['text-decoration']: (isDisabled) ? 'line-through' : 'none'
                  })}
                >
                  {option.label}
                </Label>
              )
              }
            </Block>
          );
        })}
      </List >
    </Block >
  )
}

SelectFilter.propTypes = {
  counts: PropTypes.objectOf(PropTypes.number),
  hasCounts: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelComponent: PropTypes.element,
  selected: PropTypes.string,
  options: PropTypes.array,
  renderOption: PropTypes.func,
  tooltip: PropTypes.string,
}
