import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Block } from 'design-system/block'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Label } from 'design-system/label'
import { RatingLabel } from 'components/rating-label/rating-label.component'
import { formatPhoneNumber } from 'utils/formatters/format-phone-number'
import PhoneSVG from 'assets/phone.svg'
import CheckIconSVG from 'assets/circle-check-full.svg'
import PreferredSVG from 'assets/preferred.svg'
import UnpreferredSVG from 'assets/unpreferred.svg'
import NotesSearchResultSVG from 'assets/notes-search-result.svg'
import { Flex } from 'design-system/flex'
import { InaccurateInfoIndicator } from 'components/inaccurate-info-indicator/inaccurate-info-indicator'

const LinkContainer = styled(Link)`
  text-decoration: none;
`

const ResultLabel = styled(Label)`
  font-size: ${p => p.theme.fontSize.p};
  font-weight: 400;
  color: ${p => p.theme.colors.textLightest};
`

export const SearchResultPanel = ({
  id,
  onHover,
  result,
  phone,
  address,
  title,
  subtitle,
  distance,
  score,
  showInsuranceLabel,
  confidence,
  type = 'providers', // | facilities
  locationName,
  preference,
  hasNotes,
  showHighConfidence,
}) => {
  const onMouseEnterCallback = useCallback(() => {
    if (onHover) {
      onHover(id)
    }
  }, [id, onHover])

  const onMouseExitCallback = useCallback(() => {
    if (onHover) {
      onHover(null)
    }
  }, [onHover])

  const onSelectCallback = useCallback(() => {
    typeof window !== 'undefined' &&
      window.gtag &&
      window.gtag('event', 'click', { event_label: 'List Result Card' })
  }, [])

  const onClickPhone = useCallback(
    e => {
      e.stopPropagation()
      e.preventDefault()
      window.location.href = `tel:${phone}`
    },
    [phone],
  )

  let qualityRating = null
  let costEfficiency = null
  if (result && result.performance && result.performance.aggregate) {
    if (result.performance.aggregate.cost) {
      costEfficiency = result.performance.aggregate.cost.efficiency_index
    }
    if (result.performance.aggregate.quality) {
      qualityRating = result.performance.aggregate.quality.outcomes_index
    }
  }

  return (
    <LinkContainer
      to={
        type === 'providers'
          ? `/providers/${id}/${result.locations.length > 0 ? result.locations[0].uuid : ''
          }`
          : `/facilities/${id}`
      }
      state={{
        ...(type === 'providers' && { provider: result }),
        ...(type === 'facilities' && { facility: result }),
      }}
      onClick={onSelectCallback}
    >
      <Block
        type={'CARD'}
        onMouseEnter={onMouseEnterCallback}
        onMouseLeave={onMouseExitCallback}
        s={p => ({
          background: 'none',
          minHeight: '10.25rem',
          padding: p.theme.sizes.four,
          display: 'flex',
          cursor: 'pointer',
          [`:hover`]: {
            background: `${p.theme.colors.gray7}`,
          },
        })}
      >
        <Flex
          s={() => ({
            flexDirection: 'column',
            flex: 1.2,
          })}
        >
          <Flex
            s={() => ({
              flexDirection: 'row',
              alignItems: 'center',
            })}
          >
            <Heading
              type={HEADING_TYPE.H3}
              s={p => ({
                ['> svg']: {
                  marginLeft: p.theme.sizes.one,
                  marginTop: '5px',
                },
              })}
            >
              {title}{' '}
              {!!confidence && confidence < 3 && (
                <InaccurateInfoIndicator id={`inaccurate-info-${id}`} />
              )}
              {!!confidence && confidence === 5 && (
                <CheckIconSVG width={16} height={16} />
              )}
              {preference?.rating === 2 && (
                <PreferredSVG width={16} height={16} />
              )}
              {preference?.rating === 0 && (
                <UnpreferredSVG width={16} height={16} />
              )}
              {hasNotes && <NotesSearchResultSVG width={16} height={16} />}
            </Heading>
          </Flex>
          {subtitle && (
            <Label
              s={p => ({
                fontSize: p.theme.sizes.four,
                color: p.theme.colors.textLightest,
                whiteSpace: 'pre-wrap',
              })}
            >
              {subtitle}
            </Label>
          )}

          <RatingLabel
            rating={score}
            label={showInsuranceLabel}
            costEfficiency={costEfficiency}
            qualityRating={qualityRating}
          />
        </Flex>
        <Flex
          s={() => ({
            padding: 0,
            lineHeight: '22.4px',
            flex: 1,
            flexDirection: 'column',
            textAlign: 'right',
          })}
        >
          <Flex
            s={p => ({
              flexDirection: 'column',
              flex: 1,
              background: 'none',
              fontWeight: 400,
              color: p.theme.colors.textLightest,
              marginBottom: 0,
            })}
          >
            <ResultLabel>
              {!!distance && `${distance} mi`}
            </ResultLabel>

            {locationName && <ResultLabel>{locationName}</ResultLabel>}

            <ResultLabel>
              {address.street && `${address.street}, `}{' '}
              {address.city && `${address.city}, `} {address.state}
            </ResultLabel>

            <ResultLabel>{address.zip && `${address.zip}, `} US</ResultLabel>

            {result.locations && result.locations.length > 1 && (
              <ResultLabel
                s={p => ({
                  marginTop: p.theme.sizes.four,
                })}
              >
                {`+${result.locations.length - 1} ${showHighConfidence ? `High Confidence ` : ``
                  }${result.locations.length - 1 > 1 ? 'Locations' : 'Location'}`}
              </ResultLabel>
            )}
          </Flex>
          {phone && (
            <Flex
              s={() => ({
                marginTop: 'auto',
                alignItems: 'center',
                justifyContent: 'flex-end',
              })}
            >
              <PhoneSVG />
              <ResultLabel
                s={() => ({
                  paddingLeft: '.25rem',
                })}
                onClick={onClickPhone}
              >
                {formatPhoneNumber(phone)}
              </ResultLabel>
            </Flex>
          )}
        </Flex>
      </Block>
    </LinkContainer>
  )
}

SearchResultPanel.propTypes = {
  id: PropTypes.string,
  distance: PropTypes.number,
  score: PropTypes.number,
  phone: PropTypes.string,
  address: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  theme: PropTypes.object,
  showInsuranceLabel: PropTypes.string,
  onHover: PropTypes.func,
  confidence: PropTypes.number,
  result: PropTypes.object,
  type: PropTypes.string,
  locationName: PropTypes.string,
  preference: PropTypes.object,
  hasNotes: PropTypes.bool,
  showHighConfidence: PropTypes.bool,
}

SearchResultPanel.defaultProps = {
  address: {},
  showInNetwork: false,
}
