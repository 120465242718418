import React from 'react'
import { colors } from 'theme/colors'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Flex } from 'design-system/flex'
import styled from 'theme'

const LabelContainer = styled(Flex)`
  flex: 0.48;
`

export const ClinicalAreasUngroupedListItem = ({
  isLast = false,
  item = { display: '', clinicalArea: '' },
}) => {
  return (
    <Flex
      s={p => ({
        justifyContent: 'space-between',
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: colors.gray4,
        paddingBottom: p.theme.sizes.two,
        marginBottom: p.theme.sizes.two,
        alignItems: 'center',
        ...(isLast && {
          borderBottomWidth: 0,
          paddingBottom: 0,
          marginBottom: 0,
        }),
      })}
    >
      <LabelContainer>
        <Label
          s={p => ({
            color: p.theme.colors.gray0,
          })}
          type={LABEL_TYPE.BODY1_BOLD}
        >
          {item.display}
        </Label>
      </LabelContainer>
      <LabelContainer>
        <Label
          s={p => ({
            color: p.theme.colors.gray1,
          })}
        >
          {item.clinicalArea}
        </Label>
      </LabelContainer>
    </Flex>
  )
}
