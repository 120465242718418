import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { SpecialtiesSearchResults } from './specialties-search-results'
import { Spinner } from 'design-system/spinner'
import { Flex } from 'design-system/flex'
import styled from 'styled-components'
import { Tag, TAG_SIZE } from 'design-system/tag'
import { Button, BUTTON_TYPE } from 'design-system/button'

const HeaderContainer = styled(Flex)`
  ${p => ({
    ...(p.showBorder && {
      borderBottom: `1px solid ${p.theme.colors.borderLight}`,
    }),
    padding: p.theme.sizes.four,
  })}
  justify-content: space-between;
  align-items: flex-start;
  max-height: 285px;
  overflow-y: auto;
`

const TagsContainer = styled(Flex)`
  flex: 1;
  flex-wrap: wrap;
  align-items: flex-start;
  .specialtyTooltip {
    max-width: 375px;
  }
`

const SpecialtyTag = styled(Tag)`
  height: initial;
  word-break: break-word;
  ${p => ({
    padding: `${p.theme.sizes.two} ${p.theme.sizes.four}`,
    marginRight: p.theme.sizes.four,
    marginBottom: p.theme.sizes.two,
    backgroundColor: p.theme.colors.aubergine,
    borderColor: p.theme.colors.aubergine,
    color: p.theme.colors.white,
  })}
  .closeBtn {
    > svg > path {
      fill: ${p => p.theme.colors.white};
    }
  }
  :hover {
    background: ${p => p.theme.colors.aubergine3};
  }
`

const formatTagDisplayName = displayName => {
  if (displayName && displayName.length > 65) {
    return {
      displayName: `${displayName.substring(0, 65)}...`,
      isFormatted: true,
    }
  }
  return {
    displayName,
    isFormatted: false,
  }
}

export const SpecialtiesDropdown = ({
  onClickTag,
  onSelectResult,
  onClickClearAll,
  specialties,
  searchResults,
  isLoading,
}) => {
  return (
    <>
      {specialties.length > 0 && (
        <HeaderContainer showBorder={searchResults}>
          <TagsContainer>
            {specialties.map(specialty => {
              const { displayName, isFormatted } = formatTagDisplayName(
                specialty.display_name,
              )
              return (
                <div key={specialty.uuid}>
                  <SpecialtyTag
                    size={TAG_SIZE.SMALL}
                    onClick={() => onClickTag(specialty)}
                    showClearIcon
                    data-tip={isFormatted ? specialty.display_name : null}
                    data-for={specialty.uuid}
                    data-testid={specialty.uuid}
                  >
                    {displayName}
                  </SpecialtyTag>
                  {isFormatted && (
                    <ReactTooltip
                      id={specialty.uuid}
                      place="left"
                      effect="solid"
                      className="specialtyTooltip"
                    />
                  )}
                </div>
              )
            })}
          </TagsContainer>

          <Button
            s={p => ({
              ['> button']: {
                height: p.theme.sizes.six,
                padding: p.theme.sizes.four,
              },
            })}
            onClick={onClickClearAll}
            type={BUTTON_TYPE.LINK}
          >
            Clear All
          </Button>
        </HeaderContainer>
      )}
      <>
        {isLoading ? (
          <Spinner size={32} />
        ) : (
          <SpecialtiesSearchResults
            results={searchResults}
            onClick={onSelectResult}
            selectedResults={specialties}
          />
        )}
      </>
    </>
  )
}

SpecialtiesDropdown.propTypes = {
  onClickTag: PropTypes.func,
  onSelectResult: PropTypes.func,
  onClickClearAll: PropTypes.func,
  searchResults: PropTypes.array,
  specialties: PropTypes.array,
  isLoading: PropTypes.bool,
}
