import { useEffect, useRef } from 'react'
import { useKeyPress } from './useKeyPress'

/**
 * Keyboard up, down, enter handling for list of items.
 * @param {[{isHeader?:Boolean, uuid:String}]} results list of items
 * @param {*} onClick onClick handler for enter clicks
 * @returns
 */
export const useKeyNavigateResults = (results, onClick) => {
  const activeCursorRef = useRef(-1)
  const downPressed = useKeyPress('ArrowDown')
  const upPressed = useKeyPress('ArrowUp')
  const enterPressed = useKeyPress('Enter')

  // any changes to results should reset cursor
  useEffect(() => {
    activeCursorRef.current = -1
  }, [results])

  useEffect(() => {
    if (!results) return
    if (enterPressed && activeCursorRef.current > -1) {
      onClick(results[activeCursorRef.current])
      activeCursorRef.current = -1
    }
  }, [enterPressed, results, onClick])

  useEffect(() => {
    if (!results) return
    if (results.length > 0 && downPressed) {
      const prev = activeCursorRef.current
      if (prev < results.length - 1) {
        let nextCursor = prev + 1
        if (results[nextCursor].isHeader) {
          // skip header item
          nextCursor += 1
        }
        const nextItem = results[nextCursor]
        if (nextItem && document) {
          const element = document.getElementById(nextItem.uuid)
          if (element) {
            element.focus()
            // element.scrollIntoView({ block: 'end', behavior: 'smooth' })
          }
        }
        activeCursorRef.current = nextCursor
      } else {
        activeCursorRef.current = prev
      }
    }
  }, [downPressed, results])

  useEffect(() => {
    if (!results) return
    if (results.length > 0 && upPressed) {
      const prev = activeCursorRef.current
      if (prev > 0) {
        let nextCursor = prev - 1
        if (results[nextCursor].isHeader) {
          // skip header item
          nextCursor -= 1
        }
        const nextItem = results[nextCursor]
        if (nextItem && document) {
          const element = document.getElementById(nextItem.uuid)
          if (element) {
            element.focus()
            // Focus scrolls to element - but below could be needed
            // element.scrollIntoView({
            //   block: 'end',
            //   behavior: 'smooth',
            // })
          }
        }
        activeCursorRef.current = nextCursor
      } else {
        activeCursorRef.current = prev
      }
    }
  }, [upPressed, results])

  return {}
}
