import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex } from 'design-system/flex'
import { Input, INPUT_SIZE } from 'design-system/input'
import StethoscopeSVG from 'assets/stethoscope.svg'
import { useToggle } from 'hooks/useToggle'
import { useOnClickOutside } from 'hooks/useOutsideClick'
import { useGlobalContext } from 'context/global-context-provider'
import { SpecialtiesDropdown } from './specialties-dropdown'
import { useSpecialtiesSearchQuery } from './useSpecialtiesSearchQuery'
import { focusNextInput } from 'utils/document'

const Container = styled.div`
  position: relative;

  > #dropdown-menu {
    top: 60px;
    width: 100%;
  }
`

const DropdownContainer = styled(Flex)`
  position: absolute;
  top: 60px;
  width: 500px; // fixed width intentional for long specialty names, but no small screen handling
  border: 1px solid #f5f5f5;
  background-color: white;
  border-radius: 10px;
  transition: height 100ms ease-in;
  flex-direction: column;
  box-shadow: 0px 4px 4px rgba(211, 210, 210, 0.25);
  z-index: 999;
`

const getLabelFromSpecialties = specialties => {
  if (specialties && specialties.length > 0) {
    if (specialties.length > 1) {
      return `+${specialties.length} Specialties`
    } else {
      return specialties[0].display_name
    }
  }

  return ''
}

export const SpecialtiesSearchContainer = ({ small, onSelection }) => {
  const [inputValue, setInputValue] = useState('')
  const [showDropdown, toggleDropdown] = useToggle(false)
  const ref = useRef()
  const [searchState] = useGlobalContext()
  const { specialties = [] } = searchState

  const [inputLabel, setInputLabel] = useState(
    getLabelFromSpecialties(specialties),
  )

  const {
    isLoading,
    data: specialtiesSearchResults,
  } = useSpecialtiesSearchQuery({ inputValue })

  /**
   * inputLabel should always be adjusted on any specialties changes
   * will also keep small/normal SearchHeader specialty input in sync with changes
   */
  useEffect(() => {
    setInputLabel(getLabelFromSpecialties(specialties))
  }, [specialties])

  useOnClickOutside(ref, () => {
    if (showDropdown) {
      toggleDropdown(false)
    }
    setInputValue('') // clear user editing input values
  })

  const onInputChange = e => {
    const { value } = e.target
    setInputValue(value)
  }

  const onFocus = () => {
    if (!showDropdown) {
      toggleDropdown(true)
    }
  }

  /**
   * Removes specialty from selected Specialties list
   * @param {{uuid:String}} specialty
   */
  const onClickTag = specialty => {
    const specialtiesCopy = [...specialties]
    const indexOf = specialtiesCopy.findIndex(s => s.uuid === specialty.uuid)
    if (indexOf > -1) {
      specialtiesCopy.splice(indexOf, 1)
      onSelection(specialtiesCopy)
    }
  }

  /**
   * Adds specialty to selected Specialties list
   * @param {{uuid:String}} specialty
   */
  const onSelectResult = specialty => {
    const specialtiesCopy = [...specialties]
    const indexOf = specialtiesCopy.findIndex(s => s.uuid === specialty.uuid)
    if (indexOf < 0) {
      specialtiesCopy.push(specialty)
      onSelection(specialtiesCopy)
      setInputValue('')
    }
  }

  const onClickClearAll = () => {
    onSelection([])
  }

  /**
   * Clear input of single specialty with close-x btn
   */
  const onClickClearInput = () => {
    setInputValue('')
    onSelection([])
    focusNextInput('specialties-input')
  }

  return (
    <Container ref={ref}>
      <Input
        id="specialties-input"
        size={INPUT_SIZE.EXTRA_LARGE}
        s={p => ({
          backgroundColor: 'white',
          borderRight: 0,
          ['input']: {
            paddingLeft: p.theme.sizes.two,
            paddingRight: p.theme.sizes.two,
          },
        })}
        placeholder={small ? 'Specialty' : 'Select specialty'}
        value={[
          {
            label:
              inputValue.length > 0 || showDropdown ? inputValue : inputLabel,
          },
        ]}
        leftIcon={() => {
          if (small) {
            return null
          }
          return <StethoscopeSVG width={16} height={16} />
        }}
        onInputChange={onInputChange}
        onChange={onClickClearInput}
        onFocus={onFocus}
        showClearIcon={specialties.length < 2}
      />

      {showDropdown && (inputValue.length > 0 || specialties.length > 0) && (
        <DropdownContainer>
          <SpecialtiesDropdown
            onClickClearAll={onClickClearAll}
            onClickTag={onClickTag}
            specialties={specialties}
            searchResults={specialtiesSearchResults}
            isLoading={isLoading}
            onSelectResult={onSelectResult}
          />
        </DropdownContainer>
      )}
    </Container>
  )
}

SpecialtiesSearchContainer.propTypes = {
  small: PropTypes.bool,
  onSelection: PropTypes.func,
}
