export const proceduresReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'update':
      if (Object.keys(payload).length === 1 && 'search' in payload) {
        const filtered =
          payload.search.length > 0
            ? [...state.initialData].filter(
                item =>
                  item.display &&
                  item.display
                    .toLowerCase()
                    .includes(payload.search.toLowerCase()),
              )
            : state.initialData

        return {
          ...state,
          ...payload,
          filteredData: filtered,
        }
      }

      return {
        ...state,
        ...payload,
      }
    case 'clear':
      return {
        ...state,
        search: '',
        filteredData: state.initialData,
      }

    default:
      return state
  }
}
