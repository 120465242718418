import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Input, INPUT_TYPE } from 'design-system/input'
import { Button } from 'design-system/button'
import { Flex } from 'design-system/flex'
import { Checkbox } from 'design-system/checkbox'
import CloseSVG from 'assets/close-x.svg'
import { EDIT_INSURANCE_ACTION_TYPES } from './edit-insurances-reducer'
import { useInsuranceCarriersQuery } from 'components/insurance-search-dropdown/useInsuranceCarriersQuery'
import { useInsurancePlansByCarriersQuery } from 'components/insurance-search-dropdown/useInsurancePlansByCarrierQuery'
import { focusNextInput } from 'utils/document'
import {
  filterPlansByFilterList,
  formatDisplayNameByState,
  formatInsurancePlansByState,
  getAllFilterOptionsFromPlans,
  mapPlansByState,
  mapStatePlansByPlanType,
  transformPlansToInsurancePlans,
} from 'utils/insurances'
import { FilterInsurancesDropdown } from './filter-insurances'
import { NoInsuranceSearchResultsContainer } from './no-insurance-search-results'

const ScrollContainer = styled(Flex)`
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  padding: ${p => p.theme.sizes.four} ${p => p.theme.sizes.six};
`

const HeaderContainer = styled(Flex)`
  padding-top: ${p => p.theme.sizes.four};
  padding-left: ${p => p.theme.sizes.six};
  padding-right: ${p => p.theme.sizes.six};
`

export const AddInsurancesContainer = ({
  handleToggleSingle = () => {},
  handleToggleList = () => {},
  state,
  dispatch,
}) => {
  const {
    localSearch,
    planFilters,
    insurances,
    addInsurancesMap,
    selectedCarrier,
  } = state
  const [showFilterDropdown, setShowFilterDropdown] = useState()
  const {
    results: carriers,
    isLoading: isLoadingCarriers,
  } = useInsuranceCarriersQuery(localSearch)
  const {
    results: plans,
    isLoading: isLoadingPlans,
  } = useInsurancePlansByCarriersQuery(
    selectedCarrier ? selectedCarrier.displayName : '',
    selectedCarrier ? localSearch : '',
    false,
  )

  const transformedPlans = useMemo(
    () => transformPlansToInsurancePlans(plans),
    [plans],
  )

  const filterOptions = useMemo(
    () => getAllFilterOptionsFromPlans(transformedPlans),
    [transformedPlans],
  )

  useEffect(() => {
    focusNextInput('search-input')
  }, [selectedCarrier])

  const onClickCarrier = carrier => {
    dispatch({
      type: EDIT_INSURANCE_ACTION_TYPES.updateSelectedCarrier,
      payload: {
        selectedCarrier: carrier,
      },
    })
  }

  const onCheckStateHeader = (plans, hasAll) => {
    handleToggleList(
      plans,
      hasAll
        ? EDIT_INSURANCE_ACTION_TYPES.updateAddListAllUndo
        : EDIT_INSURANCE_ACTION_TYPES.updateAddListAllAdd,
    )
  }

  const onSearch = value => {
    dispatch({
      type: EDIT_INSURANCE_ACTION_TYPES.updateFilters,
      payload: {
        localSearch: value,
      },
    })
  }

  const onClickSave = filters => {
    dispatch({
      type: EDIT_INSURANCE_ACTION_TYPES.updateFilters,
      payload: {
        filters,
      },
    })
    setShowFilterDropdown()
  }

  return (
    <>
      <HeaderContainer>
        <Input
          s={p => ({
            flex: 1,
            marginRight: selectedCarrier ? p.theme.sizes.four : 0,
          })}
          onInputChange={e => onSearch(e.target.value)}
          value={[{ label: localSearch }]}
          placeholder={
            selectedCarrier ? 'Search for plan..' : 'Search for carrier..'
          }
          type={INPUT_TYPE.ROUND}
          id="search-input"
          onChange={() => onSearch('')}
        />
        {selectedCarrier && (
          <FilterInsurancesDropdown
            label="Filter Plans"
            onClickFilter={setShowFilterDropdown}
            showMenu={showFilterDropdown}
            closeMenu={() => setShowFilterDropdown()}
            filters={planFilters}
            filterOptions={filterOptions}
            onClickSave={onClickSave}
          />
        )}
      </HeaderContainer>
      {selectedCarrier ? (
        <CarrierPlansContainer
          carrier={selectedCarrier.displayName}
          plans={transformedPlans}
          planFilters={planFilters}
          insurances={insurances}
          handleToggleSingle={handleToggleSingle}
          handleToggleList={handleToggleList}
          addInsurancesMap={addInsurancesMap}
          clearCarrier={() => onClickCarrier(null)}
          onCheckStateHeader={onCheckStateHeader}
          isLoading={isLoadingPlans}
        />
      ) : (
        <ScrollContainer>
          <CarriersList
            carriers={carriers}
            onClick={onClickCarrier}
            isLoading={isLoadingCarriers}
          />
        </ScrollContainer>
      )}
    </>
  )
}

AddInsurancesContainer.propTypes = {
  handleToggleSingle: PropTypes.func,
  handleToggleList: PropTypes.func,
  state: PropTypes.object,
  dispatch: PropTypes.func,
}

const CarrierItem = styled.li`
  padding: ${p => p.theme.sizes.three} ${p => p.theme.sizes.four};
  ${p => ({
    [':hover']: {
      backgroundColor: p.onClick ? p.theme.colors.gray6 : p.theme.colors.white,
      cursor: 'pointer',
    },
  })}

  &:focus {
    background-color: ${p => p.theme.colors.gray6};
    outline: none;
  }
  &:focus-visible {
    background-color: ${p => p.theme.colors.gray6};
    outline: none;
  }
`
const CarrierListHeader = styled(Label)`
  padding: ${p => p.theme.sizes.three} ${p => p.theme.sizes.four};
  font-weight: bold;
  color: ${p => p.theme.colors.gray1};
`

const CarriersContainer = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const CarriersList = ({
  carriers = [],
  onClick = () => {},
  isLoading = false,
}) => {
  if (!isLoading && carriers.length === 0) {
    return (
      <NoInsuranceSearchResultsContainer message="Try searching for carriers again." />
    )
  }
  return (
    <CarriersContainer
      id="carrier-results-container"
      data-testid="carrier-results-container"
    >
      {carriers.map((result, index) => {
        const { isHeader, uuid, displayName } = result

        if (isHeader) {
          return <CarrierListHeader key={uuid}>{displayName}</CarrierListHeader>
        }

        return (
          <CarrierItem
            key={uuid}
            id={result.uuid}
            onClick={() => onClick(result)}
            tabIndex={index}
          >
            {displayName}
          </CarrierItem>
        )
      })}
    </CarriersContainer>
  )
}

const SearchResultHeader = styled(Flex)`
  justify-content: space-between;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${p => p.theme.colors.gray3};
  margin: 0 ${p => p.theme.sizes.six};
  margin-top: ${p => p.theme.sizes.six};
  padding-bottom: ${p => p.theme.sizes.four};
  align-items: center;
  > div {
    align-items: center;
  }
`
const PlansContainer = styled(Flex)`
  flex-direction: column;
  padding: ${p => p.theme.sizes.three} ${p => p.theme.sizes.six};
  padding-top: ${p => p.theme.sizes.four};
  overflow-y: scroll;
`

const ClearBtnContainer = styled(Button)`
  > button {
    padding: ${p => p.theme.sizes.two};
    max-height: 32px;
  }
  border-radius: 4px;
  border-color: transparent;
`

const CarrierPlansContainer = ({
  carrier = '',
  plans = [],
  planFilters = {},
  insurances = [],
  handleToggleSingle = () => {},
  handleToggleList = () => {},
  addInsurancesMap = [],
  clearCarrier = () => {},
  onCheckStateHeader = () => {},
  isLoading = false,
}) => {
  const filteredPlans = useMemo(
    () => filterPlansByFilterList(plans, planFilters),
    [plans, planFilters],
  )

  const plansFormattedByState = useMemo(
    () => formatInsurancePlansByState(filteredPlans),
    [filteredPlans],
  )

  const plansMappedByState = useMemo(
    () => mapPlansByState(plansFormattedByState),
    [plansFormattedByState],
  )

  const plansMappedByStateAndPlanType = useMemo(
    () => mapStatePlansByPlanType(plansMappedByState),
    [plansMappedByState],
  )

  const hasInsurances = filteredPlans.length > 0

  const addListContainsAllByCarrier =
    hasInsurances &&
    filteredPlans.every(
      plan =>
        plan.isHeader || // ignore header items
        Boolean(insurances.find(i => i.uuid === plan.uuid)) || // insurance added already
        Boolean(addInsurancesMap[plan.uuid]), // insurance to be added
    )
  const insurancesContainsAllByCarrier =
    hasInsurances &&
    filteredPlans.every(
      plan =>
        plan.isHeader || // ignore header items
        Boolean(insurances.find(i => i.uuid === plan.uuid)),
    )

  const onClickCarrierCheckbox = () => {
    handleToggleList(
      filteredPlans,
      addListContainsAllByCarrier
        ? EDIT_INSURANCE_ACTION_TYPES.updateAddListAllUndo
        : EDIT_INSURANCE_ACTION_TYPES.updateAddListAllAdd,
    )
  }

  if (!isLoading && plans.length === 0) {
    return (
      <NoInsuranceSearchResultsContainer message="Try searching for plans again." />
    )
  }

  return (
    <>
      <SearchResultHeader>
        <Flex>
          <Checkbox
            s={p => ({ marginRight: p.theme.sizes.two })}
            value={addListContainsAllByCarrier}
            onClick={
              !insurancesContainsAllByCarrier
                ? () => onClickCarrierCheckbox()
                : null
            }
            disabled={insurancesContainsAllByCarrier}
            data-testid={`${carrier}-checkbox`}
          />
          <Label type={LABEL_TYPE.BODY1_BOLD}>{carrier}</Label>
        </Flex>
        <ClearBtnContainer
          onClick={() => clearCarrier()}
          data-testid="remove-carrier"
        >
          <CloseSVG height={16} width={16} />
        </ClearBtnContainer>
      </SearchResultHeader>

      <PlansContainer>
        {Object.keys(plansMappedByStateAndPlanType).map(state => {
          const { header, planTypes } = plansMappedByStateAndPlanType[state]
          const { uuid, displayName } = header

          const plansByState = plansMappedByState[uuid]
          const addListContainsAllByState =
            plansByState &&
            plansByState.length > 0 &&
            plansByState.every(
              plan =>
                Boolean(insurances.find(i => i.uuid === plan.uuid)) ||
                Boolean(addInsurancesMap[plan.uuid]),
            )

          const insurancesContainsAllByState =
            plansByState &&
            plansByState.length > 0 &&
            plansByState.every(plan =>
              Boolean(insurances.find(i => i.uuid === plan.uuid)),
            )
          return (
            <div key={state}>
              <PlanListHeaderItem
                key={uuid}
                label={displayName}
                onClick={
                  insurancesContainsAllByState
                    ? null
                    : () =>
                        onCheckStateHeader(
                          plansByState,
                          addListContainsAllByState,
                        )
                }
                isSelected={addListContainsAllByState}
                isAdded={insurancesContainsAllByState}
              />
              {Object.keys(planTypes).map(planType => {
                const plansByType = planTypes[planType]
                if (plansByType.length === 0) return

                const addListContainsAllByType = plansByType.every(
                  plan =>
                    Boolean(insurances.find(i => i.uuid === plan.uuid)) ||
                    Boolean(addInsurancesMap[plan.uuid]),
                )

                const insurancesContainsAllByType = plansByType.every(plan =>
                  Boolean(insurances.find(i => i.uuid === plan.uuid)),
                )
                return (
                  <div key={planType}>
                    <PlanListHeaderItem
                      label={planType}
                      onClick={
                        insurancesContainsAllByType
                          ? null
                          : () =>
                              onCheckStateHeader(
                                plansByType,
                                addListContainsAllByType,
                              )
                      }
                      isSelected={addListContainsAllByType}
                      isAdded={insurancesContainsAllByType}
                      isSubHeader
                    />
                    {plansByType.map(plan => {
                      const label = formatDisplayNameByState(plan)

                      const addListContainsPlan = Boolean(
                        addInsurancesMap[plan.uuid],
                      )
                      const insurancesListContainsPlan = Boolean(
                        insurances.find(i => i.uuid === plan.uuid),
                      )
                      return (
                        <PlanItem
                          key={plan.uuid}
                          label={label}
                          onClick={
                            insurancesListContainsPlan
                              ? null
                              : () =>
                                  handleToggleSingle(
                                    plan,
                                    EDIT_INSURANCE_ACTION_TYPES.updateAddListSingle,
                                  )
                          }
                          isSelected={addListContainsPlan}
                          isAdded={insurancesListContainsPlan}
                        />
                      )
                    })}
                  </div>
                )
              })}
            </div>
          )
        })}
      </PlansContainer>
    </>
  )
}

const PlanListHeader = styled(Flex)`
  align-items: center;

  padding: ${p => p.theme.sizes.three} 0;
  ${p => ({
    ...(p.isSubHeader && {
      marginLeft: p.theme.sizes.six,
      marginBottom: p.theme.sizes.two,
    }),
  })}
`

const PlanListHeaderItem = React.memo(
  ({ label, onClick, isSelected, isAdded, isSubHeader }) => {
    return (
      <PlanListHeader isSubHeader={isSubHeader}>
        <Checkbox
          s={p => ({ marginRight: p.theme.sizes.two })}
          value={isSelected}
          onClick={onClick}
          disabled={isAdded}
          data-testid={`${label}-checkbox`}
        />
        <Label type={LABEL_TYPE.BODY1_BOLD}>{label}</Label>
      </PlanListHeader>
    )
  },
  (prev, next) => prev.isSelected === next.isSelected,
)

PlanListHeaderItem.displayName = 'PlanListHeaderItem'
PlanListHeaderItem.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  isAdded: PropTypes.bool,
  isSelected: PropTypes.bool,
  isSubHeader: PropTypes.bool,
}

const PlanLabel = styled(Label)`
  color: ${p => p.theme.colors.gray1};
  text-decoration: none;

  :hover {
    color: ${p =>
      p.insurancesListContainsPlan
        ? p.theme.colors.gray3
        : p.theme.colors.aubergine3};
    font-weight: 500;
  }
  ${p => ({
    ...(p.containsPlan && {
      color: p.theme.colors.aubergine3,
      fontWeight: 500,
    }),
    ...(p.insurancesListContainsPlan && {
      color: p.theme.colors.gray3,
    }),
  })}
`

const PlanItem = React.memo(
  ({ label, onClick, isSelected, isAdded }) => {
    return (
      <Flex
        s={p => ({
          marginBottom: p.theme.sizes.four,
          marginLeft: p.theme.sizes.eight,
          alignItems: 'center',
        })}
        onClick={onClick}
      >
        <Checkbox
          s={p => ({ marginRight: p.theme.sizes.two })}
          value={isSelected || isAdded}
          disabled={isAdded}
        />
        <PlanLabel
          containsPlan={isSelected || isAdded}
          insurancesListContainsPlan={isAdded}
        >
          {label}
        </PlanLabel>
      </Flex>
    )
  },
  (prev, next) => prev.isSelected === next.isSelected,
)

PlanItem.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  isAdded: PropTypes.bool,
  isSelected: PropTypes.bool,
}

PlanItem.displayName = 'PlanItem'
