import { GlobalStateContext } from 'context/global-context-provider'
import { NotyfContext } from 'context/notyf-context'
import { useContext } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { services } from 'services'
import { searchTypes } from 'utils/constants'

export const searchQueryKeys = {
  all: ['providersSearchResults', 'facilitiesSearchResults'],
  facilitiesSearch: ['facilitiesSearchResults'],
  facilitiesSearchWithQuery: query => ['facilitiesSearchResults', query],
  providersSearch: ['providersSearchResults'],
  providersSearchWithQuery: query => ['providersSearchResults', query],
}

export const useSearchQueries = () => {
  const queryClient = useQueryClient()
  const [searchState = {}] = useContext(GlobalStateContext)
  const notyfContext = useContext(NotyfContext)

  const {
    providersSearchQuery,
    searchType,
    facilitiesSearchQuery,
  } = searchState

  const {
    isLoading: providerSearchLoading,
    data: providerCurrentPageResults,
  } = useQuery(
    ['providersSearchResults', providersSearchQuery],
    () => services.ribbon.searchProviders(providersSearchQuery),
    {
      enabled: searchType === searchTypes.providers && !!providersSearchQuery,
      initialData: () => {
        return queryClient.getQueryData(
          searchQueryKeys.providersSearchWithQuery(providersSearchQuery),
        )
      },
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
      onError: error => {
        notyfContext.error(
          error.message || 'An error occurred searching providers',
        )
      },
    },
  )

  const {
    isLoading: facilitySearchLoading,
    data: facilityCurrentPageResults,
  } = useQuery(
    ['facilitiesSearchResults', facilitiesSearchQuery],
    () => services.ribbon.searchLocations(facilitiesSearchQuery),
    {
      enabled: searchType === searchTypes.facilities && !!facilitiesSearchQuery,
      initialData: () => {
        return queryClient.getQueryData(
          searchQueryKeys.facilitiesSearchWithQuery(facilitiesSearchQuery),
        )
      },
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
      onError: error => {
        notyfContext.error(
          error.message || 'An error occurred searching locations',
        )
      },
    },
  )

  return {
    providerCurrentPageResults,
    facilityCurrentPageResults,
    isLoading: providerSearchLoading || facilitySearchLoading,
  }
}
