import { useContext, useState } from 'react'
import { AuthStateContext } from 'context/auth-state-context'
import { useQuery } from 'react-query'
import { services } from 'services'

export const useProceduresCostEstimates = ({
  procedureId,
  location,
  autoSearch = false,
}) => {
  const [authState] = useContext(AuthStateContext)
  const [result, setResult] = useState()
  const [error, setError] = useState()

  const { data: procedure, isLoading: fetchingProcedure } = useQuery(
    ['getProcedure', procedureId],
    () =>
      services.ribbon.getProcedure(authState.token, {
        uuid: procedureId,
      }),
    {
      enabled: !!procedureId,
      staleTime: 5 * 60 * 1000,
    },
  )

  const {
    isFetching: fetchingCostEstimates,
    error: costEstimatesError,
    refetch: fetchCostEstimates,
    data,
  } = useQuery(
    [
      'searchProceduresCostEstimates',
      procedure ? procedure.uuid : '-',
      location ? location.zipcode : '-',
    ],
    () =>
      services.ribbon.searchProceduresCostEstimates(authState.token, {
        procedure_ids: procedure ? procedure.uuid : '',
        member_zip: location.zipcode,
      }),
    {
      enabled: autoSearch ? !!procedure && !!location : false,
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
      onSettled: (data, err) => {
        console.log('searchProceduresCostEstimates:onSettled: data', data)
        console.log('searchProceduresCostEstimates:onSettled: err', err)
        if (data) {
          setResult({
            costEstimates: data.cost_estimates,
            location: location,
            procedure,
          })
          if (error) {
            setError()
          }
        }
        if (err) {
          setError(err)
          if (result) {
            setResult()
          }
        }
      },
    },
  )

  return {
    procedure,
    result: autoSearch
      ? {
          costEstimates: data ? data.cost_estimates : null,
          location: location,
          procedure,
        }
      : result,
    fetchCostEstimates,
    error: autoSearch ? costEstimatesError : error,
    fetchingCostEstimates,
    fetchingProcedure,
  }
}
