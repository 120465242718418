import React, { useContext } from 'react'
import { Link } from 'components/account-dropdown/link.component'
import { DropdownLabel } from 'components/account-dropdown/dropdown-label.component'
import { GlobalStateContext } from 'context/global-context-provider'
import { NotyfContext } from 'context/notyf-context'
import { searchTypes } from 'utils/constants'
import { getProvidersSearchUrl } from 'services/ribbon/search-providers.service'
import { getLocationsSearchUrl } from 'services/ribbon/search-locations.service'
import { copyToClipboard } from 'utils/copy-to-clipboard'

function getAPIUrl(searchState) {
  switch (searchState.searchType) {
    case searchTypes.providers:
      return `/v1${getProvidersSearchUrl(searchState.providersSearchQuery)}`
    case searchTypes.facilities:
      return `/v1${getLocationsSearchUrl(searchState.facilitiesSearchQuery)}`
    default:
      return null
  }
}

async function onClick(searchState, notyf) {
  const apiUrl = getAPIUrl(searchState)
  if (apiUrl) {
    await copyToClipboard(apiUrl)
    notyf.success('Copied API request to clipboard')
    return
  }

  notyf.error('Failed to copy API request. Please try again.')
}

export const CopyAPIRequestLink = () => {
  const [searchState,] = useContext(GlobalStateContext)
  const notyf = useContext(NotyfContext)

  return (
    <Link onClick={() => onClick(searchState, notyf)}>
      <DropdownLabel>Copy API request</DropdownLabel>
    </Link>
  );
}
