import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

export const BLOCK_TYPE = {
  CARD: 'CARD',
  DEFAULT: 'DEFAULT',
  FLEX: 'FLEX',
}

const UnstyledBlock = React.forwardRef((props, ref) => {
  return (
    <div
      id={props.id}
      ref={ref}
      className={props.className}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onScroll={props.onScroll}
      data-testid={props['data-testid']}
    >
      {props.children}
    </div>
  )
})

UnstyledBlock.displayName = 'Block'

UnstyledBlock.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onScroll: PropTypes.func,
  'data-testid': PropTypes.string,
}

export const Block = styled(UnstyledBlock)`
  padding: ${props => props.theme.spacing.two};
  ${props =>
    ({
      CARD: `
      border: 1px solid ${props.theme.colors.borderDefault};
      border-radius: 5px;
      background: ${props.theme.colors.white};
    `,
      FLEX: `
      display: flex;
      flex-direction: row;
    `,
    }[props.type] || ``)}
  ${props => (props.s ? css(props.s(props)) : '')}
`

Block.propTypes = {
  s: PropTypes.func,
  type: PropTypes.oneOf(Object.keys(BLOCK_TYPE)),
}

Block.defaultProps = {
  type: BLOCK_TYPE.DEFAULT,
}
