import React from 'react'
import PropTypes from 'prop-types'
import {Button} from 'design-system/button'
import {getProviderLocationsCount} from 'utils/location'
import {getModulesFromAuthState} from 'utils/modules'
import {searchTypes} from 'utils/constants'
import {useAuthState} from 'context/auth-state-context'

export const SeeOtherLocationsButton = ({
  provider,
  onSeeOtherLocationsClick,
}) => {
  const [authState] = useAuthState()
  const providerModules = getModulesFromAuthState(authState, searchTypes.providers)
  const isVirtualSearchEnabled = providerModules.virtual_search?.enabled

  const totalLocations = provider ? getProviderLocationsCount(provider, isVirtualSearchEnabled) : 0

  return totalLocations > 1 ?
    (
      <Button
        s={() => ({
          width: '240px',
          verticalAlign: 'middle',
        })}
        onClick={onSeeOtherLocationsClick}
      >
        {`See ${totalLocations - 1} other ${
          totalLocations - 1 > 1 ? 'locations' : 'location'
        }`}
      </Button>
    ) :
    null
}

SeeOtherLocationsButton.propTypes = {
  provider: PropTypes.object,
  onSeeOtherLocationsClick: PropTypes.func,
}
