import { Location, PartnerData } from 'services/ribbon/ribbon.types'
import {
  locationHasInsurance,
  virtualLocationHasInsurance,
} from 'utils/location'

type SearchStateInsurance = {
  id: string
  label: string
}

export type SearchState = {
  insurance: SearchStateInsurance
}

export const formatInsuranceLabel = (
  searchState: SearchState,
  location: Location,
): string => {
  if (!searchState || !searchState.insurance) {
    return ''
  }

  if (locationHasInsurance(searchState.insurance, location)) {
    return 'In-network'
  } else {
    return 'Out-of-network'
  }
}

export const formatInsuranceLabelForVirtual = (
  searchState: SearchState,
  partnerData: PartnerData,
): string => {
  if (!searchState || !searchState.insurance) {
    return ''
  }

  if (virtualLocationHasInsurance(searchState.insurance, partnerData)) {
    return 'In-network'
  } else {
    return 'Out-of-network'
  }
}
