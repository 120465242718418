import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import ReportIconSVG from 'assets/report-icon.svg'
import { colors } from 'theme/colors'

export const InaccurateInfoIndicator = ({ id, message }) => {
  return (
    <>
      <ReportIconSVG width={16} height={16} data-for={id} data-tip={message} />
      {message && (
        <ReactTooltip
          place="right"
          effect="solid"
          backgroundColor={colors.gray1}
          className="custom-tooltip"
          id={id}
        />
      )}
    </>
  )
}

InaccurateInfoIndicator.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
}
