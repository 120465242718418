import React, { useReducer, useState, useEffect, useContext } from 'react'
import { Modal } from 'design-system/modal'
import { Block } from 'design-system/block'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label, LABEL_TYPE } from 'design-system/label'
import styled from 'styled-components'
import { Spinner } from 'design-system/spinner/spinner.component'
import { Input, INPUT_TYPE } from 'design-system/input'
import { SelectInput } from 'design-system/input/select-input'
import { RadioOption } from 'design-system/radio-option/radio-option'
import { SelectFilter } from 'components/filters/select-filter/select-filter'
import { MultiSelectFilter } from 'components/filters/multi-select-filter/multi-select-filter'
import { services } from 'services'
import { AuthStateContext } from 'context/auth-state-context'
import { NotyfContext } from 'context/notyf-context'
import { useMutation } from 'react-query'
import { Flex } from 'design-system/flex'
import { FIELD_TYPES_FLAT } from 'utils/constants'
import {
  filterFieldTypes,
  filterTypes,
  filterTypesByFieldSelected,
  getFilterLabelByFieldSelected,
  filterDisplayTypeByFieldSelected,
} from 'utils/filters'

const onlyAlphaNumericRegex = /^[a-z0-9_]+$/i

const customFiltersReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'update':
      return {
        ...state,
        ...payload,
      }
    case 'updateDisplayOptions': {
      const { filterDisplayOptions } = state
      const { index, value } = payload
      const updatedFilterDisplayOptions = filterDisplayOptions.options.slice()
      updatedFilterDisplayOptions[index] = value

      return {
        ...state,
        filterDisplayOptions: {
          ...filterDisplayOptions,
          options: updatedFilterDisplayOptions,
        },
      }
    }
    case 'addNewOption': {
      const { filterDisplayOptions } = state
      const updatedFilterDisplayOptions = filterDisplayOptions.options.slice()
      updatedFilterDisplayOptions.push({ display: '', value: '' })
      return {
        ...state,
        filterDisplayOptions: {
          ...state.filterDisplayOptions,
          options: updatedFilterDisplayOptions,
        },
      }
    }
    case 'removeOption': {
      const { filterDisplayOptions } = state
      const { index } = payload
      const updatedFilterDisplayOptions = filterDisplayOptions.options.slice()
      updatedFilterDisplayOptions.splice(index, 1)
      return {
        ...state,
        filterDisplayOptions: {
          ...state.filterDisplayOptions,
          options: updatedFilterDisplayOptions,
        },
      }
    }

    case 'resetDisplayOptions':
      return {
        ...state,
        filterDisplayOptions: {
          title: '',
          options: [{ display: '', value: '' }],
        },
      }
    case 'reset':
      return initialState
    default:
      return state
  }
}

const initialState = {
  type: 'providers', // | facilities
  parameterName: '',
  fieldName: '',
  fieldType: '',
  filterType: '',
  filterDisplayOptions: {
    title: '',
    options: [{ display: '', value: '' }],
  },
}

const initialStateFromCustomField = customField => {
  const filterTypeOptions = filterTypesByFieldSelected(customField.field_type)

  return {
    type: customField.type, // | facilities
    parameterName: customField.parameter_name,
    fieldName: customField.parameter_name,
    fieldType: customField.field_type,
    filterType: '', // default clear filterType
    ...(filterTypeOptions.length === 1 && {
      filterType: filterTypeOptions[0].value, // only one option - default set option
    }),
    filterDisplayOptions: {
      title: '',
      options: [{ display: '', value: '' }],
    },
  }
}

export const AddNewFilterModal = ({
  customField = null,
  instance = {},
  handleSuccess = () => {},
  handleClose = () => {},
}) => {
  const [authState] = useContext(AuthStateContext)
  const notyf = useContext(NotyfContext)
  const [state, dispatch] = useReducer(
    customFiltersReducer,
    customField ? initialStateFromCustomField(customField) : initialState,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [paramIsInvalid, setParamIsInvalid] = useState(false)
  const {
    parameterName,
    fieldName,
    fieldType,
    filterType,
    filterDisplayOptions,
  } = state

  const { mutate: manageInstance, isLoading: isSaving } = useMutation(
    ({ uuid, options }) =>
      services.ribbon.manageInstance(authState.token, uuid, options),
    {
      onSuccess: res => {
        console.log(`🚀 => manageInstance => res`, res)
        notyf.success('New Filter added!')
        setIsLoading(false)
        handleSuccess(res)
      },
      onError: error => {
        console.log(`🚀 => manageInstance => error`, error)
        notyf.error(error.message)
      },
    },
  )

  useEffect(() => {
    if (parameterName.length > 0) {
      if (onlyAlphaNumericRegex.test(parameterName)) {
        if (paramIsInvalid) {
          setParamIsInvalid(false)
        }
      } else {
        if (!paramIsInvalid) {
          setParamIsInvalid(true)
        }
      }
    }
  }, [parameterName, paramIsInvalid])

  const createFilter = async () => {
    console.log(`🚀 => createFilter =>`, state)

    const createFilterOptions = {
      filter_type: filterType.toLowerCase(),
      value_type: fieldType.toLowerCase(),
      parameter: parameterName,
      field: fieldName,
    }

    setIsLoading(true)

    // Update FCA_Config on Application
    let newFilterConfig = {
      providers: [],
      facilities: [],
    }
    if (instance.fca_config.fca_filters) {
      newFilterConfig = {
        providers: [...instance.fca_config.fca_filters.providers],
        facilities: [...instance.fca_config.fca_filters.facilities],
      }
    }

    let newFilter = {
      api_parameter: parameterName,
      display_name: filterDisplayOptions.title,
      display_order:
        state.type === 'providers'
          ? newFilterConfig.providers.length
          : newFilterConfig.facilities.length,
      enabled: true,
      filter_config: {
        field_type: fieldType.toLowerCase(),
        filter_type: filterType.toLowerCase(),
        ...filterDisplayOptions,
      },
      is_more: true,
      is_custom: true,
      requires_submodules: [],
      uuid: parameterName,
    }
    console.log(`🚀 => createFilterOptions =>`, createFilterOptions)
    try {
      if (state.type === 'providers') {
        const createResult = await services.ribbon.createProvidersFilter(
          authState.token,
          instance.uuid,
          createFilterOptions,
        )

        console.log('providers:createResult :>> ', createResult)
        // INFO: UUID is currently not returned from Create Filter only on providers res
        if (createResult.data && createResult.data.uuid) {
          newFilter.uuid = createResult.data.uuid
        }
        newFilterConfig.providers.push(newFilter)
      } else {
        // Create Locations Filter
        const createResult = await services.ribbon.createLocationsFilter(
          authState.token,
          instance.uuid,
          createFilterOptions,
        )
        console.log('Locations:createResult :>> ', createResult)
        if (createResult.data && createResult.data.uuid) {
          newFilter.uuid = createResult.data.uuid
        }
        newFilterConfig.facilities.push(newFilter)
      }

      manageInstance({
        uuid: instance.uuid,
        options: {
          fca_filters: newFilterConfig,
        },
      })
    } catch (error) {
      setIsLoading(false)
      notyf.error(error.message)
    }
  }

  const onClickNextStep = () => {
    if (currentStep === 0) {
      setCurrentStep(1)
    } else {
      createFilter()
    }
  }

  const onClickCancelAction = () => {
    if (currentStep === 0) {
      handleClose()
    } else {
      setCurrentStep(0)
      dispatch({ type: 'resetDisplayOptions' })
    }
  }

  let configureDisplayBtnIsDisabled = true
  let createFilterBtnIsDisabled = true

  if (
    parameterName.length > 0 &&
    fieldName.length > 0 &&
    fieldType.length > 0 &&
    filterType.length > 0
  ) {
    if (onlyAlphaNumericRegex.test(parameterName)) {
      configureDisplayBtnIsDisabled = false
    }
  }

  if (filterDisplayOptions.title.length > 0) {
    if (
      filterDisplayOptions.options.every(option => {
        if (option.display.length > 0 && option.value.length > 0) {
          return true
        }

        return false
      })
    ) {
      createFilterBtnIsDisabled = false
    }
  }

  return (
    <Modal
      onClose={handleClose}
      gridContainerStyle={{ maxWidth: '600px' }}
      title={
        currentStep === 0
          ? `Add a new custom filter`
          : `Configure filter display`
      }
      footer={
        <Flex
          s={p => ({
            padding: p.theme.spacing.two,
            marginRight: p.theme.sizes.six,
            justifyContent: 'flex-end',
          })}
        >
          <Button
            s={() => ({
              marginRight: '1rem',
            })}
            type={BUTTON_TYPE.LINK}
            onClick={onClickCancelAction}
            disabled={isLoading || isSaving}
          >
            {currentStep === 0 ? `Cancel` : `Back`}
          </Button>
          <Button
            onClick={onClickNextStep}
            type={
              currentStep === 0
                ? configureDisplayBtnIsDisabled
                  ? BUTTON_TYPE.DISABLED
                  : BUTTON_TYPE.PRIMARY
                : createFilterBtnIsDisabled
                ? BUTTON_TYPE.DISABLED
                : BUTTON_TYPE.PRIMARY
            }
            size={BUTTON_SIZE.LARGE}
            disabled={
              (currentStep === 1 && createFilterBtnIsDisabled) ||
              (currentStep === 0 && configureDisplayBtnIsDisabled) ||
              isLoading ||
              isSaving
            }
            loading={isLoading || isSaving}
            s={() => ({
              minWidth: '110px',
            })}
          >
            {currentStep === 0 ? 'Next: Configure Display' : 'Create Filter'}
          </Button>
        </Flex>
      }
    >
      {isLoading ? (
        <Spinner label={`Creating filter..`} />
      ) : (
        <>
          {currentStep === 0 ? (
            <AddNewFilterForm
              state={state}
              dispatch={dispatch}
              paramIsInvalid={paramIsInvalid}
            />
          ) : (
            <ConfigureFilterDisplayForm state={state} dispatch={dispatch} />
          )}
        </>
      )}
    </Modal>
  )
}

const AddNewFilterForm = ({
  state = {},
  dispatch = () => {},
  paramIsInvalid = false,
}) => {
  const { parameterName, type, fieldName, fieldType, filterType } = state

  const onChangeText = e => {
    const { name, value } = e.target
    if (name === 'fieldType') {
      const filterTypeOptions = filterTypesByFieldSelected(value)
      dispatch({
        type: 'update',
        payload: {
          [name]: value,
          filterType: '', // default clear filterType
          ...(filterTypeOptions.length === 1 && {
            filterType: filterTypeOptions[0].value, // only one option - default set option
          }),
        },
      })
    } else {
      dispatch({
        type: 'update',
        payload: {
          [name]: value,
        },
      })
    }
  }
  const onChangeType = value => {
    dispatch({ type: 'update', payload: { type: value } })
  }

  return (
    <FormContainer>
      {['Providers', 'Facilities'].map((item, index) => {
        return (
          <RadioOption
            s={p => ({
              marginBottom: index === 0 ? p.theme.sizes.five : 0,
            })}
            key={item}
            checked={type === item.toLowerCase()}
            label={item}
            onClick={() => onChangeType(item.toLowerCase())}
          />
        )
      })}

      <Border />
      <Label type={LABEL_TYPE.BODY1_BOLD}>Parameter name</Label>
      <Input
        s={p => ({ marginTop: p.theme.sizes.two, maxWidth: '50%' })}
        onInputChange={onChangeText}
        name="parameterName"
        value={[{ label: parameterName }]}
        showClearIcon={false}
        type={INPUT_TYPE.ROUND}
        placeholder="Enter parameter name"
      />
      {paramIsInvalid && (
        <Label
          s={p => ({
            color: p.theme.colors.errorRed,
            marginTop: p.theme.sizes.one,
            fontSize: p.theme.fontSize.p,
          })}
        >
          You can not include any special characters or spaces in the parameter.
        </Label>
      )}
      <Label
        type={LABEL_TYPE.BODY1}
        s={p => ({
          color: p.theme.colors.gray3,
          marginTop: p.theme.sizes.six,
        })}
      >
        {`/v1/custom/${type === 'facilities' ? 'locations' : 'providers'}?`}
        <URLParam value={parameterName} error={paramIsInvalid}>
          {parameterName}
        </URLParam>
      </Label>
      <Border />

      <Block
        type="FLEX"
        s={() => ({
          padding: 0,
          justifyContent: 'space-between',
        })}
      >
        <InputContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Field</Label>
          <Input
            s={p => ({ marginTop: p.theme.sizes.two })}
            onInputChange={onChangeText}
            name="fieldName"
            value={[{ label: fieldName }]}
            showClearIcon={false}
            type={INPUT_TYPE.ROUND}
            placeholder="Enter field"
          />
        </InputContainer>
        <InputContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Field Type</Label>
          <SelectInput
            onChange={onChangeText}
            name="fieldType"
            value={fieldType || ''}
            options={filterFieldTypes}
            disabled={false}
            containerStyle={{ marginTop: '0.5em' }}
            placeholder="Enter field type"
          />
        </InputContainer>
      </Block>
      <InputContainer>
        <Label type={LABEL_TYPE.BODY1_BOLD}>Filter Type</Label>

        <SelectInput
          onChange={onChangeText}
          name="filterType"
          value={filterType ? filterTypes[filterType].label : ''}
          options={filterTypesByFieldSelected(fieldType)}
          disabled={false}
          containerStyle={{ marginTop: '0.5em', maxWidth: '50%' }}
          placeholder="Enter filter type"
        />
      </InputContainer>
    </FormContainer>
  )
}

const ConfigureFilterDisplayForm = ({ state = {}, dispatch = () => {} }) => {
  const { fieldType, filterDisplayOptions } = state

  const { title, options } = filterDisplayOptions

  const onChangeText = (e, index) => {
    const { name, value } = e.target
    if (index !== undefined) {
      dispatch({
        type: 'updateDisplayOptions',
        payload: {
          index,
          value: {
            ...options[index],
            [name]: value,
          },
        },
      })
    } else {
      dispatch({
        type: 'update',
        payload: {
          filterDisplayOptions: {
            ...filterDisplayOptions,
            [name]: value,
          },
        },
      })
    }
  }

  const onClickAddAnotherOption = () => {
    dispatch({
      type: 'addNewOption',
    })
  }
  const onClickRemoveOption = index => {
    dispatch({
      type: 'removeOption',
      payload: {
        index,
      },
    })
  }

  const filterDisplayType = filterDisplayTypeByFieldSelected(fieldType)

  return (
    <FormContainer>
      <Block
        s={() => ({
          padding: 0,
        })}
      >
        <Label
          s={p => ({
            color: p.theme.colors.gray0,
            fontWeight: '400',
          })}
        >{`${fieldType} Filter`}</Label>
        <Label
          s={p => ({
            color: p.theme.colors.gray2,
            marginTop: p.theme.sizes.two,
          })}
        >
          {getFilterLabelByFieldSelected(fieldType)}
        </Label>
      </Block>
      <Border />
      <InputContainer>
        <Label type={LABEL_TYPE.BODY1_BOLD}>Filter Title</Label>
        <Input
          s={p => ({ marginTop: p.theme.sizes.two, maxWidth: '50%' })}
          onInputChange={onChangeText}
          name="title"
          value={[{ label: title }]}
          showClearIcon={false}
          type={INPUT_TYPE.ROUND}
          placeholder="Enter filter title"
        />
      </InputContainer>

      <Label
        s={p => ({
          fontWeight: '700',
          marginBottom: p.theme.sizes.six,
          marginTop: p.theme.sizes.six,
        })}
      >
        Options
      </Label>
      {options.map((option, index) => {
        const { display, value } = option
        return (
          <div key={index}>
            <Block
              type="FLEX"
              s={() => ({
                padding: 0,
                alignItems: 'center',
                justifyContent: 'space-between',
              })}
            >
              <InputContainer>
                <Label type={LABEL_TYPE.BODY1_BOLD}>{`Option ${
                  index + 1
                } Display`}</Label>
                <Input
                  s={p => ({ marginTop: p.theme.sizes.two })}
                  onInputChange={e => onChangeText(e, index)}
                  name="display"
                  value={[{ label: display }]}
                  showClearIcon={false}
                  type={INPUT_TYPE.ROUND}
                  placeholder="Enter filter display"
                />
              </InputContainer>
              <InputContainer>
                <Label type={LABEL_TYPE.BODY1_BOLD}>{`Option ${
                  index + 1
                } Value`}</Label>
                <Input
                  s={p => ({ marginTop: p.theme.sizes.two })}
                  onInputChange={e => onChangeText(e, index)}
                  name="value"
                  value={[{ label: value }]}
                  showClearIcon={false}
                  type={INPUT_TYPE.ROUND}
                  placeholder="Enter filter value"
                  inputType={
                    fieldType === FIELD_TYPES_FLAT.integer ||
                    fieldType === FIELD_TYPES_FLAT.float
                      ? 'number'
                      : 'text'
                  }
                  step={fieldType === FIELD_TYPES_FLAT.float ? '0.1' : '1'}
                />
              </InputContainer>
            </Block>
            {options.length !== 1 && (
              <Label
                type={LABEL_TYPE.LINK}
                onClick={() => onClickRemoveOption(index)}
                s={p => ({
                  color: p.theme.colors.aubergine,
                  fontWeight: '500',
                  marginBottom: p.theme.sizes.four,
                })}
              >
                Remove option
              </Label>
            )}
          </div>
        )
      })}
      <>
        {filterDisplayType !== 'single' && (
          <Label
            type={LABEL_TYPE.LINK}
            onClick={onClickAddAnotherOption}
            s={p => ({
              color: p.theme.colors.aubergine,
              marginTop: p.theme.sizes.four,
              fontWeight: '500',
            })}
          >
            Add another option
          </Label>
        )}
      </>

      <Border />
      <Label
        s={() => ({
          fontWeight: '700',
        })}
      >
        Preview
      </Label>
      <PreviewDisplayOptions
        displayOptions={filterDisplayOptions}
        displayType={filterDisplayType}
      />
    </FormContainer>
  )
}

const PreviewDisplayOptions = ({
  displayOptions = { title: '', options: [] },
  displayType = '',
}) => {
  if (displayType === 'single-select') {
    return (
      <SelectFilter
        label={displayOptions.title || '{{Field Title}}'}
        onChange={option => console.log(option)}
        selected={null}
        options={displayOptions.options.map(item => ({
          label: item.display,
          value: item.value,
        }))}
        renderOption={option => (
          <RadioOption label={option.label || '{{Option Display}}'} />
        )}
      />
    )
  }
  if (displayType === 'single' || displayType === 'multi-select') {
    return (
      <MultiSelectFilter
        label={displayOptions.title || '{{Field Title}}'}
        onChange={value => console.log(value)}
        selected={null}
        options={displayOptions.options.map(
          item => item.display || '{{Option Display}}',
        )}
      />
    )
  }

  return <></>
}

const InputContainer = styled.div`
  flex: 0.49;
  margin-bottom: ${p => p.theme.sizes.four};
`

const URLParam = styled.span`
  ${p => ({
    ...(p.value && { color: p.theme.colors.gray1 }),
    ...(p.error && { color: p.theme.colors.errorRed }),
  })}
`

const Border = styled.div`
  height: 1px;
  min-height: 1px;
  width: 100%;
  background-color: ${p => p.theme.colors.gray4};
  margin: ${p => p.theme.sizes.six} 0;
`

const FormContainer = styled(Block)`
  padding: ${p => p.theme.sizes.four};
  padding-bottom: ${p => p.theme.sizes.twelve};
  min-height: 500px;
`
