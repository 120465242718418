/* eslint-disable react/prop-types */
import React, { createContext, useReducer } from 'react'

const defaultState = false

const reducer = (state, nextValue) =>
  typeof nextValue === 'boolean' ? nextValue : !state

export const HasChangesContext = createContext(defaultState)

export const HasChangesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState)

  return (
    <HasChangesContext.Provider value={[state, dispatch]}>
      {children}
    </HasChangesContext.Provider>
  )
}

export const HasChangesConsumer = HasChangesContext.Consumer
