import React, { useState } from 'react'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import { DataCard } from '../components/DataCard'
import styled from 'styled-components'
import { SearchTypeColumn } from './components/SearchTypeColumn'
import { ViewAllOfTypeModal } from './components/ViewAllOfTypeModal'
import { FilterByTypeModal } from './components/FilterByTypeModal'

const mockProviderData = [
  {
    display: 'Cardiologist',
    value: '1,000',
  },
  {
    display: 'Oncologist',
    value: '1,000',
  },
  {
    display: 'Nurses',
    value: '1,000',
  },
]

const mockByConfidence = [
  {
    display: '5.0',
    value: '1,000',
  },
  {
    display: '4.0',
    value: '1,000',
  },
  {
    display: '3.0',
    value: '1,000',
  },
  {
    display: '2.0',
    value: '1,000',
  },
  {
    display: '1.0',
    value: '1,000',
  },
]

const mockTypeOptions = index => ({
  display: `Type ${index}`,
  value: '1,000,000',
})
const createTestData = (size = 20) => {
  const res = []
  for (let index = 0; index < size; index++) {
    res.push(mockTypeOptions(index))
  }
  return res
}

const mockTypes = createTestData(100)

export const DataOverview = () => {
  const [viewAllType, setViewAllType] = useState()
  const [selectedProviderTypes, setSelectedProviderTypes] = useState([])
  const [selectedLocationTypes, setSelectedLocationTypes] = useState([])

  const onClickType = (value, type) => {
    if (type === 'Provider') {
      if (value === 'all') {
        setSelectedProviderTypes(prev => {
          if (mockTypes.length === prev.length) {
            return []
          }
          return mockTypes.map(i => i.display)
        })
        return
      }
      setSelectedProviderTypes(prev => {
        const prevCopy = prev.slice()
        const foundValueIndex = prevCopy.findIndex(i => i === value)
        if (foundValueIndex > -1) {
          prevCopy.splice(foundValueIndex, 1)
        } else {
          prevCopy.push(value)
        }
        return prevCopy
      })
    }
    if (type === 'Location') {
      if (value === 'all') {
        setSelectedLocationTypes(prev => {
          if (mockTypes.length === prev.length) {
            return []
          }
          return mockTypes.map(i => i.display)
        })
        return
      }
      setSelectedLocationTypes(prev => {
        const prevCopy = prev.slice()
        const foundValueIndex = prevCopy.findIndex(i => i === value)
        if (foundValueIndex > -1) {
          prevCopy.splice(foundValueIndex, 1)
        } else {
          prevCopy.push(value)
        }
        return prevCopy
      })
    }
  }

  const onClickViewAll = type => {
    setViewAllType(type)
  }

  return (
    <>
      <Label
        s={() => ({
          fontWeight: '600',
          fontSize: '1.5em',
        })}
      >
        Data Overview
      </Label>
      <OverviewHeader title="Providers" description="1,000,000 total" />
      <Flex
        s={p => ({
          marginTop: p.theme.sizes.six,
        })}
      >
        <Flex
          s={() => ({
            flex: 1,
            flexDirection: 'column',
          })}
        >
          <DataCard
            s={() => ({
              flex: 1,
            })}
          >
            <DataRowContainer>
              <Label
                s={p => ({
                  fontWeight: '500',
                  color: p.theme.colors.gray0,
                })}
              >
                Specialties
              </Label>
              <Label
                s={p => ({
                  fontWeight: '600',
                  color: p.theme.colors.aubergine2,
                })}
                onClick={() => onClickViewAll('Specialty')}
              >
                View All
              </Label>
            </DataRowContainer>
            {mockProviderData.map(item => {
              return (
                <DataRow
                  key={item.display}
                  display={item.display}
                  value={item.value}
                />
              )
            })}
          </DataCard>
          <DataCard
            s={p => ({
              flex: 1,
              marginTop: p.theme.sizes.six,
            })}
          >
            <DataRowContainer>
              <Label s={() => ({ fontWeight: '500' })}>
                Providers by Max Confidence
              </Label>
            </DataRowContainer>
            {mockByConfidence.map(item => {
              return (
                <DataRow
                  key={item.display}
                  display={item.display}
                  value={item.value}
                />
              )
            })}
          </DataCard>
        </Flex>
        <SearchTypeColumn
          type="Provider"
          selectedTypes={selectedProviderTypes}
          onClickType={onClickType}
          onClickViewAll={onClickViewAll}
          data={mockTypes}
        />
      </Flex>

      <OverviewHeader title="Locations" description="1,000,000 total" />

      <Flex
        s={p => ({
          flex: 1,
          marginTop: p.theme.sizes.six,
        })}
      >
        <DataCard
          s={() => ({
            flex: 1,
          })}
        >
          <DataRowContainer>
            <Label s={() => ({ fontWeight: '500' })}>
              Locations by Max Confidence
            </Label>
          </DataRowContainer>
          {mockByConfidence.map(item => {
            return (
              <DataRow
                key={item.display}
                display={item.display}
                value={item.value}
              />
            )
          })}
        </DataCard>
        <SearchTypeColumn
          type="Location"
          selectedTypes={selectedLocationTypes}
          onClickType={onClickType}
          onClickViewAll={onClickViewAll}
          data={mockTypes}
        />
      </Flex>
      {viewAllType && viewAllType === 'Specialty' && (
        <ViewAllOfTypeModal
          closeModal={() => setViewAllType()}
          type={viewAllType}
          filteringBy={selectedProviderTypes}
        />
      )}
      {viewAllType && viewAllType === 'Provider' && (
        <FilterByTypeModal
          closeModal={() => setViewAllType()}
          type={viewAllType}
          selectedTypes={selectedProviderTypes}
          onClickType={onClickType}
          data={mockTypes}
        />
      )}
      {viewAllType && viewAllType === 'Location' && (
        <FilterByTypeModal
          closeModal={() => setViewAllType()}
          type={viewAllType}
          selectedTypes={selectedLocationTypes}
          onClickType={onClickType}
          data={mockTypes}
        />
      )}
    </>
  )
}

const OverviewHeader = ({ title = 'Title', description = 'Description' }) => {
  return (
    <>
      <Label
        s={p => ({
          fontWeight: '600',
          fontSize: '1.125em',
          marginTop: p.theme.sizes.six,
        })}
      >
        {title}
      </Label>
      <Label
        s={p => ({
          color: p.theme.colors.gray2,
          marginTop: p.theme.sizes.two,
        })}
      >
        {description}
      </Label>
    </>
  )
}
const DataRowContainer = styled(Flex)`
  justify-content: space-between;
  padding: ${p => p.theme.sizes.four};
`

const DataRow = ({ display = 'Display', value = 'Value' }) => {
  return (
    <DataRowContainer>
      <Label
        s={p => ({
          color: p.theme.colors.gray0,
          fontWeight: '500',
        })}
      >
        {display}
      </Label>
      <Label
        s={p => ({
          color: p.theme.colors.gray2,
        })}
      >
        {value}
      </Label>
    </DataRowContainer>
  )
}
