import algoliasearch, {SearchIndex} from 'algoliasearch'

type Options = {
  appId: string
  apiKey: string
  appName: string
  inputValue: string
  userId: number
}

export type SpecialtyObject = {
  count: number
  display_name: string
  objectID: string
  type: 'specialty'
  uuid: string
  include: string[]
  user_id: string
}

export type AlgoliaSpecialtyResponse = SpecialtyObject & {
  _highlighResult: {
    display_name: {
      value: string
      matchLevel: string
      fullyHighlighted: false
      matchedWords: string[]
    }
    value: string
  }
}

export type SpecialtyResultParent = AlgoliaSpecialtyResponse & {
  childSpecialties: AlgoliaSpecialtyResponse[]
}

export type SpecialtySearchResult = AlgoliaSpecialtyResponse & {
  isParent: boolean
}

const listContainsSpecialty = (
  specialtyList: Array<SpecialtyObject | AlgoliaSpecialtyResponse>,
  specialtyUuid: string,
): boolean => {
  const specialtyListUuids = new Set(specialtyList.map(s => s.uuid))
  return specialtyListUuids.has(specialtyUuid)
}

const fetchSubSpecialties = async (
  subSpecialtyUuids: Array<string>,
  algoliaIndex: SearchIndex,
  userId: number,
) => {
  if (!subSpecialtyUuids) {
    return new Map()
  }

  const subSpecialtyObjectIDs = subSpecialtyUuids.map(uuid => `spec-${userId}-${uuid}`)
  const subSpecialties = await algoliaIndex.getObjects<SpecialtyObject>(subSpecialtyObjectIDs)
  const subSpecialtiesMap = new Map(subSpecialties.results
    .filter(obj => !!obj)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .map(subSpec => [subSpec.uuid, subSpec]))
  return subSpecialtiesMap
}

const getSubSpecialtyToParentMap = (parentSpecialties: Array<AlgoliaSpecialtyResponse>) => {
  const subSpecialtyParentMap = new Map()
  if (!parentSpecialties) {
    return subSpecialtyParentMap
  }

  for (const parent of parentSpecialties) {
    for (const subSpecialtyUuid of parent.include) {
      if (subSpecialtyUuid !== parent.uuid) {
        subSpecialtyParentMap.set(subSpecialtyUuid, parent)
      }
    }
  }

  return subSpecialtyParentMap
}

const getParentSpecialties = (specialties: Array<AlgoliaSpecialtyResponse>): Array<AlgoliaSpecialtyResponse> => {
  return specialties.filter(s => new Set(s.include).size > 1)
}

const groupAndSortSpecialties = async (
  specialties: Array<AlgoliaSpecialtyResponse>,
  algoliaIndex: SearchIndex,
  userId: number,
): Promise<SpecialtyResultParent[]> => {
  const parentSpecialties = getParentSpecialties(specialties)
  const subSpecialtyParentMap = getSubSpecialtyToParentMap(parentSpecialties)
  const subSpecialtiesMap = await fetchSubSpecialties(Array.from(subSpecialtyParentMap.keys()), algoliaIndex, userId)
  const allMatchedSpecialties = []
  const additionalSpecialties = []

  for (const specialty of specialties) {
    const processedSpecialty = {...specialty, childSpecialties: []}
    if (subSpecialtiesMap.has(processedSpecialty.uuid)) {
      // If specialty is a sub-specialty, ensure parent is in list and add subspecialty to the parent in order
      const parentSpecialty = subSpecialtyParentMap.get(processedSpecialty.uuid)
      let foundParent = allMatchedSpecialties.find(spec => spec.uuid === parentSpecialty.uuid)
      if (!foundParent) {
        parentSpecialty.childSpecialties = []
        allMatchedSpecialties.push(parentSpecialty)
        foundParent = parentSpecialty
      }
      foundParent.childSpecialties.push(processedSpecialty)
    } else if (listContainsSpecialty(parentSpecialties, processedSpecialty.uuid)) {
      // If specialty is a parent specialty, add it to the list if not already present
      if (!listContainsSpecialty(allMatchedSpecialties, processedSpecialty.uuid)) {
        allMatchedSpecialties.push(processedSpecialty)
      }
    } else {
      // Specialty is neither a child nor a parent. Add it to the additional specialties list.
      additionalSpecialties.push(processedSpecialty)
    }
  }

  // For all parent specialties, add in all remaining child specialties that were not matched in the search
  for (const parentSpecialty of parentSpecialties) {
    const parentInList = allMatchedSpecialties.find(specialty => specialty.uuid === parentSpecialty.uuid)
    // Some parent specialties may also be subspecialties of other parents already in the list, in which case
    // they won't be in allMatchedSpecialties as a "parent" specialty. Skip this parent specialty.
    if (!parentInList) {
      continue
    }

    for (const includedSubSpecialtyUuid of parentInList.include) {
      if (!listContainsSpecialty(parentInList.childSpecialties, includedSubSpecialtyUuid)
        && includedSubSpecialtyUuid !== parentSpecialty.uuid
        && subSpecialtiesMap.has(includedSubSpecialtyUuid)
      ) {
        parentInList.childSpecialties.push(subSpecialtiesMap.get(includedSubSpecialtyUuid))
      }
    }
  }

  if (additionalSpecialties.length > 0) {
    allMatchedSpecialties.push({display_name: "Additional specialties", childSpecialties: additionalSpecialties})
  }
  return allMatchedSpecialties
}

const getFinalSpecialtiesList = (specialtiesList: Array<SpecialtyResultParent>): Array<SpecialtySearchResult> => {
  const finalSpecialtiesList = []
  for (let i=0; i < specialtiesList.length; i++) {
    const parentSpecialty = specialtiesList[i]
    if (parentSpecialty.display_name === 'Additional specialties' && i === 0) {
      parentSpecialty.display_name = 'Matching specialties'
    }
    finalSpecialtiesList.push({...parentSpecialty, isParent: true})
    finalSpecialtiesList.push(
      ...(parentSpecialty.childSpecialties ?? []).map(spec => {return {...spec, isParent: false}}))
  }

  return finalSpecialtiesList
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const searchAlgoliaSpecialties = async (options: Options) => {
  const { apiKey, appId, appName, inputValue, userId } = options
  const searchClient = algoliasearch(appId, apiKey)
  const index = searchClient.initIndex(appName)
  const results = await index.search<AlgoliaSpecialtyResponse>(inputValue, {
    hitsPerPage: 10,
    facetFilters: ['type: specialty'],
    highlightPreTag: '<mark>',
    highlightPostTag: '</mark>',
  })
  const groupedSpecialties = await groupAndSortSpecialties(results.hits, index, userId)
  const finalSpecialties = getFinalSpecialtiesList(groupedSpecialties)

  return finalSpecialties
}
