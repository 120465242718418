export const formatEducation = (
  educationObj = { education: {}, type: '', year: '' },
) => {
  const result = `${educationObj.type || '-'}, ${
    educationObj.education.name
  }, ${educationObj.year || '-'}`

  return result
}

export const formatLanguage = language => {
  if (!language) {
    return '-'
  }

  if (language.length < 1) {
    return language
  }

  return `${language[0].toUpperCase()}${language.slice(1)}`
}

export const formatLanguages = (languages = []) => {
  if (languages.length > 0) {
    return languages.map(l => `${formatLanguage(l)}`).join(', ')
  }
  return '-'
}

export const formatAgeRange = age => {
  if (age < 41) {
    return 'under 40'
  } else if (age > 40 && age < 61) {
    return 'between 40 and 60'
  } else if (age > 60) {
    return 'over 60'
  }
  return '-'
}
