import React from 'react'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { Modal } from 'design-system/modal'
import { Label } from 'design-system/label'
import { Flex } from 'design-system/flex'
import { JsonView } from 'components/json-view/json-view'
import dayjs from 'utils/dayjs'

export const ViewLogModal = ({ closeModal = () => {}, log = {} }) => {
  return (
    <Modal
      onClose={closeModal}
      title={`${log.status} ${log.method} ${log.url}`}
      gridContainerStyle={{ width: '600px' }}
      footer={
        <Flex
          s={() => ({
            justifyContent: 'flex-end',
            paddingRight: '2rem',
          })}
        >
          <Button
            s={() => ({
              marginRight: '1rem',
            })}
            type={BUTTON_TYPE.LINK}
            onClick={closeModal}
          >
            Close
          </Button>
        </Flex>
      }
    >
      <Flex
        s={p => ({
          flexDirection: 'column',
          padding: p.theme.sizes.six,
        })}
      >
        <DataRow label="Status" value={log.status} />
        <DataRow label="Method" value={log.method} />
        <DataRow label="URL" value={log.url} />
        <DataRow
          label="Created at"
          value={dayjs(log.created_at).format('lll')}
        />
        {log.request_body && (
          <DataRow
            label="Request"
            renderValue={() => <JsonView data={log.request_body} index={0} />}
          />
        )}
      </Flex>
    </Modal>
  )
}

const DataRow = ({ label = 'Label', value = 'Value', renderValue = null }) => {
  return (
    <Flex
      s={p => ({
        marginBottom: p.theme.sizes.four,
      })}
    >
      <Label
        s={p => ({
          minWidth: '100px',
          textAlign: 'right',
          marginRight: p.theme.sizes.ten,
          fontWeight: '500',
          color: p.theme.colors.gray0,
        })}
      >
        {label}
      </Label>
      {renderValue ? (
        renderValue()
      ) : (
        <Label
          s={p => ({
            color: p.theme.colors.gray1,
          })}
        >
          {value}
        </Label>
      )}
    </Flex>
  )
}
