import algoliasearch from 'algoliasearch'

type Options = {
  appId: string,
  apiKey: string,
  appName: string,
  inputValue: string
  options: SearchInsurancesOptions
}
export type SearchInsurancesOptions = {
  hitsPerPage: number
  facetFilters: Array<string>
  page: number
  distinct: boolean
}

export type AlgoliaInsuranceResponse = {
  carrier_name: string
  display_name: string
  objectID: string
  type: string
  user_id: number | string
  uuid: string
  _highlighResult: Record<string, string>
  _metadata: {
    carrier_association: string
    carrier_brand: string
    carrier_name: string
    category: string
    codes: string[]
    confidence: number
    metal_level: string
    network: null
    plan_name: string
    plan_type: string
    state: string
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const searchAlgoliaInsurances = async (options: Options) => {
  const searchClient = algoliasearch(options.appId, options.apiKey)
  const index = searchClient.initIndex(options.appName)
  const results = await index.search<AlgoliaInsuranceResponse[]>(
    options.inputValue,
    {
      ...options.options,
    },
  )

  return results.hits
}
