import React, { useState } from 'react'
import { Modal } from 'design-system/modal'
import { Block } from 'design-system/block'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label, LABEL_TYPE } from 'design-system/label'
import styled from 'styled-components'
import { Spinner } from 'design-system/spinner/spinner.component'

export const RemoveUsersModal = ({
  handleClose = () => {},
  activeUsers = [],
  handleRemoveUsers = async () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const onClickRemoveUsers = async () => {
    console.log(`🚀 => onClickRemoveUsers => activeUsers`, activeUsers)

    // API call to delete Instance
    setIsLoading(true)
    try {
      await handleRemoveUsers(activeUsers)
      setIsLoading(false)
      handleClose()
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      onClose={handleClose}
      title={`Remove ${activeUsers.length > 1 ? 'users' : 'user'}`}
      gridContainerStyle={{ maxWidth: '600px' }}
      footer={
        <Block
          type="FLEX"
          s={p => ({
            marginRight: p.theme.sizes.six,
            justifyContent: 'flex-end',
          })}
        >
          <Button
            s={() => ({
              marginRight: '1rem',
            })}
            type={BUTTON_TYPE.LINK}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            onClick={onClickRemoveUsers}
            type={BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.LARGE}
            disabled={isLoading}
            s={() => ({
              minWidth: '110px',
            })}
          >
            Confirm
          </Button>
        </Block>
      }
    >
      {isLoading ? (
        <Spinner
          label={`Removing ${activeUsers.length > 1 ? 'users' : 'user'}`}
        />
      ) : (
        <FormContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>
            {`Are you sure you want to delete ${
              activeUsers.length > 1
                ? `${activeUsers.length} users`
                : 'this user'
            }?`}
          </Label>
        </FormContainer>
      )}
    </Modal>
  )
}

const FormContainer = styled(Block)`
  padding: ${p => p.theme.sizes.four};
`
