export const reducerTypes = {
  update: 'update',
  toggle_display_config: 'toggle_display_config',
}

export const editCustomFieldReducer = (state, action) => {
  const { type, payload } = action
  // console.log(`editCustomFieldReducer:action`, action)
  switch (type) {
    case reducerTypes.update:
      return {
        ...state,
        ...payload,
      }
    case reducerTypes.toggle_display_config:
      return {
        ...state,
        configureDisplay: !state.configureDisplay,
      }

    default:
      return state
  }
}
