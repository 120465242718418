import React, { useRef, useState } from 'react'
import { Block } from 'design-system/block'
import { Label } from 'design-system/label'
import styled from 'styled-components'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { RadioOption } from 'design-system/radio-option/radio-option'

const LogoOptions = [
  'Replace Ribbon logo',
  'Display both Ribbon and brand logo',
]

export const ManageInstanceBranding = () => {
  const [logoOptionIndex, setLogoOptionIndex] = useState(0)
  const [fileSelected, setFileSelected] = useState()
  const fileInputRef = useRef(null)

  const onClickUploadPhoto = () => {
    console.log('onClickUploadPhoto :>> ', fileInputRef.current)
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const onFileSelected = async e => {
    console.log('onFileSelected :>> ', e.target.files)
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      const url = URL.createObjectURL(file)
      setFileSelected(url)
    }
    const element = e.target
    element.value = ''
  }

  const onClickUploadFile = () => {
    console.log(`fileSelected`, fileSelected)
  }

  return (
    <Block
      type="FLEX"
      s={p => ({
        padding: `${p.theme.sizes.six} ${p.theme.sizes.six} `,
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        maxWidth: `${p.theme.flexboxgrid.container.lg}em`,
        height: 'max-content',
        overflow: 'auto',
      })}
    >
      <Block
        type="FLEX"
        s={() => ({
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 0,
        })}
      >
        <Label
          s={p => ({
            color: p.theme.colors.gray0,
            fontSize: p.theme.sizes.six,
            fontWeight: '700',
          })}
        >
          Branding
        </Label>
      </Block>

      <Block
        type="FLEX"
        s={p => ({
          padding: 0,
          marginTop: p.theme.sizes.six,
        })}
      >
        <Label
          s={p => ({
            fontWeight: '700',
            fontSize: p.theme.headingSizes.H3,
            minWidth: '250px',
            marginTop: p.theme.sizes.two,
          })}
        >
          Logo
        </Label>

        <input
          data-testid="branding-file-upload"
          type="file"
          ref={fileInputRef}
          onChange={onFileSelected}
          hidden
          accept="image/*"
        />

        {fileSelected ? (
          <Block
            s={() => ({
              padding: 0,
            })}
          >
            <Block
              type="FLEX"
              s={() => ({
                padding: 0,
                alignItems: 'center',
              })}
            >
              <UploadContainer
                s={p => ({
                  ...(fileSelected && {
                    backgroundImage: `url(${fileSelected})`,
                    backgroundColor: `${p.theme.colors.clear} !important`,
                    border: `1px solid ${p.theme.colors.gray4}`,
                  }),
                })}
              />
              <Block type="FLEX">
                <ActionButton onClick={() => setFileSelected()}>
                  <Label>Remove</Label>
                </ActionButton>
                <ActionButton onClick={onClickUploadFile}>
                  <Label>Upload</Label>
                </ActionButton>
              </Block>
            </Block>
            {LogoOptions.map((option, index) => {
              return (
                <RadioOption
                  key={option}
                  label={option}
                  checked={index === logoOptionIndex}
                  onClick={() => setLogoOptionIndex(index)}
                  s={p => ({
                    marginTop: p.theme.sizes.four,
                  })}
                />
              )
            })}
          </Block>
        ) : (
          <Button onClick={onClickUploadPhoto} type={BUTTON_TYPE.PRIMARY}>
            Upload Logo
          </Button>
        )}
      </Block>
    </Block>
  )
}

const UploadContainer = styled(Block)`
  padding: 0;
  background-color: ${p => p.theme.colors.gray5};
  width: 240px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  & > p {
    font-weight: 500;
    text-align: center;
  }
`

const ActionButton = styled(Button)`
  margin: ${p => p.theme.sizes.two} ${p => p.theme.sizes.four};
  > button {
    & > p {
      font-weight: bold;
    }
  }
`
ActionButton.defaultProps = {
  type: BUTTON_TYPE.LINK,
}
