import React from 'react'
import PropTypes from 'prop-types'
import { Block } from 'design-system/block'
import { Label, LABEL_TYPE } from 'design-system/label'
import { List } from 'design-system/list'

const ProviderDemographics = ({ panelDemographics }) => {
  if (
    !panelDemographics ||
    !panelDemographics.sexes ||
    !panelDemographics.ages
  ) {
    return null
  }
  return (
    <Block
      s={p => ({
        lineHeight: p.theme.sizes.five,
        padding: `${p.theme.sizes.six} 0 ${p.theme.sizes.two} 0`,
      })}
    >
      <Label
        type={LABEL_TYPE.BODY1}
        s={p => ({
          color: p.theme.colors.gray1,
          fontWeight: '700',
          fontSize: p.theme.headingSizes.H3,
        })}
      >
        Patient Focus
      </Label>
      <Block
        s={p => ({
          marginTop: p.theme.spacing.three,
          flexDirection: 'column',
          padding: 0,
        })}
      >
        <Label
          type={LABEL_TYPE.BODY1}
          s={p => ({
            color: p.theme.colors.gray,
            fontWeight: 500,
          })}
        >
          Ages
        </Label>
        {Array.isArray(panelDemographics.ages) &&
          panelDemographics.ages.map(age => {
            return (
              <Label
                key={age}
                type={LABEL_TYPE.BODY1}
                s={p => ({ color: p.theme.colors.gray1 })}
              >
                {age}
              </Label>
            )
          })}
      </Block>
      <List
        disableItemHoverStyle={true}
        s={p => ({
          marginTop: p.theme.spacing.three,
        })}
      >
        <Label
          type={LABEL_TYPE.BODY1}
          s={p => ({
            color: p.theme.colors.gray,
            fontWeight: 500,
          })}
        >
          Gender
        </Label>
        <Label
          type={LABEL_TYPE.BODY1}
          s={p => ({ color: p.theme.colors.gray1 })}
        >
          {typeof panelDemographics.sexes === 'string'
            ? panelDemographics.sexes
            : ''}
        </Label>
      </List>
    </Block>
  )
}

ProviderDemographics.propTypes = {
  panelDemographics: PropTypes.object,
}

export default ProviderDemographics
