import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import EmptySearchSVG from 'assets/empty-search.svg'
import { Label } from 'design-system/label'
import { Flex } from 'design-system/flex'
import { GlobalStateContext } from 'context/global-context-provider'
import { searchTypes } from 'utils/constants'
import { focusNextInput } from 'utils/document'

export const EmptySearchContainer = () => {
  const [searchState = {}] = useContext(GlobalStateContext)

  const handleStartSearch = () => {
    focusNextInput('location-input')
  }

  let hasValues = false
  const {
    location,
    insurance,
    searchType,
    providerName,
    facilityName,
    facilityType,
    specialties,
  } = searchState

  if (searchType === searchTypes.providers) {
    if (
      location ||
      insurance ||
      (specialties && specialties.length > 0) ||
      providerName
    ) {
      hasValues = true
    }
  }
  if (searchType === searchTypes.facilities) {
    if (location || insurance || facilityType || facilityName) {
      hasValues = true
    }
  }

  return (
    <Flex
      s={() => ({
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      })}
    >
      <EmptySearchSVG />

      <Label
        s={p => ({
          marginTop: p.theme.sizes.six,
          maxWidth: '420px',
          textAlign: 'center',
          color: p.theme.colors.gray2,
        })}
      >
        {`Get started by entering a location. You can search for relevant providers that are convenient and in-network.`}
      </Label>
      {!hasValues && (
        <Button
          type={BUTTON_TYPE.PRIMARY}
          size={BUTTON_SIZE.EXTRA_LARGE}
          s={p => ({ marginTop: p.theme.sizes.five, minWidth: '240px' })}
          onClick={handleStartSearch}
        >
          Start search
        </Button>
      )}
    </Flex>
  )
}

EmptySearchContainer.propTypes = {
  onClickStartSearch: PropTypes.func,
}
