export const debounce = <T extends any[]>(
  fn: (...args: T) => void,
  delay: number,
  atleastEveryMs = 0,
): ((...args: T) => void) => {
  let timeoutId: any
  let lastRunTime: any
  const run = (args: any) => {
    fn(...args)
    lastRunTime = new Date().getTime()
  }
  return function debounced(...args) {
    if (
      atleastEveryMs > 0 &&
      (!timeoutId || new Date().getTime() - lastRunTime > atleastEveryMs)
    ) {
      run(args)
    } else {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => run(args), delay)
    }
  }
}
