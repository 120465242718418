import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Popup from 'reactjs-popup'
import { Block } from 'design-system/block'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Flex } from 'design-system/flex'
import CloseSVG from 'assets/close-x.svg'
import { Button } from 'design-system/button'

const ModalHeaderContainer = styled(Flex)`
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: ${p => p.theme.sizes.four};
  border-bottom: 1px solid ${p => p.theme.colors.gray4};
  > div {
    flex: 1;
  }
  > h3 {
    flex: 1.5;
  }
`

const HeaderLeftContainer = styled(Flex)`
  justify-content: flex-start;
  > div {
    cursor: pointer;
    padding: ${p => p.theme.sizes.two} ${p => p.theme.sizes.two};
    border-radius: 4px;

    :hover {
      background-color: ${p => p.theme.colors.gray6};
    }
  }
`

const HeaderRightContainer = styled(Flex)`
  min-width: 32px;
  min-height: 32px;
  justify-content: flex-end;
`

const CloseBtn = styled(Button)`
  > button {
    padding: 0;
    height: 24px;
    width: 24px;
  }
  border-radius: 4px;
  border-color: transparent;
`

const ModalHeader = props => {
  return (
    <ModalHeaderContainer className={props.className}>
      {props.headerLeftAction ? (
        <HeaderLeftContainer>
          <div>{props.headerLeftAction}</div>
        </HeaderLeftContainer>
      ) : (
        <HeaderLeftContainer>
          {props.onClose && (
            <CloseBtn onClick={props.onClose} data-testid="close-modal-btn">
              <CloseSVG />
            </CloseBtn>
          )}
        </HeaderLeftContainer>
      )}

      <Heading
        type={HEADING_TYPE.H3}
        s={() => ({
          fontWeight: 700,
          textAlign: 'center',
        })}
      >
        {props.title}
      </Heading>

      <HeaderRightContainer>
        {props.headerRightAction || null}
      </HeaderRightContainer>
    </ModalHeaderContainer>
  )
}

ModalHeader.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  headerLeftAction: PropTypes.node,
  headerRightAction: PropTypes.node,
}

const ModalFooter = ({ children }) => {
  return (
    <Block
      s={p => ({
        padding: 0,
        width: '100%',
        position: 'relative',
        paddingTop: p.theme.sizes.four,
        paddingBottom: p.theme.sizes.four,
        borderTop: `1px solid ${p.theme.colors.gray4}`,
      })}
    >
      {children}
    </Block>
  )
}

ModalFooter.propTypes = {
  children: PropTypes.node,
}

const ModalContainer = styled(Block)`
  padding: 0;
  border-radius: 1.25rem;
  box-shadow: 0 ${p => p.theme.sizes.one} ${p => p.theme.sizes.one}
    rgba(211, 210, 210, 0.25);
  background: white;
  z-index: 999;
  max-width: 800px;
  width: 800px;

  @media (max-width: ${p => p.theme.flexboxgrid.breakpoints.sm}em) {
    max-width: 95%;
    width: auto;
  }

  ${p => ({
    ...p.gridContainerStyle,
  })}
`

const ModalContent = styled(Block)`
  padding: ${p => p.theme.spacing.three};
  max-height: 500px;
  overflow-y: auto;
`

export const Modal = ({
  onClose,
  nested,
  gridContainerStyle,
  header,
  headerLeftAction,
  headerRightAction,
  footer,
  title,
  children,
}) => {
  return (
    <Popup
      modal
      open
      onClose={onClose}
      nested={nested}
      overlayStyle={{
        background: 'rgba(0, 0, 0, 0.25)',
      }}
    >
      <ModalContainer gridContainerStyle={gridContainerStyle}>
        {header ? (
          <>{header}</>
        ) : (
          <ModalHeader
            headerLeftAction={headerLeftAction}
            headerRightAction={headerRightAction}
            title={title}
            onClose={onClose}
          />
        )}
        <ModalContent className="modalContent">{children}</ModalContent>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContainer>
    </Popup>
  )
}

Modal.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  footer: PropTypes.node,
  header: PropTypes.node,
  headerLeftAction: PropTypes.node,
  headerRightAction: PropTypes.node,
  gridContainerStyle: PropTypes.any,
  nested: PropTypes.bool,
}

Modal.displayName = 'Modal'

Modal.defaultProps = {
  gridContainerStyle: {},
  title: 'Title',
  nested: false,
}
