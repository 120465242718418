import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Header } from '../header/header'
import { Block } from 'design-system/block'
import styled from 'styled-components'
import { AccountDropdown } from 'components/account-dropdown/account-dropdown.component'

const Overlay = styled.div`
  ${p => ({
    zIndex: p.theme.zIndexes.modal,
  })}
  background: rgba(0, 0, 0, .2);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
`

export const Layout = ({ children, pageType }) => {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <>
      {showMenu && (
        <Overlay
          data-testid="account-dropdown-overlay"
          onClick={() => setShowMenu(false)}
        />
      )}
      {showMenu && <AccountDropdown setShowMenu={setShowMenu} />}
      <Header
        siteTitle={`Ribbon Health`}
        pageType={pageType}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
      />

      <Block
        s={p => ({
          padding: 0,
          height: '100%',
          // height of header
          paddingTop: p.theme.sizes.twelve,
        })}
      >
        {children}
      </Block>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  pageType: PropTypes.string,
}
