import React, { useCallback } from 'react'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Input, INPUT_TYPE } from 'design-system/input'
import { Label, LABEL_TYPE } from 'design-system/label'
import { SelectInput } from 'design-system/input/select-input'
import { AGE_OPTIONS, convertGenderFull, GENDER_OPTIONS } from 'utils/constants'
import styled from 'theme'
import { Flex } from 'design-system/flex'

export const ProviderDetails = ({
  editProvider = {},
  setEditProvider = () => {},
}) => {
  const { firstName, lastName, middleName, gender, age } = editProvider

  const onChangeText = useCallback(e => {
    // console.log('onChangeText', e)
    const { value, name } = e.target
    setEditProvider(prev => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }, [])

  return (
    <Flex
      s={() => ({
        flexDirection: 'column',
      })}
    >
      <Heading
        type={HEADING_TYPE.H2}
        s={p => ({
          marginBottom: p.theme.sizes.four,
        })}
      >
        Provider Details
      </Heading>
      <FormRow>
        <InputContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>First Name*</Label>
          <FormInput
            s={p => ({
              borderColor: !firstName
                ? p.theme.colors.errorRed
                : p.theme.colors.borderMid,
            })}
            onInputChange={onChangeText}
            name="firstName"
            value={[{ label: firstName }]}
            showClearIcon={false}
            type={INPUT_TYPE.ROUND}
            placeholder="First Name"
          />
        </InputContainer>
        <InputContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Middle Name</Label>
          <FormInput
            onInputChange={onChangeText}
            name="middleName"
            value={[{ label: middleName }]}
            showClearIcon={false}
            type={INPUT_TYPE.ROUND}
            placeholder="Middle Name"
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <InputContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Last Name*</Label>
          <FormInput
            s={p => ({
              borderColor: !lastName
                ? p.theme.colors.errorRed
                : p.theme.colors.borderMid,
            })}
            onInputChange={onChangeText}
            name="lastName"
            value={[{ label: lastName }]}
            showClearIcon={false}
            type={INPUT_TYPE.ROUND}
            placeholder="Last Name"
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <InputContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Gender</Label>
          <SelectInput
            onChange={onChangeText}
            name="gender"
            value={convertGenderFull(gender)}
            options={GENDER_OPTIONS}
            containerStyle={{ marginTop: '0.5rem' }}
            placeholder="Gender"
          />
        </InputContainer>
        <InputContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Age</Label>
          <SelectInput
            onChange={onChangeText}
            name="age"
            value={String(age || '')}
            options={AGE_OPTIONS}
            containerStyle={{ marginTop: '0.5rem' }}
            placeholder="Age"
          />
        </InputContainer>
      </FormRow>
    </Flex>
  )
}

const FormRow = styled(Flex)`
  margin-top: ${p => p.theme.sizes.four};
  justify-content: space-between;
`
const InputContainer = styled(Flex)`
  flex-direction: column;
  flex: 0.48;
`
const FormInput = styled(Input)`
  margin-top: ${p => p.theme.sizes.two};
`
