import React from 'react'
import { ChangePassword } from 'containers/change-password/change-password.container'
import { FacilityDetail } from 'containers/facility-detail/facility-detail.container'
import { Login } from 'containers/login/login.container'
import { ProviderDetail } from 'containers/provider-detail/provider-detail.container'
import { ResetPassword } from 'containers/reset-password/reset-password.container'
import { SearchWithContexts } from 'containers/search/search.container'
import { Instances } from 'containers/admin/instances/instances'
import { ManageInstance } from 'containers/admin/manage-instance/manage-instance'
import { AcceptInvitation } from 'containers/accept-invitation/accept-invitation'
import { PageError } from 'containers/page-error/page-error'
// Developer Dashboard Routes
import { DeveloperDashboard } from 'containers/developers/dashboard/DeveloperDashboard'
import { DeveloperData } from 'containers/developers/data/DeveloperData'
import { APIConfiguration } from 'containers/developers/api-configuration/APIConfiguration'
import { Logs } from 'containers/developers/instance-logs/Logs'
import { Syncing } from 'containers/developers/syncing/Syncing'
import { Tokens } from 'containers/developers/tokens/Tokens'
import { CostEstimates } from 'containers/cost-estimates/cost-estimates'

export type Route = {
  path: string
  component: React.FC
  name: string
  isDefault?: boolean
}

export const AuthRoutes: Route[] = [
  {
    path: '/login',
    component: Login,
    name: 'Login',
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    name: 'Reset Password',
  },
  {
    path: '/accept-invitation',
    component: AcceptInvitation,
    name: 'Accept Invitation',
  },
  {
    path: '/error',
    component: PageError,
    name: 'Error',
  },
]

export const AuthenticatedRoutes: Route[] = [
  {
    path: '/*',
    component: SearchWithContexts,
    name: 'Search',
    isDefault: true,
  },
  {
    path: '/cost-estimates',
    component: CostEstimates,
    name: 'CostEstimates',
  },
  {
    path: '/providers/:providerId/:locationId',
    component: ProviderDetail,
    name: 'Provider Detail',
  },
  {
    path: '/providers/:providerId',
    component: ProviderDetail,
    name: 'Provider Detail',
  },
  {
    path: '/facilities/:facilityId',
    component: FacilityDetail,
    name: 'Facility Detail',
  },
  // Page disabled
  // {
  //   path: '/users',
  //   component: UserManagement,
  //   name: 'User Management',
  // },
  {
    path: '/admin',
    component: Instances,
    name: 'Manage Instances',
  },
  {
    path: '/admin/instances/:instanceId',
    component: ManageInstance,
    name: 'Manage Instance',
  },
  {
    path: '/admin/instances/:instanceId/:tab',
    component: ManageInstance,
    name: 'Manage Instance',
  },
  {
    path: '/change-password',
    component: ChangePassword,
    name: 'Change Password',
  },
]

export const DeveloperDashboardRoutes: Route[] = [
  {
    path: '/',
    component: DeveloperDashboard,
    name: 'Developers Dashboard',
  },
  {
    path: '/:instanceId/',
    component: DeveloperDashboard,
    name: 'Developers Dashboard',
  },
  {
    path: '/:instanceId/data',
    component: DeveloperData,
    name: 'Developers Data',
  },
  {
    path: '/:instanceId/tokens',
    component: Tokens,
    name: 'Developers Tokens',
  },
  {
    path: '/:instanceId/logs',
    component: Logs,
    name: 'Developers Logs',
  },
  {
    path: '/:instanceId/api-configuration',
    component: APIConfiguration,
    name: 'Developers API Configuration',
  },
  {
    path: '/:instanceId/syncing',
    component: Syncing,
    name: 'Developers Syncing',
  },
]
