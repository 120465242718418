import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Label, LABEL_TYPE } from 'design-system/label'
import { SearchFilterSummary } from 'components/search-filter-summary/search-filter-summary.component'
import { Block } from 'design-system/block'
import { GlobalStateContext } from 'context/global-context-provider'
import { Flex } from 'design-system/flex'
import CostEstimatesIconSVG from 'assets/cost-estimates-icon.svg'
import { useToggle } from 'hooks/useToggle'
import { searchTypes } from 'utils/constants'
import { CostEstimatesPopup } from 'containers/cost-estimates/cost-estimate-popup'
import { useQueryClient } from 'react-query'
import { searchQueryKeys } from 'containers/search/useSearchQueries'
import { AuthStateContext } from 'context/auth-state-context'
import STATES from '../../../../static/constants/states.json'

const ResultsHeader = styled(Heading)`
  margin-bottom: ${p => p.theme.sizes.two};
`

const ProcedureCostIcon = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`

export const ProvidersSearchResultsSummary = () => {
  const queryClient = useQueryClient()
  const [searchState] = useContext(GlobalStateContext)
  const [authState] = useContext(AuthStateContext)
  const { modules } = authState
  const { providersSearchQuery } = searchState || {}
  const providerSearchResults = queryClient.getQueryData(
    searchQueryKeys.providersSearchWithQuery(providersSearchQuery),
  )
  const { name: providerName, location, specialties, insurance } =
    providersSearchQuery || {}
  const { metadata } = providerSearchResults || {}
  const [showProceduresCost, toggleProcedureCost] = useToggle(false)

  const { sortBy } = metadata || {}
  const hasResults = metadata && metadata.totalCount > 0
  const from = hasResults && (metadata.page - 1) * metadata.pageSize + 1
  const to =
    hasResults && Math.min(from + metadata.pageSize - 1, metadata.totalCount)

  let showProcedureCostIcon = false

  const costEstimatesModuleEnabled =
    modules && modules.cost_estimate && modules.cost_estimate.enabled

  if (
    costEstimatesModuleEnabled &&
    searchState.filters[searchTypes.providers].procedures
  ) {
    showProcedureCostIcon = true
  }

  const onMouseEnterCallback = useCallback(() => {
    if (!showProceduresCost) {
      toggleProcedureCost()
    }
  }, [showProceduresCost])

  const onMouseExitCallback = useCallback(() => {
    if (showProceduresCost) {
      toggleProcedureCost()
    }
  }, [showProceduresCost])

  return (
    <div style={{ position: 'relative' }}>
      {showProcedureCostIcon && (
        <ProcedureCostIcon
          onMouseEnter={onMouseEnterCallback}
          onMouseLeave={onMouseExitCallback}
        >
          <CostEstimatesIconSVG />
        </ProcedureCostIcon>
      )}
      {showProceduresCost && (
        <CostEstimatesPopup
          procedureId={
            searchState.filters[searchTypes.providers].procedures
              ? searchState.filters[searchTypes.providers].procedures[0].uuid
              : null
          }
          location={searchState.location}
        />
      )}
      {!location && (
        <ResultsHeader type={HEADING_TYPE.H2}>Providers</ResultsHeader>
      )}
      {location && (
        <>
          {metadata && metadata.totalCount === 0 && (
            <ResultsHeader type={HEADING_TYPE.H2}>
              {`No results for ${
                providerName ? `"${providerName}"` : 'your search'
              }`}
            </ResultsHeader>
          )}
          {(!metadata || metadata.totalCount > 0) && (
            <>
              {searchState.providersSearchMode === 'inPerson' && (
                <ResultsHeader type={HEADING_TYPE.H2}>
                  {`Providers${
                    providerName ? ` matching "${providerName}"` : ''
                  }${
                    !sortBy || sortBy === 'name'
                      ? ''
                      : ` near ${location.label}`
                  }`}
                </ResultsHeader>
              )}
              {searchState.providersSearchMode === 'virtualCare' && (
                <ResultsHeader type={HEADING_TYPE.H2}>
                  {`Providers${
                    providerName ? ` matching "${providerName}"` : ''
                  }${
                    location ? ` in ${STATES[location.state.toLowerCase()] || location.state}` : ''
                  }`}
                </ResultsHeader>
              )}
            </>
          )}
          {metadata && metadata.totalCount > 0 && (
            <>
              <Label type={LABEL_TYPE.BODY1}>
                <b>{from}</b> - <b>{to}</b> of{' '}
                <b>{metadata.totalCount.toLocaleString()}</b> providers
              </Label>
              {insurance && (
                <Label
                  type={LABEL_TYPE.BODY1_BOLD}
                  s={() => ({
                    ['> span']: { fontWeight: 'normal' },
                  })}
                >
                  Insurance:<span>{` ${insurance.label}`}</span>
                </Label>
              )}
              {specialties && specialties.length > 0 && (
                <Label
                  type={LABEL_TYPE.BODY1_BOLD}
                  s={() => ({
                    ['> span']: { fontWeight: 'normal' },
                  })}
                >
                  Specialties:
                  <span>
                    {` ${specialties.map(s => s.display_name).join(', ')}`}
                  </span>
                </Label>
              )}
            </>
          )}
        </>
      )}
      <Block s={p => ({ padding: `0 0 ${p.theme.sizes.four}` })}>
        <SearchFilterSummary />
      </Block>
    </div>
  )
}
