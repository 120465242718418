import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Block } from 'design-system/block'
import { AsyncSelectInput } from 'design-system/input/async-select-input'
import { Label, LABEL_TYPE } from 'design-system/label'

export const AsyncSearchFilter = ({
  label = 'Label',
  searchFunction,
  onSelect,
  name,
  placeholder = 'Search',
  filterResults,
  multiSelect = true,
  debounceInput = true,
}) => {
  const onChange = useCallback(
    e => {
      console.log('onChange e.target :>> ', e.target)
      const { data, value, name } = e.target
      onSelect(data || value, name, multiSelect)
    },
    [onSelect, multiSelect],
  )

  const loadOptions = React.useCallback(
    async (inputValue, callback) => {
      if (inputValue.length > 0) {
        try {
          const results = await searchFunction(inputValue)
          console.log(`results`, results)
          const filteredResults = filterResults(results, inputValue)

          callback(filteredResults)
        } catch (error) {
          console.log('Search Error: ', error)
          callback([])
        }
      }
      callback([])
    },
    [filterResults],
  )

  return (
    <Block
      s={p => ({
        paddingLeft: 0,
        lineHeight: p.theme.sizes.six,
        paddingBottom: p.theme.sizes.four,
      })}
    >
      <Label type={LABEL_TYPE.BODY1_BOLD}>{label}</Label>
      <AsyncSelectInput
        onChange={onChange}
        name={name}
        containerStyle={{ width: '90%', height: 'auto', marginTop: '0.5rem' }}
        placeholder={placeholder}
        loadOptions={loadOptions}
        // Adjust size for mobile?
        menuStyle={{ width: '400px' }}
        debounceInput={debounceInput}
      />
    </Block>
  )
}

AsyncSearchFilter.propTypes = {
  label: PropTypes.string,
  searchFunction: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  filterResults: PropTypes.func.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  multiSelect: PropTypes.bool,
  debounceInput: PropTypes.bool,
}
