import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex } from 'design-system/flex'
import { Label, LABEL_TYPE } from 'design-system/label'
import { AuthStateContext } from 'context/auth-state-context'
import { NotyfContext } from 'context/notyf-context'
import { useUpdateDetailsMutations } from 'hooks/useUpdateDetailsMutations'
import dayjs from 'utils/dayjs'
import ClockSVG from 'assets/clock.svg'

const Container = styled(Flex)`
  flex-direction: column;
  margin-top: ${p => p.theme.sizes.six};
  align-items: flex-start;

  > div {
    align-items: center;
  }
`

const TabsContainer = styled(Flex)`
  flex: 0;
  margin-top: ${p => p.theme.sizes.two};
  background: ${p => p.theme.colors.gray7};
  border-radius: 100px;
  border: 1px solid ${p => p.theme.colors.borderDefault};
  div:first-of-type {
    margin-right: ${p => p.theme.sizes.four};
  }
`
const PreferenceDate = styled(Label)`
  margin-left: ${p => p.theme.sizes.one};
  color: ${p => p.theme.colors.textLightest};
  font-weight: 300;
  font-size: 12px;
`

const PreferenceDateContainer = styled(Flex)`
  flex: 0;
  margin-top: ${p => p.theme.sizes.two};
`

export const PreferenceToggle = ({
  provider,
  facility,
  handleUpdateSuccess,
}) => {
  const [authState] = useContext(AuthStateContext)
  const notyf = useContext(NotyfContext)
  const [preference, setPreference] = useState(
    provider ? provider.preference : facility ? facility.preference : null,
  )

  const { updateProviderInfo, updateFacilityInfo } = useUpdateDetailsMutations({
    provider,
    facility,
    onMutate: ({ options }) => {
      console.log(`🚀 => PreferenceToggle => onMutate`, options)
      // Optimistic update
      setPreference(options.preference)

      notyf.dismissAll()
      const updatingNotification = notyf.open({
        type: 'updating',
        duration: 10000, // setting a max here as backup but should always take less time
        message: `Preference updating..`,
      })

      return {
        oldPreference: preference,
        updatedPreference: options.preference,
        updatingNotification,
      }
    },
    onSuccess: async (res, vars, context) => {
      console.log(`🚀 => PreferenceToggle => res`, res)
      const { updatingNotification } = context
      await handleUpdateSuccess()
      if (updatingNotification) {
        notyf.dismiss(updatingNotification)
      }
      notyf.success(`Preference updated!`)
    },
    onError: (error, newPreference, context) => {
      console.log(`🚀 => PreferenceToggle => error`, error)
      const { updatingNotification, oldPreference } = context
      if (updatingNotification) {
        notyf.dismiss(updatingNotification)
      }
      setPreference(oldPreference)
      notyf.error(error.message)
    },
  })

  const onClickPreference = clickedPreference => {
    // API call to update preference
    const currentPreference = preference ? preference.rating : null
    const updatedPreference =
      clickedPreference === currentPreference ? 1 : clickedPreference
    if (provider) {
      updateProviderInfo({
        options: {
          preference: {
            rating: updatedPreference,
            updated_at: dayjs().utc().toISOString(),
          },
        },
      })
    }
    if (facility) {
      updateFacilityInfo({
        options: {
          preference: {
            rating: updatedPreference,
            updated_at: dayjs().utc().toISOString(),
          },
        },
      })
    }
  }

  const { permissionLevel } = authState || {}
  let hasEditPermission = false
  if (permissionLevel && permissionLevel >= 2) {
    hasEditPermission = true
  }

  return (
    <Container>
      <Flex>
        <Label
          type={LABEL_TYPE.BODY1}
          s={p => ({
            color: p.theme.colors.gray1,
            fontWeight: '700',
            fontSize: p.theme.headingSizes.H3,
          })}
        >
          Preference
        </Label>
      </Flex>
      <TabsContainer>
        <PreferenceTab
          label="Preferred"
          isChecked={(preference && preference.rating) === 2}
          onClick={hasEditPermission ? onClickPreference : null}
          preferenceValue={2}
        />
        <PreferenceTab
          label="Unpreferred"
          isChecked={(preference && preference.rating) === 0}
          onClick={hasEditPermission ? onClickPreference : null}
          preferenceValue={0}
        />
      </TabsContainer>
      {preference && preference.updated_at && (
        <PreferenceDateContainer>
          <ClockSVG />
          <PreferenceDate>
            Updated on {dayjs(preference.updated_at).format('ll')}
          </PreferenceDate>
        </PreferenceDateContainer>
      )}
    </Container>
  )
}

const TabContainer = styled(Flex)`
  background-color: ${p => p.theme.colors.white};
  border-radius: 100px;
  border: 1px solid ${p => p.theme.colors.borderDefault};
  padding: ${p => `${p.theme.sizes.three} ${p.theme.sizes.five}`};
  > p {
    font-weight: 500;
    color: ${p => p.theme.colors.gray3};
  }
  ${p => ({
    ...(p.isChecked && {
      borderColor: p.theme.colors.gray0,
      ['> p']: {
        color: p.theme.colors.gray0,
      },
    }),
  })}
`

const PreferenceTab = ({
  label = '',
  isChecked = false,
  onClick,
  preferenceValue,
}) => {
  return (
    <TabContainer
      onClick={onClick ? () => onClick(preferenceValue) : null}
      isChecked={isChecked}
    >
      <Label>{label}</Label>
    </TabContainer>
  )
}

PreferenceTab.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
  preferenceValue: PropTypes.number,
}

PreferenceToggle.propTypes = {
  provider: PropTypes.object,
  facility: PropTypes.object,
  handleUpdateSuccess: PropTypes.func,
}
