// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import dayjs from 'utils/dayjs'
import { CustomField, CustomFieldType } from 'services/ribbon/ribbon.types'
import { FIELD_TYPES_FLAT_LOWER } from './constants'

export const validEmail = (email: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const valid = re.test(String(email).toLowerCase())
  if (!valid) {
    return false
  }
  return true
}

export const isInteger = (value: string | boolean | number | Record): boolean => {
  if (typeof value === 'number') {
    if (value % 1 === 0) {
      return true
    }
  }

  return false
}

export const primitiveTypesCheck = (
  fieldType: CustomFieldType,
  value: number | string | boolean,
): boolean => {
  let isValid = false
  switch (fieldType) {
    case FIELD_TYPES_FLAT_LOWER.integer: {
      if (isInteger(value)) {
        isValid = true
      }
      break
    }
    case FIELD_TYPES_FLAT_LOWER.float: {
      if (!Number.isNaN(value)) {
        isValid = true
      }
      break
    }
    case FIELD_TYPES_FLAT_LOWER.boolean: {
      if (typeof value === 'boolean') {
        isValid = true
      }
      break
    }
    case FIELD_TYPES_FLAT_LOWER.string: {
      // string doesnt need any validation
      // user can remove and save empty string
      isValid = true

      break
    }
    case FIELD_TYPES_FLAT_LOWER.date: {
      if (dayjs(value).isValid() || value === null) {
        isValid = true
      }

      break
    }

    default:
      break
  }
  return isValid
}

type EditFieldType = {
  [key: string]: EditCustomObject
}

type EditFieldListType = Array<string | number | boolean> | EditCustomObject

type EditCustomObject = {
  [key: string]: string | number | boolean
}

export const customFieldsEditsAreValid = (
  field: CustomField,
  editField: EditFieldType | EditFieldListType,
): boolean => {
  let isValid = false
  if (!field || Object.keys(editField).length === 0) return false

  switch (field.field_type) {
    case FIELD_TYPES_FLAT_LOWER.object: {
      const { keys: displayConfigKeys } = field.object_display_config

      isValid = Object.keys(displayConfigKeys).every(key => {
        const displayConfigKey: {
          name: string
          type: string
          display_name: string
        } = displayConfigKeys[key]
        const keyValue = editField[field.display_name][displayConfigKey.name]
        return primitiveTypesCheck(displayConfigKey.type, keyValue)
      })
      break
    }
    case FIELD_TYPES_FLAT_LOWER.list: {
      const { list_type, object_display_config } = field.list_display_config
      if (list_type === FIELD_TYPES_FLAT_LOWER.object) {
        // handle list of objects case
        const { keys: objectDisplayConfigs } = object_display_config
        const updatedObject = editField as EditCustomObject

        isValid = objectDisplayConfigs.every(objDisplayConfig => {
          const { name, type } = objDisplayConfig
          const keyValue = updatedObject[name]
          return primitiveTypesCheck(type, keyValue)
        })
      } else {
        isValid = primitiveTypesCheck(list_type, editField)
      }
      break
    }
    case FIELD_TYPES_FLAT_LOWER.integer:
    case FIELD_TYPES_FLAT_LOWER.float:
    case FIELD_TYPES_FLAT_LOWER.boolean:
    case FIELD_TYPES_FLAT_LOWER.date:
    case FIELD_TYPES_FLAT_LOWER.string: {
      const value = editField[field.display_name][field.display_name]
      isValid = primitiveTypesCheck(field.field_type, value)
      break
    }

    default:
      break
  }
  return isValid
}
