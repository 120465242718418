import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'design-system/flex'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Label } from 'design-system/label'
import { ProceduresList } from './procedures-list'

export const Procedures = ({ procedures }) => {
  return (
    <div>
      <Flex
        s={p => ({
          justifyContent: 'space-between',
          margin: `${p.theme.sizes.four} 0 ${p.theme.sizes.four} 0`,
        })}
      >
        <Heading
          type={HEADING_TYPE.H2}
          s={p => ({
            color: p.theme.colors.aubergine2,
            marginBottom: 0,
          })}
        >
          Procedures
        </Heading>
      </Flex>

      {procedures && procedures.length > 0 ? (
        <ProceduresList procedures={procedures} />
      ) : (
        <Label>There are no reported procedures at this location.</Label>
      )}
    </div>
  )
}

Procedures.propTypes = {
  procedures: PropTypes.arrayOf(PropTypes.object),
}
