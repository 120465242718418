import React, { useContext } from 'react'
import { AuthStateContext } from 'context/auth-state-context'
import { Flex } from 'design-system/flex'
import { CustomField } from './custom-fields'
import { FIELD_TYPES_FLAT_LOWER } from 'utils/constants'

export const CustomFieldsSection = ({
  section = 'sidebar',
  type = 'providers',
  item = {},
  handleUpdateSuccess = async () => {},
}) => {
  const [authState] = useContext(AuthStateContext)
  const { customFields, modules } = authState

  const { modules: typeModules } = modules[type] || {}
  if (
    !customFields ||
    (typeModules &&
      typeModules.custom_fields &&
      !typeModules.custom_fields.enabled)
  ) {
    return <></>
  }

  const filterFieldsBySection = customFields[type].filter(customField => {
    if (section === 'sidebar') {
      return (
        customField.field_type !== FIELD_TYPES_FLAT_LOWER.list &&
        customField.field_type !== FIELD_TYPES_FLAT_LOWER.object
      )
    }
    if (section === 'main') {
      return (
        customField.field_type === FIELD_TYPES_FLAT_LOWER.list ||
        customField.field_type === FIELD_TYPES_FLAT_LOWER.object
      )
    }
  })
  return (
    <Flex
      s={() => ({
        flexDirection: 'column',
      })}
    >
      {filterFieldsBySection.map(field => {
        if (!field.enabled) {
          return null
        }
        // values is an array for easy rendering of changes made on review step
        let values = []
        if (field.field_type === FIELD_TYPES_FLAT_LOWER.object) {
          // Object type is the only time values is an object for rendering labels & changes
          if (item[field.parameter_name] !== undefined) {
            values = item[field.parameter_name]
          } else {
            values = {}
          }
        } else {
          if (item[field.parameter_name] !== undefined) {
            if (field.field_type === FIELD_TYPES_FLAT_LOWER.list) {
              // concat list items with values items, otherwise its always just one item
              values.push(...item[field.parameter_name])
            } else {
              values.push(item[field.parameter_name])
            }
          }
        }

        return (
          <CustomField
            key={field.uuid}
            type={type}
            // providers -> npi, locations -> uuid
            updateId={type === 'providers' ? item.npi : item.uuid}
            field={field}
            values={values}
            handleUpdateSuccess={handleUpdateSuccess}
          />
        )
      })}
    </Flex>
  )
}
