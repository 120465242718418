const newDefaultBooleanConfig = () => {
  return {
    True: {
      display: '',
    },
    False: {
      display: '',
    },
  }
}

const defaultObjectKey = {
  name: '',
  type: '',
  display_name: '',
}

const newDefaultObjectConfig = () => {
  return {
    display_name: '',
    keys: [JSON.parse(JSON.stringify(defaultObjectKey))],
  }
}

export const initialState = {
  type: 'providers', // | facilities
  parameter_name: '',
  display_name: '',
  field_type: '',
  default_value: '',
  list_type: '',
  boolean_display_config: newDefaultBooleanConfig(),
  object_display_config: newDefaultObjectConfig(),
  configureDisplay: false,
  showConfigureDisplayButton: false,
  createFilter: false,
  errors: {},
}

export const reducerTypes = {
  update: 'update',
  reset: 'reset',
  remove_object_key: 'remove_object_key',
  add_object_key: 'add_object_key',
  toggle_display_config: 'toggle_display_config',
}

export const addNewFieldReducer = (state, action) => {
  const { type, payload } = action
  // console.log(`addNewFieldReducer:action`, action)
  switch (type) {
    case reducerTypes.update:
      return {
        ...state,
        ...payload,
      }
    case reducerTypes.remove_object_key: {
      const newObjectConfigKeys = state.object_display_config.keys.slice()
      newObjectConfigKeys.splice(payload.index, 1)

      return {
        ...state,
        object_display_config: {
          ...state.object_display_config,
          keys: newObjectConfigKeys,
        },
      }
    }
    case reducerTypes.add_object_key: {
      const newObjectConfigKeys = state.object_display_config.keys.slice()
      newObjectConfigKeys.push({ ...defaultObjectKey })
      return {
        ...state,
        object_display_config: {
          ...state.object_display_config,
          keys: newObjectConfigKeys,
        },
      }
    }
    case reducerTypes.toggle_display_config:
      return {
        ...state,
        configureDisplay: !state.configureDisplay,
        boolean_display_config: newDefaultBooleanConfig(),
        object_display_config: newDefaultObjectConfig(),
      }
    case reducerTypes.reset:
      return initialState
    default:
      return state
  }
}
