import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import CheckSVG from 'assets/checkmark.svg'
import { Flex } from 'design-system/flex'

const IndeterminateLine = styled.div`
  width: 90%;
  height: 1px;
  background-color: ${p => p.theme.colors.white};
`

const UnstyledCheckbox = props => {
  return (
    <Flex
      s={() => ({
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        ...(props.disabled
          ? { cursor: 'default' }
          : {
              cursor: 'pointer',
            }),
      })}
      className={props.className}
      onClick={props.onClick}
      data-testid={props['data-testid']}
    >
      <input
        type="checkbox"
        style={{
          position: 'absolute',
          opacity: 0,
          height: 0,
          width: 0,
          top: 0,
          left: 0,
        }}
        disabled={props.disabled}
        checked={Boolean(props.value)}
        readOnly
      />
      {props.indeterminate && !props.value && <IndeterminateLine />}
      {props.value && <CheckSVG />}
    </Flex>
  )
}

UnstyledCheckbox.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.bool,
  indeterminate: PropTypes.bool,
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
}

const SMALL = css(
  p => `
  min-width: ${p.theme.sizes.four};
  width: ${p.theme.sizes.four};
  min-height: ${p.theme.sizes.four};
  height: ${p.theme.sizes.four};
`,
)

const LARGE = css(
  p => `
  width: ${p.theme.sizes.five};
  height: ${p.theme.sizes.five};
  min-width: ${p.theme.sizes.five};
  min-height: ${p.theme.sizes.five};
`,
)

const CHECKED = css(
  props => `
  background: ${props.theme.colors.aubergine};
`,
)

const UNCHECKED = css(
  props => `
  background: ${props.theme.colors.white};
`,
)

const INDETERMINATE = css(
  props => `
  background: ${props.theme.colors.aubergine};
`,
)

const SIZE_STYLES = {
  SMALL,
  LARGE,
}

const TYPE_STYLES = {
  CHECKED,
  UNCHECKED,
  INDETERMINATE,
}

export const Checkbox = styled(UnstyledCheckbox)`
  border-radius: 2px;
  border: 1px solid ${props => props.theme.colors.gray2};
  ${props => TYPE_STYLES[props.value ? 'CHECKED' : 'UNCHECKED']}
  ${props => TYPE_STYLES[props.indeterminate ? 'INDETERMINATE' : '']}
  ${props => SIZE_STYLES[props.size]}
  ${props => (props.s ? css(props.s(props)) : '')}
`

Checkbox.propTypes = {
  s: PropTypes.func,
}

Checkbox.defaultProps = {
  size: 'SMALL',
}
