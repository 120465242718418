import React, { useState } from 'react'
import { Block } from 'design-system/block'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label } from 'design-system/label'
import { InstancesTable } from './instances-table'
import { ActivateInstanceModal } from './activate-instance-modal'

export const Instances = () => {
  const [showActivateInstanceModal, setShowActivateInstanceModal] = useState(
    false,
  )
  const onClickCreateNewInstance = () => {
    console.log('onClick Create New Instance')
    setShowActivateInstanceModal(true)
  }

  return (
    <Block
      type="FLEX"
      s={p => ({
        backgroundColor: p.theme.colors.gray7,
        padding: 0,
        justifyContent: 'center',
        overflowY: 'auto',
        flex: 1,
        height: '100%',
      })}
    >
      <Block
        type="FLEX"
        s={p => ({
          padding: `${p.theme.sizes.ten} ${p.theme.sizes.ten}`,
          flexDirection: 'column',
          flex: 1,
          width: '100%',
          maxWidth: `${p.theme.flexboxgrid.container.lg}em`,
          height: 'max-content',
        })}
      >
        <Block
          type="FLEX"
          s={() => ({
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 0,
          })}
        >
          <Label
            s={p => ({
              color: p.theme.colors.gray0,
              fontSize: p.theme.sizes.six,
              fontWeight: '700',
            })}
          >
            Find Care Application Instances
          </Label>
          <Button
            onClick={onClickCreateNewInstance}
            type={BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.LARGE}
          >
            Activate Instance
          </Button>
        </Block>

        {/* TABLE */}
        <InstancesTable />
      </Block>
      {showActivateInstanceModal && (
        <ActivateInstanceModal
          handleClose={() => setShowActivateInstanceModal(false)}
        />
      )}
    </Block>
  )
}
