import React from 'react'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import { DataCard } from '../components/DataCard'
import styled from 'styled-components'
import { DataCardContent } from '../components/DataCardContent'

export const SyncOverview = () => {
  return (
    <>
      <Label
        s={() => ({
          fontWeight: '600',
          fontSize: '1.5em',
        })}
      >
        Sync Overview
      </Label>
      <Flex
        s={p => ({
          marginTop: p.theme.sizes.six,
          justifyContent: 'space-between',
          ['> div']: {
            flex: `0 0 31%`,
          },
        })}
      >
        <DataCard>
          <DataCardContent title="Last Sync" description="4:52pm">
            <DataMainLabel>June 21, 2021</DataMainLabel>
          </DataCardContent>
        </DataCard>
        <DataCard>
          <DataCardContent title="Records Updated" description="">
            <DataMainLabel>1,000</DataMainLabel>
          </DataCardContent>
        </DataCard>
        <DataCard>
          <DataCardContent title="% Change" description="Last 30 days">
            <DataMainLabel>60%</DataMainLabel>
          </DataCardContent>
        </DataCard>
      </Flex>
    </>
  )
}

const DataMainLabel = styled(Label)`
  margin: ${p => p.theme.sizes.four} 0;
  font-weight: 500;
  font-size: ${p => p.theme.headingSizes.H1};
  color: ${p => p.theme.colors.gray1};
`
