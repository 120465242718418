import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const NativeTextArea = styled.textarea`
  outline: 0;
  border: 0;
  background: transparent;
  font-family: 'Inter';
  padding: ${p => p.theme.sizes.three} ${p => p.theme.sizes.four};
  font-size: ${p => p.theme.sizes.four};
  :focus,
  :active {
    outline: 0;
    border: 0;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${p => p.theme.colors.text};
    opacity: 0.5;
  }
  ${p => ({
    ...(p.type === 'default' && {
      border: `1px solid ${p.theme.colors.borderLight}`,
      [':focus-within']: {
        border: `1px solid ${p.theme.colors.aubergine}`,
      },
      [':hover']: {
        border: `1px solid ${p.theme.colors.aubergine}`,
      },
    }),
    ...(p.type === 'error' && {
      border: `1px solid ${p.theme.colors.errorRed}`,
    }),
    ...(p.disabled && {
      opacity: '50%',
    }),
  })}
`

export const TextArea = ({
  onChange,
  disabled,
  value,
  placeholder,
  type = 'default', // error
  ...rest
}) => {
  return (
    <NativeTextArea
      disabled={disabled}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      type={type}
      {...rest}
    />
  )
}

TextArea.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
}
