import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Block } from 'design-system/block'
import { Icon } from 'design-system/icon'
import { rotateContinuous } from 'theme/animations'
import { colors } from 'theme/colors'

const UnstyledSpinner = props => {
  return (
    <Block data-testid="loadingSpinner" className={props.className}>
      <Icon size={props.size} s={() => ({ margin: 0 })}>
        <svg
          width={props.size}
          height={props.size}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="44"
            y="31"
            width="20"
            height="27"
          >
            <path
              d="M44.4473 31.4948H63.5123V57.33H44.4473V31.4948Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M63.5129 32.4441C63.5129 32.126 63.506 31.8104 63.4971 31.4948L52.5075 31.8242C52.5132 32.03 52.5233 32.2351 52.5233 32.4441C52.5233 39.0662 49.3582 44.9536 44.4473 48.7145L51.1673 57.33C58.6762 51.5788 63.5129 42.5718 63.5129 32.4441Z"
              fill={props.accentColor}
            />
          </g>
          <mask
            id="mask1"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="1"
            width="64"
            height="63"
          >
            <path d="M0 1H63.4969V63.8894H0V1Z" fill="white" />
          </mask>
          <g mask="url(#mask1)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.756 53.0073C20.2867 53.0073 10.9896 43.8007 10.9896 32.4438C10.9896 21.087 20.2867 11.8804 31.756 11.8804C43.015 11.8804 52.1752 20.7551 52.5072 31.8239L63.4969 31.4945C62.9899 14.5671 48.9739 0.999146 31.756 0.999146C14.2169 0.999146 0 15.0766 0 32.4438C0 49.8111 14.2169 63.8892 31.756 63.8892C39.0666 63.8892 45.7999 61.4409 51.167 57.3298L44.447 48.7143C40.9378 51.4035 36.537 53.0073 31.756 53.0073Z"
              fill={props.outerColor}
            />
          </g>
        </svg>
      </Icon>
      {props.label && <Block>{props.label}</Block>}
    </Block>
  )
}

UnstyledSpinner.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  outerColor: PropTypes.string,
  accentColor: PropTypes.string,
  padding: PropTypes.string,
}

export const Spinner = styled(UnstyledSpinner)`
  text-align: center;
  ${p => ({
    ...(p.padding
      ? { padding: p.padding }
      : { padding: `${p.theme.sizes.twelve} 0` }),
  })};

  > svg {
    animation-name: ${rotateContinuous};
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  ${props => (props.s ? css(props.s(props)) : '')}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

Spinner.propTypes = {
  s: PropTypes.func,
  size: PropTypes.number,
}

Spinner.defaultProps = {
  size: 64,
  outerColor: colors.aubergine2,
  accentColor: colors.aquamarine,
}

Spinner.displayName = 'Spinner'
