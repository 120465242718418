import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Label, LABEL_TYPE } from 'design-system/label'
import { RatingLabel } from 'components/rating-label/rating-label.component'
import { Flex } from 'design-system/flex'
import { searchTypes } from 'utils/constants'
import { InaccurateInfoIndicator } from 'components/inaccurate-info-indicator/inaccurate-info-indicator'
import CheckIconSVG from 'assets/circle-check-full.svg'
import PreferredSVG from 'assets/preferred.svg'
import UnpreferredSVG from 'assets/unpreferred.svg'
import NotesSearchResultSVG from 'assets/notes-search-result.svg'

const LinkContainer = styled(Link)`
  text-decoration: none;
  color: ${p => p.theme.colors.aubergine3};
  font-weight: 500;
  margin-top: auto;
`

export const ResultInfoWindow = props => {
  const { type, result } = props

  const handleOnClick = () => {
    typeof window !== 'undefined' &&
      window.gtag &&
      window.gtag('event', 'click', { event_label: 'Map Result Card' })
  }

  const location = type === searchTypes.providers ? result.locations[0] : result

  return (
    <Flex
      s={p => ({
        padding: p.theme.sizes.five,
        flexDirection: 'column',
        minHeight: '100%',
        boxSizing: 'border-box',
        zIndex: p.theme.zIndexes.overlay + 1,
      })}
    >
      <Label
        s={p => ({
          marginBottom: p.theme.sizes.one,
        })}
        type={LABEL_TYPE.BODY1_BOLD}
      >
        {props.title}
      </Label>

      <Label type={LABEL_TYPE.BODY1}>{props.subtitle}</Label>

      <Flex
        s={p => ({
          alignItems: 'center',
          flexWrap: 'wrap',
          padding: `${p.theme.sizes.four} 0`,
          ['> svg']: {
            marginLeft: p.theme.sizes.one,
          },
          ['> svg:first-of-type']: {
            marginLeft: props.rating ? p.theme.sizes.one : 0,
          },
        })}
      >
        <RatingLabel rating={props.rating} label={props.ratingLabel} />
        {location && location.confidence < 3 && <InaccurateInfoIndicator />}
        {location && location.confidence === 5 && (
          <CheckIconSVG width={16} height={16} />
        )}
        {props.preference?.rating === 2 && (
          <PreferredSVG width={16} height={16} />
        )}
        {props.preference?.rating === 0 && (
          <UnpreferredSVG width={16} height={16} />
        )}
        {props.hasNotes && <NotesSearchResultSVG width={16} height={16} />}
      </Flex>

      <Label s={p => ({ color: p.theme.colors.gray2 })}>
        {props.addressText}
      </Label>

      <Label s={p => ({ color: p.theme.colors.gray2 })}>
        {props.distance}mi
      </Label>

      <LinkContainer
        to={
          type === searchTypes.providers
            ? `/providers/${result.npi}/${result.locations.length > 0 ? result.locations[0].uuid : ''
            }`
            : `/facilities/${result.uuid}`
        }
        state={{
          ...(type === searchTypes.providers && { provider: result }),
          ...(type === searchTypes.facilities && { facility: result }),
        }}
        onClick={handleOnClick}
      >
        See details
      </LinkContainer>
    </Flex>
  )
}

ResultInfoWindow.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  rating: PropTypes.number,
  ratingLabel: PropTypes.string,
  addressText: PropTypes.string,
  distance: PropTypes.number,
  result: PropTypes.object.isRequired,
  type: PropTypes.string,
  preference: PropTypes.object,
  hasNotes: PropTypes.bool,
}

ResultInfoWindow.defaultProps = {
  title: 'Provider Name',
  subtitle: 'Specialty Name',
  rating: null,
  ratingLabel: 'In Network',
  addressText: 'Address',
  distance: 0.25,
  result: {},
  type: 'providers',
}
