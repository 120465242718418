import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'design-system/flex'
import styled, { css } from 'styled-components'
import { NotyfContext } from 'context/notyf-context'
import { Label } from 'design-system/label'
import CopySVG from 'assets/copy.svg'
import OpenInNewSVG from 'assets/open-in-new.svg'
import {copyToClipboard} from 'utils/copy-to-clipboard'

const UrlContainer = styled.div`
  min-width: 400px;
  height: 42px;
  overflow: hidden;
  padding: ${p => p.theme.sizes.two};
  border-radius: 100px;
  border: 1px solid ${p => p.theme.colors.borderDefault};
`

const ButtonContainer = styled.div`
  width: 95px;
  position: absolute;
  display: inline-flex;
  justify-content: space-between;
  right: -1px;
  height: 42px;
  background: white;
  border-radius: 100px;
  border: 1px solid ${p => p.theme.colors.gray1};
`

const IconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1px;
  border-radius: 100px;
  width: 42px;
  height: 42px;
  border: 1px solid ${p => p.theme.colors.gray1};
  padding: ${p => p.theme.sizes.two};
  :hover {
    background: ${p => p.theme.colors.gray7};
    cursor: pointer;
  }
  ${props => (props.s ? css(props.s(props)) : '')}
`

export const CopyableBookingLink = ({ url }) => {
  const notyf = useContext(NotyfContext)

  const handleCopyClick = async () => {
    await copyToClipboard(url)
    notyf.success('Copied link')
  }

  const handleOpenInNewTabClick = () => {
    window.open(url, '_blank')
  }

  return (
    <Flex
      s={() => ({
        justifyContent: 'space-between',
        position: 'relative',
      })}
    >
      <UrlContainer>
        <Label>{url}</Label>
      </UrlContainer>
      <ButtonContainer>
        <IconButton s={() => ({ left: '-1px' })} onClick={handleCopyClick}>
          <CopySVG width={19} height={19} />
        </IconButton>
        <IconButton
          s={() => ({ right: '-1px' })}
          onClick={handleOpenInNewTabClick}
        >
          <OpenInNewSVG width={17} height={17} />
        </IconButton>
      </ButtonContainer>
    </Flex>
  )
}

CopyableBookingLink.propTypes = {
  url: PropTypes.string,
}
