import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SearchInputs } from './search-header'
import { Flex } from 'design-system/flex'

const AnimatedHeader = styled(Flex)`
  position: absolute;
  top: 0;
  width: 100%;
  justify-content: center;
  padding-top: ${p => p.theme.sizes.four};
  z-index: ${p => p.theme.zIndexes.routeNav};
  pointer-events: none;
  -webkit-animation: slideIn 0.5s forwards;
  -moz-animation: slideIn 0.5s forwards;
  animation: slideIn 0.5s forwards;
  @-webkit-keyframes slideIn {
    0% {
      transform: translateY(-75px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-moz-keyframes slideIn {
    0% {
      transform: translateY(-75px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateY(-75px);
    }

    100% {
      transform: translateY(0);
    }
  }
`

const SearchInputsContainer = styled(Flex)`
  max-width: 800px;
  flex: 1;
  background-color: ${p => p.theme.colors.white};
  pointer-events: all;
`

export const FixedSearchHeader = ({ performSearch }) => {
  return (
    <AnimatedHeader>
      <SearchInputsContainer>
        <SearchInputs performSearch={performSearch} small />
      </SearchInputsContainer>
    </AnimatedHeader>
  )
}

FixedSearchHeader.propTypes = {
  performSearch: PropTypes.func,
}
