import React, { useMemo, useState, useReducer } from 'react'
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
} from 'react-table'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label } from 'design-system/label'
import { UsersTable } from './users-table'
import {
  customFilterTypes,
  tableDefaultColumn,
  usersColumns,
} from './users-table-options'
import styled from 'styled-components'
import { DropdownMenu, Overlay } from 'components/dropdown/dropdown'
import { AddNewUsersModal } from './add-new-users-modal'
import { UploadUsersModal } from './upload-users-csv-modal'
import { RemoveUsersModal } from './remove-users-modal'
import { ManagePermissionsModal } from './manage-permissions-modal'
import RibbonLocation from 'assets/ribbon-location.svg'
import { Flex } from 'design-system/flex'
import { Spinner } from 'design-system/spinner'

const modalTypes = {
  addNewUsers: 'addNewUsers',
  uploadUsers: 'uploadUsers',
  removeUser: 'removeUser',
  managePermissions: 'managePermissions',
}

export const modalsReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'show':
      return {
        ...payload,
      }
    case 'close':
      return initialState
    default:
      return state
  }
}

const initialState = { activeModal: '', activeUsers: null }

export const ManageUsers = ({
  users = [],
  loading = false,
  handleAddUsers = () => {},
  handleRemoveUsers = () => {},
  handleUpdateUsers = () => {},
  handleResendInvite = () => {},
}) => {
  const [state, dispatch] = useReducer(modalsReducer, initialState)
  const { activeModal, activeUsers } = state

  const defaultColumn = useMemo(() => tableDefaultColumn, [])
  const filterTypes = useMemo(() => customFilterTypes, [])
  const dataMemo = useMemo(() => users, [users])
  const columnsMemo = useMemo(() => usersColumns, [])

  const tableInstance = useTable(
    {
      columns: columnsMemo,
      data: dataMemo,
      initialState: { pageIndex: 0, pageSize: 20 },
      defaultColumn,
      filterTypes,
      globalFilter: 'usersSearch',
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  )

  const { selectedFlatRows } = tableInstance

  const onClickResendInvite = user => {
    handleResendInvite(user)
  }

  const onClickRemoveUsers = user => {
    console.log('🚀 ~ onClickRemoveUsers ~ user', user)
    dispatch({
      type: 'show',
      payload: {
        activeModal: modalTypes.removeUser,
        activeUsers: user ? [user] : selectedFlatRows.map(i => i.original),
      },
    })
  }

  const onClickUpdatePermissions = user => {
    console.log('🚀 ~ onClickUpdatePermissions ~ user', user, selectedFlatRows)
    dispatch({
      type: 'show',
      payload: {
        activeModal: modalTypes.managePermissions,
        activeUsers: user ? [user] : selectedFlatRows.map(i => i.original),
      },
    })
  }

  return (
    <Flex
      s={p => ({
        padding: `${p.theme.sizes.six} ${p.theme.sizes.six} `,
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        maxWidth: `${p.theme.flexboxgrid.container.lg}em`,
        height: 'max-content',
        overflow: 'auto',
      })}
    >
      <Flex
        s={() => ({
          justifyContent: 'space-between',
          alignItems: 'center',
        })}
      >
        <Label
          s={p => ({
            color: p.theme.colors.gray0,
            fontSize: p.theme.sizes.six,
            fontWeight: '700',
          })}
        >
          Users
        </Label>
        <Flex
          s={() => ({
            alignItems: 'center',
          })}
        >
          {selectedFlatRows.length > 0 && (
            <Flex
              s={() => ({
                alignItems: 'center',
              })}
            >
              <SecondaryButton onClick={() => onClickRemoveUsers()}>
                Remove Users
              </SecondaryButton>
              <SecondaryButton onClick={() => onClickUpdatePermissions()}>
                Update Permissions
              </SecondaryButton>
            </Flex>
          )}

          <AddNewUsersContainer
            onClickAddManually={() =>
              dispatch({
                type: 'show',
                payload: { activeModal: modalTypes.addNewUsers },
              })
            }
            onClickUploadCsv={() =>
              dispatch({
                type: 'show',
                payload: { activeModal: modalTypes.uploadUsers },
              })
            }
          />
        </Flex>
      </Flex>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {users.length > 0 ? (
            <UsersTable
              tableInstance={tableInstance}
              onClickUpdatePermissions={onClickUpdatePermissions}
              onClickRemoveUsers={onClickRemoveUsers}
              onClickResendInvite={onClickResendInvite}
            />
          ) : (
            <Flex
              s={p => ({
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: p.theme.sizes.twelve,
              })}
            >
              <RibbonLocation />
              <Label
                s={p => ({
                  margin: `${p.theme.sizes.five} 0`,
                  maxWidth: '250px',
                  textAlign: 'center',
                })}
              >
                There are no users in this instance yet.
              </Label>
              <AddNewUsersContainer
                onClickAddManually={() =>
                  dispatch({
                    type: 'show',
                    payload: { activeModal: modalTypes.addNewUsers },
                  })
                }
                onClickUploadCsv={() =>
                  dispatch({
                    type: 'show',
                    payload: { activeModal: modalTypes.uploadUsers },
                  })
                }
              />
            </Flex>
          )}
        </>
      )}

      {activeModal === modalTypes.addNewUsers && (
        <AddNewUsersModal
          handleClose={() => dispatch({ type: 'close' })}
          handleAddUsers={handleAddUsers}
        />
      )}
      {activeModal === modalTypes.uploadUsers && (
        <UploadUsersModal
          handleClose={() => dispatch({ type: 'close' })}
          handleAddUsers={handleAddUsers}
        />
      )}
      {activeModal === modalTypes.removeUser && (
        <RemoveUsersModal
          handleClose={() => dispatch({ type: 'close' })}
          activeUsers={activeUsers}
          handleRemoveUsers={handleRemoveUsers}
        />
      )}
      {activeModal === modalTypes.managePermissions && (
        <ManagePermissionsModal
          handleClose={() => dispatch({ type: 'close' })}
          activeUsers={activeUsers}
          handleUpdateUsers={handleUpdateUsers}
        />
      )}
    </Flex>
  )
}

const AddNewUsersContainer = ({
  onClickAddManually = () => {},
  onClickUploadCsv = () => {},
}) => {
  const [showAddMenu, setShowAddMenu] = useState(false)
  return (
    <div>
      {showAddMenu && (
        <Overlay
          data-testid="dropdown-overlay"
          onClick={() => setShowAddMenu(false)}
        />
      )}
      <ButtonContainer>
        <Button
          onClick={() => setShowAddMenu(true)}
          type={BUTTON_TYPE.PRIMARY}
          size={BUTTON_SIZE.LARGE}
        >
          Add New Users
        </Button>
        {showAddMenu && (
          <DropdownMenu
            options={[
              {
                title: 'Add manually',
                onClick: () => {
                  onClickAddManually()
                  setShowAddMenu(false)
                },
              },
              {
                title: 'Upload via CSV',
                onClick: () => {
                  onClickUploadCsv()
                  setShowAddMenu(false)
                },
              },
            ]}
          />
        )}
      </ButtonContainer>
    </div>
  )
}

const ButtonContainer = styled.div`
  position: relative;
  & > div {
    top: 45px;
  }
`

const SecondaryButton = styled(Button)`
  margin-right: ${p => p.theme.sizes.two};
  :hover {
    background-color: ${p => p.theme.colors.gray6};
  }
`
SecondaryButton.defaultProps = {
  type: BUTTON_TYPE.LINK,
  size: BUTTON_SIZE.LARGE,
}
