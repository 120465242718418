import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex } from 'design-system/flex'
import { useProceduresCostEstimates } from './useProceduresCostEstimates'
import { CostEstimatesResults } from './cost-estimates-results'
import { InfoIndicator } from 'components/info-indicator/info-indicator'
import { Label } from 'design-system/label'

const CostEstimatesContainer = styled(Flex)`
  flex-direction: column;
  position: absolute;
  top: 70px;
  width: 100%;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 0px 29px rgba(28, 29, 31, 0.09);
  border: 1px solid ${p => p.theme.colors.clear};
  border-radius: 5px;
  z-index: ${p => p.theme.zIndexes.modal};
  & > div {
    margin-top: 0;
  }
  :first-of-type(div) {
    border: none;
  }
`

export const CostEstimatesPopup = ({ procedureId, location }) => {
  const {
    result,
    fetchingCostEstimates,
    fetchingProcedure,
    error,
  } = useProceduresCostEstimates({ procedureId, location, autoSearch: true })

  const isLoading = fetchingCostEstimates || fetchingProcedure

  return (
    <CostEstimatesContainer>
      <CostEstimatesResults
        result={result}
        isLoading={isLoading}
        noBorder={true}
        showDescription
        error={error}
      />
      {result &&
        result.costEstimates &&
        Object.keys(result.costEstimates).length > 0 && (
          <Flex
            s={p => ({
              borderTop: `1px solid ${p.theme.colors.borderLight}`,
              padding: p.theme.sizes.four,
              ['> div']: {
                minHeight: p.theme.sizes.four,
                minWidth: p.theme.sizes.four,
                marginTop: '4px',
                borderColor: p.theme.colors.gray2,
                color: p.theme.colors.gray2,
              },
            })}
          >
            <InfoIndicator />
            <Label
              s={p => ({
                color: p.theme.colors.gray2,
                marginLeft: p.theme.sizes.two,
              })}
            >
              This is a cost estimate of the procedure searched in this
              geographic area and is not specific for an insurance plan or
              provider
            </Label>
          </Flex>
        )}
    </CostEstimatesContainer>
  )
}

CostEstimatesPopup.propTypes = {
  procedureId: PropTypes.string,
  location: PropTypes.object,
}
