import IMask from 'imask'
import { FaxNumbers, PhoneNumbers } from 'services/ribbon/ribbon.types'

export const formatPhoneNumber = (value = ''): string => {
  if (!value) {
    return ''
  }
  const masked = IMask.createMask({
    mask: '(000) 000-0000',
    blocks: {
      num: {
        mask: Number,
      },
    },
  })
  const formattedValue = masked.resolve(String(value))
  // console.log(`formatted phone value = ${formattedValue}`);
  return formattedValue
}

export const getOnlyNumbers = (value = ''): string => {
  if (!value) {
    return ''
  }

  const numbers = value.replace(/[^0-9]/g, '')

  return numbers
}

export const phoneNumbersReducer = (
  phoneNumbers: Array<PhoneNumbers> = [],
): Array<PhoneNumbers> => {
  const result = phoneNumbers.reduce((result: Array<PhoneNumbers>, curr) => {
    if (curr.phone) {
      result.push({
        ...curr,
        phone: getOnlyNumbers(curr.phone),
      })
    }
    return result
  }, [])
  return result
}

export const faxNumbersReducer = (
  faxes: Array<FaxNumbers> = [],
): Array<FaxNumbers> => {
  return faxes.reduce((result: Array<FaxNumbers>, curr) => {
    if (curr.fax) {
      result.push({
        ...curr,
        fax: getOnlyNumbers(curr.fax),
      })
    }
    return result
  }, [])
}
