import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { Modal } from 'design-system/modal'
import { Label } from 'design-system/label'
import { Flex } from 'design-system/flex'
import { RadioOption } from 'design-system/radio-option/radio-option'

const ContentContainer = styled(Flex)`
  flex-direction: column;
  padding: ${p => p.theme.sizes.six};
  min-height: 100px;
  > p {
    font-weight: 500;
    margin-bottom: ${p => p.theme.sizes.two};
  }
  > div {
    margin-top: ${p => p.theme.sizes.five};
  }
`

const Disclaimer = styled(Label)`
  margin-left: ${p => p.theme.sizes.five};
  font-size: 12px;
  color: ${p => p.theme.colors.gray2};
`

const optionsByType = type => [
  type === 'provider'
    ? 'This provider does not practice at this location.'
    : 'This facility is no longer at this location.',
  'Some of the information is incorrect.',
]

const disclaimerByType = type =>
  type === 'provider'
    ? `Reporting this location for this provider will remove this
location in search for other users in your organization.`
    : `Reporting this facility will remove this location in search for other users in your organization.`

export const ConfirmReportModal = ({
  closeModal = () => {},
  onClickAction = () => {},
  type,
}) => {
  const options = optionsByType(type)
  const [selectedOption, setSelectedOption] = useState(options[0])

  const actionTitle =
    selectedOption === options[0] ? 'Report Location' : 'Edit Location'

  return (
    <Modal
      onClose={closeModal}
      nested
      title="Report Location"
      gridContainerStyle={{ maxWidth: '600px' }}
      footer={
        <Flex
          s={p => ({
            marginRight: p.theme.sizes.six,
            justifyContent: 'flex-end',
          })}
        >
          <Button
            s={p => ({
              marginRight: p.theme.sizes.four,
            })}
            type={BUTTON_TYPE.LINK}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onClickAction(options.indexOf(selectedOption))}
            type={BUTTON_TYPE.PRIMARY}
          >
            {actionTitle}
          </Button>
        </Flex>
      }
    >
      <ContentContainer>
        <Label>Select why this location is inaccurate.</Label>
        {options.map((option, index) => {
          return (
            <div key={option}>
              <RadioOption
                label={option}
                checked={option === selectedOption}
                onClick={() => setSelectedOption(option)}
              />
              {index === 0 && <Disclaimer>{disclaimerByType(type)}</Disclaimer>}
            </div>
          )
        })}
      </ContentContainer>
    </Modal>
  )
}

ConfirmReportModal.propTypes = {
  closeModal: PropTypes.func,
  onClickAction: PropTypes.func,
  type: PropTypes.string,
}
