import React from 'react'
import styled from 'styled-components'
import { Block } from 'design-system/block'
import { Label } from 'design-system/label'
import Edit from 'assets/edit.svg'
import { Button, BUTTON_TYPE } from 'design-system/button'
import ReactSwitch from 'react-switch'
import { colors } from 'theme/colors'
import { Flex } from 'design-system/flex'

export const FieldsSectionList = ({
  sectionTitle = '',
  list = [],
  configKey = '',
  onClickEditField = () => {},
  editMode = false,
  onChangeEnabled = () => {},
  onClickAddNew = () => {},
}) => {
  return (
    <div>
      <SectionHeaderTitle>{sectionTitle}</SectionHeaderTitle>
      <Block type="CARD" s={() => ({ padding: 0 })}>
        {list.length > 0 ? (
          <>
            <CardHeader>
              <Label>Field Name</Label>
            </CardHeader>
            {list.map((item, index) => {
              const isLast = list.length - 1 === index
              return (
                <SectionRowItem
                  key={item.uuid}
                  configKey={configKey}
                  editMode={editMode}
                  item={item}
                  isLast={isLast}
                  onClickEdit={onClickEditField}
                  onChangeEnabled={onChangeEnabled}
                />
              )
            })}
          </>
        ) : (
          <Label
            s={p => ({
              padding: `${p.theme.sizes.five} ${p.theme.sizes.four}`,
            })}
          >
            {`There are no custom ${configKey} fields for this instance.`}
            <AddNewLink
              onClick={onClickAddNew}
            >{` Add a new ${configKey} field`}</AddNewLink>
          </Label>
        )}
      </Block>
    </div>
  )
}

const SectionRowItem = ({
  configKey = '',
  item = { display_name: 'Field name', enabled: false },
  isLast = false,
  onClickEdit = () => {},
  onChangeEnabled = () => {},
  editMode = false,
}) => {
  return (
    <SectionRow isLast={isLast}>
      <Label>{item.display_name}</Label>

      <Flex
        s={() => ({
          alignItems: 'center',
        })}
      >
        <ReactSwitch
          onChange={() => onChangeEnabled(configKey, item)}
          checked={item.enabled}
          onColor={colors.aubergine}
          checkedIcon={false}
          uncheckedIcon={false}
          height={16}
          width={32}
          disabled={!editMode}
        />
        <Button
          type={BUTTON_TYPE.LINK}
          onClick={() => onClickEdit(configKey, item)}
          s={p => ({
            ['> button']: {
              padding: 0,
              height: '32px',
              width: '32px',
            },
            borderRadius: '50%',
            marginLeft: p.theme.sizes.six,
          })}
        >
          <Edit />
        </Button>
      </Flex>
    </SectionRow>
  )
}

const AddNewLink = styled.span`
  cursor: pointer;
  font-weight: 600;
`

const SectionHeaderTitle = styled(Label)`
  font-size: ${p => p.theme.headingSizes.H3};
  font-weight: 700;
  margin-bottom: ${p => p.theme.sizes.four};
`

const CardHeader = styled(Block)`
  flex: 1;
  padding: 0;
  padding-top: ${p => p.theme.sizes.four};
  border-bottom: 1px solid ${p => p.theme.colors.gray4};
  p {
    padding: ${p => p.theme.sizes.two} ${p => p.theme.sizes.four};
    font-weight: 500;
    color: ${p => p.theme.colors.gray1};
  }
`

const SectionRow = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${p => p.theme.sizes.two} ${p => p.theme.sizes.four};
  ${p => ({
    ...(p.isLast
      ? {
          borderBottom: `1px solid ${p.theme.colors.clear}`,
        }
      : {
          borderBottom: `1px solid ${p.theme.colors.borderLight}`,
        }),
  })}
  p {
    color: ${p => p.theme.colors.gray1};
    font-weight: 400;
  }
`
