import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { Label } from 'design-system/label'
import { Flex } from 'design-system/flex'
import CheckmarkSVG from 'assets/checkmark.svg'
import ArrowDownSVG from 'assets/arrow-down.svg'
import ReportIconSVG from 'assets/report-icon.svg'
import LowConfidenceSVG from 'assets/low-confidence.svg'
import MediumConfidenceSVG from 'assets/med-confidence.svg'
import { useToggle } from 'hooks/useToggle'
import { MoreMenu } from 'components/dropdown/dropdown'
import { useOnClickOutside } from 'hooks/useOutsideClick'
import { useAuthState } from 'context/auth-state-context'
import { Button } from 'design-system/button'
import { colors } from 'theme/colors'

const IconContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  min-height: 16px;
  min-width: 16px;
  border-radius: 50%;
`

export const ReportIcon = ({ 'data-testid': dataTestId = 'report-icon' }) => {
  return (
    <IconContainer>
      <ReportIconSVG data-testid={dataTestId} width={16} height={16} />
    </IconContainer>
  )
}

export const LowConfidenceIcon = ({
  'data-testid': dataTestId = 'low-confidence-icon',
}) => {
  return (
    <IconContainer>
      <LowConfidenceSVG data-testid={dataTestId} width={16} height={16} />
    </IconContainer>
  )
}

export const MediumConfidenceIcon = ({
  'data-testid': dataTestId = 'medium-confidence-icon',
}) => {
  return (
    <IconContainer>
      <MediumConfidenceSVG data-testid={dataTestId} width={16} height={16} />
    </IconContainer>
  )
}

const CheckmarkContainer = styled(IconContainer)`
  background-color: ${p => p.theme.colors.aquamarine};
  > svg {
    path {
      fill: ${p => p.theme.colors.aubergine};
    }
  }
`

export const VerifiedIcon = ({
  'data-testid': dataTestId = 'verified-icon',
}) => {
  return (
    <CheckmarkContainer>
      <CheckmarkSVG data-testid={dataTestId} height={12} width={12} />
    </CheckmarkContainer>
  )
}

const NoSelectionContainer = styled(IconContainer)`
  border: 1px solid ${p => p.theme.colors.gray2};
  > span {
    font-size: 12px;
    color: ${p => p.theme.colors.gray2};
  }
`
export const NoSelectionIcon = ({
  'data-testid': dataTestId = 'no-selection-icon',
}) => {
  return <NoSelectionContainer data-testid={dataTestId} />
}

const MenuItem = styled(Flex)`
  align-items: center;
  :hover {
    background-color: ${p => p.theme.colors.gray7};
    cursor: pointer;
    > p {
      font-weight: 600;
    }
  }

  padding: ${p => p.theme.sizes.three} ${p => p.theme.sizes.four};
  > p {
    margin-left: ${p => p.theme.sizes.four};
  }
  ${p => ({
    ...(p.isFirst && {
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    }),
    ...(p.isLast && {
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
    }),
    ...(p.isSelected && {
      ['> p']: {
        fontWeight: '600',
      },
    }),
  })}
`

const VerifyToggleContainer = styled(Button)`
  display: flex;
  height: initial;
  > button {
    display: flex;
    height: initial;
    padding: 2px;
    justify-content: space-between;
    align-items: center;
    > svg {
      margin-left: ${p => p.theme.sizes.two};
      margin-right: ${p => p.theme.sizes.one};
    }
  }
  border: 1px solid ${p => p.theme.colors.gray5};
  border-radius: 10px;
`

const Container = styled(Flex)`
  position: relative;
  .moreMenuContainer {
    top: 30px;
    right: 0;
    max-width: 180px;
    padding: 0;
  }
`

export const VerificationToggle = ({
  uuid,
  handleVerification,
  isUpdating,
  menuOptions = {
    none: { confidence: 3, label: 'No Selection' },
    report: { confidence: 0, label: 'Report', tooltip: '' },
    verify: { confidence: 5, label: 'Verify', tooltip: '' },
  },
  activeIconMap = {
    low: false,
    medium: false,
    report: false,
    verify: false,
  },
}) => {
  const [authState] = useAuthState()
  const { permissionLevel } = authState
  const [showDropdown, toggleDropdown] = useToggle(false)

  const menuRef = useRef()

  useOnClickOutside(menuRef, () => {
    if (showDropdown) {
      toggleDropdown()
    }
  })

  const onClickConfidence = confidence => {
    console.log(
      `🚀 => VerificationToggle => onClickConfidence`,
      uuid,
      confidence,
    )
    handleVerification(uuid, confidence)
    toggleDropdown()
  }

  const { none, report, verify } = menuOptions

  // activeIconMap prop exists because
  // depending on where toggle is used, report could have different confidence score ie. insurances = 1 & locations = 0
  // also low/med can be an initial state but not a menu option. and have a confidence range
  // this allows the parent to determine which icon should be showed
  const {
    report: isReported,
    low: isLow,
    medium: isMedium,
    verify: isVerified,
  } = activeIconMap

  let tooltipMessage = ''
  if (isVerified) {
    tooltipMessage = verify.tooltip
  }
  if (isReported) {
    tooltipMessage = report.tooltip
  }

  let hasEditPermission = false
  if (permissionLevel && permissionLevel >= 2) {
    hasEditPermission = true
  }

  return (
    <Container className="verifyToggleContainer">
      {hasEditPermission ? (
        <VerifyToggleContainer
          data-testid={uuid}
          onClick={toggleDropdown}
          disabled={isUpdating}
        >
          <div data-for="verification-status" data-tip={tooltipMessage}>
            {!isReported && !isVerified && !isLow && !isMedium && (
              <NoSelectionIcon />
            )}
            {/* Low & Medium icons are never menu options but can be initial state */}
            {isLow && <LowConfidenceIcon />}
            {isMedium && <MediumConfidenceIcon />}
            {/*  */}
            {isReported && <ReportIcon />}
            {isVerified && <VerifiedIcon />}

            {tooltipMessage && (
              <ReactTooltip
                place="bottom"
                effect="solid"
                backgroundColor={colors.gray2}
                className="custom-tooltip"
                id="verification-status"
              />
            )}
          </div>
          <ArrowDownSVG />
        </VerifyToggleContainer>
      ) : (
        <>
          {/* No Selection has no icon with no edit permission  */}
          {/* Low & Medium icons are never menu options but can be initial state */}
          {isLow && <LowConfidenceIcon />}
          {isMedium && <MediumConfidenceIcon />}
          {isReported && <ReportIcon />}
          {isVerified && <VerifiedIcon />}
        </>
      )}
      {showDropdown && (
        <MoreMenu ref={menuRef} className="moreMenuContainer">
          {none && (
            <MenuItem
              isSelected={!isReported && !isVerified}
              isFirst
              onClick={() => onClickConfidence(none.confidence)}
            >
              <NoSelectionIcon />
              <Label>{none.label}</Label>
            </MenuItem>
          )}
          {verify && (
            <MenuItem
              isSelected={isVerified}
              onClick={() => onClickConfidence(verify.confidence)}
              isFirst={!none}
            >
              <VerifiedIcon />
              <Label>{verify.label}</Label>
            </MenuItem>
          )}
          {report && (
            <MenuItem
              isSelected={isReported}
              isLast
              onClick={() => onClickConfidence(report.confidence)}
            >
              <ReportIcon />
              <Label>{report.label}</Label>
            </MenuItem>
          )}
        </MoreMenu>
      )}
    </Container>
  )
}

VerificationToggle.propTypes = {
  uuid: PropTypes.string,
  confidence: PropTypes.number,
  handleVerification: PropTypes.func,
  isUpdating: PropTypes.bool,
  menuOptions: PropTypes.object,
  activeIconMap: PropTypes.object,
}
