import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { InsuranceSearchContainer } from 'components/insurance-search-dropdown/insurance-search-container'
import { NameInput } from 'components/name-input/name-input'
import { LocationInput } from 'components/location-input/location-input.component'
import { FacilityTypeInput } from 'components/facility-type-input/facility-type-input.components'
import { useGlobalContext } from 'context/global-context-provider'
import { useAuthState } from 'context/auth-state-context'
import { searchTypes } from 'utils/constants'
import { Flex } from 'design-system/flex'
import { currentLocationOption } from 'utils/location'
import { SearchBtn } from 'components/search-btn/search-btn'

const InputContainer = styled.div`
  flex: 1;
`

export const FacilitySearchInputs = props => {
  const [searchState] = useGlobalContext()
  const [authState] = useAuthState()

  const onClickSearch = () => {
    if (!props.isSearchDisabled) {
      props.onSearchClick()
    }
  }

  const handleNameClear = () => {
    if (searchState.facilityName) props.onNameChange('')
  }

  const { modules } = authState
  const { modules: facilitiesModules } = modules[searchTypes.facilities] || {}

  return (
    <Flex s={() => ({ flex: 1 })}>
      <InputContainer>
        <LocationInput
          small={props.small}
          value={
            searchState.location && {
              id: `${searchState.location.position.latitude},${searchState.location.position.longitude}`,
              label: searchState.location.label,
              value: searchState.location,
            }
          }
          onSelection={value => props.onLocationChange(value)}
          showCurrentLocationOption
          defaultOptions={[currentLocationOption]}
          position="left"
        />
      </InputContainer>

      {facilitiesModules.networks.enabled && (
        <InputContainer>
          <InsuranceSearchContainer
            small={props.small}
            onSelection={v => {
              props.onInsuranceChange(v)
            }}
          />
        </InputContainer>
      )}

      <InputContainer>
        <FacilityTypeInput
          small={props.small}
          s={() => ({ borderRight: 0 })}
          value={searchState.facilityType}
          onSelection={v => props.onFacilityTypeChange(v)}
        />
      </InputContainer>

      <InputContainer>
        <NameInput
          onEnter={onClickSearch}
          small={props.small}
          placeholder={props.small ? 'Facility Name' : 'Enter facility name'}
          value={{
            label: searchState.facilityName || '',
          }}
          handleClear={handleNameClear}
          onInputChange={e => props.onNameChange(e.target.value)}
        />
      </InputContainer>

      <SearchBtn
        hasSearchChanges={props.hasSearchChanges}
        isSearchDisabled={props.isSearchDisabled}
        onClickSearch={onClickSearch}
        id="search-btn"
      />
    </Flex>
  )
}

FacilitySearchInputs.propTypes = {
  small: PropTypes.bool,
  isSearchDisabled: PropTypes.bool,
  hasSearchChanges: PropTypes.bool,
  onNameChange: PropTypes.func,
  onLocationChange: PropTypes.func,
  onFacilityTypeChange: PropTypes.func,
  onInsuranceChange: PropTypes.func,
  onSearchClick: PropTypes.func,
  theme: PropTypes.object,
}
