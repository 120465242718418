import React, {
  useState,
  useContext,
  useCallback,
  useRef,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { services } from 'services'
import styled from 'styled-components'
import {
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { Block } from 'design-system/block'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { LeftDetailColumn } from 'components/left-detail-column/left-detail-column.component'
import { BackToSearch } from 'components/back-to-search/back-to-search.component'
import { List } from 'design-system/list'
import { Label, LABEL_TYPE } from 'design-system/label'
import { LocationsPanel } from 'components/locations-panel/locations-panel'
import { RightDetailColumn } from 'components/right-detail-column/right-detail-column.component'
import { Spinner } from 'design-system/spinner/spinner.component'
import {
  DetailEditLocationForm,
  LOCATION_SCROLL_OPTIONS,
} from 'components/edit-location/edit-location-form'
import { EDIT_TYPES, searchTypes } from 'utils/constants'
import { AuthStateContext } from 'context/auth-state-context'
import { RatingLabel } from 'components/rating-label/rating-label.component'
import { colors } from 'theme/colors'
import { GlobalStateContext } from 'context/global-context-provider'
import { CustomFieldsSection } from 'components/custom-fields/custom-fields-section'
import { Flex } from 'design-system/flex'
import { searchQueryKeys } from 'containers/search/useSearchQueries'
import { ScrollToTopBtn } from 'components/scroll-to-top/scroll-to-top-btn'
import { NotyfContext } from 'context/notyf-context'
import { useIsMounted } from 'hooks/useIsMounted'
import { InsurancesContainer } from 'components/insurances-module/insurances-container'
import { NoteLabel } from 'components/notes/note-label'
import { NotesContainer } from 'components/notes/notes-container'
import { PreferenceToggle } from 'components/preference-toggle/preference-toggle'
import { ClinicalAreas } from '../provider-detail/components/clinical-areas/clinical-areas'

export const FacilityDetail = props => {
  const { location, facilityId } = props
  const { state } = location
  const { facility: stateFacility } = state || {}
  const [facility, setFacility] = useState(stateFacility)
  const queryClient = useQueryClient()
  const isMutating = useIsMutating(['updatingFacilityInfo']) //  used for when updating preference

  const [authState] = useContext(AuthStateContext)
  const [searchState] = useContext(GlobalStateContext)
  const notyf = useContext(NotyfContext)
  const [editType, setEditType] = useState()
  const [scrollTo, setScrollTo] = useState()
  const rightDetailContainerRef = useRef(false)
  const notesContainerRef = useRef()
  const isMounted = useIsMounted()

  const { modules } = authState
  const { modules: facilitiesModules } = modules[searchTypes.facilities] || {}
  const { facilitiesSearchQuery } = searchState

  // Initial query - always should fire on initial and only sets facility if
  // doest exist from location state
  useQuery(
    ['facilityById', facilityId],
    () => services.ribbon.getLocation({ id: facilityId }),
    {
      staleTime: 0,
      cacheTime: 0,
      onSuccess: res => {
        console.log('useQuery: fetchLocation Res', res)
        if (!facility) {
          setFacility(res)
        }
      },
      onError: error => {
        notyf.error(error.message)
      },
    },
  )

  const {
    mutateAsync: fetchLatestFacility,
    isLoading: fetchingFacility,
  } = useMutation(
    ({ options }) => services.ribbon.getLocation({ id: options.facilityId }),
    {
      onSuccess: latestFacility => {
        console.log('fetchLatestFacility res:', latestFacility)
        setFacility(latestFacility)
        navigate(`/facilities/${latestFacility.uuid}`, {
          state: { facility: latestFacility },
          replace: true,
        })
      },
      onError: error => {
        console.log('fetchLatestFacility error:', error)
        notyf.open({
          type: 'error',
          duration: 6000,
          message: `An error occurred fetching this facility's latest info.`,
        })
        // clear location state so any page refreshes dont have old data
        navigate(`.`, {
          state: {},
          replace: true,
        })
      },
    },
  )

  useEffect(() => {
    console.log('Facility:', facility)
  }, [facility])

  const refreshData = useCallback(
    async newFacility => {
      // adding fake latency so getLocation & searchLocations have time to return updated data
      // calling instantly would sometimes return old data.
      await new Promise(resolve => setTimeout(resolve, 1800))

      await fetchLatestFacility({
        options: {
          facilityId: newFacility
            ? newFacility.uuid
            : facility
              ? facility.uuid
              : undefined,
        },
      }).catch(error => {
        // Catching error as update was technically successfull and not to return error here
        // Error is handled onError of mutation.
        console.log('fetchLatestFacility:error', error)
      })

      queryClient.invalidateQueries({
        predicate: query => {
          // invalidating all other queries besides the current query in search
          // invalidated queries will be refetched when searched for again and not cached with old data
          const invalidateQuery =
            query.queryKey[0] &&
            query.queryKey[0] === searchQueryKeys.facilitiesSearch[0] &&
            (!query.queryKey[1] || query.queryKey[1] !== facilitiesSearchQuery)

          return invalidateQuery ? query : false
        },
      })

      // Do not need to wait for it to finish - worst case search results will have a loading indicator
      // Resetting data for any update/refresh, so data loads again on search container
      queryClient.refetchQueries(
        searchQueryKeys.facilitiesSearchWithQuery(facilitiesSearchQuery),
      )
    },
    [navigate, facility, facilitiesSearchQuery],
  )

  const closeModal = useCallback(
    async result => {
      console.log('closeModal:result', result)
      const { refresh, newFacility } = result || {}
      if (refresh) {
        if (newFacility && newFacility.uuid) {
          await refreshData(newFacility)
        } else {
          await refreshData()
        }
      }
      if (isMounted()) {
        setEditType()
        if (scrollTo) setScrollTo()
      }
    },
    [navigate, refreshData, scrollTo],
  )

  const handleCustomFieldUpdateSuccess = useCallback(async () => {
    await refreshData()
  }, [refreshData])

  const onClickEditLocation = useCallback(locationScroll => {
    typeof window !== 'undefined' &&
      window.gtag('event', 'click', { event_label: 'Edit Facility Location' })
    if (Object.values(LOCATION_SCROLL_OPTIONS).includes(locationScroll)) {
      // May need adjusting to be one state update with editType if not reliable enough
      setScrollTo(locationScroll)
    }
    setEditType(EDIT_TYPES.location)
  }, [])

  const onClickNotesLabel = () => {
    if (notesContainerRef && notesContainerRef.current) {
      notesContainerRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  const onClickAddNote = () => {
    setEditType(EDIT_TYPES.addNotes)
  }

  return (
    <>
      <Flex s={() => ({ height: '100%' })}>
        <Flex
          s={() => ({
            height: '100%',
            flexDirection: 'column',
            flex: 0.3,
            maxWidth: '27.5rem',
          })}
        >
          <BackToSearch
            onBackClick={() => navigate('/')}
            disabled={isMutating > 0 || fetchingFacility}
          />
          {facility && (
            <LeftDetailColumn>
              <Heading
                type={HEADING_TYPE.H1}
                s={p => ({
                  color: p.theme.colors.gray8,
                  lineHeight: '160%',
                  fontWeight: p.theme.fontWeights.semiUltraBold,
                })}
              >
                {facility.name}
              </Heading>
              {facility.locationTypes && (
                <List
                  disableItemHoverStyle={true}
                  s={p => ({ margin: `${p.theme.sizes.five} 0` })}
                >
                  {facility.locationTypes.map((location, index) => (
                    <Block
                      s={p => ({
                        lineHeight: p.theme.sizes.five,
                        padding: 0,
                      })}
                      key={`specialty-row-${index}`}
                    >
                      <Label
                        s={p => ({ color: p.theme.colors.gray1 })}
                        type={LABEL_TYPE.BODY1}
                      >
                        {location}
                      </Label>
                    </Block>
                  ))}
                </List>
              )}
              <Flex
                s={p => ({
                  ['> div:first-of-type']: {
                    marginRight: p.theme.sizes.six,
                  },
                })}
              >
                {facility.ratingsCount && facility.ratingsCount > 0 && (
                  <RatingLabel
                    rating={
                      facility.ratingsCount > 0
                        ? facility.ratingsAvg / 2
                        : undefined
                    }
                    label={`(${facility.ratingsCount})`}
                  />
                )}

                {facilitiesModules.notes && facilitiesModules.notes.enabled && (
                  <NoteLabel
                    notesLength={facility.notes ? facility.notes.length : 0}
                    onClickLabel={onClickNotesLabel}
                    onClickAddNote={onClickAddNote}
                  />
                )}
              </Flex>

              {facilitiesModules.preference &&
                facilitiesModules.preference.enabled && (
                  <PreferenceToggle
                    facility={facility}
                    handleUpdateSuccess={handleCustomFieldUpdateSuccess}
                  />
                )}

              <CustomFieldsSection
                type="facilities"
                section="sidebar"
                item={facility}
                handleUpdateSuccess={handleCustomFieldUpdateSuccess}
              />
            </LeftDetailColumn>
          )}
        </Flex>
        <Block
          s={() => ({
            padding: 0,
            flex: 0.7,
            height: '100%',
            background: colors.gray7,
          })}
        >
          <RightDetailColumn ref={rightDetailContainerRef}>
            {(facility && (
              <>
                <LocationsPanel
                  onClickEditLocation={onClickEditLocation}
                  location={facility}
                  setSelectedLocation={setFacility}
                  totalLocations={1}
                  refreshLocation={refreshData}
                  facility={facility}
                />
                {facilitiesModules.networks.enabled && (
                  <>
                    <Divider />

                    <InsurancesContainer
                      handleUpdateSuccess={closeModal}
                      facility={facility}
                      selectedLocation={facility}
                      modalType={editType}
                      setModalType={setEditType}
                    />
                  </>
                )}
                {facilitiesModules.focus_areas.enabled && (
                  <>
                    <Divider />
                    <ClinicalAreas
                      clinicalAreas={facility.clinical_areas}
                      isFacility={true}
                    />
                  </>
                )}

                {facilitiesModules.notes && facilitiesModules.notes.enabled && (
                  <>
                    <Divider />
                    <NotesContainer
                      notes={facility.notes}
                      facility={facility}
                      handleUpdateSuccess={handleCustomFieldUpdateSuccess}
                      ref={notesContainerRef}
                      // Edit & Add Notes Modals
                      modalType={editType}
                      setModalType={setEditType}
                    />
                  </>
                )}
                <CustomFieldsSection
                  type="facilities"
                  section="main"
                  item={facility}
                  handleUpdateSuccess={handleCustomFieldUpdateSuccess}
                />
              </>
            )) || (
                <Block>
                  <Spinner label="Loading facility" />
                </Block>
              )}
          </RightDetailColumn>
        </Block>
      </Flex>
      {editType === EDIT_TYPES.location && (
        <DetailEditLocationForm
          selectedLocation={facility}
          closeModal={closeModal}
          title={'Edit Location Details'}
          facility={facility}
          scrollTo={scrollTo}
        />
      )}

      <ScrollToTopBtn containerRef={rightDetailContainerRef} minScroll={500} />
    </>
  )
}

const Divider = styled.div`
  ${p => ({
    height: '1px',
    width: '100%',
    backgroundColor: p.theme.colors.gray4,
    margin: `${p.theme.sizes.six} 0`,
  })}
`

FacilityDetail.propTypes = {
  facilityId: PropTypes.string,
  location: PropTypes.object,
  state: PropTypes.object,
}
