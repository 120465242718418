import React from 'react'
import styled from 'styled-components'
import RibbonLogoImage from 'images/ribbon-logo.png'

const StyledContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  flex: 1;
  background-color: white;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  height: ${props => props.theme.sizes.seven};
`

const RibbonLogo = () => {
  return <Logo src={RibbonLogoImage} alt="Ribbon Health" />
}
export const PageLoading = () => {
  return (
    <StyledContainer>
      <RibbonLogo />
    </StyledContainer>
  )
}
