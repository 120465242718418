import React, { useState } from 'react'
import { Modal } from 'design-system/modal'
import { Block } from 'design-system/block'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label, LABEL_TYPE } from 'design-system/label'
import styled from 'styled-components'
import { Spinner } from 'design-system/spinner/spinner.component'
import { SelectInput } from 'design-system/input/select-input'
import { formatPermissionLevel } from 'utils/formatters/format-permission-levels'

const getPermissionHelpText = permission => {
  switch (permission) {
    case 1:
      return 'User can only view and search data'
    case 2:
      return 'User can add and edit fields'
    case 3:
      return 'User can add and edit fields, and manage users'

    default:
      return ''
  }
}

export const ManagePermissionsModal = ({
  handleClose = () => {},
  activeUsers = [{}],
  handleUpdateUsers = async () => {},
}) => {
  const [permissionLevel, setPermissionLevel] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const onChange = e => {
    const { value } = e.target
    setPermissionLevel(value)
  }

  const onClickSave = async () => {
    console.log(`🚀 => onClickSave => activeUsers`, activeUsers)

    const updateOptions = activeUsers.map(user => ({
      userId: user.id,
      options: {
        permission_level: permissionLevel,
      },
    }))
    // API call to delete Instance
    setIsLoading(true)
    try {
      await handleUpdateUsers(updateOptions)
      setIsLoading(false)
      handleClose()
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      onClose={handleClose}
      title={`Manage Permissions`}
      gridContainerStyle={{ maxWidth: '600px' }}
      footer={
        <Block
          type="FLEX"
          s={p => ({
            marginRight: p.theme.sizes.six,
            justifyContent: 'flex-end',
          })}
        >
          <Button
            s={() => ({
              marginRight: '1rem',
            })}
            type={BUTTON_TYPE.LINK}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            onClick={onClickSave}
            type={BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.LARGE}
            disabled={!permissionLevel || isLoading}
            s={() => ({
              minWidth: '110px',
            })}
          >
            Save
          </Button>
        </Block>
      }
    >
      {isLoading ? (
        <Spinner label={`Updating Permissions..`} />
      ) : (
        <FormContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Permission Level</Label>
          <SelectInput
            onChange={onChange}
            name="permissions"
            value={
              permissionLevel ? formatPermissionLevel(permissionLevel) : ''
            }
            options={[
              {
                label: formatPermissionLevel(1),
                value: 1,
              },
              {
                label: formatPermissionLevel(2),
                value: 2,
              },
              {
                label: formatPermissionLevel(3),
                value: 3,
              },
            ]}
            placeholder="Select permission level"
            disabled={false}
            containerStyle={{ margin: '1em 0', maxWidth: '50%' }}
          />
          <Label
            s={p => ({
              color: p.theme.colors.gray1,
            })}
          >
            {getPermissionHelpText(permissionLevel)}
          </Label>
        </FormContainer>
      )}
    </Modal>
  )
}

const FormContainer = styled(Block)`
  padding: ${p => p.theme.sizes.four};
  min-height: 250px;
`
