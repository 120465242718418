import React, { useState, useEffect } from 'react'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import { Input } from 'design-system/input'
import SearchSVG from 'assets/developer-dashboard/search.svg'
import { Checkbox } from 'design-system/checkbox'

export const SearchTypeColumn = ({
  type = 'Type',
  selectedTypes = [],
  onClickType = () => {},
  onClickViewAll = () => {},
  data = [],
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [filteredData, setFilteredData] = useState(data)

  useEffect(() => {
    setFilteredData(data.filter(i => i.display.includes(searchValue)))
  }, [searchValue])

  const onChangeSearch = e => {
    setSearchValue(e.target.value)
  }

  const onClickTypeCallback = value => {
    onClickType(value, type)
  }

  const onClickViewAllCallback = () => {
    onClickViewAll(type)
  }

  return (
    <Flex
      s={p => ({
        flexDirection: 'column',
        margin: `0 ${p.theme.sizes.six}`,
        width: '275px',
      })}
    >
      <Label
        s={() => ({
          fontWeight: '500',
        })}
      >
        {`${type} Types`}
      </Label>
      <Input
        placeholder={`Search ${type} type`}
        value={[{ label: searchValue }]}
        leftIcon={SearchSVG}
        onChange={() => setSearchValue('')}
        onInputChange={onChangeSearch}
        s={p => ({
          marginTop: p.theme.sizes.four,
          borderRadius: p.theme.sizes.thirteen,
          backgroundColor: p.theme.colors.white,
        })}
      />
      {filteredData.length === 0 && (
        <Label
          s={p => ({
            color: p.theme.colors.gray1,
            marginTop: p.theme.sizes.four,
          })}
        >
          No results found
        </Label>
      )}
      {filteredData.map((option, index) => {
        const isSelected = !!selectedTypes.find(i => i === option.display)
        if (index > 4) {
          return null
        }
        return (
          <Flex
            key={option.display}
            s={p => ({
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: p.theme.sizes.four,
            })}
          >
            <Flex
              s={() => ({
                alignItems: 'center',
              })}
            >
              <Checkbox
                s={p => ({
                  marginRight: p.theme.sizes.two,
                })}
                value={isSelected}
                onClick={() => onClickTypeCallback(option.display)}
              />
              <Label
                s={p => ({
                  color: p.theme.colors.gray1,
                })}
              >
                {option.display}
              </Label>
            </Flex>
            <Label
              s={p => ({
                color: p.theme.colors.gray2,
              })}
            >
              {option.value}
            </Label>
          </Flex>
        )
      })}
      <Label
        s={p => ({
          fontWeight: '600',
          color: p.theme.colors.aubergine2,
          marginTop: p.theme.sizes.four,
        })}
        onClick={onClickViewAllCallback}
      >
        View All
      </Label>
    </Flex>
  )
}
