import React from 'react'
import PropTypes from 'prop-types'
import { Block } from 'design-system/block'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Spinner } from 'design-system/spinner/spinner.component'
import { Flex } from 'design-system/flex'
import styled from 'theme'

const BoldSpan = styled.span`
  font-weight: bold;
  color: ${p => p.theme.colors.aubergine};
`

export const ReviewChanges = ({
  diff,
  renderArrayChanges = () => {},
  renderPropertyChanges = () => {},
  title = '',
  loading = false,
  loadingMessage = 'Updating',
}) => {
  console.log('Diff:', diff)

  let diffCount = 0
  if (diff) {
    Object.keys(diff).forEach(key => {
      // _t:'a' indicates this is an array
      if (!diff[key]._t) {
        diffCount += Object.keys(diff[key]).length
      } else {
        Object.keys(diff[key]).forEach(itemKey => {
          if (!itemKey.includes('_t')) {
            diffCount += 1
          }
        })
      }
    })
  }

  if (loading) {
    return (
      <Block
        s={() => ({
          minHeight: '450px',
        })}
      >
        <Spinner label={loadingMessage} />
      </Block>
    )
  }
  return (
    <Block
      s={() => ({
        minHeight: '450px',
      })}
    >
      <Flex
        s={p => ({
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: p.theme.colors.gray3,
          paddingBottom: p.theme.sizes.four,
          flexDirection: 'column',
        })}
      >
        <Label type={LABEL_TYPE.BODY1_BOLD}>{title}</Label>
        <Label
          type={LABEL_TYPE.BODY1}
          s={p => ({
            color: p.theme.colors.gray2,
            marginTop: p.theme.sizes.three,
          })}
        >
          <BoldSpan>{`${diffCount} ${
            diffCount === 1 ? 'item' : 'items'
          } `}</BoldSpan>
          will be updated. Once submitted, changes will be visible immediately
          to members within
        </Label>
      </Flex>
      <Flex
        s={p => ({
          paddingTop: p.theme.sizes.four,
          marginBottom: p.theme.sizes.two,
          flexDirection: 'column',
        })}
      >
        {diff &&
          Object.keys(diff).map(key => {
            const item = diff[key]
            return (
              <Flex
                key={key}
                s={p => ({
                  paddingTop: p.theme.sizes.four,
                  marginBottom: p.theme.sizes.four,
                  flexDirection: 'column',
                })}
              >
                <Label
                  s={p => ({
                    marginBottom: p.theme.sizes.two,
                  })}
                  type={LABEL_TYPE.BODY1_BOLD}
                >
                  {key}
                </Label>
                {item._t && renderArrayChanges(key, item)}
                {!item._t &&
                  Object.keys(item).map(itemKey => {
                    return (
                      <Flex
                        s={p => ({
                          justifyContent: 'space-between',
                          marginBottom: p.theme.sizes.two,
                        })}
                        key={itemKey}
                      >
                        <Label type={LABEL_TYPE.BODY1}>
                          {renderPropertyChanges(item, itemKey)}
                        </Label>
                        <Label type={LABEL_TYPE.BODY1}>Modified</Label>
                      </Flex>
                    )
                  })}
              </Flex>
            )
          })}
      </Flex>
    </Block>
  )
}

ReviewChanges.propTypes = {
  diff: PropTypes.object,
  renderArrayChanges: PropTypes.func,
  renderPropertyChanges: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
}
