import React, { useContext, useState } from 'react'
import { Modal } from 'design-system/modal'
import { Block } from 'design-system/block'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Input, INPUT_TYPE } from 'design-system/input'
import styled from 'styled-components'
import { useMutation, useQueryClient } from 'react-query'
import { services } from 'services'
import { AuthStateContext } from 'context/auth-state-context'
import { NotyfContext } from 'context/notyf-context'

export const ActivateInstanceModal = ({ handleClose = () => {} }) => {
  const queryClient = useQueryClient()
  const [uuid, setUuid] = useState('')
  const [authState] = useContext(AuthStateContext)
  const notyf = useContext(NotyfContext)
  const { mutate: manageInstance, isLoading } = useMutation(
    ({ token, uuid, options }) =>
      services.ribbon.manageInstance(token, uuid, options),
    {
      onSuccess: res => {
        console.log(`🚀 => manageInstance => res`, res)
        queryClient.refetchQueries('applications')
        handleClose()
      },
      onError: error => {
        console.log(`🚀 => manageInstance => error`, error)
        notyf.error(error.message)
      },
    },
  )

  const onChangeText = event => {
    const { value } = event.target

    setUuid(value)
  }

  const onClickActivate = () => {
    console.log(`🚀 => onClickActivate => uuid`, uuid)
    manageInstance({
      token: authState.token,
      uuid: uuid,
      options: {
        has_fca_instance: true,
      },
    })
  }

  const actionDisabled = uuid.length === 0
  return (
    <Modal
      onClose={handleClose}
      title={'Activate New Instance'}
      gridContainerStyle={{ maxWidth: '600px' }}
      footer={
        <Block
          type="FLEX"
          s={p => ({
            marginRight: p.theme.sizes.six,
            justifyContent: 'flex-end',
          })}
        >
          <Button
            s={() => ({
              marginRight: '1rem',
            })}
            type={BUTTON_TYPE.LINK}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            onClick={onClickActivate}
            type={actionDisabled ? BUTTON_TYPE.DISABLED : BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.LARGE}
            disabled={actionDisabled}
            loading={isLoading}
            s={() => ({
              minWidth: '110px',
            })}
          >
            Activate
          </Button>
        </Block>
      }
    >
      <FormContainer>
        <Label type={LABEL_TYPE.BODY1_BOLD}>UUID</Label>
        <Input
          s={p => ({ marginTop: p.theme.sizes.two })}
          onInputChange={onChangeText}
          name="uuid"
          value={[{ label: uuid }]}
          showClearIcon={false}
          type={INPUT_TYPE.ROUND}
          placeholder="Enter Application UUID"
        />
      </FormContainer>
    </Modal>
  )
}

const FormContainer = styled(Block)`
  padding: ${p => p.theme.sizes.four};
  max-width: 320px;
  min-height: 200px;
`
