import { useContext, useMemo } from 'react'
import { NotyfContext } from 'context/notyf-context'
import { useQuery } from 'react-query'
import * as AlgoliaService from 'services/algolia'
import { AuthStateContext } from 'context/auth-state-context'
import { formatInsurancePlansByState } from 'utils/insurances'

const searchQueryKeys = {
  insurancePlansByCarrier: (carrier, planSearch) => [
    'insurancePlansByCarrier',
    carrier,
    planSearch,
  ],
}

export const useInsurancePlansByCarriersQuery = (
  carrier,
  planSearch,
  formatByState = true,
) => {
  const notyfContext = useContext(NotyfContext)
  const [authState] = useContext(AuthStateContext)
  const appId = authState.refTypeaheadId
  const apiKey = authState.refTypeaheadKey
  const appName = authState.refTypeaheadName

  const { isLoading, data } = useQuery(
    searchQueryKeys.insurancePlansByCarrier(carrier, planSearch),
    () =>
      AlgoliaService.searchAlgoliaInsurances({
        appId: appId,
        apiKey: apiKey,
        appName: appName,
        inputValue: planSearch,
        options: {
          hitsPerPage: 1000,
          facetFilters: ['type: insurance', `carrier_name: ${carrier}`],
          distinct: 0,
        },
      }),
    {
      enabled: !!(carrier && carrier.length > 0),
      onError: error => {
        notyfContext.error(
          error.message || `An error occurred fetching ${carrier} plans.`,
        )
      },
    },
  )

  const results = useMemo(
    () => (formatByState ? formatInsurancePlansByState(data) : data),
    [formatByState, data],
  )

  // console.log(`🚀 => useInsurancePlansByCarriersQuery => results`, results)

  return {
    results,
    isLoading,
  }
}
