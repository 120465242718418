import React, { useContext, useState } from 'react'
import { navigate } from 'gatsby'
import { services } from 'services'
import { Input, INPUT_SIZE, INPUT_TYPE } from 'design-system/input'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Block } from 'design-system/block'
import { Flex } from 'design-system/flex'
import { AuthStateContext, AUTH_ACTION_TYPES } from 'context/auth-state-context'
import { validEmail } from 'utils/validations'

export const UserPasswordLogin = () => {
  const [, dispatch] = useContext(AuthStateContext)
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [emailError, setEmailError] = useState()
  const [error, setError] = useState(0)
  const [loading, setLoading] = useState(false)

  const handleAuthenticateButtonClick = async () => {
    if (email && password && !loading) {
      setError(0)
      if (!validEmail(email)) {
        setEmailError(true)
        return
      }
      setLoading(true)
      try {
        const result = await services.ribbon.authenticate({ email, password })
        setLoading(false)
        if (result.modules && result.token) {
          dispatch({ type: AUTH_ACTION_TYPES.LOGGED_IN, payload: result })
          return
        }

        throw Error('User missing required data')
      } catch (error) {
        setLoading(false)
        setError((error && error.response && error.response.status) || 500)
      }
    }
  }

  return (
    <Flex
      s={() => ({
        flex: 1,
        alignItems: 'flex-start',
      })}
    >
      <Flex
        s={p => ({
          flexDirection: 'column',
          padding: p.theme.sizes.eight,
          border: `1px solid ${p.theme.colors.gray1}`,
          borderRadius: p.theme.sizes.four,
          maxWidth: '440px',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        })}
      >
        <Label s={p => ({ fontSize: p.theme.sizes.six, fontWeight: 800 })}>
          Login to Ribbon
        </Label>
        <Block
          s={p => ({
            margin: `${p.theme.sizes.six} 0`,
            width: '100%',
          })}
        >
          <Block s={p => ({ padding: 0, marginBottom: p.theme.sizes.three })}>
            <Input
              s={p => ({
                borderRadius: p.theme.sizes.thirteen,
                width: '100%',
                borderColor: emailError
                  ? p.theme.colors.errorRed
                  : p.theme.colors.borderMid,
              })}
              type={INPUT_TYPE.ROUND}
              size={INPUT_SIZE.EXTRA_LARGE}
              showClearIcon={false}
              inputType={'email'}
              placeholder={'Email address'}
              value={(email && [{ id: email, label: email }]) || undefined}
              onInputChange={e => {
                setEmail(e.target.value)
                setEmailError()
              }}
              onEnter={handleAuthenticateButtonClick}
            />
            {emailError && (
              <Label
                s={p => ({
                  textAlign: 'center',
                  margin: `${p.theme.sizes.two} 0`,
                })}
                type={LABEL_TYPE.ERROR}
              >
                Please enter a valid email.
              </Label>
            )}
          </Block>

          <Input
            inputType="password"
            showClearIcon={false}
            type={INPUT_TYPE.ROUND}
            size={INPUT_SIZE.EXTRA_LARGE}
            placeholder={'Password'}
            value={
              (password && [{ id: password, label: password }]) || undefined
            }
            onInputChange={e => setPassword(e.target.value)}
            onEnter={handleAuthenticateButtonClick}
          />
        </Block>
        <Button
          type={
            (email && password && BUTTON_TYPE.PRIMARY) || BUTTON_TYPE.DISABLED
          }
          disabled={!(email && password) || loading}
          size={BUTTON_SIZE.EXTRA_LARGE}
          onClick={handleAuthenticateButtonClick}
          s={() => ({ width: '100%', textAlign: 'center' })}
          loading={loading}
        >
          Login
        </Button>
        {error > 0 && (
          <Block
            s={p => ({
              marginTop: p.theme.sizes.two,
            })}
          >
            {error === 401 ? (
              <Label
                type={LABEL_TYPE.ERROR}
                s={() => ({
                  textAlign: 'center',
                })}
              >
                Email or password was incorrect.
              </Label>
            ) : (
              <Label
                type={LABEL_TYPE.ERROR}
                s={() => ({
                  textAlign: 'center',
                })}
              >
                There was an error authenticating, please reach out to{' '}
                <a href="mailto:support@ribbonhealth.com">
                  support@ribbonhealth.com
                </a>
              </Label>
            )}
          </Block>
        )}
        <Button
          type={BUTTON_TYPE.LINK}
          size={BUTTON_SIZE.EXTRA_LARGE}
          s={p => ({
            marginTop: p.theme.sizes.four,
            width: '100%',
          })}
          onClick={() => navigate('/reset-password')}
        >
          <Label
            s={p => ({
              color: p.theme.colors.aubergine,
              fontWeight: 500,
            })}
          >
            I forgot my password
          </Label>
        </Button>
      </Flex>
    </Flex>
  )
}
