import React from 'react'
import { Block } from 'design-system/block'
import { Row } from 'react-styled-flexboxgrid'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Modal } from 'design-system/modal'
import { Label } from 'design-system/label'

export const ConfirmCloseModal = ({
  closeModal = () => {},
  onClickCancel = () => {},
}) => {
  return (
    <Modal
      onClose={closeModal}
      title="Discard Changes?"
      gridContainerStyle={{ maxWidth: '600px' }}
      footer={
        <Row end="xs" style={{ paddingRight: '2rem' }}>
          <Button
            s={() => ({
              marginRight: '1rem',
            })}
            type={BUTTON_TYPE.LINK}
            onClick={onClickCancel}
          >
            Cancel
          </Button>
          <Button
            onClick={closeModal}
            type={BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.LARGE}
            data-testid="discard-changes-btn"
          >
            Discard
          </Button>
        </Row>
      }
    >
      <Block>
        <Label>
          Are you sure you want to discard the changes you’ve made? You cannot
          undo this action.
        </Label>
      </Block>
    </Modal>
  )
}
