import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { LocationInput } from 'components/location-input/location-input.component'
import styled from 'styled-components'
import { AuthStateContext } from 'context/auth-state-context'
import SearchSVG from 'assets/search.svg'
import { Flex } from 'design-system/flex'
import { services } from 'services'
import { AsyncSelectInput } from 'design-system/input/async-select-input'
import { colors } from 'theme/colors'
import { sizes } from 'theme/sizing'
import { COST_ESTIMATES_ACTION_TYPES } from 'context/cost-estimates-context'
import { currentLocationOption } from 'utils/location'
import { Label } from 'design-system/label'
import { focusNextInput } from 'utils/document'
import { SearchBtn } from 'components/search-btn/search-btn'

const searchParamsObjectHash = {
  objectHash: obj => JSON.stringify(obj),
  arrays: {
    detectMove: false,
  },
}
const jsonDiff = require('jsondiffpatch')

const InputContainer = styled.div`
  flex: 1;
`

export const CostEstimatesSearch = ({
  searchState,
  dispatch,
  search,
  fetchingCostEstimates,
  result,
  fetchingProcedure,
}) => {
  const { procedureDisplay = '', location } = searchState || {}
  const [authState] = useContext(AuthStateContext)

  let searchHasChanges = false

  let resultParams = {
    procedure: '',
    location: null,
  }

  if (result) {
    const { procedure: resultProcedure, location: resultLocation } = result
    resultParams.procedure = resultProcedure ? resultProcedure.display : null
    resultParams.location = resultLocation
  }
  const paramsDiff = jsonDiff
    .create(searchParamsObjectHash)
    .diff(
      { procedure: procedureDisplay, location: location || null },
      resultParams,
    )

  if (paramsDiff && location) {
    searchHasChanges = true
  }

  const onClickSearch = () => {
    if (!location) {
      focusNextInput('location-input')
      return
    }
    if (!procedureDisplay) {
      focusNextInput('procedureDisplay-input')
      return
    }

    if (fetchingProcedure || fetchingCostEstimates) {
      return
    }
    if (!searchHasChanges) {
      return
    }

    search()
  }

  const handleAsyncOnSelect = event => {
    console.log(`🚀 => handleAsyncOnSelect => event`, event)
    const { data } = event.target
    if (!data) {
      dispatch({
        type: COST_ESTIMATES_ACTION_TYPES.clearProcedure,
      })
    } else {
      dispatch({
        type: COST_ESTIMATES_ACTION_TYPES.update,
        payload: {
          procedureId: data.uuid,
          procedureDisplay: data.display,
        },
      })
      // flaky without timeout..
      setTimeout(() => {
        if (location) {
          focusNextInput('cost-estimates-search-btn')
        }
      }, 100)
    }
  }

  const onLocationChange = selection => {
    console.log(`🚀 => onLocationChange => selection`, selection)
    dispatch({
      type: COST_ESTIMATES_ACTION_TYPES.update,
      payload: {
        location: selection,
      },
    })
    focusNextInput('procedureDisplay-input')
  }

  const loadProcedureOptions = async (inputValue, callback) => {
    if (inputValue.length > 0) {
      try {
        const results = await services.ribbon.searchProcedures(
          authState.token,
          {
            search: inputValue,
          },
        )
        if (results && results.data) {
          const filteredResults = results.data
            ? results.data.reduce((acc, next) => {
                acc.push({
                  data: next,
                  label: next.display,
                  value: next.display,
                })
                return acc
              }, [])
            : []

          callback(filteredResults)
        }
      } catch (error) {
        console.log('Search Error: ', error)
        callback([])
      }
    }
    callback([])
  }

  return (
    <Flex
      s={() => ({
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        maxWidth: '830px',
      })}
    >
      <Flex
        s={() => ({
          width: '100%',
          maxWidth: '830px',
        })}
      >
        <Label
          s={p => ({
            fontWeight: '900',
            fontSize: p.theme.sizes.five,
          })}
        >
          Estimate Cost Tool
        </Label>
      </Flex>
      <Flex
        s={p => ({
          flex: 1,
          width: '100%',
          marginTop: p.theme.sizes.five,
        })}
      >
        <InputContainer>
          <LocationInput
            value={
              location && {
                id: `${location.position.latitude},${location.position.longitude}`,
                label: location.label,
                value: location,
              }
            }
            onSelection={onLocationChange}
            showCurrentLocationOption
            defaultOptions={[currentLocationOption]}
            position="left"
          />
        </InputContainer>
        <InputContainer>
          <AsyncSelectInput
            onChange={handleAsyncOnSelect}
            name="procedureDisplay"
            value={procedureDisplay ? procedureDisplay : ''}
            loadOptions={loadProcedureOptions}
            containerStyle={{ width: '100%', height: sizes.eight }}
            controlStyle={{
              borderRadius: 0,
              borderColor: colors.borderLight,
            }}
            placeholder={'Search Procedures'}
            isClearable
            iconLeft={() => <SearchSVG width="16px" height="16px" />}
          />
        </InputContainer>
        <SearchBtn
          hasSearchChanges={searchHasChanges}
          isSearchDisabled={false}
          onClickSearch={onClickSearch}
          id="cost-estimates-search-btn"
        />
      </Flex>
    </Flex>
  )
}

CostEstimatesSearch.propTypes = {
  search: PropTypes.func,
  fetchingCostEstimates: PropTypes.bool,
  fetchingProcedure: PropTypes.bool,
  searchState: PropTypes.object,
  result: PropTypes.object,
  dispatch: PropTypes.func,
}
