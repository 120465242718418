import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Label } from 'design-system/label'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { Modal } from 'design-system/modal'
import { EditInsurances } from 'components/insurances-module/edit-insurances'
import { ConfirmCloseModal } from 'components/confirm-close/confirm-close-modal'
import { Flex } from 'design-system/flex'
import PlusSVG from 'assets/plus.svg'
import {
  editInsurancesInitialState,
  editInsurancesReducer,
  EDIT_INSURANCE_ACTION_TYPES,
  EDIT_INSURANCE_VIEWS,
} from './edit-insurances-reducer'
import { useUpdateInsuranceMutations } from './useUpdateInsuranceMutations'
import { useAuthState } from 'context/auth-state-context'
import { searchTypes } from 'utils/constants'

const objectHash = {
  objectHash: obj => JSON.stringify(obj),
  arrays: {
    detectMove: false,
  },
}
const jsonDiff = require('jsondiffpatch')

const ModalContent = styled(Flex)`
  height: 415px;
  flex-direction: column;
`

export const EditInsurancesModal = ({
  selectedLocation = {},
  closeModal = async () => {},
  allLocations = [],
  provider,
  facility,
}) => {
  const [authState] = useAuthState()
  const [state, dispatch] = useReducer(editInsurancesReducer, {
    ...editInsurancesInitialState,
    fcaUserId: authState.fcaUserId,
    type: provider ? searchTypes.providers : searchTypes.facilities,
    insurances: [...selectedLocation.insurances],
    verifications: selectedLocation.insuranceVerifications || {},
    editVerifications: JSON.parse(
      JSON.stringify(selectedLocation.insuranceVerifications || {}),
    ),
  })

  const {
    insurances,
    addInsurancesMap,
    editVerifications,
    verifications,
    view,
    previousView,
    selectedCarrier,
    confirmClose: showConfirmClose,
    copyFromLocation,
    type,
    storedNextAction,
  } = state

  const showAdd = view === EDIT_INSURANCE_VIEWS.add
  const showReview = view === EDIT_INSURANCE_VIEWS.review
  const showDefault = view === EDIT_INSURANCE_VIEWS.default
  const showCopyInsurances = view === EDIT_INSURANCE_VIEWS.copyInsurances
  const showEmpty = insurances.length === 0

  const {
    updateProviderLocationInsurancesMutation,
    updateProviderLocationDataMutation,
    updateLocationInsurancesMutation,
    updateLocationDataMutation,
  } = useUpdateInsuranceMutations({ handleClose: closeModal })

  const {
    mutate: mutateProviderLocationInsurances,
    isLoading: isAddingInsurances,
  } = updateProviderLocationInsurancesMutation
  const {
    mutate: mutateLocationInsurances,
    isLoading: isAddingLocationInsurances,
  } = updateLocationInsurancesMutation

  const {
    mutate: mutateProviderLocationData,
    isLoading: isUpdatingProviderLocationData,
  } = updateProviderLocationDataMutation
  const {
    mutate: mutateLocationData,
    isLoading: isUpdatingLocationData,
  } = updateLocationDataMutation

  const submittingChanges =
    isAddingInsurances ||
    isAddingLocationInsurances ||
    isUpdatingProviderLocationData ||
    isUpdatingLocationData

  const verificationsDiff = jsonDiff
    .create(objectHash)
    .diff(verifications, editVerifications)

  const hasChanges =
    (showAdd && Object.keys(addInsurancesMap).length > 0) ||
    (showDefault && verificationsDiff) ||
    (showCopyInsurances && copyFromLocation) ||
    showReview

  const submitChanges = async () => {
    console.log(`🚀 => submitChanges => state`, state)
    if (previousView === EDIT_INSURANCE_VIEWS.add) {
      const updateBody = {
        add: Object.keys(addInsurancesMap),
      }
      console.log(`🚀 => submitChanges => updateBody`, updateBody)
      if (facility) {
        mutateLocationInsurances({
          locationId: selectedLocation.uuid,
          options: updateBody,
        })
      }
      if (provider) {
        // Handle update on Provider Location level
        mutateProviderLocationInsurances({
          providerId: provider.npi,
          locationId: selectedLocation.uuid,
          options: updateBody,
        })
      }
    }

    if (previousView === EDIT_INSURANCE_VIEWS.default) {
      console.log(`🚀 => submitChanges => editVerifications`, editVerifications)
      if (facility) {
        mutateLocationData({
          locationId: selectedLocation.uuid,
          options: {
            insurance_verifications: editVerifications,
          },
        })
      }
      if (provider) {
        // Handle update on Provider Location level
        mutateProviderLocationData({
          providerId: provider.npi,
          locationId: selectedLocation.uuid,
          options: {
            insurance_verifications: editVerifications,
          },
        })
      }
    }

    // Option is only for providers
    if (
      previousView === EDIT_INSURANCE_VIEWS.copyInsurances &&
      type === searchTypes.providers
    ) {
      if (copyFromLocation) {
        const updateBody = {
          add: copyFromLocation.insurances.map(i => i.uuid),
        }
        console.log(`🚀 => submitChanges => updateBody`, updateBody)
        // Handle update on Provider Location level
        mutateProviderLocationInsurances({
          providerId: provider.npi,
          locationId: selectedLocation.uuid,
          options: updateBody,
        })
      }
    }
  }

  const handleClose = () => {
    if (hasChanges) {
      dispatch({
        type: EDIT_INSURANCE_ACTION_TYPES.toggleConfirmClose,
      })
      return
    }
    closeModal()
  }

  const onClickBackOrCancel = () => {
    if (view !== EDIT_INSURANCE_VIEWS.default) {
      if (view === EDIT_INSURANCE_VIEWS.add) {
        if (selectedCarrier) {
          return dispatch({
            type: EDIT_INSURANCE_ACTION_TYPES.updateSelectedCarrier,
            payload: {
              selectedCarrier: null,
            },
          })
        }
      }
      return dispatch({
        type: EDIT_INSURANCE_ACTION_TYPES.changeView,
        payload: { view: previousView },
      })
    }
    handleClose()
  }

  const onClickClearAll = () => {
    dispatch({
      type: EDIT_INSURANCE_ACTION_TYPES.resetUpdates,
    })
  }

  const otherLocations = allLocations.filter(location => {
    if (location.uuid !== selectedLocation.uuid) {
      return location
    }

    return undefined
  })

  let modalLabel = 'Edit Insurances'
  if (showAdd || showEmpty) {
    modalLabel = 'Add Insurances'
  }
  if (showReview) {
    modalLabel = 'Confirm Changes'
  }
  if (showCopyInsurances) {
    modalLabel = 'Copy Insurances'
  }

  if (showConfirmClose) {
    return (
      <ConfirmCloseModal
        closeModal={
          storedNextAction
            ? () => dispatch(storedNextAction)
            : () => closeModal()
        }
        onClickCancel={() =>
          dispatch({
            type: EDIT_INSURANCE_ACTION_TYPES.toggleConfirmClose,
            payload: {
              storedNextAction: null,
            },
          })
        }
      />
    )
  }

  return (
    <Modal
      onClose={handleClose}
      title={modalLabel}
      gridContainerStyle={{
        ['> div']: {
          overflow: 'hidden',
        },
        ['.modalContent']: {
          padding: 0,
        },
      }}
      headerRightAction={
        showDefault && insurances.length > 0 ? (
          <Button
            onClick={() => {
              const addInsurancesAction = {
                type: EDIT_INSURANCE_ACTION_TYPES.changeView,
                payload: { view: EDIT_INSURANCE_VIEWS.add },
              }
              if (hasChanges) {
                dispatch({
                  type: EDIT_INSURANCE_ACTION_TYPES.toggleConfirmClose,
                  payload: {
                    storedNextAction: addInsurancesAction,
                  },
                })
              } else {
                dispatch(addInsurancesAction)
              }
            }}
          >
            <PlusSVG height={16} width={16} style={{ marginRight: '12px' }} />
            <Label>Add Insurances</Label>
          </Button>
        ) : null
      }
      footer={
        showEmpty && showDefault ? null : (
          <Flex
            s={p => ({
              padding: `0 ${p.theme.sizes.six}`,
              justifyContent: 'space-between',
            })}
          >
            {showDefault && hasChanges ? (
              <Button
                s={p => ({
                  marginRight: p.theme.sizes.four,
                  ['> button']: {
                    color: p.theme.colors.errorRed,
                  },
                })}
                type={BUTTON_TYPE.LINK}
                onClick={onClickClearAll}
                disabled={submittingChanges}
              >
                Undo All
              </Button>
            ) : (
              <div />
            )}

            <Flex>
              <Button
                s={p => ({
                  marginRight: p.theme.sizes.four,
                })}
                type={BUTTON_TYPE.LINK}
                onClick={onClickBackOrCancel}
                disabled={submittingChanges}
              >
                {showAdd || showCopyInsurances || showReview
                  ? 'Back'
                  : 'Cancel'}
              </Button>
              <Button
                onClick={
                  showReview
                    ? submitChanges
                    : () =>
                        dispatch({
                          type: EDIT_INSURANCE_ACTION_TYPES.changeView,
                          payload: { view: EDIT_INSURANCE_VIEWS.review },
                        })
                }
                type={
                  !hasChanges || submittingChanges
                    ? BUTTON_TYPE.DISABLED
                    : BUTTON_TYPE.PRIMARY
                }
                disabled={!hasChanges || submittingChanges}
              >
                {showReview ? 'Submit changes' : 'Next: Review Changes'}
              </Button>
            </Flex>
          </Flex>
        )
      }
    >
      <ModalContent>
        <EditInsurances
          state={state}
          dispatch={dispatch}
          submittingChanges={submittingChanges}
          otherLocations={otherLocations}
          verificationsDiff={verificationsDiff}
        />
      </ModalContent>
    </Modal>
  )
}

EditInsurancesModal.propTypes = {
  selectedLocation: PropTypes.object,
  closeModal: PropTypes.func,
  addNew: PropTypes.bool,
  allLocations: PropTypes.array,
  provider: PropTypes.object,
  facility: PropTypes.object,
}
