/*global google*/
import React, { useContext, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Block } from 'design-system/block'
import { MapPanel } from 'components/map-panel/map-panel.component'
import { boundsToZoomLevel } from 'utils/gmaps'
import { ResultInfoWindow } from 'components/result-info-window/result-info-window.component'
import usePrevious from 'hooks/usePrevious'
import { formatInsuranceLabel } from 'utils/formatters/format-insurance-labels'
import { GlobalStateContext } from 'context/global-context-provider'
import { debounce } from 'utils/debounce.util'
import { searchTypes } from 'utils/constants'

/**
 * Provided selected marker, either returns provider's first specialty
 * or the facility's type. Or nothing.
 * @param {{}} selectedMarker
 */
const getResultSubtitle = selectedMarker => {
  if (selectedMarker.provider) {
    const { specialties } = selectedMarker.provider
    if (specialties && specialties === Array && specialties.length > 0) {
      return specialties[0].display
    }
  }

  const { locationTypes } = selectedMarker
  if (locationTypes && locationTypes === Array && locationTypes.length > 0) {
    return locationTypes[0]
  }

  return null
}

const StyledMapPanel = styled(MapPanel)`
  position: absolute;
  top: 0;
  left: 0;
`

export const PROVIDER = 'PROVIDER'
export const FACILITY = 'FACILITY'

const DEFAULT_ZOOM = 10

export const MapSection = ({
  isLoading = false,
  markerLocations = [],
  markerLocationBounds = {},
  selectedMarker = null,
  setSelectedMarker = () => {},
}) => {
  const mapRef = useRef()
  const [searchState = {}, update = () => {}] = useContext(GlobalStateContext)
  const [customZoom, setCustomZoom] = useState()
  const [customCenter, setCustomCenter] = useState()

  const {
    searchType,
    providersSearchQuery,
    facilitiesSearchQuery,
  } = searchState
  let searchedLocation = null
  if (searchType === searchTypes.providers) {
    if (providersSearchQuery) {
      searchedLocation = providersSearchQuery.location
    }
  }
  if (searchType === searchTypes.facilities) {
    if (facilitiesSearchQuery) {
      searchedLocation = facilitiesSearchQuery.location
    }
  }
  const searchResultZoom =
    markerLocations.length > 0 &&
    searchedLocation &&
    boundsToZoomLevel(
      markerLocationBounds.minLat,
      markerLocationBounds.maxLat,
      markerLocationBounds.minLng,
      markerLocationBounds.maxLng,
      searchedLocation.position.latitude,
      searchedLocation.position.longitude,
    )

  useEffect(() => {
    const handleResize = debounce(() => {
      if (mapRef.current) {
        const newSize =
          window.innerWidth - mapRef.current.getBoundingClientRect().x
        mapRef.current.style.width = `${newSize}px`
      }
    }, 250)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // reset customCenter on any searchState changes
  useEffect(() => {
    setCustomCenter()
  }, [providersSearchQuery, facilitiesSearchQuery])

  /**
   * Storing previous zoom levels when new markerlocations are being added
   * as backup to previous zoom level user has selected.
   * So map doesn't zoom out to Default zoom and back in to search result zoom
   */
  const previousSearchZoomLevel = usePrevious(searchResultZoom)
  const previousCustomZoomLevel = usePrevious(customZoom)

  let effectiveZoomLevel =
    customZoom ||
    searchResultZoom ||
    previousCustomZoomLevel ||
    previousSearchZoomLevel ||
    DEFAULT_ZOOM

  const previousEffectiveZoomLevel = usePrevious(effectiveZoomLevel)
  if (
    previousEffectiveZoomLevel > DEFAULT_ZOOM &&
    effectiveZoomLevel === DEFAULT_ZOOM
  ) {
    effectiveZoomLevel = previousEffectiveZoomLevel
  }

  // No Location zooms out to show all of USA
  if (!searchedLocation) effectiveZoomLevel = 3

  return (
    <Block
      s={() => ({
        boxShadow: 'inset 14px 4px 14px rgba(94, 94, 94, 0.06)',
        padding: 0,
        position: 'relative',
        height: '100%',
        flexGrow: 1,
      })}
    >
      <Block s={() => ({ padding: 0, height: '100%' })}>
        <StyledMapPanel
          ref={mapRef}
          width={'700px'}
          center={
            customCenter ||
            (searchedLocation
              ? {
                  lat: searchedLocation.position.latitude,
                  lng: searchedLocation.position.longitude,
                }
              : {
                  // Initial Center loadin: This location zoomed out to level 3 shows full USA
                  lat: 44.64132438867662,
                  lng: -96.99024656672994,
                })
          }
          shouldShowRedoSearchHereControl={!!(searchedLocation && customCenter)}
          zoom={effectiveZoomLevel}
          onZoomOut={() => setCustomZoom(Math.max(effectiveZoomLevel - 1, 1))}
          onZoomIn={() => setCustomZoom(Math.min(effectiveZoomLevel + 1, 22))}
          onRedoSearchHereClick={() =>
            customCenter &&
            update({
              key: 'location',
              value: {
                position: {
                  latitude: customCenter.lat,
                  longitude: customCenter.lng,
                },
                label: 'Map Location',
              },
            })
          }
          onCenterChanged={c => {
            if (c) {
              setCustomCenter({ lat: c.lat(), lng: c.lng() })
            }
          }}
          infoWindows={
            (selectedMarker && [
              {
                position: new google.maps.LatLng(
                  selectedMarker.latitude,
                  selectedMarker.longitude,
                ),
                onInfoWindowMouseEnter: () => {
                  setSelectedMarker(selectedMarker)
                },
                onInfoWindowMouseLeave: () => {
                  setSelectedMarker()
                },
                children: (
                  <ResultInfoWindow
                    title={
                      selectedMarker.provider
                        ? `${selectedMarker.provider.firstName} ${selectedMarker.provider.lastName}`
                        : selectedMarker.name
                    }
                    subtitle={getResultSubtitle(selectedMarker)}
                    rating={
                      selectedMarker.provider &&
                      selectedMarker.provider.ratingsAvg
                        ? selectedMarker.provider.ratingsAvg / 2
                        : null
                    }
                    ratingLabel={formatInsuranceLabel(
                      searchType === searchTypes.providers
                        ? providersSearchQuery
                        : facilitiesSearchQuery,
                      selectedMarker,
                    )}
                    addressText={selectedMarker.address}
                    distance={selectedMarker.distance}
                    preference={
                      selectedMarker.provider
                        ? selectedMarker.provider.preference
                        : selectedMarker.preference
                    }
                    hasNotes={
                      selectedMarker.provider
                        ? selectedMarker.provider.notes &&
                          selectedMarker.provider.notes.length > 0
                        : selectedMarker.notes &&
                          selectedMarker.notes.length > 0
                    }
                    type={
                      selectedMarker.provider
                        ? searchTypes.providers
                        : searchTypes.facilities
                    }
                    result={
                      selectedMarker.provider
                        ? selectedMarker.provider
                        : selectedMarker
                    }
                  />
                ),
              },
            ]) ||
            []
          }
          markers={
            (!isLoading &&
              markerLocations.map((markerLocation, j) => {
                const id = `${searchType}-location-${j}`
                return {
                  position: new google.maps.LatLng(
                    markerLocation.latitude,
                    markerLocation.longitude,
                  ),
                  id,
                  onMarkerMouseOver: () => setSelectedMarker(markerLocation),
                  icon: {
                    url: '/icons/marker-default.png',
                  },
                }
              })) ||
            []
          }
        />
      </Block>
    </Block>
  )
}
