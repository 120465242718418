import React, { useMemo, useState } from 'react'
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
  useFlexLayout,
} from 'react-table'
import { Flex } from 'design-system/flex'
import { PageContainer } from '../components/PageContainer'
import { PageTitle } from '../components/PageTitle'
import styled from 'styled-components'
import {
  logsFilterTypes,
  logsTableColumns,
  tableDefaultColumn,
} from './LogsTableOptions'
import { LogsTable } from './LogsTable'
import { DetailSearchInput } from 'components/detail-search-input/detail-search-input'
import { FilterDropdown } from 'components/dropdown/filter-dropdown'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { DateFilterDropdown } from './DateFilterDropdown'
import dayjs from 'utils/dayjs'
import { MethodFilterDropdown } from './MethodFilterDropdown'
import { StatusFilterDropdown } from './StatusFilterDropdown'
import { EndpointFilterDropdown } from './EndpointFilterDropdown'
import { mockApiLogsData } from '__mocks__/mockApiLogs'
import { ViewLogModal } from './ViewLogModal'

const ContentContainer = styled(Flex)`
  margin-top: ${p => p.theme.sizes.six};
  flex: 1;
  flex-direction: column;
`

export const Logs = () => {
  const [showMenu, setShowMenu] = useState()

  const defaultColumn = useMemo(() => tableDefaultColumn, [])
  const dataMemo = useMemo(() => mockApiLogsData, [])
  const filterTypes = useMemo(() => logsFilterTypes, [])
  const columnsMemo = useMemo(() => logsTableColumns, [])
  const [activeLog, setActiveLog] = useState(null)

  const [search, setSearch] = useState('')

  const tableInstance = useTable(
    {
      columns: columnsMemo,
      data: dataMemo,
      initialState: { pageIndex: 0, pageSize: 20 },
      defaultColumn,
      filterTypes,
      globalFilter: 'logsSearch',
    },
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const { setGlobalFilter, setFilter, state } = tableInstance

  const onSearch = event => {
    const { value } = event.target

    setSearch(value)
    setGlobalFilter(value)
  }

  const clearSearch = () => {
    setSearch('')
    setGlobalFilter('')
  }

  const onClickExportView = () => {
    //
  }

  const onClickClearFilter = type => {
    setShowMenu()
    setFilter(type)
  }

  const onClickApplyFilter = (type, filter) => {
    setShowMenu()
    setFilter(type, filter)
  }

  const onClickRow = row => {
    console.log('onClickRow', row)
    setActiveLog(row.original)
  }

  const dateCreatedFilter = state.filters.find(i => i.id === 'created_at')
  const pathsFilter = state.filters.find(i => i.id === 'url') || {
    id: 'url',
    value: {
      endpoints: [],
      methods: [],
    },
  }
  const statusFilter = state.filters.find(i => i.id === 'status')

  return (
    <PageContainer>
      <PageTitle>API Logs</PageTitle>

      <ContentContainer>
        <Flex
          s={p => ({
            justifyContent: 'space-between',
            marginBottom: p.theme.sizes.six,
            alignItems: 'flex-start',
          })}
        >
          <Flex
            s={p => ({
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              ['> div']: {
                marginBottom: p.theme.sizes.two,
              },
              flex: 1,
            })}
          >
            <FilterDropdown
              label={
                dateCreatedFilter
                  ? `${dayjs(dateCreatedFilter.value.from).format('L')}-${dayjs(
                      dateCreatedFilter.value.to,
                    ).format('L')}`
                  : 'Date'
              }
              onClickFilter={setShowMenu}
              showMenu={showMenu}
              closeMenu={() => setShowMenu()}
              renderMenu={
                <DateFilterDropdown
                  onClickClearFilter={onClickClearFilter}
                  onClickApplyFilter={onClickApplyFilter}
                  logsDateFilter={dateCreatedFilter}
                />
              }
            />
            <FilterDropdown
              label={
                pathsFilter.value.endpoints.length > 0
                  ? pathsFilter.value.endpoints.join(', ')
                  : 'Endpoint'
              }
              onClickFilter={setShowMenu}
              showMenu={showMenu}
              closeMenu={() => setShowMenu()}
              renderMenu={
                <EndpointFilterDropdown
                  onClickApplyFilter={onClickApplyFilter}
                  pathsFilter={pathsFilter}
                />
              }
            />
            <FilterDropdown
              label={
                pathsFilter.value.methods.length > 0
                  ? pathsFilter.value.methods.join(', ')
                  : 'Method'
              }
              onClickFilter={setShowMenu}
              showMenu={showMenu}
              closeMenu={() => setShowMenu()}
              renderMenu={
                <MethodFilterDropdown
                  onClickApplyFilter={onClickApplyFilter}
                  pathsFilter={pathsFilter}
                />
              }
            />
            <FilterDropdown
              label={statusFilter ? statusFilter.value.join(', ') : 'Status'}
              onClickFilter={setShowMenu}
              showMenu={showMenu}
              closeMenu={() => setShowMenu()}
              renderMenu={
                <StatusFilterDropdown
                  onClickClearFilter={onClickClearFilter}
                  onClickApplyFilter={onClickApplyFilter}
                  statusFilter={statusFilter}
                />
              }
            />
          </Flex>
          <Button onClick={onClickExportView} type={BUTTON_TYPE.PRIMARY}>
            Export View
          </Button>
        </Flex>
        <DetailSearchInput
          placeholder="Search URL by keyword"
          value={search}
          onChange={clearSearch}
          onInputChange={onSearch}
        />

        <LogsTable tableInstance={tableInstance} onClickRow={onClickRow} />
      </ContentContainer>
      {activeLog && (
        <ViewLogModal closeModal={() => setActiveLog(null)} log={activeLog} />
      )}
    </PageContainer>
  )
}
