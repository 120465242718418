import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { theme } from 'theme'
import { formatPhoneNumber } from 'utils/formatters/format-phone-number'
import { Flex } from 'design-system/flex'
import CloseSVG from 'assets/close-x.svg'

const NativeInput = styled.input`
  outline: 0;
  border: 0;
  background: transparent;
  font-family: 'Inter';
  :focus,
  :active {
    outline: 0;
    border: 0;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${p => p.theme.colors.text};
    opacity: 0.5;
  }
`

const UnstyledInput = props => {
  const inputRef = useRef()

  const handleInputChange = e => {
    if (props.inputType === 'phone') {
      const formatted = formatPhoneNumber(e.target.value)
      const eventFormatted = {
        ...e,
      }
      eventFormatted.target.value = formatted
      props.onInputChange && props.onInputChange(eventFormatted)
    } else {
      props.onInputChange && props.onInputChange(e)
    }
  }

  const handleClear = () => {
    props.onChange && props.onChange([])
  }

  return (
    <Flex className={props.className} onClick={props.onClick}>
      {props.leftIcon && (
        <Flex
          s={p => ({ paddingLeft: p.theme.sizes.two, alignItems: 'center' })}
        >
          <props.leftIcon />
        </Flex>
      )}
      <NativeInput
        id={props.id}
        ref={inputRef}
        onKeyPress={
          props.onEnter
            ? event => {
                let code
                if (event.key !== undefined) {
                  code = event.key
                } else if (event.keyIdentifier !== undefined) {
                  code = event.keyIdentifier
                } else if (event.keyCode !== undefined) {
                  code = event.keyCode
                }
                if ((code === 13 || code === 'Enter') && props.onEnter) {
                  props.onEnter()
                  if (inputRef && inputRef.current) {
                    inputRef.current.blur()
                  }
                }
              }
            : undefined
        }
        onKeyDown={props.onKeyDown}
        type={props.inputType}
        disabled={props.disabled}
        onFocus={props.onFocus}
        onChange={handleInputChange}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        name={props.name}
        value={
          (props.value && props.value.length > 0 && props.value[0].label) || ''
        }
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          color:
            props.value && props.value.length > 0
              ? theme.colors.gray0
              : theme.colors.gray1,
        }}
        step={props.step}
        {...((props.min !== undefined && { min: props.min }) || {})}
        {...((props.max !== undefined && { max: props.max }) || {})}
        autoComplete={props.autoComplete || 'off'}
      />
      {props.rightIcon && (
        <IconRightContainer>
          <props.rightIcon />
        </IconRightContainer>
      )}
      {props.value &&
        Array.isArray(props.value) &&
        props.value.length > 0 &&
        props.value[0].label &&
        props.showClearIcon &&
        !props.disabled && (
          <IconRightContainer
            data-testid="clear-input-btn"
            onClick={handleClear}
          >
            <CloseSVG height={12} width={12} />
          </IconRightContainer>
        )}
    </Flex>
  )
}

const IconRightContainer = styled.div`
  padding: ${p => p.theme.sizes.two} ${p => p.theme.sizes.two};
  margin-right: ${p => p.theme.sizes.three};
  border-radius: 4px;
  line-height: 12px;
  max-height: 36px;

  :hover {
    cursor: pointer;
    background-color: ${p => p.theme.colors.gray6};
  }
  > svg {
    path {
      fill: ${p => p.theme.colors.gray0};
    }
  }
`

UnstyledInput.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  showClearIcon: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onEnter: PropTypes.func,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string | PropTypes.number,
      }),
    ),
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  leftIcon: PropTypes.func,
  rightIcon: PropTypes.func,
  theme: PropTypes.object,
  inputType: PropTypes.oneOf(['password', 'text', 'email', 'number', 'phone'])
    .isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.string,
  autoComplete: PropTypes.string,
}

UnstyledInput.defaultProps = {
  showClearIcon: true,
  inputType: 'text',
  value: [],
}

const DEFAULT = props => `
  border: 1px solid ${props.theme.colors.borderLight};
  :focus-within {
      border: 1px solid ${props.theme.colors.aubergine};
  }
  :hover {
      border: 1px solid ${props.theme.colors.aubergine};
  }
`

const ERROR = props => `
  border: 1px solid ${props.theme.colors.errorRed};
`

const ROUND = props => `
  border: 1px solid ${props.theme.colors.borderMid};
  border-radius: 2rem;
  :focus-within {
    border: 1px solid ${props.theme.colors.aubergine};
}
:hover {
    border: 1px solid ${props.theme.colors.aubergine};
}
`

const DISABLED = () => `
  opacity: 50%;
`

const LARGE = p => `
  height: ${p.theme.sizes.seven};
  > input {
    height: ${p.theme.sizes.seven};
  }
`

const EXTRA_LARGE = p => `
  height: ${p.theme.sizes.eight};
  > input {
    height: ${p.theme.sizes.eight};
  }
`

export const INPUT_TYPE = {
  DEFAULT: 'DEFAULT',
  ERROR: 'ERROR',
  ROUND: 'ROUND',
}

const TYPE_STYLES = {
  DEFAULT,
  ERROR,
  ROUND,
}

export const INPUT_SIZE = {
  LARGE: 'LARGE',
  EXTRA_LARGE: 'EXTRA_LARGE',
}

const SIZE_STYLES = {
  LARGE,
  EXTRA_LARGE,
}

export const Input = styled(UnstyledInput)`
  overflow: hidden;
  align-items: center;
  > input {
    width: 100%;
    border: 0;
    padding: 0 ${p => p.theme.sizes.four};
    font-size: ${p => p.theme.sizes.four};
    &:-webkit-autofill::first-line,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      font-size: ${p => p.theme.sizes.four};
    }
  }

  ${props => TYPE_STYLES[props.type] && css(TYPE_STYLES[props.type])}
  ${props => SIZE_STYLES[props.size] && css(SIZE_STYLES[props.size])}
  ${props => props.disabled && css(DISABLED)}
  ${props => (props.s ? css(props.s(props)) : '')}
`

Input.propTypes = {
  s: PropTypes.func,
  type: PropTypes.oneOf(Object.keys(INPUT_TYPE)),
  size: PropTypes.oneOf(Object.keys(INPUT_SIZE)),
}

Input.defaultProps = {
  type: INPUT_TYPE.DEFAULT,
  size: INPUT_SIZE.LARGE,
}
