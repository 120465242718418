import React from 'react'
import RibbonCare from 'assets/ribbon-care.svg'
import { Label } from 'design-system/label'
import styled from 'styled-components'
import { Flex } from 'design-system/flex'

const Container = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media (max-width: ${p => p.theme.flexboxgrid.breakpoints.sm}em) {
    display: none;
  }
`

export const AuthLayoutAside = () => {
  return (
    <Container>
      <Label
        s={p => ({
          fontSize: p.theme.sizes.nine,
          lineHeight: p.theme.sizes.ten,
          fontWeight: '700',
          textAlign: 'center',
          marginBottom: p.theme.sizes.ten,
          maxWidth: '530px',
        })}
      >
        Find the right care with Ribbon
      </Label>

      <RibbonCare />
    </Container>
  )
}
