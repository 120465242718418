/*global google*/
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {MapPanel} from 'components/map-panel/map-panel.component'
import {LocationVerification} from 'components/locations-panel/location-verification'
import { Flex } from 'design-system/flex'
import { Block } from 'design-system/block'
import { Label, LABEL_TYPE } from 'design-system/label'
import { formatPhoneNumber } from 'utils/formatters/format-phone-number'
import {SeeOtherLocationsButton} from 'components/locations-panel/see-other-locations-button'

const PhoneAnchor = styled.a`
  color: ${p => p.theme.colors.textLight};
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`

const GetDirectionsAnchor = styled.a`
  color: ${p => p.theme.colors.aubergine};
  text-decoration: none;
`

export const PhysicalLocationPanel = ({
  location = {},
  setSelectedLocation,
  provider,
  facility,
  refreshLocation,
  onSeeOtherLocationsClick,
  onClickEditLocation
}) => {
  const handleMutation = options => {
    const { confidence, verified, verified_at } = options || {}
    setSelectedLocation(prev => ({
      ...prev,
      confidence,
      verified,
      verified_at,
    }))
  }

  /**
   *
   * @param {*} phoneNumbers
   * @param {string} type phone || fax
   * @returns Labels of phonenumbers, if primary phone exists only primary
   */
  const renderPhoneNumbers = (phoneNumbers, type = 'phone') => {
    const results = []
    const primaryPhoneNumber = phoneNumbers.find(
      item => item.details && item.details === 'primary',
    )
    if (primaryPhoneNumber) {
      results.push(primaryPhoneNumber)
    } else {
      results.push(...phoneNumbers)
    }
    return results.map((phoneNumber, phoneNumberIndex) => (
      <Label
        key={`${
          type === 'fax' ? 'fax' : 'phone'
        }-number-item-${phoneNumberIndex}`}
        type={LABEL_TYPE.BODY1}
        s={p => ({ color: p.theme.colors.gray1 })}
      >
        <PhoneAnchor
          onClick={e => e.stopPropagation()}
          href={`tel:${phoneNumber[type === 'fax' ? 'fax' : 'phone']}`}
        >
          {formatPhoneNumber(phoneNumber[type === 'fax' ? 'fax' : 'phone'])}
        </PhoneAnchor>
      </Label>
    ))
  }

  return (
    <Block
      type={'CARD'}
      s={p => ({
        padding: p.theme.sizes.four,
        lineHeight: p.theme.sizes.five,
        maxHeight: '32em',
        overflowY: 'auto',
      })}
    >
      <Flex>
        <Flex
          s={() => ({
            flex: 0.4,
            height: 192,
          })}
        >
          <MapPanel
            center={{
              lat: location.latitude,
              lng: location.longitude,
            }}
            markers={[
              {
                position:
                  window !== undefined && window.google !== undefined
                    ? new google.maps.LatLng(
                        location.latitude,
                        location.longitude,
                      )
                    : { lat: () => 0, lng: () => 0 },
                id: 1,
                icon: {
                  url: '/icons/marker-default.png',
                },
              },
            ]}
            zoom={15}
            showZoomControls={false}
          />
        </Flex>

        <Flex
          s={p => ({
            flex: 0.8,
            marginLeft: p.theme.sizes.six,
          })}
        >
          <Flex
            s={() => ({
              flexDirection: 'column',
              flex: 1,
            })}
          >
            {location.name && (
              <Flex
                s={p => ({
                  flexDirection: 'column',
                  marginBottom: p.theme.sizes.five,
                })}
              >
                <Label
                  type={LABEL_TYPE.BODY1}
                  s={p => ({ color: p.theme.colors.gray1 })}
                >
                  {location.name}
                </Label>
              </Flex>
            )}
            <Flex
              s={p => ({
                flexDirection: 'column',
                marginBottom: p.theme.sizes.five,
              })}
            >
              <Label
                type={LABEL_TYPE.BODY1}
                s={p => ({ color: p.theme.colors.gray1 })}
              >
                {location.address}
              </Label>
              {location.googleMapsLink && (
                <Label type={LABEL_TYPE.LINK}>
                  <GetDirectionsAnchor
                    rel="noreferrer"
                    target="_blank"
                    href={location.googleMapsLink}
                  >
                    Get directions
                  </GetDirectionsAnchor>
                </Label>
              )}
            </Flex>
            {location.phoneNumbers && location.phoneNumbers.length > 0 && (
              <Flex
                s={p => ({
                  flexDirection: 'column',
                  marginBottom: p.theme.sizes.five,
                })}
              >
                <Label
                  key={'phone-number-header'}
                  type={LABEL_TYPE.BODY1_BOLD}
                  s={p => ({
                    color: p.theme.colors.gray,
                    lineHeight: p.theme.sizes.six,
                  })}
                >
                  Phone Numbers
                </Label>

                {renderPhoneNumbers(location.phoneNumbers, 'phone')}
              </Flex>
            )}
            {location.faxes && location.faxes.length > 0 && (
              <Flex
                s={p => ({
                  flexDirection: 'column',
                  marginBottom: p.theme.sizes.five,
                })}
              >
                <Label
                  key={'phone-number-header'}
                  type={LABEL_TYPE.BODY1_BOLD}
                  s={p => ({
                    color: p.theme.colors.gray,
                    lineHeight: p.theme.sizes.six,
                  })}
                >
                  Fax Numbers
                </Label>
                {renderPhoneNumbers(location.faxes, 'fax')}
              </Flex>
            )}
            <SeeOtherLocationsButton
              provider={provider}
              onSeeOtherLocationsClick={onSeeOtherLocationsClick}
            />
          </Flex>
          <LocationVerification
            location={location}
            handleMutation={handleMutation}
            setSelectedLocation={setSelectedLocation}
            onClickEditLocation={onClickEditLocation}
            refreshLocation={refreshLocation}
            provider={provider}
            facility={facility}
          />
        </Flex>
      </Flex>
    </Block>
  )
}

PhysicalLocationPanel.propTypes = {
  location: PropTypes.object,
  setSelectedLocation: PropTypes.func,
  onSeeOtherLocationsClick: PropTypes.func,
  onClickEditLocation: PropTypes.func,
  refreshLocation: PropTypes.func,
  provider: PropTypes.object,
  facility: PropTypes.object,
}

PhysicalLocationPanel.defaultProps = {
  location: {},
}
