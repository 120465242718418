import React, { useState } from 'react'
import Switch from 'react-switch'
import PropTypes from 'prop-types'
import { Flex } from 'design-system/flex'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Label } from 'design-system/label'
import { colors } from 'theme/colors'
import { ClinicalAreasList } from './clinical-areas-list'

export const ClinicalAreas = ({ clinicalAreas, isFacility }) => {
  const isFacilityPage = isFacility ?? false;
  const [groupByArea, setGroupByArea] = useState(true)
  return (
    <div>
      <Flex
        s={p => ({
          justifyContent: 'space-between',
          margin: `${p.theme.sizes.four} 0 ${p.theme.sizes.four} 0`,
        })}
      >
        <Heading
          type={HEADING_TYPE.H2}
          s={p => ({
            color: p.theme.colors.aubergine2,
            marginBottom: 0,
          })}
        >
          Clinical Areas
        </Heading>

        {clinicalAreas && clinicalAreas.length > 0 && (
          <Flex
            s={() => ({
              alignItems: 'center',
            })}
          >
            <Switch
              onChange={() => setGroupByArea(prev => !prev)}
              checked={groupByArea}
              onColor={colors.aubergine}
              checkedIcon={false}
              uncheckedIcon={false}
              height={16}
              width={32}
              data-testid="group-by-area-switch"
            />
            <Label
              s={p => ({
                marginLeft: p.theme.sizes.two,
                color: p.theme.colors.gray1,
                fontWeight: 400,
              })}
            >
              Group by area
            </Label>
          </Flex>
        )}
      </Flex>

      {clinicalAreas && clinicalAreas.length > 0 ? (
        <ClinicalAreasList
          clinicalAreas={clinicalAreas}
          groupByArea={groupByArea}
          isFacilityPage={isFacilityPage}
        />
      ) : (
        <Label>There are no reported clinical areas at this location.</Label>
      )}
    </div>
  )
}

ClinicalAreas.propTypes = {
  clinicalAreas: PropTypes.array,
  isFacility: PropTypes.bool
}
