import { useContext } from 'react'
import { useMutation } from 'react-query'
import { services } from 'services'
import { AuthStateContext } from 'context/auth-state-context'
import { NotyfContext } from 'context/notyf-context'

export const useCustomFieldMutations = ({ field, updateId, handleClose }) => {
  const [authState] = useContext(AuthStateContext)
  const notyf = useContext(NotyfContext)
  const {
    mutate: updateProviderInfo,
    isLoading: updatingProviderField,
  } = useMutation(
    ({ options }) =>
      services.ribbon.updateProviderInfo(authState.token, updateId, options),
    {
      mutationKey: 'updateProviderInfo',
      onSuccess: async res => {
        console.log(`🚀 => updateProviderInfo => res`, res)
        await handleClose(true)
        notyf.success(`${field.display_name} updated!`)
      },
      onError: error => {
        console.log(`🚀 => updateProviderInfo => error`, error)
        notyf.error(error.message)
      },
    },
  )
  const {
    mutate: updateFacilityInfo,
    isLoading: updatingFacilityField,
  } = useMutation(
    ({ options }) => services.ribbon.updateLocationData(updateId, options),
    {
      onSuccess: async res => {
        console.log(`🚀 => updateLocationData => res`, res)
        await handleClose(true)
        notyf.success(`${field.display_name} updated!`)
      },
      onError: error => {
        console.log(`🚀 => updateLocationData => error`, error)
        notyf.error(error.message)
      },
    },
  )

  return {
    updateProviderInfo,
    updatingProviderField,
    updateFacilityInfo,
    updatingFacilityField,
  }
}
