import React from 'react'
import { Flex } from 'design-system/flex'
import PropTypes from 'prop-types'

export const TabIcon = ({ icon }) => {
  return (
    <Flex
      s={p => ({
        paddingLeft: p.theme.sizes.one,
        alignItems: 'center',
        marginRight: p.theme.sizes.three,
      })}
    >
      {icon}
    </Flex>
  )
}

TabIcon.propTypes = {
  icon: PropTypes.node
}
