import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex } from 'design-system/flex'
import { Label, LABEL_TYPE } from 'design-system/label'
import { TabIcon } from 'components/tab-icon/tab-icon.component'

const Container = styled(Flex)`
  flex-direction: column;
  margin-top: ${p => p.theme.sizes.six};
  align-items: flex-start;
  > div {
    align-items: center;
  }
`

const ToggleTabsContainer = styled(Flex)`
  flex: 0;
  width: 272px;
  margin-top: ${p => p.theme.sizes.two};
  background: ${p => p.theme.colors.gray7};
  border-radius: 5px;
  border: 1px solid ${p => p.theme.colors.borderDefault};
  > div :first-child {
    margin-right: ${p => p.theme.sizes.one};
    :hover {
      cursor: ${p => p.tabs[0].enabled ? 'pointer' : 'not-allowed'};
      margin-right: ${p => p.tabs[0].enabled && p.tabs[0].id !== p.activeTab ? 0 : p.theme.sizes.one};
    }
  }
  > div:last-child {
    margin-left: ${p => p.theme.sizes.one};
    :hover {
      cursor: ${p => p.tabs[1].enabled ? 'pointer' : 'not-allowed'};
      margin-left: ${p => p.tabs[1].enabled && p.tabs[1].id !== p.activeTab ? 0 : p.theme.sizes.one};
    }
  }
`

export const BinaryToggle = ({
  toggleTitle,
  tabs = [],
  onClickTab = () => { },
  activeTab,
}) => {
  return (
    <Container>
      <Flex>
        <Label
          type={LABEL_TYPE.BODY1}
          s={p => ({
            color: p.theme.colors.gray1,
            fontWeight: '700',
            fontSize: p.theme.headingSizes.H3,
          })}
        >
          {toggleTitle}
        </Label>
      </Flex>
      <ToggleTabsContainer
        tabs={tabs}
        activeTab={activeTab}
      >
        {tabs.map((tab, i) => {
          const isActive = tab.id == activeTab
          const hasHoverState = !isActive && tab.enabled
          return (
            <ToggleTab
              key={tab.id}
              isActive={isActive}
              hasHoverState={hasHoverState}
              tabIndex={i}
              onClick={() => onClickTab(tab)}
            >
              {isActive && tab.icon?.active && (
                <TabIcon icon={tab.icon.active} />
              )}
              {!isActive && tab.icon?.inactive && (
                <TabIcon icon={tab.icon.inactive} />
              )}
              <Label>{tab.title}</Label>
            </ToggleTab>
          )
        })}
      </ToggleTabsContainer>
    </Container>
  )
}

const ToggleTab = styled(Flex)`
  background-color: ${p => p.theme.colors.gray7};
  border-radius: 5px;
  margin: ${p => p.theme.sizes.one};
  padding: ${p => `${p.theme.sizes.two} ${p.theme.sizes.four}`};
  > p {
    font-weight: 500;
    color: ${p => p.theme.colors.gray3};
  }
  :hover {
    margin-left: ${p =>
    p.hasHoverState && p.tabIndex == 0 ? p.theme.sizes.two : p.theme.sizes.one};
    margin-right: ${p =>
    p.hasHoverState && p.tabIndex == 1 ? p.theme.sizes.two : p.theme.sizes.one};
  }
  ${p => ({
    ...(p.isActive && {
      backgroundColor: p.theme.colors.white,
      boxShadow: '0px 4px 4px 0px rgba(159, 159, 159, 0.25)',
      ['> p']: {
        color: p.theme.colors.gray0,
      },
    }),
  })}
`

BinaryToggle.propTypes = {
  toggleTitle: PropTypes.string,
  tabs: PropTypes.array,
  onClickTab: PropTypes.func,
  activeTab: PropTypes.string,
}
