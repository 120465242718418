import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Block } from 'design-system/block'
import { Label } from 'design-system/label'

const UnstyledRadioOption = props => {
  return (
    <Block
      type="FLEX"
      s={() => ({
        cursor: 'pointer',
        padding: 0,
        alignItems: 'center',
        position: 'relative',
      })}
      className={props.className}
      onClick={props.onClick}
    >
      <input
        type="radio"
        style={{
          position: 'absolute',
          opacity: 0,
          height: 0,
          width: 0,
          top: 0,
          left: 0,
        }}
      />
      <Block
        s={p => ({
          padding: 0,
          height: '16px',
          width: '16px',
          display: 'flex',
          backgroundColor: 'transparent',
          borderRadius: '8px',
          marginRight: p.theme.sizes.two,
          ...(props.checked
            ? {
                border: `5px solid ${p.theme.colors.aubergine}`,
              }
            : {
                border: `1px solid ${p.theme.colors.gray2}`,
              }),
        })}
      />
      <Label
        s={p => ({
          ...(props.checked
            ? {
                fontWeight: '500',
                color: p.theme.colors.gray0,
              }
            : {
                color: p.theme.colors.gray1,
              }),
        })}
      >
        {props.label}
      </Label>
    </Block>
  )
}

UnstyledRadioOption.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  checked: PropTypes.bool,
  label: PropTypes.string,
}

export const RadioOption = styled(UnstyledRadioOption)`
  ${props => (props.s ? css(props.s(props)) : '')}
`

RadioOption.propTypes = {
  s: PropTypes.func,
}
