import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Chevron } from 'design-system/icon/icons/chervon-left'
import { Flex } from 'design-system/flex'
import { useToggle } from 'hooks/useToggle'
import { Button } from 'design-system/button'
import {
  mapInsurancesByCarrier,
  formatInsurancePlansByState,
  formatDisplayNameByState,
} from 'utils/insurances'
import {
  ReportIcon,
  VerifiedIcon,
} from 'components/verification-toggle/verification-toggle'

export const ReviewInsuranceChanges = ({
  insurances,
  type,
  verificationsDiff,
  verifications,
}) => {
  const mappedByCarrierInsurances = mapInsurancesByCarrier(insurances)

  console.log(`🚀 => verificationsDiff`, verificationsDiff)
  return (
    <>
      {Object.keys(mappedByCarrierInsurances).map(carrier => {
        const carrierInsurances = mappedByCarrierInsurances[carrier]

        return (
          <ReviewChangesListItem
            key={carrier}
            carrier={carrier}
            carrierInsurances={carrierInsurances}
            type={type}
            verificationsDiff={verificationsDiff}
            verifications={verifications}
          />
        )
      })}
    </>
  )
}

ReviewInsuranceChanges.propTypes = {
  insurances: PropTypes.array,
  type: PropTypes.string,
  verificationsDiff: PropTypes.object,
  verifications: PropTypes.object,
}

const Header = styled(Flex)`
  flex: 1;
  justify-content: space-between;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${p => p.theme.colors.gray3};
  padding-bottom: ${p => p.theme.sizes.two};
  margin-bottom: ${p => p.theme.sizes.two};
  align-items: center;
  > p {
    font-weight: bold;
    color: ${p => p.theme.colors.text};
  }
`
const PlansContainer = styled(Flex)`
  flex-direction: column;
  margin-bottom: ${p => p.theme.sizes.four};
`

const PlanListHeader = styled(Flex)`
  align-items: center;
  margin-bottom: ${p => p.theme.sizes.two};
  margin-left: ${p => p.theme.sizes.four};
  padding: ${p => p.theme.sizes.three} 0;
  > p {
    font-weight: bold;
    color: ${p => p.theme.colors.text};
  }
`

const PlanLabel = styled(Label)`
  color: ${p => p.theme.colors.aubergine3};
  font-weight: 500;
`

const ReviewChangesListItem = ({
  carrier,
  carrierInsurances,
  type,
  verificationsDiff,
  verifications,
}) => {
  const [expanded, toggleExpanded] = useToggle(true)
  const formattedPlansByState = formatInsurancePlansByState(carrierInsurances)

  return (
    <Flex
      s={() => ({
        flexDirection: 'column',
      })}
    >
      <Header>
        <Label type={LABEL_TYPE.BODY1_BOLD}>
          {`${carrier} (${carrierInsurances.length})`}
        </Label>
        <Button
          s={() => ({
            ['> button']: {
              padding: 0,
              height: 24,
              width: 24,
            },
            borderRadius: 4,
            borderColor: 'transparent',
          })}
          onClick={toggleExpanded}
        >
          <Chevron direction={expanded ? 'up' : 'down'} />
        </Button>
      </Header>
      {expanded && (
        <PlansContainer>
          {formattedPlansByState.map(item => {
            const { isHeader, uuid, displayName } = item

            if (isHeader) {
              return (
                <PlanListHeader key={uuid}>
                  <Label>{displayName}</Label>
                </PlanListHeader>
              )
            }

            const isVerificationDiff = type === 'verification'
            let verificationConfidence
            if (isVerificationDiff) {
              let insuranceVerification = verificationsDiff[item.uuid]
              if (insuranceVerification) {
                // New entry into verifications
                if (Array.isArray(insuranceVerification)) {
                  if (insuranceVerification.length > 2) {
                    // array of length 3 indicates removed entry - eg. set as no selection of previously reported/verified
                    verificationConfidence = 3
                  } else {
                    // inserted new entry with confidence of verify/report scores
                    verificationConfidence = insuranceVerification[0].confidence
                  }
                }
                // Change of confidence number only
                if (
                  insuranceVerification.confidence &&
                  Array.isArray(insuranceVerification.confidence)
                ) {
                  verificationConfidence = insuranceVerification.confidence[1]
                }
              }
              if (!verificationConfidence) {
                const foundVerification = verifications[item.uuid]
                // this plan's verification has only changed updated_at or fcaUserId fields
                if (foundVerification) {
                  verificationConfidence = foundVerification.confidence
                }
              }
            }

            const label = formatDisplayNameByState(item)

            return (
              <Flex
                key={uuid}
                s={p => ({
                  marginBottom: p.theme.sizes.two,
                  marginLeft: p.theme.sizes.six,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                })}
              >
                <PlanLabel>{label}</PlanLabel>

                {verificationConfidence && verificationConfidence < 3 && (
                  <ReportIcon />
                )}
                {verificationConfidence && verificationConfidence > 3 && (
                  <VerifiedIcon />
                )}
              </Flex>
            )
          })}
        </PlansContainer>
      )}
    </Flex>
  )
}

ReviewChangesListItem.propTypes = {
  carrierInsurances: PropTypes.array,
  carrier: PropTypes.string,
  type: PropTypes.string,
  verificationsDiff: PropTypes.object,
  verifications: PropTypes.object,
}
