import React, { useRef } from 'react'
import styled from 'styled-components'
import { MoreMenu } from './dropdown'
import ArrowDown from 'assets/arrow-down.svg'
import { Label } from 'design-system/label'
import { useOnClickOutside } from 'hooks/useOutsideClick'
import { Flex } from 'design-system/flex'

const LabelContainer = styled(Flex)`
  padding: ${p => p.theme.sizes.two} ${p => p.theme.sizes.four};
  align-items: center;
  justify-content: flex-start;
  border: 1px solid ${p => p.theme.colors.borderMid};
  margin-right: ${p => p.theme.sizes.four};
  border-radius: 100px;
  position: relative;
  background-color: ${p => p.theme.colors.white};
  cursor: pointer;
  :hover {
    background-color: ${p => p.theme.colors.gray7};
    border: 1px solid ${p => p.theme.colors.aubergine};
  }
`

const StyledMoreMenu = styled(MoreMenu)`
  top: 44px;
  left: 0;
  min-height: 100px;
  min-width: 280px;
  padding: ${p => p.theme.sizes.five} 0;
`

const ArrowContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  > svg {
    ${p => ({
      ...(p.isOpen && {
        transform: `rotate(180deg)`,
      }),
    })}
  }
`

export const FilterDropdown = ({
  label = 'Label',
  labelComponent = null,
  onClickFilter = () => {},
  closeMenu = () => {},
  showMenu = '',
  renderMenu = () => null,
}) => {
  const menuRef = useRef()

  useOnClickOutside(menuRef, () => {
    if (showMenu && showMenu === label) {
      closeMenu()
    }
  })

  const onClickLabel = () => {
    if (!showMenu) {
      onClickFilter(label)
    } else {
      onClickFilter()
    }
  }

  return (
    <Flex
      s={() => ({
        position: 'relative',
      })}
      ref={menuRef}
    >
      {labelComponent || (
        <LabelContainer
          onClick={onClickLabel}
          className="filter-label-container"
        >
          <Label
            s={p => ({
              marginRight: p.theme.sizes.two,
            })}
          >
            {label}
          </Label>
          <ArrowContainer isOpen={showMenu && showMenu === label}>
            <ArrowDown />
          </ArrowContainer>
        </LabelContainer>
      )}
      {showMenu && showMenu === label && (
        <StyledMoreMenu className="filter-more-menu">
          {renderMenu}
        </StyledMoreMenu>
      )}
    </Flex>
  )
}
