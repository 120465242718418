import React from 'react'
import PropTypes from 'prop-types'
import { Input, INPUT_SIZE } from 'design-system/input'
import SearchSVG from 'assets/search.svg'

export const NameInput = props => {
  return (
    <Input
      id="name-input"
      size={INPUT_SIZE.EXTRA_LARGE}
      s={p => ({
        backgroundColor: 'white',
        ...(props.s && props.s(p)),
        ['input']: {
          paddingLeft: p.theme.sizes.two,
          paddingRight: p.theme.sizes.two,
        },
      })}
      placeholder={props.placeholder || ''}
      onEnter={props.onEnter}
      value={props.value ? [props.value] : ''}
      leftIcon={() => {
        if (props.small) {
          return null
        }
        return <SearchSVG width="16px" height="16px" />
      }}
      onChange={props.handleClear} // used for clearing input in this case
      onInputChange={props.onInputChange}
    />
  )
}

NameInput.propTypes = {
  small: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  s: PropTypes.func,
  onInputChange: PropTypes.func,
  onEnter: PropTypes.func,
  placeholder: PropTypes.string,
  handleClear: PropTypes.func,
}
