import React from 'react'
import PropTypes from 'prop-types'
import { Label, LABEL_TYPE } from 'design-system/label'
import { searchTypes } from 'utils/constants'
import { Flex } from 'design-system/flex'
import { PermissionRestricted } from 'components/permission-restricted/permission-restricted'
import { Button } from 'design-system/button'

export const SearchNoResults = ({
  type = searchTypes.providers,
  onClickAddNewFacility = () => {},
}) => {
  return (
    <Flex s={() => ({ flexDirection: 'column' })}>
      <Label
        s={p => ({
          padding: 0,
          marginBottom: p.theme.sizes.three,
          color: p.theme.colors.gray0,
        })}
        type={LABEL_TYPE.BODY1_BOLD}
      >
        To get better results:
      </Label>

      <Label type={LABEL_TYPE.BODY1}>• Try searching in a larger area</Label>
      {type === searchTypes.providers && (
        <Label type={LABEL_TYPE.BODY1}>
          • Try selecting a more general provider specialty
        </Label>
      )}
      {type === searchTypes.facilities && (
        <>
          <Label type={LABEL_TYPE.BODY1}>
            • Try selecting a different facility type
          </Label>
          <Label type={LABEL_TYPE.BODY1}>• Try a different location</Label>
        </>
      )}

      <Label type={LABEL_TYPE.BODY1}>• Try a different location</Label>

      {type === searchTypes.facilities && (
        <PermissionRestricted level={2}>
          <Flex
            s={p => ({
              flexDirection: 'column',
              marginTop: p.theme.sizes.six,
            })}
          >
            <Label>{`Don't see the facility you're looking for?`}</Label>
            <Button
              s={p => ({
                marginTop: p.theme.sizes.four,
                maxWidth: '240px',
              })}
              onClick={() => {
                typeof window !== 'undefined' &&
                  window.gtag('event', 'click', {
                    event_label: 'Add New Facility',
                  })
                onClickAddNewFacility()
              }}
            >
              Add a new facility
            </Button>
          </Flex>
        </PermissionRestricted>
      )}
    </Flex>
  )
}

SearchNoResults.propTypes = {
  type: PropTypes.string,
  onClickAddNewFacility: PropTypes.func,
}
