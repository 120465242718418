import React from 'react'
import styled from 'styled-components'
import dayjs from 'utils/dayjs'

export const logsTableColumns = [
  {
    Header: () => null,
    headerTitle: 'Status',
    accessor: 'status', // accessor is the "key" in the data
    defaultCanSort: true,
    filter: 'status',
    maxWidth: 60,
    width: 60,
  },
  {
    Header: () => null,
    headerTitle: 'Path',
    accessor: 'url', // accessor is the "key" in the data
    filter: 'url',
    defaultCanSort: false,
    Cell: cell => `${cell.row.original.method} ${cell.row.original.url}`,
  },
  {
    Header: () => null,
    headerTitle: 'Date',
    id: 'created_at',
    accessor: 'created_at', // accessor is the "key" in the data
    filter: 'created_at',
    defaultCanSort: true,
    width: 100,
    // eslint-disable-next-line react/display-name
    Cell: cell => (
      <RightAlignedContent>
        {dayjs(cell.row.original.created_at).format('lll')}
      </RightAlignedContent>
    ),
  },
]

const RightAlignedContent = styled.div`
  text-align: right;
`

export const logsFilterTypes = {
  // custom filter
  logsSearch: (rows, id, filterValue) => {
    // console.log('search', rows, id, filterValue)
    return rows.filter(row => {
      const {
        original: { url },
      } = row
      const urlLower = url ? url.toLowerCase() : ''

      return row !== undefined
        ? urlLower.includes(filterValue.toLowerCase())
        : false
    })
  },
  status: (rows, id, filterValue) => {
    // console.log(`🚀 => status:filterValue`, filterValue)
    return rows.filter(row => {
      const {
        original: { status },
      } = row

      return filterValue.includes(status)
    })
  },
  // eslint-disable-next-line no-unused-vars
  url: (rows, id, filterValue) => {
    // console.log(`🚀 => url:filterValue`, filterValue)
    // check if endpoints array or methods array are empty - return all.
    const { endpoints, methods } = filterValue
    if (!endpoints && !methods) {
      return rows
    }

    return rows.filter(row => {
      const { original } = row
      const { method, url } = original

      let returnRow = true // default return every row

      if (endpoints.length > 0) {
        // If endpoints filter exists and url doesnt include any of them,
        // dont return this row
        if (!endpoints.some(e => url.includes(e))) {
          returnRow = false
        }
      }

      if (methods.length > 0) {
        // If methods filter exists and method doesnt include any of them,
        // dont return this row
        if (!methods.some(m => m === method)) {
          returnRow = false
        }
      }

      if (returnRow) return row
    })
  },

  created_at: (rows, id, filterValue) => {
    const { from, to } = filterValue || {}

    if (!from || !to) {
      return rows
    }

    return rows.filter(row => {
      const {
        original: { created_at },
      } = row

      return (
        dayjs(created_at).isSameOrBefore(to) &&
        dayjs(created_at).isSameOrAfter(from)
      )
    })
  },
}

export const tableDefaultColumn = {
  // When using the useFlexLayout:
  minWidth: 40, // minWidth is only used as a limit for resizing
}
