import { useContext } from 'react'
import { useMutation } from 'react-query'
import { services } from 'services'
import { useUpdateDetailsMutations } from 'hooks/useUpdateDetailsMutations'
import { NotyfContext } from 'context/notyf-context'

export const useNoteMutations = ({
  notes,
  provider,
  facility,
  handleUpdateSuccess,
  addNew,
}) => {
  const notyf = useContext(NotyfContext)

  const {
    updateProviderInfo,
    updatingProviderInfo,
    updateFacilityInfo,
    updatingFacilityInfo,
  } = useUpdateDetailsMutations({
    provider,
    facility,
    onSuccess: async res => {
      console.log(`🚀 => useNoteMutations => res`, res)
      await handleUpdateSuccess(true)
      if (addNew) {
        notyf.success(`Note added!`)
      } else {
        notyf.success(`Note updated!`)
      }
    },
    onError: error => {
      console.log(`🚀 => useNoteMutations => error`, error)
      notyf.error(error.message)
    },
  })

  const { mutate: createNote, isLoading: creatingNote } = useMutation(
    options => services.ribbon.createNote(options),
    {
      onSuccess: createdNote => {
        console.log(`🚀 => createNote => res`, createdNote)
        const notesCopy = notes.slice()
        notesCopy.unshift(createdNote.data)

        // Update Provider Info
        if (provider) {
          updateProviderInfo({
            options: { notes: notesCopy },
          })
        }
        if (facility) {
          updateFacilityInfo({
            options: { notes: notesCopy },
          })
        }
      },
      onError: error => {
        console.log(`🚀 => createNote => error`, error)
        notyf.error(error.message)
      },
    },
  )

  return {
    createNote,
    creatingNote,
    updateProviderInfo,
    updatingProviderInfo,
    updateFacilityInfo,
    updatingFacilityInfo,
  }
}
