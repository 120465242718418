export const spinnerAsString = `<svg width="18"  height="18" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask
  id="mask0"
  mask-type="alpha"
  maskUnits="userSpaceOnUse"
  x="44"
  y="31"
  width="20"
  height="27"
>
  <path
    d="M44.4473 31.4948H63.5123V57.33H44.4473V31.4948Z"
    fill="white"
  />
</mask>
<g mask="url(#mask0)">
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M63.5129 32.4441C63.5129 32.126 63.506 31.8104 63.4971 31.4948L52.5075 31.8242C52.5132 32.03 52.5233 32.2351 52.5233 32.4441C52.5233 39.0662 49.3582 44.9536 44.4473 48.7145L51.1673 57.33C58.6762 51.5788 63.5129 42.5718 63.5129 32.4441Z"
    fill="#32213A"
  />
</g>
<mask
  id="mask1"
  mask-type="alpha"
  maskUnits="userSpaceOnUse"
  x="0"
  y="1"
  width="64"
  height="63"
>
  <path d="M0 1H63.4969V63.8894H0V1Z" fill="white" />
</mask>
<g mask="url(#mask1)">
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M31.756 53.0073C20.2867 53.0073 10.9896 43.8007 10.9896 32.4438C10.9896 21.087 20.2867 11.8804 31.756 11.8804C43.015 11.8804 52.1752 20.7551 52.5072 31.8239L63.4969 31.4945C62.9899 14.5671 48.9739 0.999146 31.756 0.999146C14.2169 0.999146 0 15.0766 0 32.4438C0 49.8111 14.2169 63.8892 31.756 63.8892C39.0666 63.8892 45.7999 61.4409 51.167 57.3298L44.447 48.7143C40.9378 51.4035 36.537 53.0073 31.756 53.0073Z"
    fill="white"
  />
</g>
</svg>`
