export const multiplyRemStringValue = (value: string, by: number): string => {
  const n = parseFloatFromRemString(value)
  return `${n * by}rem`
}

export const convertRemToPixels = (value: string): number => {
  if (value.slice(value.length - 3, value.length) !== 'rem') {
    throw Error(`Bad rem value: '${value}'`)
  }
  return (
    parseFloatFromRemString(value) *
    parseFloat(getComputedStyle(document.documentElement).fontSize)
  )
}

export const parseFloatFromRemString = (value: string): number => {
  if (value.slice(value.length - 3, value.length) !== 'rem') {
    throw Error(`Bad rem value: '${value}'`)
  }
  return parseFloat(value.slice(0, value.length - 3))
}
