import React from 'react'
import { Flex } from 'design-system/flex'
import { PageContainer } from '../components/PageContainer'
import { PageTitle } from '../components/PageTitle'
import { DataSearch } from './DataSearch'

export const DeveloperData = () => {
  return (
    <PageContainer>
      <PageTitle>Data</PageTitle>

      <Flex
        s={p => ({
          marginTop: p.theme.sizes.six,
          flex: 1,
          flexDirection: 'column',
        })}
      >
        <DataSearch />
      </Flex>
    </PageContainer>
  )
}
