import React from 'react'
import PropTypes from 'prop-types'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Flex } from 'design-system/flex'
import { PermissionRestricted } from 'components/permission-restricted/permission-restricted'
import { FIELD_TYPES_FLAT_LOWER } from 'utils/constants'
import { EditDetailButton } from 'components/edit-detail-btn/edit-detail-btn'
import dayjs from 'utils/dayjs'

export const SidebarField = ({ field = {}, values = [], onClickEditField }) => {
  const { display_name, field_type, boolean_display_config } = field

  const renderLabel = value => {
    if (value === undefined) {
      return 'There is no value for this field'
    }
    switch (field_type) {
      case FIELD_TYPES_FLAT_LOWER.string:
      case FIELD_TYPES_FLAT_LOWER.integer:
      case FIELD_TYPES_FLAT_LOWER.float: {
        return value
      }
      case FIELD_TYPES_FLAT_LOWER.date: {
        if (value === null) return '-'

        return dayjs(value).format('L')
      }

      case FIELD_TYPES_FLAT_LOWER.boolean: {
        let displayValue = value
        return boolean_display_config
          ? displayValue === true
            ? boolean_display_config.True.display
            : boolean_display_config.False.display
          : displayValue
      }

      default:
        return ''
    }
  }

  let renderValue = renderLabel(values[0])

  return (
    <Flex
      s={() => ({
        flexDirection: 'column',
      })}
    >
      <Flex
        s={() => ({
          justifyContent: 'space-between',
        })}
      >
        <Label
          type={LABEL_TYPE.BODY1}
          s={p => ({
            color: p.theme.colors.gray,
            fontWeight: 500,
          })}
        >
          {display_name}
        </Label>
        <PermissionRestricted level={2}>
          <EditDetailButton onClick={() => onClickEditField()} size="sm" />
        </PermissionRestricted>
      </Flex>

      <Label type={LABEL_TYPE.BODY1} s={p => ({ color: p.theme.colors.gray1 })}>
        {renderValue}
      </Label>
    </Flex>
  )
}

SidebarField.propTypes = {
  field: PropTypes.object,
  values: PropTypes.array,
  onClickEditField: PropTypes.func,
}
