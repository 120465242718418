import React, { useContext, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'design-system/flex'
import { Input, INPUT_SIZE } from 'design-system/input'
import UmbrellaIcon from 'assets/umbrella.svg'
import { useToggle } from 'hooks/useToggle'
import styled from 'styled-components'
import { useOnClickOutside } from 'hooks/useOutsideClick'
import { GlobalStateContext } from 'context/global-context-provider'
import { DropdownMenu } from 'components/dropdown/dropdown'
import { focusNextInput } from 'utils/document'
import { InsuranceDropdown } from './insurance-dropdown'
import { useInsuranceManualSearchQuery } from './useInsuranceManualSearchQuery'

const Container = styled.div`
  position: relative;

  > #dropdown-menu {
    top: 60px;
    width: 100%;
  }
`

const InsuranceDropdownContainer = styled(Flex)`
  position: absolute;
  top: 60px;
  height: 410px;
  width: 560px;
  border: 1px solid #f5f5f5;
  background-color: white;
  border-radius: 10px;
  transition: height 100ms ease-in;
  flex-direction: column;
  box-shadow: 0px 4px 4px rgba(211, 210, 210, 0.25);
  z-index: 999;
`

export const InsuranceSearchContainer = ({ small, onSelection }) => {
  const [value, setValue] = useState('')
  const [selectedCarrier, setSelectedCarrier] = useState()
  const [showDropdown, toggleDropdown] = useToggle(false)
  const [
    showChooseDifferentDropdown,
    toggleChooseDifferentDropdown,
  ] = useToggle(false)
  const ref = useRef()
  const [searchState] = useContext(GlobalStateContext)
  const { insurance } = searchState
  const isManualSearch = value.length > 0 && !insurance

  const { results } = useInsuranceManualSearchQuery(value, isManualSearch)

  useOnClickOutside(ref, () => {
    if (showDropdown) {
      toggleDropdown(false)
    }
    if (showChooseDifferentDropdown) {
      toggleChooseDifferentDropdown(false)
    }
    setValue('')
    setSelectedCarrier()
  })

  const onInputChange = e => {
    if (insurance || selectedCarrier) {
      return
    }
    const { value } = e.target
    setValue(value)
  }

  const onFocus = () => {
    // console.log('onFocus', e)
    if (insurance && !showDropdown && !showChooseDifferentDropdown) {
      toggleChooseDifferentDropdown(true)
    } else {
      if (!showDropdown) {
        toggleDropdown(true)
      }
    }
  }

  const onSelectOption = option => {
    console.log('onSelectOption', option)
    onSelection({ id: option.uuid, label: option.displayName })
    toggleDropdown(false)
  }

  const onClickChooseDifferent = () => {
    // clear global state
    onSelection(undefined)
    // clear local state
    setSelectedCarrier()
    setValue('')
    // toggle dropdowns
    toggleChooseDifferentDropdown(false)
    toggleDropdown(true)

    focusNextInput('insurances-input')
  }

  return (
    <Container ref={ref}>
      <Input
        id="insurances-input"
        size={INPUT_SIZE.EXTRA_LARGE}
        s={p => ({
          backgroundColor: 'white',
          borderRight: 0,
          ['input']: {
            paddingLeft: p.theme.sizes.two,
            paddingRight: p.theme.sizes.two,
          },
        })}
        placeholder="Select insurance plan"
        value={[
          { label: insurance && insurance.label ? insurance.label : value },
        ]}
        leftIcon={() => {
          if (small) {
            return null
          }
          return <UmbrellaIcon width="16px" height="16px" />
        }}
        onInputChange={onInputChange}
        onFocus={onFocus}
        showClearIcon={false}
      />

      {showDropdown && (
        <InsuranceDropdownContainer>
          <InsuranceDropdown
            onSelectOption={onSelectOption}
            isManualSearch={isManualSearch}
            searchResults={results}
            selectedCarrier={selectedCarrier}
            setSelectedCarrier={setSelectedCarrier}
          />
        </InsuranceDropdownContainer>
      )}

      {showChooseDifferentDropdown && (
        <DropdownMenu
          options={[
            {
              title: 'Choose a different insurance',
              onClick: onClickChooseDifferent,
            },
          ]}
        />
      )}
    </Container>
  )
}

InsuranceSearchContainer.propTypes = {
  small: PropTypes.bool,
  s: PropTypes.func,
  onSelection: PropTypes.func,
}
