import React, { useCallback } from 'react'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Label, LABEL_TYPE } from 'design-system/label'
import { AsyncSelectInput } from 'design-system/input/async-select-input'
import {
  EDUCATION_TYPE_OPTIONS,
  filterEducations,
  YEAR_OPTIONS,
} from 'utils/constants'
import { SelectInput } from 'design-system/input/select-input'
import { Flex } from 'design-system/flex'
import styled from 'styled-components'
import { Button } from 'design-system/button'
import PlusSVG from 'assets/plus.svg'
import MinusSVG from 'assets/minus.svg'

export const ProviderEducation = ({
  setEditProvider = () => {},
  editProvider = {},
}) => {
  const { educations = [] } = editProvider
  const handleChangeText = useCallback((id, { name, value, data }) => {
    setEditProvider(prev => {
      const educationsCopy = prev.educations.slice()

      const foundEducationIndex = educationsCopy.findIndex(
        i => i.education.uuid === id,
      )
      if (foundEducationIndex > -1) {
        if (name === 'name') {
          if (data) {
            educationsCopy[foundEducationIndex].education = { ...data }
          } else {
            // Created Option selected
            educationsCopy[foundEducationIndex].education = {
              uuid: `NEW-${value.replace(' ', '')}-${Math.floor(
                1000 + Math.random() * 9000,
              )}`,
              name: value,
            }
          }
        } else {
          educationsCopy[foundEducationIndex][name] = value
        }
        return {
          ...prev,
          educations: educationsCopy,
        }
      }
      return prev
    })
  }, [])

  const onClickAddEducation = useCallback(() => {
    setEditProvider(prev => {
      const educationsCopy = prev.educations.slice()
      const newEducation = {
        education: { name: '', uuid: `NEW-${educationsCopy.length}` },
        type: '',
        year: '',
      }
      educationsCopy.push(newEducation)
      return {
        ...prev,
        educations: educationsCopy,
      }
    })
  }, [setEditProvider])

  const onClickRemoveEducation = useCallback(item => {
    setEditProvider(prev => {
      const educationsCopy = prev.educations.slice()
      const foundEducationIndex = educationsCopy.findIndex(
        i => i.education.uuid === item.education.uuid && i.year === item.year,
      )
      if (foundEducationIndex > -1) {
        educationsCopy.splice(foundEducationIndex, 1)
        return {
          ...prev,
          educations: educationsCopy,
        }
      }
      return prev
    })
  }, [])

  /**
       *
       * Educations format example {
              "education": {
                  "name": "Stritch School of Medicine",
                  "uuid": "0b26c31a-d74a-4327-9702-57753b82a126"
              },
              "type": null,
              "year": 2007
          }
       */
  return (
    <Flex
      s={() => ({
        flexDirection: 'column',
      })}
    >
      <Heading
        type={HEADING_TYPE.H2}
        s={p => ({
          marginBottom: p.theme.sizes.four,
        })}
      >
        Education
      </Heading>
      {educations.map(item => {
        const { education } = item
        return (
          <EducationForm
            key={`${education.uuid}-${item.year}`}
            id={education.uuid}
            item={item}
            handleChangeText={handleChangeText}
            handleRemoveEducation={onClickRemoveEducation}
          />
        )
      })}
      <Button
        onClick={onClickAddEducation}
        s={p => ({
          color: p.theme.colors.aubergine,
          marginTop: p.theme.sizes.six,
          alignSelf: 'flex-start',
        })}
      >
        <PlusSVG height={16} width={16} />
        <Label
          type={LABEL_TYPE.BODY1}
          s={p => ({
            color: p.theme.colors.aubergine,
            marginLeft: p.theme.sizes.three,
          })}
        >
          Add education
        </Label>
      </Button>
    </Flex>
  )
}

const EducationForm = ({
  id = '',
  item = {},
  handleChangeText = () => {},
  handleRemoveEducation = () => {},
}) => {
  const onInputChange = useCallback(
    e => {
      const { name, value, data } = e.target
      handleChangeText(id, { name, value, data })
    },
    [id, handleChangeText],
  )

  const onClickRemoveEducation = useCallback(() => {
    handleRemoveEducation(item)
  }, [item, handleRemoveEducation])

  const loadOptions = useCallback(async (inputValue, callback) => {
    if (inputValue.length > 0) {
      try {
        const results = await filterEducations(inputValue)
        callback(results)
      } catch (error) {
        callback([])
      }
    }
    callback([])
  }, [])

  const { education, type, year } = item
  const { name } = education
  return (
    <>
      <FormRow>
        <InputContainer data-testid={`type-${id}`}>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Type</Label>
          <SelectInput
            onChange={onInputChange}
            name="type"
            value={type}
            options={EDUCATION_TYPE_OPTIONS}
            containerStyle={{ marginTop: '0.5rem' }}
            placeholder="Select"
          />
        </InputContainer>
        <InputContainer data-testid={`institution-${id}`}>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Institution Name*</Label>
          <AsyncSelectInput
            onChange={onInputChange}
            name="name"
            value={name}
            containerStyle={{ marginTop: '0.5rem' }}
            placeholder="Institution Name"
            loadOptions={loadOptions}
            creatable
            onCreateOption={onInputChange}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <InputContainer data-testid={`year-${id}`}>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Year Graduated</Label>
          <SelectInput
            onChange={onInputChange}
            name="year"
            value={year ? String(year) : ''}
            options={YEAR_OPTIONS}
            containerStyle={{ marginTop: '0.5rem' }}
            placeholder="YYYY"
          />
        </InputContainer>
        <InputContainer>
          <Flex
            s={() => ({
              flex: 1,
              flexDirection: 'column',
              alignSelf: 'flex-start',
              justifyContent: 'flex-end',
            })}
          >
            <Button
              onClick={onClickRemoveEducation}
              s={p => ({
                color: p.theme.colors.aubergine,
                alignSelf: 'flex-start',
                ['> button']: {
                  padding: 0,
                  height: '40px',
                  width: '40px',
                },
                borderRadius: '50%',
              })}
              data-testid="remove-education"
            >
              <MinusSVG height={16} width={16} />
            </Button>
          </Flex>
        </InputContainer>
      </FormRow>
    </>
  )
}

const FormRow = styled(Flex)`
  margin-top: ${p => p.theme.sizes.four};
  justify-content: space-between;
`
const InputContainer = styled(Flex)`
  flex-direction: column;
  flex: 0.48;
`
