import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Label } from 'design-system/label'
import { Flex } from 'design-system/flex'
import { Checkbox } from 'design-system/checkbox'
import { FilterDropdown } from 'components/dropdown/filter-dropdown'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { MenuLabel } from 'components/dropdown/dropdown'
import ArrowDown from 'assets/arrow-down.svg'
import { useToggle } from 'hooks/useToggle'
import { Chevron } from 'design-system/icon/icons/chervon-left'

const FilterDropdownContainer = styled(Flex)`
  position: relative;
  .filter-more-menu {
    right: 0;
    left: initial;
    padding: ${p => p.theme.sizes.three} 0;
  }
  .filter-label-container {
    margin-right: 0;
    ${p => ({
      ...(p.isActive && {
        borderColor: p.theme.colors.aubergine3,
        borderWidth: 1.5,
      }),
    })}
  }
`

const ActiveDot = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  background: ${p => p.theme.colors.aubergine3};
  height: 12px;
  width: 12px;
  border-radius: 50%;
`

export const FilterInsurancesDropdown = ({
  label = 'Filter Plans',
  onClickFilter,
  showMenu,
  closeMenu,
  filters,
  filterOptions,
  onClickSave,
}) => {
  const hasActiveFilters = Object.keys(filters).length > 0

  return (
    <FilterDropdownContainer isActive={hasActiveFilters}>
      <FilterDropdown
        label={label}
        onClickFilter={onClickFilter}
        showMenu={showMenu}
        closeMenu={closeMenu}
        renderMenu={
          <PlanFilterMenu
            filters={filters}
            filterOptions={filterOptions}
            onClickSave={onClickSave}
          />
        }
      />
      {hasActiveFilters && <ActiveDot />}
    </FilterDropdownContainer>
  )
}

FilterInsurancesDropdown.propTypes = {
  label: PropTypes.string,
  onClickFilter: PropTypes.func,
  showMenu: PropTypes.string, // label of dropdown
  closeMenu: PropTypes.func,
  filters: PropTypes.object,
  filterOptions: PropTypes.object,
  onClickSave: PropTypes.func,
}

const FilterMenuFooter = styled(Flex)`
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${p => p.theme.sizes.four};
  padding-top: ${p => p.theme.sizes.three};
  border-top: 1px solid ${p => p.theme.colors.borderDefault};
`

const CheckboxesContainer = styled(Flex)`
  flex-direction: column;
  overflow-y: scroll;
  max-height: 240px;
  padding-bottom: ${p => p.theme.sizes.four};
`

const FILTER_PLAN_TYPES = {
  HMO: 'HMO',
  PPO: 'PPO',
  EPO: 'EPO',
  OTHER: 'Other', // null plan type
}

const PlanFilterMenu = ({
  filterOptions = {},
  onClickSave = () => {},
  filters = {},
}) => {
  const [localFilters, setLocalFilters] = useState({ ...filters })
  const { HMOcount, PPOcount, EPOcount, OtherCount, options } = filterOptions

  const onClickCheck = (option, typeOption) => {
    console.log(`🚀 => onClickCheck:`, option, typeOption)

    // Handle specific type checked/unchecked
    if (typeOption) {
      return setLocalFilters(prev => {
        const prevCopy = { ...prev }
        if (!prevCopy[option]) {
          prevCopy[option] = { [typeOption]: true }
        } else {
          if (prevCopy[option][typeOption]) {
            delete prevCopy[option][typeOption]
            if (Object.keys(prevCopy[option]).length === 0) {
              delete prevCopy[option]
            }
          } else {
            prevCopy[option][typeOption] = true
          }
        }

        return prevCopy
      })
    }

    // One of the All Options checked
    if (
      option === 'all-hmo' ||
      option === 'all-ppo' ||
      option === 'all-epo' ||
      option === 'all-other'
    ) {
      let type
      let totalCountOfType = 0
      if (option === 'all-hmo') {
        type = FILTER_PLAN_TYPES.HMO
        totalCountOfType = HMOcount
      }
      if (option === 'all-ppo') {
        type = FILTER_PLAN_TYPES.PPO
        totalCountOfType = PPOcount
      }
      if (option === 'all-epo') {
        type = FILTER_PLAN_TYPES.EPO
        totalCountOfType = EPOcount
      }
      if (option === 'all-other') {
        type = FILTER_PLAN_TYPES.OTHER
        totalCountOfType = OtherCount
      }
      if (!type) return
      return setLocalFilters(prev => {
        const prevCopy = { ...prev }
        const allFiltersByType = Object.keys(prevCopy).filter(
          key => Boolean(prevCopy[key][type]) === true,
        )
        const hasAllOfType = allFiltersByType.length === totalCountOfType

        if (hasAllOfType) {
          allFiltersByType.forEach(key => {
            // Remove individual type filter
            if (prevCopy[key][type]) {
              delete prevCopy[key][type]
            }
            // check if filter key no longer has any other filters mapped to it - remove from filters if so
            if (Object.keys(prevCopy[key]).length === 0) {
              delete prevCopy[key]
            }
          })
        } else {
          options.forEach(filterOption => {
            // Filter option plan types should include this type as possible option.
            if (filterOption.planTypes && filterOption.planTypes[type]) {
              if (prevCopy[filterOption.uuid]) {
                prevCopy[filterOption.uuid][type] = true
              } else {
                prevCopy[filterOption.uuid] = {
                  [type]: true,
                }
              }
            }
          })
        }
        return prevCopy
      })
    }

    // State checked
    setLocalFilters(prev => {
      const prevCopy = { ...prev }
      const foundOption = options.find(o => o.uuid === option)
      if (foundOption && foundOption.planTypes) {
        if (
          prevCopy[option] &&
          Object.keys(prevCopy[option]).length ===
            Object.keys(foundOption.planTypes).length
        ) {
          delete prevCopy[option]
        } else {
          // Set all possible planType to true
          Object.keys(foundOption.planTypes).forEach(key => {
            prevCopy[option] = {
              ...(prevCopy[option] ? prevCopy[option] : {}),
              [key]: true,
            }
          })
        }
      }
      return prevCopy
    })
  }

  const onClickClear = () => {
    setLocalFilters({})
  }

  const allFiltersWithHMO = Object.keys(localFilters).filter(
    key => Boolean(localFilters[key][FILTER_PLAN_TYPES.HMO]) === true,
  )
  const allFiltersWithPPO = Object.keys(localFilters).filter(
    key => Boolean(localFilters[key][FILTER_PLAN_TYPES.PPO]) === true,
  )
  const allFiltersWithEPO = Object.keys(localFilters).filter(
    key => Boolean(localFilters[key][FILTER_PLAN_TYPES.EPO]) === true,
  )
  const allFiltersWithOther = Object.keys(localFilters).filter(
    key => Boolean(localFilters[key][FILTER_PLAN_TYPES.OTHER]) === true,
  )

  return (
    <>
      <CheckboxesContainer>
        {[
          ...(HMOcount > 0
            ? [{ uuid: 'all-hmo', displayName: 'All HMO' }]
            : []),
          ...(PPOcount > 0
            ? [{ uuid: 'all-ppo', displayName: 'All PPO' }]
            : []),
          ...(EPOcount > 0
            ? [{ uuid: 'all-epo', displayName: 'All EPO' }]
            : []),
          ...(OtherCount > 0
            ? [{ uuid: 'all-other', displayName: 'All Other' }]
            : []),
          ...options,
        ].map(option => {
          const { uuid, planTypes } = option
          let stateIsChecked = false

          if (uuid === 'all-hmo') {
            stateIsChecked = allFiltersWithHMO.length === HMOcount
          } else if (uuid === 'all-ppo') {
            stateIsChecked = allFiltersWithPPO.length === PPOcount
          } else if (uuid === 'all-epo') {
            stateIsChecked = allFiltersWithEPO.length === EPOcount
          } else if (uuid === 'all-other') {
            stateIsChecked = allFiltersWithOther.length === OtherCount
          } else {
            if (planTypes) {
              stateIsChecked =
                localFilters[uuid] &&
                Object.keys(localFilters[uuid]).length ===
                  Object.keys(planTypes).length
            }
          }

          return (
            <FilterByStateDropdown
              key={option.uuid}
              option={option}
              stateChecked={stateIsChecked}
              typesChecked={localFilters[uuid] || {}}
              isAllOption={uuid.includes('all')}
              onClick={onClickCheck}
            />
          )
        })}
      </CheckboxesContainer>
      <FilterMenuFooter>
        <Button type={BUTTON_TYPE.LINK} onClick={onClickClear}>
          Clear
        </Button>
        <Button
          type={BUTTON_TYPE.PRIMARY}
          onClick={() => onClickSave(localFilters)}
        >
          Save
        </Button>
      </FilterMenuFooter>
    </>
  )
}

const StateOptionContainer = styled(Flex)`
  flex: 1;
  flex-direction: column;

  ${p => ({
    ...(p.isAllOption
      ? {
          padding: `0 ${p.theme.sizes.four}`,
          marginBottom: p.theme.sizes.three,
          marginTop: p.theme.sizes.one,
        }
      : {
          borderTop: `1px solid ${p.theme.colors.borderDefault}`,
          padding: p.theme.sizes.four,
        }),
  })}

  .stateHeader {
    flex: 1;
    justify-content: space-between;
    > div {
      align-items: center;
    }
  }
  .typeOption {
    align-items: center;
    padding-left: ${p => p.theme.sizes.six};
    margin-top: ${p => p.theme.sizes.three};
  }
`

const FilterByStateDropdown = ({
  onClick,
  option,
  stateChecked,
  typesChecked = {},
  isAllOption = false,
}) => {
  const [isOpen, toggle] = useToggle(
    stateChecked || Object.keys(typesChecked).length > 0,
  )
  const { uuid, displayName, planTypes } = option

  const onClickStateCheck = () => {
    onClick(uuid)
  }

  const onClickTypeOption = typeOption => {
    onClick(uuid, typeOption)
  }

  return (
    <StateOptionContainer key={uuid} isAllOption={isAllOption}>
      <Flex className="stateHeader">
        <Flex>
          <Checkbox
            s={p => ({ marginRight: p.theme.sizes.two })}
            value={stateChecked}
            onClick={onClickStateCheck}
            data-testid={`${displayName}-checkbox`}
          />
          <Label
            s={() => ({
              ...(stateChecked && { fontWeight: '500' }),
            })}
          >
            {displayName}
          </Label>
        </Flex>
        {!isAllOption && (
          <Button
            s={() => ({
              ['> button']: {
                padding: 0,
                height: 20,
                width: 20,
              },
              borderRadius: 4,
              borderColor: 'transparent',
            })}
            onClick={toggle}
            data-testid={`${displayName}-dropdown`}
          >
            <Chevron direction={isOpen ? 'up' : 'down'} />
          </Button>
        )}
      </Flex>
      {!isAllOption && isOpen && (
        <>
          {/* Desired order of types */}
          {['HMO', 'PPO', 'EPO', 'Other']
            .filter(type => Boolean(planTypes[type]))
            .map(type => {
              const typeIsChecked = Boolean(typesChecked[type])

              return (
                <Flex className="typeOption" key={type}>
                  <Checkbox
                    s={p => ({ marginRight: p.theme.sizes.two })}
                    value={typeIsChecked}
                    onClick={() => onClickTypeOption(type)}
                    data-testid={`${displayName}-${type}-checkbox`}
                  />
                  <Label
                    s={() => ({
                      ...(typeIsChecked && { fontWeight: '500' }),
                    })}
                  >
                    {type}
                  </Label>
                </Flex>
              )
            })}
        </>
      )}
    </StateOptionContainer>
  )
}

FilterByStateDropdown.propTypes = {
  onClick: PropTypes.func,
  option: PropTypes.object,
  stateChecked: PropTypes.bool,
  typesChecked: PropTypes.object,
  isAllOption: PropTypes.bool,
}

const SelectByVerification = styled(Flex)`
  padding: ${p => p.theme.sizes.one} ${p => p.theme.sizes.three};
  align-items: center;
  border: 1px solid ${p => p.theme.colors.borderMid};
  border-radius: 100px;
  background-color: ${p => p.theme.colors.white};
`

export const SelectByVerificationDropdown = ({
  onClickOption = () => {},
  onClickCheck = () => {},
  options = [],
  mappedByVerification = {},
  selectedOption = null,
  selectedInsurances = {},
  allInsurances = [],
}) => {
  const [showDropdown, setShowDropdown] = useState()

  const onClickOptionCallback = option => {
    setShowDropdown()
    onClickOption(option)
  }

  const hasAllSelected =
    Object.keys(selectedInsurances).length === allInsurances.length
  const hasSomeSelected = Object.keys(selectedInsurances).length > 0
  return (
    <FilterDropdown
      label="selected-filter"
      labelComponent={
        <SelectByVerification>
          <Checkbox
            value={hasAllSelected}
            onClick={onClickCheck}
            s={p => ({
              marginRight: p.theme.sizes.two,
            })}
            indeterminate={!hasAllSelected && hasSomeSelected}
            data-testid={`select-by-verification-checkbox`}
          />

          <Button
            s={() => ({
              ['> button']: {
                padding: 0,
                height: 16,
                width: 16,
              },
              borderRadius: 4,
              borderColor: 'transparent',
            })}
            onClick={() =>
              setShowDropdown(showDropdown ? '' : 'selected-filter')
            }
            data-testid={`selected-filter-chevron`}
          >
            <ArrowDown />
          </Button>
        </SelectByVerification>
      }
      showMenu={showDropdown}
      closeMenu={() => setShowDropdown()}
      renderMenu={
        <SelectionOptionsMenu
          options={options}
          onClickOption={onClickOptionCallback}
          mappedByVerification={mappedByVerification}
          selectedOption={selectedOption}
        />
      }
    />
  )
}

const SelectionOptionsMenu = ({
  options = [],
  onClickOption = () => {},
  mappedByVerification = {},
  selectedOption = null,
}) => {
  return (
    <>
      {options.map(option => {
        const optionIsChecked = option === selectedOption
        const count = mappedByVerification[option]
          ? mappedByVerification[option].length
          : 0
        return (
          <MenuLabel
            key={option}
            onClick={count > 0 ? () => onClickOption(option) : null}
            selected={optionIsChecked}
            disabled={count === 0}
          >
            {`${option} (${count})`}
          </MenuLabel>
        )
      })}
    </>
  )
}
