import React, { useContext } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from '@reach/router'
import { ManageUsers } from 'containers/users/manage-users'
import { AuthStateContext } from 'context/auth-state-context'
import { services } from 'services'
import { NotyfContext } from 'context/notyf-context'

export const ManageInstanceUsers = () => {
  // Fetch Instance users by Instance ID
  const [authState] = useContext(AuthStateContext)
  const notyf = useContext(NotyfContext)
  const { instanceId } = useParams()

  const { data: usersData, isLoading, refetch: refetchUsers } = useQuery(
    `users-${instanceId}`,
    () =>
      services.ribbon.listUsers(authState.token, {
        application_uuid: instanceId,
      }),
    {
      enabled: !!(instanceId && authState && authState.token),
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  )

  const { mutateAsync: createUser } = useMutation(({ token, options }) =>
    services.ribbon.createUser(token, options),
  )

  const {
    mutateAsync: updateUser,
  } = useMutation(({ token, userId, options }) =>
    services.ribbon.updateUser(token, userId, options),
  )

  const { mutateAsync: deleteUser } = useMutation(({ token, userId }) =>
    services.ribbon.deleteUser(token, userId),
  )

  const { mutateAsync: resendInvite } = useMutation(({ token, userId }) =>
    services.ribbon.resendInvite(token, userId),
  )

  const handleAddUsers = async newUsers => {
    console.log(`🚀 => UserManagement => handleAddUsers:newUsers`, newUsers)

    const promises = newUsers.map(user =>
      createUser({
        token: authState.token,
        options: {
          application_uuid: instanceId,
          first_name: user.firstName,
          last_name: user.lastName,
          permission_level: user.permissionLevel,
          email: user.email,
          is_sso: user.isSso,
        },
      }),
    )
    await Promise.all(promises)
    // error handled on modal components where this function is called
    notyf.success(
      `${promises.length > 1 ? `${promises.length} Users` : 'User'} created!`,
    )
    refetchUsers()
  }

  const handleRemoveUsers = async users => {
    console.log(`🚀 => UserManagement => handleRemoveUsers:users`, users)

    try {
      const promises = users.map(user =>
        deleteUser({ token: authState.token, userId: user.id }),
      )
      await Promise.all(promises)
      notyf.success(
        `${promises.length > 1 ? `${promises.length} Users` : 'User'} deleted!`,
      )

      refetchUsers()
    } catch (error) {
      notyf.error(error.message)
    }
  }

  const handleUpdateUsers = async updateOptions => {
    console.log(
      `🚀 => UserManagement => handleUpdateUsers:updateOptions`,
      updateOptions,
    )

    try {
      const promises = updateOptions.map(option =>
        updateUser({
          token: authState.token,
          userId: option.userId,
          options: option.options,
        }),
      )
      await Promise.all(promises)
      notyf.success(
        `${promises.length > 1 ? `${promises.length} Users` : 'User'} updated!`,
      )

      refetchUsers()
    } catch (error) {
      notyf.error(error.message)
    }
  }

  const handleResendInvite = async user => {
    console.log('🚀 ~ onClickResetPasswords ~ user', user)
    try {
      await resendInvite({ token: authState.token, userId: user.id })
      notyf.success(`Invite resent to ${user.email}`)
    } catch (error) {
      notyf.error(error.message)
    }
  }

  return (
    <ManageUsers
      users={usersData ? usersData.data : []}
      loading={isLoading}
      handleAddUsers={handleAddUsers}
      handleUpdateUsers={handleUpdateUsers}
      handleRemoveUsers={handleRemoveUsers}
      handleResendInvite={handleResendInvite}
    />
  )
}
