import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { useParams, useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { useQuery } from 'react-query'
import { ManageInstanceUsers } from './users/manage-instance-users'
import { InstanceInfo } from './instance-info'
import { TabNavigation } from 'design-system/tab-navigation'
import { ManageInstanceModules } from './modules/manage-modules'
import { ManageInstanceFilterConfig } from './filter-config/manage-filters'
import { ManageInstanceCustomFields } from './custom-fields/manage-custom-fields'
import { ManageInstanceBranding } from './branding/manage-branding'
import { useToggle } from 'hooks/useToggle'
import { DeactivateInstanceModal } from './deactivate-instance/deactivate-instance-modal'
import { UnsavedChangesModal } from './unsaved-changes-modal'
import { HasChangesContext, HasChangesProvider } from './has-changes-context'
import { Flex } from 'design-system/flex'
import { AuthStateContext } from 'context/auth-state-context'
import { services } from 'services'
import { PageLoading } from 'containers/page-loading/page-loading'
import ReactTooltip from 'react-tooltip'
import { colors } from 'theme/colors'

const TABS = [
  {
    id: 'users',
    title: 'Users',
  },
  {
    id: 'modules',
    title: 'Modules',
  },
  {
    id: 'filter-configuration',
    title: 'Filter Configuration',
  },
  {
    id: 'custom-fields',
    title: 'Custom Fields',
  },
  {
    id: 'branding',
    title: 'Branding',
  },
]

export const ManageInstance = () => {
  const [authState] = useContext(AuthStateContext)
  const { instanceId } = useParams()
  const { state } = useLocation()
  const { instance: locationInstance } = state || {}

  const [
    showDeactivateInstanceModal,
    toggleShowDeactivateInstanceModal,
  ] = useToggle(false)

  const [instance, setInstance] = useState(locationInstance)
  const { isLoading } = useQuery(
    'applications',
    () =>
      services.ribbon.listApplications(authState.token, {
        has_fca_instance: true,
      }),
    {
      // enabled: !instance,
      retry: false,
      onSuccess: res => {
        const { data: allApplications } = res
        if (allApplications) {
          const foundInstance = allApplications.find(i => i.uuid === instanceId)
          if (foundInstance) {
            setInstance(foundInstance)
            return
          }
        }

        // instance not found - navigate to admin for all instances to load.
        navigate('/admin')
      },
      onError: error => {
        console.log(`listApplications: error`, error)
        navigate('/admin')
      },
    },
  )

  // No instance - fetch for instance by id from URL
  console.log('instance :>> ', instance)
  console.log('instanceId :>> ', instanceId)

  const onDeactivateSuccess = () => {
    toggleShowDeactivateInstanceModal(false)
    navigate(`/admin`)
  }

  if (isLoading) {
    return <PageLoading />
  }

  return (
    <Flex
      s={p => ({
        backgroundColor: p.theme.colors.gray7,
        flex: 1,
        height: '100%',
      })}
    >
      {/* Instance Info Panel */}
      <InstanceInfo
        instance={instance}
        onClickDeactivateInstance={() =>
          toggleShowDeactivateInstanceModal(true)
        }
      />
      <Flex
        s={() => ({
          flexDirection: 'column',
          flex: 1,
        })}
      >
        {/* TABS */}
        <HasChangesProvider>
          <TabsContainer instance={instance} />
        </HasChangesProvider>
        {showDeactivateInstanceModal && (
          <DeactivateInstanceModal
            instance={instance}
            handleClose={() => toggleShowDeactivateInstanceModal(false)}
            handleSuccess={() => onDeactivateSuccess()}
          />
        )}
      </Flex>

      <ReactTooltip
        place="bottom"
        effect="solid"
        backgroundColor={colors.gray1}
      />
    </Flex>
  )
}

const TabsContainer = ({ instance }) => {
  const { tab } = useParams()
  const foundTabFromLocation = TABS.find(t => t.id === tab)
  const [activeTab, setActiveTab] = useState(
    foundTabFromLocation ? foundTabFromLocation.id : TABS[0].id,
  )
  const [showUnsavedChangesModal, toggleShowUnsavedChangesModal] = useToggle(
    false,
  )
  const tabSelected = useRef()
  const [hasChanges, setHasChanges] = useContext(HasChangesContext)

  // Always redirect to a valid default Tab
  useEffect(() => {
    if (instance && !foundTabFromLocation) {
      navigate(`/admin/instances/${instance.uuid}/${TABS[0].id}`, {
        state: {
          instance,
        },
      })
    }
  }, [foundTabFromLocation, instance])

  const onClickTab = selectedTab => {
    if (hasChanges) {
      tabSelected.current = selectedTab
      toggleShowUnsavedChangesModal(true)
      return
    }
    if (activeTab !== selectedTab.id) {
      setActiveTab(selectedTab.id)
      navigate(`/admin/instances/${instance.uuid}/${selectedTab.id}`, {
        state: {
          instance,
        },
      })
    }
  }

  const renderTab = useCallback(() => {
    switch (activeTab) {
      case TABS[0].id:
        return <ManageInstanceUsers />
      case TABS[1].id:
        return <ManageInstanceModules instance={instance} />
      case TABS[2].id:
        return <ManageInstanceFilterConfig instance={instance} />
      case TABS[3].id:
        return <ManageInstanceCustomFields instance={instance} />
      case TABS[4].id:
        return <ManageInstanceBranding />

      default:
        return null
    }
  }, [activeTab, instance])

  const onClickLeavePage = () => {
    toggleShowUnsavedChangesModal(false)
    setHasChanges(false)
    if (tabSelected.current) {
      setActiveTab(tabSelected.current.id)
      navigate(`/admin/instances/${instance.uuid}/${tabSelected.current.id}`, {
        state: {
          instance,
        },
      })
    }
  }

  return (
    <>
      <TabNavigation
        tabs={TABS}
        activeTab={activeTab}
        onClickTab={onClickTab}
      />
      {/* Selected Tab */}
      {renderTab()}
      {showUnsavedChangesModal && (
        <UnsavedChangesModal
          handleClose={() => toggleShowUnsavedChangesModal(false)}
          handleContinue={() => onClickLeavePage()}
        />
      )}
    </>
  )
}

TabsContainer.propTypes = {
  instance: PropTypes.object,
}
