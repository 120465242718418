import React, { useContext, useEffect, useReducer } from 'react'
import queryString from 'query-string'
import { useLocation } from '@reach/router'
import styled from 'styled-components'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Input, INPUT_SIZE, INPUT_TYPE } from 'design-system/input'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Spinner } from 'design-system/spinner/spinner.component'
import { AuthStateContext, AUTH_ACTION_TYPES } from 'context/auth-state-context'
import { services } from 'services'
import { AuthLayoutAside } from 'components/auth-layout-aside/auth-layout-aside'
import { useToggle } from 'hooks/useToggle'
import ShowSVG from 'assets/show.svg'
import { Flex } from 'design-system/flex'
import { PasswordValidations } from 'components/password-validations/password-validations'
import { validPassword } from 'utils/password.util'

const Container = styled(Flex)`
  flex: 1;
  padding: ${p => p.theme.sizes.eight};
  overflow-y: auto;

  @media (max-width: ${p => p.theme.flexboxgrid.breakpoints.sm}em) {
    padding: ${p => p.theme.sizes.five};
    > div {
      justify-content: center;
    }
  }
`

const initialState = {
  application_name: '',
  email: '',
  password: '',
  loading: true,
  formLoading: false,
  invalidInvite: false,
  errors: {},
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...action.payload,
      }
    }
    case 'error': {
      return {
        ...state,
        formLoading: false,
        errors: {
          ...action.payload,
        },
      }
    }

    default:
      return state
  }
}

export const AcceptInvitation = () => {
  const [, authDispatch] = useContext(AuthStateContext)
  const location = useLocation()
  const [state, dispatch] = useReducer(reducer, initialState)
  const parsedLocation = queryString.parse(location.search)
  const { token, uid } = parsedLocation
  const [showPassword, togglePassword] = useToggle(false)

  useEffect(() => {
    const verifyToken = async (token, uid) => {
      try {
        const result = await services.ribbon.verifyToken({ uid, token })

        dispatch({
          type: 'update',
          payload: {
            loading: false,
            email: result.email,
            // application_name: result.application_name,
          },
        })
      } catch (error) {
        if (error.message) {
          dispatch({
            type: 'update',
            payload: {
              loading: false,
              invalidInvite: true,
            },
          })
        }
      }
    }
    if (token && uid) {
      verifyToken(token, uid)
    } else {
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          invalidInvite: true,
        },
      })
    }
  }, [token, uid])

  const onChange = e => {
    const { name, value } = e.target

    dispatch({
      type: 'update',
      payload: {
        [name]: value,
        ...(state.errors[name] && {
          errors: {
            [name]: undefined,
          },
        }),
      },
    })
  }

  const onClickCreateAccount = async () => {
    console.log(`🚀 => onClickCreateAccount => state`, state)

    const { password } = state
    dispatch({
      type: 'update',
      payload: {
        formLoading: true,
        errors: {},
      },
    })

    try {
      const result = await services.ribbon.createAccount({
        uid,
        token,
        password,
      })
      dispatch({
        type: 'update',
        payload: {
          formLoading: false,
        },
      })
      // createAccount should return all required fields for user to login
      // -> should navigate to search container
      if (result.modules && result.token) {
        authDispatch({ type: AUTH_ACTION_TYPES.LOGGED_IN, payload: result })
        return
      }
      throw Error('User missing required data')
    } catch (error) {
      const { message, status } = error.response || {}
      dispatch({
        type: 'error',
        payload: {
          createAccount: {
            message: message || error.message,
            status,
          },
        },
      })
    }
  }

  const {
    // application_name,
    email,
    password,
    errors,
    loading,
    invalidInvite,
    formLoading,
  } = state

  const passwordValidations = validPassword(password, true)

  return (
    <Container>
      <Flex
        s={() => ({
          flex: 1,
        })}
      >
        <Flex
          s={p => ({
            flexDirection: 'column',
            padding: p.theme.sizes.eight,
            border: `1px solid ${p.theme.colors.gray1}`,
            borderRadius: '16px',
            maxWidth: '480px',
            width: '100%',
          })}
        >
          {loading ? (
            <Flex
              s={() => ({
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              })}
            >
              <Spinner />
            </Flex>
          ) : (
            <>
              {invalidInvite ? (
                <Flex
                  s={() => ({
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'center',
                  })}
                >
                  <Label
                    s={p => ({
                      fontSize: p.theme.sizes.six,
                      fontWeight: '800',
                      marginBottom: p.theme.sizes.four,
                    })}
                  >
                    This invitation has expired.
                  </Label>
                  <Label
                    s={p => ({
                      fontSize: p.theme.sizes.four,
                      marginBottom: p.theme.sizes.ten,
                    })}
                  >
                    This can happen if the invite is malformed, expired or
                    already been used. To request a new invite, please contact
                    Ribbon support.
                  </Label>
                  <Button
                    type={BUTTON_TYPE.PRIMARY}
                    size={BUTTON_SIZE.EXTRA_LARGE}
                  >
                    <a
                      style={{ textDecoration: 'none', color: 'white' }}
                      href="mailto:support@ribbonhealth.com"
                    >
                      Contact Support
                    </a>
                  </Button>
                </Flex>
              ) : (
                <>
                  <Label
                    s={p => ({
                      fontSize: p.theme.sizes.six,
                      fontWeight: '800',
                      textAlign: 'center',
                    })}
                  >
                    {`Complete your account creation`}
                  </Label>
                  <Input
                    s={p => ({ marginTop: p.theme.sizes.seven })}
                    name="email"
                    value={[{ label: email }]}
                    showClearIcon={false}
                    type={INPUT_TYPE.ROUND}
                    size={INPUT_SIZE.EXTRA_LARGE}
                    placeholder="Email"
                    disabled
                  />
                  <Input
                    s={p => ({ marginTop: p.theme.sizes.four })}
                    name="password"
                    onInputChange={onChange}
                    value={[{ label: password }]}
                    showClearIcon={false}
                    type={INPUT_TYPE.ROUND}
                    size={INPUT_SIZE.EXTRA_LARGE}
                    placeholder="Password"
                    inputType={showPassword ? 'text' : 'password'}
                    rightIcon={() => (
                      <EyeIcon
                        togglePassword={togglePassword}
                        showPassword={showPassword}
                      />
                    )}
                    onEnter={() => {
                      if (!formLoading && passwordValidations.length === 0) {
                        onClickCreateAccount()
                      }
                    }}
                  />
                  <PasswordValidations
                    passwordValidations={passwordValidations}
                  />

                  {errors.createAccount && (
                    <Label
                      type={LABEL_TYPE.ERROR}
                      s={p => ({
                        textAlign: 'center',
                        alignSelf: 'center',
                        marginTop: p.theme.sizes.four,
                      })}
                    >
                      There was an error authenticating, please reach out to{' '}
                      <a href="mailto:support@ribbonhealth.com">
                        support@ribbonhealth.com
                      </a>
                    </Label>
                  )}

                  <Button
                    s={p => ({
                      marginTop: p.theme.sizes.four,
                    })}
                    type={
                      passwordValidations.length > 0
                        ? BUTTON_TYPE.DISABLED
                        : BUTTON_TYPE.PRIMARY
                    }
                    onClick={onClickCreateAccount}
                    size={BUTTON_SIZE.EXTRA_LARGE}
                    loading={formLoading}
                    disabled={passwordValidations.length > 0}
                  >
                    Create Account
                  </Button>
                </>
              )}
            </>
          )}
        </Flex>
      </Flex>
      <AuthLayoutAside />
    </Container>
  )
}

const ShowButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  opacity: ${p => (p.showPassword ? '0.33' : '1')};
  cursor: pointer;
`

const EyeIcon = ({ togglePassword = () => {}, showPassword = false }) => {
  return (
    <ShowButton onClick={togglePassword} showPassword={showPassword}>
      <ShowSVG height="16px" width="16px" />
    </ShowButton>
  )
}
