/* eslint-disable react/prop-types */
import { Block } from 'design-system/block'
import { Chevron } from 'design-system/icon/icons/chervon-left'
import { Label } from 'design-system/label'
import React from 'react'
import styled from 'styled-components'

const PaginationContainer = styled.div`
  margin-top: ${p => p.theme.sizes.six};
  align-self: center;
`
const PaginationRow = styled.div`
  display: flex;
  align-items: center;
`
const PageButton = styled.button`
  cursor: pointer;
  padding: 0;
  margin: 0 0.5em;
  background-color: ${p => p.theme.colors.clear};
  border: none;
`
const PageNumberBlock = styled(Block)`
  padding: 0;
  margin: 0 0.5em;
  cursor: pointer;
  ${p => ({
    ...(p.active && {
      borderBottom: `1px solid ${p.theme.colors.aubergine2}`,
    }),
  })}
  p {
    line-height: ${p => p.theme.sizes.five};
    ${p => ({
      ...(p.active && {
        fontWeight: 'bold',
      }),
    })}
  }
`

const getPageNumbers = (statePageIndex, pageCount) => {
  const allPageNumbers = Array.from(Array(pageCount).keys())
  const pageNumbers = [statePageIndex]
  // Add Previous Page & Next Page if they exist
  if (allPageNumbers.indexOf(statePageIndex - 1) > -1) {
    pageNumbers.unshift(statePageIndex - 1)
  }
  if (allPageNumbers.indexOf(statePageIndex + 1) > -1) {
    pageNumbers.push(statePageIndex + 1)
  }
  // If length is only 2 go back or up 2 pages
  if (pageNumbers.length === 2) {
    if (allPageNumbers.indexOf(statePageIndex - 2) > -1) {
      pageNumbers.unshift(statePageIndex - 2)
    }
    if (allPageNumbers.indexOf(statePageIndex + 2) > -1) {
      pageNumbers.push(statePageIndex + 2)
    }
  }

  return pageNumbers
}
export const Pagination = ({
  gotoPage,
  canPreviousPage,
  canNextPage,
  nextPage,
  previousPage,
  statePageIndex,
  pageCount,
}) => {
  // Logic for displaying page numbers
  const pageNumbers = getPageNumbers(statePageIndex, pageCount)

  return (
    <PaginationContainer>
      <PaginationRow>
        <PageButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          <Chevron direction="left" height={16} width={16} />
        </PageButton>
        {pageNumbers.map(pg => {
          return (
            <PageNumberBlock
              key={pg}
              onClick={() => gotoPage(pg)}
              active={statePageIndex === pg}
            >
              <Label>{pg + 1}</Label>
            </PageNumberBlock>
          )
        })}
        <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
          <Chevron direction="right" height={16} width={16} />
        </PageButton>
      </PaginationRow>
    </PaginationContainer>
  )
}
