import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import { Button, BUTTON_TYPE, BUTTON_SIZE } from 'design-system/button'
import { Flex } from 'design-system/flex'
import RibbonCare from 'assets/ribbon-care.svg'
import { Label } from 'design-system/label'

export const SsoLogin = ({ssoSubdomain}) => {
  const onLoginClick = useCallback(() => {
    // Because the GATSBY_RIBBON_ENDPOINT_BASE includes '/api/v1', we need to parse out the protocol and hostname to
    // remove that extra part of the path before constructing the SSO URL
    const { protocol, host } = new URL(process.env.GATSBY_RIBBON_ENDPOINT_BASE)
    navigate(`${protocol}//${host}/${ssoSubdomain}/login`)
  }, [ssoSubdomain])

  return (
    <>
      <Flex
        s={p => ({
          flexDirection: 'column',
          padding: p.theme.sizes.eight,
          border: `1px solid ${p.theme.colors.gray1}`,
          borderRadius: p.theme.sizes.four,
          maxWidth: '440px',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        })}
      >
        <Label
          s={p => ({
            fontSize: p.theme.sizes.six,
            lineHeight: p.theme.sizes.seven,
            fontWeight: '700',
            textAlign: 'center',
            marginBottom: p.theme.sizes.five,
            maxWidth: '530px',
          })}
        >
          Find the right care with Ribbon
        </Label>
        <RibbonCare
          style={{ width: 200, height: 200 }}
        />
        <Button
          s={p => ({ width: '100%', textAlign: 'center', marginTop: p.theme.sizes.eight })}
          type={BUTTON_TYPE.PRIMARY}
          size={BUTTON_SIZE.EXTRA_LARGE}
          onClick={onLoginClick}
        >
          Login with SSO
        </Button>
      </Flex>
    </>
  )
}

SsoLogin.propTypes = {
  ssoSubdomain: PropTypes.string,
}
