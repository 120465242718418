import {
  CustomFilter,
  CustomFilterValueType,
  Filters,
} from 'services/ribbon/ribbon.types'

type FilterSections = {
  sidebar: CustomFilter[]
  more: CustomFilter[]
}

type FiltersWithSections = {
  providers: FilterSections
  facilities: FilterSections
}

export const splitFiltersIntoSections = (
  filters?: Filters,
): FiltersWithSections => {
  const providers: FilterSections = {
    sidebar: [],
    more: [],
  }
  const facilities: FilterSections = {
    sidebar: [],
    more: [],
  }
  if (!filters) {
    return {
      providers,
      facilities,
    }
  }
  if (filters.providers) {
    filters.providers.forEach(filter => {
      if (filter.is_more) {
        providers.more.push(filter)
      } else {
        providers.sidebar.push(filter)
      }
    })
  }
  if (filters.facilities) {
    filters.facilities.forEach(filter => {
      if (filter.is_more) {
        facilities.more.push(filter)
      } else {
        facilities.sidebar.push(filter)
      }
    })
  }

  return {
    providers,
    facilities,
  }
}

export const displaySummaryLabelByFilterType = (filter: {
  display: string
  value: string | number
  field_type: CustomFilterValueType
}): string | number => {
  if (!filter || !filter.field_type) return ''
  switch (filter.field_type.toLowerCase()) {
    case 'boolean':
      return filter.display
    case 'string':
      return filter.value
    case 'integer':
      return filter.display
    case 'float':
      return filter.display
    case 'list':
      return filter.value

    default:
      return ''
  }
}

export const filterFieldTypes: Array<{ label: string; value: string }> = [
  {
    label: 'Boolean',
    value: 'Boolean',
  },
  {
    label: 'String',
    value: 'String',
  },
  {
    label: 'Float',
    value: 'Float',
  },
  {
    label: 'Integer',
    value: 'Integer',
  },
  {
    label: 'List',
    value: 'List',
  },
]

export const filterTypes = {
  equals: {
    label: 'Equals',
    value: 'equals',
  },
  contains: {
    label: 'Contains',
    value: 'contains',
  },
  less_than: {
    label: 'Less than',
    value: 'less_than',
  },
  greater_than: {
    label: 'Greater than',
    value: 'greater_than',
  },
}

export const filterTypesByFieldSelected = (
  fieldType: CustomFilterValueType,
): Array<{ label: string; value: string }> => {
  if (!fieldType) {
    return []
  }
  switch (fieldType.toLowerCase()) {
    case 'boolean':
      return [filterTypes.equals]
    case 'string':
      return [filterTypes.equals, filterTypes.contains]
    case 'integer':
      return [filterTypes.less_than, filterTypes.greater_than]
    case 'float':
      return [filterTypes.less_than, filterTypes.greater_than]
    case 'list':
      return [filterTypes.contains]

    default:
      return []
  }
}

export const getFilterLabelByFieldSelected = (
  fieldType: CustomFilterValueType,
): string => {
  if (!fieldType) {
    return 'Displayed as'
  }
  switch (fieldType.toLowerCase()) {
    case 'boolean':
      // eslint-disable-next-line quotes
      return `Displayed as a checkbox, passes 'true' when checked.`
    case 'string':
      return 'Displayed as single-select radio options. Only one option can be passed at once.'
    case 'integer':
      return 'Displayed as single-select radio options. Only one option can be passed at once.'
    case 'float':
      return 'Displayed as single-select radio options. Only one option can be passed at once.'
    case 'list':
      return 'Displayed as a checkbox, each option selected is passed comma separated'

    default:
      return 'Displayed as'
  }
}

export const filterDisplayTypeByFieldSelected = (
  fieldType: CustomFilterValueType,
): string => {
  if (!fieldType) return ''
  switch (fieldType.toLowerCase()) {
    case 'boolean':
      return 'single'
    case 'string':
      return 'single-select'
    case 'integer':
      return 'single-select'
    case 'float':
      return 'single-select'
    case 'list':
      return 'multi-select'

    default:
      return ''
  }
}
