import React from 'react'
import { Flex } from 'design-system/flex'
import styled from 'styled-components'
import CheckCircleSVG from 'assets/check-circle.svg'
import RemoveCircleSVG from 'assets/remove-circle.svg'

const ValidationMessage = styled.p`
  display: flex;
  align-items: center;
  margin-top: ${p => p.theme.sizes.two};
  color: ${p => (p.invalid ? p.theme.colors.aubergine : p.theme.colors.mint)};
  > svg {
    margin-right: 8px;
  }
`
const ValidationContainer = styled(Flex)`
  margin-top: ${p => p.theme.sizes.two};
  flex-direction: column;
  padding: 0 ${p => p.theme.sizes.four};
`
export const PasswordValidations = ({ passwordValidations = [] }) => {
  return (
    <ValidationContainer>
      <ValidationMessage invalid={passwordValidations.includes('min')}>
        {passwordValidations.includes('min') ? (
          <RemoveCircleSVG />
        ) : (
          <CheckCircleSVG />
        )}
        Must be at least 8 characters
      </ValidationMessage>
      <ValidationMessage invalid={passwordValidations.includes('lowercase')}>
        {passwordValidations.includes('lowercase') ? (
          <RemoveCircleSVG />
        ) : (
          <CheckCircleSVG />
        )}
        Must contain at least 1 lowercase letter
      </ValidationMessage>
      <ValidationMessage invalid={passwordValidations.includes('uppercase')}>
        {passwordValidations.includes('uppercase') ? (
          <RemoveCircleSVG />
        ) : (
          <CheckCircleSVG />
        )}
        Must contain at least 1 uppercase letter
      </ValidationMessage>
      <ValidationMessage invalid={passwordValidations.includes('digits')}>
        {passwordValidations.includes('digits') ? (
          <RemoveCircleSVG />
        ) : (
          <CheckCircleSVG />
        )}
        Must contain at least 1 number
      </ValidationMessage>
      <ValidationMessage invalid={passwordValidations.includes('symbols')}>
        {passwordValidations.includes('symbols') ? (
          <RemoveCircleSVG />
        ) : (
          <CheckCircleSVG />
        )}
        Must contain at least 1 symbol
      </ValidationMessage>
      <ValidationMessage invalid={passwordValidations.includes('spaces')}>
        {passwordValidations.includes('spaces') ? (
          <RemoveCircleSVG />
        ) : (
          <CheckCircleSVG />
        )}
        Cannot contain spaces
      </ValidationMessage>
    </ValidationContainer>
  )
}
