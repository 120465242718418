import React from 'react'
import PropTypes from 'prop-types'
import { Block } from 'design-system/block'
import { Label } from 'design-system/label'
import { Icon } from 'design-system/icon'
import styled from 'styled-components'

const MAX_CONTIGUOUS_RANGE = 4

const LeftChevron = ({ disabled }) => (
  <Icon>
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.63822 7.99812L9.07343 0.562903C9.20017 0.429465 9.19686 0.219318 9.06677 0.0925727C8.93668 -0.0308576 8.73319 -0.0308576 8.6031 0.0925727L0.931064 7.76461C0.800973 7.8947 0.800973 8.10485 0.931064 8.23494L8.6031 15.907C8.73654 16.0338 8.94668 16.0304 9.07343 15.9003C9.19686 15.7703 9.19686 15.5668 9.07343 15.4367L1.63822 7.99812Z"
        fill={disabled ? '#A7A5A6' : '#231F20'}
      />
    </svg>
  </Icon>
)

LeftChevron.propTypes = {
  disabled: PropTypes.bool,
}

LeftChevron.defaultProps = {
  disabled: false,
}

const RightChevron = ({ disabled }) => (
  <Icon>
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.07114 7.76037L1.40372 0.0929634C1.27036 -0.0337053 1.06034 -0.0303925 0.933671 0.0996203C0.810314 0.229633 0.810314 0.432997 0.933671 0.56301L8.36441 7.99374L0.933671 15.4278C0.800313 15.5545 0.797001 15.7679 0.927014 15.8979C1.05368 16.0312 1.26705 16.0345 1.39706 15.9045C1.4004 15.9012 1.40372 15.8979 1.40372 15.8979L9.07114 8.23045C9.20115 8.10041 9.20115 7.89039 9.07114 7.76037Z"
        fill={disabled ? '#A7A5A6' : '#231F20'}
      />
    </svg>
  </Icon>
)

RightChevron.propTypes = {
  disabled: PropTypes.bool,
}

RightChevron.defaultProps = {
  disabled: false,
}

const PageNumberBlock = styled(Block)`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
`

export const Pagination = ({ currentPage, setPage, totalCount, pageSize }) => {
  // Results have a max cap of returning 5000 results / default pageSize is 25.
  // Although the totalCount could be higher.
  let totalPages = Math.ceil(totalCount / pageSize)
  if (totalCount > 5000) {
    totalPages = Math.ceil(5000 / pageSize)
  }
  const leftButtonCount =
    currentPage < MAX_CONTIGUOUS_RANGE
      ? currentPage - 1
      : MAX_CONTIGUOUS_RANGE - 1
  const rightButtonCount =
    currentPage > totalPages - MAX_CONTIGUOUS_RANGE
      ? totalPages - currentPage
      : MAX_CONTIGUOUS_RANGE - 1
  const goesBeyondLeftCount = currentPage > MAX_CONTIGUOUS_RANGE
  const goesBeyondRightCount = currentPage < totalPages - MAX_CONTIGUOUS_RANGE
  return (
    <Block
      s={p => ({
        display: 'flex',
        color: p.theme.colors.gray1,
        flexWrap: 'wrap',
      })}
    >
      <Block
        s={() => ({
          display: 'flex',
          alignItems: 'center',
          cursor: (currentPage > 1 && 'pointer') || undefined,
        })}
        onClick={() => (currentPage > 1 ? setPage(currentPage - 1) : undefined)}
      >
        <LeftChevron disabled={currentPage === 1} />
      </Block>
      {goesBeyondLeftCount && (
        <PageNumberBlock>
          <Label onClick={() => setPage(1)}>1</Label>
        </PageNumberBlock>
      )}
      {goesBeyondLeftCount && (
        <PageNumberBlock>
          <Label>...</Label>
        </PageNumberBlock>
      )}
      {new Array(leftButtonCount)
        .fill(1)
        .map((_, i) => i)
        .reverse()
        .map(i => (
          <PageNumberBlock key={`prev-${i}`}>
            <Label onClick={() => setPage(currentPage - i - 1)}>
              {currentPage - i - 1}
            </Label>
          </PageNumberBlock>
        ))}
      <PageNumberBlock>
        <Block
          s={p => ({
            padding: 0,
            marginBottom: '-1px',
            borderBottom: `1px solid ${p.theme.colors.aubergine2}`,
            color: p.theme.colors.gray0,
          })}
        >
          <Label>{currentPage}</Label>
        </Block>
      </PageNumberBlock>
      {rightButtonCount > 0 &&
        new Array(rightButtonCount).fill(1).map((_, i) => (
          <PageNumberBlock key={`next-${i}`}>
            <Label onClick={() => setPage(i + currentPage + 1)}>
              {i + currentPage + 1}
            </Label>
          </PageNumberBlock>
        ))}
      {goesBeyondRightCount && (
        <PageNumberBlock>
          <Label>...</Label>
        </PageNumberBlock>
      )}
      {goesBeyondRightCount && (
        <PageNumberBlock>
          <Label onClick={() => setPage(totalPages)}>{totalPages}</Label>
        </PageNumberBlock>
      )}
      <PageNumberBlock
        s={() => ({
          display: 'flex',
          cursor: (currentPage < totalPages && 'pointer') || undefined,
          alignItems: 'center',
        })}
        onClick={() =>
          currentPage < totalPages ? setPage(currentPage + 1) : undefined
        }
      >
        <RightChevron disabled={currentPage === totalPages} />
      </PageNumberBlock>
    </Block>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
}
