import React, { useCallback, useRef } from 'react'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Input, INPUT_TYPE } from 'design-system/input'
import { Block } from 'design-system/block'
import { Flex } from 'design-system/flex'
import { Label, LABEL_TYPE } from 'design-system/label'
import { services } from 'services'
import {
  formatAddress,
  getAddressFromPlaceResult,
} from 'utils/formatters/format-addresses'
import { AsyncSelectInput } from 'design-system/input/async-select-input'
import { findPlaceFromPlaceId } from 'services/google-maps/geocode.service'
import styled from 'theme'

export const LocationDetails = ({
  editLocation = {},
  setEditLocation = () => {},
}) => {
  const { addressDetails = {}, name } = editLocation
  const { addressLine1, addressLine2, city, state, zip } = addressDetails

  const sessionToken = useRef(null)

  const loadOptions = useCallback(async (inputValue, callback) => {
    if (inputValue.length > 0) {
      try {
        if (!sessionToken.current) {
          sessionToken.current = new window.google.maps.places.AutocompleteSessionToken()
        }

        const results = await services.gmaps.geocode({
          searchString: inputValue,
          sessionToken: sessionToken.current,
          types: ['address'],
        })
        console.log('searchLocations Results', results)
        if (results) {
          const filteredResults = results
            ? results.map(result => ({
                id: result.place_id,
                label: result.description,
                value: result.description,
                data: {
                  place_id: result.place_id,
                },
              }))
            : []

          return callback(filteredResults)
        }
      } catch (error) {
        console.log('Search Error: ', error)
        return callback([])
      }
    }
    return callback([])
  }, [])

  const onChangeText = useCallback(e => {
    const { value, name } = e.target

    setEditLocation(prev => {
      const addressDetailsCopy = { ...prev.addressDetails }
      if (name === 'name') {
        return {
          ...prev,
          name: value,
        }
      }

      addressDetailsCopy[name] = value
      const formattedAddress = formatAddress(
        addressDetailsCopy.addressLine1,
        addressDetailsCopy.addressLine2,
        addressDetailsCopy.city,
        addressDetailsCopy.state,
        addressDetailsCopy.zip,
      )
      return {
        ...prev,
        address: formattedAddress,
        addressDetails: addressDetailsCopy,
      }
    })
  }, [])

  const onChange = useCallback(async e => {
    console.log('Selection', e)
    const { data } = e.target
    let addressFromPlaceResult = {}
    if (data.place_id) {
      const result = await findPlaceFromPlaceId(
        data.place_id,
        sessionToken.current,
      )
      if (result) {
        addressFromPlaceResult = getAddressFromPlaceResult(result)
      }
    }

    console.log('addressFromPlaceResult', addressFromPlaceResult)
    const { address, city, state, zipcode } = addressFromPlaceResult

    setEditLocation(prev => {
      const { addressDetails } = prev
      const addressDetailsCopy = { ...addressDetails }
      const unit = addressDetailsCopy.addressLine2
      const formattedAddress = formatAddress(
        address,
        unit,
        city,
        state,
        zipcode,
      )
      const newAddressDetails = {
        addressLine1: address,
        addressLine2: unit,
        city: city,
        state: state,
        street: `${address}${unit ? ` ${unit}` : ''}`,
        zip: zipcode,
      }
      return {
        ...prev,
        address: formattedAddress,
        addressDetails: newAddressDetails,
        latitude: data.latitude,
        longitude: data.longitude,
      }
    })
  }, [])

  return (
    <Block>
      <Heading type={HEADING_TYPE.H2}>Location Details</Heading>
      <InputContainer
        s={p => ({
          marginTop: p.theme.sizes.six,
          flex: 1,
        })}
      >
        <Label type={LABEL_TYPE.BODY1_BOLD}>Address Line 1*</Label>
        <AsyncSelectInput
          onChange={onChange}
          name="addressLine1"
          loadOptions={loadOptions}
          value={addressLine1}
          containerStyle={{ marginTop: '0.5rem', width: '100%' }}
        />
      </InputContainer>

      <FormRow>
        <InputContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Apt. Suite or Floor</Label>
          <FormInput
            onInputChange={onChangeText}
            name="addressLine2"
            value={[{ label: addressLine2 }]}
            showClearIcon={false}
            type={INPUT_TYPE.ROUND}
            placeholder="Apt. Suite or Floor"
          />
        </InputContainer>
        <InputContainer>
          <Label
            s={() => ({
              opacity: '50%',
            })}
            type={LABEL_TYPE.BODY1_BOLD}
          >
            City*
          </Label>
          <FormInput
            onInputChange={onChangeText}
            name="city"
            value={[{ label: city }]}
            showClearIcon={false}
            type={INPUT_TYPE.ROUND}
            disabled={true}
          />
        </InputContainer>
      </FormRow>

      <FormRow>
        <InputContainer>
          <Label
            s={() => ({
              opacity: '50%',
            })}
            type={LABEL_TYPE.BODY1_BOLD}
          >
            State*
          </Label>
          <FormInput
            onInputChange={onChangeText}
            name="state"
            value={[{ label: state }]}
            showClearIcon={false}
            type={INPUT_TYPE.ROUND}
            disabled={true}
          />
        </InputContainer>
        <InputContainer>
          <Label
            s={() => ({
              opacity: '50%',
            })}
            type={LABEL_TYPE.BODY1_BOLD}
          >
            Zip Code*
          </Label>
          <FormInput
            onInputChange={onChangeText}
            name="age"
            value={[{ label: zip }]}
            showClearIcon={false}
            type={INPUT_TYPE.ROUND}
            disabled={true}
          />
        </InputContainer>
      </FormRow>

      <FormRow>
        <InputContainer>
          <Label type={LABEL_TYPE.BODY1_BOLD}>Location Name</Label>
          <FormInput
            onInputChange={onChangeText}
            name="name"
            value={[{ label: name || '' }]}
            showClearIcon={false}
            type={INPUT_TYPE.ROUND}
            placeholder="Location Name"
          />
        </InputContainer>
      </FormRow>
    </Block>
  )
}

const FormRow = styled(Flex)`
  margin-top: ${p => p.theme.sizes.four};
  justify-content: space-between;
`
const InputContainer = styled(Flex)`
  flex-direction: column;
  flex: 0.45;
`
const FormInput = styled(Input)`
  margin-top: ${p => p.theme.sizes.two};
`
