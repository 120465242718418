import { useEffect, useState } from 'react'

export const useKeyPress = (targetKey, ref) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false)
  // If pressed key is our target key then set to true
  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true)
    }
  }
  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false)
    }
  }
  // Add event listeners
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener('keydown', downHandler)
      ref.current.addEventListener('keyup', upHandler)
    } else {
      window.addEventListener('keydown', downHandler)
      window.addEventListener('keyup', upHandler)
    }
    // Remove event listeners on cleanup
    return () => {
      if (ref && ref.current) {
        ref.current.removeEventListener('keydown', downHandler)
        ref.current.removeEventListener('keyup', upHandler)
      }
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return keyPressed
}
