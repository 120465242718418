import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Spinner } from 'design-system/spinner/spinner.component'
import { Flex } from 'design-system/flex'
import { AddInsurancesContainer } from './add-insurances-container'
import { ReviewInsuranceChanges } from './review-insurance-changes'
import { VerifyInsurancesContainer } from './verify-insurances-container'
import { EmptyInsurancesContainer } from './empty-insurances-container'
import {
  EDIT_INSURANCE_ACTION_TYPES,
  EDIT_INSURANCE_VIEWS,
} from './edit-insurances-reducer'
import { listOtherLocationsWithInsurances } from 'utils/location'

const ScrollContainer = styled(Flex)`
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  padding: ${p => p.theme.sizes.four} ${p => p.theme.sizes.six};
`

export const EditInsurances = ({
  otherLocations = [],
  submittingChanges = false,
  state = {},
  dispatch = () => {},
  verificationsDiff = undefined,
}) => {
  const {
    view,
    previousView,
    addInsurancesMap,
    insurances,
    editVerifications,
    copyFromLocation,
    type,
  } = state

  const showAdd = view === EDIT_INSURANCE_VIEWS.add
  const showReview = view === EDIT_INSURANCE_VIEWS.review
  const showCopyInsurances = view === EDIT_INSURANCE_VIEWS.copyInsurances

  const handleToggleSingle = (insurance, type) => {
    console.log(`🚀 => handleToggleSingle`, insurance, type)
    dispatch({
      type,
      payload: { insurances: [insurance] },
    })
  }

  const handleToggleList = (insurances, type, filter) => {
    console.log(`🚀 => handleToggleList`, insurances, type, filter)

    dispatch({
      type,
      payload: {
        insurances,
        filter,
      },
    })
  }

  const onSelectLocation = uuid => {
    console.log('onSelectLocation:uuid', uuid)
    if (uuid) {
      const foundLocation = otherLocations.find(l => l.uuid === uuid)
      if (foundLocation) {
        dispatch({
          type: EDIT_INSURANCE_ACTION_TYPES.copyFromLocation,
          payload: { location: foundLocation },
        })
      }
    }
  }

  const onClickCopyInsurances = () => {
    dispatch({
      type: EDIT_INSURANCE_ACTION_TYPES.changeView,
      payload: { view: EDIT_INSURANCE_VIEWS.copyInsurances },
    })
  }

  const onClickAddInsurances = () => {
    dispatch({
      type: EDIT_INSURANCE_ACTION_TYPES.changeView,
      payload: { view: EDIT_INSURANCE_VIEWS.add },
    })
  }

  const listedOtherLocations = listOtherLocationsWithInsurances(otherLocations)

  if (showReview) {
    if (submittingChanges) {
      return <Spinner label="Updating Insurances.." />
    }

    let insurancesForReview = []
    let type = 'default'
    if (previousView === EDIT_INSURANCE_VIEWS.copyInsurances) {
      if (copyFromLocation) {
        insurancesForReview = copyFromLocation.insurances
      }
      type = 'add'
    }
    if (previousView === EDIT_INSURANCE_VIEWS.add) {
      insurancesForReview = Object.keys(addInsurancesMap).map(
        key => addInsurancesMap[key],
      )
      type = 'add'
    }

    if (previousView === EDIT_INSURANCE_VIEWS.default) {
      if (verificationsDiff) {
        insurancesForReview = insurances.filter(i =>
          Boolean(verificationsDiff[i.uuid]),
        )
      }
      type = 'verification'
    }
    return (
      <ScrollContainer>
        <ReviewInsuranceChanges
          verifications={editVerifications}
          verificationsDiff={verificationsDiff}
          insurances={insurancesForReview}
          type={type}
        />
      </ScrollContainer>
    )
  }

  return (
    <>
      {insurances.length > 0 && !showAdd && (
        <VerifyInsurancesContainer
          dispatch={dispatch}
          state={state}
          handleToggleSingle={handleToggleSingle}
          handleToggleList={handleToggleList}
        />
      )}

      {showAdd && (
        <AddInsurancesContainer
          handleToggleSingle={handleToggleSingle}
          handleToggleList={handleToggleList}
          dispatch={dispatch}
          state={state}
        />
      )}

      {insurances.length === 0 && !showAdd && (
        <EmptyInsurancesContainer
          otherLocations={listedOtherLocations}
          onSelectLocation={onSelectLocation}
          onClickAddInsurances={onClickAddInsurances}
          onClickCopyInsurances={onClickCopyInsurances}
          showCopyInsurances={showCopyInsurances}
          selectedLocation={copyFromLocation}
          type={type}
        />
      )}
    </>
  )
}

EditInsurances.propTypes = {
  otherLocations: PropTypes.array,
  submittingChanges: PropTypes.bool,
  state: PropTypes.object,
  dispatch: PropTypes.func,
  verificationsDiff: PropTypes.object,
}
