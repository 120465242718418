import React, { useEffect, useReducer } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import styled from 'styled-components'
import { services } from 'services'
import { Input, INPUT_SIZE, INPUT_TYPE } from 'design-system/input'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label, LABEL_TYPE } from 'design-system/label'
import { AuthLayoutAside } from 'components/auth-layout-aside/auth-layout-aside'
import { Flex } from 'design-system/flex'
import { useToggle } from 'hooks/useToggle'
import { validPassword } from 'utils/password.util'
import { Spinner } from 'design-system/spinner'
import { PasswordValidations } from 'components/password-validations/password-validations'
import ShowSVG from 'assets/show.svg'

const Container = styled(Flex)`
  flex: 1;
  padding: ${p => p.theme.sizes.eight};
  overflow-y: auto;

  @media (max-width: ${p => p.theme.flexboxgrid.breakpoints.sm}em) {
    padding: ${p => p.theme.sizes.five};
    > div {
      justify-content: center;
    }
  }
`

const initialState = {
  email: '',
  password: '',
  loading: true,
  formLoading: false,
  invalidToken: false,
  errors: {},
  success: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export const ConfirmResetPassword = () => {
  const location = useLocation()
  const parsedLocation = queryString.parse(location.search)
  const { token, uid } = parsedLocation
  const [state, dispatch] = useReducer(reducer, initialState)
  const [showPassword, togglePassword] = useToggle(false)

  useEffect(() => {
    const verifyToken = async (token, uid) => {
      try {
        const result = await services.ribbon.verifyToken({ uid, token })

        dispatch({
          type: 'update',
          payload: {
            loading: false,
            email: result.email,
          },
        })
      } catch (error) {
        if (error.message) {
          dispatch({
            type: 'update',
            payload: {
              loading: false,
              invalidToken: true,
            },
          })
        }
      }
    }
    if (token && uid) {
      verifyToken(token, uid)
    } else {
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          invalidToken: true,
        },
      })
    }
  }, [token, uid])

  const onClickResetPassword = async () => {
    const { password } = state
    dispatch({
      type: 'update',
      payload: {
        formLoading: true,
        errors: {},
      },
    })
    try {
      await services.ribbon.confirmResetPassword({
        uid,
        token,
        password,
      })

      dispatch({
        type: 'update',
        payload: {
          success: true,
        },
      })
    } catch (error) {
      dispatch({
        type: 'update',
        payload: {
          formLoading: false,
          errors: {
            general: error.message,
          },
        },
      })
    }
  }

  const onChange = e => {
    const { name, value } = e.target

    dispatch({
      type: 'update',
      payload: {
        [name]: value,
        ...(state.errors[name] && {
          errors: {
            [name]: undefined,
          },
        }),
      },
    })
  }

  const {
    email,
    password,
    errors,
    loading,
    invalidToken,
    formLoading,
    success,
  } = state

  const passwordValidations = validPassword(password, true)

  return (
    <Container>
      <Flex
        s={() => ({
          flex: 1,
        })}
      >
        <Flex
          s={p => ({
            flexDirection: 'column',
            padding: p.theme.sizes.eight,
            border: `1px solid ${p.theme.colors.gray1}`,
            borderRadius: '16px',
            maxWidth: '480px',
            width: '100%',
          })}
        >
          {loading ? (
            <Flex
              s={() => ({
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              })}
            >
              <Spinner />
            </Flex>
          ) : (
            <>
              {invalidToken ? (
                <Flex
                  s={() => ({
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'center',
                  })}
                >
                  <Label
                    s={p => ({
                      fontSize: p.theme.sizes.six,
                      fontWeight: '800',
                      marginBottom: p.theme.sizes.four,
                    })}
                  >
                    This link has expired.
                  </Label>
                  <Label
                    s={p => ({
                      fontSize: p.theme.sizes.four,
                    })}
                  >
                    This can happen if the link is malformed, expired or already
                    been used.
                  </Label>
                  <Button
                    type={BUTTON_TYPE.PRIMARY}
                    size={BUTTON_SIZE.EXTRA_LARGE}
                    onClick={() => navigate('/login')}
                    s={p => ({
                      width: '100%',
                      textAlign: 'center',
                      marginTop: p.theme.sizes.six,
                    })}
                  >
                    Back to Login
                  </Button>
                </Flex>
              ) : (
                <>
                  {success ? (
                    <Flex
                      s={() => ({
                        flex: 1,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      })}
                    >
                      <Label
                        s={p => ({
                          fontSize: p.theme.sizes.six,
                          fontWeight: '800',
                          textAlign: 'center',
                        })}
                      >
                        Password reset successful
                      </Label>
                      <Button
                        type={BUTTON_TYPE.PRIMARY}
                        size={BUTTON_SIZE.EXTRA_LARGE}
                        onClick={() => navigate('/login')}
                        s={p => ({
                          width: '100%',
                          textAlign: 'center',
                          marginTop: p.theme.sizes.seven,
                        })}
                      >
                        Back to Login
                      </Button>
                    </Flex>
                  ) : (
                    <>
                      <Label
                        s={p => ({
                          fontSize: p.theme.sizes.six,
                          fontWeight: '800',
                          textAlign: 'center',
                        })}
                      >
                        Create a new password
                      </Label>
                      <Input
                        s={p => ({ marginTop: p.theme.sizes.seven })}
                        name="email"
                        value={[{ label: email }]}
                        showClearIcon={false}
                        type={INPUT_TYPE.ROUND}
                        size={INPUT_SIZE.EXTRA_LARGE}
                        placeholder="Email"
                        disabled
                      />
                      <Input
                        s={p => ({ marginTop: p.theme.sizes.four })}
                        name="password"
                        onInputChange={onChange}
                        value={[{ label: password }]}
                        showClearIcon={false}
                        type={INPUT_TYPE.ROUND}
                        size={INPUT_SIZE.EXTRA_LARGE}
                        placeholder="Password"
                        inputType={showPassword ? 'text' : 'password'}
                        rightIcon={() => (
                          <EyeIcon
                            togglePassword={togglePassword}
                            showPassword={showPassword}
                          />
                        )}
                        onEnter={() => {
                          if (
                            !formLoading &&
                            passwordValidations.length === 0
                          ) {
                            onClickResetPassword()
                          }
                        }}
                      />
                      <PasswordValidations
                        passwordValidations={passwordValidations}
                      />

                      {errors.general && (
                        <Label
                          type={LABEL_TYPE.ERROR}
                          s={p => ({
                            marginTop: p.theme.sizes.four,
                            alignSelf: 'center',
                          })}
                        >
                          {errors.general}
                        </Label>
                      )}
                      <Button
                        s={p => ({
                          marginTop: p.theme.sizes.four,
                        })}
                        type={
                          passwordValidations.length > 0
                            ? BUTTON_TYPE.DISABLED
                            : BUTTON_TYPE.PRIMARY
                        }
                        onClick={onClickResetPassword}
                        size={BUTTON_SIZE.EXTRA_LARGE}
                        loading={formLoading}
                        disabled={passwordValidations.length > 0}
                      >
                        Save password
                      </Button>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Flex>
      </Flex>
      <AuthLayoutAside />
    </Container>
  )
}

const ShowButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  opacity: ${p => (p.showPassword ? '0.33' : '1')};
  cursor: pointer;
`

const EyeIcon = ({ togglePassword = () => {}, showPassword = false }) => {
  return (
    <ShowButton onClick={togglePassword} showPassword={showPassword}>
      <ShowSVG height="16px" width="16px" />
    </ShowButton>
  )
}
