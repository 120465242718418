import React, {
  useState,
  useCallback,
  useContext,
  useRef,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { Block } from 'design-system/block'
import styled from 'styled-components'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Modal } from 'design-system/modal'
import { LocationDetails } from 'components/edit-location/edit-location-details'
import { LocationFacilityType } from 'components/edit-location/edit-facilty-type'
import { LocationContactInfo } from 'components/edit-location/edit-contact-info'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Chevron } from 'design-system/icon/icons/chervon-left'
import { NotyfContext } from 'context/notyf-context'
import { ConfirmCloseModal } from 'components/confirm-close/confirm-close-modal'
import {
  faxNumbersReducer,
  phoneNumbersReducer,
} from 'utils/formatters/format-phone-number'
import { ReviewChanges } from 'components/edit-review-changes/review-changes'
import { services } from 'services'
import { AuthStateContext } from 'context/auth-state-context'
import { Flex } from 'design-system/flex'

export const LOCATION_SCROLL_OPTIONS = {
  details: 'Details',
  contact: 'Contact',
  facility: 'Facility',
}

const objectHash = {
  objectHash: obj => JSON.stringify(obj),
  arrays: {
    detectMove: false,
  },
}
const jsonDiff = require('jsondiffpatch')

const addNewLocationOrExisting = async (token, updateBody, provider) => {
  try {
    const addRes = await services.ribbon.addLocation(token, updateBody)
    console.log('Add Res: ', addRes)
    if (provider) {
      await services.ribbon
        .updateProviderLocations(token, provider.npi, {
          add: [addRes.uuid],
        })
        .catch(error => {
          console.log(error)
          return {
            type: 'error',
            message: 'Error adding location to this provider!',
            newProviderLocation: null,
          }
        })
      // New Location used to navigate to this newly created location
      return {
        type: 'success',
        message: 'New Location Added!',
        newProviderLocation: addRes,
      }
    }
    // Add Result on close navigates to location page of newly created
    return {
      type: 'success',
      message: 'New Facility Added!',
      newFacility: addRes,
    }
  } catch (error) {
    console.log('Create Location Err:', error.response)

    const { response } = error
    if (response) {
      if (response.status === 409) {
        if (provider) {
          const { data } = response.data
          console.log('Create Location Exists already data:', data)
          await services.ribbon
            .updateProviderLocations(token, provider.npi, {
              add: [data.uuid],
            })
            .catch(error => {
              console.log(error)
              return {
                type: 'error',
                message: 'Error adding location to this provider!',
                newProviderLocation: null,
              }
            })
          return {
            type: 'success',
            message: 'New Location Added!',
            newProviderLocation: data,
          }
        }

        return {
          type: 'error',
          message: 'Location already exists!',
          newFacility: null,
        }
      }
    }
  }
}

const setUpdateBodyMetadata = editLocation => {
  const updateBody = {}
  updateBody.name = editLocation.name
  updateBody.phone_numbers = editLocation.phoneNumbers
    ? phoneNumbersReducer(editLocation.phoneNumbers)
    : []
  updateBody.faxes = editLocation.faxes
    ? faxNumbersReducer(editLocation.faxes)
    : []
  updateBody.location_types = editLocation.locationTypes || []
  updateBody.confidence = 5.0

  return updateBody
}

export const DetailEditLocationForm = ({
  selectedLocation = {},
  closeModal = async () => {},
  addNew = false,
  title = '',
  provider,
  facility,
  scrollTo,
}) => {
  const [authState] = useContext(AuthStateContext)
  const [editLocation, setEditLocation] = useState(
    JSON.parse(JSON.stringify(selectedLocation)),
  )
  const [showReviewChanges, setShowReviewChanges] = useState(false)
  const [submittingChanges, setSubmittingChanges] = useState(false)
  const notyf = useContext(NotyfContext)
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false)
  const contactInformationRef = useRef(false)

  console.log('Edit Location', editLocation)

  useEffect(() => {
    console.log('Initial Run', scrollTo)
    if (scrollTo && scrollTo === LOCATION_SCROLL_OPTIONS.contact) {
      if (contactInformationRef.current) {
        contactInformationRef.current.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }
  }, [])

  const ogLocation = {
    'Address Details': {
      address: selectedLocation.address,
    },
    'Location Name': {
      name: selectedLocation.name,
    },
    'Phone Numbers': selectedLocation.phoneNumbers
      ? phoneNumbersReducer(selectedLocation.phoneNumbers)
      : [],
    'Fax Numbers': selectedLocation.faxes
      ? faxNumbersReducer(selectedLocation.faxes)
      : [],
    'Facility Type': selectedLocation.locationTypes || [],
  }

  const locationWithChanges = {
    'Address Details': {
      address: editLocation.address,
    },
    'Location Name': {
      name: editLocation.name,
    },
    'Phone Numbers': editLocation.phoneNumbers
      ? phoneNumbersReducer(editLocation.phoneNumbers)
      : [],
    'Fax Numbers': editLocation.faxes
      ? faxNumbersReducer(editLocation.faxes)
      : [],
    'Facility Type': editLocation.locationTypes || [],
  }

  let locationDiff = undefined
  locationDiff = jsonDiff
    .create(objectHash)
    .diff(ogLocation, locationWithChanges)

  const toggleReviewChanges = useCallback(() => {
    setShowReviewChanges(prev => !prev)
  }, [])

  const addNewLocation = useCallback(async () => {
    console.log('Adding New Location')
    setSubmittingChanges(true)
    // Create New Location
    const updateBody = setUpdateBodyMetadata(editLocation)
    updateBody.address = editLocation.address

    // Add New(Facility/Provider Location) or Add Existing Location to Provider
    try {
      const result = await addNewLocationOrExisting(
        authState.token,
        updateBody,
        provider,
      )
      console.log('Result', result)
      const { type, message, newProviderLocation, newFacility } = result

      // Update Provider Location Metadata with new updated information
      if (newProviderLocation) {
        await services.ribbon.updateProviderLocationData(
          provider.npi,
          newProviderLocation.uuid,
          updateBody,
        )
      }

      if (type === 'success') {
        if (newProviderLocation) {
          await closeModal({
            refresh: true,
            newProviderLocation: newProviderLocation,
          })
        }
        if (newFacility) {
          await closeModal({ refresh: true, newFacility: newFacility })
        }
        notyf.success(message)
      }
      if (type === 'error') {
        notyf.error(message)
      }
      setSubmittingChanges(false)
    } catch (error) {
      console.log('addNewLocation Err:', error.response)
      setSubmittingChanges(false)
      notyf.error('An error occurred!')
    }
  }, [editLocation, authState.token, provider])

  const updateLocation = useCallback(async () => {
    console.log('Updating Location')
    setSubmittingChanges(true)
    let updateBody = {}
    let updateId = editLocation.uuid
    let newLocation = false
    let addNewLocationResult = null

    Object.keys(locationDiff).forEach(key => {
      if (key !== 'Address Details') {
        updateBody = setUpdateBodyMetadata(editLocation)
      }

      if (key === 'Address Details') {
        let addressDiff = undefined
        const ogAddressDetails = selectedLocation.addressDetails
        const addressDetailsWithChanges = editLocation.addressDetails
        addressDiff = jsonDiff
          .create(objectHash)
          .diff(ogAddressDetails, addressDetailsWithChanges)
        console.log('AddressDiff', addressDiff)

        if (
          addressDiff &&
          Object.keys(addressDiff).length === 1 &&
          addressDiff.addressLine2
        ) {
          // Only for updating AddressLine 2, rest of address stays the same
          updateBody.address_details = {
            address_line_1: selectedLocation.addressDetails.addressLine1,
            address_line_2: editLocation.addressDetails.addressLine2,
            city: selectedLocation.addressDetails.city,
            state: selectedLocation.addressDetails.state,
            zip: selectedLocation.addressDetails.zip,
            street: `${selectedLocation.addressDetails.addressLine1}, ${editLocation.addressDetails.addressLine2}`,
          }
          updateBody.address = editLocation.address
          updateBody.confidence = 5.0
        }
        if (addressDiff && addressDiff.addressLine1) {
          // Create or select location. Same handling as Create New
          newLocation = true
          updateBody = setUpdateBodyMetadata(editLocation)
        }
      }
    })

    console.log('UpdateBody', updateBody)
    /**
     * Handle Creating New Location - Updating Provider Locations
     */
    if (newLocation) {
      const newLocationBody = {
        name: editLocation.name,
        address: editLocation.address,
        phone_numbers: editLocation.phoneNumbers,
        faxes: editLocation.faxes,
        location_types: editLocation.locationTypes,
        confidence: 5,
      }
      const addNewRes = await addNewLocationOrExisting(
        authState.token,
        newLocationBody,
        provider,
      )
      console.log('addNewRes', addNewRes)
      const { newProviderLocation, newFacility } = addNewRes
      // Remove old location from provider
      if (provider) {
        await services.ribbon
          .updateProviderLocations(authState.token, provider.npi, {
            remove: [selectedLocation.uuid],
          })
          .catch(error => {
            console.log('updateProviderLocations error: ', error)
          })

        if (newProviderLocation && newProviderLocation.uuid) {
          updateId = newProviderLocation.uuid
          addNewLocationResult = newProviderLocation

          // Add Insurances to New Provider Location
          if (editLocation.insurances && editLocation.insurances.length > 0) {
            const addInsurancesBody = {
              add: editLocation.insurances.map(i => i.uuid),
            }
            await services.ribbon.updateProviderLocationInsurances(
              provider.npi,
              updateId,
              addInsurancesBody,
            )
          }
        }
      }

      if (facility) {
        if (newFacility && newFacility.uuid) {
          updateId = newFacility.uuid
          addNewLocationResult = newFacility
          // Add Insurances to New Location
          if (editLocation.insurances && editLocation.insurances.length > 0) {
            const addInsurancesBody = {
              add: editLocation.insurances.map(i => i.uuid),
            }
            await services.ribbon.updateLocationInsurances(
              updateId,
              addInsurancesBody,
            )
          }
        }
      }
    }

    /**
     * Handle Update for rest of fields
     */
    if (Object.keys(updateBody).length > 0) {
      try {
        if (provider) {
          await services.ribbon.updateProviderLocationData(
            provider.npi,
            updateId,
            updateBody,
          )
        }
        if (facility) {
          await services.ribbon.updateLocationData(updateId, updateBody)
        }
        await closeModal({
          refresh: true,
          ...(addNewLocationResult
            ? {
                newFacility: addNewLocationResult,
                newProviderLocation: addNewLocationResult,
              }
            : {}),
        })
        setSubmittingChanges(false)
        notyf.success('Location Updated!')
        return
      } catch (error) {
        setSubmittingChanges(false)
        notyf.error(error.message)
        return
      }
    }
  }, [
    locationDiff,
    selectedLocation,
    editLocation,
    provider,
    facility,
    authState.token,
  ])

  const submitChanges = () => {
    console.log('Previous:', selectedLocation)
    console.log('Changes:', editLocation)
    console.log('Diff', locationDiff)

    if (addNew) {
      addNewLocation()
    } else {
      updateLocation()
    }
  }

  const handleClose = useCallback(() => {
    if (locationDiff) {
      setShowCloseConfirmation(true)
      return
    }
    closeModal()
  }, [locationDiff, closeModal])

  if (showCloseConfirmation) {
    return (
      <ConfirmCloseModal
        closeModal={closeModal}
        onClickCancel={() => setShowCloseConfirmation(false)}
      />
    )
  }

  let disabledReviewChangesBtn = false
  if (locationDiff === undefined) {
    disabledReviewChangesBtn = true
  }
  if (locationDiff !== undefined) {
    // User should not be able to continue if no address is empty
    if (addNew && !editLocation.address) {
      disabledReviewChangesBtn = true
    }
  }
  if (submittingChanges) {
    disabledReviewChangesBtn = true
  }

  return (
    <Modal
      onClose={handleClose}
      title={title}
      headerLeftAction={
        showReviewChanges ? (
          <Label
            type={LABEL_TYPE.LINK}
            onClick={() => setShowReviewChanges(false)}
          >
            <Chevron direction="left" style={{ marginRight: '0.5rem' }} />
            Back to edit
          </Label>
        ) : null
      }
      footer={
        <Flex
          s={p => ({
            paddingRight: p.theme.sizes.six,
            justifyContent: 'flex-end',
          })}
        >
          <Button
            s={() => ({
              marginRight: '1rem',
            })}
            type={BUTTON_TYPE.LINK}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            onClick={showReviewChanges ? submitChanges : toggleReviewChanges}
            type={
              disabledReviewChangesBtn
                ? BUTTON_TYPE.DISABLED
                : BUTTON_TYPE.PRIMARY
            }
            size={BUTTON_SIZE.LARGE}
            disabled={disabledReviewChangesBtn}
          >
            {showReviewChanges ? 'Submit changes' : 'Next: Review Changes'}
          </Button>
        </Flex>
      }
    >
      {showReviewChanges ? (
        <ReviewChanges
          diff={locationDiff}
          renderArrayChanges={renderArrayChanges}
          renderPropertyChanges={renderPropertyChanges}
          title="Location Details"
          loading={submittingChanges}
          loadingMessage="Updating Location Details"
        />
      ) : (
        <Block
          s={() => ({
            paddingBottom: '14rem',
          })}
        >
          <LocationDetails
            editLocation={editLocation}
            setEditLocation={setEditLocation}
          />
          <Divider />
          <LocationContactInfo
            editLocation={editLocation}
            setEditLocation={setEditLocation}
            forwardRef={contactInformationRef}
          />
          <Divider />
          <LocationFacilityType
            editLocation={editLocation}
            setEditLocation={setEditLocation}
          />
        </Block>
      )}
    </Modal>
  )
}

const renderArrayChanges = (key, item) => {
  return Object.keys(item).map(itemKey => {
    if (itemKey === '_t') return // Ignore _t as its only an indicator this is an array
    let formattedLabel = ''
    let action = ''

    if (key === 'Phone Numbers') {
      formattedLabel = item[itemKey][0].phone
    }
    if (key === 'Fax Numbers') {
      formattedLabel = item[itemKey][0].fax
    }

    if (key === 'Facility Type') {
      formattedLabel = item[itemKey][0]
    }

    if (!itemKey.includes('_')) {
      action = 'Added'
    }
    if (itemKey.includes('_')) {
      action = 'Removed'
    }

    return (
      <Flex
        key={itemKey}
        s={p => ({
          marginBottom: p.theme.sizes.three,
          justifyContent: 'space-between',
        })}
      >
        <Label type={LABEL_TYPE.BODY1}>{formattedLabel}</Label>
        <Label type={LABEL_TYPE.BODY1}>{action}</Label>
      </Flex>
    )
  })
}

const renderPropertyChanges = (item = [], itemKey = '') => `${item[itemKey][1]}`

const Divider = styled.div`
  background-color: ${props => props.theme.colors.gray3};
  width: 100%;
  height: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`
DetailEditLocationForm.propTypes = {
  selectedLocation: PropTypes.object,
  closeModal: PropTypes.func,
  addNew: PropTypes.bool,
  title: PropTypes.string,
  provider: PropTypes.object,
  facility: PropTypes.object,
  scrollTo: PropTypes.string,
}
