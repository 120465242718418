import React from 'react'
import ReactSwitch from 'react-switch'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { Block } from 'design-system/block'
import { Label } from 'design-system/label'
import { colors } from 'theme/colors'
import DragHandle from 'assets/drag-handle.svg'
import { Flex } from 'design-system/flex'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // change background colour if dragging
  background: isDragging ? colors.gray7 : colors.white,
  border: isDragging ? `1px solid ${colors.borderLight}` : 'none',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? colors.gray7 : colors.white,
})

const listIds = {
  sidebar: 'sidebar',
  more: 'more',
}

export const FilterSectionList = ({
  sectionTitle = '',
  list = { sidebar: [], more: [] },
  configKey = '',
  editMode = false,
  onChangeEnabled = () => {},
  dispatch = () => {},
}) => {
  const sidebarMenu = list[listIds.sidebar]
  const moreMenu = list[listIds.more]

  const onDragEnd = result => {
    console.log(`🚀 => result`, result)
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    // staying within same list
    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        list[source.droppableId],
        source.index,
        destination.index,
      )

      dispatch({
        type: 'reorder',
        payload: {
          configKey,
          result: {
            [source.droppableId]: items,
          },
        },
      })
    } else {
      const result = move(
        list[source.droppableId],
        list[destination.droppableId],
        source,
        destination,
      )

      // adjust value of is_more depending on destination change
      result[destination.droppableId][destination.index].is_more =
        destination.droppableId === listIds.more

      dispatch({
        type: 'reorder',
        payload: {
          configKey,
          result: {
            [source.droppableId]: result[source.droppableId],
            [destination.droppableId]: result[destination.droppableId],
          },
        },
      })
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <>
        <SectionHeaderTitle>{sectionTitle}</SectionHeaderTitle>
        <Block
          type="CARD"
          s={() => ({
            padding: 0,
          })}
        >
          <Droppable droppableId="sidebar" isDropDisabled={!editMode}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{ ...getListStyle(snapshot.isDraggingOver) }}
              >
                <CardHeader>
                  <Label>Sidebar Menu</Label>
                </CardHeader>
                {sidebarMenu.map((item, index) => {
                  return (
                    <Draggable
                      key={item.uuid}
                      draggableId={item.uuid}
                      index={index}
                      isDragDisabled={!editMode}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <SectionRowItem
                            key={item.uuid}
                            configKey={configKey}
                            editMode={editMode}
                            item={item}
                            isLast={false}
                            onChangeEnabled={onChangeEnabled}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable droppableId="more" isDropDisabled={!editMode}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{ ...getListStyle(snapshot.isDraggingOver) }}
              >
                <CardHeader>
                  <Label>More Menu</Label>
                </CardHeader>
                {moreMenu.map((item, index) => {
                  const isLast = index === moreMenu.length - 1
                  return (
                    <Draggable
                      key={item.uuid}
                      draggableId={item.uuid}
                      index={index}
                      isDragDisabled={!editMode}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <SectionRowItem
                            key={item.uuid}
                            configKey={configKey}
                            editMode={editMode}
                            item={item}
                            isLast={isLast}
                            onChangeEnabled={onChangeEnabled}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Block>
      </>
    </DragDropContext>
  )
}

const SectionRowItem = ({
  configKey = '',
  item = {
    uuid: '',
    display_name: '',
    enabled: false,
    is_more: false,
    display_order: 0,
  },
  editMode = false,
  isLast = false,
  onChangeEnabled = () => {},
}) => {
  return (
    <SectionRow isLast={isLast} editMode={editMode}>
      <Flex
        s={p => ({
          padding: p.theme.spacing.two,
          alignItems: 'center',
          paddingLeft: 0,
        })}
      >
        {editMode && <DragHandle />}
        <Label>{item.display_name}</Label>
      </Flex>
      <ReactSwitch
        onChange={() => onChangeEnabled(configKey, item)}
        checked={item.enabled}
        onColor={colors.aubergine}
        checkedIcon={false}
        uncheckedIcon={false}
        height={16}
        width={32}
        disabled={!editMode}
      />
    </SectionRow>
  )
}

const SectionHeaderTitle = styled(Label)`
  font-size: ${p => p.theme.headingSizes.H3};
  font-weight: 700;
  margin-bottom: ${p => p.theme.sizes.four};
`

const CardHeader = styled(Block)`
  flex: 1;
  padding: 0;
  padding-top: ${p => p.theme.sizes.four};
  border-bottom: 1px solid ${p => p.theme.colors.gray4};
  p {
    padding: ${p => p.theme.sizes.two} ${p => p.theme.sizes.four};
    font-weight: 500;
    color: ${p => p.theme.colors.gray1};
  }
`

const SectionRow = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${p => p.theme.sizes.two} ${p => p.theme.sizes.four};
  cursor: ${p => (p.editMode ? 'grab' : 'auto')};
  ${p => ({
    ...(p.isLast
      ? {
          borderBottom: `1px solid ${p.theme.colors.clear}`,
        }
      : {
          borderBottom: `1px solid ${p.theme.colors.borderLight}`,
        }),
  })}
  p {
    color: ${p => p.theme.colors.gray1};
    font-weight: 400;
  }
  & > div > svg {
    margin-right: ${p => p.theme.sizes.four};
  }
`
