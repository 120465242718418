import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex } from 'design-system/flex'
import { Label, LABEL_TYPE } from 'design-system/label'
import dayjs from 'utils/dayjs'
import { EditDetailButton } from 'components/edit-detail-btn/edit-detail-btn'
import { useToggle } from 'hooks/useToggle'
import { PermissionRestricted } from 'components/permission-restricted/permission-restricted'

const NoteContainer = styled(Flex)`
  flex-direction: column;
  margin-left: ${p => p.theme.sizes.three};
  flex: 1;
`

const NoteHeaderRow = styled(Flex)`
  align-items: center;
  line-height: 32px;
  justify-content: space-between;
`

const NoteAuthor = styled(Label)`
  font-weight: 500;
`

const NoteDate = styled(Label)`
  margin-left: ${p => p.theme.sizes.three};
  color: ${p => p.theme.colors.textLightest};
`
const NoteTitle = styled(Label)`
  font-weight: 700;
  margin-top: ${p => p.theme.sizes.three};
  font-size: ${p => p.theme.headingSizes.H3};
`

const NoteBody = styled(Label)`
  margin-top: ${p => p.theme.sizes.three};
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
`

const ProfileIcon = styled(Flex)`
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.colors.aubergine};
  height: 32px;
  width: 32px;
  min-width: 32px;
  padding: 8px;
  border-radius: 50%;
  color: ${p => p.theme.colors.white};
  font-weight: 500;
`

export const NoteItem = ({ note, onClickEditNote, isEditable = false }) => {
  const { title, body, user_name: authorFullName, updated_at: updatedAt } = note
  const [showMore, toggleShowMore] = useToggle(false)
  const onClickEdit = () => {
    onClickEditNote(note)
  }

  const showReadMoreBtn = body && body.length > 150 // about two lines of characters
  let truncatedBody = body
  if (showReadMoreBtn && !showMore) {
    truncatedBody = body.slice(0, 150).concat('...')
  }

  return (
    <Flex>
      <ProfileIcon>
        {authorFullName && authorFullName.length > 0
          ? authorFullName[0].toUpperCase()
          : '?'}
      </ProfileIcon>
      <NoteContainer>
        <NoteHeaderRow>
          <Flex>
            <NoteAuthor>{authorFullName}</NoteAuthor>
            <NoteDate>{dayjs(updatedAt).format('ll')}</NoteDate>
          </Flex>
          {isEditable && (
            <PermissionRestricted level={2}>
              <EditDetailButton onClick={onClickEdit} size="sm" />
            </PermissionRestricted>
          )}
        </NoteHeaderRow>
        <NoteTitle>{title}</NoteTitle>
        <NoteBody>{truncatedBody}</NoteBody>
        {showReadMoreBtn && (
          <Label
            s={p => ({
              marginTop: p.theme.sizes.four,
            })}
            onClick={toggleShowMore}
            type={LABEL_TYPE.LINK}
          >
            {showMore ? 'Show less' : 'Read more'}
          </Label>
        )}
      </NoteContainer>
    </Flex>
  )
}

NoteItem.propTypes = {
  note: PropTypes.object,
  onClickEditNote: PropTypes.func,
  isEditable: PropTypes.bool,
}
