import React, { useReducer } from 'react'
import { Block } from 'design-system/block'
import { Label, LABEL_TYPE } from 'design-system/label'
import { DetailSearchInput } from '../../../../components/detail-search-input/detail-search-input'
import styled from 'styled-components'
import {
  clinicalAreasReducer,
  getInitialClinicalAreaState,
} from './clinical-areas-reducer'
import { ClinicalAreasUngroupedListItem } from './clinical-areas-ungrouped-list-item'
import { ClinicalAreaListItem } from './clinical-areas-list-item'

const ListCard = styled(Block)`
  ${p => ({
    padding: p.theme.sizes.four,
    fontSize: p.theme.sizes.four,
    maxHeight: '24rem',
    overflowY: 'auto',
  })}
`

export const ClinicalAreasList = ({
  clinicalAreas = [],
  groupByArea = true,
  isFacilityPage = false,
}) => {
  const [state, dispatch] = useReducer(
    clinicalAreasReducer,
    getInitialClinicalAreaState(clinicalAreas),
  )

  const {
    filteredClinicalAreas,
    filteredTreatments,
    filteredConditions,
    clinicalAreasSearch,
    conditionsSearch,
    treatmentsSearch,
  } = state

  if (!groupByArea) {
    return (
      <>
        {!isFacilityPage &&
          <>
            <Label
              s={p => ({
                marginBottom: p.theme.sizes.four,
                fontSize: p.theme.headingSizes.H3,
                fontWeight: '700',
              })}
              type={LABEL_TYPE.BODY1_BOLD}
            >
              Conditions
            </Label>
            <DetailSearchInput
              placeholder="Search conditions"
              value={conditionsSearch}
              onChange={() =>
                dispatch({ type: 'clear', payload: { key: 'conditionsSearch' } })
              }
              onInputChange={e =>
                dispatch({
                  type: 'update',
                  payload: { conditionsSearch: e.target.value },
                })
              }
            />
            <ListCard type={'CARD'}>
              {filteredConditions.map((item, index) => (
                <ClinicalAreasUngroupedListItem
                  key={item.uuid}
                  item={item}
                  isLast={index === filteredConditions.length - 1}
                />
              ))}
              {filteredConditions.length === 0 && conditionsSearch.length > 0 && (
                <Label type={LABEL_TYPE.BODY1_BOLD}>No matching results</Label>
              )}
            </ListCard>

            <Divider />
          </>
        }


        <Label
          s={p => ({
            marginBottom: p.theme.sizes.four,
            fontSize: p.theme.headingSizes.H3,
            fontWeight: '700',
          })}
          type={LABEL_TYPE.BODY1_BOLD}
        >
          Treatments
        </Label>
        <DetailSearchInput
          placeholder="Search treatments"
          value={treatmentsSearch}
          onChange={() =>
            dispatch({ type: 'clear', payload: { key: 'treatmentsSearch' } })
          }
          onInputChange={e =>
            dispatch({
              type: 'update',
              payload: { treatmentsSearch: e.target.value },
            })
          }
        />

        <ListCard type={'CARD'}>
          {filteredTreatments.map((item, index) => (
            <ClinicalAreasUngroupedListItem
              key={item.uuid}
              item={item}
              isLast={index === filteredTreatments.length - 1}
            />
          ))}
          {filteredTreatments.length === 0 && treatmentsSearch.length > 0 && (
            <Label type={LABEL_TYPE.BODY1_BOLD}>No matching results</Label>
          )}
        </ListCard>
      </>
    )
  }
  return (
    <>
      <DetailSearchInput
        placeholder="Search Clinical Areas"
        value={clinicalAreasSearch}
        onChange={() =>
          dispatch({ type: 'clear', payload: { key: 'clinicalAreasSearch' } })
        }
        onInputChange={e =>
          dispatch({
            type: 'update',
            payload: { clinicalAreasSearch: e.target.value },
          })
        }
      />

      <ListCard type={'CARD'}>
        {filteredClinicalAreas.map((item, index) => {
          return (
            <ClinicalAreaListItem
              key={item.uuid}
              clinicalArea={item}
              isLast={index === filteredClinicalAreas.length - 1}
              isFacilityPage={isFacilityPage}
            />
          )
        })}
        {filteredClinicalAreas.length === 0 &&
          clinicalAreasSearch.length > 0 && (
            <Label type={LABEL_TYPE.BODY1_BOLD}>No matching results</Label>
          )}
      </ListCard>
    </>
  )
}

const Divider = styled.div`
  ${p => ({
    height: '1px',
    width: '100%',
    backgroundColor: p.theme.colors.gray4,
    margin: `${p.theme.sizes.six} 0`,
  })}
`
