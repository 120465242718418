import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal } from 'design-system/modal'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Chevron } from 'design-system/icon/icons/chervon-left'
import { Flex } from 'design-system/flex'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { FIELD_TYPES_FLAT_LOWER } from 'utils/constants'
import { customFieldsEditsAreValid } from 'utils/validations'
import { InputByType } from './input-by-type'
import { useCustomFieldMutations } from './useCustomFieldMutations'
import { Spinner } from 'design-system/spinner'
import { useToggle } from 'hooks/useToggle'
import { DeleteModal } from 'components/delete-modal/delete-modal'

const objectHash = {
  objectHash: obj => JSON.stringify(obj),
  arrays: {
    detectMove: false,
  },
}
const jsonDiff = require('jsondiffpatch')

const FooterContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 0 ${p => p.theme.sizes.four};
`

export const CustomListModal = ({
  field = {},
  values = [],
  handleClose = () => {},
  type, // provider || facility
  updateId, // provider npi || facility id
  activeItem,
}) => {
  const { item, index } = activeItem || {}
  const isAddNew = index === values.length

  const [editField, setEditField] = useState(JSON.parse(JSON.stringify(item)))
  const [showDelete, toggleDelete] = useToggle()

  const fieldDiff = jsonDiff.create(objectHash).diff(item, editField)
  // console.log(`🚀 => field`, field)
  // console.log(`🚀 => item`, item)
  // console.log(`🚀 => editField`, editField)
  // console.log(`🚀 => fieldDiff`, fieldDiff)

  const [showReviewChanges, setShowReviewChanges] = useState(false)

  const {
    updateProviderInfo,
    updatingProviderField,
    updateFacilityInfo,
    updatingFacilityField,
  } = useCustomFieldMutations({ field, updateId, handleClose })

  const submitChanges = () => {
    console.log(`🚀 => submitChanges:editField`, editField, activeItem)
    if (!activeItem || activeItem.index === undefined) {
      return
    }

    const updateBody = {}
    const valuesCopy = values.slice()
    if (isAddNew) {
      // Adding new items to beginning of list
      valuesCopy.unshift(editField)
    } else {
      valuesCopy.splice(activeItem.index, 1, editField)
    }

    updateBody[field.parameter_name] = valuesCopy
    console.log(`🚀 => submitChanges:updateBody`, updateBody)

    if (type === 'providers') {
      updateProviderInfo({ options: updateBody })
    }
    if (type === 'facilities') {
      updateFacilityInfo({ options: updateBody })
    }
  }

  const submitDelete = () => {
    if (activeItem.index === undefined) {
      return
    }

    const updateBody = {}
    const valuesCopy = values.slice()
    valuesCopy.splice(activeItem.index, 1)

    updateBody[field.parameter_name] = valuesCopy
    console.log(`🚀 => submitDelete:updateBody`, updateBody)

    if (type === 'providers') {
      updateProviderInfo({ options: updateBody })
    }
    if (type === 'facilities') {
      updateFacilityInfo({ options: updateBody })
    }
  }

  const toggleReviewChanges = () => {
    console.log(`🚀 => toggleReviewChanges`)
    setShowReviewChanges(prev => !prev)
  }

  const onChangeField = e => {
    let { value, name, type } = e.target
    // console.log(`🚀 => onChangeField => value, name, type`, value, name, type)

    // Format numbers
    if (type === 'number') {
      value = Number(value)
      if (value > Number.MAX_SAFE_INTEGER) {
        value = Number.MAX_SAFE_INTEGER
      }
    }

    const { list_type } = field.list_display_config
    if (list_type === FIELD_TYPES_FLAT_LOWER.object) {
      // handle special case for List of Objects
      if (name === 'display_name') {
        // Updating display_name currently not a feature.
        // display_name comes from config which is not editable
      } else {
        // Update values within keys of object values
        setEditField(prev => {
          return {
            ...prev,
            [name]: value,
          }
        })
      }
    } else {
      setEditField(value)
    }
  }

  const handleDelete = () => {
    toggleDelete()
  }

  let disabledReviewChangesBtn = true

  if (fieldDiff !== undefined) {
    if (customFieldsEditsAreValid(field, editField)) {
      disabledReviewChangesBtn = false
    }
  }

  const isUpdating = updatingProviderField || updatingFacilityField

  const { list_display_config } = field
  const { object_display_config } = list_display_config

  // keys will be null if list is not type object.
  const { keys: objectDisplayConfigs } = object_display_config || {}

  if (showDelete) {
    return (
      <DeleteModal
        handleClose={() => handleClose()}
        handleDelete={submitDelete}
        handleCancel={toggleDelete}
        title={`Delete ${field.display_name || 'Field'}`}
        fieldName={field.display_name}
        loading={isUpdating}
      />
    )
  }

  return (
    <Modal
      onClose={() => handleClose(null)}
      title={
        showReviewChanges
          ? 'Review Changes'
          : isAddNew
          ? `Add ${field.display_name || 'Field Name'}`
          : `Edit ${field.display_name || 'Field Name'}`
      }
      gridContainerStyle={{ maxWidth: '600px' }}
      headerLeftAction={
        showReviewChanges ? (
          <Label
            type={LABEL_TYPE.LINK}
            onClick={() => setShowReviewChanges(false)}
          >
            <Chevron direction="left" style={{ marginRight: '0.5rem' }} />
            Back to edit
          </Label>
        ) : null
      }
      footer={
        <FooterContainer>
          <Flex>
            {!showReviewChanges && !isAddNew && (
              <Button
                s={p => ({
                  ['> button']: {
                    color: p.theme.colors.errorRed,
                    fontWeight: '500',
                  },
                })}
                type={BUTTON_TYPE.LINK}
                onClick={handleDelete}
              >
                Delete
              </Button>
            )}
          </Flex>
          <Flex>
            <Button
              s={p => ({
                marginRight: p.theme.sizes.two,
              })}
              type={BUTTON_TYPE.LINK}
              onClick={() => handleClose(null)}
            >
              Cancel
            </Button>
            <Button
              onClick={showReviewChanges ? submitChanges : toggleReviewChanges}
              type={
                disabledReviewChangesBtn || isUpdating
                  ? BUTTON_TYPE.DISABLED
                  : BUTTON_TYPE.PRIMARY
              }
              disabled={disabledReviewChangesBtn || isUpdating}
            >
              {showReviewChanges ? 'Submit changes' : 'Next: Review Changes'}
            </Button>
          </Flex>
        </FooterContainer>
      }
    >
      {isUpdating ? (
        <Flex
          s={() => ({
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <Spinner label={`Updating ${field.display_name || 'Field Name'}`} />
        </Flex>
      ) : (
        <>
          {/* List of objects */}
          {list_display_config.list_type === FIELD_TYPES_FLAT_LOWER.object ? (
            <FormContainer>
              {objectDisplayConfigs.map((objDisplayConfig, index) => {
                const { name, type, display_name } = objDisplayConfig
                const keyValue = editField[name]
                return (
                  <Flex
                    key={name}
                    s={p => ({
                      marginTop: index !== 0 ? p.theme.sizes.four : 0,
                    })}
                  >
                    <InputContainer>
                      <Label>{display_name}</Label>
                      <InputByType
                        fieldType={type}
                        value={keyValue || ''}
                        name={name}
                        onChangeField={onChangeField}
                        disabled={showReviewChanges}
                      />
                    </InputContainer>
                  </Flex>
                )
              })}
            </FormContainer>
          ) : (
            <FormContainer>
              {/* List of string, integers, floats, booleans, dates */}
              <InputContainer>
                <InputByType
                  fieldType={list_display_config.list_type}
                  value={editField}
                  onChangeField={onChangeField}
                  disabled={showReviewChanges}
                />
              </InputContainer>
            </FormContainer>
          )}
        </>
      )}
    </Modal>
  )
}

const InputContainer = styled.div`
  flex: 1;
`

const FormContainer = styled(Flex)`
  flex-direction: column;
  padding: ${p => p.theme.sizes.five};
  min-height: 120px;
`

CustomListModal.propTypes = {
  field: PropTypes.object,
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  handleClose: PropTypes.func,
  type: PropTypes.string,
  updateId: PropTypes.string,
  activeItem: PropTypes.object,
}
