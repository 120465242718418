import React from 'react'
import PropTypes from 'prop-types'
import { AsyncSelectInput } from 'design-system/input/async-select-input'
import { sizes } from 'theme/sizing'
import { colors } from 'theme/colors'
import { GlobalStateContext } from 'context/global-context-provider'
import algoliasearch from 'algoliasearch/lite'
import SearchSVG from 'assets/search.svg'
import { AuthStateContext } from 'context/auth-state-context'

export const NameInputAutocomplete = props => {
  const [searchState] = React.useContext(GlobalStateContext)
  const [authState] = React.useContext(AuthStateContext)
  const appId = authState.algoliaAppId
  const apiKey = authState.algoliaSearchKey
  const searchClient = algoliasearch(appId, apiKey)
  const loadOptions = React.useCallback(
    async (inputValue, callback) => {
      if (inputValue.length > 0) {
        try {
          const facetFilters = []
          let geoSearchParams = ['', '']
          if (searchState.specialties) {
            const mappedSpecialties = searchState.specialties.map(
              s => `specialties:${s.uuid}`,
            )
            // https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/
            // Using the OR option to match api search results
            facetFilters.push(mappedSpecialties)
          }
          if (searchState.location) {
            geoSearchParams = [
              `${searchState.location.position.latitude}, ${searchState.location.position.longitude}`,
              160934, // 100 miles in meters
            ]
          }

          if (searchState.providersSearchMode == 'virtualCare')
            facetFilters.push('has_telehealth:True')

          const index = searchClient.initIndex(authState.algoliaIndexName)
          const results = await index.search(inputValue, {
            hitsPerPage: 8,
            facetFilters: facetFilters,
            aroundLatLng: geoSearchParams[0],
            aroundRadius: geoSearchParams[1],
            highlightPreTag: '<mark>',
            highlightPostTag: '</mark>',
          })

          if (results && results.hits) {
            const filteredResults = results.hits.map(item => {
              return {
                label: `${item.first_name} ${item.last_name}`,
                value: `${item.first_name} ${item.last_name}`,
              }
            })
            filteredResults.push({
              label: `Search for "${inputValue}"`,
              value: inputValue,
            })
            return callback(filteredResults)
          }

          callback([])
        } catch (error) {
          console.log('Search Error: ', error)
          callback([])
        }
      }
      callback([])
    },
    [searchState],
  )

  return (
    <AsyncSelectInput
      onChange={e => {
        console.log(e)
        const { value } = e.target
        props.onSelection(value)
      }}
      name="name"
      value={props.value ? props.value : ''}
      loadOptions={loadOptions}
      containerStyle={{ width: '100%', height: sizes.eight }}
      controlStyle={{
        borderRadius: 0,
        borderColor: colors.borderLight,
        borderRight: 0,
      }}
      placeholder={props.small ? 'Provider Name' : 'Enter Provider Name'}
      isClearable
      iconLeft={() => {
        if (props.small) {
          return null
        }
        return <SearchSVG width="16px" height="16px" />
      }}
      debounceInput={false}
    />
  )
}

NameInputAutocomplete.propTypes = {
  small: PropTypes.bool,
  s: PropTypes.func,
  onSelection: PropTypes.func,
  value: PropTypes.string,
}
