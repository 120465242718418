import React, { useReducer } from 'react'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import { DataCard } from '../components/DataCard'
import styled from 'styled-components'
import { LabelDropdown } from './components/LabelDropdown'
import { ApiRequestsMenuOptions } from './components/ApiRequestsMenuOptions'
import { UsageChart } from './components/UsageChart'
import { createUsageTestData } from '__mocks__/mockApiLogs'

const OverviewContainer = styled(DataCard)`
  padding: 0;
  margin-top: ${p => p.theme.sizes.six};
`

const OverviewHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: ${p => p.theme.sizes.four} ${p => p.theme.sizes.four} 0;
`

export const ENDPOINTS = [
  '/v1/custom/providers',
  '/v1/custom/locations',
  '/v1/custom/deductible',
]
export const METHODS = ['GET', 'PUT', 'POST', 'DELETE']
export const VIEW_OPTIONS = ['Split by endpoint', 'Split by method']
export const monthOptionLabels = [
  { label: '1mo', value: 1 },
  { label: '3mo', value: 3 },
  { label: '6mo', value: 6 },
  { label: '12mo', value: 12 },
]

const addOrRemoveFromList = (item, list = []) => {
  const listCopy = list.slice()
  const foundEndpointIndex = listCopy.findIndex(i => i === item)
  if (foundEndpointIndex > -1) {
    listCopy.splice(foundEndpointIndex, 1)
  } else {
    listCopy.push(item)
  }
  return listCopy
}

const initialState = {
  endpoints: [],
  methods: [],
  viewOptions: [VIEW_OPTIONS[0]],
  monthsView: monthOptionLabels[0].value,
}

const reducer = (state = initialState, { type, payload }) => {
  const { option } = payload
  switch (type) {
    case 'SELECT':
      return { ...state, ...payload }
    case 'UNSELECT_ALL':
      return { ...state, [option]: [] }
    default:
      return state
  }
}

export const UsageOverview = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { endpoints, methods, viewOptions, monthsView } = state

  const onClickEndpoint = endpoint => {
    const newList = addOrRemoveFromList(endpoint, endpoints)
    dispatch({
      type: 'SELECT',
      payload: {
        endpoints: newList,
      },
    })
  }

  const onClickUnselectAll = type => {
    dispatch({
      type: 'UNSELECT_ALL',
      payload: {
        option: type,
      },
    })
  }

  const onClickMethod = method => {
    const newList = addOrRemoveFromList(method, methods)
    dispatch({
      type: 'SELECT',
      payload: {
        methods: newList,
      },
    })
  }

  const onClickViewOption = viewOption => {
    dispatch({
      type: 'SELECT',
      payload: {
        viewOptions: [viewOption],
      },
    })
  }

  const onClickMonthView = option => {
    dispatch({
      type: 'SELECT',
      payload: {
        monthsView: option,
      },
    })
  }

  const onClickRequestRow = endpoint => {
    dispatch({
      type: 'SELECT',
      payload: {
        endpoints: [endpoint],
        viewOptions: [VIEW_OPTIONS[0]],
      },
    })
  }

  const testData = createUsageTestData(
    viewOptions[0],
    monthsView,
    endpoints,
    methods,
  )
  return (
    <>
      <Label
        s={() => ({
          fontWeight: '600',
          fontSize: '1.5em',
        })}
      >
        Usage Overview
      </Label>
      <OverviewContainer>
        <OverviewHeader>
          <Flex
            s={() => ({
              alignItems: 'center',
            })}
          >
            <Label
              s={p => ({
                color: p.theme.colors.gray1,
                fontWeight: '400',
                fontSize: p.theme.headingSizes.H3,
                marginRight: p.theme.sizes.six,
              })}
            >
              API Requests
            </Label>
            <LabelDropdown
              label="All endpoints"
              renderMenu={() => (
                <ApiRequestsMenuOptions
                  onClick={onClickEndpoint}
                  selected={endpoints}
                  items={ENDPOINTS}
                  onClickUnselectAll={() => onClickUnselectAll('endpoints')}
                />
              )}
            />
            <LabelDropdown
              label="All methods"
              renderMenu={() => (
                <ApiRequestsMenuOptions
                  onClick={onClickMethod}
                  selected={methods}
                  items={METHODS}
                  onClickUnselectAll={() => onClickUnselectAll('methods')}
                />
              )}
            />
          </Flex>
          <Flex
            s={() => ({
              alignItems: 'center',
            })}
          >
            <LabelDropdown
              label="View options"
              renderMenu={() => (
                <ApiRequestsMenuOptions
                  onClick={onClickViewOption}
                  selected={viewOptions}
                  items={VIEW_OPTIONS}
                  type="switch"
                />
              )}
            />
            <MonthOptions selected={monthsView} onClick={onClickMonthView} />
          </Flex>
        </OverviewHeader>

        <UsageChart state={state} data={testData} />

        <RequestList
          state={state}
          data={testData}
          onClickRow={onClickRequestRow}
        />
      </OverviewContainer>
    </>
  )
}

const PathLabel = styled(Label)`
  display: flex;
  align-items: center;
  color: ${p => p.theme.colors.gray0};

  ::before {
    content: '';
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: ${p =>
      p.status === 'success'
        ? p.theme.colors.successGreen
        : p.theme.colors.orange};
    margin-right: ${p => p.theme.sizes.four};
  }
`

const RequestList = ({ state = {}, data = [], onClickRow = () => {} }) => {
  const { endpoints } = state

  const totalRequests = {
    [ENDPOINTS[0]]: {
      requests: 0,
    },
    [ENDPOINTS[1]]: {
      requests: 0,
    },
    [ENDPOINTS[2]]: {
      requests: 0,
    },
  }
  // Add up for total requests from test data
  data.forEach(item => {
    totalRequests[ENDPOINTS[0]].requests +=
      totalRequests[ENDPOINTS[0]].requests + item[ENDPOINTS[0]]
    totalRequests[ENDPOINTS[1]].requests +=
      totalRequests[ENDPOINTS[1]].requests + item[ENDPOINTS[1]]
    totalRequests[ENDPOINTS[2]].requests +=
      totalRequests[ENDPOINTS[2]].requests + item[ENDPOINTS[2]]
  })

  return (
    <Flex
      s={p => ({
        borderTop: `1px solid ${p.theme.colors.gray5}`,
        flexDirection: 'column',
        padding: `${p.theme.sizes.two} 0`,
      })}
    >
      {Object.keys(totalRequests).map(key => {
        const isSelected = endpoints.find(i => i === key)
        return (
          <Flex
            key={key}
            s={p => ({
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: p.theme.sizes.four,
              ...(isSelected && {
                background: p.theme.colors.gray6,
              }),
              [':hover']: {
                background: p.theme.colors.gray6,
                cursor: 'pointer',
              },
            })}
            onClick={() => onClickRow(key)}
          >
            <PathLabel status="success">{key}</PathLabel>
            <Label
              s={p => ({
                color: p.theme.colors.gray2,
              })}
            >
              {`${(
                totalRequests[key].requests || 0
              ).toLocaleString()} requests`}
            </Label>
          </Flex>
        )
      })}
    </Flex>
  )
}

const MonthLabel = styled(Label)`
  padding: 0 12px;
  color: ${p => p.theme.colors.gray2};
  font-size: 14px;
  line-height: 24px;
  border: 1px solid transparent;
  ${p => ({
    ...(p.selected && {
      backgroundColor: p.theme.colors.aquamarine2,
      borderColor: p.theme.colors.aquamarine,
      borderRadius: '20px',
      color: p.theme.colors.gray1,
      fontWeight: '500',
    }),
  })};
  :hover {
    cursor: pointer;
  }
`

const MonthOptions = ({ selected = '', onClick = () => {} }) => {
  return (
    <Flex
      s={() => ({
        alignItems: 'center',
      })}
    >
      {monthOptionLabels.map(option => {
        return (
          <MonthLabel
            onClick={() => onClick(option.value)}
            selected={option.value === selected}
            key={option.label}
          >
            {option.label}
          </MonthLabel>
        )
      })}
    </Flex>
  )
}
