import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { Icon } from 'design-system/icon'
import { BuildingIconContent } from 'design-system/icon/icons/building'
import { SelectInput } from 'design-system/input/select-input'
import { colors } from 'theme/colors'
import { sizes } from 'theme/sizing'
import { services } from 'services'

const BuildingIcon = () => <Icon size={16}>{BuildingIconContent}</Icon>

export const FacilityTypeInput = ({ small, onSelection, value }) => {
  const { data } = useQuery(
    'location_types',
    () => services.ribbon.getLocationTypes(),
    {
      select: res => {
        if (res && res.data && res.data.length > 0) {
          return res.data.map(item => {
            return {
              label: item.display_name,
              value: item.display_name,
            }
          })
        }
      },
    },
  )

  return (
    <SelectInput
      onChange={e => {
        console.log(e)
        const { value } = e.target
        if (value) {
          onSelection({ id: value, label: value })
        } else {
          onSelection(undefined)
        }
      }}
      name="type"
      value={value ? value.label : ''}
      options={data}
      containerStyle={{ width: '100%', height: sizes.eight }}
      controlStyle={{
        borderRadius: 0,
        borderColor: colors.borderLight,
        borderRight: 0,
      }}
      placeholder={small ? 'Facility Type' : 'Select facility type'}
      isClearable
      iconLeft={(!small && BuildingIcon) || undefined}
    />
  )
}

FacilityTypeInput.propTypes = {
  small: PropTypes.bool,
  onSelection: PropTypes.func,
  value: PropTypes.shape({ id: PropTypes.string, label: PropTypes.string }),
}
