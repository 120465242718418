import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { colors } from 'theme/colors'

export const InfoIndicator = ({ id, message }) => {
  return (
    <Container data-for={id} data-tip={message}>
      <p>i</p>
      <ReactTooltip
        place="bottom"
        effect="solid"
        backgroundColor={colors.gray1}
        className="custom-tooltip"
        id={id}
      />
    </Container>
  )
}

InfoIndicator.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
}
const Container = styled.div`
  height: ${p => p.theme.sizes.four};
  min-height: ${p => p.theme.sizes.four};
  width: ${p => p.theme.sizes.four};
  min-width: ${p => p.theme.sizes.four};
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 ${p => p.theme.sizes.one};
  > p {
    font-size: 10px;
  }
`
