import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Label } from 'design-system/label'
import { useKeyNavigateResults } from 'hooks/useKeyNavigateResults'

const ResultItem = styled.li`
  padding: ${p => p.theme.sizes.three} ${p => p.theme.sizes.four};
  ${p => ({
    ...(p.isSelected
      ? {
          fontWeight: 'bold',
          backgroundColor: p.theme.colors.gray6,
        }
      : {
          [':hover']: {
            backgroundColor: p.onClick
              ? p.theme.colors.gray6
              : p.theme.colors.white,
            cursor: 'pointer',
          },
        }),
  })}

  &:focus {
    background-color: ${p => p.theme.colors.gray6};
    outline: none;
  }
  &:focus-visible {
    background-color: ${p => p.theme.colors.gray6};
    outline: none;
  }
`
const ResultHeader = styled(Label)`
  padding: ${p => p.theme.sizes.three} ${p => p.theme.sizes.four};
  font-weight: bold;
  color: ${p => p.theme.colors.gray2};
`

const SearchResultsContainer = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
`

export const SearchResultsWindow = ({
  results = [],
  onClick,
  selectedResult,
  renderLabel,
}) => {
  useKeyNavigateResults(results, onClick)

  return (
    <SearchResultsContainer
      id="search-results-container"
      data-testid="search-results-container"
    >
      {results.map((result, index) => {
        const { isHeader, uuid, displayName } = result
        const isSelected = selectedResult
          ? selectedResult.uuid === result.uuid
          : false

        if (isHeader) {
          return <ResultHeader key={uuid}>{displayName}</ResultHeader>
        }
        const label = renderLabel ? renderLabel(result) : displayName

        return (
          <ResultItem
            key={uuid}
            id={result.uuid}
            onClick={() => onClick(result)}
            isSelected={isSelected}
            tabIndex={index}
          >
            {label}
          </ResultItem>
        )
      })}
    </SearchResultsContainer>
  )
}

SearchResultsWindow.propTypes = {
  results: PropTypes.array,
  onClick: PropTypes.func,
  selectedResult: PropTypes.object,
  renderLabel: PropTypes.func,
}
