import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Icon} from '../icon'
import CopySVG from 'assets/copy.svg'
import styled from 'styled-components'
import {Flex} from 'design-system/flex'

const Container = styled(Flex)`
	align-items: center;
	.copy-icon {
		margin-left: 5px;
	}
`

const Copy = ({value}) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleClick = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(value);
    } else {
      document.execCommand('copy', true, value);
    }
    notifyIsCopied()
  }

  const notifyIsCopied = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false)
    }, 1500)
  }

  return (
    <Container>
      <div>
        {isCopied ? "Copied!" : value}
      </div>
      <Icon className="copy-icon" size={19} onClick={handleClick}>
        <CopySVG/>
      </Icon>
    </Container>
  )
}

Copy.propTypes = {
  value: PropTypes.string,
}

export default Copy
