/* eslint-disable indent */
import { AlgoliaInsuranceResponse } from 'services/algolia/algolia-search-insurances'
import {
  Insurance,
  InsuranceVerificationApi,
} from 'services/ribbon/ribbon.types'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import STATES from '../../static/constants/states.json'
const STATES_DICT: { [state: string]: string } = STATES

type InsurancePlan = {
  uuid: string
  displayName: string
  carrierName: string
  planName: string
  state: string
  planType: string
  category: string
  codes: string[]
}
type InsurancePlanStateHeader = {
  uuid: string
  displayName: string
  isHeader: boolean
}
type MappedInsurances = Record<string, InsurancePlan[]>

export const transformPlanToInsurancePlan = (
  plan: AlgoliaInsuranceResponse | Insurance,
): InsurancePlan => {
  return {
    uuid: plan.uuid,
    ...('_metadata' in plan
      ? {
        displayName: plan.display_name,
        carrierName: plan.carrier_name,
        planName: plan._metadata.plan_name,
        state: plan._metadata.state,
        planType: plan._metadata.plan_type,
        category: plan._metadata.category,
        codes: plan._metadata.codes,
      }
      : {
        displayName: plan.displayName,
        carrierName: plan.carrierName,
        planName: plan.planName,
        state: plan.state,
        planType: plan.planType,
        category: plan.category,
        codes: plan.codes,
      }),
  }
}

export const transformPlansToInsurancePlans = (
  plans: Array<AlgoliaInsuranceResponse | Insurance>,
): Array<InsurancePlan> => {
  if (!plans) return []
  return plans.map(plan => {
    return transformPlanToInsurancePlan(plan)
  })
}

export const formatInsurancePlansByState = (
  plans: Array<AlgoliaInsuranceResponse | Insurance>,
): Array<InsurancePlanStateHeader | InsurancePlan> => {
  if (!plans) {
    return []
  }
  const sortedResults: Array<InsurancePlanStateHeader | InsurancePlan> = []
  const resultsMap: MappedInsurances = {}
  const nationwideResults: Array<InsurancePlanStateHeader | InsurancePlan> = []

  // TODO: Optimize for less loops
  // Could insert keys sorted & plans sorted after if needed
  plans.forEach(plan => {
    const formattedPlan: InsurancePlan = transformPlanToInsurancePlan(plan)

    if (formattedPlan.state) {
      const lowercasePlanState = formattedPlan.state.toLowerCase()
      const resultValueByKey = resultsMap[lowercasePlanState]
      if (resultValueByKey) {
        resultValueByKey.push(formattedPlan)
      } else {
        resultsMap[lowercasePlanState] = [formattedPlan]
      }
    } else {
      // handle nationwide case
      nationwideResults.push(formattedPlan)
    }
  })

  const sortedKeys = Object.keys(resultsMap).sort()
  sortedKeys.forEach(key => {
    sortedResults.push(
      {
        displayName: STATES_DICT[key.toLowerCase()] || key,
        uuid: key,
        isHeader: true,
      },
      ...resultsMap[key].sort((a, b) => {
        return a.displayName < b.displayName
          ? -1
          : a.displayName > b.displayName
            ? 1
            : 0
      }),
    )
  })

  if (nationwideResults.length > 0) {
    sortedResults.unshift(
      { displayName: NATIONWIDE_KEY, uuid: NATIONWIDE_KEY, isHeader: true },
      ...nationwideResults.sort((a, b) => {
        return a.displayName < b.displayName
          ? -1
          : a.displayName > b.displayName
            ? 1
            : 0
      }),
    )
  }

  return sortedResults
}

type MappedInsurancesByStateAndPlan = {
  [state: string]: {
    header: InsurancePlanStateHeader
    planTypes: {
      HMO: InsurancePlan[]
      PPO: InsurancePlan[]
      EPO: InsurancePlan[]
      Other: InsurancePlan[]
    }
  }
}

export const mapStatePlansByPlanType = (
  mappedPlansByState: MappedInsurances,
): MappedInsurancesByStateAndPlan => {
  const result: MappedInsurancesByStateAndPlan = {}

  Object.keys(mappedPlansByState).forEach(state => {
    const statePlans = mappedPlansByState[state]
    const header = {
      displayName: STATES_DICT[state.toLowerCase()] || state,
      uuid: state,
      isHeader: true,
    }

    result[state] = {
      header,
      planTypes: {
        HMO: [],
        PPO: [],
        EPO: [],
        Other: [],
      },
    }

    statePlans.forEach(plan => {
      let planType: 'Other' | 'HMO' | 'PPO' | 'EPO' = 'Other' // plan.planType === null
      if (plan.planType === 'HMO') {
        planType = 'HMO'
      }
      if (plan.planType === 'PPO') {
        planType = 'PPO'
      }
      if (plan.planType === 'EPO') {
        planType = 'EPO'
      }

      result[state].planTypes[planType].push(plan)
    })
  })
  // console.log(`🚀 => MappedInsurancesByStateAndPlan:result`, result)
  return result
}

interface FilterOption extends InsurancePlanStateHeader {
  planTypes: Record<string, boolean>
}

type FilterOptions = {
  options: FilterOption[]
  HMOcount: number
  PPOcount: number
  EPOcount: number
  OtherCount: number
}

export const getAllFilterOptionsFromPlans = (
  plans: Array<AlgoliaInsuranceResponse | Insurance>,
): FilterOptions => {
  const plansFormattedByState = formatInsurancePlansByState(plans)
  const allFilterOptions: FilterOption[] = plansFormattedByState.reduce(
    (result: FilterOption[], curr) => {
      if ('isHeader' in curr) {
        result.push({ ...curr, planTypes: {} })
      }
      return result
    },
    [],
  )
  const plansMappedByState = mapPlansByState(plansFormattedByState)
  let HMOcount = 0
  let PPOcount = 0
  let EPOcount = 0
  let OtherCount = 0
  allFilterOptions.forEach(filterOption => {
    const plansByFilterOption = plansMappedByState[filterOption.uuid]
    let hasHMO = false
    let hasPPO = false
    let hasEPO = false
    let hasOther = false
    plansByFilterOption.forEach(plan => {
      let planType = 'Other' // plan.planType === null
      if (plan.planType === 'HMO') {
        planType = 'HMO'
        hasHMO = true
      }
      if (plan.planType === 'PPO') {
        planType = 'PPO'
        hasPPO = true
      }
      if (plan.planType === 'EPO') {
        planType = 'EPO'
        hasEPO = true
      }
      // Remained unchanged -> plan.planType is null
      if (planType === 'Other') {
        hasOther = true
      }
      filterOption.planTypes[planType] = true
    })
    // counts are used for checking if all HMO/PPO are checked within results.
    // also if needed to show all-hmo,all-ppo options
    if (hasHMO) HMOcount += 1
    if (hasPPO) PPOcount += 1
    if (hasEPO) EPOcount += 1
    if (hasOther) OtherCount += 1
  })

  return {
    options: allFilterOptions,
    HMOcount,
    PPOcount,
    EPOcount,
    OtherCount,
  }
}

export const mapInsurancesByCarrier = (
  insurances: InsurancePlan[] = [],
): MappedInsurances => {
  const mappedInsurances: MappedInsurances = {}

  insurances
    .sort((a, b) => (a.carrierName > b.carrierName ? 1 : -1))
    .forEach(item => {
      if (mappedInsurances[item.carrierName]) {
        mappedInsurances[item.carrierName].push(item)
      } else {
        mappedInsurances[item.carrierName] = [item]
      }
    })

  return mappedInsurances
}

type MappedInsurancesByVerification = {
  All: InsurancePlan[]
  Verified: InsurancePlan[]
  Reported: InsurancePlan[]
  'No Selection': InsurancePlan[]
}

export const mapInsurancesByVerificationState = (
  insurances: InsurancePlan[] = [],
  verifications: InsuranceVerificationApi,
): MappedInsurancesByVerification => {
  const insurancesCopy = JSON.parse(
    JSON.stringify(insurances),
  ) as InsurancePlan[]

  const result: MappedInsurancesByVerification = {
    All: insurancesCopy,
    Verified: [],
    Reported: [],
    'No Selection': [],
  }
  insurancesCopy.forEach(plan => {
    const verification = verifications[plan.uuid]
    if (!verification) {
      result['No Selection'].push(plan)
    } else {
      if (verification.confidence === 5) {
        result['Verified'].push(plan)
      }
      if (verification.confidence === 1) {
        result['Reported'].push(plan)
      }
    }
  })

  return result
}

const NATIONWIDE_KEY = 'Nationwide / Other'

export const mapPlansByState = (
  plans: Array<InsurancePlanStateHeader | InsurancePlan>,
): MappedInsurances => {
  const map: MappedInsurances = {}
  if (!plans) {
    return map
  }
  plans.forEach(plan => {
    if ('isHeader' in plan) return
    if ('state' in plan && plan.state) {
      const stateLower = plan.state.toLowerCase()
      if (map[stateLower]) {
        map[stateLower].push(plan)
      } else {
        map[stateLower] = [plan]
      }
    } else {
      // handle other - plan.state = null

      if (map[NATIONWIDE_KEY]) {
        map[NATIONWIDE_KEY].push(plan)
      } else {
        map[NATIONWIDE_KEY] = [plan]
      }
    }
  })
  return map
}

function isInsurancePlanStateHeader(
  item: InsurancePlanStateHeader | InsurancePlan | Insurance,
): item is InsurancePlanStateHeader {
  return (item as InsurancePlanStateHeader).isHeader !== undefined
}

export const filterPlansByFilterList = (
  plans: Array<InsurancePlanStateHeader | InsurancePlan | Insurance>,
  filters: Record<string, Record<'HMO' | 'PPO' | 'EPO' | 'Other', boolean>>,
): Array<InsurancePlanStateHeader | InsurancePlan | Insurance> => {
  if (!plans) {
    return []
  }
  if (Object.keys(filters).length === 0) {
    return plans
  }

  return plans.filter(plan => {
    if (isInsurancePlanStateHeader(plan)) {
      if ('isHeader' in plan) {
        // Header for plans
        if (filters[plan.uuid]) {
          return true
        }
      }
    } else {
      let planType: 'Other' | 'HMO' | 'PPO' | 'EPO' = 'Other' // plan.planType === null
      if (plan.planType === 'HMO') {
        planType = 'HMO'
      }
      if (plan.planType === 'PPO') {
        planType = 'PPO'
      }
      if (plan.planType === 'EPO') {
        planType = 'EPO'
      }

      if ('state' in plan && plan.state) {
        const stateLower = plan.state.toLowerCase()
        if (filters[stateLower]) {
          if (filters[stateLower][planType]) {
            return true
          }
        }
      } else {
        // handle other - plan.state = null
        if ('planType' in plan && filters[NATIONWIDE_KEY]) {
          if (filters[NATIONWIDE_KEY][planType]) {
            return true
          }
        }
      }
    }

    return false
  })
}

export const formatDisplayNameByState = (
  item: InsurancePlan | Insurance,
): string => {
  const { displayName, planName, planType, state, category, codes } = item

  if (planName) {
    let planAppend = false
    if (planType) {
      planAppend = true
      if (planName.includes(planType)) {
        planAppend = false
      }
    }
    let formattedPlanName = `${state ? `${state} - ` : ''}${planName}${planAppend ? ` - ${planType}` : ''}`

    // append PBP codes for Medicare plans
    if (category === 'Medicare Advantage') {
      const codesSuffix = codes?.length > 0 ? ` [${codes.join(', ')}]` : ''
      formattedPlanName = `${formattedPlanName}${codesSuffix}`
    }

    return formattedPlanName
  }

  return displayName
}
