import React from 'react'
import { Input, INPUT_SIZE } from 'design-system/input'
import { colors } from 'theme/colors'

export const DetailSearchInput = ({
  placeholder = 'Search',
  value = '',
  onChange = () => {},
  onInputChange = () => {},
}) => {
  return (
    <Input
      size={INPUT_SIZE.EXTRA_LARGE}
      placeholder={placeholder}
      value={[{ label: value }]}
      onChange={onChange}
      onInputChange={onInputChange}
      s={p => ({
        borderRadius: p.theme.sizes.thirteen,
        marginBottom: p.theme.sizes.four,
        backgroundColor: colors.white,
      })}
    />
  )
}
