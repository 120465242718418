import React, { useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { services } from 'services'
import { Icon } from 'design-system/icon'
import {
  findPlaceFromPlaceId,
  reverseGeocode,
} from 'services/google-maps/geocode.service'
import { AsyncSelectInput } from 'design-system/input/async-select-input'
import { sizes } from 'theme/sizing'
import {
  currentLocationOption,
  getCurrentPosition,
  getZipcodeAndStateFromReverseGeocode,
} from 'utils/location'
import { NotyfContext } from 'context/notyf-context'
import { colors } from 'theme/colors'

const LocationPinIcon = () => (
  <Icon size={16}>
    <path
      d="M8.00042 0C5.00627 0 2.57031 2.43592 2.57031 5.43008C2.57031 6.63328 3.27869 8.46313 4.79962 11.1887C5.83748 13.0484 6.87055 14.6192 6.91413 14.6854L7.66082 15.8172C7.7361 15.9313 7.86366 16 8.00042 16C8.13719 16 8.26474 15.9313 8.34003 15.8172L9.08668 14.6855C9.12984 14.62 10.1554 13.0628 11.2012 11.1887C12.7222 8.46325 13.4305 6.63341 13.4305 5.43008C13.4305 2.43592 10.9946 0 8.00042 0ZM10.4907 10.7921C9.45942 12.64 8.45007 14.1729 8.40753 14.2373L8.00042 14.8543L7.59344 14.2374C7.55067 14.1725 6.53358 12.6259 5.51014 10.7921C4.09935 8.26393 3.38401 6.45986 3.38401 5.43008C3.38401 2.8846 5.45491 0.813699 8.00042 0.813699C10.5459 0.813699 12.6168 2.8846 12.6168 5.43008C12.6168 6.45999 11.9015 8.264 10.4907 10.7921Z"
      fill="#4F4C4D"
    />
    <path
      d="M7.99995 2.38544C6.33984 2.38544 4.98926 3.73605 4.98926 5.39612C4.98926 7.05619 6.33984 8.4068 7.99995 8.4068C9.66006 8.4068 11.0106 7.05619 11.0106 5.39612C11.0106 3.73605 9.66006 2.38544 7.99995 2.38544ZM7.99995 7.59311C6.78851 7.59311 5.80296 6.60755 5.80296 5.39612C5.80296 4.18469 6.78851 3.19914 7.99995 3.19914C9.21142 3.19914 10.1969 4.18469 10.1969 5.39612C10.1969 6.60755 9.21142 7.59311 7.99995 7.59311Z"
      fill="#4F4C4D"
    />
  </Icon>
)

export const LocationInput = props => {
  const sessionToken = useRef(null)
  const notyf = useContext(NotyfContext)
  const [loading, setLoading] = React.useState(false)

  const onSelectLocation = async selection => {
    console.log('Selection', selection)
    const { data, value } = selection.target
    if (data) {
      try {
        setLoading(true)
        let resultLocation
        if (data.place_id) {
          const result = await findPlaceFromPlaceId(
            data.place_id,
            sessionToken.current,
          )
          if (result.geometry) {
            const { location } = result.geometry
            resultLocation = {
              position: {
                latitude: location.lat(),
                longitude: location.lng(),
              },
              label: value,
            }
          }
        }

        if (data.current_location) {
          const location = await getCurrentPosition()
          if (location) {
            resultLocation = {
              position: {
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
              },
              label: value,
            }
          }
        }
        if (resultLocation) {
          const geocodedResults = await reverseGeocode({
            lat: resultLocation.position.latitude,
            lng: resultLocation.position.longitude,
          })
          const { zipcode, state } = getZipcodeAndStateFromReverseGeocode(
            geocodedResults,
          )
          resultLocation['zipcode'] = zipcode
          resultLocation['state'] = state
          props.onSelection(resultLocation)
        }

        setLoading(false)
        return
      } catch (error) {
        setLoading(false)
        if (error.message) {
          if (error.code === 1) {
            notyf.error(
              'Permission denied, please allow us access to your location to start searching by your current location.',
            )
          } else {
            notyf.error(error.message)
          }
        } else {
          notyf.error('There was a problem resolving selected location.')
        }
      }
    }
    props.onSelection(undefined)
  }

  const loadOptions = React.useCallback(async (inputValue, callback) => {
    if (inputValue.length > 0) {
      try {
        if (!sessionToken.current) {
          sessionToken.current = new window.google.maps.places.AutocompleteSessionToken()
        }
        const results = await services.gmaps.geocode({
          searchString: inputValue,
          sessionToken: sessionToken.current,
        })
        console.log(`🚀 => loadOptions => results`, results)
        if (results) {
          const filteredResults = results
            ? results.map(result => ({
              id: result.place_id,
              label: result.description,
              value: result.description,
              data: {
                place_id: result.place_id,
              },
            }))
            : []

          if (props.showCurrentLocationOption) {
            filteredResults.unshift(currentLocationOption)
          }

          callback(filteredResults)
        }
        callback([])
      } catch (error) {
        console.log('Search Error: ', error)
        callback([])
      }
    }
    callback([])
  }, [])

  return (
    <AsyncSelectInput
      onChange={onSelectLocation}
      name="location"
      value={props.value ? props.value.label : ''}
      loadOptions={loadOptions}
      containerStyle={{ width: '100%', height: sizes.eight }}
      controlStyle={{
        borderColor: colors.borderLight,
        borderRight: 0,
        ...(props.position === 'left'
          ? {
            borderRadius: `6.25rem 0 0 6.25rem`,
          }
          : {
            borderRadius: 0,
          }),
      }}
      isLoading={loading}
      placeholder={props.small ? 'Location' : 'Enter a location'}
      isClearable
      iconLeft={(!props.small && LocationPinIcon) || undefined}
      defaultOptions={props.defaultOptions}
    />
  )
}

LocationInput.propTypes = {
  small: PropTypes.bool,
  onSelection: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.shape({ id: PropTypes.string, label: PropTypes.string }),
  showCurrentLocationOption: PropTypes.bool,
  defaultOptions: PropTypes.array,
  position: PropTypes.string,
}
