import React, { useContext, useReducer } from 'react'
import { services } from 'services'
import { ProviderSearchOptions } from '../services/ribbon/ribbon.types'


// CONTEXT VALUES
/////////////////
interface FacetFilterContextValues {
  counts: FacetFilterCounts
  getFacetCounts: (facetNames: string[], search: ProviderSearchOptions) => Promise<void>
}

const FacetFilterContext = React.createContext<FacetFilterContextValues>({
  counts: {},
  getFacetCounts: async () => { return },
})


// COUNT FORMATTERS
////////////////////

type FilterCounts = {
  value: string
  count: string
}
interface FacetFilterCounts {
  [facetId: string]: { [filterId: string]: number }
}

const formatOutcomeAndEffiencyCounts = (filterCounts: FilterCounts[]): FacetFilterCounts['facetId'] => {
  const formattedCounts: FacetFilterCounts['facetId'] = {}
  let sum = 0
  for (let i = 5; i >= 0; i -= 1) {
    const countKey = String(i)
    const countWithKey: FilterCounts | undefined = filterCounts.find(c => c.value === countKey)
    sum += countWithKey ? parseInt(countWithKey.count) : 0
    formattedCounts[countKey] = sum
  }
  return formattedCounts
}

const defaultFormatter = (counts: FilterCounts[]) => counts.reduce((formattedCounts: FacetFilterCounts['facetId'], filterCount: FilterCounts) => {
  formattedCounts[filterCount.value] = parseInt(filterCount.count)
  return formattedCounts
}, {})

const FacetCountFormatter: { [facetId: string]: (counts: FilterCounts[]) => FacetFilterCounts['facetId'] } = {
  'min_outcomes_index': formatOutcomeAndEffiencyCounts,
  'min_efficiency_index': formatOutcomeAndEffiencyCounts,
}

// REDUCER
/////////////////
interface AddFacetCounts {
  type: 'add'
  payload: FacetFilterCounts
}

interface RemoveFacetCounts {
  type: 'remove'
  payload: string[]
}

type CountActions = AddFacetCounts | RemoveFacetCounts;

function countReducer(counts: FacetFilterCounts, action: CountActions): FacetFilterCounts {
  switch (action.type) {
    case 'add':
      return { ...counts, ...action.payload }
    case 'remove':
      return Object.entries(counts).reduce((newCounts: FacetFilterCounts, [facetId, facetValueCounts]) => {
        if (!action.payload.includes(facetId)) {
          newCounts[facetId] = facetValueCounts
        }
        return newCounts
      }, {})
    default:
      return counts
  }
}


// STATE/PROVIDER
/////////////////

export function FacetFilterContextProvider({ children }: { children: JSX.Element }): React.ReactNode {
  const [counts, dispatch] = useReducer(countReducer, {})

  const getFacetCounts = async (facets: string[], searchOptions: ProviderSearchOptions) => {
    const removeFacetIds = Object.keys(counts).filter(facetId => !facets.includes(facetId))
    const facetRequests = facets.map(async (facetName) => {
      const optionsWithoutOwnFacet = { ...searchOptions }
      delete optionsWithoutOwnFacet[facetName]
      const facetCountsResp = await services.ribbon.getProviderFacetCounts(facetName, optionsWithoutOwnFacet)
      return { facetName, facetCountsResp }
    })
    const facetCounts = await Promise.all(facetRequests)
    const newCounts: FacetFilterCounts = facetCounts.reduce((counts, { facetName, facetCountsResp }) => {
      const formatter = FacetCountFormatter[facetName] ?? defaultFormatter
      return {
        ...counts,
        [facetName]: formatter(facetCountsResp.data),
      }
    }, {})
    dispatch({ type: 'remove', payload: removeFacetIds })
    dispatch({ type: 'add', payload: newCounts })
  }

  const contextValues: FacetFilterContextValues = {
    counts,
    getFacetCounts,
  }
  return (
    <FacetFilterContext.Provider value={contextValues} >
      {children}
    </FacetFilterContext.Provider>
  )
}

export const useFacetFilterContext = (): FacetFilterContextValues => {
  return useContext(FacetFilterContext)
}
