import React, {
  useCallback,
  useEffect,
  useContext,
  useMemo,
  useState,
} from 'react'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Block } from 'design-system/block'
import {
  GlobalStateContext,
  GLOBAL_CONTEXT_ACTIONS,
} from 'context/global-context-provider'
import { useFacetFilterContext } from 'context/facet-filter-context'
import { AsyncSearchFilter } from 'components/filters/async-search-filter/async-search-filter'
import { services } from 'services'
import { SelectFilter } from 'components/filters/select-filter/select-filter'
import { MultiSelectFilter } from 'components/filters/multi-select-filter/multi-select-filter'
import { AuthStateContext } from 'context/auth-state-context'
import { filterLanguages, searchTypes } from 'utils/constants'
import { DotIndicators } from 'components/dot-indicators/dot-indicators'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { useToggle } from 'hooks/useToggle'
import { MoreFiltersModal } from './more-filters-modal'
import { CustomFilter } from './custom-filter'
import { splitFiltersIntoSections } from 'utils/filters'
import { Flex } from 'design-system/flex'
import { InaccurateInfoIndicator } from 'components/inaccurate-info-indicator/inaccurate-info-indicator'
import PreferredSVG from 'assets/preferred.svg'
import UnpreferredSVG from 'assets/unpreferred.svg'
import * as AlgoliaService from 'services/algolia'
import { debounce } from 'utils/debounce.util'
import { NoProcedureMatchModal } from 'containers/search/components/no-procedure-match-popup'

export const FILTERS = {
  ratings: 'ratings',
  distance: 'distance',
  gender: 'gender',
  languages: 'languages',
  age: 'provider_age',
  ribbon_trusted: 'ribbon_trusted',

  // Module specifics filters
  networks: 'networks',
  focus_areas_clinical_areas: 'focus_areas_clinical_areas',
  focus_areas_locations: 'focus_areas_locations',
  focus_areas_panel_demographics_ages: 'focus_areas_panel_demographics_ages',
  focus_areas_panel_demographics_sexes: 'focus_areas_panel_demographics_sexes',
  procedures: 'procedures',
  care_journey_cost: 'care_journey_cost',
  care_journey_quality: 'care_journey_quality',
  preference: 'preference',
}

const reduceFiltersByEnabledAndActiveModule = (filterOptions, modules) => {
  if (!filterOptions || !modules || filterOptions.length === 0) {
    return []
  }

  const filterOptionsCopy = [...filterOptions]
  Object.keys(modules).forEach(moduleKey => {
    // find filters in modules list
    // some modules have multiple filters associated to them
    const foundFiltersRequiringModules = filterOptionsCopy.filter(fo => {
      return fo.requires_submodules.includes(moduleKey)
    })

    if (foundFiltersRequiringModules.length > -1) {
      // If filter is not enabled - remove from filters list
      // Can probably reduce number of array searches here, with original filter search
      if (!modules[moduleKey].enabled) {
        foundFiltersRequiringModules.forEach(foundFilter => {
          const indexOfFilter = filterOptionsCopy.findIndex(filterOption =>
            filterOption.uuid.includes(foundFilter.uuid),
          )
          if (indexOfFilter > -1) {
            filterOptionsCopy.splice(indexOfFilter, 1)
          }
        })
      }
    }
  })

  // Some filters are default always added(eg. distance) so they are not removed
  // if not found in modules.

  // enabled check for possible enabled/disabling of filters from admin
  // INFO: all are enabled by default currently
  const result = filterOptionsCopy.filter(i => i.enabled)
  return result
}

let noProcedureMatchInput = null

export const SearchFilters = () => {
  const [searchState, update] = useContext(GlobalStateContext)
  const [authState = {}] = useContext(AuthStateContext)
  const { filters: searchFilters, searchType, insurance } = searchState
  const activeFilters = searchFilters[searchType] || {}
  const [showMoreMenu, toggleShowMoreMenu] = useToggle(false)

  const { modules, filters } = authState
  const { modules: providersModules } = modules[searchTypes.providers] || {}
  const { modules: facilitiesModules } = modules[searchTypes.facilities] || {}

  const filtersWithSections = splitFiltersIntoSections(filters)
  const focusAreaType = searchType === 'facilities' ? 'locations' : 'providers'

  const { counts, getFacetCounts } = useFacetFilterContext()

  const [isProcedurePopupOpen, setIsProcedurePopupOpen] = useState(false)
  const handlePopupClose = () => setIsProcedurePopupOpen(false)
  let noProcedureMatch = false

  // Memoizing so filter only runs when modules changes
  // which usually happens once during login/auth only
  // filters are currently a static list and do not change on auth reloads
  const sidebarOptions = useMemo(
    () =>
      searchType === searchTypes.facilities
        ? reduceFiltersByEnabledAndActiveModule(
            filtersWithSections.facilities.sidebar || [],
            facilitiesModules,
          )
        : reduceFiltersByEnabledAndActiveModule(
            filtersWithSections.providers.sidebar || [],
            providersModules,
          ),
    [searchType, facilitiesModules, providersModules, filters],
  )
  const moreOptions = useMemo(
    () =>
      searchType === searchTypes.facilities
        ? reduceFiltersByEnabledAndActiveModule(
            filtersWithSections.facilities.more || [],
            facilitiesModules,
          )
        : reduceFiltersByEnabledAndActiveModule(
            filtersWithSections.providers.more || [],
            providersModules,
          ),
    [searchType, facilitiesModules, providersModules, filters],
  )

  const fetchFacetCounts = useMemo(() => debounce(getFacetCounts, 900), [])
  useEffect(() => {
    const { providersSearchQuery } = searchState
    const facetNames = sidebarOptions
      .filter(
        option => option.has_counts && option.api_parameter && option.enabled,
      )
      .map(o => o.api_parameter)
    fetchFacetCounts(facetNames, providersSearchQuery)
  }, [sidebarOptions, searchState, fetchFacetCounts])

  const handleChange = useCallback(({ key, value }) => {
    update({
      type: GLOBAL_CONTEXT_ACTIONS.UPDATE_FILTERS,
      payload: { key, value },
    })
  }, [])

  const reduceProcedureResults = (results, inputValue) => {
    const formattedResults = results.reduce((acc, next) => {
      acc.push({
        data: { ...next, type: 'procedures' },
        label: next.display,
        value: next.display,
      })

      return acc
    }, [])
    formattedResults.push({
      data: { display: inputValue, type: 'procedureFuzzy' },
      label: `Search for "${inputValue}"`,
      value: inputValue,
    })
    if (formattedResults.length === 1) {
      noProcedureMatchInput = inputValue
      noProcedureMatch = true
    }
    return formattedResults
  }

  const handleAsyncOnSelect = (selected, filterName, isMultiSelect) => {
    if (selected && typeof selected === 'object' && selected.type) {
      const activeOfType = activeFilters[selected.type]
      if (noProcedureMatch && selected.type === 'procedureFuzzy') {
        setIsProcedurePopupOpen(true)
        noProcedureMatch = false
        return
      }
      if (!activeOfType) {
        handleChange({
          key: selected.type,
          value: [selected],
        })
      } else {
        // check if exists
        const foundSelected = activeOfType.find(
          item =>
            item.uuid === selected.uuid || item.display === selected.display,
        )
        // if not exists add to array
        if (!foundSelected) {
          handleChange({
            key: selected.type,
            value: [...activeOfType, selected],
          })
        }
        // do nothing if exists
      }
    } else {
      // use filterName
      const activeOfFilters = activeFilters[filterName]
      if (!activeOfFilters) {
        handleChange({
          key: filterName,
          value: [selected],
        })
      } else {
        if (!isMultiSelect) {
          // Replace current as only one can be selected at a time;
          handleChange({
            key: filterName,
            value: [selected],
          })
        } else {
          const foundSelected = activeOfFilters.find(item => item === selected)
          // if not exists add to array
          if (!foundSelected) {
            activeOfFilters.push(selected)
            handleChange({
              key: filterName,
              value: activeOfFilters,
            })
          }
        }
      }
    }
  }

  const searchClinicalAreasCombined = async (
    inputValue,
    specialties,
    searchType = '',
  ) => {
    const appId = authState.refTypeaheadId
    const apiKey = authState.refTypeaheadKey
    const appName = authState.refTypeaheadName
    const searchTypeFilter = authState.hasSyncingTypeahead
      ? `-${searchType}`
      : ''

    const specialtyIds = new Set(
      specialties.reduce(
        (ids, s) => ids.concat([s.uuid, ...(s.include ?? [])]),
        [],
      ),
    )
    const specialtyIdsFilter = Array.from(specialtyIds).map(
      id => `_metadata.specialties:${id}`,
    )

    const [clinicalAreas, conditions, treatments] = await Promise.all([
      AlgoliaService.searchAlgoliaClinicalAreas({
        appId: appId,
        apiKey: apiKey,
        appName: appName,
        inputValue: inputValue,
        options: {
          hitsPerPage: 100,
          facetFilters: [
            `type:clinical_area${searchTypeFilter}`,
            specialtyIdsFilter,
          ],
        },
      }),
      searchType === 'locations'
        ? Promise.resolve([])
        : AlgoliaService.searchAlgoliaClinicalAreas({
            appId: appId,
            apiKey: apiKey,
            appName: appName,
            inputValue: inputValue,
            options: {
              hitsPerPage: 100,
              facetFilters: ['type:condition', specialtyIdsFilter],
            },
          }),
      AlgoliaService.searchAlgoliaClinicalAreas({
        appId: appId,
        apiKey: apiKey,
        appName: appName,
        inputValue: inputValue,
        options: {
          hitsPerPage: 100,
          facetFilters: [
            `type:treatment${searchTypeFilter}`,
            specialtyIdsFilter,
          ],
        },
      }),
    ])

    const uniqueClinicalAreas = []
    clinicalAreas.forEach(clinicalArea => {
      uniqueClinicalAreas.push({
        uuid: clinicalArea.uuid,
        display: clinicalArea.display_name,
        type: 'clinicalAreas',
        conditions: clinicalArea._metadata.conditions,
        treatments: clinicalArea._metadata.treatments,
      })
    })

    const groupedResults = []

    // Group conditions & treatments with related Clinical Area
    uniqueClinicalAreas.forEach(clinicalArea => {
      const conditionsInThisClinicalArea = conditions.reduce(
        (result, condition) => {
          if (clinicalArea.conditions.find(c => c.uuid === condition.uuid)) {
            result.push({
              data: {
                uuid: condition.uuid,
                type: 'conditions',
                display: condition.display_name,
                has_header: true,
              },
              label: condition.display_name,
              value: condition.display_name,
            })
          }
          return result
        },
        [],
      )
      const treatmentsInThisClinicalArea = treatments.reduce(
        (result, treatment) => {
          if (clinicalArea.treatments.find(c => c.uuid === treatment.uuid)) {
            result.push({
              data: {
                uuid: treatment.uuid,
                type: 'treatments',
                display: treatment.display_name,
                has_header: true,
              },
              label: treatment.display_name,
              value: treatment.display_name,
            })
          }
          return result
        },
        [],
      )

      groupedResults.push(
        {
          data: { ...clinicalArea, is_header: true },
          label: clinicalArea.display,
        },
        { label: 'Conditions', options: conditionsInThisClinicalArea },
        { label: 'Treatments', options: treatmentsInThisClinicalArea },
      )
    })

    const conditionsWithoutClinicalArea = conditions.reduce((result, curr) => {
      if (
        uniqueClinicalAreas.find(ca =>
          ca.conditions.find(caCondition => curr.uuid === caCondition.uuid),
        )
      ) {
        // Has a clinical area - continue
        return result
      } else {
        result.push({
          data: {
            uuid: curr.uuid,
            type: 'conditions',
            display: curr.display_name,
            has_header: true,
          },
          label: curr.display_name,
          value: curr.display_name,
        })
      }
      return result
    }, [])
    const treatmentsWithoutClinicalArea = treatments.reduce((result, curr) => {
      if (
        uniqueClinicalAreas.find(ca =>
          ca.treatments.find(caTreatment => curr.uuid === caTreatment.uuid),
        )
      ) {
        // Has a clinical area - continue
        return result
      } else {
        result.push({
          data: {
            uuid: curr.uuid,
            type: 'treatments',
            display: curr.display_name,
            has_header: true,
          },
          label: curr.display_name,
          value: curr.display_name,
        })
      }
      return result
    }, [])

    if (
      conditionsWithoutClinicalArea.length > 0 ||
      treatmentsWithoutClinicalArea.length > 0
    ) {
      groupedResults.push(
        {
          data: { is_header: true, no_op: true },
          label: 'Other',
        },
        {
          label: 'Conditions',
          options: conditionsWithoutClinicalArea,
        },
        {
          label: 'Treatments',
          options: treatmentsWithoutClinicalArea,
        },
      )
    }

    // console.log('Search Clinical Areas Res groupedResults:>> ', groupedResults)

    return { data: groupedResults }
  }

  const onClickMoreFilters = () => {
    // toggle more modal
    toggleShowMoreMenu(true)
  }

  const renderFilter = (option, index) => {
    const { specialties } = searchState

    // Handle Custom Filters First
    if (option.is_custom) {
      return (
        <CustomFilter
          key={option.uuid}
          filter={option}
          activeFilters={activeFilters}
          onChange={handleChange}
        />
      )
    }
    switch (option.uuid) {
      case FILTERS.ratings:
        return (
          <SelectFilter
            key={option.uuid}
            label="Patient Satisfaction"
            onChange={option =>
              handleChange({ key: 'minRating', value: option.value })
            }
            selected={activeFilters.minRating}
            options={[
              {
                label: '4 stars and up',
                value: '4',
              },
              {
                label: '3 stars and up',
                value: '3',
              },
              {
                label: '2 stars and up',
                value: '2',
              },
              {
                label: '1 star and up',
                value: '1',
              },
            ]}
          />
        )

      case FILTERS.distance:
        return (
          <SelectFilter
            key={option.uuid}
            label="Distance"
            onChange={option =>
              handleChange({ key: 'maxDistance', value: option.value })
            }
            selected={activeFilters.maxDistance}
            options={[
              {
                label: 'Within 5 Miles',
                value: '5',
              },
              {
                label: 'Within 10 Miles',
                value: '10',
              },
              {
                label: 'Within 50 Miles',
                value: '50',
              },
              {
                label: 'Within 100 Miles',
                value: '100',
              },
            ]}
          />
        )
      case FILTERS.gender:
        return (
          <SelectFilter
            key={option.uuid}
            label="Provider Gender"
            onChange={option =>
              handleChange({ key: 'gender', value: option.value })
            }
            selected={activeFilters.gender}
            options={[
              {
                label: 'Male',
                value: 'm',
              },
              {
                label: 'Female',
                value: 'f',
              },
            ]}
          />
        )
      case FILTERS.languages:
        return (
          <AsyncSearchFilter
            key={option.uuid}
            label="Languages"
            searchFunction={inputValue => filterLanguages(inputValue)}
            onSelect={handleAsyncOnSelect}
            // only show languages not selected already
            filterResults={results =>
              results.filter(
                option =>
                  !(activeFilters.languages || []).find(
                    l => l.toLowerCase() === option.value.toLowerCase(),
                  ),
              )
            }
            name="languages"
            placeholder="Search Language"
            multiSelect={false}
          />
        )
      case FILTERS.age:
        return (
          <SelectFilter
            key={option.uuid}
            label="Provider Age"
            onChange={option =>
              handleChange({ key: 'age', value: option.value })
            }
            selected={activeFilters.age}
            options={[
              {
                label: '40 and under',
                value: '0-40',
              },
              {
                label: 'Between 40 and 60',
                value: '40-60',
              },
              {
                label: '60 and over',
                value: '60+',
              },
            ]}
          />
        )
      case FILTERS.preference:
        return (
          <MultiSelectFilter
            key={option.uuid}
            labelComponent={<PreferenceFilterLabel />}
            label="Preference"
            onChange={selected => {
              handleChange({
                key: 'preference',
                value:
                  activeFilters.preference &&
                  activeFilters.preference.value === selected.value
                    ? undefined
                    : selected,
              })
            }}
            options={
              option.filter_config && option.filter_config.options
                ? option.filter_config.options
                : []
            }
            renderOptionLabel={option => option.display}
            selectedOptions={
              activeFilters.preference ? [activeFilters.preference] : []
            }
          />
        )
      case FILTERS.ribbon_trusted:
        return (
          <MultiSelectFilter
            key={option.uuid}
            labelComponent={<ShowInaccurateLabel />}
            label="Include Lower Confidence Locations"
            onChange={value => {
              handleChange({
                key: 'showInaccurate',
                value: activeFilters.showInaccurate ? undefined : value,
              })
            }}
            options={[
              'Expand search results to include information on providers and locations that Ribbon is less confident in',
            ]}
            selectedOptions={
              activeFilters.showInaccurate
                ? [
                    'Expand search results to include information on providers and locations that Ribbon is less confident in',
                  ]
                : []
            }
          />
        )

      case FILTERS.procedures:
        return (
          <div>
            <AsyncSearchFilter
              key={option.uuid}
              label="Procedures"
              searchFunction={inputValue =>
                services.ribbon.searchProcedures(authState.token, {
                  search: inputValue,
                })
              }
              filterResults={(results, inputValue) =>
                results.data
                  ? reduceProcedureResults(results.data, inputValue)
                  : []
              }
              onSelect={handleAsyncOnSelect}
              name="procedures"
              placeholder="Search Procedures"
              multiSelect
            />
            {isProcedurePopupOpen && (
              <NoProcedureMatchModal
                input={noProcedureMatchInput}
                closeModal={handlePopupClose}
              />
            )}
          </div>
        )
      case FILTERS.focus_areas_clinical_areas:
        return (
          <AsyncSearchFilter
            key={option.uuid}
            label={'Provider Focus Areas'}
            searchFunction={input =>
              searchClinicalAreasCombined(input, specialties, focusAreaType)
            }
            onSelect={handleAsyncOnSelect}
            filterResults={results => {
              if (results.data) {
                // data is already formatted in proper select-input form
                // after searching and building grouped result.
                return results.data
              }
              return []
            }}
            name="clinical_area"
            placeholder="Search Focus Areas"
            multiSelect
            debounceInput={false}
          />
        )
      case FILTERS.focus_areas_locations:
        return (
          <AsyncSearchFilter
            key={option.uuid}
            label={'Facility Focus Areas'}
            searchFunction={input =>
              searchClinicalAreasCombined(input, [], focusAreaType)
            }
            onSelect={handleAsyncOnSelect}
            filterResults={results => {
              if (results.data) {
                // data is already formatted in proper select-input form
                // after searching and building grouped result.
                return results.data
              }
              return []
            }}
            name="clinical_area"
            placeholder="Search Focus Areas"
            multiSelect
            debounceInput={false}
          />
        )

      case FILTERS.focus_areas_panel_demographics_ages:
        return (
          <MultiSelectFilter
            key={option.uuid}
            label="Patient Ages Focus"
            onChange={value => {
              let filtersCopy = activeFilters.panelAges
                ? [...activeFilters.panelAges]
                : []

              if (filtersCopy) {
                const foundOption = filtersCopy.find(option => option === value)
                if (foundOption) {
                  filtersCopy = filtersCopy.filter(option => option !== value)
                } else {
                  filtersCopy.push(value)
                }
              }

              handleChange({
                key: 'panelAges',
                value: filtersCopy,
              })
            }}
            options={[
              'Pediatric (0-12)',
              'Adolescent (13-21)',
              'Adult (22-44)',
              'Adult (45-64)',
              'Senior (65 and over)',
            ]}
            selectedOptions={activeFilters.panelAges || []}
          />
        )
      case FILTERS.focus_areas_panel_demographics_sexes:
        return (
          <SelectFilter
            key={option.uuid}
            label="Patient Gender Focus"
            onChange={option =>
              handleChange({ key: 'panelSexes', value: option.value })
            }
            selected={activeFilters.panelSexes}
            options={[
              {
                label: 'Both female and male',
                value: 'Both female and male',
              },
              {
                label: 'Primarily female',
                value: 'Primarily female',
              },
              {
                label: 'Primarily male',
                value: 'Primarily male',
              },
            ]}
          />
        )
      case FILTERS.care_journey_cost:
        return (
          <SelectFilter
            key={option.uuid}
            counts={counts[option.api_parameter]}
            hasCounts={!!option.has_counts}
            label="Relative Cost"
            onChange={option =>
              handleChange({ key: 'min_efficiency_index', value: option.value })
            }
            selected={activeFilters.min_efficiency_index}
            options={[
              {
                label: '$ and below',
                value: '5',
              },
              {
                label: '$$ and below',
                value: '4',
              },
              {
                label: '$$$ and below',
                value: '3',
              },
              {
                label: '$$$$ and below',
                value: '2',
              },
              {
                label: '$$$$$ and below',
                value: '1',
              },
            ]}
            tooltip="A clinician’s relative cost score measures how expensive they are compared to clinicians treating similar types of patients."
          />
        )

      case FILTERS.care_journey_quality:
        return (
          <SelectFilter
            key={option.uuid}
            counts={counts[option.api_parameter]}
            hasCounts={!!option.has_counts}
            label="Relative Quality"
            onChange={option =>
              handleChange({ key: 'min_outcomes_index', value: option.value })
            }
            selected={activeFilters.min_outcomes_index}
            options={[
              {
                label: '•••••',
                value: '5',
              },
              {
                label: '••••',
                value: '4',
              },
              {
                label: '•••',
                value: '3',
              },
              {
                label: '••',
                value: '2',
              },
              {
                label: '•',
                value: '1',
              },
            ]}
            renderOption={filterOption => {
              const optionCounts = counts[option.api_parameter] ?? {}
              const isDisabled =
                option.has_counts && !optionCounts[filterOption.value]
              return (
                <Flex
                  s={() => ({
                    alignItems: 'center',
                  })}
                >
                  <DotIndicators
                    showEmpty={false}
                    total={Math.round(Number(filterOption.value))}
                  />
                  <Label
                    type={
                      (activeFilters.min_outcomes_index ===
                        filterOption.value &&
                        LABEL_TYPE.BODY1_BOLD) ||
                      LABEL_TYPE.BODY1
                    }
                    s={p => ({
                      color: p.theme.colors.gray1,
                      [':hover']: {
                        fontWeight: isDisabled ? 'initial' : 600,
                        cursor: isDisabled ? 'default' : 'pointer',
                      },
                      ['text-decoration']: isDisabled ? 'line-through' : 'none',
                    })}
                  >
                    and up
                  </Label>
                </Flex>
              )
            }}
            tooltip="A clinician’s relative quality score measures how the clinician’s outcomes compared to those of clinicians treating similar types of patients."
          />
        )
      case FILTERS.networks:
        return showOutOfNetworkFilter ? (
          <MultiSelectFilter
            key={option.uuid}
            label="Network"
            onChange={value => {
              handleChange({
                key: 'outOfNetwork',
                value: activeFilters.outOfNetwork ? undefined : value,
              })
            }}
            options={['Include out of network']}
            selectedOptions={
              activeFilters.outOfNetwork ? [activeFilters.outOfNetwork] : []
            }
          />
        ) : (
          <div key={`empty-${index}`} />
        )
      default:
        return <div key={`empty-${index}`} />
    }
  }

  const showOutOfNetworkFilter = !!insurance

  return (
    <Block
      s={p => ({
        padding: 0,
        paddingTop: p.theme.sizes.seven,
        paddingLeft: '2rem',
        paddingBottom: '2rem',
        lineHeight: p.theme.sizes.five,
        width: '100%',
        maxWidth: '19rem',
        overflow: 'auto',
      })}
    >
      <Block s={p => ({ padding: 0, paddingBottom: p.theme.sizes.four })}>
        <Label
          type={LABEL_TYPE.BODY1}
          s={p => ({
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: p.theme.sizes.five,
          })}
        >
          Filters
        </Label>
      </Block>
      {sidebarOptions.map((option, index) => {
        return renderFilter(option, index)
      })}
      {moreOptions.length > 0 && (
        <Button
          type={BUTTON_TYPE.DEFAULT}
          s={p => ({
            marginTop: p.theme.sizes.four,
            maxWidth: '160px',
          })}
          onClick={onClickMoreFilters}
        >
          More filters
        </Button>
      )}
      {showMoreMenu && (
        <MoreFiltersModal handleClose={() => toggleShowMoreMenu(false)}>
          {moreOptions.map((option, index) => renderFilter(option, index))}
        </MoreFiltersModal>
      )}
    </Block>
  )
}

const ShowInaccurateLabel = () => {
  return (
    <Flex
      s={() => ({
        alignItems: 'center',
      })}
    >
      <Label
        s={p => ({
          ['> svg']: {
            marginLeft: p.theme.sizes.one,
          },
        })}
        type={LABEL_TYPE.BODY1_BOLD}
      >
        Include Lower Confidence Locations
        <InaccurateInfoIndicator
          id="inaccurate-filter"
          message="Ribbon's machine learning models have scored this location's information as potentially inaccurate."
        />
      </Label>
    </Flex>
  )
}

const PreferenceFilterLabel = () => {
  return (
    <Flex
      s={p => ({
        alignItems: 'center',
        ['> svg']: {
          marginLeft: p.theme.sizes.one,
        },
      })}
    >
      <Label type={LABEL_TYPE.BODY1_BOLD}>Preference</Label>
      <PreferredSVG />
      <UnpreferredSVG />
    </Flex>
  )
}

SearchFilters.propTypes = {}
