import React, { useState } from 'react'
import { Table } from 'components/table/table'
import styled from 'styled-components'
import { Flex } from 'design-system/flex'

export const TokensTable = ({
  tableInstance = {},
  onClickRemoveToken = () => {},
}) => {
  const [activeMenu, setActiveMenu] = useState(null)

  const onClickMore = token => {
    console.log(`onClickMore`, token)
    if (token === null) {
      setActiveMenu(null)
    } else {
      setActiveMenu(token.uuid)
    }
  }

  return (
    <StyledTable>
      <Table
        tableInstance={tableInstance}
        loading={false}
        custom={{
          actions: {
            onClickMore,
            onClickRemoveToken,
          },
          state: {
            activeMenu,
          },
        }}
      />
    </StyledTable>
  )
}

const StyledTable = styled(Flex)`
  flex-direction: column;
  > div:first-of-type {
    box-shadow: 0px 4px 16px 0px rgba(28, 29, 31, 0.08);
    background: ${p => p.theme.colors.white};
    border-radius: 5px;
    border: 1px solid ${p => p.theme.colors.gray5};
    tr {
      border: none;
    }
  }
`
