import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { Tag, TAG_TYPE } from 'design-system/tag'
import { Label, LABEL_TYPE } from 'design-system/label'
import {
  GlobalStateContext,
  GLOBAL_CONTEXT_ACTIONS,
} from 'context/global-context-provider'
import { Flex } from 'design-system/flex'
import { displaySummaryLabelByFilterType } from 'utils/filters'

const formatFilters = {
  maxDistance: { display: value => `Within ${value} miles` },
  minRating: {
    display: value =>
      `${value} ${value && value > 1 ? 'stars' : 'star'} and up`,
  },
  ribbonTrusted: { display: () => 'Contact Info Likely Accurate' },
  showInaccurate: { display: () => 'Lower Confidence Locations' },
  outOfNetwork: { display: () => 'Out of Network' },
  gender: {
    display: value => {
      if (value) {
        if (value === 'f') return 'Female'
        if (value === 'm') return 'Male'
      }
      return
    },
  },
  age: {
    display: value => {
      switch (value) {
        case '0-40':
          return '40 and under'
        case '40-60':
          return 'Between 40 and 60'
        case '60+':
          return '60 and over'
      }
    },
  },
  panelAges: {
    display: value => value,
    filterByKey: 'value',
  },
  panelSexes: { display: value => value },
  languages: {
    display: item => item,
    filterByKey: 'value',
  },
  clinicalAreas: {
    display: item => item.display,
    filterByKey: 'uuid',
  },
  conditions: {
    display: item => item.display,
    filterByKey: 'uuid',
  },
  treatments: {
    display: item => item.display,
    filterByKey: 'uuid',
  },
  procedures: {
    display: item => item.display,
    filterByKey: 'uuid',
  },
  procedureFuzzy: {
    display: item => item.display,
    filterByKey: 'value',
  },
  min_efficiency_index: {
    display: value =>
      // Reversing cost -> value of 5 = 1($) and value of 1 = 5($)s
      `Relative Cost: ${'$'.repeat(Math.abs(Math.round(value - 6)))}`,
  },
  min_outcomes_index: {
    display: value => `Relative Quality: ${value} and up`,
  },
  preference: {
    display: item => item.display,
  },
  custom: {
    display: item => item,
    filterByKey: 'value',
  },
  default: {
    display: item => item,
  },
}

export const SearchFilterSummary = () => {
  const [searchState = {}, update = () => {}] = useContext(GlobalStateContext)
  const { filters, searchType } = searchState
  const activeFilters = filters[searchType] || {}
  const handleChange = useCallback(({ key, value }) => {
    update({
      type: GLOBAL_CONTEXT_ACTIONS.UPDATE_FILTERS,
      payload: { key, value },
    })
  }, [])

  // console.log('activeFilters :>> ', activeFilters)
  const keysWithValue = Object.keys(activeFilters).filter(
    key => activeFilters[key],
  )

  // console.log('keysWithValue :>> ', keysWithValue)

  return (
    <Flex
      s={p => ({
        padding: `${p.theme.sizes.two} 0`,
        flexWrap: 'wrap',
      })}
    >
      {keysWithValue.map(key => {
        const isCustom = activeFilters[key].is_custom
        // Could use some cleanup..
        // Handle Custom Filters first - Custom filters are objects
        if (isCustom) {
          const { values, value, field_type } = activeFilters[key]
          const { display } = formatFilters['custom']
          if (values) {
            return values.map(item => (
              <FilterTag
                key={`filter-feedback-${key}-${item}`}
                onClick={() => {
                  // Remove Filter
                  let filteredValues = [...values]
                  // filter by value
                  filteredValues = filteredValues.filter(v => v !== item)
                  return handleChange({
                    key: key,
                    value:
                      filteredValues.length > 0
                        ? {
                            values: filteredValues,
                            is_custom: true,
                            field_type,
                          }
                        : undefined,
                  })
                }}
                label={display(item)}
              />
            ))
          }
          if (value) {
            return (
              <FilterTag
                key={`filter-feedback-${key}`}
                onClick={() => handleChange({ key, value: undefined })}
                label={displaySummaryLabelByFilterType(activeFilters[key])}
              />
            )
          }
        }

        // Handle Arrays in Filters
        if (Array.isArray(activeFilters[key])) {
          const format = formatFilters[key]
          const { filterByKey, display } = format || {}
          // console.log('isArray :>> ', activeFilters[key])

          return activeFilters[key].map(item => (
            <FilterTag
              key={`filter-feedback-${key}-${
                filterByKey === 'value' ? item : item[filterByKey]
              }`}
              onClick={() => {
                // Remove Filter
                let filteredCopy = [...activeFilters[key]]
                if (filterByKey === 'value') {
                  // filter by value
                  filteredCopy = filteredCopy.filter(v => v !== item)
                } else {
                  // filter by key field
                  filteredCopy = filteredCopy.filter(
                    v => v[filterByKey] !== item[filterByKey],
                  )
                }

                const value = filteredCopy.length > 0 ? filteredCopy : undefined
                return handleChange({
                  key: key,
                  value,
                })
              }}
              label={
                display ? display(item) : formatFilters['default'].display(item)
              }
            />
          ))
        }

        return (
          <FilterTag
            key={`filter-feedback-${key}`}
            onClick={() => handleChange({ key, value: undefined })}
            label={
              formatFilters[key]
                ? formatFilters[key].display(activeFilters[key])
                : formatFilters['default'].display(activeFilters[key])
            }
          />
        )
      })}
      {keysWithValue.length > 0 && (
        <Tag
          s={p => ({
            margin: `${p.theme.sizes.two} ${p.theme.sizes.two} 0 0`,
            height: p.theme.sizes.six,
          })}
          type={TAG_TYPE.BORDERLESS}
          onClick={() => update({ type: GLOBAL_CONTEXT_ACTIONS.CLEAR_FILTERS })}
        >
          <Label
            type={LABEL_TYPE.BODY2_BOLD}
            s={p => ({ color: p.theme.colors.aubergine })}
          >
            Clear filters
          </Label>
        </Tag>
      )}
    </Flex>
  )
}

const FilterTag = ({ onClick, label }) => {
  return (
    <Tag
      s={p => ({
        margin: `${p.theme.sizes.two} ${p.theme.sizes.two} 0 0`,
      })}
      onClick={onClick}
      showClearIcon
    >
      <Label type={LABEL_TYPE.BODY2_BOLD}>{label}</Label>
    </Tag>
  )
}

FilterTag.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
}
