import React, { useState } from 'react'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { Flex } from 'design-system/flex'
import { RadioOption } from 'design-system/radio-option/radio-option'

export const StatusFilterDropdown = ({
  onClickApplyFilter = () => {},
  onClickClearFilter = () => {},
  statusFilter = null,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(
    statusFilter ? statusFilter.value : null,
  )
  return (
    <Flex
      s={p => ({
        flexDirection: 'column',
        padding: `0 ${p.theme.sizes.five}`,
      })}
    >
      <RadioOption
        checked={selectedStatus === 'Active'}
        label="Active"
        s={p => ({
          marginBottom: p.theme.sizes.four,
        })}
        onClick={() => setSelectedStatus('Active')}
      />
      <RadioOption
        checked={selectedStatus === 'Expired'}
        label="Expired"
        s={p => ({
          marginBottom: p.theme.sizes.four,
        })}
        onClick={() => setSelectedStatus('Expired')}
      />
      <Flex
        s={p => ({
          justifyContent: 'space-between',
          marginTop: p.theme.sizes.four,
        })}
      >
        <Button
          onClick={() => onClickClearFilter('status')}
          type={BUTTON_TYPE.LINK}
        >
          Clear
        </Button>
        <Button
          onClick={() => onClickApplyFilter('status', selectedStatus)}
          type={!selectedStatus ? BUTTON_TYPE.DISABLED : BUTTON_TYPE.PRIMARY}
          disabled={!selectedStatus}
        >
          Apply
        </Button>
      </Flex>
    </Flex>
  )
}
