import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Block } from 'design-system/block'
import { ProviderSearchResultsPanel } from './provider-search-results-panel'
import { FacilitySearchResultsPanel } from './facility-search-results-panel'
import { ProvidersSearchResultsSummary } from './provider-search-result-summary'
import { FacilitySearchResultsSummary } from './facility-search-result-summary'
import { VirtualProviderSearchResultsPanel } from './virtual-provider-search-results-panel'
import { Pagination } from 'components/pagination/pagination.component'
import { Spinner } from 'design-system/spinner/spinner.component'
import { Button } from 'design-system/button'
import { PermissionRestricted } from 'components/permission-restricted/permission-restricted'
import { GlobalStateContext } from 'context/global-context-provider'
import { searchTypes } from 'utils/constants'
import { useIsFetching, useQueryClient } from 'react-query'
import { searchQueryKeys } from '../useSearchQueries'

export const ResultsSection = ({
  performSearch,
  onHoverResult,
  onClickAddNewFacility,
}) => {
  const queryClient = useQueryClient()
  const [searchState = {}] = useContext(GlobalStateContext)
  const { providersSearchQuery, facilitiesSearchQuery } = searchState
  const providerSearchResults = queryClient.getQueryData(
    searchQueryKeys.providersSearchWithQuery(providersSearchQuery),
  )
  const facilitySearchResults = queryClient.getQueryData(
    searchQueryKeys.facilitiesSearchWithQuery(facilitiesSearchQuery),
  )

  const isLoading = useIsFetching(
    searchState.searchType === searchTypes.providers
      ? searchQueryKeys.providersSearchWithQuery(providersSearchQuery)
      : searchQueryKeys.facilitiesSearchWithQuery(facilitiesSearchQuery),
  )

  return (
    <Block
      s={p => ({
        padding: 0,
        paddingBottom: p.theme.sizes.ten,
        paddingTop: p.theme.sizes.seven,
        paddingRight: p.theme.sizes.six,
        paddingLeft: p.theme.sizes.six,
      })}
    >
      {searchState.searchType === searchTypes.providers ? (
        <>
          <ProvidersSearchResultsSummary />
          {isLoading ? (
            <Spinner label={'Searching providers'} />
          ) : (
            <>
              {providerSearchResults && (
                <>
                  {searchState.providersSearchMode === 'inPerson' && (
                    <ProviderSearchResultsPanel
                      onHover={onHoverResult}
                      results={providerSearchResults.data || []}
                      resultsMetadata={providerSearchResults.metadata || {}}
                    />
                  )}
                  {searchState.providersSearchMode === 'virtualCare' && (
                    <VirtualProviderSearchResultsPanel
                      onHover={onHoverResult}
                      results={providerSearchResults.data || []}
                      resultsMetadata={providerSearchResults.metadata || {}}
                    />
                  )}
                  {providerSearchResults.metadata &&
                    providerSearchResults.metadata.totalCount >
                      providerSearchResults.metadata.pageSize && (
                      <Pagination
                        totalCount={providerSearchResults.metadata.totalCount}
                        pageSize={providerSearchResults.metadata.pageSize}
                        setPage={p => performSearch(p, false)}
                        currentPage={providerSearchResults.metadata.page}
                      />
                    )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <FacilitySearchResultsSummary />

          {isLoading ? (
            <Spinner label={'Searching facilities'} />
          ) : (
            <>
              {facilitySearchResults && (
                <>
                  <FacilitySearchResultsPanel
                    results={facilitySearchResults.data || []}
                    resultsMetadata={facilitySearchResults.metadata || {}}
                    onHover={onHoverResult}
                    onClickAddNewFacility={onClickAddNewFacility}
                  />
                  {facilitySearchResults.metadata &&
                    facilitySearchResults.metadata.totalCount >
                      facilitySearchResults.metadata.pageSize && (
                      <Pagination
                        totalCount={facilitySearchResults.metadata.totalCount}
                        pageSize={facilitySearchResults.metadata.pageSize}
                        setPage={p => performSearch(p)}
                        currentPage={facilitySearchResults.metadata.page}
                      />
                    )}
                  <PermissionRestricted level={2}>
                    {facilitySearchResults.data &&
                      facilitySearchResults.data.length > 0 && (
                        <Button
                          s={() => ({
                            marginTop: '4rem',
                            maxWidth: '14rem',
                          })}
                          onClick={onClickAddNewFacility}
                        >
                          Add a new facility
                        </Button>
                      )}
                  </PermissionRestricted>
                </>
              )}
            </>
          )}
        </>
      )}
    </Block>
  )
}

ResultsSection.propTypes = {
  performSearch: PropTypes.func,
  onHoverResult: PropTypes.func,
  onClickAddNewFacility: PropTypes.func,
}
