import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex } from 'design-system/flex'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { PermissionRestricted } from 'components/permission-restricted/permission-restricted'
import { EditDetailButton } from 'components/edit-detail-btn/edit-detail-btn'
import { ContainsInsuranceIndicator } from 'components/insurances-module/contains-insurance-indicator'
import { Label } from 'design-system/label'
import { Button } from 'design-system/button'
import PlusSVG from 'assets/plus.svg'
import { InsurancesList } from 'components/insurances-module/insurances-list'
import { EDIT_TYPES } from 'utils/constants'
import { useGlobalContext } from 'context/global-context-provider'
import { EditInsurancesModal } from './edit-insurances-modal'
import { useAuthState } from 'context/auth-state-context'
import { searchTypes } from 'utils/constants'
import {isPartnerLocation} from 'utils/location'

const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin: ${p => `${p.theme.sizes.four} 0 ${p.theme.sizes.four} 0`};
`
const fetchTypeModules = (resource_type, modules) => {
  const { modules: parsedModules } = modules[resource_type] || {}
  return parsedModules
}

export const InsurancesContainer = ({
  handleUpdateSuccess,
  provider,
  facility,
  modalType,
  setModalType,
  selectedLocation,
}) => {
  const [searchState] = useGlobalContext()

  const [authState] = useAuthState()
  const { modules } = authState
  const selectedTypeModules = fetchTypeModules(
    provider ? searchTypes.providers : searchTypes.facilities,
    modules,
  )

  const closeModal = async res => {
    if (res && res.refresh) {
      await handleUpdateSuccess(res)
    }

    setModalType()
  }

  const onClickOpenEditInsurances = () => {
    typeof window !== 'undefined' &&
      window.gtag('event', 'click', {
        event_label: `Edit ${provider ? 'Provider' : 'Facility'} Insurances`,
      })
    setModalType(EDIT_TYPES.insurances)
  }

  const { providersSearchQuery, facilitiesSearchQuery } = searchState

  let selectedSearchQuery = null
  if (provider) {
    selectedSearchQuery = providersSearchQuery
  }
  if (facility) {
    selectedSearchQuery = facilitiesSearchQuery
  }
  return (
    <div>
      <Header>
        <Heading
          type={HEADING_TYPE.H2}
          s={p => ({
            color: p.theme.colors.aubergine2,
          })}
        >
          Insurances
        </Heading>
        {selectedTypeModules.insurance_edits?.enabled &&
          !isPartnerLocation(selectedLocation) && (
            <PermissionRestricted level={2}>
              {selectedLocation.insurances &&
              selectedLocation.insurances.length > 0 ? (
                <EditDetailButton
                  label="Edit Insurances"
                  onClick={onClickOpenEditInsurances}
                  size="lg"
                />
              ) : (
                <Button onClick={onClickOpenEditInsurances}>
                  <PlusSVG
                    height={16}
                    width={16}
                    style={{ marginRight: '12px' }}
                  />
                  <Label>Add Insurances</Label>
                </Button>
              )}
            </PermissionRestricted>
          )}
      </Header>
      <ContainsInsuranceIndicator
        searchQuery={selectedSearchQuery}
        selectedLocation={selectedLocation}
      />
      {selectedLocation.insurances && selectedLocation.insurances.length > 0 ? (
        <InsurancesList
          provider={provider}
          facility={facility}
          selectedLocation={selectedLocation}
          handleUpdateSuccess={handleUpdateSuccess}
          moduleEnabled={
            selectedTypeModules.insurance_edits?.enabled &&
            !isPartnerLocation(selectedLocation)
          }
        />
      ) : (
        <>
          <Label>There are no reported insurances at this location.</Label>
        </>
      )}

      {modalType === EDIT_TYPES.insurances && (
        <EditInsurancesModal
          allLocations={provider ? provider.locations : []}
          selectedLocation={selectedLocation}
          closeModal={closeModal}
          provider={provider}
          facility={facility}
        />
      )}
    </div>
  )
}

InsurancesContainer.displayName = 'InsurancesContainer'

InsurancesContainer.propTypes = {
  handleUpdateSuccess: PropTypes.func,
  provider: PropTypes.object,
  facility: PropTypes.object,
  selectedLocation: PropTypes.object,
  modalType: PropTypes.string,
  setModalType: PropTypes.func,
}
