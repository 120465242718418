import React, { useContext, useReducer } from 'react'
import styled from 'styled-components'
import queryString from 'query-string'
import { Flex } from 'design-system/flex'
import { DATA_SEARCH_OPTIONS } from 'utils/constants'
import { SelectInput } from 'design-system/input/select-input'
import { theme } from 'theme'
import { Button, BUTTON_TYPE } from 'design-system/button'
import SearchSVG from 'assets/developer-dashboard/search.svg'
import { Label } from 'design-system/label'
import { ParameterSelect } from './ParameterSelect'
import { ParameterValue } from './ParameterValue'
import { useQuery } from 'react-query'
import { services } from 'services'
import { AuthStateContext } from 'context/auth-state-context'
import { DataResults } from './DataResults'
import ReactTooltip from 'react-tooltip'
import { colors } from 'theme/colors'

const Container = styled(Flex)`
  flex-direction: column;
`

const newParam = { selectedParam: null, value: '' }

const searchReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'UPDATE_TYPE':
      return {
        ...state,
        type: payload,
        params: [{ ...newParam }],
      }
    case 'ADD_PARAM':
      return {
        ...state,
        params: [...state.params, { ...newParam }],
      }
    case 'REMOVE_PARAM':
      return {
        ...state,
        params: payload,
      }

    case 'SET_PARAM':
      return {
        ...state,
        params: payload,
      }

    default:
      break
  }
  return state
}

const initialState = {
  type: DATA_SEARCH_OPTIONS[0],
  params: [newParam],
}

export const DataSearch = () => {
  const [authState] = useContext(AuthStateContext)
  const [state, dispatch] = useReducer(searchReducer, initialState)
  const { type, params } = state

  const queryParams = {}

  params.forEach(element => {
    if (element.selectedParam && element.selectedParam.value) {
      queryParams[element.selectedParam.value] = element.value
    }
  })

  console.log(`🚀 => DataSearch => queryParams`, queryParams)

  const {
    data,
    isFetching: isLoading,
    refetch: searchQuery,
    remove: clearSearch,
  } = useQuery(
    `dataQuery-${type}`,
    () =>
      services.ribbon.developerSearchData(
        authState.token,
        type.value,
        queryString.stringify(queryParams),
      ),
    {
      enabled: false,
      onSuccess: res => {
        console.log(`🚀 => dataQuery => res`, res)
      },
    },
  )

  const onTypeSelect = type => {
    console.log(`🚀 => onTypeSelect => type`, type)
    dispatch({ type: 'UPDATE_TYPE', payload: type })
    clearSearch()
  }

  const onParamSelect = (index, selectedParam) => {
    console.log(
      `🚀 => onParamSelect => index, selectedParam`,
      index,
      selectedParam,
    )

    if (selectedParam.value === 'npis') {
      dispatch({ type: 'SET_PARAM', payload: [{ selectedParam, value: '' }] })
    } else {
      const newParams = JSON.parse(JSON.stringify(params))
      newParams[index].selectedParam = selectedParam
      newParams[index].value = ''
      dispatch({ type: 'SET_PARAM', payload: newParams })
    }
  }

  const onChangeParamValue = (index, e) => {
    const { value } = e.target
    const newParams = JSON.parse(JSON.stringify(params))
    newParams[index].value = value
    dispatch({ type: 'SET_PARAM', payload: newParams })
  }

  const onClickAddParam = () => {
    dispatch({ type: 'ADD_PARAM' })
  }

  const onClickRemoveParam = index => {
    console.log(`🚀 => DataSearch => index`, index)
    const newParams = JSON.parse(JSON.stringify(params))
    newParams.splice(index, 1)
    dispatch({ type: 'REMOVE_PARAM', payload: newParams })
  }

  const onClickSearch = () => {
    if (Object.keys(queryParams).length > 0) {
      searchQuery()
    }
  }

  const addParamDisabled = Object.keys(queryParams).some(key => key === 'npis')

  return (
    <Container>
      <Flex>
        <SelectInput
          onChange={e => {
            console.log(e)
            const { value, label } = e.target
            if (value) {
              onTypeSelect({ value, label: label })
            } else {
              onTypeSelect(undefined)
            }
          }}
          name="type"
          value={type ? type.label : ''}
          options={DATA_SEARCH_OPTIONS}
          containerStyle={{
            width: '160px',
          }}
          controlStyle={{
            borderRadius: 0,
            borderTopLeftRadius: '100px',
            borderBottomLeftRadius: '100px',
            border: `1px solid ${theme.colors.borderLight}`,
            borderRight: 0,
            backgroundColor: theme.colors.white,
            ':hover': {
              borderColor: theme.colors.borderLight,
              cursor: 'text',
            },
          }}
          isClearable={false}
        />
        <Flex
          s={() => ({
            flexDirection: 'column',
          })}
        >
          {params.map((param, index) => {
            return (
              <ParameterSelect
                key={index}
                param={param}
                onParamSelect={onParamSelect}
                index={index}
                allParams={params}
                type={type ? type.value : DATA_SEARCH_OPTIONS[0].value}
              />
            )
          })}
        </Flex>
        <Flex
          s={() => ({
            flexDirection: 'column',
            flex: 1,
          })}
        >
          {params.map((param, index) => {
            return (
              <ParameterValue
                key={index}
                index={index}
                param={param}
                onChangeParamValue={onChangeParamValue}
              />
            )
          })}
          <Flex
            s={() => ({
              justifyContent: 'flex-end',
            })}
          >
            <Button
              type={BUTTON_TYPE.LINK}
              s={p => ({
                marginTop: p.theme.sizes.two,
                ['> button']: {
                  padding: `0 ${p.theme.sizes.two}`,
                },
              })}
              onClick={onClickAddParam}
              disabled={addParamDisabled}
              tooltip={
                addParamDisabled
                  ? `Parameters cannot be added when searching by NPI. To add a paramter, remove 'npis' as your search criteria.`
                  : ''
              }
            >
              + Add Param
            </Button>
          </Flex>
        </Flex>
        <Flex
          s={() => ({
            flexDirection: 'column',
          })}
        >
          <Button
            type={BUTTON_TYPE.PRIMARY}
            s={p => ({
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              ['svg > path']: {
                fill: p.theme.colors.white,
              },
            })}
            disabled={Object.keys(queryParams).length === 0}
            onClick={onClickSearch}
          >
            <SearchSVG />
          </Button>
          {params.length > 1 &&
            params.map((param, index) => {
              if (index === 0) {
                return null
              }
              return (
                <Button
                  type={BUTTON_TYPE.LINK}
                  s={() => ({
                    lineHeight: '40px',
                  })}
                  key={index}
                  onClick={() => onClickRemoveParam(index)}
                >
                  -
                </Button>
              )
            })}
        </Flex>
      </Flex>
      {type && (
        <Label
          s={p => ({
            color: p.theme.colors.gray1,
            marginTop: p.theme.sizes.four,
            wordWrap: 'break-word',
          })}
        >
          {`/v1/custom/${type.value}?${queryString.stringify(queryParams)}`}
        </Label>
      )}

      <DataResults results={data} isLoading={isLoading} />

      <ReactTooltip
        place="bottom"
        effect="solid"
        backgroundColor={colors.gray1}
      />
    </Container>
  )
}
