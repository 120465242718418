import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { ResultCard } from './ResultCard'
import { Spinner } from 'design-system/spinner'

export const DataResults = ({ results, isLoading = false }) => {
  const [forceOpen, setForceOpen] = useState(false)
  if (!results && !isLoading) {
    return null
  }

  console.log(`🚀 => DataResults => results`, results)
  const { parameters, data } = results || {}

  const hasPages = parameters && parameters.page
  const hasResults = parameters && parameters.total_count > 0
  const from = hasPages ? (parameters.page - 1) * parameters.page_size + 1 : 0
  const to = hasPages
    ? Math.min(from + parameters.page_size - 1, parameters.total_count)
    : 0

  const toggleForceOpen = () => {
    setForceOpen(prev => !prev)
  }

  return (
    <Flex
      s={p => ({
        marginTop: p.theme.sizes.six,
        flex: 1,
        flexDirection: 'column',
      })}
    >
      <Flex
        s={() => ({
          justifyContent: 'space-between',
          alignItems: 'center',
        })}
      >
        <Label
          s={p => ({
            color: p.theme.colors.gray1,
          })}
        >
          {isLoading
            ? `-- records found`
            : `${
                hasPages && hasResults
                  ? `${from} - ${to} of ${parameters.total_count.toLocaleString()}`
                  : `${parameters.total_count}`
              } records found`}
        </Label>
        {!isLoading && hasResults && (
          <Flex
            s={() => ({
              justifyContent: 'space-between',
              alignItems: 'center',
            })}
          >
            <Button
              type={BUTTON_TYPE.LINK}
              s={p => ({
                marginRight: p.theme.sizes.two,
              })}
              onClick={toggleForceOpen}
            >
              {`${forceOpen ? 'Collapse' : 'Expand'} all records`}
            </Button>
            <Button type={BUTTON_TYPE.PRIMARY}>Export as CSV</Button>
          </Flex>
        )}
      </Flex>
      {isLoading ? (
        <Spinner />
      ) : (
        <MemoizedResults data={data} forceOpen={forceOpen} />
      )}
    </Flex>
  )
}

const MemoizedResults = React.memo(({ data, forceOpen }) => {
  return (
    <>
      {data.map((result, index) => {
        return (
          <ResultCard
            key={index}
            result={result}
            index={index}
            forceOpen={forceOpen}
          />
        )
      })}
    </>
  )
})

MemoizedResults.displayName = 'MemoizedResults'
MemoizedResults.propTypes = {
  data: PropTypes.array,
  forceOpen: PropTypes.bool,
}

DataResults.propTypes = {
  results: PropTypes.object,
  isLoading: PropTypes.bool,
}
