import { Flex } from 'design-system/flex'
import styled from 'styled-components'

export const DataCard = styled(Flex)`
  flex-direction: column;
  box-shadow: 0px 4px 16px 0px rgba(28, 29, 31, 0.08);
  padding: ${p => p.theme.sizes.four};
  background: ${p => p.theme.colors.white};
  border-radius: 5px;
  border: 1px solid ${p => p.theme.colors.gray5};
`
