import React, { useCallback } from 'react'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Input, INPUT_TYPE } from 'design-system/input'
import { Block } from 'design-system/block'
import { Flex } from 'design-system/flex'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Button } from 'design-system/button'
import { formatPhoneNumber } from 'utils/formatters/format-phone-number'
import PlusSVG from 'assets/plus.svg'
import MinusSVG from 'assets/minus.svg'

const newPhone = {
  phone: '',
}

const newFax = {
  fax: '',
}

export const LocationContactInfo = ({
  editLocation = {},
  setEditLocation = () => {},
  forwardRef = null,
}) => {
  const { phoneNumbers = [], faxes = [] } = editLocation

  const onClickRemovePhone = useCallback((index, type) => {
    setEditLocation(prev => {
      if (type === 'phone') {
        const { phoneNumbers = [] } = prev
        const phoneNumbersCopy = phoneNumbers.slice()
        phoneNumbersCopy.splice(index, 1)
        return {
          ...prev,
          phoneNumbers: phoneNumbersCopy,
        }
      }
      if (type === 'fax') {
        const { faxes = [] } = prev
        const faxesCopy = faxes.slice()
        faxesCopy.splice(index, 1)
        return {
          ...prev,
          faxes: faxesCopy,
        }
      }
      return prev
    })
  }, [])

  const onClickAddPhoneNumber = useCallback(type => {
    setEditLocation(prev => {
      if (type === 'phone') {
        const { phoneNumbers = [] } = prev
        const phoneNumbersCopy = phoneNumbers.slice()
        phoneNumbersCopy.push({ ...newPhone })
        return {
          ...prev,
          phoneNumbers: phoneNumbersCopy,
        }
      }
      if (type === 'fax') {
        const { faxes = [] } = prev
        const faxesCopy = faxes.slice()
        faxesCopy.push({ ...newFax })
        return {
          ...prev,
          faxes: faxesCopy,
        }
      }
      return prev
    })
  }, [])

  const onChangeText = useCallback((index, value, type) => {
    setEditLocation(prev => {
      if (type === 'phone') {
        const { phoneNumbers = [] } = prev
        const phoneNumbersCopy = phoneNumbers.slice()
        phoneNumbersCopy[index].phone = value
        return {
          ...prev,
          phoneNumbers: phoneNumbersCopy,
        }
      }
      if (type === 'fax') {
        const { faxes = [] } = prev
        const faxesCopy = faxes.slice()
        faxesCopy[index].fax = value
        return {
          ...prev,
          faxes: faxesCopy,
        }
      }
    })
  }, [])

  return (
    <Block ref={forwardRef}>
      <Heading type={HEADING_TYPE.H2}>Contact Information</Heading>
      <Flex
        s={p => ({ flexDirection: 'column', marginTop: p.theme.sizes.six })}
      >
        <Label type={LABEL_TYPE.BODY1_BOLD}>Phone Numbers</Label>
        <Flex
          s={() => ({
            flexDirection: 'column',
          })}
        >
          {phoneNumbers.map((item, index) => {
            return (
              <PhoneNumberRow
                key={`phone-${index}`}
                item={item}
                onClickRemove={onClickRemovePhone}
                onChangeText={onChangeText}
                index={index}
                type="phone"
              />
            )
          })}
        </Flex>

        <Button
          onClick={() => onClickAddPhoneNumber('phone')}
          s={p => ({
            color: p.theme.colors.aubergine,
            marginTop: p.theme.sizes.six,
            alignSelf: 'flex-start',
          })}
        >
          <PlusSVG height={16} width={16} />
          <Label
            type={LABEL_TYPE.BODY1}
            s={p => ({
              color: p.theme.colors.aubergine,
              marginLeft: p.theme.sizes.three,
            })}
          >
            {`${
              phoneNumbers.length > 0
                ? 'Add another phone number'
                : 'Add phone number'
            }`}
          </Label>
        </Button>
      </Flex>
      <Flex
        s={p => ({ flexDirection: 'column', marginTop: p.theme.sizes.six })}
      >
        <Label type={LABEL_TYPE.BODY1_BOLD}>Fax Numbers</Label>
        <Flex
          s={() => ({
            flexDirection: 'column',
          })}
        >
          {faxes.map((item, index) => {
            return (
              <PhoneNumberRow
                key={`fax-${index}`}
                item={item}
                onClickRemove={onClickRemovePhone}
                onChangeText={onChangeText}
                index={index}
                type="fax"
              />
            )
          })}
        </Flex>

        <Button
          onClick={() => onClickAddPhoneNumber('fax')}
          s={p => ({
            color: p.theme.colors.aubergine,
            marginTop: p.theme.sizes.six,
            alignSelf: 'flex-start',
          })}
        >
          <PlusSVG height={16} width={16} />
          <Label
            type={LABEL_TYPE.BODY1}
            s={p => ({
              color: p.theme.colors.aubergine,
              marginLeft: p.theme.sizes.three,
            })}
          >
            {`${
              faxes.length > 0 ? 'Add another fax number' : 'Add fax number'
            }`}
          </Label>
        </Button>
      </Flex>
    </Block>
  )
}

const PhoneNumberRow = ({
  item = {},
  onClickRemove = () => {},
  onChangeText = undefined,
  index = 0,
  type = 'phone',
}) => {
  const { phone, fax } = item
  const onClickRemoveCallback = useCallback(() => {
    onClickRemove(index, type)
  }, [index, type])

  const onChangeTextCallback = useCallback(
    e => {
      const { value } = e.target
      onChangeText(index, value, type)
    },
    [onChangeText, index, type],
  )

  return (
    <Flex
      s={p => ({
        alignItems: 'center',
        marginTop: p.theme.sizes.four,
      })}
    >
      <Input
        disabled={!onChangeText}
        onInputChange={onChangeTextCallback}
        name="phone"
        value={[{ label: formatPhoneNumber(type === 'fax' ? fax : phone) }]}
        showClearIcon={false}
        type={INPUT_TYPE.ROUND}
        placeholder="(000)-000-0000"
        inputType="phone"
      />
      <Button
        data-testid="remove-phone"
        s={p => ({
          marginLeft: p.theme.sizes.four,
          ['> button']: {
            padding: 0,
            height: '40px',
            width: '40px',
          },
          borderRadius: '50%',
        })}
        onClick={onClickRemoveCallback}
      >
        <MinusSVG height={16} width={16} />
      </Button>
    </Flex>
  )
}
