import React from 'react';
import PropTypes from 'prop-types';
import { Block } from "design-system/block";
import styled from "styled-components";
import { Label, LABEL_TYPE } from 'design-system/label';

const WrapperBlock = styled(Block)`
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 100px;
  padding: 0;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const RedoSearchHereControl = props => {
  return (
    <WrapperBlock>
      <Block onClick={props.onClick} s={() => ({ padding: 0, flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' })}>
        <Label type={LABEL_TYPE.BODY1_BOLD}>Redo search in this area</Label>
      </Block>
    </WrapperBlock>
  );
}

RedoSearchHereControl.propTypes = {
  onClick: PropTypes.func,
}