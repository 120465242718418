import { createContext, useContext } from 'react'
import { Notyf } from 'notyf'
import { spinnerAsString } from 'design-system/spinner/spinnerAsString'

export const NotyfContext = createContext(
  new Notyf({
    // Set your global Notyf configuration here
    duration: 3000,
    position: {
      x: 'center',
      y: 'bottom',
    },
    ripple: true,
    types: [
      {
        type: 'info',
        background: '#fcbf49',
      },
      {
        type: 'updating',
        background: '#55A7D6',
        className: 'notyf__loading',
        icon: spinnerAsString,
      },
    ],
  }),
)

// Example usage

// const notyf = useContext(NotyfContext);
// notyf.success(
//   'Success Message'
// );

/**
 * Docs: https://github.com/caroso1222/notyf/blob/master/recipes/react.md
 * Note: We're purposely not using <NotyfContext.Provider> in the root component.
 * This allows React to inject the default value for every Context consumer. The default value is what we defined in the argument of createContext.
 */

export const useNotyf = () => {
  return useContext(NotyfContext)
}

export const NotyfConsumer = NotyfContext.Consumer
