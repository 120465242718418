import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Block } from 'design-system/block'
import { SearchResultPanel } from 'components/search-result-panel/search-result-panel.component'
import { GlobalStateContext } from 'context/global-context-provider'
import { formatInsuranceLabel } from 'utils/formatters/format-insurance-labels'
import { SearchNoResults } from './search-no-results'
import { searchTypes } from 'utils/constants'

const formatFacilitySubtitle = (props, result) => {
  if (!result || !result.locationTypes) {
    return ''
  }

  const selectedType =
    props.resultsMetadata.locationTypes &&
    props.resultsMetadata.locationTypes.length > 0 &&
    props.resultsMetadata.locationTypes[0]
  const sortedTypes = (result.locationTypes || []).sort(s =>
    s === selectedType ? -1 : 0,
  )

  const facilityTypesLabels = sortedTypes.reduce((acc, curr) => {
    if (acc.length === 1) {
      acc.push(`+${result.locationTypes.length - 1} more`)
    } else if (acc.length === 0) {
      acc.push(curr)
    }
    return acc
  }, [])

  return facilityTypesLabels.join('\n')
}

export const FacilitySearchResultsPanel = props => {
  const [searchState] = useContext(GlobalStateContext)
  return (
    <div>
      {props.results.length === 0 ? (
        <SearchNoResults
          type={searchTypes.facilities}
          onClickAddNewFacility={props.onClickAddNewFacility}
        />
      ) : (
        props.results.map(r => {
          const {
            uuid,
            name,
            ratingsCount = 0,
            ratingsAvg,
            phoneNumbers = [],
            addressDetails,
            distance,
            confidence,
            preference,
            notes,
          } = r
          return (
            <Block
              key={`facility-result-${uuid}`}
              s={p => ({ padding: `${p.theme.sizes.two} 0` })}
            >
              <SearchResultPanel
                id={uuid}
                type={'facilities'}
                title={name}
                subtitle={formatFacilitySubtitle(props, r)}
                address={addressDetails}
                distance={distance}
                phone={
                  (phoneNumbers.length > 0 && phoneNumbers[0].phone) ||
                  undefined
                }
                onHover={props.onHover}
                score={ratingsCount > 0 ? ratingsAvg / 2 : undefined}
                showInsuranceLabel={formatInsuranceLabel(
                  searchState.facilitiesSearchQuery,
                  r,
                )}
                confidence={confidence}
                result={r}
                showHighConfidence={false}
                preference={preference}
                hasNotes={notes && notes.length > 0}
              />
            </Block>
          )
        })
      )}
    </div>
  )
}

FacilitySearchResultsPanel.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object),
  resultsMetadata: PropTypes.object,
  onHover: PropTypes.func,
  onClickAddNewFacility: PropTypes.func,
}
