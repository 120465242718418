/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import { AuthStateContext, AUTH_ACTION_TYPES } from 'context/auth-state-context'
import { PageLoading } from 'containers/page-loading/page-loading'
import usePrevious from 'hooks/usePrevious'
import { services } from 'services'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [authState = {}, dispatch] = useContext(AuthStateContext)
  const { isFetching, token, permissionLevel = 1, modules, fcaUserId, isSsoUser } = authState
  const prevFetching = usePrevious(isFetching)
  const { location = {} } = rest
  const { uid, token: ssoToken } = queryString.parse(location.search)

  useEffect(() => {
    async function initiateLoginFromSsoResult() {
      if (!uid || !ssoToken) {
        return
      }

      // If user is already logged in, do not re-login
      if (token && modules && fcaUserId.toString() === uid) {
        await navigate('/')
        return
      }

      try {
        const result = await services.ribbon.loginSso({
          uid: uid.toString(),
          token: ssoToken.toString()
        })
        if (result.modules && result.token) {
          dispatch({ type: AUTH_ACTION_TYPES.LOGGED_IN, payload: result })
          await navigate('/')
          return
        }

        throw new Error('SSO login failed')
      } catch (e) {
        await navigate('/error')
        return
      }
    }

    initiateLoginFromSsoResult()
  }, [uid, ssoToken, token, modules])

  if (isFetching || (ssoToken && !token)) {
    return <PageLoading />
  }

  if ((!token || !modules) && (!ssoToken || !uid)) {
    console.log(`No token or modules - Navigating to /login`)
    // If we’re not logged in, redirect to the login page.
    if (typeof window !== `undefined`) {
      navigate('/login')
    }

    return null
  }

  // User does not have permission to view this page if not level 3 or higher
  if (
    location.pathname?.includes('/users') &&
    permissionLevel < 3
  ) {
    console.log('Not enough permission to access this page')
    // If we’re not logged in, redirect to the login page.
    if (typeof window !== `undefined`) {
      navigate('/')
    }

    return null
  }

  // Admin only routes are level 4 or higher
  if (
    location.pathname?.includes('/admin') &&
    permissionLevel < 4
  ) {
    console.log('Not enough permission to access this page')
    // If we’re not logged in, redirect to the login page.
    if (typeof window !== `undefined`) {
      navigate('/')
    }

    return null
  }

  // Auto navigate to /admin if refreshing from anywhere but /admin.
  // /admin will be default route on permission level 4
  if (prevFetching && !isFetching && token) {
    if (permissionLevel >= 4) {
      if (location.pathname && !location.pathname.includes('/admin')) {
        console.log('Navigating to /admin')
        navigate('/admin')
      }
    }
  }

  // Cost Estimates can be disabled by Admin Modules
  if (location.pathname?.includes('/cost-estimates')) {
    const { cost_estimate } = modules
    if (!cost_estimate || !cost_estimate.enabled) {
      if (typeof window !== `undefined`) {
        navigate('/')
      }
    }
  }

  // SSO users should not be able to access the change password page
  if (location.pathname?.includes('/change-password') && isSsoUser) {
    navigate('/')
  }

  return <Component {...rest}></Component>
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default PrivateRoute
