import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Label, LABEL_TYPE } from 'design-system/label'
import { ChevronLeftIcon } from 'design-system/icon/icons/chervon-left'
import { Flex } from 'design-system/flex'
import { Button, BUTTON_TYPE } from 'design-system/button'

const Container = styled(Flex)`
  padding: ${p => p.theme.sizes.four} 0;
`

export const BackToSearch = props => {
  return (
    <Container>
      <Button
        type={BUTTON_TYPE.LINK}
        s={p => ({
          marginTop: p.theme.sizes.four,
        })}
        onClick={() => {
          typeof window !== 'undefined' &&
            window.gtag('event', 'click', { event_label: 'Back to Search' })
          props.onBackClick()
        }}
        disabled={props.disabled}
      >
        <ChevronLeftIcon />
        <Label
          type={LABEL_TYPE.BODY1}
          s={p => ({
            color: p.theme.colors.aubergine,
            paddingLeft: p.theme.sizes.two,
            fontWeight: 500,
          })}
        >
          {props.title}
        </Label>
      </Button>
    </Container>
  )
}

BackToSearch.propTypes = {
  onBackClick: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool,
}

BackToSearch.defaultProps = {
  title: 'Back to search results',
}
