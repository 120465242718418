import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Label } from 'design-system/label'
import { Flex } from 'design-system/flex'
import SearchSVG from 'assets/search.svg'

export const SearchSVGBackground = styled(Flex)`
  justify-content: center;
  align-items: center;
  background: ${p => p.theme.colors.gray7};
  height: 150px;
  width: 150px;
  align-self: center;
  border-radius: 50%;
`

export const NoInsuranceSearchResultsContainer = ({ message }) => {
  return (
    <Flex
      s={p => ({
        marginTop: p.theme.sizes.five,
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        maxWidth: 450,
        ['> p']: {
          textAlign: 'center',
        },
      })}
    >
      <SearchSVGBackground>
        <SearchSVG height={65} width={65} />
      </SearchSVGBackground>
      <Label
        s={p => ({
          fontSize: p.theme.headingSizes.H3,
          fontWeight: 'bold',
          marginBottom: p.theme.sizes.four,
          marginTop: p.theme.sizes.four,
        })}
      >
        No Insurances found
      </Label>
      {message && <Label>{message}</Label>}
    </Flex>
  )
}

NoInsuranceSearchResultsContainer.propTypes = {
  message: PropTypes.string,
}
