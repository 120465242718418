import React, { useState, useEffect } from 'react'
import { Modal } from 'design-system/modal'
import { Label } from 'design-system/label'
import { Flex } from 'design-system/flex'
import { Input } from 'design-system/input'
import SearchSVG from 'assets/developer-dashboard/search.svg'
import { Checkbox } from 'design-system/checkbox'

export const FilterByTypeModal = ({
  closeModal = () => {},
  type = '',
  onClickType = () => {},
  selectedTypes = [],
  data = [],
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [filteredData, setFilteredData] = useState(data)

  useEffect(() => {
    setFilteredData(data.filter(i => i.display.includes(searchValue)))
  }, [searchValue])

  const onChangeSearch = e => {
    setSearchValue(e.target.value)
  }

  const onClickTypeCallback = display => {
    onClickType(display, type)
  }
  return (
    <Modal onClose={closeModal} title={`Providers By ${type}`}>
      <Flex
        s={p => ({
          flexDirection: 'column',
          padding: p.theme.sizes.six,
          minHeight: '468px', // 500 max minus padding - so modal doesnt jump on search
        })}
      >
        <Input
          placeholder={`Search by ${type.toLowerCase()} type`}
          value={[{ label: searchValue }]}
          leftIcon={SearchSVG}
          onChange={() => setSearchValue('')}
          onInputChange={onChangeSearch}
          s={p => ({
            borderRadius: p.theme.sizes.thirteen,
            backgroundColor: p.theme.colors.white,
          })}
        />
        <Flex
          s={p => ({
            marginTop: p.theme.sizes.four,
            border: `1px solid ${p.theme.colors.gray5}`,
            borderBottom: 'none',
            flexDirection: 'column',
          })}
        >
          {filteredData.length === 0 && (
            <DataRow option={{ display: 'No results found', value: '' }} />
          )}
          {filteredData.length > 0 && (
            <Flex
              s={p => ({
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: `1px solid ${p.theme.colors.gray5}`,
                padding: `${p.theme.sizes.four} ${p.theme.sizes.four}`,
              })}
            >
              <Checkbox
                s={p => ({
                  marginRight: p.theme.sizes.two,
                })}
                indeterminate={selectedTypes.length > 0}
                value={selectedTypes.length === data.length}
                onClick={() => onClickTypeCallback('all')}
              />
            </Flex>
          )}
          {filteredData.map(option => {
            const isSelected = !!selectedTypes.find(i => i === option.display)
            return (
              <DataRow
                key={option.display}
                option={option}
                isSelected={isSelected}
                onClick={onClickTypeCallback}
              />
            )
          })}
        </Flex>
      </Flex>
    </Modal>
  )
}

const DataRow = ({
  option = { display: 'Display', value: 'Value' },
  isSelected = false,
  onClick = () => {},
}) => {
  return (
    <Flex
      s={p => ({
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${p.theme.colors.gray5}`,
        padding: `${p.theme.sizes.four} ${p.theme.sizes.four}`,
      })}
    >
      <Flex
        s={() => ({
          alignItems: 'center',
        })}
      >
        {option.value && (
          <Checkbox
            s={p => ({
              marginRight: p.theme.sizes.two,
            })}
            value={isSelected}
            onClick={() => onClick(option.display)}
          />
        )}
        <Label
          s={p => ({
            color: p.theme.colors.gray1,
          })}
        >
          {option.display}
        </Label>
      </Flex>
      <Label
        s={p => ({
          color: p.theme.colors.gray2,
        })}
      >
        {option.value !== 'all' ? option.value : ''}
      </Label>
    </Flex>
  )
}
