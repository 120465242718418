import React from 'react'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import { PageContainer } from '../components/PageContainer'
import { PageTitle } from '../components/PageTitle'
import { PageDivider } from '../components/PageDivider'
import QuestionSVG from 'assets/developer-dashboard/question.svg'
import { MetricsCards } from './MetricsCards'
import { UsageOverview } from './UsageOverview'
import { SyncOverview } from './SyncOverview'
import { DataOverview } from './DataOverview'

export const DeveloperDashboard = () => {
  return (
    <PageContainer>
      <Flex
        s={() => ({
          justifyContent: 'space-between',
          flex: 1,
        })}
      >
        <PageTitle>Dashboard</PageTitle>
        <Label
          s={p => ({
            color: p.theme.colors.gray2,
            display: 'flex',
            alignItems: 'center',
            ['> svg']: {
              marginLeft: p.theme.sizes.two,
            },
          })}
        >
          Support <QuestionSVG />
        </Label>
      </Flex>

      <MetricsCards />

      <PageDivider />

      <UsageOverview />

      <PageDivider />

      <DataOverview />

      <PageDivider />

      <SyncOverview />
    </PageContainer>
  )
}
