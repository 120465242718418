import React from 'react'
import PropTypes from 'prop-types'
import { Block } from 'design-system/block'

export const RightDetailColumn = React.forwardRef((props, ref) => {
  return (
    <Block
      ref={ref}
      s={p => ({
        width: '100%',
        boxSizing: 'border-box',
        padding: `${p.theme.sizes.thirteen} ${p.theme.sizes.ten} ${p.theme.sizes.twelve} ${p.theme.sizes.ten}`,
        height: '100%',
        overflowY: 'auto',
        background: p.theme.colors.gray7,
        maxWidth: '62.5rem',
        [`@media (max-width: ${p.theme.flexboxgrid.breakpoints.sm}em)`]: {
          padding: `${p.theme.sizes.thirteen} ${p.theme.sizes.four} ${p.theme.sizes.twelve} ${p.theme.sizes.four}`,
        },
      })}
    >
      {props.children}
    </Block>
  )
})

RightDetailColumn.displayName = 'right-detail-column'

RightDetailColumn.propTypes = {
  children: PropTypes.node,
}
