import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import { useKeyNavigateResults } from 'hooks/useKeyNavigateResults'

const ResultItem = styled.li`
  padding: ${p => p.theme.sizes.three} ${p => p.theme.sizes.four};
  border-radius: 6px;
  ${p => ({
    ...(p.isSelected && {
      color: p.theme.colors.gray9,
      fontWeight: '500',
    }),
  })}
  ${p => ({
    ...(p.isParent && {
      fontWeight: 'bold'
    })
  })}
  ${p => ({
    ...(!p.isParent && {
      paddingLeft: '32px'
    })
  })}

  &:hover {
    background-color: ${p => p.theme.colors.gray6};
    cursor: pointer;
  }
  &:focus {
    background-color: ${p => p.theme.colors.gray6};
    outline: none;
  }
  &:focus-visible {
    background-color: ${p => p.theme.colors.gray6};
    outline: none;
  }
  ${p => ({
    ...(!p.id && {
      pointerEvents: 'none'
    })
  })}
`

const SearchResultsContainer = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  max-height: 285px;
`

const NoResultsContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  padding: ${p => p.theme.sizes.four};
  > p {
    color: ${p => p.theme.colors.gray3};
  }
`

export const SpecialtiesSearchResults = ({
  results,
  onClick,
  selectedResults,
}) => {
  useKeyNavigateResults(results, onClick)

  if (results && results.length === 0) {
    return (
      <NoResultsContainer>
        <Label>No results found, try searching again</Label>
      </NoResultsContainer>
    )
  }

  return (
    <SearchResultsContainer
      id="search-results-container"
      data-testid="search-results-container"
    >
      {results &&
        results.map((result, index) => {
          const { uuid, display_name, isParent } = result
          const isSelected = Boolean(
            selectedResults.find(i => i.uuid === result.uuid),
          )
          return (
            <ResultItem
              key={`${index}-${uuid}` || 'additional-specialties'}
              id={uuid}
              onClick={() => onClick(result)}
              tabIndex={index}
              isSelected={isSelected}
              isParent={isParent}
              data-testid={`${uuid}-result`}
            >
              {display_name}
            </ResultItem>
          )
        })}
    </SearchResultsContainer>
  )
}

SpecialtiesSearchResults.propTypes = {
  results: PropTypes.array,
  selectedResults: PropTypes.array,
  onClick: PropTypes.func,
}
