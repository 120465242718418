import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Block } from 'design-system/block'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Flex } from 'design-system/flex'
import Stethoscope from 'assets/stethoscope-2.svg'
import {SeeOtherLocationsButton} from 'components/locations-panel/see-other-locations-button'
import {getPartnerLocationName, getProviderPhysicalLocations} from 'utils/location'
import {PARTNERS} from 'utils/constants/partners'


const Divider = styled.div`
  ${p => ({
    height: '1px',
    width: '100%',
    backgroundColor: p.theme.colors.borderDefault,
    margin: `${p.theme.sizes.five} 0`,
  })}
`

export const VirtualLocationPanel = ({
  location,
  provider,
  onSeeOtherLocationsClick,
}) => {
  const { partnerId } = location
  const { logo, description, specialty } = PARTNERS[partnerId]

  return (
    <Block
      type={'CARD'}
      s={p => ({
        paddingLeft: p.theme.sizes.four,
        paddingRight: p.theme.sizes.four,
        paddingTop: p.theme.sizes.five,
        paddingBottom: p.theme.sizes.five,
        lineHeight: p.theme.sizes.five,
        maxHeight: '32em',
        overflowY: 'auto',
      })}
    >
      <Label
        type={LABEL_TYPE.BODY1_BOLD}
      >
        {logo && logo({marginRight: '0.5em', verticalAlign: 'middle'})}
        {getPartnerLocationName(location)}
      </Label>
      {description && (
        <Label
          type={LABEL_TYPE.BODY1}
          s={p => ({marginTop: p.theme.sizes.four})}
        >
          {description}
        </Label>
      )}
      {specialty && (
        <Flex
          s={p => ({
            marginTop: p.theme.sizes.five,
          })}
        >
          <Stethoscope width={24} height={24} style={{marginTop: '0.25em', marginRight: '1em'}} />
          <Flex
            s={() => ({
                flexDirection: 'column',
            })}
          >
            <Label
              type={LABEL_TYPE.BODY1_BOLD}
              s={p => ({marginBottom: p.theme.sizes.two})}
            >
              Specialty
            </Label>
            <Label
              type={LABEL_TYPE.BODY1}
            >
              {specialty}
            </Label>
          </Flex>
        </Flex>
      )}
      {getProviderPhysicalLocations(provider).length > 0 && (
        <>
          <Divider />
          <Label
            type={LABEL_TYPE.BODY1_BOLD}
            s={p => ({marginBottom: p.theme.sizes.one})}
          >
            Interested in visiting in-person?
          </Label>
          <Flex
            s={() => ({
              justifyContent: 'space-between',
              alignItems: 'center',
            })}
          >
            <Label
              type={LABEL_TYPE.BODY1}
            >
              {`${provider.firstName ?? ''} ${provider.lastName ?? ''} is also available for in-person visits 
              at other locations.`}
            </Label>
            <SeeOtherLocationsButton
              provider={provider}
              onSeeOtherLocationsClick={onSeeOtherLocationsClick}
            />
          </Flex>
        </>
      )}
    </Block>
  )
}

VirtualLocationPanel.propTypes = {
  location: PropTypes.object,
  onSeeOtherLocationsClick: PropTypes.func,
  provider: PropTypes.object,
}

VirtualLocationPanel.defaultProps = {
  location: {},
}
