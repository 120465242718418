import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Block } from 'design-system/block'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Flex } from 'design-system/flex'
import { PermissionRestricted } from 'components/permission-restricted/permission-restricted'
import { useToggle } from 'hooks/useToggle'
import { FIELD_TYPES_FLAT_LOWER } from 'utils/constants'
import { Chevron } from 'design-system/icon/icons/chervon-left'
import { EditDetailButton } from 'components/edit-detail-btn/edit-detail-btn'
import dayjs from 'utils/dayjs'
import PencilSVG from 'assets/pencil.svg'
import PlusSVG from 'assets/plus.svg'
import { Button, BUTTON_TYPE } from 'design-system/button'

const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${p => p.theme.sizes.four};
`

const ContentContainer = styled(Block)`
  padding: 0;
  ${p => ({
    ...(p.listExpandAll
      ? {}
      : {
          maxHeight: '316px', // about 5.5 items/rows closed
          overflowY: 'auto',
        }),
  })}
`

export const MainSectionField = ({
  field,
  values,
  onClickEditField,
  onClickAddField,
}) => {
  const [listExpandAll, toggleListExpandAll] = useToggle(false)
  const { display_name, object_display_config, list_display_config } = field
  // values is currently either an object or array
  // object if field_type is an object
  const valuesIsArray = Array.isArray(values)
  let valuesLength = 0
  if (values) {
    if (valuesIsArray) {
      valuesLength = values.length
    } else {
      valuesLength = Object.keys(values).length
    }
  }

  const onClickEdit = (item, index) => {
    if (item && index !== undefined) {
      onClickEditField(item, index)
    } else {
      onClickEditField(null)
    }
  }

  let listExpandAllButton = null
  if (
    valuesIsArray &&
    list_display_config &&
    list_display_config.list_type === FIELD_TYPES_FLAT_LOWER.object
  ) {
    listExpandAllButton = (
      <Button
        s={p => ({
          marginRight: p.theme.sizes.four,
        })}
        type={BUTTON_TYPE.LINK}
        onClick={toggleListExpandAll}
      >
        {listExpandAll ? 'Collapse All' : 'Expand All'}
      </Button>
    )
  }

  return (
    <>
      <Header>
        <Heading
          type={HEADING_TYPE.H2}
          s={p => ({
            color: p.theme.colors.aubergine2,
          })}
        >
          {display_name}
        </Heading>
        {valuesLength > 0 && (
          <>
            {valuesIsArray ? (
              <Flex>
                {listExpandAllButton}
                <PermissionRestricted level={2}>
                  <Button onClick={onClickAddField}>
                    <PlusSVG height={16} width={16} />
                    <Label
                      type={LABEL_TYPE.BODY1}
                      s={p => ({
                        marginLeft: p.theme.sizes.three,
                      })}
                    >
                      Add
                    </Label>
                  </Button>
                </PermissionRestricted>
              </Flex>
            ) : (
              <PermissionRestricted level={2}>
                <EditDetailButton
                  onClick={onClickEdit}
                  size="lg"
                  label="Edit field"
                />
              </PermissionRestricted>
            )}
          </>
        )}
      </Header>
      {valuesLength === 0 && (
        <>
          <Label>There are no values for this field.</Label>
          <PermissionRestricted level={2}>
            <Flex
              s={p => ({
                marginTop: p.theme.sizes.four,
              })}
            >
              {valuesIsArray ? (
                <Button onClick={onClickAddField}>
                  <PlusSVG />
                  <Label
                    type={LABEL_TYPE.BODY1}
                    s={p => ({
                      marginLeft: p.theme.sizes.three,
                    })}
                  >
                    Add
                  </Label>
                </Button>
              ) : (
                <EditDetailButton
                  onClick={onClickEdit}
                  size="lg"
                  label="Edit field"
                />
              )}
            </Flex>
          </PermissionRestricted>
        </>
      )}
      {valuesLength > 0 && (
        <>
          {valuesIsArray ? (
            <ContentContainer type={'CARD'} listExpandAll={listExpandAll}>
              <ArrayItems
                values={values}
                displayConfig={list_display_config}
                onClickEditItem={onClickEdit}
                expandAll={listExpandAll}
              />
            </ContentContainer>
          ) : (
            <ContentContainer type={'CARD'}>
              <ObjectKeys
                values={values}
                displayConfig={object_display_config}
              />
            </ContentContainer>
          )}
        </>
      )}
    </>
  )
}

const ArrayItems = ({
  values = [],
  displayConfig = {},
  onClickEditItem = () => {},
  expandAll = false,
}) => {
  const {
    list_type,
    object_display_config,
    boolean_display_config,
  } = displayConfig

  if (!list_type) return <></>

  if (list_type === FIELD_TYPES_FLAT_LOWER.object) {
    return (
      <>
        {values.map((value, valueIndex) => {
          return (
            <ArrayObjectListItem
              value={value}
              key={`item-${valueIndex}`}
              index={valueIndex}
              displayConfig={object_display_config}
              onClickEditItem={onClickEditItem}
              expandAll={expandAll}
            />
          )
        })}
      </>
    )
  }

  return (
    <>
      {values.map((value, valueIndex) => {
        const isLast = valueIndex === values.length - 1
        const display_value = value === true ? 'True' : 'False'
        return (
          <ListItem key={`item-${value}-${valueIndex}`} isLast={isLast}>
            <ListItemHeader>
              <Label
                type={LABEL_TYPE.BODY1_BOLD}
                s={() => ({
                  flex: 1,
                })}
              >
                {/* Render label based on type and display config (boolean config mostly) */}
                {list_type === FIELD_TYPES_FLAT_LOWER.boolean
                  ? boolean_display_config[display_value]
                    ? boolean_display_config[display_value].display
                    : value
                  : value}
              </Label>
              <PermissionRestricted level={2}>
                <ListItemHeaderButton
                  type={BUTTON_TYPE.LINK}
                  onClick={() => onClickEditItem(value, valueIndex)}
                >
                  <PencilSVG width={14} height={14} />
                </ListItemHeaderButton>
              </PermissionRestricted>
            </ListItemHeader>
          </ListItem>
        )
      })}
    </>
  )
}

export const ArrayObjectListItem = ({
  value = {},
  index,
  displayConfig = {},
  onClickEditItem = () => {},
  expandAll = false,
}) => {
  const [expanded, toggleExpand] = useToggle(false)
  const { keys: displayConfigKeys } = displayConfig

  useEffect(() => {
    if (expandAll) {
      toggleExpand(true)
    } else {
      toggleExpand(false)
    }
  }, [expandAll])

  const onClickEdit = event => {
    event.stopPropagation()
    onClickEditItem(value, index)
  }

  const firstKey = Object.keys(value)[0]
  const firstKeyValue = value[firstKey]
  const configObj = (displayConfigKeys || []).find(
    configKey => configKey.name === firstKey,
  )
  return (
    <ListItem
      s={() => ({
        flex: 1,
      })}
    >
      <Flex
        s={() => ({
          flexDirection: 'column',
          flex: 1,
        })}
      >
        <ListItemHeader
          s={() => ({
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1,
          })}
          onClick={toggleExpand}
        >
          <Label
            s={() => ({
              flex: 1,
            })}
            type={LABEL_TYPE.BODY1_BOLD}
          >
            {configObj
              ? renderObjectKeyValue(configObj.type, firstKeyValue)
              : '-'}
          </Label>
          <Flex
            s={() => ({
              alignItems: 'center',
            })}
          >
            <PermissionRestricted level={2}>
              <ListItemHeaderButton
                type={BUTTON_TYPE.LINK}
                s={p => ({
                  marginRight: p.theme.sizes.five,
                })}
                onClick={onClickEdit}
                data-testid="array-object-edit-btn"
              >
                <PencilSVG width={14} height={14} />
              </ListItemHeaderButton>
            </PermissionRestricted>

            <ListItemHeaderButton
              type={BUTTON_TYPE.LINK}
              onClick={event => {
                event.stopPropagation()
                toggleExpand()
              }}
              data-testid="array-object-expand-btn"
            >
              <Chevron direction={expanded ? 'up' : 'down'} />
            </ListItemHeaderButton>
          </Flex>
        </ListItemHeader>
        {expanded && value && (
          <ListItemContent>
            {Object.keys(value).map((key, keyIndex) => {
              const keyValue = value[key]
              const configObj = (displayConfigKeys || []).find(
                configKey => configKey.name === key,
              )
              if (!configObj) {
                return <></>
              }
              return (
                <Flex
                  s={p => ({
                    flexDirection: 'column',
                    paddingLeft: p.theme.sizes.four,
                    marginTop: p.theme.sizes.four,
                  })}
                  key={`key-${keyIndex}`}
                >
                  <Label type={LABEL_TYPE.BODY1_BOLD}>
                    {configObj.display_name}
                  </Label>
                  <Label
                    s={p => ({
                      marginTop: p.theme.sizes.two,
                      color: p.theme.colors.gray0Alpha70,
                    })}
                  >
                    {renderObjectKeyValue(configObj.type, keyValue)}
                  </Label>
                </Flex>
              )
            })}
          </ListItemContent>
        )}
      </Flex>
    </ListItem>
  )
}

ArrayObjectListItem.propTypes = {
  value: PropTypes.object,
  index: PropTypes.number,
  displayConfig: PropTypes.object,
  onClickEditItem: PropTypes.func,
  expandAll: PropTypes.bool,
}

const renderObjectKeyValue = (fieldType, value) => {
  if (!fieldType) return '-'
  switch (fieldType) {
    case FIELD_TYPES_FLAT_LOWER.string:
    case FIELD_TYPES_FLAT_LOWER.integer:
    case FIELD_TYPES_FLAT_LOWER.float:
      return value

    case FIELD_TYPES_FLAT_LOWER.boolean:
      return value !== undefined ? (value === true ? 'True' : 'False') : ''

    case FIELD_TYPES_FLAT_LOWER.date: {
      if (value === null) return '-'

      return dayjs(value).format('L')
    }
    default:
      return ''
  }
}

const ObjectKeys = ({ values = {}, displayConfig = {} }) => {
  const { keys: displayConfigKeys } = displayConfig

  return (
    <>
      {Object.keys(values).map((key, keyIndex) => {
        const isLast = keyIndex === Object.keys(values).length - 1
        const configObj = (displayConfigKeys || []).find(
          configKey => configKey.name === key,
        )
        if (!configObj) {
          return <></>
        }
        return (
          <ListItem key={`item-${key}`} isLast={isLast}>
            <ListItemHeader>
              <Label
                type={LABEL_TYPE.BODY1_BOLD}
                s={() => ({
                  width: '300px',
                })}
              >
                {configObj.display_name}
              </Label>
              <Label type={LABEL_TYPE.BODY1}>
                {renderObjectKeyValue(configObj.type, values[key])}
              </Label>
            </ListItemHeader>
          </ListItem>
        )
      })}
    </>
  )
}

MainSectionField.propTypes = {
  field: PropTypes.object,
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onClickEditField: PropTypes.func,
  onClickAddField: PropTypes.func,
}

const ListItem = styled(Flex)`
  align-items: center;
  border-bottom: ${p =>
    !p.isLast ? `1px solid ${p.theme.colors.borderDefault}` : 0};
`

const ListItemHeader = styled(Flex)`
  padding: ${p => p.theme.sizes.four};
  align-items: center;
  flex: 1;
  ${p => ({
    ...(p.onClick && {
      [':hover']: {
        backgroundColor: p.theme.colors.gray6,
      },
    }),
  })}
`
const ListItemHeaderButton = styled(Button)`
  > button {
    padding: 0;
    height: 24px;
    width: 24px;
  }
  :hover {
    background-color: ${p => p.theme.colors.gray5};
  }
  border-radius: 50%;
`

const ListItemContent = styled(Flex)`
  flex-direction: column;
  padding-bottom: ${p => p.theme.sizes.four};
`
