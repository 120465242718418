export const formatPermissionLevel = (permission: number): string => {
  switch (permission) {
    case 1:
      return 'Viewer'
    case 2:
      return 'Editor'
    case 3:
      return 'Admin'

    default:
      return ''
  }
}
