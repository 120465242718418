import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components';

import { Block } from 'design-system/block';

const UnstyledDropdown = props => {
  return (
    <Block className={props.className} s={props.sContainer}>
      {props.children}
    </Block>
  );
}

UnstyledDropdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  sContainer: PropTypes.func,
}

const DEFAULT = css(props => `
  border: 1px solid ${props.theme.colors.gray6};
  border-radius: 1.25rem;
  padding: ${props.theme.spacing.three};
  box-shadow: 0 ${props.theme.sizes.one} ${props.theme.sizes.one} rgba(211, 210, 210, 0.25);
  background: white;
`);

const styles = {
  DEFAULT,
}

export const Dropdown = styled(UnstyledDropdown)`
  position: absolute;
  color: ${props => props.theme.colors.textLight};
  ${props => styles[props.type]}
  ${props => props.sContainer ? css(props.sContainer(props)) : ''}
`

Dropdown.propTypes = {
  type: PropTypes.oneOf(['DEFAULT']).isRequired,
};

Dropdown.displayName = 'Dropdown'

Dropdown.defaultProps = {
  type: 'DEFAULT',
};
