import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'design-system/modal'
import { Block } from 'design-system/block'
import { Label } from 'design-system/label'
import dayjs from 'utils/dayjs'
import { Flex } from 'design-system/flex'
import FilledArrowSVG from 'assets/developer-dashboard/filled-arrow.svg'
import SearchSVG from 'assets/developer-dashboard/search.svg'
import { Input } from 'design-system/input'

const mockDetails = {
  last_sync: dayjs().format('LLLL'),
  total_updated: '6.3M',
  type: 'Provider',
  details: {
    Age: {
      new_fill_rate: '2.3M out of 6M',
      previous_fill_rate: '2.3M out of 6M',
      percent_change: 65,
    },
    Insurances: {
      new_fill_rate: '2.3M out of 6M',
      previous_fill_rate: '2.3M out of 6M',
      percent_change: 15,
    },
    Confidence: {
      new_fill_rate: '2.3M out of 6M',
      previous_fill_rate: '2.3M out of 6M',
      percent_change: 25,
    },
  },
}

const ContentContainer = styled(Block)`
  padding: ${p => p.theme.sizes.four};
`

const SectionTitle = styled(Label)`
  font-size: 1.125em;
  margin-bottom: ${p => p.theme.sizes.four};
  font-weight: 500;
`

const SectionContainer = styled(Flex)`
  flex-direction: column;
  border: 1px solid ${p => p.theme.colors.gray6};
  border-bottom: none;
  margin-bottom: ${p => p.theme.sizes.six};
`
const CardRow = styled(Flex)`
  padding: ${p => p.theme.sizes.two} ${p => p.theme.sizes.four};
  border-bottom: 1px solid ${p => p.theme.colors.gray6};
`

const CardLabel = styled(Label)`
  min-width: 180px;
  margin-right: ${p => p.theme.sizes.six};
  font-weight: 500;
`

const PercentLabel = styled(Label)`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 2px;
    > path {
      fill: ${p =>
        p.down ? p.theme.colors.errorRed : p.theme.colors.successGreen};
    }
  }
  ${p => p.down && `transform: rotate(180deg)`}
`

export const DetailsModal = ({
  handleClose = () => {},
  type = 'Type',
  details = mockDetails,
}) => {
  return (
    <Modal
      onClose={handleClose}
      title={`${dayjs(details.last_sync).format(
        'LL',
      )} Sync - ${type} Update Details`}
      gridContainerStyle={{ maxWidth: '1200px' }}
    >
      <ContentContainer>
        <SectionContainer>
          <CardRow>
            <CardLabel>Last Sync</CardLabel>
            <Label>{details.last_sync}</Label>
          </CardRow>
          <CardRow>
            <CardLabel>Total Records Updated</CardLabel>
            <Label>{details.total_updated}</Label>
          </CardRow>
        </SectionContainer>
        {type === 'Insurances' ? (
          <InsurancesChange />
        ) : (
          <>
            {Object.keys(details.details).map(key => {
              const section = details.details[key]
              return (
                <Flex
                  key={key}
                  s={() => ({
                    flexDirection: 'column',
                  })}
                >
                  <SectionTitle>{key}</SectionTitle>
                  <SectionContainer>
                    <CardRow>
                      <CardLabel>New Fill Rate</CardLabel>
                      <Label>{section.new_fill_rate}</Label>
                    </CardRow>
                    <CardRow>
                      <CardLabel>Previous Fill Rate</CardLabel>
                      <Label>{section.previous_fill_rate}</Label>
                    </CardRow>
                    <CardRow>
                      <CardLabel>% Change</CardLabel>
                      <PercentLabel>
                        <FilledArrowSVG />
                        {`${section.percent_change}%`}
                      </PercentLabel>
                    </CardRow>
                  </SectionContainer>
                </Flex>
              )
            })}
          </>
        )}
      </ContentContainer>
    </Modal>
  )
}

const PlanLabel = styled(Label)`
  max-width: 320px;
  width: 320px;
  margin-right: ${p => p.theme.sizes.six};
`

const InsurancesChange = () => {
  const [searchValue, setSearchValue] = useState('')

  const onChangeSearch = e => {
    setSearchValue(e.target.value)
  }

  return (
    <Flex
      s={() => ({
        flexDirection: 'column',
      })}
    >
      <Input
        placeholder={`Search by insurance name`}
        value={[{ label: searchValue }]}
        leftIcon={SearchSVG}
        onChange={() => setSearchValue('')}
        onInputChange={onChangeSearch}
        s={p => ({
          borderRadius: p.theme.sizes.thirteen,
          backgroundColor: p.theme.colors.white,
        })}
      />

      <SectionContainer
        s={p => ({
          marginTop: p.theme.sizes.four,
        })}
      >
        <CardRow>
          <CardLabel
            s={() => ({
              width: '320px',
            })}
          >
            Plan Name
          </CardLabel>
          <Label>Provider Coverage</Label>
        </CardRow>
        <CardRow
          s={() => ({
            alignItems: 'flex-start',
          })}
        >
          <PlanLabel>Aetna NY - Signature</PlanLabel>
          <PercentLabel>
            2.3M (
            <FilledArrowSVG />
            65% )
          </PercentLabel>
        </CardRow>
        <CardRow
          s={() => ({
            alignItems: 'flex-start',
          })}
        >
          <PlanLabel>
            Aetna - Premier Care Network Plus - CHI Health Accountable Care
            Network - Open Access Select
          </PlanLabel>
          <PercentLabel>
            2.3M (
            <FilledArrowSVG />
            65% )
          </PercentLabel>
        </CardRow>
        <CardRow>
          <PlanLabel>Plan name</PlanLabel>
          <PercentLabel>
            ##M (
            <FilledArrowSVG />
            ##% )
          </PercentLabel>
        </CardRow>
        <CardRow>
          <PlanLabel>Plan name</PlanLabel>
          <PercentLabel>
            ##M (
            <FilledArrowSVG />
            ##% )
          </PercentLabel>
        </CardRow>
      </SectionContainer>
    </Flex>
  )
}
