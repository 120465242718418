import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { ClearIndicator, Control, dropdownStyles } from './async-select-input'

export const SelectInput = ({
  value = '',
  name = 'Name',
  onChange = () => {},
  placeholder = '',
  disabled,
  options = [],
  containerStyle = {},
  defaultOptions = [],
  controlStyle = {},
  isClearable,
  iconLeft = null,
}) => {
  const onChangeCallback = useCallback(
    selected => {
      if (!selected) {
        onChange({ target: { name, value: undefined } })
        return
      }
      onChange({ target: { ...selected, name, value: selected.value } })
    },
    [onChange, name],
  )

  const customStyles = dropdownStyles({ containerStyle, controlStyle })
  return (
    <Select
      id={name}
      inputId={`${name}-input`}
      classNamePrefix="react-select"
      name={name}
      // Component changes from uncontrolled to controlled when there is a value
      // Otherwise the placeholder doesn't work?
      value={value && value.length > 0 ? { label: value, name: value } : ''}
      placeholder={placeholder}
      onChange={onChangeCallback}
      styles={customStyles}
      options={options}
      isDisabled={disabled}
      defaultOptions={defaultOptions}
      isClearable={isClearable}
      iconLeft={iconLeft}
      components={{
        ClearIndicator,
        Control,
      }}
    />
  )
}

SelectInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  containerStyle: PropTypes.object,
  controlStyle: PropTypes.object,
  defaultOptions: PropTypes.array,
  isClearable: PropTypes.bool,
  iconLeft: PropTypes.any,
}
