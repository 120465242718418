import React from 'react'
import { Block } from 'design-system/block'
import { Label, LABEL_TYPE } from 'design-system/label'
import { DotIndicators } from 'components/dot-indicators/dot-indicators'
import { CostIndicators } from 'components/cost-indicators/cost-indicators'

export const ProceduresListItem = ({ item = {}, isLast = false }) => {
  return (
    <Block
      type="FLEX"
      s={p => ({
        justifyContent: 'space-between',
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: p.theme.colors.gray4,
        padding: `${p.theme.sizes.four} 0`,
        alignItems: 'center',
        ...(isLast && {
          borderBottomWidth: 0,
          marginBottom: 0,
        }),
      })}
    >
      <Label
        s={() => ({
          flex: 0.45,
        })}
        type={LABEL_TYPE.BODY1_BOLD}
      >
        {item.display}
      </Label>

      <Block
        type={'FLEX'}
        s={() => ({
          flex: 0.3,
          justifyContent: 'center',
        })}
      >
        {item.quality && item.quality.experience_index ? (
          <DotIndicators
            total={Math.round(item.quality.experience_index / 2)}
          />
        ) : (
          <Label
            s={p => ({
              color: p.theme.colors.gray1,
              textAlign: 'center',
            })}
          >
            -
          </Label>
        )}
      </Block>

      <Block
        s={() => ({
          flex: 0.25,
          justifyContent: 'center',
        })}
      >
        {item.cost && item.cost.cost_index ? (
          <CostIndicators total={Math.round(item.cost.cost_index / 2)} />
        ) : (
          <Label
            s={() => ({
              textAlign: 'center',
            })}
          >
            -
          </Label>
        )}
      </Block>
    </Block>
  )
}
