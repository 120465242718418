import React, { useState, useCallback } from 'react'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Row } from 'react-styled-flexboxgrid'
import { Block } from 'design-system/block'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Tag, TAG_SIZE } from 'design-system/tag'
import { SelectInput } from 'design-system/input/select-input'
import { FACILITY_OPTIONS } from 'utils/constants'

export const LocationFacilityType = ({
  editLocation = {},
  setEditLocation = () => {},
}) => {
  const [newType, setNewType] = useState('')
  const { locationTypes = [] } = editLocation

  const onChange = useCallback(e => {
    const { value } = e.target
    setEditLocation(prev => {
      const { locationTypes = [] } = prev
      const typesCopy = locationTypes.slice()

      typesCopy.push(value)
      return {
        ...prev,
        locationTypes: typesCopy,
      }
    })
    setNewType('')
  }, [])

  const onClickTag = useCallback(item => {
    setEditLocation(prev => {
      const { locationTypes = [] } = prev
      const typesCopy = locationTypes.slice()
      const foundTypeIndex = typesCopy.findIndex(i => i === item)
      if (foundTypeIndex > -1) {
        typesCopy.splice(foundTypeIndex, 1)
        return {
          ...prev,
          locationTypes: typesCopy,
        }
      }
      return prev
    })
  }, [])

  const filteredOptions = FACILITY_OPTIONS.filter(
    option =>
      !locationTypes.find(l => l.toLowerCase() === option.value.toLowerCase()),
  )

  return (
    <Block>
      <Heading type={HEADING_TYPE.H2}>Facility Type</Heading>
      <Row style={{ marginBottom: '1rem', marginTop: '1rem' }}>
        {locationTypes.map(item => {
          return (
            <Tag
              size={TAG_SIZE.SMALL}
              key={item}
              onClick={() => onClickTag(item)}
              showClearIcon
              s={p => ({
                marginRight: p.theme.sizes.four,
                marginBottom: p.theme.sizes.two,
              })}
            >
              <Label type={LABEL_TYPE.BODY1}>{item}</Label>
            </Tag>
          )
        })}
      </Row>
      <Row>
        <SelectInput
          onChange={onChange}
          name="newType"
          value={newType}
          options={filteredOptions}
          containerStyle={{ width: '100%' }}
          placeholder="Add a facility type.."
        />
      </Row>
    </Block>
  )
}
