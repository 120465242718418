import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { Block } from 'design-system/block'
import ArrowDown from 'assets/arrow-down.svg'
import { Label } from 'design-system/label'
import { Button, BUTTON_TYPE } from 'design-system/button'
import dayjs from 'utils/dayjs'
import styled from 'styled-components'

const InputLabel = styled(Label)`
  margin-bottom: ${p => p.theme.sizes.two};
  color: ${p => p.theme.colors.gray1};
`
const InputContainer = styled.div`
  margin-bottom: ${p => p.theme.sizes.four};
`

export const DateFilterDropdown = ({
  onClickApplyFilter = () => {},
  onClickClearFilter = () => {},
  logsDateFilter = null,
}) => {
  const [customFilter, setCustomFilter] = useState({
    from: logsDateFilter ? logsDateFilter.value.from : null,
    to: logsDateFilter ? logsDateFilter.value.to : null,
  })

  return (
    <Block
      s={p => ({
        padding: `0 ${p.theme.sizes.five}`,
      })}
    >
      <Label
        s={p => ({
          marginBottom: p.theme.sizes.four,
          color: p.theme.colors.gray0,
        })}
      >
        Between
      </Label>
      <InputContainer>
        <InputLabel>Start Date</InputLabel>
        <DatePicker
          selected={customFilter.from}
          onChange={date => setCustomFilter(prev => ({ ...prev, from: date }))}
          customInput={<CustomDatePickerInput />}
          todayButton="Today"
          maxDate={dayjs().toDate()}
          disabledKeyboardNavigation
        />
      </InputContainer>
      <InputContainer>
        <InputLabel>End Date</InputLabel>
        <DatePicker
          selected={customFilter.to}
          onChange={date => setCustomFilter(prev => ({ ...prev, to: date }))}
          customInput={<CustomDatePickerInput />}
          todayButton="Today"
          maxDate={dayjs().toDate()}
          disabledKeyboardNavigation
        />
      </InputContainer>

      <Block type="FLEX">
        <Button
          onClick={() => onClickApplyFilter('created_at', customFilter)}
          type={
            !customFilter.to || !customFilter.from
              ? BUTTON_TYPE.DISABLED
              : BUTTON_TYPE.PRIMARY
          }
          disabled={!customFilter.to || !customFilter.from}
        >
          Apply
        </Button>
        <Button
          onClick={() => onClickClearFilter('created_at')}
          type={BUTTON_TYPE.LINK}
        >
          Clear
        </Button>
      </Block>
    </Block>
  )
}

const CustomDatePickerInput = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value = null, onClick = () => {} }, ref) => (
    <Block
      ref={ref}
      onClick={onClick}
      type="FLEX"
      s={p => ({
        padding: `${p.theme.sizes.two} ${p.theme.sizes.four}`,
        alignItems: 'center',
        justifyContent: 'flex-start',
        border: `0.75px solid ${p.theme.colors.gray0Alpha70}`,
        marginRight: p.theme.sizes.four,
        borderRadius: '100px',
        position: 'relative',
        backgroundColor: p.theme.colors.white,
        cursor: 'pointer',
      })}
    >
      <Label
        s={() => ({
          flex: 1,
        })}
      >
        {value ? dayjs(value).format('LL') : 'Select Date'}
      </Label>
      <ArrowDown />
    </Block>
  ),
)

CustomDatePickerInput.displayName = 'CustomDatePickerInput'
