import React from 'react'
import { Modal } from 'design-system/modal'
import { Block } from 'design-system/block'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import styled from 'styled-components'
import { Flex } from 'design-system/flex'

export const MoreFiltersModal = ({ handleClose = () => {}, children = [] }) => {
  return (
    <Modal
      onClose={handleClose}
      title="More Filters"
      gridContainerStyle={{ maxWidth: '600px' }}
      footer={
        <Flex
          s={p => ({
            marginRight: p.theme.sizes.six,
            justifyContent: 'flex-end',
          })}
        >
          <Button
            onClick={handleClose}
            type={BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.LARGE}
          >
            Done
          </Button>
        </Flex>
      }
    >
      <FormContainer>{children}</FormContainer>
    </Modal>
  )
}

const FormContainer = styled(Block)`
  padding: ${p => p.theme.sizes.four};
  max-width: 400px;
  min-height: 200px;
`
