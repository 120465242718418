import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from '@reach/router'
import { navigate } from 'gatsby'
import { AuthStateContext } from 'context/auth-state-context'
import { InstanceContext } from 'containers/developers/context/InstanceContext'
import { mockDeveloperInstanceOptions } from '__mocks__/mockDeveloperInstances'

export const DevelopersRoute = ({ component: Component, ...rest }) => {
  const [authState = {}] = useContext(AuthStateContext)
  const [{ uuid: instanceStateUUID }, updateInstance] = useContext(
    InstanceContext,
  )
  const { token, permissionLevel = 1 } = authState

  const { instanceId } = useParams()

  useEffect(() => {
    if (!instanceStateUUID || instanceStateUUID !== instanceId) {
      const foundInstance = mockDeveloperInstanceOptions.find(
        i => i.uuid === instanceId,
      )
      updateInstance(foundInstance || mockDeveloperInstanceOptions[0])
    }
  }, [instanceStateUUID, instanceId])

  // Check if user has permission to view developer console

  if (!token) {
    console.log('No Token - Navigating to /login')
    // If we’re not logged in, redirect to the login page.
    if (typeof window !== `undefined`) {
      navigate('/login')
    }

    return null
  }

  // Developer only routes are level 4 or higher for now
  if (
    location.pathname &&
    location.pathname.includes('/developers') &&
    permissionLevel < 4
  ) {
    console.log('Not enough permission to access this page')
    // If we’re not logged in, redirect to the login page.
    if (typeof window !== `undefined`) {
      navigate('/')
    }

    return null
  }

  return <Component {...rest}></Component>
}

DevelopersRoute.propTypes = {
  component: PropTypes.any.isRequired,
}
