import React, { useContext } from 'react'
import dayjs from 'utils/dayjs'
import { Link } from 'components/account-dropdown/link.component'
import { DropdownLabel } from 'components/account-dropdown/dropdown-label.component'
import { GlobalStateContext } from 'context/global-context-provider'
import { searchTypes } from 'utils/constants'
import { getProvidersSearchUrl } from 'services/ribbon/search-providers.service'
import { getLocationsSearchUrl } from 'services/ribbon/search-locations.service'

export function formatSearchParameter(filter) {
  let valueText = filter[1]
  if (Array.isArray(filter[1])) {
    valueText = filter[1].map(val => {
      if (typeof val === 'object') {
        return `${val.display} (${val.uuid})`
      }
      return val;
    }).join(', ')
  }
  return `${filter[0]}: ${valueText}`
}

export function constructEmailBodyFromSearchState(searchState) {
  if (!searchState) {
    return ''
  }

  const searchMode = `Search Mode: ${searchState.searchType}`
  const {label, position, state, zipcode} = searchState.location || {}
  const locationText = searchState.location &&
    [`Location: ${label}`, `${position.latitude}, ${position.longitude}`, `${state} ${zipcode}`].join('\n')
  const insuranceText = searchState.insurance && `Insurance: ${searchState.insurance.label} (${searchState.insurance.id})`
  let searchParameters = [searchMode, locationText, insuranceText]

  let apiUrl
  let filters = []
  if (searchState.searchType === searchTypes.providers) {
    apiUrl = getProvidersSearchUrl(searchState.providersSearchQuery)
    const specialties = searchState.specialties.map(specialty => `${specialty.display_name} (${specialty.uuid})`)
    filters.push(
      ['Specialties', specialties.join(', ')],
      ['Provider name', searchState.providerName])
  } else if (searchState.searchType === searchTypes.facilities) {
    apiUrl = getLocationsSearchUrl(searchState.facilitiesSearchQuery)
    filters.push(
      ['Facility type', searchState.facilityType && searchState.facilityType.label],
      ['Facility name', searchState.facilityName])
  }
  const additionalFilters = (searchState.filters && searchState.filters[searchState.searchType]) || []
  filters.push(...Object.keys(additionalFilters).map(key => [key, additionalFilters[key]]))

  searchParameters.push(...filters.map(filter => filter[1] && formatSearchParameter(filter)))

  return encodeURIComponent(`\n\n\nCurrent Search Configuration${apiUrl ? `\n/v1${apiUrl}` : ''}\n\n`
    + searchParameters.filter(param => param).join('\n'))
}

export const HelpLink = () => {
  const [searchState,] = useContext(GlobalStateContext)

  const mail_to_address = 'support@ribbonhealth.com'
  const subject = `Ribbon App Ticket - ${dayjs().format('L',)}`

  const full_address = `mailto:${mail_to_address}?subject=${subject}&body=${constructEmailBodyFromSearchState(searchState)}`

  return (
    <Link>
      <a
        href={full_address}
      >
        <DropdownLabel>Email support</DropdownLabel>
      </a>
    </Link>
  );
}
