import React from 'react'
import { Flex } from 'design-system/flex'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DataCard } from '../components/DataCard'
import { JsonView } from 'components/json-view/json-view'

const CardContainer = styled(Flex)`
  flex-direction: column;
  margin-top: ${p => p.theme.sizes.four};
`

export const ResultCard = ({ result = {}, index, forceOpen = false }) => {
  return (
    <CardContainer>
      <DataCard>
        <JsonView data={result} forceOpen={forceOpen} index={index} />
      </DataCard>
    </CardContainer>
  )
}
ResultCard.propTypes = {
  result: PropTypes.object,
  index: PropTypes.number,
  forceOpen: PropTypes.bool,
}
