import React from 'react'
import { Table } from 'components/table/table'
import styled from 'styled-components'
import { Flex } from 'design-system/flex'

export const LogsTable = ({ tableInstance = {}, onClickRow = () => {} }) => {
  return (
    <StyledTable>
      <Table tableInstance={tableInstance} onClickRow={onClickRow} />
    </StyledTable>
  )
}

const StyledTable = styled(Flex)`
  flex-direction: column;
  > div:first-of-type {
    box-shadow: 0px 4px 16px 0px rgba(28, 29, 31, 0.08);
    background: ${p => p.theme.colors.white};
    border-radius: 5px;
    border: 1px solid ${p => p.theme.colors.gray5};
    table {
      padding-top: 0;
    }
    thead {
      display: none;
    }
    tr {
      border: none;
    }
  }
`
