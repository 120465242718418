import React from 'react'
import { Block } from 'design-system/block'

export const VirtualSearchRightSection = () => {
  return (
    <Block
      s={() => ({
        boxShadow: 'inset 14px 4px 14px rgba(94, 94, 94, 0.06)',
        padding: 0,
        position: 'relative',
        height: '100%',
        flexGrow: 1,
      })}
    >
      <Block s={() => ({ padding: 0, height: '100%' })}></Block>
    </Block>
  )
}
