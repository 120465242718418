import React from 'react';

export const BuildingIconContent = <>
  <path d="M11.4667 14.9336H4.00005C3.85287 14.9336 3.7334 15.0531 3.7334 15.2002V15.7336C3.7334 15.8808 3.85287 16.0002 4.00005 16.0002C4.14724 16.0002 4.26671 15.8808 4.26671 15.7336V15.4669H11.4667C11.6139 15.4669 11.7334 15.3475 11.7334 15.2003C11.7334 15.0531 11.6139 14.9336 11.4667 14.9336Z" fill="#4F4C4D" />
  <path d="M5.8667 6.66646C5.8667 6.81365 5.98617 6.93312 6.13336 6.93312H7.20004C7.34723 6.93312 7.4667 6.81365 7.4667 6.66646V5.06646C7.4667 4.91927 7.34723 4.7998 7.20004 4.7998H6.13339C5.9862 4.7998 5.86673 4.91927 5.86673 5.06646V6.66646H5.8667ZM6.40004 5.33315H6.93339V6.3998H6.40004V5.33315Z" fill="#4F4C4D" />
  <path d="M3.46666 3.2002H0.266656C0.119469 3.2002 0 3.31966 0 3.46685V8.8002C0 8.94738 0.119469 9.06685 0.266656 9.06685C0.413844 9.06685 0.533312 8.94738 0.533312 8.8002V3.73354H3.46666C3.61384 3.73354 3.73331 3.61407 3.73331 3.46688C3.73331 3.3197 3.61387 3.2002 3.46666 3.2002Z" fill="#4F4C4D" />
  <path d="M12.8 13.0669C12.8 13.214 12.9195 13.3335 13.0667 13.3335H14.1334C14.2805 13.3335 14.4 13.214 14.4 13.0669V11.4669C14.4 11.3197 14.2805 11.2002 14.1334 11.2002H13.0667C12.9195 11.2002 12.8 11.3197 12.8 11.4669V13.0669ZM13.3334 11.7335H13.8667V12.8002H13.3334V11.7335Z" fill="#4F4C4D" />
  <path d="M1.33334 9.59961C0.0151875 9.59961 0 13.0316 0 13.0663C0 13.7103 0.458937 14.2487 1.06666 14.3727V15.733C1.06666 15.8801 1.18613 15.9996 1.33331 15.9996C1.4805 15.9996 1.59997 15.8801 1.59997 15.733V14.3727C2.20769 14.2487 2.66663 13.7103 2.66663 13.0663C2.66666 13.0316 2.65147 9.59961 1.33334 9.59961ZM1.33334 13.8663C0.892281 13.8663 0.533344 13.5073 0.533344 13.0663C0.533344 11.7377 0.890156 10.1329 1.33334 10.1329C1.77653 10.1329 2.13334 11.7377 2.13334 13.0663C2.13334 13.5073 1.77441 13.8663 1.33334 13.8663Z" fill="#4F4C4D" />
  <path d="M2.93344 8H1.86675C1.71957 8 1.6001 8.11947 1.6001 8.26666V8.8C1.6001 8.94719 1.71957 9.06666 1.86675 9.06666C2.01394 9.06666 2.13341 8.94719 2.13341 8.8V8.53334H2.66675V9.86669C2.66675 10.0139 2.78622 10.1333 2.93341 10.1333C3.0806 10.1333 3.20006 10.0139 3.20006 9.86669V8.26669C3.2001 8.11947 3.08063 8 2.93344 8Z" fill="#4F4C4D" />
  <path d="M13.0667 6.93312H14.1334C14.2805 6.93312 14.4 6.81365 14.4 6.66646V5.06646C14.4 4.91927 14.2805 4.7998 14.1334 4.7998H13.0667C12.9195 4.7998 12.8 4.91927 12.8 5.06646V6.66646C12.8 6.81365 12.9195 6.93312 13.0667 6.93312ZM13.3334 5.33312H13.8667V6.39977H13.3334V5.33312Z" fill="#4F4C4D" />
  <path d="M2.93344 4.7998H1.86675C1.71957 4.7998 1.6001 4.91927 1.6001 5.06646V6.66646C1.6001 6.81365 1.71957 6.93312 1.86675 6.93312H2.93341C3.0806 6.93312 3.20006 6.81365 3.20006 6.66646V5.06646C3.2001 4.91927 3.08063 4.7998 2.93344 4.7998ZM2.66675 6.3998H2.13344V5.33315H2.66678V6.3998H2.66675Z" fill="#4F4C4D" />
  <path d="M8.53345 6.66646C8.53345 6.81365 8.65292 6.93312 8.8001 6.93312H9.86676C10.0139 6.93312 10.1334 6.81365 10.1334 6.66646V5.06646C10.1334 4.91927 10.0139 4.7998 9.86676 4.7998H8.8001C8.65292 4.7998 8.53345 4.91927 8.53345 5.06646V6.66646ZM9.06676 5.33315H9.6001V6.3998H9.06676V5.33315Z" fill="#4F4C4D" />
  <path d="M15.7333 3.2002H12.5333C12.3861 3.2002 12.2666 3.31966 12.2666 3.46685C12.2666 3.61404 12.3861 3.73351 12.5333 3.73351H15.4666V14.4493C15.3829 14.4194 15.2941 14.4002 15.1999 14.4002C15.1538 14.4002 15.1079 14.4042 15.0629 14.4122C14.8789 14.0807 14.5288 13.8669 14.1333 13.8669C13.7378 13.8669 13.3877 14.0807 13.2037 14.4122C13.1586 14.4042 13.1128 14.4002 13.0666 14.4002C12.6256 14.4002 12.2666 14.7592 12.2666 15.2002C12.2666 15.6413 12.6256 16.0002 13.0666 16.0002H15.1999C15.641 16.0002 15.9999 15.6413 15.9999 15.2002V3.46685C15.9999 3.31966 15.8804 3.2002 15.7333 3.2002ZM15.1999 15.4669H13.0666C12.9196 15.4669 12.7999 15.3472 12.7999 15.2002C12.7999 15.0533 12.9196 14.9336 13.0666 14.9336C13.1178 14.9336 13.1663 14.9482 13.2111 14.977C13.2842 15.024 13.3754 15.0325 13.4559 15C13.5364 14.9674 13.5959 14.8976 13.6154 14.813C13.6717 14.5701 13.8847 14.4002 14.1333 14.4002C14.3818 14.4002 14.5949 14.5701 14.6511 14.813C14.6706 14.8976 14.7301 14.9674 14.8106 15C14.8909 15.0325 14.9823 15.024 15.0554 14.977C15.229 14.865 15.4666 15.0021 15.4666 15.2002C15.4666 15.3472 15.3469 15.4669 15.1999 15.4669Z" fill="#4F4C4D" />
  <path d="M8.8001 3.73292H9.86676C10.0139 3.73292 10.1334 3.61345 10.1334 3.46627V1.86627C10.1334 1.71908 10.0139 1.59961 9.86676 1.59961H8.8001C8.65292 1.59961 8.53345 1.71908 8.53345 1.86627V3.46627C8.53345 3.61345 8.65292 3.73292 8.8001 3.73292ZM9.06676 2.13292H9.6001V3.19958H9.06676V2.13292Z" fill="#4F4C4D" />
  <path d="M8.53345 9.86666C8.53345 10.0138 8.65292 10.1333 8.8001 10.1333H9.86676C10.0139 10.1333 10.1334 10.0138 10.1334 9.86666V8.26666C10.1334 8.11947 10.0139 8 9.86676 8H8.8001C8.65292 8 8.53345 8.11947 8.53345 8.26666V9.86666ZM9.06676 8.53334H9.6001V9.6H9.06676V8.53334Z" fill="#4F4C4D" />
  <path d="M6.13336 3.73292H7.20001C7.3472 3.73292 7.46667 3.61345 7.46667 3.46627V1.86627C7.46667 1.71908 7.3472 1.59961 7.20001 1.59961H6.13336C5.98617 1.59961 5.8667 1.71908 5.8667 1.86627V3.46627C5.86667 3.61345 5.98614 3.73292 6.13336 3.73292ZM6.40001 2.13292H6.93336V3.19958H6.40001V2.13292Z" fill="#4F4C4D" />
  <path d="M7.81074 12.8777C7.76274 12.9284 7.7334 12.9977 7.7334 13.0671C7.7334 13.1364 7.76274 13.2057 7.81074 13.2564C7.8614 13.3044 7.93074 13.3338 8.00009 13.3338C8.06943 13.3338 8.13874 13.3044 8.18943 13.2564C8.23743 13.2058 8.26677 13.1364 8.26677 13.0671C8.26677 12.9977 8.23743 12.9284 8.18943 12.8777C8.08809 12.779 7.91209 12.779 7.81074 12.8777Z" fill="#4F4C4D" />
  <path d="M5.8667 9.86666C5.8667 10.0138 5.98617 10.1333 6.13336 10.1333H7.20004C7.34723 10.1333 7.4667 10.0138 7.4667 9.86666V8.26666C7.4667 8.11947 7.34723 8 7.20004 8H6.13339C5.9862 8 5.86673 8.11947 5.86673 8.26666V9.86666H5.8667ZM6.40004 8.53334H6.93339V9.6H6.40004V8.53334Z" fill="#4F4C4D" />
  <path d="M5.33325 11.4669C5.33325 11.614 5.45272 11.7335 5.59991 11.7335H6.39991V14.1335C6.39991 14.2807 6.51938 14.4002 6.66657 14.4002C6.81375 14.4002 6.93322 14.2807 6.93322 14.1335V11.7335H9.06657V14.1335C9.06657 14.2807 9.18604 14.4002 9.33323 14.4002C9.48041 14.4002 9.59988 14.2807 9.59988 14.1335V11.7335H10.3999C10.5471 11.7335 10.6665 11.614 10.6665 11.4669C10.6665 11.3197 10.5471 11.2002 10.3999 11.2002H5.59991C5.45272 11.2002 5.33325 11.3197 5.33325 11.4669Z" fill="#4F4C4D" />
  <path d="M12.8 9.86666C12.8 10.0138 12.9195 10.1333 13.0667 10.1333H14.1334C14.2805 10.1333 14.4 10.0138 14.4 9.86666V8.26666C14.4 8.11947 14.2805 8 14.1334 8H13.0667C12.9195 8 12.8 8.11947 12.8 8.26666V9.86666ZM13.3334 8.53334H13.8667V9.6H13.3334V8.53334Z" fill="#4F4C4D" />
  <path d="M11.4666 0H4.53326C4.38607 0 4.2666 0.119469 4.2666 0.266656V14.1333C4.2666 14.2805 4.38607 14.4 4.53326 14.4C4.68045 14.4 4.79991 14.2805 4.79991 14.1333V0.533312H11.1999V14.1333C11.1999 14.2805 11.3194 14.4 11.4666 14.4C11.6138 14.4 11.7332 14.2805 11.7332 14.1333V0.266656C11.7333 0.119469 11.6138 0 11.4666 0Z" fill="#4F4C4D" />
</>