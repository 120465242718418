import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useLocation } from '@reach/router'

import { AuthLayoutAside } from 'components/auth-layout-aside/auth-layout-aside'
import { Flex } from 'design-system/flex'
import { UserPasswordLogin } from 'containers/login/components/user-password-login'
import { SsoLogin } from 'containers/login/components/sso-login'

const Container = styled(Flex)`
  flex: 1;
  padding: ${p => p.theme.sizes.eight};
  overflow-y: auto;

  @media (max-width: ${p => p.theme.flexboxgrid.breakpoints.sm}em) {
    padding: ${p => p.theme.sizes.five};
    > div {
      justify-content: center;
    }
  }
`

const getSsoSubdomain = (hostname) => {
  if (!hostname) {
    return null
  }

  const subdomain = hostname.split('.')[0]
  const nonSsoDomains = [/^localhost$/, /^dev$/, /^app$/, /^deploy-preview.*$/]
  return nonSsoDomains.find(regex => subdomain.toLowerCase().match(regex)) ?
    null :
    subdomain
}

export const Login = () => {
  const { hostname } = useLocation()
  const ssoSubdomain = getSsoSubdomain(hostname)

  return (
    <Container
      s={() => ({
        justifyContent: ssoSubdomain ? 'center' : 'space-between'
      })}
    >
      {ssoSubdomain ? (
        <SsoLogin
          ssoSubdomain={ssoSubdomain}
        />
      ) : (
        <>
          <UserPasswordLogin />
          <AuthLayoutAside />
        </>
      )}
    </Container>
  )
}

Login.propTypes = {
  theme: PropTypes.object,
}
