import React, { useState } from 'react'
import { Modal } from 'design-system/modal'
import { Block } from 'design-system/block'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label, LABEL_TYPE } from 'design-system/label'
import styled from 'styled-components'
import { Spinner } from 'design-system/spinner/spinner.component'
import { SelectInput } from 'design-system/input/select-input'
import { theme } from 'theme'

// Could possibly combined into one modal that handles all updates to config eg. with api version as props
export const UpdateTypoToleranceModal = ({
  handleClose = () => {},
  currentTolerance = '5',
}) => {
  const [tolerance, setTolerance] = useState(currentTolerance)
  const [isLoading, setIsLoading] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const onChange = e => {
    const { value } = e.target
    setTolerance(value)
  }

  const onClickSave = async () => {
    if (tolerance === currentTolerance) {
      handleClose()
    } else {
      setShowConfirm(true)
    }
  }

  const onClickConfirm = async () => {
    console.log(`🚀 => onClickConfirm => tolerance`, tolerance)

    // API call to delete Instance
    setIsLoading(true)
    try {
      setTimeout(() => {
        setIsLoading(false)
        handleClose()
      }, 500)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onClickCancel = () => {
    if (showConfirm) {
      setShowConfirm(false)
    } else {
      handleClose()
    }
  }

  return (
    <Modal
      onClose={handleClose}
      title={`Update Typo Tolerance`}
      footer={
        <Block
          type="FLEX"
          s={p => ({
            marginRight: p.theme.sizes.six,
            justifyContent: 'flex-end',
          })}
        >
          <Button
            s={() => ({
              marginRight: '1rem',
            })}
            type={BUTTON_TYPE.LINK}
            onClick={onClickCancel}
          >
            {showConfirm ? 'Back' : 'Cancel'}
          </Button>
          <Button
            onClick={showConfirm ? onClickConfirm : onClickSave}
            type={BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.LARGE}
            disabled={isLoading}
            s={() => ({
              minWidth: '110px',
            })}
          >
            {showConfirm ? 'Confirm' : 'Save'}
          </Button>
        </Block>
      }
    >
      {isLoading ? (
        <Spinner label={`Updating Typo Tolerance..`} />
      ) : (
        <div>
          {showConfirm ? (
            <FormContainer>
              <Label
                s={p => ({
                  color: p.theme.colors.aubergine,
                })}
              >
                Are you sure you want to update your typo tolerance from{' '}
                <span style={{ color: theme.colors.gray1, fontWeight: '500' }}>
                  {currentTolerance}
                </span>{' '}
                to{' '}
                <span style={{ color: theme.colors.gray1, fontWeight: '500' }}>
                  {tolerance}
                </span>
                ? This change will impact all search behavior.
              </Label>
            </FormContainer>
          ) : (
            <FormContainer>
              <Label type={LABEL_TYPE.BODY1_BOLD}>Typo Tolerance</Label>
              <SelectInput
                onChange={onChange}
                name="tolerance"
                value={tolerance}
                options={[
                  {
                    label: '5',
                    value: '5',
                  },
                  {
                    label: '4',
                    value: '4',
                  },
                  {
                    label: '3',
                    value: '3',
                  },
                ]}
                placeholder="Select tolerance level"
                disabled={false}
                containerStyle={{ margin: '1em 0', maxWidth: '50%' }}
              />
              <Label
                s={p => ({
                  color: p.theme.colors.gray1,
                })}
              >
                This change will impact all records and could result in a loss
                of editing changes.
              </Label>
            </FormContainer>
          )}
        </div>
      )}
    </Modal>
  )
}

const FormContainer = styled(Block)`
  padding: ${p => p.theme.sizes.four};
  min-height: 250px;
`
