import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Chevron } from 'design-system/icon/icons/chervon-left'
import { Button } from 'design-system/button'
import { Flex } from 'design-system/flex'
import { EDIT_INSURANCE_ACTION_TYPES } from './edit-insurances-reducer'
import { useToggle } from 'hooks/useToggle'
import {
  filterPlansByFilterList,
  formatDisplayNameByState,
  formatInsurancePlansByState,
  getAllFilterOptionsFromPlans,
  mapInsurancesByCarrier,
  mapInsurancesByVerificationState,
  mapPlansByState,
  mapStatePlansByPlanType,
} from 'utils/insurances'
import {
  NoSelectionIcon,
  ReportIcon,
  VerifiedIcon,
} from 'components/verification-toggle/verification-toggle'
import { Checkbox } from 'design-system/checkbox'
import { NoInsuranceSearchResultsContainer } from './no-insurance-search-results'
import { Input, INPUT_TYPE } from 'design-system/input'
import {
  FilterInsurancesDropdown,
  SelectByVerificationDropdown,
} from './filter-insurances'
import { focusNextInput } from 'utils/document'

const ScrollContainer = styled(Flex)`
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  padding: ${p => p.theme.sizes.four} ${p => p.theme.sizes.six};
`

const ActionRow = styled(Flex)`
  padding: 0 ${p => p.theme.sizes.six};
  align-items: center;
  margin-bottom: ${p => p.theme.sizes.two};
  > div {
    margin-right: ${p => p.theme.sizes.four};
    height: initial;
    > button {
      height: initial;
      padding: ${p => p.theme.sizes.one} ${p => p.theme.sizes.four};

      > p {
        margin-left: ${p => p.theme.sizes.two};
        line-height: 18px;
      }
    }
  }
  .filter-label-container {
    margin-right: 0;
  }
  .filter-more-menu {
    width: 200px;
    min-width: initial;
  }
`

export const VerifyInsurancesContainer = ({
  dispatch,
  state,
  handleToggleSingle,
  handleToggleList,
}) => {
  const {
    insurances,
    verifications,
    editVerifications,
    selectedInsurancesMap,
    selectedVerificationFilter,
    localSearch,
    planFilters,
  } = state

  const [showFilterDropdown, setShowFilterDropdown] = useState()

  const filterOptions = useMemo(
    () => getAllFilterOptionsFromPlans(insurances),
    [insurances],
  )

  const filteredInsurances = useMemo(
    () =>
      filterPlansByFilterList(
        insurances.filter(item =>
          item.displayName.toLowerCase().includes(localSearch.toLowerCase()),
        ),
        planFilters,
      ),
    [insurances, localSearch, planFilters],
  )

  const mappedByCarrierInsurances = useMemo(
    () => mapInsurancesByCarrier(filteredInsurances),
    [filteredInsurances],
  )

  const mappedByVerification = useMemo(
    () => mapInsurancesByVerificationState(filteredInsurances, verifications),
    [filteredInsurances],
  )

  useEffect(() => {
    focusNextInput('search-insurances-input')
  }, [])

  const onClickSaveFilters = filters => {
    dispatch({
      type: EDIT_INSURANCE_ACTION_TYPES.updateFilters,
      payload: {
        filters,
      },
    })
    setShowFilterDropdown()
  }

  const onClickSelectedDropdownOption = option => {
    handleToggleList(
      mappedByVerification[option],
      EDIT_INSURANCE_ACTION_TYPES.selectByFilter,
      option,
    )
  }

  const onClickConfidenceAction = confidence => {
    const mapSelectedAsArray = Object.keys(selectedInsurancesMap).map(
      key => selectedInsurancesMap[key],
    )
    if (confidence === 5) {
      handleToggleList(
        mapSelectedAsArray,
        EDIT_INSURANCE_ACTION_TYPES.verifyList,
      )
    }
    if (confidence === 1) {
      handleToggleList(
        mapSelectedAsArray,
        EDIT_INSURANCE_ACTION_TYPES.reportList,
      )
    }
    if (confidence === 3) {
      handleToggleList(
        mapSelectedAsArray,
        EDIT_INSURANCE_ACTION_TYPES.noSelectionList,
      )
    }
  }

  const onClickSelectCheck = () => {
    if (Object.keys(selectedInsurancesMap).length > 0) {
      handleToggleList([], EDIT_INSURANCE_ACTION_TYPES.unselectAll, null)
    }
    if (Object.keys(selectedInsurancesMap).length === 0) {
      handleToggleList(
        filteredInsurances,
        EDIT_INSURANCE_ACTION_TYPES.selectByFilter,
        'All',
      )
    }
  }

  return (
    <>
      <Flex
        s={p => ({
          marginBottom: p.theme.sizes.four,
          paddingTop: p.theme.sizes.four,
          paddingLeft: p.theme.sizes.six,
          paddingRight: p.theme.sizes.six,
        })}
      >
        <Input
          s={p => ({
            flex: 1,
            marginRight: p.theme.sizes.four,
          })}
          onInputChange={e =>
            dispatch({
              type: EDIT_INSURANCE_ACTION_TYPES.updateFilters,
              payload: { localSearch: e.target.value },
            })
          }
          value={[{ label: localSearch }]}
          placeholder="Search for an insurance.."
          type={INPUT_TYPE.ROUND}
          id="search-insurances-input"
          onChange={() =>
            dispatch({
              type: EDIT_INSURANCE_ACTION_TYPES.updateFilters,
              payload: { localSearch: '' },
            })
          }
        />
        <FilterInsurancesDropdown
          label="Filter Plans"
          onClickFilter={setShowFilterDropdown}
          showMenu={showFilterDropdown}
          closeMenu={() => setShowFilterDropdown()}
          filters={planFilters}
          filterOptions={filterOptions}
          onClickSave={onClickSaveFilters}
        />
      </Flex>
      {filteredInsurances.length > 0 && (
        <ActionRow>
          <SelectByVerificationDropdown
            selectedOption={selectedVerificationFilter}
            selectedInsurances={selectedInsurancesMap}
            allInsurances={filteredInsurances}
            onClickCheck={onClickSelectCheck}
            onClickOption={onClickSelectedDropdownOption}
            options={Object.keys(mappedByVerification)}
            mappedByVerification={mappedByVerification}
          />
          {Object.keys(selectedInsurancesMap).length > 0 && (
            <>
              <Button onClick={() => onClickConfidenceAction(5)}>
                <VerifiedIcon />
                <Label>Verify</Label>
              </Button>
              <Button onClick={() => onClickConfidenceAction(1)}>
                <ReportIcon />
                <Label>Report</Label>
              </Button>
              <Button onClick={() => onClickConfidenceAction(3)}>
                <NoSelectionIcon />
                <Label>No Selection</Label>
              </Button>
            </>
          )}
        </ActionRow>
      )}

      <ScrollContainer>
        {filteredInsurances.length === 0 ? (
          <NoInsuranceSearchResultsContainer message="Try searching for insurances again." />
        ) : (
          <>
            {Object.keys(mappedByCarrierInsurances).map(carrier => {
              const carrierInsurances = mappedByCarrierInsurances[carrier]
              return (
                <VerifyListItem
                  key={carrier}
                  carrier={carrier}
                  carrierInsurances={carrierInsurances}
                  handleToggleList={handleToggleList}
                  handleToggleSingle={handleToggleSingle}
                  verifications={verifications}
                  editVerifications={editVerifications}
                  filters={planFilters}
                  selectedInsurancesMap={selectedInsurancesMap}
                />
              )
            })}
          </>
        )}
      </ScrollContainer>
    </>
  )
}

VerifyInsurancesContainer.propTypes = {
  dispatch: PropTypes.func,
  state: PropTypes.object,
  handleToggleSingle: PropTypes.func,
  handleToggleList: PropTypes.func,
}

const Header = styled(Flex)`
  flex: 1;
  justify-content: space-between;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${p => p.theme.colors.gray3};
  padding-bottom: ${p => p.theme.sizes.two};
  margin-bottom: ${p => p.theme.sizes.two};
  align-items: center;
`

const HeaderRow = styled(Flex)`
  align-items: center;
`

const PlansContainer = styled(Flex)`
  flex-direction: column;
`

const PlanListHeader = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
  padding: ${p => p.theme.sizes.three} 0;
  margin-left: ${p => p.theme.sizes.four};

  ${p => ({
    ...(p.isSubHeader && {
      marginLeft: p.theme.sizes.six,
    }),
  })}
`

const VerifyListItem = ({
  carrier = '',
  carrierInsurances = [],
  handleToggleSingle,
  handleToggleList,
  filters = {},
  verifications = {},
  editVerifications = {},
  selectedInsurancesMap = {},
}) => {
  const [expanded, toggleExpanded] = useToggle(false)

  const filteredCarrierInsurances = filterPlansByFilterList(
    carrierInsurances,
    filters,
  )

  const hasInsurances = filteredCarrierInsurances.length > 0

  const hasAllSelectedByCarrier =
    hasInsurances &&
    filteredCarrierInsurances.every(plan => selectedInsurancesMap[plan.uuid])

  const plansFormattedByState = useMemo(
    () => formatInsurancePlansByState(filteredCarrierInsurances),
    [filteredCarrierInsurances],
  )

  const plansMappedByState = useMemo(
    () => mapPlansByState(plansFormattedByState),
    [plansFormattedByState],
  )
  const plansMappedByStateAndPlanType = useMemo(
    () => mapStatePlansByPlanType(plansMappedByState),
    [plansMappedByState],
  )

  // console.log(`🚀 => plansFormattedByState:${carrier}:`, plansFormattedByState)
  // console.log(`🚀 => plansMappedByState:${carrier}:`, plansMappedByState)

  return (
    <Flex
      s={p => ({
        flexDirection: 'column',
        marginBottom: p.theme.sizes.two,
      })}
    >
      <Header>
        <HeaderRow>
          <Checkbox
            s={p => ({ marginRight: p.theme.sizes.two })}
            value={hasAllSelectedByCarrier}
            onClick={() =>
              hasAllSelectedByCarrier
                ? handleToggleList(
                    filteredCarrierInsurances,
                    EDIT_INSURANCE_ACTION_TYPES.unselectList,
                  )
                : handleToggleList(
                    filteredCarrierInsurances,
                    EDIT_INSURANCE_ACTION_TYPES.selectList,
                  )
            }
            data-testid={`${carrier}-checkbox`}
          />
          <Label type={LABEL_TYPE.BODY1_BOLD}>
            {`${carrier} (${filteredCarrierInsurances.length})`}
          </Label>
        </HeaderRow>
        <HeaderRow>
          <Button
            s={() => ({
              ['> button']: {
                padding: 0,
                height: 24,
                width: 24,
              },
              borderRadius: 4,
              borderColor: 'transparent',
            })}
            onClick={toggleExpanded}
            data-testid={`${carrier}-chevron`}
          >
            <Chevron direction={expanded ? 'up' : 'down'} />
          </Button>
        </HeaderRow>
      </Header>
      {expanded && (
        <PlansContainer>
          {Object.keys(plansMappedByStateAndPlanType).map(state => {
            const { header, planTypes } = plansMappedByStateAndPlanType[state]
            const { uuid, displayName } = header

            const plansByState = plansMappedByState[uuid]
            const hasAllSelectedByState =
              plansByState &&
              plansByState.length > 0 &&
              plansByState.every(plan => selectedInsurancesMap[plan.uuid])
            return (
              <div key={state}>
                <PlanListHeader key={uuid}>
                  <Checkbox
                    s={p => ({ marginRight: p.theme.sizes.two })}
                    value={hasAllSelectedByState}
                    onClick={() =>
                      hasAllSelectedByState
                        ? handleToggleList(
                            plansByState,
                            EDIT_INSURANCE_ACTION_TYPES.unselectList,
                          )
                        : handleToggleList(
                            plansByState,
                            EDIT_INSURANCE_ACTION_TYPES.selectList,
                          )
                    }
                    data-testid={`${displayName}-checkbox`}
                  />
                  <Label type={LABEL_TYPE.BODY1_BOLD}>{displayName}</Label>
                </PlanListHeader>
                {Object.keys(planTypes).map(planType => {
                  const plansByType = planTypes[planType]
                  if (plansByType.length === 0) return

                  const hasAllSelectedByType = plansByType.every(
                    plan => selectedInsurancesMap[plan.uuid],
                  )

                  return (
                    <div key={planType}>
                      <PlanListHeader isSubHeader>
                        <Checkbox
                          s={p => ({ marginRight: p.theme.sizes.two })}
                          value={hasAllSelectedByType}
                          onClick={() =>
                            hasAllSelectedByType
                              ? handleToggleList(
                                  plansByType,
                                  EDIT_INSURANCE_ACTION_TYPES.unselectList,
                                )
                              : handleToggleList(
                                  plansByType,
                                  EDIT_INSURANCE_ACTION_TYPES.selectList,
                                )
                          }
                          data-testid={`${planType}-checkbox`}
                        />
                        <Label type={LABEL_TYPE.BODY1_BOLD}>{planType}</Label>
                      </PlanListHeader>
                      {plansByType.map(plan => {
                        // Purple highlight already verified plans
                        const previousConfidence =
                          verifications[plan.uuid] &&
                          verifications[plan.uuid].confidence
                        const editConfidence =
                          editVerifications[plan.uuid] &&
                          editVerifications[plan.uuid].confidence

                        const isVerified = editConfidence > 3
                        const isReported = editConfidence < 3
                        const hasChanges = previousConfidence !== editConfidence

                        const label = formatDisplayNameByState(plan)

                        const isSelected = Boolean(
                          selectedInsurancesMap[plan.uuid],
                        )

                        return (
                          <Flex
                            key={plan.uuid}
                            s={p => ({
                              marginBottom: p.theme.sizes.two,
                              marginLeft: p.theme.sizes.eight,
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            })}
                          >
                            <Flex
                              s={p => ({
                                alignItems: 'center',
                                marginRight: p.theme.sizes.four,
                              })}
                              onClick={() =>
                                handleToggleSingle(
                                  plan,
                                  EDIT_INSURANCE_ACTION_TYPES.selectSingle,
                                )
                              }
                            >
                              <Checkbox
                                s={p => ({ marginRight: p.theme.sizes.two })}
                                value={isSelected}
                                data-testid={`${label}-checkbox`}
                              />
                              <Label
                                s={p => ({
                                  color: p.theme.colors.gray1,
                                  marginRight: p.theme.sizes.two,
                                  ...(hasChanges && {
                                    fontWeight: '500',
                                    color: p.theme.colors.aubergine3,
                                  }),
                                })}
                              >
                                {label}
                              </Label>
                            </Flex>

                            {isReported && <ReportIcon />}
                            {isVerified && <VerifiedIcon />}
                          </Flex>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </PlansContainer>
      )}
    </Flex>
  )
}

VerifyListItem.propTypes = {
  carrier: PropTypes.string,
  carrierInsurances: PropTypes.array,
  handleToggleList: PropTypes.func,
  handleToggleSingle: PropTypes.func,
  verifications: PropTypes.object,
  editVerifications: PropTypes.object,
  filters: PropTypes.object,
  selectedInsurancesMap: PropTypes.object,
}
