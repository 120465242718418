import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import styled from 'styled-components'
import { services } from 'services'
import { Input, INPUT_SIZE, INPUT_TYPE } from 'design-system/input'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label, LABEL_TYPE } from 'design-system/label'
import { ConfirmResetPassword } from 'containers/confirm-reset-password/confirm-reset-password.container'
import { AuthLayoutAside } from 'components/auth-layout-aside/auth-layout-aside'
import { Flex } from 'design-system/flex'
import { validEmail } from 'utils/validations'

const Container = styled(Flex)`
  flex: 1;
  padding: ${p => p.theme.sizes.eight};
  overflow-y: auto;

  @media (max-width: ${p => p.theme.flexboxgrid.breakpoints.sm}em) {
    padding: ${p => p.theme.sizes.five};
    > div {
      justify-content: center;
    }
  }
`

export const ResetPassword = () => {
  const location = useLocation()
  const parsedLocation = queryString.parse(location.search)
  const { token, uid } = parsedLocation

  const [email, setEmail] = useState('')
  const [complete, setComplete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [emailError, setEmailError] = useState()

  const onChangeText = e => {
    setEmail(e.target.value)
    setEmailError()
  }
  const handleButtonClick = async () => {
    if (email && !loading) {
      if (!validEmail(email)) {
        setEmailError(true)
        return
      }
      setLoading(true)
      try {
        await services.ribbon.resetPassword({ email })
        setComplete(true)
        setLoading(false)
      } catch (error) {
        setComplete(true)
        setLoading(false)
      }
    }
  }

  if (uid && token) {
    return <ConfirmResetPassword />
  }

  return (
    <Container>
      <Flex s={() => ({ flex: 1, alignItems: 'flex-start' })}>
        <Flex
          s={p => ({
            flex: 1,
            flexDirection: 'column',
            border: `1px solid ${p.theme.colors.gray1}`,
            borderRadius: p.theme.sizes.four,
            padding: p.theme.sizes.eight,
            textAlign: complete ? 'left' : 'center',
            maxWidth: '440px',
            width: '100%',
            justifyContent: 'center',
            alignItems: complete ? 'left' : 'center',
          })}
        >
          {(!complete && (
            <>
              <Label
                s={p => ({
                  fontSize: p.theme.sizes.six,
                  fontWeight: 800,
                })}
              >
                Forgot password
              </Label>
              <Flex
                s={p => ({
                  flexDirection: 'column',
                  margin: `${p.theme.sizes.six} `,
                  width: '100%',
                })}
              >
                <Input
                  s={p => ({
                    width: '100%',
                    borderColor: emailError
                      ? p.theme.colors.errorRed
                      : p.theme.colors.borderMid,
                  })}
                  size={INPUT_SIZE.EXTRA_LARGE}
                  type={INPUT_TYPE.ROUND}
                  showClearIcon={false}
                  placeholder={'Email address'}
                  value={(email && [{ id: email, label: email }]) || undefined}
                  onInputChange={onChangeText}
                  onEnter={handleButtonClick}
                />
                {emailError && (
                  <Label
                    s={p => ({
                      textAlign: 'center',
                      marginTop: p.theme.sizes.two,
                    })}
                    type={LABEL_TYPE.ERROR}
                  >
                    Please enter a valid email.
                  </Label>
                )}
              </Flex>
              <Button
                type={(email && BUTTON_TYPE.PRIMARY) || BUTTON_TYPE.DISABLED}
                size={BUTTON_SIZE.EXTRA_LARGE}
                onClick={handleButtonClick}
                s={() => ({ width: '100%', textAlign: 'center' })}
                loading={loading}
                disabled={loading || !email}
              >
                Send reset link
              </Button>
              <Button
                type={BUTTON_TYPE.LINK}
                size={BUTTON_SIZE.EXTRA_LARGE}
                s={p => ({
                  marginTop: p.theme.sizes.four,
                  width: '100%',
                })}
                onClick={() => navigate('/login')}
              >
                <Label
                  s={p => ({
                    color: p.theme.colors.aubergine,
                    fontWeight: 500,
                  })}
                >
                  Back to Login
                </Label>
              </Button>
            </>
          )) || (
            <>
              <Label
                s={p => ({
                  fontSize: p.theme.sizes.six,
                  fontWeight: 800,
                })}
              >
                Help is on the way
              </Label>
              <Label
                type={LABEL_TYPE.BODY1}
                s={p => ({
                  fontWeight: 400,
                  color: p.theme.colors.gray1,
                  margin: `${p.theme.sizes.six} 0`,
                })}
              >
                If you have an active account, you will receive a link to reset
                your password in your email shortly. <br />
                <br />
                If you don’t receive an email after a few minutes, contact us at{' '}
                <a href="mailto:support@ribbonhealth.com">
                  support@ribbonhealth.com
                </a>
                .
              </Label>
              <Button
                type={BUTTON_TYPE.PRIMARY}
                size={BUTTON_SIZE.EXTRA_LARGE}
                onClick={() => navigate('/login')}
                s={() => ({ width: '100%', textAlign: 'center' })}
              >
                Back to Login
              </Button>
            </>
          )}
        </Flex>
      </Flex>
      <AuthLayoutAside />
    </Container>
  )
}
ResetPassword.propTypes = {
  theme: PropTypes.object,
}
