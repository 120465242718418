/* eslint-disable react/prop-types */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { colors } from 'theme/colors'
import { sizes } from 'theme/sizing'
import { debounce } from 'utils/debounce.util'
import CloseSVG from 'assets/close-x.svg'
import CurrentLocation from 'assets/current-location.svg'
import { Flex } from 'design-system/flex'
import styled from 'theme'

const StyledCurrentLocationSVG = styled(CurrentLocation)`
  margin-right: ${p => p.theme.sizes.two};
`

export const ClearIndicator = props => {
  return (
    <components.ClearIndicator {...props}>
      <CloseSVG height={12} width={12} />
    </components.ClearIndicator>
  )
}

const DropdownIndicator = () => null

export const Control = ({ children, ...props }) => {
  const { iconLeft } = props.selectProps
  return (
    <components.Control {...props}>
      {iconLeft ? iconLeft() : null}
      {children}
    </components.Control>
  )
}

export const CustomOption = props => {
  const { data } = props
  return (
    <components.Option {...props}>
      <Flex>
        {data && data.id && data.id === 'current_location' && (
          <StyledCurrentLocationSVG />
        )}
        {props.children}
      </Flex>
    </components.Option>
  )
}

export const dropdownStyles = ({
  containerStyle = {},
  controlStyle = {},
  menuStyle = {},
}) => ({
  // menuPortal: provided => ({ ...provided }),
  option: (provided, state) => {
    const { data } = state.data
    const headerStyle = {}
    const groupedStyle = {}
    if (data) {
      // Optional styles to fake sections list UI within the result list
      // based on params passed through data - (Only in use for Clinical Areas search)
      if (data.is_header) {
        headerStyle['fontWeight'] = 'bold'
        if (data.no_op) {
          headerStyle['pointerEvents'] = 'none'
          headerStyle[':hover'] = {
            cursor: 'text',
          }
        }
      }
      if (data.has_header) {
        groupedStyle['paddingLeft'] = '32px'
      }
    }
    return {
      ...provided,
      backgroundColor: state.isFocused ? colors.gray6 : colors.white,
      ':hover': {
        cursor: 'pointer',
      },
      ...headerStyle,
      ...groupedStyle,
    }
  },
  groupHeading: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '32px',
    margin: 0,
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#231F20',
  }),
  container: provided => ({
    ...provided,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    height: sizes.seven,
    ...containerStyle,
  }),
  control: (base, state) => ({
    ...base,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.borderMid,
    borderRadius: '2rem',
    paddingLeft: '0.50rem',
    boxShadow: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    ...controlStyle,
    '&:hover': {
      border: `1px solid ${colors.aubergine}`,
      cursor: 'text',
    },
    ...(state.isFocused && {
      border: `1px solid ${colors.aubergine}`,
    }),
  }),
  valueContainer: provided => ({
    ...provided,
    alignItems: 'center',
    outline: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    justifyContent: 'center',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  input: provided => ({
    ...provided,
    fontSize: sizes.four,
    color: colors.text,
    fontFamily: `Inter`,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
  placeholder: provided => ({
    ...provided,
    opacity: 0.5,
    fontSize: sizes.four,
    color: colors.text,
    fontFamily: `Inter`,
  }),
  menuList: provided => ({
    ...provided,
    maxHeight: '13rem',
  }),
  menu: provided => ({
    ...provided,
    maxHeight: '13rem',
    ...menuStyle,
  }),
  clearIndicator: provided => ({
    ...provided,
    cursor: 'pointer',
    marginRight: sizes.two,
    padding: sizes.two,
    borderRadius: '4px',
    ':hover': {
      background: colors.gray6,
    },
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: colors.text,
    opacity: 0.5,
  }),
})

/**
 * Split up from SelectInput for chance this could have very different style/options/props
 */

export const AsyncSelectInput = ({
  value = '',
  name = 'Name',
  onChange = () => {},
  placeholder = '',
  disabled,
  isLoading,
  loadOptions,
  containerStyle = {},
  creatable = false,
  onCreateOption = () => {},
  defaultOptions = [],
  controlStyle = {},
  menuStyle = {},
  isClearable,
  iconLeft = null,
  debounceInput = true,
}) => {
  const onChangeCallback = useCallback(
    selected => {
      console.log(`onChangeCallback`, selected)
      if (!selected) {
        onChange({ target: { name, value: undefined } })
        return
      }
      // Param to skip headers/options not searchable eg. Conditions & Treatments
      if (selected.data && selected.data.no_op) {
        return
      }
      onChange({ target: { name, value: selected.value, data: selected.data } })
    },
    [onChange, name],
  )

  const onCreateCallback = useCallback(
    selected => {
      onCreateOption({
        target: { name, value: selected },
      })
    },
    [onCreateOption, name],
  )

  let searchOptions = loadOptions
  if (debounceInput) {
    searchOptions = debounce(loadOptions, 400)
  }

  const customStyles = dropdownStyles({
    containerStyle,
    controlStyle,
    menuStyle,
  })

  if (creatable) {
    return (
      <AsyncCreatableSelect
        id={name}
        classNamePrefix="react-select"
        name={name}
        // Component changes from uncontrolled to controlled when there is a value
        // Otherwise the placeholder doesn't work?
        value={value && value.length > 0 ? { label: value, name: value } : ''}
        placeholder={placeholder}
        onChange={onChangeCallback}
        styles={customStyles}
        loadOptions={(inputValue, callback) => {
          if (debounceInput) {
            if (inputValue.length > 1) {
              return searchOptions(inputValue, callback)
            } else {
              return callback([])
            }
          }

          return searchOptions(inputValue, callback)
        }}
        isDisabled={disabled}
        onFocus={e => {
          if (e.target.autocomplete) {
            e.target.autocomplete = 'nope'
          }
        }}
        onCreateOption={onCreateCallback}
        defaultOptions={defaultOptions}
        noOptionsMessage={e => (e.inputValue ? 'No Options' : null)}
        isClearable={isClearable}
        iconLeft={iconLeft}
        components={{
          ClearIndicator,
          DropdownIndicator,
          Control,
        }}
      />
    )
  }

  return (
    <AsyncSelect
      id={name}
      inputId={`${name}-input`}
      classNamePrefix="react-select"
      name={name}
      // Component changes from uncontrolled to controlled when there is a value
      // Otherwise the placeholder doesn't work?
      value={value && value.length > 0 ? { label: value, name: value } : ''}
      placeholder={placeholder}
      onChange={onChangeCallback}
      styles={customStyles}
      loadOptions={(inputValue, callback) => {
        if (debounceInput) {
          if (inputValue.length > 1) {
            return searchOptions(inputValue, callback)
          } else {
            return callback([])
          }
        }

        return searchOptions(inputValue, callback)
      }}
      isDisabled={disabled}
      isLoading={isLoading}
      onFocus={e => {
        if (e.target.autocomplete) {
          e.target.autocomplete = 'nope'
        }
      }}
      defaultOptions={defaultOptions}
      noOptionsMessage={e => (e.inputValue ? 'No Options' : null)}
      isClearable={isClearable}
      iconLeft={iconLeft}
      components={{
        ClearIndicator,
        DropdownIndicator,
        Control,
        Option: CustomOption,
      }}
      menuPosition="fixed"
      menuShouldBlockScroll={true}
      openMenuOnFocus
    />
  )
}

AsyncSelectInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  loadOptions: PropTypes.func,
  containerStyle: PropTypes.object,
  creatable: PropTypes.bool,
  onCreateOption: PropTypes.func,
  controlStyle: PropTypes.object,
  defaultOptions: PropTypes.array,
  isClearable: PropTypes.bool,
  iconLeft: PropTypes.any,
}
