import React, { useCallback } from 'react'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Tag, TAG_SIZE } from 'design-system/tag'
import { filterLanguages } from 'utils/constants'
import { AsyncSelectInput } from 'design-system/input/async-select-input'
import { Flex } from 'design-system/flex'

export const ProviderLanguages = ({
  editProvider = {},
  setEditProvider = () => {},
}) => {
  const { languages = [] } = editProvider

  const onChange = useCallback(e => {
    const { value } = e.target
    setEditProvider(prev => {
      const languagesCopy = prev.languages.slice()

      languagesCopy.push(value)
      return {
        ...prev,
        languages: languagesCopy,
      }
    })
  }, [])

  const onClickTag = useCallback(language => {
    setEditProvider(prev => {
      const languagesCopy = prev.languages.slice()
      const foundLanguageIndex = languagesCopy.findIndex(i => i === language)
      if (foundLanguageIndex > -1) {
        languagesCopy.splice(foundLanguageIndex, 1)
        return {
          ...prev,
          languages: languagesCopy,
        }
      }
      return prev
    })
  }, [])

  const loadOptions = useCallback(
    async (inputValue, callback) => {
      if (inputValue.length > 0) {
        try {
          const results = await filterLanguages(inputValue)
          const filteredResults = results.filter(
            option =>
              !languages.find(
                l => l.toLowerCase() === option.value.toLowerCase(),
              ),
          )
          callback(filteredResults)
        } catch (error) {
          callback([])
        }
      }
      callback([])
    },
    [languages],
  )

  return (
    <Flex
      s={() => ({
        flexDirection: 'column',
      })}
      data-testid="languages"
    >
      <Heading
        type={HEADING_TYPE.H2}
        s={p => ({
          marginBottom: p.theme.sizes.four,
        })}
      >
        Languages
      </Heading>
      <Flex s={() => ({ flexWrap: 'wrap' })}>
        {languages &&
          languages.map(item => {
            return (
              <Tag
                size={TAG_SIZE.SMALL}
                key={item}
                onClick={() => onClickTag(item)}
                showClearIcon
                s={p => ({
                  marginRight: p.theme.sizes.four,
                  marginBottom: p.theme.sizes.two,
                })}
              >
                <Label type={LABEL_TYPE.BODY1}>
                  {item.length > 0
                    ? `${item[0].toUpperCase() + item.slice(1)}`
                    : item}
                </Label>
              </Tag>
            )
          })}
      </Flex>
      <Flex
        s={p => ({
          marginTop: p.theme.sizes.four,
        })}
      >
        <AsyncSelectInput
          onChange={onChange}
          name="newLanguage"
          containerStyle={{ width: '100%' }}
          placeholder="Add a language.."
          loadOptions={loadOptions}
        />
      </Flex>
    </Flex>
  )
}
