/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
import { SortingHeader } from 'components/table/sorting-header'
import { useOnClickOutside } from 'hooks/useOutsideClick'
import { Block } from 'design-system/block'
import { Label } from 'design-system/label'
import { DropdownMenu } from 'components/dropdown/dropdown'

const createTestData = (size = 20) => {
  const res = []
  for (let index = 0; index < size; index++) {
    res.push({
      uuid: `token_${index}`,
      token: `token_${index}`,
      status: index % 2 === 0 ? 'Active' : 'Expired',
      last_used: `{{date}}`,
      created_at: `{{date}}`,
    })
  }
  return res
}
export const tokensTestData = createTestData(6)

const MoreMenuCell = ({ custom, cell, page, state: tableState }) => {
  const { actions, state } = custom
  const { onClickMore, onClickRemoveToken } = actions
  const { activeMenu } = state
  const menuRef = useRef()
  const { original: token, index } = cell.row
  const showMenu = activeMenu === token.uuid

  useOnClickOutside(menuRef, () => {
    if (showMenu) {
      onClickMore(null)
    }
  })

  let inverseDropdown = false
  if (page && page.length >= 3) {
    const { pageIndex, pageSize } = tableState
    const pageMultiple = pageIndex * pageSize
    // page mutiple because index = index of all table results which doesnt account current pages
    // eg. page 2 - index(22) - pagesize(20) -> should not inverse
    let pageLengthMinusItemIndex = page.length - (index - pageMultiple)
    // 3 results is minimum to start pointing dropdown upwards(inverse)
    let differenceForInverse = page.length <= 3 ? 1 : 2

    if (pageLengthMinusItemIndex <= differenceForInverse) {
      inverseDropdown = true
    }
  }

  return (
    <div ref={menuRef}>
      <Block
        s={() => ({
          position: 'relative',
        })}
      >
        <Label
          s={() => ({
            fontWeight: 'bold',
            fontSize: '1.25em',
            textAlign: 'center',
          })}
          onClick={() => onClickMore(token)}
        >
          ...
        </Label>
        {showMenu && (
          <DropdownMenu
            inverse={inverseDropdown}
            options={[
              {
                title: 'Remove token',
                onClick: () => {
                  onClickRemoveToken(token)
                  onClickMore(null)
                },
              },
            ]}
          />
        )}
      </Block>
    </div>
  )
}

export const tokensTableColumns = [
  {
    Header: SortingHeader,
    headerTitle: 'Token',
    accessor: 'token', // accessor is the "key" in the data
    defaultCanSort: true,
  },
  {
    Header: SortingHeader,
    headerTitle: 'Status',
    accessor: 'status', // accessor is the "key" in the data
    defaultCanSort: false,
  },
  {
    Header: SortingHeader,
    headerTitle: 'Last Used',
    accessor: 'last_used', // accessor is the "key" in the data
    defaultCanSort: true,
  },
  {
    Header: SortingHeader,
    headerTitle: 'Created',
    accessor: 'created_at', // accessor is the "key" in the data
    defaultCanSort: true,
  },
  {
    Header: () => {
      return null
    },
    headerTitle: '',
    Cell: MoreMenuCell,
    accessor: 'more_menu', // accessor is the "key" in the data
    minWidth: 40, // minWidth is only used as a limit for resizing
    width: 40, // width is used for both the flex-basis and flex-grow
    disableSortBy: true,
  },
]

export const tableDefaultColumn = {
  // When using the useFlexLayout:
  minWidth: 80, // minWidth is only used as a limit for resizing
  width: 180, // width is used for both the flex-basis and flex-grow
  maxWidth: 300,
}
