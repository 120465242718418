import React, { useContext, useReducer } from 'react'
import styled from 'styled-components'
import { services } from 'services'
import { Input, INPUT_SIZE, INPUT_TYPE } from 'design-system/input'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label, LABEL_TYPE } from 'design-system/label'
import { validPassword } from 'utils/password.util'
import { AuthStateContext } from 'context/auth-state-context'
import ChangePasswordPageImage from 'assets/change-password.svg'
import { Flex } from 'design-system/flex'
import { useToggle } from 'hooks/useToggle'
import ShowSVG from 'assets/show.svg'
import { PasswordValidations } from 'components/password-validations/password-validations'

const Container = styled(Flex)`
  flex: 1;
  padding: ${p => p.theme.sizes.eight};
  overflow-y: auto;

  @media (max-width: ${p => p.theme.flexboxgrid.breakpoints.sm}em) {
    padding: ${p => p.theme.sizes.five};
    > div {
      justify-content: center;
    }
  }
`

const RightImageContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media (max-width: ${p => p.theme.flexboxgrid.breakpoints.sm}em) {
    display: none;
  }
`
const ACTION_TYPES = {
  loading: 'loading',
  update: 'update',
  error: 'error',
}
const initialState = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
  success: false,
  loading: false,
  errors: {},
}

const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case ACTION_TYPES.loading: {
      return {
        ...state,
        loading: payload,
      }
    }
    case ACTION_TYPES.update: {
      return {
        ...state,
        ...payload,
      }
    }
    case ACTION_TYPES.error: {
      return {
        ...state,
        loading: false,
        errors: {
          ...payload,
        },
      }
    }

    default:
      return state
  }
}

export const ChangePassword = () => {
  const [authState] = useContext(AuthStateContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  const [showOldPassword, toggleOldPassword] = useToggle(false)
  const [showNewPassword, toggleNewPassword] = useToggle(false)
  const [showConfirmPassword, toggleConfirmPassword] = useToggle(false)

  const {
    oldPassword,
    newPassword,
    confirmNewPassword,
    loading,
    errors,
    success,
  } = state

  const onChangeText = e => {
    const { name, value } = e.target

    dispatch({
      type: 'update',
      payload: {
        [name]: value,
        ...(state.errors[name] && {
          errors: {
            [name]: undefined,
          },
        }),
      },
    })
  }

  const handleButtonClick = async () => {
    if (newPassword !== confirmNewPassword) {
      dispatch({
        type: ACTION_TYPES.update,
        payload: {
          errors: {
            formError: 'Passwords do not match',
          },
        },
      })
      return
    }
    try {
      dispatch({
        type: ACTION_TYPES.loading,
        payload: true,
      })
      await services.ribbon.changePassword(authState.token, {
        old_password: oldPassword,
        new_password1: newPassword,
        new_password2: confirmNewPassword,
      })
      dispatch({
        type: ACTION_TYPES.update,
        payload: {
          success: true,
          loading: false,
        },
      })
    } catch (error) {
      console.log('error', error.response)
      const { message, status } = error.response || {}
      dispatch({
        type: ACTION_TYPES.error,
        payload: {
          formError:
            message || (status && status === 400)
              ? 'Incorrect password entered.'
              : error.message,
        },
      })
    }
  }

  const passwordValidations = validPassword(newPassword, true)
  const confirmPasswordValidations = validPassword(confirmNewPassword, true)

  const invalidPasswords =
    passwordValidations.length > 0 ||
    confirmPasswordValidations.length > 0 ||
    oldPassword.length === 0

  return (
    <Container>
      <Flex s={() => ({ flex: 1, alignItems: 'flex-start' })}>
        <Flex
          s={p => ({
            flex: 1,
            flexDirection: 'column',
            border: `1px solid ${p.theme.colors.gray1}`,
            borderRadius: p.theme.sizes.four,
            padding: p.theme.sizes.eight,
            textAlign: 'center',
            maxWidth: '480px',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          {success ? (
            <>
              <Label
                s={p => ({
                  fontSize: p.theme.sizes.six,
                  fontWeight: 800,
                  margin: `${p.theme.sizes.six} 0`,
                })}
              >
                Password successfully changed!
              </Label>
            </>
          ) : (
            <>
              <Label
                s={p => ({ fontSize: p.theme.sizes.six, fontWeight: 800 })}
              >
                Change Password
              </Label>

              <Input
                showClearIcon={false}
                type={INPUT_TYPE.ROUND}
                size={INPUT_SIZE.EXTRA_LARGE}
                s={p => ({ marginTop: p.theme.sizes.seven, width: '100%' })}
                placeholder={'Old Password'}
                name="oldPassword"
                value={(oldPassword && [{ label: oldPassword }]) || undefined}
                onInputChange={onChangeText}
                inputType={showOldPassword ? 'text' : 'password'}
                rightIcon={() => (
                  <EyeIcon
                    togglePassword={toggleOldPassword}
                    showPassword={showOldPassword}
                  />
                )}
              />

              <Input
                showClearIcon={false}
                type={INPUT_TYPE.ROUND}
                size={INPUT_SIZE.EXTRA_LARGE}
                s={p => ({ marginTop: p.theme.sizes.four, width: '100%' })}
                placeholder="New Password"
                name="newPassword"
                value={[{ label: newPassword }]}
                onInputChange={onChangeText}
                inputType={showNewPassword ? 'text' : 'password'}
                rightIcon={() => (
                  <EyeIcon
                    togglePassword={toggleNewPassword}
                    showPassword={showNewPassword}
                  />
                )}
              />

              <PasswordValidations passwordValidations={passwordValidations} />

              <Input
                showClearIcon={false}
                type={INPUT_TYPE.ROUND}
                size={INPUT_SIZE.EXTRA_LARGE}
                s={p => ({ marginTop: p.theme.sizes.four, width: '100%' })}
                placeholder="Confirm New Password"
                name="confirmNewPassword"
                value={[{ label: confirmNewPassword }]}
                onInputChange={onChangeText}
                inputType={showConfirmPassword ? 'text' : 'password'}
                rightIcon={() => (
                  <EyeIcon
                    togglePassword={toggleConfirmPassword}
                    showPassword={showConfirmPassword}
                  />
                )}
              />

              {errors.formError && (
                <Label
                  type={LABEL_TYPE.ERROR}
                  s={p => ({
                    textAlign: 'center',
                    alignSelf: 'center',
                    marginTop: p.theme.sizes.four,
                  })}
                >
                  {errors.formError}
                </Label>
              )}

              <Button
                s={p => ({
                  marginTop: p.theme.sizes.eight,
                  width: '100%',
                })}
                type={
                  invalidPasswords ? BUTTON_TYPE.DISABLED : BUTTON_TYPE.PRIMARY
                }
                size={BUTTON_SIZE.EXTRA_LARGE}
                onClick={handleButtonClick}
                disabled={invalidPasswords || loading}
                loading={loading}
              >
                Save Password
              </Button>
            </>
          )}
        </Flex>
      </Flex>
      <RightImageContainer>
        <ChangePasswordPageImage />
      </RightImageContainer>
    </Container>
  )
}

const ShowButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  opacity: ${p => (p.showPassword ? '0.33' : '1')};
  cursor: pointer;
`
const EyeIcon = ({ togglePassword = () => {}, showPassword = false }) => {
  return (
    <ShowButton onClick={togglePassword} showPassword={showPassword}>
      <ShowSVG height="16px" width="16px" />
    </ShowButton>
  )
}
