import React, { useMemo, useState, useContext } from 'react'
import { navigate } from 'gatsby'
import { DetailSearchInput } from 'components/detail-search-input/detail-search-input'
import { Block } from 'design-system/block'
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
  useFlexLayout,
} from 'react-table'
import { Table } from 'components/table/table'
import {
  customFilterTypes,
  tableDefaultColumn,
  instancesColumns,
} from './instances-table-options'
import { Label, LABEL_TYPE } from 'design-system/label'
import { StatusFilterDropdown } from '../components/status-filter-dropdown'
import { DateFilterDropdown } from '../components/date-filter-dropdown'
import { useQuery } from 'react-query'
import { services } from 'services'
import { AuthStateContext } from 'context/auth-state-context'
import { Spinner } from 'design-system/spinner/spinner.component'
import { FilterDropdown } from 'components/dropdown/filter-dropdown'

export const InstancesTable = () => {
  const [showMenu, setShowMenu] = useState()
  const [authState] = useContext(AuthStateContext)

  const { data: applicationsData, isLoading } = useQuery(
    'applications',
    () =>
      services.ribbon.listApplications(authState.token, {
        has_fca_instance: true,
      }),
    {
      enabled: !!(authState && authState.token),
      retry: false,
    },
  )

  const defaultColumn = useMemo(() => tableDefaultColumn, [])
  const filterTypes = useMemo(() => customFilterTypes, [])
  const dataMemo = useMemo(
    () => (applicationsData ? applicationsData.data : []),
    [applicationsData],
  )
  const columnsMemo = useMemo(() => instancesColumns, [instancesColumns])

  const [search, setSearch] = useState('')

  const tableInstance = useTable(
    {
      columns: columnsMemo,
      data: dataMemo,
      initialState: { pageIndex: 0, pageSize: 20 },
      defaultColumn,
      filterTypes,
      globalFilter: 'instancesSearch',
    },
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const { setGlobalFilter, setFilter, state } = tableInstance

  const onSearch = event => {
    const { value } = event.target

    setSearch(value)
    setGlobalFilter(value)
  }

  const clearSearch = () => {
    setSearch('')
    setGlobalFilter('')
  }

  const onClickRow = row => {
    console.log('onClickRow', row)
    const { original: instance } = row
    navigate(`/admin/instances/${instance.uuid}/users`, {
      state: {
        instance,
      },
    })
  }

  const onClickClearFilter = type => {
    setShowMenu()
    setFilter(type)
  }

  const onClickApplyFilter = (type, filter) => {
    setShowMenu()
    setFilter(type, filter)
  }

  const statusFilter = state.filters.find(i => i.id === 'status')
  const dateCreatedFilter = state.filters.find(i => i.id === 'created_at')

  return (
    <>
      <Label
        type={LABEL_TYPE.BODY1}
        s={p => ({
          fontWeight: '400',
          fontSize: p.theme.headingSizes.H3,
          color: p.theme.colors.textLightest,
          marginTop: p.theme.sizes.two,
        })}
      >
        {`${
          isLoading
            ? '-'
            : (applicationsData ? applicationsData.data : []).length
        } instances`}
      </Label>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Block
            s={p => ({
              padding: 0,
              marginTop: p.theme.sizes.nine,
              marginBottom: p.theme.sizes.four,
            })}
          >
            <DetailSearchInput
              placeholder="Search instance name"
              value={search}
              onChange={clearSearch}
              onInputChange={onSearch}
            />
            <Block
              type="FLEX"
              s={() => ({
                alignItems: 'center',
                justifyContent: 'flex-start',
              })}
            >
              <FilterDropdown
                label={
                  dateCreatedFilter
                    ? `Date Created: ${dateCreatedFilter.value.display}`
                    : 'Date Created'
                }
                onClickFilter={setShowMenu}
                showMenu={showMenu}
                closeMenu={() => setShowMenu()}
                renderMenu={
                  <DateFilterDropdown
                    onClickClearFilter={onClickClearFilter}
                    onClickApplyFilter={onClickApplyFilter}
                    dateCreatedFilter={dateCreatedFilter}
                  />
                }
              />
              <FilterDropdown
                label={
                  statusFilter
                    ? `Token Status: ${statusFilter.value}`
                    : 'Token Status'
                }
                onClickFilter={setShowMenu}
                closeMenu={() => setShowMenu()}
                showMenu={showMenu}
                renderMenu={
                  <StatusFilterDropdown
                    onClickClearFilter={onClickClearFilter}
                    onClickApplyFilter={onClickApplyFilter}
                    statusFilter={statusFilter}
                  />
                }
              />
            </Block>
          </Block>
          <Table
            tableInstance={tableInstance}
            onClickRow={onClickRow}
            loading={false}
          />
        </>
      )}
    </>
  )
}
