import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

export const LABEL_TYPE = {
  BODY1_BOLD: 'BODY1_BOLD',
  BODY1_LIGHT: 'BODY1_LIGHT',
  BODY1: 'BODY1',
  BODY2_BOLD: 'BODY2_BOLD',
  BODY2_LIGHT: 'BODY2_LIGHT',
  BODY2: 'BODY2',
  BODY3_LIGHT: 'BODY3_LIGHT',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  LINK: 'LINK',
}

const UnstyledLabel = props => {
  return (
    <p
      onClick={props.onClick}
      className={props.className}
      data-tip={props['data-tip']}
      data-testid={props['data-testid']}
      id={props.id}
    >
      {props.children}
    </p>
  )
}

const TYPE_STYLES = {
  BODY1: p => `
    font-size: ${p.theme.bodySizes.BODY1};
    font-weight: 400;
  `,
  BODY1_LIGHT: p => `
    font-size: ${p.theme.bodySizes.BODY1};
    font-weight: 300;
  `,
  BODY1_BOLD: p => `
    font-size: ${p.theme.bodySizes.BODY1};
    font-weight: 600;
  `,
  BODY2: p => `
    font-size: ${p.theme.bodySizes.BODY2};
    font-weight: 400;
  `,
  BODY2_LIGHT: p => `
    font-size: ${p.theme.bodySizes.BODY2};
    font-weight: 300;
  `,
  BODY2_BOLD: p => `
    font-size: ${p.theme.bodySizes.BODY2};
    font-weight: 600;
  `,
  BODY3_LIGHT: p => `
    font-size: ${p.theme.bodySizes.BODY3};
    font-weight: 300;
  `,
  ERROR: p => `
    font-size: ${p.theme.bodySizes.BODY1};
    font-weight: 400;
    color: ${p.theme.colors.errorRed};
  `,
  SUCCESS: p => `
    font-size: ${p.theme.bodySizes.BODY1};
    font-weight: 400;
    color: ${p.theme.colors.successGreen};
  `,
  LINK: p => `
  font-size: ${p.theme.bodySizes.BODY1};
  font-weight: 500;
  color: ${p.theme.colors.aubergine};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  `,
}

UnstyledLabel.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(TYPE_STYLES)),
  'data-tip': PropTypes.string,
  'data-testid': PropTypes.string,
}

UnstyledLabel.defaultProps = {
  type: LABEL_TYPE.BODY1,
}

export const Label = styled(UnstyledLabel)`
  cursor: ${p => (p.onClick && 'pointer') || undefined};
  ${props => TYPE_STYLES[props.type]}
  ${props => (props.s ? css(props.s(props)) : '')}
`

Label.propTypes = {
  s: PropTypes.func,
}

Label.displayName = 'Label'
