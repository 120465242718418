import React, { useState } from 'react'
import { DetailSearchInput } from 'components/detail-search-input/detail-search-input'
import { Block } from 'design-system/block'
import { Table } from 'components/table/table'

export const UsersTable = ({
  tableInstance = {},
  onClickUpdatePermissions = () => {},
  onClickRemoveUsers = () => {},
  onClickResendInvite = () => {},
}) => {
  const [search, setSearch] = useState('')
  const { setGlobalFilter } = tableInstance
  const [activeMenu, setActiveMenu] = useState(null)
  const onSearch = event => {
    const { value } = event.target

    setSearch(value)
    setGlobalFilter(value)
  }

  const clearSearch = () => {
    setSearch('')
    setGlobalFilter('')
  }

  const onClickMore = user => {
    console.log(`onClickMore`, user)
    if (user === null) {
      setActiveMenu(null)
    } else {
      setActiveMenu(user.id)
    }
  }

  return (
    <>
      <Block
        s={p => ({
          padding: 0,
          marginTop: p.theme.sizes.nine,
          marginBottom: p.theme.sizes.four,
        })}
      >
        <DetailSearchInput
          placeholder="Search users"
          value={search}
          onChange={clearSearch}
          onInputChange={onSearch}
        />
      </Block>
      <Table
        tableInstance={tableInstance}
        loading={false}
        custom={{
          actions: {
            onClickMore,
            onClickUpdatePermissions,
            onClickRemoveUsers,
            onClickResendInvite,
          },
          state: {
            activeMenu,
          },
        }}
      />
    </>
  )
}
