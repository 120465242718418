import React from 'react'
import { Label } from 'design-system/label'

export const DataCardContent = ({
  title = 'Title',
  description = 'Description',
  children = [],
}) => {
  return (
    <>
      <Label
        s={p => ({
          color: p.theme.colors.gray2,
          fontWeigth: '600',
        })}
      >
        {title}
      </Label>
      {children}
      <Label
        s={p => ({
          color: p.theme.colors.gray2,
        })}
      >
        {description}
      </Label>
    </>
  )
}
