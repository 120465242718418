import dayjs from 'utils/dayjs'

const URLS = [
  '/v1/custom/insurances',
  '/v1/custom/providers?name=test',
  '/v1/custom/providers',
  '/v1/custom/specialties',
  '/v1/custom/locations',
  '/v1/custom/deductible',
]

const REQUEST_BODYS = [
  {
    name: 'Family Dental Health of Five Forks',
    address: '216 Scuffletown Rd, Simpsonville, SC 29681, US',
  },
  { confirmed: true, confirmed_at: '2021-06-07T16:01:18.503Z' },
  {
    name: 'Classic Dental Group',
    address: 'Cleveland Ct 6 A , Greenville, SC 29607, US',
  },
]

const METHODS = ['GET', 'PUT', 'POST', 'DELETE']
const STATUSES = ['200', '400', '500']

const getRandomFromList = list => {
  return list[Math.floor(Math.random() * list.length)]
}

const getRandomNumber = max => {
  return Math.floor(Math.random() * max)
}

export const mockLog = index => {
  const method = getRandomFromList(METHODS)
  return {
    id: `index-${index}`,
    url: getRandomFromList(URLS),
    created_at: dayjs().subtract(index, 'day').toISOString(),
    method,
    status: getRandomFromList(STATUSES),
    ...((method === 'POST' || method === 'PUT') && {
      request_body: getRandomFromList(REQUEST_BODYS),
    }),
  }
}

const createTestData = (size = 20) => {
  const res = []
  for (let index = 0; index < size; index++) {
    res.push(mockLog(index))
  }
  return res
}

export const mockApiLogsData = createTestData(500)

// eslint-disable-next-line no-unused-vars
export const createUsageTestData = (viewOption, months, endpoints, methods) => {
  const today = dayjs()
  const data = []
  const timeUnit = months >= 6 ? 'month' : 'week'
  for (let index = months >= 6 ? months : months * 4; index >= 0; index--) {
    const item = {
      date: today.subtract(index, timeUnit).format('MM/DD'),
      'All endpoints': getRandomNumber(10000),
      'All methods': getRandomNumber(10000),
      '/v1/custom/providers': getRandomNumber(2000),
      '/v1/custom/locations': getRandomNumber(2000),
      '/v1/custom/deductible': getRandomNumber(2000),
      '/v1/custom/insurances': getRandomNumber(2000),
      '/v1/custom/specialties': getRandomNumber(2000),
      GET: getRandomNumber(2000),
      PUT: getRandomNumber(2000),
      POST: getRandomNumber(2000),
      DELETE: getRandomNumber(2000),
    }
    data.push(item)
  }

  return data
}
