import React from 'react'
import PropTypes from 'prop-types'
import { SelectInput } from 'design-system/input/select-input'
import { theme } from 'theme'
import { Icon } from 'design-system/icon'

const SearchIcon = () => (
  <Icon size={16}>
    <path
      d="M15.8484 15.1295L11.7175 11.0646C12.7993 9.8893 13.4639 8.33495 13.4639 6.62455C13.4634 2.96568 10.4497 0 6.73183 0C3.01395 0 0.00025177 2.96568 0.00025177 6.62455C0.00025177 10.2834 3.01395 13.2491 6.73183 13.2491C8.33821 13.2491 9.81154 12.6935 10.9688 11.7697L15.1158 15.8507C15.3179 16.0498 15.6459 16.0498 15.848 15.8507C16.0505 15.6517 16.0505 15.3286 15.8484 15.1295ZM6.73183 12.2299C3.5861 12.2299 1.036 9.72029 1.036 6.62455C1.036 3.52881 3.5861 1.01923 6.73183 1.01923C9.87758 1.01923 12.4277 3.52881 12.4277 6.62455C12.4277 9.72029 9.87758 12.2299 6.73183 12.2299Z"
      fill="#4F4C4D"
    />
  </Icon>
)

const ALL_PROVIDER_PARAMS = [
  { label: 'address', value: 'address' },
  { label: 'specialty_ids', value: 'specialty_ids' },
  { label: 'specialty', value: 'specialty' },
  { label: 'insurance_ids', value: 'insurance_ids' },
  { label: 'name', value: 'name' },
  { label: 'location_ids', value: 'location_ids' },
  { label: 'location', value: 'location' },
  { label: 'npis', value: 'npis' },
  { label: 'provider_types', value: 'provider_types' },
  { label: 'min_location_confidence', value: 'min_location_confidence' },
  { label: 'min_confidence', value: 'min_confidence' },
  { label: 'gender', value: 'gender' },
  { label: 'max_age', value: 'max_age' },
  { label: 'min_age', value: 'min_age' },
  { label: 'language', value: 'language' },
  { label: 'location_within_distance', value: 'location_within_distance' },
]
const ALL_LOCATIONS_PARAMS = [
  { label: 'address', value: 'address' },
  { label: 'name', value: 'name' },
  { label: 'distance', value: 'distance' },
  { label: 'location_types', value: 'location_types' },
  { label: 'location', value: 'location' },
  { label: 'min_rating', value: 'min_rating' },
  { label: 'location_ids', value: 'location_ids' },
]

export const ParameterSelect = ({
  param,
  onParamSelect = () => {},
  type = 'providers',
  index,
  allParams,
}) => {
  const filteredOptions =
    type === 'providers'
      ? ALL_PROVIDER_PARAMS.filter(
          p =>
            !allParams.some(
              ap => ap.selectedParam && ap.selectedParam.label === p.label,
            ),
        )
      : ALL_LOCATIONS_PARAMS.filter(
          p =>
            !allParams.some(
              ap => ap.selectedParam && ap.selectedParam.label === p.label,
            ),
        )
  return (
    <SelectInput
      onChange={e => {
        console.log(e)
        const { value, label } = e.target
        if (value) {
          onParamSelect(index, { value, label })
        } else {
          onParamSelect(index, undefined)
        }
      }}
      name="type"
      value={param.selectedParam ? param.selectedParam.label : ''}
      options={filteredOptions}
      containerStyle={{
        width: '300px',
      }}
      controlStyle={{
        borderRadius: 0,
        border: `1px solid ${theme.colors.borderLight}`,
        backgroundColor: theme.colors.white,
        ':hover': {
          borderColor: theme.colors.borderLight,
          cursor: 'text',
        },
        ...(index > 0 && {
          borderTop: 'none',
        }),
      }}
      isClearable={false}
      placeholder="Select Parameter"
      iconLeft={
        index === 0 ? SearchIcon : () => <div style={{ minWidth: '16px' }} />
      }
    />
  )
}

ParameterSelect.propTypes = {
  param: PropTypes.object,
  onParamSelect: PropTypes.func,
  type: PropTypes.string,
  index: PropTypes.number,
  allParams: PropTypes.array,
}
