import React, { useCallback, useContext } from 'react'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Flex } from 'design-system/flex'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Tag, TAG_SIZE } from 'design-system/tag'
import { services } from 'services'
import { AsyncSelectInput } from 'design-system/input/async-select-input'
import { AuthStateContext } from 'context/auth-state-context'

export const ProviderSpecialties = ({
  editProvider = {},
  setEditProvider = () => {},
}) => {
  const [authState = {}] = useContext(AuthStateContext)
  const { specialties = [] } = editProvider

  const loadOptions = useCallback(
    async (inputValue, callback) => {
      if (inputValue.length > 0) {
        try {
          const results = await services.ribbon.searchSpecialties(
            authState.token,
            {
              search: inputValue,
            },
          )
          console.log('searchSpecialties Results', results)
          if (results && results.data) {
            const filteredResults = results.data
              ? results.data.reduce((acc, next) => {
                  acc.push({
                    data: next,
                    label: next.display,
                    value: next.display,
                  })
                  return acc
                }, [])
              : []

            callback(filteredResults)
          }
        } catch (error) {
          console.log('Specialty Search Error: ', error)
          callback([])
        }
      }
      callback([])
    },
    [authState.token],
  )

  const onChange = useCallback(e => {
    console.log('Selection', e)
    const { data } = e.target
    setEditProvider(prev => {
      const specialtiesCopy = prev.specialties.slice()
      const foundSelection = prev.specialties.find(i => i.uuid === data.uuid)
      if (!foundSelection) {
        const newItem = { ...data }
        specialtiesCopy.push(newItem)
        return {
          ...prev,
          specialties: specialtiesCopy,
        }
      }
      return prev
    })
  }, [])

  const onClickTag = useCallback(uuid => {
    setEditProvider(prev => {
      const specialtiesCopy = prev.specialties.slice()
      const foundSpecialtyIndex = specialtiesCopy.findIndex(
        i => i.uuid === uuid,
      )
      if (foundSpecialtyIndex > -1) {
        specialtiesCopy.splice(foundSpecialtyIndex, 1)
        return {
          ...prev,
          specialties: specialtiesCopy,
        }
      }
      return prev
    })
  }, [])

  return (
    <Flex
      s={() => ({
        flexDirection: 'column',
      })}
      data-testid="specialties"
    >
      <Heading
        type={HEADING_TYPE.H2}
        s={p => ({
          marginBottom: p.theme.sizes.four,
        })}
      >
        Specialties
      </Heading>
      <Flex s={() => ({ flexWrap: 'wrap' })}>
        {specialties.map(item => {
          const { uuid, display } = item
          return (
            <Tag
              size={TAG_SIZE.SMALL}
              key={uuid}
              onClick={() => onClickTag(uuid)}
              showClearIcon
              s={p => ({
                marginRight: p.theme.sizes.four,
                marginBottom: p.theme.sizes.two,
              })}
            >
              <Label type={LABEL_TYPE.BODY1}>{display}</Label>
            </Tag>
          )
        })}
      </Flex>
      <Flex
        s={p => ({
          marginTop: p.theme.sizes.four,
        })}
      >
        <AsyncSelectInput
          onChange={onChange}
          name="newSpecialty"
          loadOptions={loadOptions}
          containerStyle={{ marginTop: '0.25rem', width: '100%' }}
          placeholder="Add a specialty.."
        />
      </Flex>
    </Flex>
  )
}
