import React from 'react'
import PropTypes from 'prop-types'
import { Block } from 'design-system/block'
import { Label, LABEL_TYPE } from 'design-system/label'
import { DotIndicators } from 'components/dot-indicators/dot-indicators'
import { CostIndicators } from 'components/cost-indicators/cost-indicators'
import styled from 'styled-components'
import { InfoIndicator } from 'components/info-indicator/info-indicator'

export const CareJourney = ({ performance }) => {
  if (!performance || !performance.aggregate) {
    return null
  }
  const { aggregate } = performance
  const { cost, quality } = aggregate
  // scale out of 5
  return (
    <Block
      s={() => ({
        padding: 0,
      })}
    >
      <Block
        type="FLEX"
        s={p => ({
          padding: 0,
          marginBottom: p.theme.sizes.two,
          alignItems: 'center',
        })}
      >
        <StyledLabel>Relative Cost</StyledLabel>

        <InfoIndicator
          id="custom-tooltip-cost"
          message="A clinician’s relative cost score measures how expensive they are compared to clinicians treating similar types of patients."
        />
        <StyledLabel
          s={p => ({
            marginRight: p.theme.sizes.one,
          })}
        >
          :
        </StyledLabel>
        {cost && cost.efficiency_index ? (
          <CostIndicators
            // Reversing cost -> value of 5 = 1($) and value of 1 = 5($)s
            total={Math.abs(Math.round(cost.efficiency_index - 6))}
          />
        ) : (
          <Label
            type={LABEL_TYPE.BODY1}
            s={p => ({
              color: p.theme.colors.gray,
            })}
          >
            -
          </Label>
        )}
      </Block>
      <Block
        type="FLEX"
        s={p => ({
          padding: 0,
          marginBottom: p.theme.sizes.two,
          alignItems: 'center',
        })}
      >
        <StyledLabel>Relative Quality</StyledLabel>
        <InfoIndicator
          id="custom-tooltip-quality"
          message="A clinician’s relative quality score measures how the clinician’s outcomes compared to those of clinicians treating similar types of patients."
        />
        <StyledLabel
          s={p => ({
            marginRight: p.theme.sizes.one,
          })}
        >
          :
        </StyledLabel>
        {quality && quality.outcomes_index ? (
          <DotIndicators total={Math.round(quality.outcomes_index)} />
        ) : (
          <Label
            type={LABEL_TYPE.BODY1}
            s={p => ({
              color: p.theme.colors.gray,
            })}
          >
            -
          </Label>
        )}
      </Block>
    </Block>
  )
}

const StyledLabel = styled(Label)`
  color: ${p => p.theme.colors.gray};
  font-weight: 500;
  font-size: ${p => p.theme.bodySizes.BODY1};
`

CareJourney.propTypes = {
  performance: PropTypes.object,
}
