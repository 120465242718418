import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'
import { Label } from 'design-system/label'
import { PermissionRestricted } from 'components/permission-restricted/permission-restricted'
import { Flex } from 'design-system/flex'
import { EditDetailButton } from 'components/edit-detail-btn/edit-detail-btn'
import { InfoIndicator } from 'components/info-indicator/info-indicator'
import {isLocationVirtual, isPartnerLocation} from 'utils/location'
import {PhysicalLocationPanel} from 'components/locations-panel/physical-location-panel'
import {VirtualLocationPanel} from 'components/locations-panel/virtual-location-panel'

const Container = styled(Flex)`
  flex-direction: column;
`

export const LocationsPanel = ({
  location = {},
  setSelectedLocation,
  provider,
  facility,
  onClickEditLocation,
  refreshLocation,
  onSeeOtherLocationsClick,
}) => {
  const { confidence = 0 } = location
  const isVirtualLocation = isLocationVirtual(location)
  const isPartnerDataLocation = isPartnerLocation(location)

  return (
    <Container>
      {!isPartnerDataLocation && confidence < 3 && <InformationInaccurateBanner />}

      <Flex
        s={p => ({
          marginBottom: p.theme.sizes.four,
          justifyContent: 'space-between',
          alignItems: 'center',
        })}
      >
        <Flex>
          <Heading
            type={HEADING_TYPE.H2}
            s={p => ({
              color: p.theme.colors.aubergine2,
              margin: 0,
              marginRight: p.theme.sizes.two,
            })}
          >
            {isVirtualLocation ? 'Virtual Platform Details' : 'Location Details'}
          </Heading>
        </Flex>
        {!isPartnerDataLocation &&
          <PermissionRestricted level={2}>
            <EditDetailButton
              label="Edit Location"
              onClick={onClickEditLocation}
              size="lg"
            />
          </PermissionRestricted>
        }
      </Flex>
      {isPartnerDataLocation ? (
        <VirtualLocationPanel
          location={location}
          provider={provider}
          onSeeOtherLocationsClick={onSeeOtherLocationsClick}
        />
      ) : (
        <PhysicalLocationPanel
          location={location}
          setSelectedLocation={setSelectedLocation}
          provider={provider}
          facility={facility}
          refreshLocation={refreshLocation}
          onSeeOtherLocationsClick={onSeeOtherLocationsClick}
          onClickEditLocation={onClickEditLocation}
        />
      )}
    </Container>
  )
}

const InformationInaccurateBanner = () => {
  return (
    <Flex
      s={p => ({
        padding: `${p.theme.sizes.three} ${p.theme.sizes.four}`,
        backgroundColor: p.theme.colors.gray5,
        border: `1px solid ${p.theme.colors.borderLight}`,
        borderRadius: '4px',
        marginBottom: p.theme.sizes.six,
        alignItems: 'center',
        ['> div']: {
          marginRight: p.theme.sizes.four,
          backgroundColor: p.theme.colors.aubergine,
          ['> p']: {
            color: p.theme.colors.white,
          },
        },
      })}
    >
      <InfoIndicator />
      <Label>
        {`Ribbon's machine learning models have scored the current selected
        location information as potentially inaccurate.`}
      </Label>
    </Flex>
  )
}

LocationsPanel.propTypes = {
  location: PropTypes.object,
  setSelectedLocation: PropTypes.func,
  onSeeOtherLocationsClick: PropTypes.func,
  onClickEditLocation: PropTypes.func,
  refreshLocation: PropTypes.func,
  provider: PropTypes.object,
  facility: PropTypes.object,
}

LocationsPanel.defaultProps = {
  location: {},
}
