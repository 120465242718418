import React, { useState } from 'react'
import { Flex } from 'design-system/flex'
import { PageTitle } from '../components/PageTitle'
import { PageContainer } from '../components/PageContainer'
import { PageDivider } from '../components/PageDivider'
import { SetupCard } from './SetupCard'
import { UpdateApiVersionModal } from './UpdateApiVersionModal'
import { UpdateTypoToleranceModal } from './UpdateTypoToleranceModal'

export const APIConfiguration = () => {
  const [showModal, setShowModal] = useState(null)

  const handleSetupChange = type => {
    setShowModal(type)
  }

  return (
    <PageContainer>
      <PageTitle>API Configuration</PageTitle>

      <Flex
        s={p => ({
          marginTop: p.theme.sizes.six,
          flex: 1,
          flexDirection: 'column',
        })}
      >
        <SetupCard onClickChange={handleSetupChange} />
        <PageDivider />
      </Flex>

      {showModal === 'api_version' && (
        <UpdateApiVersionModal
          handleClose={() => setShowModal(null)}
          currentApiVersion="2021-05-21"
        />
      )}
      {showModal === 'typo_tolerance' && (
        <UpdateTypoToleranceModal
          handleClose={() => setShowModal(null)}
          currentTolerance="5"
        />
      )}
    </PageContainer>
  )
}
