import React, { useState } from 'react'
import { Modal } from 'design-system/modal'
import { Block } from 'design-system/block'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Label, LABEL_TYPE } from 'design-system/label'
import styled from 'styled-components'
import { Spinner } from 'design-system/spinner/spinner.component'
import { SelectInput } from 'design-system/input/select-input'
import { theme } from 'theme'

export const UpdateApiVersionModal = ({
  handleClose = () => {},
  currentApiVersion = '2021-05-21',
}) => {
  const [apiVersion, setApiVersion] = useState(currentApiVersion)
  const [isLoading, setIsLoading] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const onChange = e => {
    const { value } = e.target
    setApiVersion(value)
  }

  const onClickSave = async () => {
    if (apiVersion === currentApiVersion) {
      handleClose()
    } else {
      setShowConfirm(true)
    }
  }

  const onClickConfirm = async () => {
    console.log(`🚀 => onClickConfirm => apiVersion`, apiVersion)

    // API call to delete Instance
    setIsLoading(true)
    try {
      setTimeout(() => {
        setIsLoading(false)
        handleClose()
      }, 500)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onClickCancel = () => {
    if (showConfirm) {
      setShowConfirm(false)
    } else {
      handleClose()
    }
  }

  return (
    <Modal
      onClose={handleClose}
      title={`Update API Version`}
      footer={
        <Block
          type="FLEX"
          s={p => ({
            marginRight: p.theme.sizes.six,
            justifyContent: 'flex-end',
          })}
        >
          <Button
            s={() => ({
              marginRight: '1rem',
            })}
            type={BUTTON_TYPE.LINK}
            onClick={onClickCancel}
          >
            {showConfirm ? 'Back' : 'Cancel'}
          </Button>
          <Button
            onClick={showConfirm ? onClickConfirm : onClickSave}
            type={BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.LARGE}
            disabled={isLoading}
            s={() => ({
              minWidth: '110px',
            })}
          >
            {showConfirm ? 'Confirm' : 'Save'}
          </Button>
        </Block>
      }
    >
      {isLoading ? (
        <Spinner label={`Updating API Version..`} />
      ) : (
        <div>
          {showConfirm ? (
            <FormContainer>
              <Label
                s={p => ({
                  color: p.theme.colors.aubergine,
                })}
              >
                Are you sure you want to update your API version from{' '}
                <span style={{ color: theme.colors.gray1, fontWeight: '500' }}>
                  {currentApiVersion}
                </span>{' '}
                to{' '}
                <span style={{ color: theme.colors.gray1, fontWeight: '500' }}>
                  {apiVersion}
                </span>
                ? This change will impact all records and cannot be reversed.
              </Label>
            </FormContainer>
          ) : (
            <FormContainer>
              <Label type={LABEL_TYPE.BODY1_BOLD}>API Version</Label>
              <SelectInput
                onChange={onChange}
                name="apiVersion"
                value={apiVersion}
                options={[
                  {
                    label: '2021-05-21',
                    value: '2021-05-21',
                  },
                  {
                    label: '2021-04-11',
                    value: '2021-04-11',
                  },
                  {
                    label: '2021-03-10',
                    value: '2021-03-10',
                  },
                ]}
                placeholder="Select api version"
                disabled={false}
                containerStyle={{ margin: '1em 0', maxWidth: '50%' }}
              />
              <Label
                s={p => ({
                  color: p.theme.colors.gray1,
                })}
              >
                This change will impact all records and could result in a loss
                of editing changes.
              </Label>
            </FormContainer>
          )}
        </div>
      )}
    </Modal>
  )
}

const FormContainer = styled(Block)`
  padding: ${p => p.theme.sizes.four};
  min-height: 250px;
`
