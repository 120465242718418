import React from 'react'
import PropTypes from 'prop-types'
import PencilSVG from 'assets/pencil.svg'
import { Label, LABEL_TYPE } from 'design-system/label'
import { Button } from 'design-system/button'

export const EditDetailButton = ({ label, onClick, size = 'lg' }) => {
  return (
    <Button
      s={() => ({
        ...(size !== 'lg' && {
          ['> button']: {
            padding: 0,
            height: '24px',
            width: '24px',
          },
          borderRadius: '50%',
        }),
      })}
      onClick={onClick}
      data-testid="edit-detail-btn"
    >
      <PencilSVG
        width={size === 'lg' ? 17 : 10}
        height={size === 'lg' ? 17 : 10}
      />
      {size === 'lg' && (
        <Label
          type={LABEL_TYPE.BODY1}
          s={p => ({
            marginLeft: p.theme.sizes.three,
          })}
        >
          {label}
        </Label>
      )}
    </Button>
  )
}

EditDetailButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
}
