import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const TYPE_STYLES = {
  H1: p => `
    font-size: ${p.theme.headingSizes.H1};
    font-weight: 700;
  `,
  H2: p => `
    font-size: ${p.theme.headingSizes.H2};
    font-weight: 700;
  `,
  H3: p => `
    font-size: ${p.theme.headingSizes.H3};
    font-weight: 600;
  `,
  H4: p => `
    font-size: ${p.theme.headingSizes.H4};
    font-weight: 600;
  `,
}

const COMPONENT_MAP = {
  H1: styled.h1``,
  H2: styled.h2``,
  H3: styled.h3``,
  H4: styled.h4``,
}

export const HEADING_TYPE = {
  H1: 'H1',
  H2: 'H2',
  H3: 'H3',
  H4: 'H4',
}

const UnstyledHeading = props => {
  const Comp = COMPONENT_MAP[`${props.type || 1}`]

  return <Comp className={props.className}>{props.children}</Comp>
}

UnstyledHeading.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(TYPE_STYLES)).isRequired,
}

UnstyledHeading.defaultProps = {
  type: HEADING_TYPE.H1,
}

export const Heading = styled(UnstyledHeading)`
  color: ${props => props.theme.colors.text};
  margin: 0;
  ${props => css(TYPE_STYLES[props.type])}
  ${props => (props.s ? css(props.s(props)) : '')}
`

Heading.propTypes = {
  s: PropTypes.func,
}

Heading.defaultProps = {
  type: HEADING_TYPE.H1,
}
