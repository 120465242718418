/**
 * Specialty "subtitle" display rules:
 *     If primary specialty in searched specialties, display primary specialty
 *     If primary specialty exists and no specialty was searched, display primary specialty
 *     If non-primary specialty searched, display the searched specialty
 *     Otherwise, display first specialty in provider list (no sorting)
 * @returns {uuid} the specialty UUID used to sort result specialties, or undefined if no sorting will be performed
 */
export function determineSpecialtyIdToDisplay(searchedSpecialtyIds, resultSpecialties) {
  const firstSearchedSpecialtyId = searchedSpecialtyIds?.length > 0 && searchedSpecialtyIds[0]
  const primarySpecialtyId = (resultSpecialties || []).find(specialty => specialty.isPrimary)?.uuid
  const primarySpecialtyInSearchedSpecialties = searchedSpecialtyIds?.includes(primarySpecialtyId)

  if ((primarySpecialtyId && !firstSearchedSpecialtyId) || primarySpecialtyInSearchedSpecialties) {
    return primarySpecialtyId
  } else if (firstSearchedSpecialtyId) {
    return firstSearchedSpecialtyId
  }
}

export const formatProviderSubtitle = (resultsMetadata, result) => {
  const specialtyIdToDisplay = determineSpecialtyIdToDisplay(resultsMetadata.specialtyIds, result.specialties)

  function specialtyCompareFn(s1, s2) {
    if (s1.uuid === specialtyIdToDisplay) return -1;
    if (s2.uuid === specialtyIdToDisplay) return 1;
    return 0;
  }
  const sortedSpecialties = (result.specialties || []).sort(specialtyCompareFn)
  const specialtiesLabels = sortedSpecialties.reduce((acc, specialty) => {
    if (acc.length === 1) {
      acc.push(`+${result.specialties.length - 1} more`)
    } else if (acc.length === 0) {
      acc.push(specialty.providerName)
    }
    return acc
  }, [])
  return specialtiesLabels.join('\n')
}
