import React from 'react'
import PropTypes from 'prop-types'
import { CostIndicators } from 'components/cost-indicators/cost-indicators'
import { DotIndicators } from 'components/dot-indicators/dot-indicators'
import { Flex } from 'design-system/flex'
import { Label, LABEL_TYPE } from 'design-system/label'
import StarSvg from 'assets/star.svg'

const BulletPoint = () => {
  return (
    <Label
      s={p => ({
        color: p.theme.colors.gray0,
        fontWeight: 500,
        padding: '0 4px',
      })}
    >
      ·
    </Label>
  )
}
export const RatingLabel = ({
  rating,
  label,
  qualityRating,
  costEfficiency,
}) => {
  return (
    <Flex
      s={() => ({
        alignItems: 'center',
        marginTop: 'auto',
        flexWrap: 'wrap',
      })}
    >
      {rating && (
        <Flex s={() => ({ alignItems: 'center' })}>
          <StarSvg />
          <Label
            s={p => ({
              paddingLeft: '3px',
              color: p.theme.colors.gray0,
              fontWeight: 500,
            })}
            type={LABEL_TYPE.BODY1}
          >
            {rating.toFixed(1)}
          </Label>
        </Flex>
      )}

      {costEfficiency && (
        <>
          {rating && <BulletPoint />}
          <CostIndicators
            // Reversing cost -> value of 5 = 1($) and value of 1 = 5($)s
            total={Math.abs(Math.round(costEfficiency - 6))}
            showEmpty={false}
          />
        </>
      )}

      {qualityRating && (
        <>
          {(rating || costEfficiency) && <BulletPoint />}
          <DotIndicators total={Math.round(qualityRating)} />
        </>
      )}
      {label && (
        <>
          {(rating || costEfficiency || qualityRating) && <BulletPoint />}
          <Label
            s={p => ({
              color: p.theme.colors.gray0,
              fontWeight: 500,
              textOverflow: 'ellipsis',
            })}
          >
            {label}
          </Label>
        </>
      )}
    </Flex>
  )
}

RatingLabel.propTypes = {
  rating: PropTypes.number,
  label: PropTypes.string,
  costEfficiency: PropTypes.number,
  qualityRating: PropTypes.number,
}
