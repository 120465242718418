import React, { useContext } from 'react'
import { Router } from '@reach/router'
import { DeveloperDashboardRoutes } from 'config/route-config'
import { Flex } from 'design-system/flex'
import styled from 'styled-components'
import { DeveloperSidebar } from './sidebar/DeveloperSidebar'
import { DevelopersRoute } from 'components/developers-route/DevelopersRoute'
import { InstanceContextProvider } from './context/InstanceContext'
import { AuthStateContext } from 'context/auth-state-context'
import { PageLoading } from 'containers/page-loading/page-loading'

const LayoutContainer = styled(Flex)`
  flex: 1;
  background-color: ${p => p.theme.colors.gray7};
  overflow: hidden;
  height: 100%;
  > div {
    flex: 1;
    min-width: 0;
  }
`

export const DevelopersLayout = () => {
  const [authState = {}] = useContext(AuthStateContext)
  const { isFetching } = authState

  if (isFetching) {
    return <PageLoading />
  }

  return (
    <InstanceContextProvider>
      <LayoutContainer>
        <DeveloperSidebar />
        <Router>
          {DeveloperDashboardRoutes.map(route => {
            const { path, component: RouteComponent } = route
            return (
              <DevelopersRoute
                key={path}
                path={path}
                component={RouteComponent}
              />
            )
          })}
        </Router>
      </LayoutContainer>
    </InstanceContextProvider>
  )
}
