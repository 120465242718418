import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'design-system/label'
import { MenuOption } from './LabelDropdown'
import { Checkbox } from 'design-system/checkbox'
import ReactSwitch from 'react-switch'
import { colors } from 'theme/colors'

export const ApiRequestsMenuOptions = ({
  onClick = () => {},
  selected = [],
  onClickUnselectAll,
  items = [],
  type = 'checkbox', // 'switch'
}) => {
  return (
    <>
      {onClickUnselectAll && (
        <MenuOption
          s={() => ({
            justifyContent: 'flex-end',
          })}
          isDisabled={selected.length === 0}
          onClick={selected.length > 0 ? onClickUnselectAll : undefined}
        >
          <Label
            s={() => ({
              textAlign: 'right',
              fontWeight: '500',
            })}
          >
            Unselect all
          </Label>
        </MenuOption>
      )}
      {items.map(item => {
        const isSelected = !!selected.find(i => i === item)
        return (
          <MenuOption key={item} onClick={() => onClick(item)}>
            {type === 'switch' ? (
              <ReactSwitch
                onChange={() => onClick(item)}
                checked={isSelected}
                onColor={colors.aubergine}
                checkedIcon={false}
                uncheckedIcon={false}
                height={12}
                width={24}
              />
            ) : (
              <Checkbox value={isSelected} />
            )}
            <Label
              s={p => ({
                marginLeft: p.theme.sizes.two,
              })}
            >
              {item}
            </Label>
          </MenuOption>
        )
      })}
    </>
  )
}

ApiRequestsMenuOptions.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.array,
  onClickUnselectAll: PropTypes.func,
  items: PropTypes.array,
  type: PropTypes.string,
}
