import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'design-system/flex'
import styled from 'styled-components'
import { Button, BUTTON_TYPE } from 'design-system/button'
import { Modal } from 'design-system/modal'
import { Label, LABEL_TYPE } from 'design-system/label'

const FooterContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  padding: 0 ${p => p.theme.sizes.four};
`

export const NoProcedureMatchModal = ({ input, closeModal = () => {} }) => {
  return (
    <Modal
      onClose={closeModal}
      title={`No Procedure Match Found`}
      gridContainerStyle={{ maxWidth: '600px' }}
      footer={
        <FooterContainer>
          <Flex>
            <Button
              s={p => ({
                marginRight: p.theme.sizes.four,
              })}
              type={BUTTON_TYPE.LINK}
              onClick={closeModal}
            >
              Proceed to search results
            </Button>
          </Flex>
        </FooterContainer>
      }
    >
      <Flex
        s={p => ({
          padding: p.theme.sizes.five,
          flexDirection: 'column',
        })}
      >
        <Label type={LABEL_TYPE.BODY2_BOLD}>
          We were not able to find a procedure match for &quot;{input}&quot;,
          but have found providers that match your other search criteria.
        </Label>
      </Flex>
    </Modal>
  )
}

NoProcedureMatchModal.propTypes = {
  input: PropTypes.any,
  closeModal: PropTypes.func,
}
