import React from 'react'
import PropTypes from 'prop-types'
import { Button, BUTTON_TYPE, BUTTON_SIZE } from 'design-system/button'
import { Label } from 'design-system/label'
import SearchSVG from 'assets/search.svg'

export const SearchBtn = ({
  hasSearchChanges,
  isSearchDisabled,
  onClickSearch,
  id = 'search-btn',
}) => {
  return (
    <Button
      s={p => ({
        ...(hasSearchChanges
          ? {
              width: '120px',
              transition: 'width 0.3s ease-in',
            }
          : {
              width: '48px',
              transition: 'width 0.3s ease-in',
            }),
        borderRadius: `0 ${p.theme.sizes.thirteen} ${p.theme.sizes.thirteen} 0`,
        ['> button']: {
          borderRadius: `0 ${p.theme.sizes.thirteen} ${p.theme.sizes.thirteen} 0`,
        },
        ['svg']: {
          path: {
            fill: 'white',
          },
        },
      })}
      disabled={isSearchDisabled}
      onClick={onClickSearch}
      type={isSearchDisabled ? BUTTON_TYPE.DISABLED : BUTTON_TYPE.PRIMARY}
      size={BUTTON_SIZE.EXTRA_LARGE}
      data-testid={id}
    >
      <SearchSVG width="16px" height="16px" />
      {hasSearchChanges && (
        <Label
          s={p => ({
            marginLeft: p.theme.sizes.four,
            fontWeight: '400',
          })}
        >
          Search
        </Label>
      )}
    </Button>
  )
}

SearchBtn.propTypes = {
  hasSearchChanges: PropTypes.bool,
  isSearchDisabled: PropTypes.bool,
  onClickSearch: PropTypes.func,
  id: PropTypes.string,
}
