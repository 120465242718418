import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Flex } from 'design-system/flex'
import CloseSVG from 'assets/close-x.svg'

const ActionIconContainer = styled(Flex)`
  align-items: center;
  /* left only because parent has padding as well*/
  padding-left: ${p => p.theme.sizes.two};
`

const TagContainer = styled(Flex)`
  align-items: center;
  cursor: pointer;
  padding: 0 ${p => p.theme.sizes.four};
  min-height: ${p => p.theme.sizes.six};
  font-size: ${p => p.theme.sizes.five};
  border-radius: ${p => p.theme.sizes.thirteen};
  border: 1.75px solid ${props => props.theme.colors.gray0};
  color: ${props => props.theme.colors.gray0};
  background: ${props => props.theme.colors.white};
  :hover {
    background: ${props => props.theme.colors.gray6};
  }
  ${props => TYPE_STYLES[props.type] && css(TYPE_STYLES[props.type])}
  ${props => SIZE_STYLES[props.size] && css(SIZE_STYLES[props.size])}
  ${props => (props.s ? css(props.s(props)) : '')}
`

export const Tag = props => {
  return (
    <TagContainer
      className={props.className}
      onClick={props.onClick}
      data-tip={props['data-tip']}
      data-testid={props['data-testid']}
      {...props}
    >
      {props.children}
      {props.showClearIcon && (
        <ActionIconContainer onClick={props.onClickClear} className="closeBtn">
          <CloseSVG height={10} width={10} />
        </ActionIconContainer>
      )}
    </TagContainer>
  )
}

const SMALL = p => `
  font-size: ${p.theme.sizes.four};
`

const LARGE = p => `
  font-size: ${p.theme.sizes.five};
  border-radius: ${p.theme.sizes.thirteen};
`

const DEFAULT = () => `
`

const BORDERLESS = p => `
  border: 0;
  :hover {
      background: ${p.theme.colors.gray6};
  }
`

const SIZE_STYLES = {
  SMALL,
  LARGE,
}

const TYPE_STYLES = {
  DEFAULT,
  BORDERLESS,
}

export const TAG_TYPE = {
  DEFAULT: 'DEFAULT',
  BORDERLESS: 'BORDERLESS',
}

export const TAG_SIZE = {
  SMALL: 'SMALL',
  LARGE: 'LARGE',
}

Tag.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
  onClickClear: PropTypes.func,
  showClearIcon: PropTypes.bool,
  s: PropTypes.func,
  type: PropTypes.oneOf(Object.keys(TAG_TYPE)),
  size: PropTypes.oneOf(Object.keys(TAG_SIZE)),
  'data-tip': PropTypes.string,
  'data-testid': PropTypes.string,
}

Tag.defaultProps = {
  size: 'LARGE',
  tag: 'DEFAULT',
}
