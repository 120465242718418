import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import { InfoIndicator } from 'components/info-indicator/info-indicator'
import { colors } from 'theme/colors'
import { Spinner } from 'design-system/spinner'
import QuestionMark from 'assets/question-mark.svg'
import { Heading, HEADING_TYPE } from 'design-system/heading/heading.component'

export const CostEstimatesResults = ({
  result,
  isLoading,
  noBorder = false,
  showDescription = false,
  error: error,
}) => {
  if (isLoading) {
    return <Spinner padding="32px" label={'Searching cost estimates'} />
  }

  if (error) {
    return (
      <Flex
        s={p => ({
          padding: noBorder ? p.theme.sizes.four : 0,
          flexDirection: 'column',
        })}
      >
        <Heading type={HEADING_TYPE.H2}>Something went wrong..</Heading>
        <Label
          s={p => ({
            marginTop: p.theme.sizes.eight,
          })}
        >
          Please try again
        </Label>
      </Flex>
    )
  }

  const { procedure, costEstimates } = result || {}
  return (
    <>
      {!procedure ||
      !costEstimates ||
      Object.keys(costEstimates).length === 0 ? (
        <NoResultsContainer noBorder={noBorder} procedure={procedure} />
      ) : (
        <>
          <ProcedureResult noBorder={noBorder}>
            <ResultHeaderRow>
              <Flex
                s={() => ({
                  flexDirection: 'column',
                })}
              >
                <ResultHeaderLabel>
                  {procedure.display}
                  {showDescription ? (
                    <span>{' procedures in this area cost:'}</span>
                  ) : (
                    ''
                  )}
                </ResultHeaderLabel>
                <Label
                  s={p => ({
                    marginTop: p.theme.sizes.one,
                    color: p.theme.colors.gray2,
                  })}
                >
                  {`CPT: ${
                    procedure.procedure_code_count > 0
                      ? procedure.procedure_codes[0].code
                      : ''
                  }`}
                </Label>
              </Flex>
              {!showDescription && (
                <InfoIndicator message="Estimates are calculated using historical claims data. Prices are not specific for an insurance network or patient." />
              )}
            </ResultHeaderRow>
            <ResultBar>
              <ResultLine position="left" />
              <ResultLineRange>
                <VerticalIndicator />
                <ResultLine bgColor={colors.gray0} />
                <ResultCircle />
                <ResultLine bgColor={colors.gray0} />
                <VerticalIndicator />
              </ResultLineRange>
              <ResultLine position="right" />
            </ResultBar>
            <Flex
              s={() => ({
                width: '90%',
                alignSelf: 'center',
              })}
            >
              <ResultLine bgColor="transparent" />
              <ResultLineRange>
                <PriceOption
                  price={Math.round(costEstimates.minimum)}
                  type="Min"
                  position="left"
                />
                <ResultLine bgColor="transparent" />
                <PriceOption
                  price={Math.round(costEstimates.median)}
                  type="Median"
                />
                <ResultLine bgColor="transparent" />
                <PriceOption
                  price={Math.round(costEstimates.maximum)}
                  type="Max"
                  position="right"
                />
              </ResultLineRange>
              <ResultLine bgColor="transparent" />
            </Flex>
          </ProcedureResult>
        </>
      )}
    </>
  )
}

CostEstimatesResults.propTypes = {
  procedure: PropTypes.object,
  location: PropTypes.object,
  result: PropTypes.object,
  isLoading: PropTypes.bool,
  noBorder: PropTypes.bool,
  showDescription: PropTypes.bool,
  error: PropTypes.any,
}

const PriceOptionContainer = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  p:nth-of-type(1) {
    font-weight: bold;
  }
  p:nth-of-type(2) {
    color: ${p => p.theme.colors.gray2};
    font-size: 14px;
  }
  ${p => ({
    ...(p.position === 'left' && {
      marginLeft: '-18px',
    }),
    ...(p.position === 'right' && {
      marginRight: '-18px',
    }),
  })}
`

const PriceOption = ({ price = 500, type = 'Type', position = 'center' }) => {
  return (
    <PriceOptionContainer position={position}>
      <Label>{`$${price}`}</Label>
      <Label>{type}</Label>
    </PriceOptionContainer>
  )
}

const NoResultsContainer = ({ noBorder = false, procedure = {} }) => {
  return (
    <ProcedureResult noBorder={noBorder}>
      <ResultHeaderLabel>
        {procedure.display}
        <span>{' no cost estimate available'}</span>
      </ResultHeaderLabel>
      <ResultBar>
        <ResultLine position="left" bgColor={colors.borderLight} />
        <QuestionMark />
        <ResultLine position="right" bgColor={colors.borderLight} />
      </ResultBar>
    </ProcedureResult>
  )
}

const ResultHeaderLabel = styled(Label)`
  font-weight: 500;
  font-size: ${p => p.theme.headingSizes.H3};
  span {
    color: ${p => p.theme.colors.gray2};
    font-weight: 400;
  }
`

const ProcedureResult = styled(Flex)`
  flex-direction: column;
  margin-top: ${p => p.theme.sizes.six};
  border: 1px solid ${p => p.theme.colors.borderDefault};
  border-radius: 5px;
  padding: ${p => p.theme.sizes.four};
  background-color: ${p => p.theme.colors.white};
  ${p => ({
    ...(p.noBorder && {
      border: 'none',
    }),
  })}
`

const ResultHeaderRow = styled(Flex)`
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
`

const ResultBar = styled(Flex)`
  width: 90%;
  margin-top: ${p => p.theme.sizes.six};
  margin-bottom: ${p => p.theme.sizes.four};
  align-items: center;
  align-self: center;
`

const ResultLineRange = styled(Flex)`
  flex: 2;
  width: '100%';
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
`

const ResultLine = styled(Flex)`
  height: 3px;
  flex: 1;
  background-color: ${p =>
    p.bgColor ? p.bgColor : p.theme.colors.borderLight};
  ${p => ({
    ...(p.position === 'left' && {
      borderRadius: `2px 0px 0px 2px`,
    }),
    ...(p.position === 'right' && {
      borderRadius: `0px 2px 2px 0px`,
    }),
  })}
`

const ResultCircle = styled.div`
  flex: 1;
  height: 14px;
  max-width: 14px;
  border-radius: 7px;
  border: 2.5px solid ${p => p.theme.colors.gray0};
`

const VerticalIndicator = styled.div`
  height: 18px;
  width: 3px;
  background-color: ${p => p.theme.colors.gray0};
  border-radius: 2px;
`
