import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Spinner } from 'design-system/spinner/spinner.component'
import { colors } from 'theme/colors'

const UnstyledButton = props => {
  return (
    <div className={props.className} data-tip={props.tooltip}>
      <button
        id={props['data-testid']}
        disabled={props.disabled || props.loading}
        onClick={props.onClick}
        data-testid={props['data-testid']}
      >
        {props.loading ? (
          <Spinner
            size={18}
            outerColor={props.outerLoadingColor}
            accentColor={props.accentLoadingColor}
            s={() => ({
              padding: 0,
            })}
          />
        ) : (
          props.children
        )}
      </button>
    </div>
  )
}

UnstyledButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  outerLoadingColor: PropTypes.string,
  accentLoadingColor: PropTypes.string,
  tooltip: PropTypes.string,
  'data-testid': PropTypes.string,
}

const SMALL = p => `
  height: ${p.theme.sizes.six};
`

const LARGE = p => `
  border-radius: ${p.theme.sizes.twelve};
  
  > button {
    border-radius: ${p.theme.sizes.twelve};
    height: ${p.theme.sizes.seven};
    font-size: ${p.theme.sizes.four};
    padding: ${p.theme.sizes.two} ${p.theme.sizes.five};
    letter-spacing: ${p.theme.letterSpacing.small};
  }
`

const EXTRA_LARGE = p => `
  height: ${p.theme.sizes.eight};
  border-radius: ${p.theme.sizes.twelve};
  > button {
    border-radius: ${p.theme.sizes.twelve};
    height: ${p.theme.sizes.eight};
    font-size: ${p.theme.sizes.four};
    padding: ${p.theme.sizes.three} ${p.theme.sizes.four};
    letter-spacing: ${p.theme.letterSpacing.small};
  }
`

const DEFAULT = p => `
  border: 1px solid ${p.theme.colors.borderMid};
  :hover {
    background-color: ${p.theme.colors.gray6};
  }
`

const PRIMARY = p => `
  background: ${p.theme.colors.aubergine};
  > button {
    color: ${p.theme.colors.white};
    :focus {
      background-color: ${p.theme.colors.aubergine3};
    }
  }
  :hover {
    background-color: ${p.theme.colors.aubergine3};
  }
`

const DESTRUCTIVE = p => `
  background: ${p.theme.colors.errorRed};
  > button {
    color: ${p.theme.colors.white};
    :focus {
      background-color: ${p.theme.colors.errorRedLight};
    }
  }
  :hover {
    background-color: ${p.theme.colors.errorRedLight};
  }
`

const GREYED_OUT = p => `
  border: 1px solid transparent;
  > button {
    color: ${p.theme.colors.textLight};
  }
`

const DISABLED = p => `
  background: ${p.theme.colors.gray3};
  > button {
    color: ${p.theme.colors.white};
    cursor: default
  }
`

const LINK = p => `
  border: none;
  > button {
    color: ${p.disabled ? p.theme.colors.gray3 : p.theme.colors.aubergine};
  }
  background: ${p.theme.colors.clear};
  :hover {
    background-color: ${
      p.disabled ? p.theme.colors.clear : p.theme.colors.gray6
    };
  }
`

const SIZE_STYLES = {
  SMALL,
  LARGE,
  EXTRA_LARGE,
}

const TYPE_STYLES = {
  DISABLED,
  DEFAULT,
  PRIMARY,
  GREYED_OUT,
  LINK,
  DESTRUCTIVE,
}

export const BUTTON_TYPE = {
  DEFAULT: 'DEFAULT',
  DISABLED: 'DISABLED',
  PRIMARY: 'PRIMARY',
  GREYED_OUT: 'GREYED_OUT',
  LINK: 'LINK',
  DESTRUCTIVE: 'DESTRUCTIVE',
}

export const BUTTON_SIZE = {
  LARGE: 'LARGE',
  SMALL: 'SMALL',
  EXTRA_LARGE: 'EXTRA_LARGE',
}

export const Button = styled(UnstyledButton)`
  color: ${props => props.theme.colors.text};
  background: white;
  margin: 0;
  > button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${props =>
      props.onClick && !props.disabled ? 'pointer' : 'normal'};
    font-family: 'Inter', sans-serif;
    background: none;
    border: 0;
    :focus,
    :active {
      outline: 0;
      border: 0;
    }
  }

  ${props => TYPE_STYLES[props.type] && css(TYPE_STYLES[props.type])}
  ${props => SIZE_STYLES[props.size] && css(SIZE_STYLES[props.size])}
  ${props => (props.s ? css(props.s(props)) : '')}
`

Button.propTypes = {
  s: PropTypes.func,
  type: PropTypes.oneOf(Object.keys(BUTTON_TYPE)),
  size: PropTypes.oneOf(Object.keys(BUTTON_SIZE)),
}

Button.defaultProps = {
  type: BUTTON_TYPE.DEFAULT,
  size: BUTTON_SIZE.LARGE,
  loading: false,
  outerLoadingColor: colors.aquamarine,
  accentLoadingColor: colors.white,
}
