import React from 'react'
import styled from 'styled-components'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { Flex } from 'design-system/flex'
import { Label } from 'design-system/label'
import { VIEW_OPTIONS } from '../UsageOverview'

const Container = styled(Flex)`
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  width: 100%;
  margin: ${p => p.theme.sizes.four} 0;
  padding: ${p => p.theme.sizes.four};
`

const GraphContainer = styled.div`
  width: 100%;
  height: 300px;
  overflow: hidden;
`

const colorMap = {
  GET: '#4cc9f0',
  PUT: '#7209b7',
  POST: '#ffea00',
  DELETE: '#e71d36',
  'All requests': '#8E3BC4',
  'All methods': '#4cc9f0',
  '/v1/custom/providers': '#e71d36',
  '/v1/custom/locations': '#2ec4b6',
  '/v1/custom/deductible': '#ff9f1c',
  '/v1/custom/insurances': '#3a0ca3',
  '/v1/custom/specialties': '#ffea00',
}

export const UsageChart = ({ state = {}, data = [] }) => {
  const { endpoints, methods, viewOptions } = state
  let endpointsLegend = [{ label: 'All endpoints', color: '#8E3BC4' }]
  if (endpoints.length > 0) {
    endpointsLegend = endpoints.map(e => ({
      label: e,
      color: colorMap[e] || '#4cc9f0',
    }))
  }

  let methodsLegend = [{ label: 'All methods', color: '#4cc9f0' }]
  if (methods.length > 0) {
    methodsLegend = methods.map(e => ({
      label: e,
      color: colorMap[e] || '#8E3BC4',
    }))
  }

  const selectedViewOption = viewOptions[0]

  return (
    <Container>
      <Flex
        s={p => ({
          marginBottom: p.theme.sizes.four,
          flexWrap: 'wrap',
        })}
      >
        {selectedViewOption === VIEW_OPTIONS[0] &&
          endpointsLegend.map(i => {
            return (
              <Flex
                key={i.label}
                s={p => ({
                  marginRight: p.theme.sizes.four,
                  alignItems: 'center',
                })}
              >
                <LegendLine color={i.color} />
                <Label
                  s={p => ({
                    color: p.theme.colors.gray2,
                    fontWeight: '400',
                  })}
                >
                  {i.label}
                </Label>
              </Flex>
            )
          })}
        {selectedViewOption === VIEW_OPTIONS[1] &&
          methodsLegend.map(i => {
            return (
              <Flex
                key={i.label}
                s={p => ({
                  marginRight: p.theme.sizes.four,
                  alignItems: 'center',
                })}
              >
                <LegendLine color={i.color} />
                <Label
                  s={p => ({
                    color: p.theme.colors.gray2,
                    fontWeight: '400',
                  })}
                >
                  {i.label}
                </Label>
              </Flex>
            )
          })}
      </Flex>

      <GraphContainer>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            {selectedViewOption === VIEW_OPTIONS[0] &&
              endpointsLegend.map(i => {
                return (
                  <Line
                    key={i.label}
                    type="monotone"
                    dataKey={i.label}
                    stroke={i.color}
                    activeDot={{ r: 8 }}
                  />
                )
              })}
            {selectedViewOption === VIEW_OPTIONS[1] &&
              methodsLegend.map(i => {
                return (
                  <Line
                    key={i.label}
                    type="monotone"
                    dataKey={i.label}
                    stroke={i.color}
                    activeDot={{ r: 8 }}
                  />
                )
              })}
          </LineChart>
        </ResponsiveContainer>
      </GraphContainer>
    </Container>
  )
}

const LegendLine = styled.div`
  width: 24px;
  height: 2px;
  background: ${p => (p.color ? p.color : '#8E3BC4')};
  margin-right: 8px;
`
