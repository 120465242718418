import { Block } from 'design-system/block'
import styled from 'styled-components'

export const Link = styled(Block)`
  cursor: pointer;
  ${p => ({
    padding: `${p.theme.sizes.two} ${p.theme.sizes.four}`,
  })};
  > a {
    text-decoration: none;
  }
`
