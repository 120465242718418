import { Flex } from 'design-system/flex'
import styled from 'styled-components'

export const PageContainer = styled(Flex)`
  flex: 1;
  flex-direction: column;
  padding: ${p => p.theme.sizes.six};
  padding-bottom: ${p => p.theme.sizes.twelve};
  max-width: 1175px;
  height: 100%;
  overflow-y: auto;
`
