import PasswordValidator from 'password-validator'

const schema = new PasswordValidator()
schema
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(100) // Maximum length 100
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .digits() // Must have digits
  .has()
  .symbols() // must have a symbol
  .has()
  .not()
  .spaces() // Should not have spaces

export const validPassword = (
  password: string,
  showList?: boolean,
): string | string[] => {
  const valid = schema.validate(password)
  if (showList) {
    return schema.validate(password, { list: true })
  }
  if (!valid) {
    return 'Please enter a valid password'
  }
  return ''
}

export const validateNewPassword = (password: string): boolean => {
  const rules: Array<(p: string) => boolean> = [
    p => (p && p.length >= 12 ? true : false),
    p => !!p.split('').find(c => !isNaN(c as any)),
    p => !!p.split('').find(c => c !== c.toUpperCase()),
    p => !!p.split('').find(c => c !== c.toLowerCase()),
  ]

  return !rules.find(rule => !rule(password))
}
