import React from 'react'
import styled from 'styled-components'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from 'design-system/button'
import { Flex } from 'design-system/flex'
import { colors } from 'theme/colors'

const AnimatedHeader = styled(Flex)`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${p => p.theme.colors.white};
  padding: ${p => p.theme.sizes.four};
  justify-content: flex-end;
  border-bottom: 1px solid ${p => p.theme.colors.gray4};
  z-index: 999;
  -webkit-animation: slideIn 0.5s forwards;
  -moz-animation: slideIn 0.5s forwards;
  animation: slideIn 0.5s forwards;
  @-webkit-keyframes slideIn {
    0% {
      transform: translateY(-75px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-moz-keyframes slideIn {
    0% {
      transform: translateY(-75px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateY(-75px);
    }

    100% {
      transform: translateY(0);
    }
  }
`

export const AnimatedStickyHeader = ({
  secondaryButton = { action: () => {}, label: 'Secondary' },
  primaryButton = { action: () => {}, label: 'Primary', loading: false },
}) => {
  return (
    <AnimatedHeader>
      <Flex s={() => ({ padding: 0 })}>
        <SecondaryButton
          onClick={secondaryButton.action}
          disabled={primaryButton.loading}
        >
          {secondaryButton.label}
        </SecondaryButton>
        <PrimaryButton
          onClick={primaryButton.action}
          loading={primaryButton.loading}
          outerLoadingColor={colors.aubergine}
        >
          {primaryButton.label}
        </PrimaryButton>
      </Flex>
    </AnimatedHeader>
  )
}

const PrimaryButton = styled(Button)`
  min-width: 160px;
`

PrimaryButton.defaultProps = {
  type: BUTTON_TYPE.DEFAULT,
  size: BUTTON_SIZE.LARGE,
}

const SecondaryButton = styled(Button)`
  margin-right: ${p => p.theme.sizes.two};
  :hover {
    background-color: ${p => p.theme.colors.gray6};
  }
`
SecondaryButton.defaultProps = {
  type: BUTTON_TYPE.LINK,
  size: BUTTON_SIZE.LARGE,
}
