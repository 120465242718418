import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { Flex } from 'design-system/flex'
import { Label, LABEL_TYPE } from 'design-system/label'
import { DropdownMenu } from 'components/dropdown/dropdown'
import { searchTypes } from 'utils/constants'
import { GlobalStateContext } from 'context/global-context-provider'
import StethoscopeSVG from 'assets/stethoscope.svg'
import FacilitiesSVG from 'assets/facilities.svg'
import { AuthStateContext } from 'context/auth-state-context'
import ReactTooltip from 'react-tooltip'

const DisabledMessage = 'Disabled by your administrator'

export const FindCareDropdown = ({ toggleDropdown, isHomePath }) => {
  const [searchState, searchDispatch] = useContext(GlobalStateContext)
  const [authState] = useContext(AuthStateContext)
  const { modules } = authState
  // Will always have atleast one minimum module type enabled.
  const enabledModuleTypes = Object.keys(modules).filter(
    key => modules[key].enabled,
  )

  const facilitiesEnabled = enabledModuleTypes.find(
    type => type === searchTypes.facilities,
  )
  const providersEnabled = enabledModuleTypes.find(
    type => type === searchTypes.providers,
  )

  const onClickFindCarePath = type => {
    console.log('onClickFindCarePath', type)
    const {
      searchType,
      providersSearchMode,
      facilitiesSearchMode,
    } = searchState
    if (!isHomePath) {
      navigate('/')
    }
    if (type === searchType) {
      // do nothing
    } else {
      searchDispatch({ key: 'searchType', value: type })
      searchDispatch({
        key: `${type}SearchMode`,
        value:
          type === searchTypes.providers
            ? providersSearchMode
            : facilitiesSearchMode,
      })
    }
    toggleDropdown()
  }
  return (
    <>
      <DropdownMenu
        id="find-care-dropdown"
        renderOption={option => {
          const isSelected =
            isHomePath && option.type === searchState.searchType
          let isDisabled = false
          if (option.type === searchTypes.providers && !providersEnabled) {
            isDisabled = true
          }
          if (option.type === searchTypes.facilities && !facilitiesEnabled) {
            isDisabled = true
          }
          return (
            <OptionLabelContainer
              selected={isSelected}
              key={option.title}
              onClick={!isDisabled ? option.onClick : null}
              disabled={isDisabled}
              data-tip={isDisabled ? DisabledMessage : ''}
              data-for={'find-care'}
            >
              {option.type === searchTypes.providers ? (
                <StethoscopeSVG />
              ) : (
                <FacilitiesSVG />
              )}
              <Label
                s={p => ({
                  paddingLeft: p.theme.sizes.two,
                })}
                type={LABEL_TYPE.BODY1}
              >
                {option.title}
              </Label>
            </OptionLabelContainer>
          )
        }}
        options={[
          {
            title: 'Search for Providers',
            type: searchTypes.providers,
            onClick: () => {
              onClickFindCarePath(searchTypes.providers)
            },
          },
          {
            title: 'Search for Facilities',
            type: searchTypes.facilities,
            onClick: () => {
              onClickFindCarePath(searchTypes.facilities)
            },
          },
        ]}
      />
      <ReactTooltip id="find-care" place="right" effect="solid" />
    </>
  )
}

FindCareDropdown.propTypes = {
  toggleDropdown: PropTypes.func,
  isHomePath: PropTypes.bool,
}

const OptionLabelContainer = styled(Flex)`
  align-items: center;
  padding: ${p => p.theme.sizes.three} ${p => p.theme.sizes.four};
  > p {
    font-weight: ${p => (p.selected ? 'bold' : '500')};
    color: ${p => p.theme.colors.gray0};
  }
  > svg {
    fill: ${p => p.theme.colors.gray0};
  }
  :hover {
    cursor: pointer;
    background-color: ${p =>
      p.onClick ? p.theme.colors.gray7 : p.theme.colors.white};
  }
  ${p => ({
    ...(p.disabled && {
      ['> p']: {
        color: p.theme.colors.gray2,
      },
      [':hover']: {
        cursor: 'not-allowed',
        backgroundColor: p.theme.colors.gray6,
      },
      backgroundColor: p.theme.colors.gray6,
    }),
  })}
`
